import { Component, ViewEncapsulation } from '@angular/core';
import { ICustomerDisplayService } from '@pos-common/services/system/customer-display';
import { SubSink } from 'subsink';
import { LoadingService } from '@pos-common/services/system/loading.service';
import { ICustomerDisplayDevice } from '@pos-common/services/system/customer-display';

@Component({
  selector: 'displays',
  styleUrls: ['displays.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'displays.component.html',
})
export class Displays {
  public displays: ICustomerDisplayDevice[] = [];
  public connectedDisplays: ICustomerDisplayDevice[] = [];
  private subs = new SubSink();

  constructor(private customerDisplayService: ICustomerDisplayService, private loadingService: LoadingService) {}

  ngOnInit() {
    this.subs.sink = this.customerDisplayService.devicesChangeEvent.subscribe((devices) => {
      this.refreshDevices(devices);
    });

    const { devices } = this.customerDisplayService;
    this.refreshDevices(devices);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  trackByFn(index: number, item: ICustomerDisplayDevice) {
    return item.id || index;
  }

  public changeConnectionStatus(device: ICustomerDisplayDevice) {
    if (this.connectedDisplays.includes(device)) {
      this.customerDisplayService.disconnectDevice(device);
      return;
    }

    this.loadingService.showLoadingItem();
    let timeout = setTimeout(() => this.loadingService.hideLoadingItem(), 30000);
    this.customerDisplayService
      .connectDevice(device)
      .then(() => {
        this.loadingService.hideLoadingItem();
        clearTimeout(timeout);
      })
      .catch(() => {
        this.loadingService.hideLoadingItem();
        clearTimeout(timeout);
      });
  }

  private refreshDevices(devices: ICustomerDisplayDevice[]) {
    this.displays = devices.filter((d) => d.status === 'disconnected');
    this.connectedDisplays = devices.filter((d) => d.status !== 'disconnected');
  }
}
