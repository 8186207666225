export enum MyPosTransactionCodeError {
  INVALID_CURRENCY = 6,
  USER_CANCEL_PAYMENT = 3,
  CANCEL_REQUEST = 999,
}

export enum MyPosTransactionMessageError {
  'terminal_transaction_error_wrong_currency' = 6,
  'mypos_user_cancel_payment' = 3,
  'mypos_invalid_pay_message' = 999,
}
