import { PrinterType } from './enum/printer-type.enum';

export const PRINTER_MODELS = {
  DEFAULT: {
    modelTitle: undefined,
    model: undefined,
    modelBT: undefined,
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    isScannerSupported: false,
    printerType: undefined,
  },
  TM_M10: {
    modelTitle: 'TM-m10',
    model: 'TM-m10',
    modelBT: 'TM-m10',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_M30: {
    modelTitle: 'TM-m30',
    model: 'TM-m30',
    modelBT: 'TM-m30',
    receiptLength: {
      smallTextRowWidth: 48,
      bigTextRowWidth: 24,
      kitchenSmallTextRowWidth: 47,
      kitchenBigTextRowWidth: 28,
    },
    printerType: PrinterType.Epson,
  },
  TM_P20: {
    modelTitle: 'TM-P20',
    model: 'TM-P20',
    modelBT: 'TM-P20',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_P60: {
    modelTitle: 'TM-P60',
    model: 'TM-P60',
    modelBT: 'TM-P60',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_P60II: {
    modelTitle: 'TM-P60II',
    model: 'TM-P60II',
    modelBT: 'TM-P60II',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_P80: {
    modelTitle: 'TM-P80',
    model: 'TM-P80',
    modelBT: 'TM-P80',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_T20: {
    modelTitle: 'TM-T20',
    model: 'TM-T20',
    modelBT: 'TM-T20',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_T60: {
    modelTitle: 'TM-T60',
    model: 'TM-T60',
    modelBT: 'TM-T60',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_T70: {
    modelTitle: 'TM-T70',
    model: 'TM-T70',
    modelBT: 'TM-T70',
    receiptLength: {
      smallTextRowWidth: 42,
      bigTextRowWidth: 21,
      kitchenSmallTextRowWidth: 42,
      kitchenBigTextRowWidth: 28,
    },
    printerType: PrinterType.Epson,
  },
  TM_T81: {
    modelTitle: 'TM-T81',
    model: 'TM-T81',
    modelBT: 'TM-T81',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_T82: {
    modelTitle: 'TM-T82',
    model: 'TM-T82',
    modelBT: 'TM-T82',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_T88: {
    modelTitle: 'TM-T88',
    model: 'TM-T88',
    modelBT: 'TM-T88',
    receiptLength: {
      smallTextRowWidth: 42,
      bigTextRowWidth: 21,
      kitchenSmallTextRowWidth: 42,
      kitchenBigTextRowWidth: 28,
    },
    printerType: PrinterType.Epson,
  },
  TM_T88V: {
    modelTitle: 'TM-T88V',
    model: 'TM-T88V',
    modelBT: 'TM-T88V',
    receiptLength: {
      smallTextRowWidth: 42,
      bigTextRowWidth: 21,
      kitchenSmallTextRowWidth: 42,
      kitchenBigTextRowWidth: 28,
    },
    printerType: PrinterType.Epson,
  },
  TM_T90: {
    modelTitle: 'TM-T90',
    model: 'TM-T90',
    modelBT: 'TM-T90',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_T90KP: {
    modelTitle: 'TM-T90KP',
    model: 'TM-T90KP',
    modelBT: 'TM-T90KP',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_U220: {
    modelTitle: 'TM-U220',
    model: 'TM-U220',
    modelBT: 'TM-U220',
    receiptLength: {
      smallTextRowWidth: 33,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 33,
      kitchenBigTextRowWidth: 20,
    },
    printerType: PrinterType.Epson,
  },
  TM_U330: {
    modelTitle: 'TM-U330',
    model: 'TM-U330',
    modelBT: 'TM-U330',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_L90: {
    modelTitle: 'TM-L90',
    model: 'TM-L90',
    modelBT: 'TM-L90',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TM_H6000: {
    modelTitle: 'TM-H6000',
    model: 'TM-H6000',
    modelBT: 'TM-H6000',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Epson,
  },
  TSP100: {
    modelTitle: 'TSP100',
    model: 'TSP143',
    modelBT: 'TSP100-',
    receiptLength: {
      smallTextRowWidth: 41,
      bigTextRowWidth: 20,
      kitchenSmallTextRowWidth: 41,
      kitchenBigTextRowWidth: 20,
    },
    printerType: PrinterType.Star,
  },
  TSP650: {
    modelTitle: 'TSP654',
    model: 'TSP654 (STR_T-001)',
    modelBT: 'TSP654 (STR_T-001)',
    receiptLength: {
      smallTextRowWidth: 48,
      bigTextRowWidth: 24,
      kitchenSmallTextRowWidth: 48,
      kitchenBigTextRowWidth: 24,
    },
    printerType: PrinterType.Star,
  },
  SP700: {
    modelTitle: 'SP700',
    model: 'SP742 (STR-001)',
    modelBT: 'SP742',
    receiptLength: {
      smallTextRowWidth: 42,
      bigTextRowWidth: 21,
      kitchenSmallTextRowWidth: 42,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.Star,
  },
  'SM-L200': {
    modelTitle: 'SM-L200',
    model: 'SM-L200',
    modelBT: 'STAR L200-',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 16,
    },
    printerType: PrinterType.Star,
  },
  POP: {
    modelTitle: 'mPOP',
    model: 'mPOP',
    modelBT: 'mPOP',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 16,
    },
    isScannerSupported: true,
    printerType: PrinterType.Star,
  },
  MCP20: {
    modelTitle: 'MCP20',
    model: 'MCP20 (STR-001)',
    modelBT: 'mC-Print2',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 16,
    },
    isScannerSupported: true,
    printerType: PrinterType.Star,
  },
  MCP21: {
    modelTitle: 'MCP21',
    model: 'MCP21 (STR-001)',
    modelBT: 'mC-Print2',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 16,
    },
    isScannerSupported: true,
    printerType: PrinterType.Star,
  },
  MCP31: {
    modelTitle: 'MCP31',
    model: 'MCP31 (STR-001)',
    modelBT: 'mC-Print3',
    receiptLength: {
      smallTextRowWidth: 48,
      bigTextRowWidth: 24,
      kitchenSmallTextRowWidth: 48,
      kitchenBigTextRowWidth: 24,
    },
    isScannerSupported: true,
    printerType: PrinterType.Star,
  },
  MYPOS_MINI: {
    modelTitle: 'MYPOS',
    model: 'MINI',
    modelBT: 'MYPOS',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 17,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 16,
    },
    printerType: PrinterType.MyPosMini,
  },
  MYPOS_N5: {
    modelTitle: 'MYPOS',
    model: 'N5',
    modelBT: 'MYPOS',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 21,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.MyPos,
  },
  MYPOS_CARBON: {
    modelTitle: 'MYPOS',
    model: 'CARBON',
    modelBT: 'N86',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 21,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.MyPos,
  },
  MYPOS_PRO: {
    modelTitle: 'MYPOS',
    model: 'PRO',
    modelBT: 'F20',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 21,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 21,
    },
    printerType: PrinterType.MyPos,
  },
  MYPOS_HUB_E800: {
    modelTitle: 'MYPOS',
    model: 'HUB+',
    modelBT: 'E800',
    receiptLength: {
      smallTextRowWidth: 40,
      bigTextRowWidth: 27,
      kitchenSmallTextRowWidth: 40,
      kitchenBigTextRowWidth: 27,
    },
    printerType: PrinterType.MyPos,
  },
  MYPOS_HUB_E500: {
    modelTitle: 'MYPOS',
    model: 'HUB',
    modelBT: 'E500',
    receiptLength: {
      smallTextRowWidth: 40,
      bigTextRowWidth: 27,
      kitchenSmallTextRowWidth: 40,
      kitchenBigTextRowWidth: 27,
    },
    printerType: PrinterType.MyPos,
  },
  SUNMI_80mm: {
    modelTitle: 'SUNMI_80mm',
    model: 'SUNMI_80mm',
    modelBT: 'SUNMI_80mm',
    receiptLength: {
      smallTextRowWidth: 44,
      bigTextRowWidth: 24,
      kitchenSmallTextRowWidth: 44,
      kitchenBigTextRowWidth: 24,
    },
    isScannerSupported: false,
    printerType: PrinterType.Sunmi,
  },
  SUNMI_58mm: {
    modelTitle: 'SUNMI_58mm',
    model: 'SUNMI_58mm',
    modelBT: 'SUNMI_58mm',
    receiptLength: {
      smallTextRowWidth: 44,
      bigTextRowWidth: 24,
      kitchenSmallTextRowWidth: 44,
      kitchenBigTextRowWidth: 24,
    },
    isScannerSupported: false,
    printerType: PrinterType.Sunmi,
  },
  SUNMI_40mm: {
    modelTitle: 'SUNMI_40mm',
    model: 'SUNMI_40mm',
    modelBT: 'SUNMI_40mm',
    receiptLength: {
      smallTextRowWidth: 29,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 29,
      kitchenBigTextRowWidth: 16,
    },
    isScannerSupported: false,
    printerType: PrinterType.Sunmi,
  },
  ADYEN: {
    modelTitle: 'ADYEN',
    model: 'ADYEN',
    modelBT: 'ADYEN',
    receiptLength: {
      smallTextRowWidth: 32,
      bigTextRowWidth: 16,
      kitchenSmallTextRowWidth: 32,
      kitchenBigTextRowWidth: 16,
    },
    isScannerSupported: false,
    printerType: PrinterType.Adyen,
  },
};
