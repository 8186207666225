import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../common/guards/auth.guard';
import { InitResolver } from '@pos-common/guards/init.resolver';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';
import { PreloadNextModules } from '@pos-common/services/system';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    resolve: { init: InitResolver },
    data: {
      preload: true,
    },
    loadChildren: () => import('../pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'employee',
    resolve: { init: InitResolver },
    canActivate: [AuthGuard],
    data: {
      preload: true,
    },
    loadChildren: () => import('../pages/employee-select/employee-select.module').then((m) => m.EmployeeSelectModule),
  },
  {
    path: 'collection',
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: {
          name: ROUTE_URLS.collection,
          nextPages: [
            ROUTE_URLS.checkout,
            ROUTE_URLS.invoices,
            ROUTE_URLS.report,
            ROUTE_URLS.settings,
            ROUTE_URLS.customers,
            ROUTE_URLS.calendar,
            ROUTE_URLS.shifts,
          ],
        },
        loadChildren: () => import('../pages/collection-view/collection-view.module').then((m) => m.CollectionViewPageModule),
      },
      {
        path: 'checkout',
        canActivate: [AuthGuard],
        data: {
          name: ROUTE_URLS.checkout,
          nextPages: [ROUTE_URLS.customers, ROUTE_URLS.success],
        },
        loadChildren: () => import('../pages/checkout/checkout.module').then((m) => m.CheckoutPageModule),
      },
    ],
  },
  { path: 'products', redirectTo: 'collection' },
  {
    path: 'customers',
    canActivate: [AuthGuard],
    data: {
      name: ROUTE_URLS.customers,
    },
    loadChildren: () => import('../pages/customers2/customers2.module').then((m) => m.CustomersPage2Module),
  },
  {
    path: 'invoices',
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: {
          name: ROUTE_URLS.invoices,
          nextPages: [
            ROUTE_URLS.invoicesCombine,
            ROUTE_URLS.invoicesWebshop,
            ROUTE_URLS.success,
            ROUTE_URLS.collection,
            ROUTE_URLS.settings,
            ROUTE_URLS.customers,
            ROUTE_URLS.calendar,
            ROUTE_URLS.shifts,
          ],
        },
        loadChildren: () => import('../pages/invoices/invoices.module').then((m) => m.InvoicesPageModule),
      },
      {
        path: 'combine',
        canActivate: [AuthGuard],
        data: { name: ROUTE_URLS.invoicesCombine },
        loadChildren: () => import('../pages/invoices-combine.page/invoices-combine.module').then((m) => m.InvoicesCombinePageModule),
      },
      {
        path: 'webshop',
        canActivate: [AuthGuard],
        data: { name: ROUTE_URLS.invoicesWebshop, nextPages: [ROUTE_URLS.collection] },
        loadChildren: () => import('../pages/invoices-webshop/invoices-webshop.module').then((m) => m.InvoicesWebshopPageModule),
      },
    ],
  },
  {
    path: 'report',
    canActivate: [AuthGuard],
    data: { name: ROUTE_URLS.report },
    loadChildren: () => import('../pages/day-report/day-report.module').then((m) => m.DayReportPageModule),
  },
  {
    path: 'shifts',
    canActivate: [AuthGuard],
    data: { name: ROUTE_URLS.shifts },
    loadChildren: () => import('../pages/shifts/shifts-module').then((m) => m.ShiftsModule),
  },
  {
    path: 'calendar',
    canActivate: [AuthGuard],
    data: { name: ROUTE_URLS.calendar, nextPages: [ROUTE_URLS.appointment] },
    loadChildren: () => import('../pages/calendar/calendar.module').then((m) => m.CalendarModule),
  },
  {
    path: 'cancellation',
    canActivate: [AuthGuard],
    loadChildren: () => import('../pages/invoice-cancellation/invoice-cancellation.module').then((m) => m.InvoiceCancellationModule),
  },
  {
    path: 'weak-device',
    data: { name: ROUTE_URLS.weakDevice },
    loadChildren: () => import('../pages/weak-device/weak-device.module').then((m) => m.WeakDeviceModule),
  },
  {
    path: 'success',
    canActivate: [AuthGuard],
    data: { name: ROUTE_URLS.success, nextPages: [ROUTE_URLS.customers] },
    loadChildren: () => import('../pages/success/success.module').then((m) => m.SuccessPageModule),
  },
  {
    path: 'appointment',
    canActivate: [AuthGuard],
    data: { name: ROUTE_URLS.appointment, nextPages: [ROUTE_URLS.collection] },
    loadChildren: () => import('../pages/appointment/appointment.module').then((m) => m.AppointmentModule),
  },
  {
    path: 'settings',
    data: {
      name: ROUTE_URLS.settings,
      nextPages: [ROUTE_URLS.printerQueue, ROUTE_URLS.weakDevice],
    },
    loadChildren: () => import('../pages/settings2/settings.module').then((m) => m.SettingsModule),
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'employee' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadNextModules, useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
