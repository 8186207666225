import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  Output,
  OnInit,
  NgModule,
} from '@angular/core';
import { LOCALE } from '@pos-common/constants/locale.const';
import { INullifyModalOptionsStyles } from '@pos-common/interfaces';
import { NullifyModalService } from '@pos-common/services/system/nullify-modal.service';
import { SecurityService } from '@pos-common/services/system/security.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { CompanyLocalizedPipeModule } from '@pos-common/pipes';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pos-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent implements OnInit {
  @Input() value: string;
  @Output() posSetValue = new EventEmitter<string>();
  public placeholder = '';

  constructor(
    private nullifyModalService: NullifyModalService,
    private securityService: SecurityService,
    private subSinkService: SubSinkService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const company = this.securityService.getLoggedCompanyData();
    this.placeholder = LOCALE.DateFormat[company.locale.dateFormat];
  }

  openPopup(event: Event) {
    const target = event.target as HTMLElement;
    const targetRect = target.getBoundingClientRect();
    const height = 300;
    const padding = 10;
    const expectedHeight = window.innerHeight - targetRect.bottom - padding;
    const arrAbove = expectedHeight > height;
    const top = expectedHeight > height ? targetRect.bottom + padding : targetRect.top - height - padding;

    const styles: INullifyModalOptionsStyles = {
      top: `${top}px !important`,
      right: '0',
      left: 'initial !important',
      maxWidth: '100%',
      margin: '0 20px !important',
      padding: '10px !important',
      width: '300px !important',
      height: `${height}px !important`,
      zIndex: 30001,
    };
    const posSetValue = new EventEmitter<string>();
    this.subSinkService.sink = posSetValue.subscribe((value) => {
      this.value = value;
      this.posSetValue.emit(value);
      this.cdr.detectChanges();
    });
    const value = this.value || new Date().toISOString();
    this.nullifyModalService.initModal({
      component: DatePickerComponent,
      options: { styles, className: 'pos-content-picker pos-date-picker', arrAbove },
      inputs: [{ value, posSetValue }],
    });
  }
}
@NgModule({
  imports: [CommonModule, CompanyLocalizedPipeModule],
  declarations: [DateComponent],
  exports: [DateComponent],
})
export class DateComponentModule {}
