import { ScannerPluginWrapperBase } from './abstract-scanner-plugin-wrapper';
import { BarcodeScannerPlugin } from '@paymash/capacitor-barcode-scanner-plugin';
import { PermissionTypes } from '@pos-common/constants/permission-types.enum';
import { PermissionService } from '@pos-common/services/system/permission/permission.service';
import { IBarcodeScannerPluginSetting } from '@pos-common/services/system/camera-scanner-adapter/wrappers/model';
import { Injectable } from '@angular/core';
import { SubSink } from 'subsink';
import { filter } from 'rxjs';

@Injectable()
export class PaymashScannerPluginWrapper extends ScannerPluginWrapperBase {
  private plugin = BarcodeScannerPlugin;
  private subs = new SubSink();
  constructor(private readonly permissionService: PermissionService) {
    super();
  }

  startScan(options: IBarcodeScannerPluginSetting) {
    this.stopScan();
    this.subs.sink = this.plugin
      .startScan({
        rect: {
          x: options.x,
          y: options.y,
          width: options.width,
          height: options.height,
        },
      })
      .pipe(filter((result) => !!result?.value))
      .subscribe((result) => {
        this.onResult({ code: result.value });
      });
    return Promise.resolve();
  }

  stopScan(): Promise<void> {
    this.subs.unsubscribe();
    return Promise.resolve();
  }

  checkPermission() {
    return this.permissionService.checkAndRequestPermission(PermissionTypes.CAMERA);
  }
}
