<header>
  <div class="header-start">
    <ng-content select="[header-start]"></ng-content>
  </div>
  <div class="header-title">
    <ng-content select="[header-title]"></ng-content>
  </div>
  <div class="header-end">
    <ng-content select="[header-end]"></ng-content>
  </div>
</header>
