<product-variant-modal></product-variant-modal>
<ion-header>
  <ion-toolbar>
    <!-- min-width needed for old android devices -->
    <div class="ion-toolbar-container">
      <div class="left-navbar">
        <div class="left-navbar-menu">
          <ion-menu-toggle>
            <button tappable (click)="closeScannerManually();" [disabled]="isMobile && productVariantIsActive">
              <pos-icon ionName="menu-outline"></pos-icon>
            </button>
          </ion-menu-toggle>
          <button (click)="openBarcodeScanner()" tappable class="btn-barcode-scanner-open">
            <i class="custom-icon custom-icon-barcode"></i>
          </button>
          <button *ngIf="socketScannerConnected" class="btn-connect-scanner" [disabled]="isBarcodeScannerOpened">
            <i class="custom-icon custom-icon-socket-scanner"></i>
          </button>
          <button *ngIf="scannerAppConnected" class="btn-connect-scanner">
            <i class="custom-icon custom-icon-scan-mobile"></i>
          </button>
          <button (click)="toggleInventorySearch(); closeScannerManually();" tappable class="btn-inventory-search">
            <i class="custom-icon search-icon"></i>
          </button>
          <printer-status-icon></printer-status-icon>
          <sync-icon></sync-icon>
        </div>

        <div class="left-navbar-invoices">
          <button
            *ngIf="!isMobile"
            class="btn-create-new-invoice invoice-count-btn"
            tappable
            (click)="openInvoices(); closeScannerManually()"
            end
          >
            <i class="custom-icon bill-icon"></i>
            <span class="text">{{'global_invoices_title' | translate}}</span>
            <div class="invoice-count">
              <span *ngIf="invoiceCount !== 0">{{invoiceCount}}</span>
            </div>
          </button>
          <button
            *ngIf="!isMobile"
            class="btn-create-new-invoice"
            tappable
            (click)="createNewInvoice(); closeScannerManually()"
            [disabled]="isDisabledCreateNewInvoice"
            end
            wave-effect
          >
            <i class="custom-icon custom-icon-add-custom-product" style="margin-right: 0"></i>
            <span class="text">{{'invoices_new_invoice_additional' | translate}}</span>
          </button>
        </div>
        <ion-buttons slot="primary" [class.buttons-last-slot]="isMyPosHubDevice">
          <button class="btn-current-user" (click)="closeScannerManually();" tappable>
            <current-user></current-user>
          </button>
          <printer-link
            *ngIf="isGastroMode"
            class="BTN-PRINT-GASTRO-KITCHEN-INVOICE"
            posMode="kitchen"
            [invoice]="activeInvoice"
            (click)="closeScannerManually();"
            tappable
          ></printer-link>
        </ion-buttons>
      </div>
      <div class="right-navbar" [class.gastro]="isGastroMode" [class.multile-guests]="isMultipleGuests">
        <customer-btn
          *ngIf="!isMultipleGuests"
          swipeAll
          class="select-customer-button in-toolbar"
          (click)="showCustomersModal()"
          tappable
          (swipe)="customerBlockSwipe($event)"
          [customerInputData]="customerData"
        ></customer-btn>

        <ng-container *ngIf="isMobile">
          <button class="btn-create-new-invoice" tappable (click)="openInvoices(); closeScannerManually();">
            <em class="mobile-invoice-count" *ngIf="invoiceCount !== 0">{{invoiceCount}}</em>
            <i class="custom-icon bill-icon"></i>
          </button>

          <button
            class="btn-create-new-invoice"
            tappable
            (click)="createNewInvoice(); closeScannerManually();"
            [disabled]="isDisabledCreateNewInvoice"
            end
            wave-effect
          >
            <i class="custom-icon custom-icon-add-custom-product"></i>
          </button>
        </ng-container>

        <button class="btn-change-table" *ngIf="isGastroMode" (click)="changeTable(); closeScannerManually();">
          <span class="text" *ngIf="!activeInvoice.gastronomyTableName">{{'no_table' | translate}}</span>
          <span class="text" *ngIf="activeInvoice.gastronomyTableName">{{activeInvoice.gastronomyTableName}}</span>
          <i class="custom-icon custom-icon-table"></i>
        </button>
        <pos-add-guest-button *ngIf="isMultipleGuests" (onClick)="createNewGuest()"></pos-add-guest-button>
        <printer-link
          *ngIf="isMultipleGuests && isGastroMode"
          posMode="kitchen"
          [invoice]="activeInvoice"
          [onlyIcon]="true"
          [isArrowRight]="true"
          [showUnprintedKitchenItems]="showUnprintedKitchenItems"
          [showGuestOnReceipt]="true"
        ></printer-link>
      </div>
    </div>
  </ion-toolbar>
  <table-guest-section-holder
    [activeInvoice]="activeInvoice"
    [isGastroMode]="isGastroMode"
    [productVariantIsActive]="productVariantIsActive"
    (onClickSection)="closeScannerManually()"
    (onChangeTable)="changeTable(); closeScannerManually();"
  >
  </table-guest-section-holder>
</ion-header>
<ion-content scrollY="false">
  <inventory [inventorySearchActive]="inventorySearchActive"></inventory>
  <cart></cart>
</ion-content>
