import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarNotificationsModalPage } from './page/calendar-notifications-modal.page';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import components from './components';
import { AppointmentNotificationTextPipeModule } from '@pos-modules/shared/pipes';
import { CompanyLocalizedPipeModule } from '@pos-common/pipes';
import { HeaderComponentModule, IconComponentModule, LoaderComponentModule } from '@pos-modules/shared/components';
import { NgForTrackByPropertyDirectiveModule } from '@pos-modules/shared/directives';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    AppointmentNotificationTextPipeModule,
    CompanyLocalizedPipeModule,
    LoaderComponentModule,
    IconComponentModule,
    HeaderComponentModule,
    NgForTrackByPropertyDirectiveModule,
  ],
  declarations: [CalendarNotificationsModalPage, ...components],
})
export class CalendarNotificationsModalModule {}
