import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter, NgModule } from '@angular/core';
import { Employee } from '@pos-common/classes/employee.class';
import { EmployeesProvider } from '@pos-common/services/resources/employees-db-entity.provider';
import { SecurityService } from '@pos-common/services/system/security.service';
import { Observable, map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pos-employee-picker',
  templateUrl: './employee-picker.component.html',
  styleUrls: ['./employee-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeePickerComponent implements OnInit {
  @Output() posClick = new EventEmitter<Employee>();
  public employeeList$: Observable<Employee[]>;
  constructor(private employeesProvider: EmployeesProvider, private securityService: SecurityService) {}

  ngOnInit() {
    this.employeeList$ = this.getEmployee();
  }

  setPicked(employee: Employee = null) {
    this.posClick.emit(employee);
  }

  private getEmployee(): Observable<Employee[]> {
    const activeStore = this.securityService.getActiveStore();
    return this.employeesProvider
      .getListByParams({ deleted: false })
      .pipe(map((employees) => employees.filter((employee) => employee.stores.some((store) => store.uuid === activeStore.uuid))));
  }
}

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [EmployeePickerComponent],
  exports: [EmployeePickerComponent],
})
export class EmployeePickerComponentModule {}
