<pos-header>
  <button tappable (click)="dismiss()" header-start>
    <pos-icon customName="custom-icon-cancel-search"></pos-icon>
  </button>
  <ion-title header-title>{{ 'common_select_guest' | translate }}</ion-title>
  <button (click)="handleDone()" header-end tappable>
    <pos-icon customName="custom-icon-select"></pos-icon>
  </button>
</pos-header>
<ion-content>
  <ion-list class="select-guest-list">
    <ion-item class="add-guest-button" lines="full">
      <pos-add-guest-button (onClick)="createNewGuest()"></pos-add-guest-button>
    </ion-item>
    <ion-item *ngFor="let invoiceEntryGuest of invoiceEntryGuests" lines="full">
      <guest-button
        [isSelected]="invoiceEntryGuest.guestNumber === activeGuestNumber"
        [marker]="invoiceEntryGuest.guestNumber"
        [name]="invoiceEntryGuest.name"
        [isChangeable]="true"
        colorMode="blue"
        (onClick)="selectGuest(invoiceEntryGuest.guestNumber)"
        (onEdit)="editGuest(invoiceEntryGuest.guestNumber)"
      ></guest-button>
      <pos-icon
        *ngIf="invoiceEntryGuests.length > 1"
        customName="custom-icon-paid-invoices-trash"
        slot="end"
        tappable
        (click)="deleteGuest(invoiceEntryGuest.guestNumber)"
        tappable
      ></pos-icon>
    </ion-item>
  </ion-list>
</ion-content>
