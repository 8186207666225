export enum CUSTOMER_EDIT_ACTION_TYPE {
  SELECT,
  EDIT,
  DELETE,
  CANCEL,
  VALID,
  DONE,
  NEW,
  BACK,
}
