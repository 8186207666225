import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { KeyboardService } from '@pos-common/services/system/keyboard/keyboard.service';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';
import { debounceTime } from 'rxjs/operators';
import { IconComponentModule } from '../icon/icon.component';

@Component({
  selector: 'pos-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('search', { static: true }) searchEl: ElementRef;
  @Input() placeholder: string;
  @Input() debounceTime: number = 500;
  @Input() set autofocus(value: boolean) {
    this.setAutofocus(value);
  }
  @Output() onSearch = new EventEmitter<string>();
  @Output() onClearValue = new EventEmitter();

  public searchInput: FormControl = new FormControl('');
  private hasAutofocus = false;

  constructor(
    private subSinkService: SubSinkService,
    private setTimeoutUtil: SetTimeoutUtil,
    private platformService: PlatformService,
    private keyboardService: KeyboardService
  ) {}

  ngOnInit() {
    this.subSinkService.sink = this.searchInput.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe((newSearchValue) => {
      this.onSearch.emit(newSearchValue);
    });
  }

  ngOnDestroy() {
    if (this.keyboardService.isOpen) {
      this.keyboardService.hide();
    }
  }

  clearSearchInput() {
    this.searchInput.reset('');
    this.onClearValue.emit();
  }

  private setAutofocus(autofocus: boolean) {
    if (this.hasAutofocus !== autofocus && autofocus) {
      this.inputFocus();
    }
    this.hasAutofocus = autofocus;
  }

  private inputFocus() {
    this.setTimeoutUtil.waitTimeAndDo(100).then(() => {
      if (this.searchEl.nativeElement) {
        (this.searchEl.nativeElement as HTMLElement).focus();
        this.showKeyboard();
      }
    });
  }

  private showKeyboard() {
    if (this.platformService.isNativePlatform && this.platformService.isAndroid) {
      this.keyboardService.show();
    }
  }
}
@NgModule({
  imports: [ReactiveFormsModule, TranslateModule.forChild(), IconComponentModule],
  declarations: [SearchComponent],
  exports: [SearchComponent],
})
export class SearchComponentModule {}
