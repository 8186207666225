/**
 * Created by y.belinsky on 2/24/17.
 */

import { Injectable } from '@angular/core';
import { PAYMASH_PROFILE } from '@profile';

@Injectable()
export class GoogleAnalyticsService {
  private isStarted: boolean = false;

  constructor() {}

  public startTracker(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (this.isStarted) resolve();
      if (!window.ga) {
        reject();
        return;
      }
      window.ga.startTrackerWithId(
        PAYMASH_PROFILE.gaTrackingCode,
        30,
        () => {
          this.isStarted = true;
          resolve();
        },
        (err) => {
          this.isStarted = false;
          reject(err);
        }
      );
    });
  }

  public trackView(screenName: string): void {
    this.startTracker()
      .then(() => {
        window.ga.trackView(screenName);
      })
      .catch(() => {});
  }

  public trackEvent(
    category: string,
    action: string,
    label?: string,
    value?: string,
    newSession?: boolean,
    successCallback?: Function,
    errorCallback?: Function
  ): void {
    this.startTracker()
      .then(() => {
        window.ga.trackEvent(category, action, label, value, newSession, successCallback, errorCallback);
      })
      .catch(() => {});
  }
}
