// Vendors
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as lodash from 'lodash';
// Common
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { DbDaoService } from '../../services/db/db-dao.service';
import { UpdatesService } from '../../services/system/updates.service';
import { SecurityService } from '../../services/system/security.service';
import { NullifyModalService } from '../../services/system/nullify-modal.service';
import { CartService } from '../../services/system/cart.service';
import { InvoicesFilterService } from '../../services/system/invoice-filter.service';
import { TableEnforceService } from '../../services/system/table-select-enforcement.service';
import { NotifyPopupsService } from '../../services/system/notify-popups.service';

import { scaleElm } from '../../../app/app.animations';
import { LogService } from '../../services/system/logger/log.service';
import { PagesName } from '../../constants/pages-name.enum';
import { first, takeUntil } from 'rxjs/operators';
import { MultipleGuestsService } from '@pos-common/services/system/multiple-guests/multiple-guests.service';
import { ChangeTableTypes } from '@pos-common/constants/change-table-types.enum';
import { InvoicesService } from '@pos-common/services/system/invoices.service';
import { Invoice } from '@pos-common/classes/invoice.class';
import { IChangeTableAlertResult } from '@pos-common/interfaces/change-table-alert-result.interface';
import { ModalService } from '@pos-common/services/system/modal.service';
import { CompanyProperties, SELECT_GUEST_MODAL_ACTION } from '@pos-common/constants';
import { TransferGuestsModalComponent, TransferGuestsModalComponentModule } from '../transfer-guests-modal/transfer-guests-modal.component';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';
import { Swiper } from 'swiper';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { IonSlidesDirectiveModule, RippleEffectDirectiveModule } from '@pos-modules/shared/directives';

@Component({
  selector: 'invoice-filter',
  templateUrl: './invoice-filter.component.html',
  styleUrls: ['./invoice-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [scaleElm()],
  providers: [SubSinkService],
})
export class InvoiceFilter implements OnInit {
  public hallsList = [];
  public currentTableSet = [];
  public activeInvoiceSelects = { hall: null, table: null };
  public currentSelects = { tab: 'open', table: 'all' };
  public selectedOptionName = { hall: null, table: null };
  public isGastroMode: boolean;
  public noTablesTab: boolean = false;
  public activeInvoice: Invoice;
  public isMobile: boolean = false;
  public isTableForce: boolean = false;
  public activateEnforcementTableSetting: boolean = false;
  public noRoomInvoices: boolean = true;
  public slideOpts = {
    slidesPerView: 3,
    initialSlide: 0,
    threshold: 20,
  };
  @Input('onlySet') onlySet: boolean = false;
  @Input('initPage') initPage: string = null;
  @Input('invoiceForTable') invoiceForTable: Invoice = null;
  @Output() tableChanged = new EventEmitter();
  @ViewChild('mySlider') slider: ElementRef;
  private get swiper(): Swiper | undefined {
    const element = this.slider as any;
    return element?.el?.swiper || element?.nativeElement?.swiper;
  }

  constructor(
    public translateService: TranslateService,
    public DbDaoService: DbDaoService,
    public SecurityService: SecurityService,
    public CartService: CartService,
    public InvoicesFilterService: InvoicesFilterService,
    public NotifyPopupsService: NotifyPopupsService,
    public NullifyModalService: NullifyModalService,
    public UpdatesService: UpdatesService,
    private TableEnforceService: TableEnforceService,
    private multipleGuestsService: MultipleGuestsService,
    private invoicesService: InvoicesService,
    private modalService: ModalService,
    private subSinkService: SubSinkService,
    private setTimeoutUtil: SetTimeoutUtil,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.isMobile = window.innerWidth >= 751 ? false : true;
    this.activeInvoice = this.CartService.getActiveInvoice();
    this.isGastroMode = this.SecurityService.getLoggedCompanyData()['isRestaurantEnabled'];
    this.isTableForce = this.TableEnforceService.checkForTableEnforcement();
    this.SecurityService.observableCompanyProperties(CompanyProperties.isRestaurantEnabled, CompanyProperties.tablesEnforced)
      .pipe(takeUntil(this.subSinkService.destroy$))
      .subscribe((company) => {
        this.isGastroModeSwitcher(company.isRestaurantEnabled);
        this.isTableForce = company.tablesEnforced;
      });

    if (this.isGastroMode) {
      this.checkNoRoomTab();
      this.InvoicesFilterService.ivoiceFilterData.pipe(first(), takeUntil(this.subSinkService.destroy$)).subscribe((data) => {
        if (data && data.tab && data.tab !== 'open' && data.tab !== 'paid' && data.tab !== 'no') this.currentSelects.tab = data.tab;
      });
      this.subSinkService.sink = this.CartService.tableChangedEvent.subscribe((data) => {
        if (data) {
          this.checkNoRoomTab();
          this.activeInvoice = this.CartService.getActiveInvoice();
          if (data.table) {
            this.tableToHall(data.table, true);
          } else {
            this.setHall('no');
            if (this.activeInvoice.uuid === data.invoice) this.activeInvoiceSelects = { hall: 'no', table: null };
          }
        }
      });
      this.subSinkService.sink = this.UpdatesService.updatesEmitters[UPDATES_TYPES.GastronomyHall.type].subscribe((data) => {
        if (data) data.forEach((data) => this.handleUpdateData(data.data));
      });
      this.DbDaoService.getAllData(UPDATES_TYPES.GastronomyHall.type)
        .then((data) => {
          if (data && data['data'] && data['data'].length >= 1) {
            this.handleData(data['data']);
            if (this.initPage === PagesName.Invoices) {
              const gastronomyTableUuid = this.invoiceForTable?.gastronomyTable?.uuid || null;
              this.tableToHall(gastronomyTableUuid);
            } else {
              let currentTable = this.CartService.getActiveTableUuid();
              currentTable ? this.tableToHall(currentTable) : this.tableToHall(null);
            }
          } else {
            this.setCustomTab('open');
          }
        })
        .catch((err) => this.logService.error('InvoiceFilter', 'ngOnInit:getAllDataFromCollection', err));
    } else {
      this.setCustomTab('open');
    }
    this.subSinkService.sink = this.InvoicesFilterService.deleteInvoiceEvent().subscribe((data) => {
      if (data && data.active) this.activeInvoiceSelects = { hall: null, table: null };
      if (data && data.uuid) {
        this.checkNoRoomTab();
        this.hallsList.forEach(
          (x) =>
            x.busyTables >= 1 &&
            x.gastronomyTables.forEach((y) => {
              if (y.uuid === data.uuid && y.invoices.length === 1) x.busyTables = x.busyTables - 1;
            })
        );
      }
    });
    this.subSinkService.sink = this.InvoicesFilterService.getUpdateEvent().subscribe(() => {
      this.updateTableBusyStatus();
    });
  }

  trackByFn(index: number, item: any) {
    return item && item.uuid ? item.uuid : index;
  }

  checkNoRoomTab() {
    this.isTableForce && setTimeout(() => this.InvoicesFilterService.getNoRoomInvoices().then((data) => (this.noRoomInvoices = data)), 100);
  }

  handleData(data) {
    this.hallsList = data.filter((h) => !h.deleted).map((hall) => this.busyTables(hall));
    this.updateSlides();
  }

  busyTables(data) {
    data['busyTables'] = null;
    data.gastronomyTables.forEach((table) => {
      if (table.invoices && table.invoices.length >= 1) {
        data.busyTables += 1;
        table.hallId = data.uuid;
      }
    });
    return data;
  }

  handleUpdateData(updateData) {
    //console.log('here UPDATE', updateData)
    let hallIndex = this.hallsList.findIndex((x) => updateData.uuid === x.uuid);
    if (hallIndex !== -1) {
      if (updateData.gastronomyTables) this.hallsList[hallIndex] = this.busyTables(this.hallsList[hallIndex]);
      if (updateData.deleted) {
        if (this.hallsList[hallIndex].uuid === this.currentSelects.tab) {
          this.hallsList.splice(hallIndex, 1);
          this.hallsList.length === 1 ? this.setHall(this.hallsList[0].uuid) : this.setHall('open');
        } else {
          this.hallsList.splice(hallIndex, 1);
          // this.currentSelects.tab === 'open' && this.InvoicesFilterService.getDataFromDb();
        }
      } else {
        if (this.hallsList[hallIndex].gastronomyTables && updateData.gastronomyTables) {
          let current: Array<any> = lodash.sortBy(this.hallsList[hallIndex].gastronomyTables, 'name');
          let updated: Array<any> = lodash.sortBy(updateData.gastronomyTables, 'name');
          current.map((table, i) => {
            if (table.name !== updated[i].name && table.uuid === updated[i].uuid) {
              this.InvoicesFilterService.changeTablesInDb(table.uuid, updated[i].name);
            }
            if (table.deleted !== updated[i].deleted && table.uuid === updated[i].uuid) {
              if (updated[i].deleted) {
                //this.InvoicesFilterService.changeTablesInDb(table.uuid, null);
                this.setTable('all');
              }
            }
          });
        }
        if (updateData.name !== this.hallsList[hallIndex].name) this.hallsList[hallIndex].name = updateData.name;
        this.hallsList[hallIndex].gastronomyTables = lodash.unionBy(
          updateData.gastronomyTables,
          this.hallsList[hallIndex].gastronomyTables,
          'uuid'
        );
        this.hallsList[hallIndex].uuid === this.currentSelects.tab && this.setHall(this.currentSelects.tab, true);
      }
    } else {
      this.hallsList.push(updateData);
    }
    this.checkNoRoomTab();
  }

  setHall(uuid, current?, emit?) {
    this.currentSelects.tab = uuid;
    this.slideOpts.initialSlide = 0;
    if (uuid && uuid !== 'open' && uuid !== 'no' && uuid !== 'paid') {
      if (!current) {
        this.currentSelects.table = 'all';
        this.selectedOptionName.table = null;
      }
      this.currentTableSet = this.hallsList
        .filter((hall) => hall['uuid'] === uuid)
        .map((x) => {
          this.selectedOptionName.hall = x.name;
          return x.gastronomyTables.filter((t) => !t.deleted);
        })
        .reduce((p, c) => p.concat(c));

      this.updateTableBusyStatus();
      const index = this.hallsList.findIndex((hall) => hall.uuid === uuid);
      if (index > -1) {
        this.slideOpts.initialSlide = index;
      }
    } else {
      this.currentTableSet = [];
      this.selectedOptionName.hall = null;
    }
    !emit && this.emitFilterChanges();
  }

  setTable(uuid) {
    this.currentSelects.table = uuid;
    if (uuid && this.currentTableSet.length >= 1) {
      const table = this.currentTableSet.find((x) => x.uuid === uuid);
      this.selectedOptionName.table = table ? table : null;
    } else {
      this.selectedOptionName.table = null;
    }
    this.emitFilterChanges();
  }

  tableToHall(tableUuid, activeCheck = false) {
    if (tableUuid) {
      this.hallsList.forEach((hall) =>
        hall.gastronomyTables.forEach((t) => {
          if (t.uuid === tableUuid) {
            if (!t.hallId) t.hallId = hall.uuid;
            if (!activeCheck) {
              this.activeInvoiceSelects = { hall: t.hallId, table: tableUuid };
              this.setHall(t.hallId, false, true);
            } else {
              if (activeCheck && this.activeInvoice.gastronomyTable && this.activeInvoice.gastronomyTable.uuid === t.uuid) {
                this.activeInvoiceSelects = { hall: t.hallId, table: tableUuid };
              }
              this.currentSelects.tab !== t.hallId && this.setHall(t.hallId, false, true);
            }
            this.setTable(t.uuid);
          }
        })
      );
    } else {
      if (this.hallsList.length === 0) {
        this.currentSelects = { tab: 'open', table: 'all' };
        this.emitFilterChanges();
      } else {
        if (!this.activeInvoice.gastronomyTable && this.activeInvoice.amount > 0) {
          if (this.onlySet) {
            this.setHall(this.hallsList[0].uuid);
          } else {
            this.activeInvoiceSelects = { hall: 'no', table: null };
            this.setHall('no');
          }
        } else {
          this.currentSelects.tab === 'open' ? this.setHall(this.hallsList[0].uuid) : this.setHall(this.currentSelects.tab);
        }
      }
    }
  }

  setCustomTab(param) {
    if (this.isGastroMode && param !== 'paid') {
      if (param === 'open' || param === 'no') this.setHall(param);
    } else {
      this.currentSelects.tab = param;
      this.emitFilterChanges();
    }
  }

  createNewInvoice(length) {
    length === 0 && this.InvoicesFilterService.newTableInvoice();
  }

  async onTableSelected(table) {
    if (!this.onlySet) {
      return;
    }

    if (this.initPage && this.initPage === PagesName.Invoices) {
      this.NullifyModalService.destroyModal();
      this.CartService.setNewTableToInvoice(table, this.invoiceForTable);
      this.NotifyPopupsService.launchNotification('set-table', this.invoiceForTable?.gastronomyTableName);
      return;
    }

    if (this.activeInvoice) {
      this.NullifyModalService.destroyModal();
      const { changeTableType, guestNumber } = await this.openTransferGuestsModal();
      switch (changeTableType) {
        case ChangeTableTypes.INVOICE:
          this.CartService.setNewTableToInvoice(table, this.activeInvoice);
          this.NotifyPopupsService.launchNotification('set-table', this.activeInvoice.gastronomyTableName);
          break;
        case ChangeTableTypes.GUEST:
          const { firstInvoice, secondInvoice } = await this.invoicesService.splitInvoicesByGuest(this.activeInvoice, guestNumber);
          await this.CartService.setCustomerFromGuestToInvoiceCustomer(firstInvoice);
          await this.invoicesService.saveInvoice(firstInvoice);
          await this.CartService.setCustomerFromGuestToInvoiceCustomer(secondInvoice);
          this.CartService.setNewTableToInvoice(table, secondInvoice);
          this.CartService.setActiveInvoice(secondInvoice);
          this.NotifyPopupsService.launchNotification('set-table', secondInvoice.gastronomyTableName);
          break;
      }
    }
  }

  isGastroModeSwitcher(isGastroMode: boolean) {
    this.isGastroMode = isGastroMode;
    if (isGastroMode) {
      this.DbDaoService.getAllData(UPDATES_TYPES.GastronomyHall.type)
        .then((data) => {
          data && data['data'] && data['data'].length >= 1 && this.handleData(data['data']);
        })
        .catch((err) => this.logService.error('InvoiceFilter', 'isGastroModeSwitcher:getAllDataFromCollection', err));
      return;
    }
    this.hallsList = [];
    this.setHall('open');
  }

  emitFilterChanges() {
    setTimeout(() => {
      let sendData = this.currentSelects;
      if (this.isGastroMode) {
        Object.assign(sendData, { tableList: [], entities: this.selectedOptionName });
        if (sendData.table === 'all') sendData['tableList'] = this.currentTableSet.map((x) => x.uuid);
      }
      !this.onlySet && this.InvoicesFilterService.setInvoiceFilter(sendData);
    });
  }

  private openTransferGuestsModal(): Promise<IChangeTableAlertResult> {
    return new Promise(async (resolve) => {
      try {
        const result: IChangeTableAlertResult = {
          changeTableType: ChangeTableTypes.INVOICE,
        };

        const guestInvoiceEntries = this.activeInvoice.getGuestInvoiceEntries();
        if (!this.multipleGuestsService.isMultipleGuests || guestInvoiceEntries.length < 2 || !this.activeInvoice.gastronomyTable) {
          return resolve(result);
        }

        const invoiceEntryGuests = this.multipleGuestsService.getInvoiceEntryGuests(this.activeInvoice.invoiceEntries);
        const transferGuestsModal = await this.modalService.getGuestsModal(TransferGuestsModalComponent, invoiceEntryGuests);

        transferGuestsModal.onDidDismiss().then((value) => {
          const data = value.data || {};
          result.guestNumber = data.activeGuestNumber || 0;
          result.changeTableType = this.getChangeTableTypes(value.role, result.guestNumber);
          resolve(result);
        });

        await transferGuestsModal.present();
      } catch (error) {
        this.logService.error('InvoiceFilter', 'transferInvoicePopup', error);
        resolve({ changeTableType: ChangeTableTypes.CANCEL });
      }
    });
  }

  private getChangeTableTypes(action: string, activeGuestNumber?: number): ChangeTableTypes {
    const hasPartialPayment = activeGuestNumber && this.CartService.checkPartialPaymentInInvoice('checkout_aside_disallow_pay_separately');
    const { GUEST, INVOICE, CANCEL } = ChangeTableTypes;
    if (action === SELECT_GUEST_MODAL_ACTION.SELECT && !hasPartialPayment) {
      if (activeGuestNumber) {
        return GUEST;
      }
      return INVOICE;
    }
    return CANCEL;
  }

  private updateTableBusyStatus() {
    this.currentTableSet.forEach((item) => {
      if (!item.invoices.length) {
        item.isBusy = false;
        return;
      }
      this.InvoicesFilterService.checkTableBusyStatus(item.uuid)
        .then((isBusy) => (item.isBusy = isBusy))
        .catch(() => (item.isBusy = false));
    });
  }

  private updateSlides() {
    this.setTimeoutUtil.waitTimeAndDo().then(() => this.swiper?.update());
  }
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    TransferGuestsModalComponentModule,
    RippleEffectDirectiveModule,
    IonSlidesDirectiveModule,
  ],
  declarations: [InvoiceFilter],
  exports: [InvoiceFilter],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InvoiceFilterModule {}
