/**
 * Created by maksymkunytsia on 8/9/16.
 */

import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import * as Hammer from 'hammerjs';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  constructor() {
    super();
    // FIX for
    // https://github.com/angular/angular/issues/10541
    this.overrides['pinch'] = { enable: false };
    this.overrides['rotate'] = { enable: false };
  }

  buildHammer(element: HTMLElement): any {
    return new Hammer(element, {
      swipe: { direction: Hammer.DIRECTION_ALL },
    });
  }
}
