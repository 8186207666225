import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'pos-list-col',
  templateUrl: './list-col.component.html',
  styleUrls: ['./list-col.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListColComponent implements OnInit {
  @Input() col: string;
  @HostBinding(`class`) classes = '';

  ngOnInit() {
    this.classes = `col col-${this.col}`;
  }
}
