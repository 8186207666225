import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Customer } from '@pos-common/classes/customer.class';
import { IMAGE_SIZES } from '@pos-common/classes/image.class';

@Component({
  selector: 'pos-customer-list-item',
  templateUrl: './customer-list-item.component.html',
  styleUrls: ['./customer-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerListItemComponent implements OnInit {
  @Input() customer: Customer;
  @Input() isActive: boolean = false;
  @Input() showEditButton: boolean;

  @Output() onSelectCustomer = new EventEmitter<Customer>();
  @Output() onEditCustomer = new EventEmitter<Customer>();

  public imageUrl: string = null;

  ngOnInit() {
    this.customer = new Customer(this.customer);
    this.setImage();
  }

  private setImage() {
    if (this.customer.image) {
      this.imageUrl = this.customer.image.getImageUrlBySize(IMAGE_SIZES.NORMAL);
    }
  }

  public selectCustomer(): void {
    this.onSelectCustomer.emit(this.customer);
  }

  public editCustomer(event: MouseEvent) {
    event.stopPropagation();
    this.onEditCustomer.emit(this.customer);
  }
}
