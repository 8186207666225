import { Injectable } from '@angular/core';
import { DbDaoService, GetAllDataOptions } from '../db/db-dao.service';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { forkJoin, Observable } from 'rxjs';
import { Invoice } from '../../classes/invoice.class';
import { DbEntityProvider } from './db-entity.provider';
import { map, first } from 'rxjs/operators';
import { InvoicesService } from '../system/invoices.service';
import { Query } from '@paymash/capacitor-database-plugin';

@Injectable()
export class InvoicesProvider extends DbEntityProvider {
  constructor(dbDaoService: DbDaoService, private invoicesService: InvoicesService) {
    super(UPDATES_TYPES.Invoice.type, dbDaoService);
  }

  getByUuid(uuid: string): Observable<Invoice> {
    return <Observable<Invoice>>this.getEntityByUuid(uuid);
  }

  getList(): Observable<Invoice[]> {
    return <Observable<Invoice[]>>this.getEntitiesList();
  }

  getListByParams(queryParams: Query, options?: GetAllDataOptions): Observable<Invoice[]> {
    return <Observable<Invoice[]>>this.getEntitiesListByParams(queryParams, options).pipe(this.mapPipeTransformEntities());
  }

  getCancelledInvoicesBylUuid(uuid: string): Observable<Invoice[]> {
    return new Observable((observer) => {
      this.dbDaoService
        .getAllData(this.entityType, { 'originalInvoiceReference.uuid': uuid })
        .then((dbResponse) => {
          if (dbResponse.status === 200) {
            const invoices = dbResponse.data.map((data) => this.transformEntity(data));
            observer.next(invoices);
          } else {
            observer.error('no_data');
          }
          observer.complete();
        })
        .catch(() => {
          observer.error('no_data');
          observer.complete();
        });
    });
  }

  getInvoiceWithoutCancelledInvoices(uuid): Observable<Invoice> {
    const observable = forkJoin([this.getByUuid(uuid), this.getCancelledInvoicesBylUuid(uuid)]);
    return observable.pipe(
      map((result) => {
        let [invoice, cancellationInvoices] = result;
        cancellationInvoices.forEach((cancellationInvoice) => {
          invoice = this.invoicesService.subInvoices(invoice, cancellationInvoice);
        });
        invoice.invoiceEntries = invoice.invoiceEntries.filter((invoiceEntry) => invoiceEntry.quantity !== 0);
        return invoice;
      }),
      first()
    );
  }

  getCountByParams(queryParams: Query): Observable<number> {
    return this.getEntitiesCountByParams(queryParams);
  }
}
