import { Injectable } from '@angular/core';
import { SecurityService } from '../system/security.service';
import { LOCALE } from '../../constants/locale.const';
import * as accounting from 'accounting';
import * as moment from 'moment';
import { MathUtils } from './math.utils';

type Format = 'dateFormat' | 'currencyRounding' | 'dateTimeFormat' | 'dateTimeListFormat' | 'dateAt' | 'timeFormat' | '';

@Injectable()
export class LocalizationUtils {
  constructor(private securityService: SecurityService) {}

  getFormattedCurrency(value: any, showCurrency: any, currencyPosition?: any) {
    const currentCompanyLocale = this.securityService.getLoggedCompanyData()['locale'];
    return accounting.formatMoney(
      value,
      showCurrency ? currentCompanyLocale.currency : '',
      2,
      LOCALE.NumberFormat[currentCompanyLocale.numberFormat].thousand,
      LOCALE.NumberFormat[currentCompanyLocale.numberFormat].decimal,
      currencyPosition ? (showCurrency ? '%v %s' : '%s') : showCurrency ? '%s %v' : '%s'
    );
  }

  getRoundedCurrency(value: any) {
    return MathUtils.roundByFinalRoundingSize(
      value,
      LOCALE.CurrencyRounding[this.securityService.getLoggedCompanyData()['locale'].currencyRounding]
    );
  }

  getRoundedFormattedCurrency(value, showCurrency: any, currencyPosition?: any) {
    return this.getFormattedCurrency(this.getRoundedCurrency(value), showCurrency, currencyPosition);
  }

  getFormattedDate(value: any, format: Format) {
    const company = this.securityService.getLoggedCompanyData();
    const { locale: companyLocale } = company;
    const { DateFormat, TimeFormat, DateFormatInvoicesList } = LOCALE;
    switch (format) {
      case 'dateFormat':
        return moment(value).format(DateFormat[companyLocale.dateFormat]);
      case 'currencyRounding':
        return this.getRoundedFormattedCurrency(value, false);
      case 'dateTimeFormat':
        const dateTimeFormat = DateFormat[companyLocale.dateFormat] + ' ' + TimeFormat[companyLocale.timeFormat];
        return moment(value).format(dateTimeFormat);
      case 'dateTimeListFormat':
        const dateTimeListFormat = DateFormatInvoicesList[companyLocale.dateFormat] + ' ' + TimeFormat[companyLocale.timeFormat];
        return moment(value).format(dateTimeListFormat);
      case 'dateAt':
        const dateFormat = moment(value).format(DateFormat[companyLocale.dateFormat]);
        const timeFormat = moment(value).format(TimeFormat[companyLocale.timeFormat]);
        return `${dateFormat} at ${timeFormat}`;
      case 'timeFormat':
        return moment(value).format(TimeFormat[companyLocale.timeFormat]);
      default:
        return value;
    }
  }
}
