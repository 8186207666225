import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Customer } from '@pos-common/classes/customer.class';
import { CUSTOMER_SOURCE } from '@pos-common/constants';
import { ICustomerPageData } from '@pos-common/interfaces/customer-page-data.interface';
import { AppointmentService } from '@pos-common/services/system/appointment/appointment.service';
import { CartService } from '@pos-common/services/system/cart.service';
import { CustomerService } from '@pos-common/services/system/customers.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { ModalService } from '@pos-common/services/system/modal.service';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { ButtonComponentModule, HeaderComponentModule, IconComponentModule } from '@pos-modules/shared/components';
import { CustomerSearchComponentModule } from '../customer-search/customer-search.component';

@Component({
  selector: 'pos-select-customer-modal',
  templateUrl: './select-customer-modal.component.html',
  styleUrls: ['./select-customer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCustomerModalComponent implements OnInit {
  @Input() customer: Customer;
  @Input() source: CUSTOMER_SOURCE = CUSTOMER_SOURCE.INVOICE;
  public showClearButton = false;
  private modal: HTMLIonModalElement;

  constructor(
    private viewController: ModalService,
    private cartService: CartService,
    private customerService: CustomerService,
    private appointmentService: AppointmentService,
    private platformService: PlatformService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.setShowClearButton();
    this.viewController
      .getTop()
      .then((modal) => (this.modal = modal))
      .catch((err) => this.logService.error('SelectCustomerModalComponent', 'ngOnInit:viewController:getTop', err));
  }

  selectCustomer(customer: Customer) {
    this.customer = customer;
  }

  confirmModal() {
    if (this.customer) {
      this.updateCustomer(this.customer);
    }
    this.closeModal();
  }

  removeCustomerFromInvoice() {
    this.updateCustomer(null);
    this.closeModal();
  }

  closeModal() {
    this.modal.dismiss().catch((err) => this.logService.error('SelectCustomerModalComponent', 'closeModal:viewController:dismiss', err));
  }

  createNewCustomer() {
    const data: ICustomerPageData = {
      fromState: this.source === CUSTOMER_SOURCE.APPOINTMENT ? 'Appointment' : undefined,
      actionsType: 'new',
    };
    this.showCustomerPage(data);
  }

  editCustomer(customer: Customer) {
    const data: ICustomerPageData = {
      fromState: this.source === CUSTOMER_SOURCE.APPOINTMENT ? 'Appointment' : undefined,
      customer,
    };
    this.showCustomerPage(data);
  }

  private updateCustomer(customer: Customer) {
    let setCustomer = this.cartService.setCustomer.bind(this.cartService);
    if (this.source === CUSTOMER_SOURCE.APPOINTMENT) {
      setCustomer = this.appointmentService.setCustomer.bind(this.appointmentService);
    }
    setCustomer(customer);
  }

  private setShowClearButton() {
    this.showClearButton = !!this.customer;
  }

  private async showCustomerPage(data: ICustomerPageData) {
    const animated = !this.platformService.isMobile;
    await this.customerService.showCustomerPage(data, animated);
    this.modal.animated = false;
    this.closeModal();
  }
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    HeaderComponentModule,
    IconComponentModule,
    ButtonComponentModule,
    CustomerSearchComponentModule,
  ],
  declarations: [SelectCustomerModalComponent],
  exports: [SelectCustomerModalComponent],
})
export class SelectCustomerModalComponentModule {}
