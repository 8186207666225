/**
 * Created by yaroslav on 9/19/17.
 */
export const SYNC_STATUSES = {
  IN_PROGRESS: true,
  NOT_IN_PROGRESS: false
};

export const SYNC_ITEM_STATES = {
  SYNCHRONIZED: "SYNCHRONIZED",
  NEED_SYNCHRONIZATION: "NEED_SYNCHRONIZATION",
  REMOVED_NEED_SYNCHRONIZATION: "REMOVED_NEED_SYNCHRONIZATION",
  IS_SYNCHRONIZING: "IS_SYNCHRONIZING",
  STOPPED_WITH_ERROR: "STOPPED_WITH_ERROR"
};
