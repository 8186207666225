import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Output,
  ViewEncapsulation,
  EventEmitter,
  Input,
  OnInit,
  ElementRef,
  Renderer2,
  NgModule,
} from '@angular/core';
import { IconComponentModule } from '@pos-modules/shared/components';
import { BadgeComponentModule } from '../badge/badge.component';

type ColorModeType = 'grey' | 'blue';

@Component({
  selector: 'guest-button',
  templateUrl: './guest-button.component.html',
  styleUrls: ['./guest-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestButtonComponent implements OnInit {
  @Input() showCheckbox = true;
  @Input() isSelected = false;
  @Input() marker: string | number = '';
  @Input() position = 0;
  @Input() name = '';
  @Input() isChangeable: boolean = false;
  @Input() isDisabled = false;
  @Input() colorMode: ColorModeType = 'grey';

  @Output() onClick = new EventEmitter();
  @Output() onEdit = new EventEmitter();

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setColorMode();
  }

  selectGuest() {
    this.onClick.emit();
  }

  editGuest(event: MouseEvent) {
    this.onEdit.emit();
    event.stopPropagation();
  }

  private setColorMode() {
    if (this.isDisabled) {
      this.colorMode = 'grey';
    }
    this.renderer.addClass(this.element.nativeElement, `${this.colorMode}-mode`);
  }
}

@NgModule({
  imports: [CommonModule, IconComponentModule, BadgeComponentModule],
  declarations: [GuestButtonComponent],
  exports: [GuestButtonComponent],
})
export class GuestButtonComponentModule {}
