import { Injectable } from '@angular/core';
import { ActionSheetController, ActionSheetOptions } from '@ionic/angular';

@Injectable()
export class ActionSheetService {
  private actionSheet: HTMLIonActionSheetElement = null;
  constructor(private actionSheetController: ActionSheetController) {}

  async create(opts?: ActionSheetOptions) {
    const actionSheet = await this.actionSheetController.create(opts);
    this.actionSheet = actionSheet;
    return actionSheet;
  }

  dismiss() {
    if (this.actionSheet) {
      this.actionSheet.dismiss();
    }
  }
}
