export enum CoreFields {
  CompanyUuid = 'company.uuid',
  CompanyId = 'company.id',
  Device = 'device',
  AppVersion = 'app.version',
  System = 'system',
  AppEnv = 'app.env',
  PosSystemUuid = 'pos.system.uuid',
  DeviceFingerprint = 'device.fingerprint',
}
