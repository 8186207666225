<div class="search-container" [class.not-empty]="searchInput.value.length > 0">
  <input type="text" #search [formControl]="searchInput" [value]="" />
  <span class="search-placeholder">
    <pos-icon customName="search-icon"></pos-icon>
    <span>{{ placeholder | translate }}</span>
  </span>
  <a class="search-button-clear" (click)="clearSearchInput()" tappable>
    <pos-icon ionName="close-circle"></pos-icon>
  </a>
</div>
