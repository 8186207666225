import { Product as ProductEntity } from '@pos-common/classes/product.class';
import { ILogFormatter, LogFormatterOptions } from '@spryrocks/logger';

export class Product implements ILogFormatter<Product> {
  public static fromProductEntity(product: ProductEntity, image: string | undefined): Product {
    const uuid = product.uuid;

    const name = product.name;

    let price = product.price;
    let strikePrice = product.isSale ? product.wasPrice : undefined;

    let firstVariant: Object | undefined;
    if (product.variants.length > 0) {
      firstVariant = product.variants[0];
    }
    let variantName: string | undefined;
    if (firstVariant && firstVariant['options'].length > 0) {
      variantName = firstVariant['options'][0].name;
    }

    const highestPrice = product.highestPrice;

    return new Product(
      uuid, name, image, variantName, price, strikePrice, highestPrice,
    );
  }

  constructor(
    public readonly uniqueIdentifier: string,
    public readonly name: string,
    public readonly image: string | undefined,
    public readonly variantName: string | undefined,
    public readonly price: number,
    public readonly strikePrice: number | undefined,
    public readonly highestPrice: number | undefined,
  ) {
  }

  get logFormatter(): LogFormatterOptions<Product> {
    return {
      excludeFields: [
        'image',
      ],
    }
  }
}
