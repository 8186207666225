import { IDriver } from '../IDriver';
import { Device, ConnectionInfo } from './Device';

export class WebDemoDriver extends IDriver<Device, ConnectionInfo> {
  constructor() {
    super();
  }

  async connect() {
  }

  async disconnect(): Promise<void> {
  }

  async sendMessage(message: object) {
  }

  setOnError(callback: (error: Error) => void) {}

  setOnMessageReceived(callback: (message: object) => void) {
  }

  setOnClosed(callback: () => void): void {}
}
