import { NgForOf } from '@angular/common';
import { Directive, Host, Input, NgModule } from '@angular/core';

@Directive({
  selector: '[ngForTrackByProperty]',
})
export class NgForTrackByPropertyDirective {
  @Input('ngForTrackByProperty') propertyName = '';

  constructor(@Host() readonly ngFor: NgForOf<any>) {
    ngFor.ngForTrackBy = (index: number, item: any) => (this.propertyName ? item[this.propertyName] : index);
  }
}
@NgModule({
  declarations: [NgForTrackByPropertyDirective],
  exports: [NgForTrackByPropertyDirective],
})
export class NgForTrackByPropertyDirectiveModule {}
