import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ReceiptPrinter } from '../../classes/receipt-printer.class';
import { MyPosPrinterService } from './my-pos-printer.service';
import { PrinterType } from '../../enum/printer-type.enum';

@Injectable()
export class MyPosPrinterMockService extends MyPosPrinterService {
  private static cordovaMocks(): ReceiptPrinter[] {
    return [
      new ReceiptPrinter({
        deviceType: PrinterType.MyPosMini,
        deviceName: 'myPosMini',
        printerNameForModelPick: 'mini',
        ipAddress: '10.0.1.10',
        macAddress: '00:01:90:C2:BB:7C',
        target: 'TCP:00:01:90:C2:BB:7C',
      }),
    ];
  }

  protected browserMockPerform(): void {
    if (this.myPosService.isMyPosTerminalAndPrinter) {
      this.handleDiscover(MyPosPrinterMockService.cordovaMocks());
      return;
    }
    this.handleDiscover([]);
  }

  protected discover(): Observable<ReceiptPrinter[]> {
    this.browserMockPerform();
    return this.printerList;
  }
}
