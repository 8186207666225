<ion-item-group>
  <div class="label-caption">{{ 'settings_device' | translate }}</div>
  <ion-item>
    <span class="label">{{ 'settings_app_info_version' | translate }}</span>
    <span class="value" slot="end">{{ appInfo.APP_VERSION + ' (' + appInfo.APP_BUILD + ')' }}</span>
  </ion-item>
  <ion-item>
    <span class="label">{{ 'settings_app_info_date' | translate }} </span>
    <span class="value" slot="end">{{ appInfo.APP_DATE }}</span>
  </ion-item>
  <ion-item (click)="showWeakDevicesPage()">
    <span class="label">{{ 'weak_device_attention_you_device_performance' | translate }} </span>
    <span class="value" slot="end">{{ currentDeviceScore }} <i class="fi flaticon-arrows arr-forward"></i></span>
  </ion-item>
  <ion-item class="has-action item-language-settings">
    <span class="label">{{ 'settings_app_info_language' | translate }}</span>
    <span class="value" slot="end">{{ activeLanguage?.name }} <i class="fi flaticon-arrows arr-forward"></i></span>
    <select [value]="activeLanguage | json" (change)="updateSelectedValue($event)">
      <option *ngFor="let item of languagesList; trackBy: trackByFn" [value]="item | json" [selected]="activeLanguage === item">
        {{ item.name }}
      </option>
    </select>
  </ion-item>
  <ion-item [hidden]="serverConfig.APP_ENV === 'production'">
    <span>Site</span>
    <input
      type="text"
      class="wide-debug-input"
      [(ngModel)]="debugAleternateApiInput"
      (ngModelChange)="debugAleternateApiInputChanged($event)"
      slot="end"
    />
  </ion-item>
  <ion-item class="has-action item-language-settings">
    <span class="label">{{ 'settings_invoices_keep_duration' | translate }}</span>
    <span class="value" slot="end"
      >{{ invoicesKeepDuration }} {{ 'common_days' | translate }} <i class="fi flaticon-arrows arr-forward"></i
    ></span>
    <select [value]="invoicesKeepDuration" (change)="updateInvoicesKeepDuration($event)">
      <option
        *ngFor="let item of invoicesKeepDurationOptions; trackBy: trackByFn"
        [value]="item"
        [selected]="invoicesKeepDuration === item"
      >
        {{ item }}
      </option>
    </select>
  </ion-item>
</ion-item-group>
<ion-item-group>
  <div class="label-caption">{{ 'settings_payments' | translate }}</div>
  <div class="list-group">
    <ion-item class="list-item">
      <ion-label>{{ 'settings_on_invoice_payment_label' | translate }}</ion-label>
      <ion-toggle [checked]="onInvocePaymentStatus" slot="end" (ionChange)="onInvocePaymentStatusChange($event)"></ion-toggle>
    </ion-item>
    <ion-item class="list-item">
      <ion-label>{{ 'settings_one_click_payment_label' | translate }}</ion-label>
      <ion-toggle [checked]="oneClickPaymentStatus" slot="end" (ionChange)="oneClickPaymentStatusChanged($event)"></ion-toggle>
    </ion-item>
    <ion-item class="has-action item-language-settings" *ngIf="oneClickPaymentStatus">
      <span class="label">{{ 'settings_one_click_payment_default_payment_method' | translate }}</span>
      <span class="value" slot="end">{{ defaultPaymentMethod?.name | translate }} <i class="fi flaticon-arrows arr-forward"></i></span>
      <select [ngModel]="defaultPaymentMethod | json" (ngModelChange)="defaultPaymentMethodChanged($event)">
        <option *ngFor="let item of paymentMethodsList; trackBy: trackByFn" [value]="item | json">{{ item.name | translate }}</option>
      </select>
    </ion-item>
  </div>
</ion-item-group>
<ion-item-group>
  <div class="label-caption">{{ 'settings_multiple_guests' | translate }}</div>
  <div class="list-group">
    <ion-item class="list-item">
      <ion-label>{{ 'settings_multiple_guests_label' | translate }}</ion-label>
      <ion-toggle [checked]="multipleGuestsStatus" slot="end" (ionChange)="multipleGuestsStatusChanged($event)"></ion-toggle>
    </ion-item>
  </div>
</ion-item-group>
<ion-item-group>
  <div class="label-caption">{{ 'settings_tipping' | translate }}</div>
  <div class="list-group">
    <ion-item class="list-item">
      <ion-label>{{ 'settings_tipping_label' | translate }}</ion-label>
      <ion-toggle [checked]="tippingStatus" slot="end" (ionChange)="tippingStatusChanged($event)"></ion-toggle>
    </ion-item>
    <ion-item class="has-action item-language-settings" *ngIf="tippingStatus" (click)="openTippingSearchModal()" tappable>
      <span class="label">{{ 'settings_tipping_default_product' | translate }}</span>
      <span class="value" slot="end">{{ defaultTipProduct?.name | translate }} <i class="fi flaticon-arrows arr-forward"></i></span>
    </ion-item>
  </div>
</ion-item-group>
