import { Injectable, Injector } from '@angular/core';
import { PrinterServiceInterface } from '@pos-common/interfaces/printer-service.interface';
import { Observable } from 'rxjs';
import { ReceiptPrinter } from '../../classes/receipt-printer.class';
import { CommonPrinterService } from '../common-printer.service';
import { MyPosService } from '@pos-common/services/system/devices/my-pos/my-pos.service';
import { PrinterType } from '../../enum/printer-type.enum';
import { PAYMENT_PROVIDERS } from '@pos-common/constants/payment-providers.const';
import { MYPOS } from '@pos-common/constants/mypos.const';

@Injectable()
export class MyPosPrinterService extends CommonPrinterService implements PrinterServiceInterface {
  protected myPosService: MyPosService;
  constructor(injector: Injector) {
    super(injector);
    this.myPosService = injector.get(MyPosService);
  }

  protected get pluginName() {
    return '';
  }

  protected get discoverMethodName() {
    return '';
  }

  protected get stopDiscoverMethodName(): string {
    return '';
  }

  protected get printMethodName(): string {
    return '';
  }

  protected logsSubscription() {
    return;
  }

  protected discover(): Observable<ReceiptPrinter[]> {
    this.getPrinter();
    return this.printerList;
  }

  protected stopDiscover() {
    return Promise.resolve();
  }

  public checkPermissionsOnAndroid(): Promise<boolean> {
    return Promise.resolve(true);
  }

  private getPrinter() {
    const { hasMyPosPrinter, isMyPosTerminalAndPrinter } = this.myPosService;

    if (hasMyPosPrinter) {
      let deviceType = PrinterType.MyPos;
      let modelName = this.myPosService.getModelName();
      if (isMyPosTerminalAndPrinter) {
        deviceType = PrinterType.MyPosMini;
        modelName = 'mini';
      }

      const myPosPrinter = new ReceiptPrinter({
        deviceName: PAYMENT_PROVIDERS.MYPOS,
        deviceType,
        ipAddress: MYPOS.IP_ADDRESS,
        isActive: true,
        macAddress: MYPOS.MAC_ADDRESS,
        printerNameForModelPick: modelName,
        target: MYPOS.TARGET,
      });

      this.handleDiscover([myPosPrinter]);
      return;
    }
    this.handleDiscover([]);
  }
}
