import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  Output,
  OnInit,
  NgModule,
} from '@angular/core';
import { by, contains, notEquals, query } from '@paymash/capacitor-database-plugin';
import { Customer } from '@pos-common/classes/customer.class';
import { PAGINATION } from '@pos-common/constants/pagination.const';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { SearchUtils } from '@pos-common/services';
import { CustomersProvider } from '@pos-common/services/resources';
import { CustomerService } from '@pos-common/services/system/customers.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { UpdatesService } from '@pos-common/services/system/updates.service';
import {
  ButtonComponentModule,
  IconComponentModule,
  ImageLoaderCapComponentModule,
  InfiniteScrollBaseComponent,
  LoaderComponentModule,
  SearchComponentModule,
} from '@pos-modules/shared/components';
import { Observable, forkJoin, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CustomerListItemComponent } from './customer-list-item/customer-list-item.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { PersonPlaceholderModule } from '../person-placeholder/person-placeholder.component';
import { ILoadDataOptions } from '@pos-common/interfaces';

type LoadInterface = 'button' | 'scroll';

@Component({
  selector: 'pos-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class CustomerSearchComponent extends InfiniteScrollBaseComponent<Customer> implements OnInit {
  @Input() selectedCustomerUuid: string;
  @Input() showEditButton = false;
  @Input() loadInterface: LoadInterface = 'scroll';
  @Input() loadMoreCount = PAGINATION.CUSTOMERS_ITEMS_COUNT;
  @Input() useUpdate = false;

  @Output() onSelectCustomer = new EventEmitter<Customer>();
  @Output() onEditCustomer = new EventEmitter<Customer>();
  @Output() noItems = new EventEmitter<boolean>();

  private activeCustomerUuid: string;

  constructor(
    protected cdr: ChangeDetectorRef,
    private customersProvider: CustomersProvider,
    private customerService: CustomerService,
    private searchUtils: SearchUtils,
    private subSinkService: SubSinkService,
    private updatesService: UpdatesService
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.subscribeForUpdate();
    this.activeCustomerUuid = this.selectedCustomerUuid || null;
    super.ngOnInit();
  }

  handleSearchValue(value: string): void {
    value = this.searchUtils.replaceForSearch(value);
    this.searchValue = value;
    super.handleSearchValue(value);
  }

  clearSearch() {
    this.showLoader();
  }

  protected loadData({ start, end, searchValue }: ILoadDataOptions): Promise<any[]> {
    const queryParams = query({ deleted: false });
    const isStartQuery = start === 0 && !searchValue;
    let activeCustomerObservable: Observable<Customer> = of(null);
    if (this.activeCustomerUuid) {
      queryParams.push({ uuid: notEquals(this.activeCustomerUuid) });
      if (isStartQuery) {
        activeCustomerObservable = this.customersProvider.getByUuid(this.activeCustomerUuid);
      }
    }
    if (searchValue) {
      queryParams.push({ fieldToSearch: contains(searchValue) });
    }
    const options = {
      offset: start,
      limit: end,
      order: [by('fieldToSort')],
    };
    return forkJoin([activeCustomerObservable, this.customersProvider.getListByParams(queryParams, options)])
      .toPromise()
      .then(([activeCustomer, customers]) => {
        const isNoItems = isStartQuery && !customers.length && !activeCustomer;
        this.noItems.emit(isNoItems);
        const hasActiveCustomer = activeCustomer && !activeCustomer.deleted;
        if (hasActiveCustomer && isStartQuery) {
          customers = [activeCustomer, ...customers];
        }
        this.isLoading = false;
        return customers;
      });
  }

  selectCustomer(customer: Customer) {
    if (this.selectedCustomerUuid !== customer.uuid) {
      this.selectedCustomerUuid = customer.uuid;
      this.onSelectCustomer.emit(customer);
      this.cdr.detectChanges();
    }
  }

  editCustomer(customer: Customer) {
    this.onEditCustomer.emit(customer);
  }

  private subscribeForUpdate() {
    if (!this.useUpdate) {
      return;
    }
    this.updatesService
      .getUpdateEmmiterByType(UPDATES_TYPES.Customer.type)
      .pipe(
        takeUntil(this.subSinkService.destroy$),
        filter((response) => !!response?.length)
      )
      .subscribe(() => {
        this.updateList();
      });
    this.customerService
      .getUpdate()
      .pipe(takeUntil(this.subSinkService.destroy$))
      .subscribe(() => {
        this.updateList();
      });
  }

  private updateList() {
    const end = this.loadMoreCount * this.activePage + this.loadMoreCount;
    this.updateItems(0, end);
  }
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    SearchComponentModule,
    ButtonComponentModule,
    LoaderComponentModule,
    IconComponentModule,
    PersonPlaceholderModule,
    ImageLoaderCapComponentModule,
  ],
  declarations: [CustomerSearchComponent, CustomerListItemComponent, CustomerListComponent],
  exports: [CustomerSearchComponent],
})
export class CustomerSearchComponentModule {}
