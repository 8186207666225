<div class="common-invoice-entry">
  <div
    (click)="handleOnClick($event)"
    (swipe)="handleOnSwipe($event)"
    [class.discounted]="invoiceEntry.discount || invoiceEntry.discountPercentage"
    tappable
  >
    <div class="img-holder"></div>
    <div class="entry-name" [class.with-note]="noteText || invoiceEntry.specialTaxing === specialTaxing.takeAway">
      <span class="name" style="-webkit-box-orient: vertical">{{ invoiceEntry.name }}</span>
      <span *ngIf="noteText" class="note" style="-webkit-box-orient: vertical">{{ noteText }}</span>
    </div>
    <pos-badge
      *ngIf="showUnprintedKitchenItems && invoiceEntry.quantityForKitchenReceipt"
      class="unprinted-kitchen-items-indicator"
      [value]="invoiceEntry.quantityForKitchenReceipt"
    ></pos-badge>
    <div class="item-info">
      <p class="item-total">{{ invoiceEntry.totalAmount | CustomCurrency: false }}</p>
      <p class="item-quantity-price" [attr.amount]="invoiceEntry.quantity + 'x'">
        <span class="item-price"> {{ invoiceEntry.discountedPrice | CustomCurrency: false }}</span>
      </p>
    </div>
  </div>
</div>
