/**
 * Created by maksymkunytsia on 6/29/16.
 */

import { of as observableOf, Subscription, combineLatest, timer } from 'rxjs';
// Vendors
import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavController, ViewDidEnter, ViewWillEnter } from '@ionic/angular';
// Pages/components
import { Product } from '../../common/classes/product.class';
import { Invoice } from '../../common/classes/invoice.class';
import { UPDATES_TYPES } from '../../common/constants/updates-types.const';
import { UpdatesService } from '../../common/services/system/updates.service';
import { DbDaoService } from '../../common/services/db/db-dao.service';
import { ModalService } from '../../common/services/system/modal.service';
import { PRODUCT_TYPES } from '../../common/constants/product-types';
import { CartService } from '../../common/services/system/cart.service';
import { CustomerService } from '../../common/services/system/customers.service';
import { ProductVariant } from '../../common/classes/product-variant.class';
import { AlertService } from '../../common/services/system/alert.service';
import { SecurityService } from '../../common/services/system/security.service';
import { TableEnforceService } from '../../common/services/system/table-select-enforcement.service';
import { SocketScannerService } from '../../common/services/system/socket-scanner/socket-scanner.service';
import { ScannerAppService } from '../../common/services/system/scanner-app.service';
import { CameraScannerService } from '../../common/services/system/camera-scanner.service';
import { GoogleAnalyticsService } from '../../common/services/system/google-analitycs.service';
import { CollectionViewService } from '../../common/services/system/collection-view.service';
import { InvoicesService } from '../../common/services/system/invoices.service';
import { LoadingService } from '../../common/services/system/loading.service';
import { Customer } from '../../common/classes/customer.class';
import { CHECKOUT_STEPS } from '../../common/constants/checkout-steps.const';
import { PosInfoService } from '../../common/services/system/pos-info.service';
import { InvoicesFilterService } from '../../common/services/system/invoice-filter.service';
import { NullifyModalService } from '../../common/services/system/nullify-modal.service';
import { InvoiceFilter } from '@pos-common/components/invoice-filter/invoice-filter.component';
import { TranslateService } from '@ngx-translate/core';
import { BarcodeScannerService } from '../../common/services/system/barcode-scanner.service';
import { LogService } from '../../common/services/system/logger/log.service';
import { PrintingQueueItem, PrintingQueueItemStatus } from '../../common/services/system/receipt-printers/classes/receipt-printer.class';
import { PrinterService } from '../../common/services/system/printer.service';
import { LocalStorage } from '../../common/services/utils/localstorage.utils';
import { StorageKeys } from '../../common/constants/storage.const';
import { filter, mergeMap } from 'rxjs/operators';
import { RouteNavigationService } from '../../common/services/system/route-navigation/route-navigation.service';
import { ROUTE_URLS } from '../../common/constants/route-urls.const';
import { INullifyModalOptionsStyles } from '../../common/interfaces/nullify-modal.interface';
import { PagesName } from '../../common/constants/pages-name.enum';
import { SubSink } from 'subsink';
import { Keyboard } from '@pos-common/classes/capacitor-plugins/capacitor-plugins.class';
import { MyPosService } from '@pos-common/services/system/devices/my-pos/my-pos.service';
import { MultipleGuestsService } from '@pos-common/services/system/multiple-guests/multiple-guests.service';
import { BarcodeScannerTypes } from '@pos-common/constants/barcode-scanner-types.enum';
import { BarcodeScanningStatus } from '@pos-common/constants/barcode-scanning-status.enum';
import { SelectCustomerModalComponent } from '@pos-common/components/select-customer-modal/select-customer-modal.component';
import { PreloadNextModules } from '@pos-common/services/system';

@Component({
  selector: 'collection-view-page',
  styleUrls: ['collection-view.page.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'collection-view.page.html',
})
export class CollectionViewPage implements OnInit, OnDestroy, ViewDidEnter, ViewWillEnter {
  public activeInvoice: Invoice = null;
  // search variables
  public inventorySearchActive: boolean = false;
  public singleClickMade = false;
  public isGastroMode = false;
  public isMultipleGuests = false;
  public isMobile = false;
  public isMyPosHubDevice = false;
  public socketScannerConnected: boolean = false;
  public scannerAppConnected: boolean = false;
  public productVariantIsActive: boolean = false;
  public customerData: Customer = null;
  public isBarcodeScannerOpened: boolean = false;
  public invoiceCount: number = 0;
  public isDisabledCreateNewInvoice = false;
  public iPosScannerEvent: Subscription;
  public showUnprintedKitchenItems = false;
  private printingFailureAlert: HTMLIonAlertElement;
  private fromState = '';
  private subs = new SubSink();
  private subsScanner = new SubSink();

  constructor(
    public navController: NavController,
    public UpdatesService: UpdatesService,
    public DbDaoService: DbDaoService,
    public CartService: CartService,
    public CustomerService: CustomerService,
    public AlertService: AlertService,
    public TranslateService: TranslateService,
    public GoogleAnalyticsService: GoogleAnalyticsService,
    public SecurityService: SecurityService,
    public ModalService: ModalService,
    public TableEnforceService: TableEnforceService,
    public ScannerAppService: ScannerAppService,
    public CameraScannerService: CameraScannerService,
    public CollectionViewService: CollectionViewService,
    public InvoicesService: InvoicesService,
    public elementRef: ElementRef,
    public InvoicesFilterService: InvoicesFilterService,
    public LoadingService: LoadingService,
    public NullifyModalService: NullifyModalService,
    public PosInfoService: PosInfoService,
    public printerService: PrinterService,
    private socketScannerService: SocketScannerService,
    private localStorage: LocalStorage,
    private myPosService: MyPosService,
    private multipleGuestsService: MultipleGuestsService,
    private preloadNextModules: PreloadNextModules,
    private logService: LogService,
    private routeNavigationService: RouteNavigationService,
    private barcodeScannerService: BarcodeScannerService
  ) {}

  ngOnInit() {
    this.isMyPosHubDevice = this.myPosService.isMyPosHubDevice;
    this.subsScanner.sink = this.CameraScannerService.getBarcodeScannerOpenedStateEvent()
      .pipe(filter(() => this.isBarcodeScannerOpened))
      .subscribe((data) => (this.isBarcodeScannerOpened = data));

    const showWeakDevicePage = this.PosInfoService.showWeakDevicePage();
    if (showWeakDevicePage) {
      this.navController
        .navigateForward(ROUTE_URLS.weakDevice)
        .catch((err) => this.logService.error('CollectionViewPage', 'constructorNavController:push:WeakDevice', err));
      this.PosInfoService.setWeakDevicePageIsShowedStatus();
    }

    this.subs.sink = this.TableEnforceService.openChangeTableEvent.subscribe(() => this.changeTable());
    this.LoadingService.hideLoadingItem();
    this.CollectionViewService.setCurrentSidebarSlide(CHECKOUT_STEPS.CHECKOUT);
    this.GoogleAnalyticsService.trackView('CollectionViewPage');
    this.subs.sink = this.UpdatesService.updatesEmitters[UPDATES_TYPES.Invoice.type].subscribe(() => {
      this.reCountOpenInvoices();
    });
    this.subs.sink = this.UpdatesService.updatesEmitters[UPDATES_TYPES.Company.type].subscribe(() => {
      this.setGastroModeState(this.SecurityService.getLoggedCompanyData()['isRestaurantEnabled']);
      this.TableEnforceService.setTableEnforcementState(this.isGastroMode && this.SecurityService.getLoggedCompanyData()['tablesEnforced']);
      if (!this.isGastroMode || !this.SecurityService.getLoggedCompanyData()['tablesEnforced']) this.NullifyModalService.destroyModal();
      this.showUnprintedKitchenItems = this.isGastroMode && this.printerService.getShowUnprintedKitchenItems();
    });
    this.activeInvoice = this.CartService.getActiveInvoice();
    this.activeInvoice ? (this.customerData = this.activeInvoice.customer) : this.CartService.createInvoice(true);
    this.subs.sink = this.CartService.activeInvoiceUpdated.subscribe((invoice) => {
      this.activeInvoice = new Invoice(invoice);
      this.customerData = invoice.customer;
      this.reCountOpenInvoices();
      this.isDisabledCreateNewInvoice = this.getIsDisabledNewInvoice();
    });
    this.setGastroModeState(this.SecurityService.getLoggedCompanyData()['isRestaurantEnabled']);
    this.TableEnforceService.setTableEnforcementState(this.isGastroMode && this.SecurityService.getLoggedCompanyData()['tablesEnforced']);
    this.subs.sink = this.CollectionViewService.getMobileConfirmationStateEmitter().subscribe((data) => {
      data
        ? this.elementRef.nativeElement.classList.add('checkout-mobile')
        : this.elementRef.nativeElement.classList.remove('checkout-mobile');
    });
    this.isMobile = window.innerWidth < 751;
    this.subs.sink = this.CollectionViewService.getProductVariantModalDataChangeEmitter().subscribe((data) => {
      setTimeout(() => (this.productVariantIsActive = !!data), 100);
    });
    const { fromState } = this.routeNavigationService.data;
    this.fromState = fromState || '';
    this.isMultipleGuests = this.multipleGuestsService.isMultipleGuests;
    this.showUnprintedKitchenItems = this.isGastroMode && this.printerService.getShowUnprintedKitchenItems();
    this.isDisabledCreateNewInvoice = this.getIsDisabledNewInvoice();
    this.preloadNextModules.preloadNextPages(ROUTE_URLS.collection);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ionViewWillEnter() {
    this.CollectionViewService.setCalculateCellParameters();
  }

  private setGastroModeState(isGastroMode: boolean): void {
    this.isGastroMode = isGastroMode;
    this.isGastroMode ? this.elementRef.nativeElement.classList.add('gastro') : this.elementRef.nativeElement.classList.remove('gastro');
  }

  ionViewWillLeave() {
    this.subsScanner.unsubscribe();
    this.CameraScannerService.closeBarcodeScanner();
    if (this.iPosScannerEvent) {
      this.iPosScannerEvent.unsubscribe();
    }
    this.barcodeScannerService.stopScanners();
  }

  ionViewDidEnter() {
    this.reCountOpenInvoices();
    if (this.isGastroMode) {
      if (this.activeInvoice.gastronomyTable) {
        this.InvoicesFilterService.checkTableExist(this.activeInvoice.gastronomyTable.uuid)
          .then((check) => {
            if (!check) {
              this.CartService.setNewTableToInvoice(null, this.activeInvoice);
              if (this.fromState === 'Invoices')
                setTimeout(() => this.SecurityService.getLoggedCompanyData()['tablesEnforced'] && this.changeTable(), 300);
            }
          })
          .catch((err) => this.logService.error('CollectionViewPage', 'ionViewDidEnter:checkTableExist', err));
      } else {
        if (this.fromState === 'Invoices')
          setTimeout(() => this.SecurityService.getLoggedCompanyData()['tablesEnforced'] && this.changeTable(), 300);
      }
    }

    this.LoadingService.hideLoadingItem();
    if (this.localStorage.get(StorageKeys.socketScannerConnectedKey) === 'true') {
      this.socketScannerService.startObserve();
    }
    this.subsScanner.sink = this.barcodeScannerService.newBarcodeEvent.subscribe((barcode) => this.scannerReceivedBarcode(barcode));
    this.subsScanner.sink = this.CameraScannerService.newBarcodeEvent
      .pipe(filter(() => this.isBarcodeScannerOpened))
      .subscribe((barcode) => this.scannerReceivedBarcode(barcode));

    this.subsScanner.sink = this.socketScannerService.onConnected().subscribe(() => {
      this.updateSocketScannerConnectionState();
    });

    this.subsScanner.sink = this.socketScannerService.onDisconnected().subscribe(() => {
      this.updateSocketScannerConnectionState();
    });
    this.updateSocketScannerConnectionState();

    this.scannerAppConnected = !!this.ScannerAppService.activeScanner;
    this.subsScanner.sink = this.ScannerAppService.activeScannerChangedEvent.subscribe((data) => {
      this.scannerAppConnected = !!data;
    });
    this.subsScanner.sink = combineLatest([
      this.printerService.getPrintingQueueWithFilter(PrintingQueueItemStatus.printingFailed),
      timer(0, 60000),
    ])
      .pipe(mergeMap((results) => observableOf(results[0])))
      .subscribe((items: any) => this.handlePrintingFailure(items));
  }

  private reCountOpenInvoices() {
    this.InvoicesFilterService.getInvoiceCountFromDB()
      .then((count) => (this.invoiceCount = count))
      .catch((err) => this.logService.error('CollectionViewPage', 'reCountOpenInvoices:getInvoiceCountFromDB', err));
  }

  public scannerReceivedBarcode(barcode: string) {
    if (this.TableEnforceService.checkForTableEnforcementAndShowAlert()) return;
    this.addProductToCart(barcode)
      .then((isAdded) => {
        this.logService.debug('CollectionViewPage', `scannerReceivedBarcode:addProductToCart:isAdded - ${BarcodeScanningStatus[isAdded]}`);
        const scannerProperties = {
          message: 'common_barcode_unknown_code_message',
          duration: 3000,
          success: false,
        };
        switch (isAdded) {
          case BarcodeScanningStatus.ADDED:
            scannerProperties.message = 'common_barcode_product_added';
            scannerProperties.duration = 1500;
            scannerProperties.success = true;
            break;
          case BarcodeScanningStatus.FOUNDED_MULTI:
            scannerProperties.message = 'barcode_is_used_multiple';
            scannerProperties.duration = 1500;
            break;
          case BarcodeScanningStatus.NOT_VISIBLE:
            scannerProperties.message = 'common_barcode_not_visible';
            break;
          case BarcodeScanningStatus.NOT_AVAILABLE_IN_STORE:
            scannerProperties.message = 'common_barcode_not_available_in_store';
            break;
        }
        const { message, duration, success } = scannerProperties;
        const translate = this.TranslateService.instant(message, { barcode });
        this.CameraScannerService.showBarcodeScannerMsg(translate, duration, success);
      })
      .catch((err) => this.logService.error('CollectionViewPage', 'scannerReceivedBarcode:addProductToCart', err));
  }

  public toggleInventorySearch() {
    this.inventorySearchActive = !this.inventorySearchActive;
  }

  public closeScannerManually() {
    if (this.isBarcodeScannerOpened) this.CameraScannerService.closeBarcodeScanner();
  }

  public inventorySearchClosed($event: any) {
    if (window.cordova) {
      Keyboard.hide().catch((error) => this.logService.error('CollectionViewPage', 'inventorySearchClosed:Keyboard:hide', error));
    }
    this.toggleInventorySearch();
  }

  public async showCustomersModal() {
    if (this.singleClickMade || this.CartService.checkPartialPaymentInInvoice()) {
      return;
    }
    // TODO: It should be fixed on version 2 of the guest concept
    if (this.isMultipleGuests) {
      return;
    }
    this.singleClickMade = true;

    try {
      const customerModal = await this.ModalService.presentModal(SelectCustomerModalComponent, { customer: this.customerData });
      await customerModal.present();
    } catch (error) {
      this.logService.error('CartComponent', 'openGuestDetailsModal:ModalService:present', error);
    }
    this.singleClickMade = false;
  }

  public openBarcodeScanner() {
    if (this.TableEnforceService.checkForTableEnforcementAndShowAlert()) return;
    this.isBarcodeScannerOpened = true;
    this.CameraScannerService.setBarcodeScannerType(BarcodeScannerTypes.Paymash);
    const selector = 'inventory .inventory-wrapper';
    this.CameraScannerService.openBarcodeScanner(document.querySelector(selector));
  }

  public addProductToCart(eanCode: string): Promise<BarcodeScanningStatus> {
    this.logService.debug('CollectionViewPage', 'addProductToCart:eanCode ' + eanCode);
    return new Promise((resolve) => {
      this.DbDaoService.getAllData(UPDATES_TYPES.ProductVariant.type, { eanCode }).then((data) => {
        if (!data || !data.data || !data.data[0]) {
          return resolve(BarcodeScanningStatus.NOT_FOUND);
        }
        if (data.data.length > 1) {
          return resolve(BarcodeScanningStatus.FOUNDED_MULTI);
        }
        const variant: ProductVariant = data.data[0];
        this.DbDaoService.getAllData(UPDATES_TYPES.Product.type, { uuid: variant.productUuid }).then((result) => {
          if (!result || !result.data || !result.data[0]) {
            return resolve(BarcodeScanningStatus.NOT_FOUND);
          }
          const product = new Product(result.data[0]);
          const activeStore = this.SecurityService.getActiveStore();
          if (activeStore?.uuid) {
            const hasCurrentStore = product.storeUuids.some((storeUuid) => storeUuid === activeStore.uuid);
            if (!hasCurrentStore) {
              return resolve(BarcodeScanningStatus.NOT_AVAILABLE_IN_STORE);
            }
          }
          let productCategory = product.productCategoriesUuid.split(',')[0];
          productCategory = productCategory === 'root' ? null : productCategory;
          let productShortName = '';
          if (product.hasCustomOptions) {
            productShortName = this.generateVariantShortName(variant, ', ');
          }
          const newEntryData = this.InvoicesService.makeInvoiceEntryToAddData(variant, product, productShortName, productCategory);
          newEntryData.image = this.CartService.getEntryImage(product, variant);
          this.CartService.addEntry(newEntryData, PRODUCT_TYPES.PRODUCT, 1);
          if (!product.visible) {
            return resolve(BarcodeScanningStatus.NOT_VISIBLE);
          }
          resolve(BarcodeScanningStatus.ADDED);
        });
      });
    });
  }

  public openInvoices() {
    this.navController.navigateRoot(ROUTE_URLS.invoices).catch((err) => this.logService.error('CollectionViewPage', 'openInvoices', err));
  }

  public getActiveTableName(maxLength: number) {
    let tableName = this.CartService.getActiveTableName();
    if (!tableName) return;
    return tableName.length < maxLength ? tableName : tableName.slice(0, maxLength - 3) + '...' + tableName.slice(-4);
  }

  public customerBlockSwipe($event) {
    if ($event.direction === 2) {
      this.CartService.setCustomer(null);
    } else if ($event.direction === 4) {
      this.showCustomersModal();
    }
  }

  public generateVariantShortName(variant: ProductVariant, separator: string) {
    let variantShortName = '';
    for (let i = 0; i < variant.options.length; i++) {
      let current = variant.options[i];
      variantShortName += current['value'] + separator;
    }
    return variantShortName.slice(0, -separator.length);
  }

  public createNewInvoice() {
    if (this.activeInvoice.invoiceEntries.length !== 0) {
      if (this.isMultipleGuests) {
        this.multipleGuestsService.reset();
      }
      this.CartService.createInvoice();
      if (this.isGastroMode && this.SecurityService.getLoggedCompanyData()['tablesEnforced']) {
        setTimeout(() => this.changeTable(), 100);
      }
    }
  }

  public changeTable() {
    const styles: INullifyModalOptionsStyles = {
      top: '70px',
      right: 0,
      maxWidth: '80%',
      marginRight: '20px',
      marginLeft: '20px',
    };
    this.NullifyModalService.initModal({
      component: InvoiceFilter,
      options: { styles, arrAbove: true, delayRender: 150, className: 'collection-invoice-filter' },
      inputs: [{ onlySet: true }, { initPage: PagesName.CollectionView }],
    });
  }

  private async handlePrintingFailure(items: PrintingQueueItem[]) {
    if (!this.printingFailureAlert && items.length > 0) {
      this.printingFailureAlert = await this.AlertService.create({
        header: this.TranslateService.instant('common_error'),
        message: this.TranslateService.instant('printing_queue_printing_failure_message'),
        buttons: [
          {
            text: this.TranslateService.instant('common_cancel'),
          },
          {
            text: this.TranslateService.instant('common_continue'),
            handler: () => {
              this.navController
                .navigateForward(ROUTE_URLS.printerQueue)
                .catch((err) => this.logService.error('CollectionView', 'handlePrintingFailure', err));
            },
          },
        ],
      });
      this.printingFailureAlert.onDidDismiss().then(() => (this.printingFailureAlert = null));
      await this.printingFailureAlert
        .present()
        .catch((err) => this.logService.error('CollectionView', 'handlePrintingFailure:present', err));
    }
  }

  private updateSocketScannerConnectionState() {
    this.socketScannerConnected = this.socketScannerService.connectedDevices.length !== 0;
  }

  public createNewGuest() {
    this.CartService.addNewGuest();
  }

  private getIsDisabledNewInvoice(): boolean {
    if (!this.activeInvoice) {
      return false;
    }
    let hasInvoiceEntries = this.activeInvoice.invoiceEntries.length === 0;
    if (this.multipleGuestsService.isMultipleGuests && !hasInvoiceEntries) {
      hasInvoiceEntries = this.activeInvoice.invoiceEntries.length === 1 && this.activeInvoice.hasGuestInvoiceEntry();
    }
    return hasInvoiceEntries;
  }
}
