import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Migration as CoreMigration } from './core/Migration';
import { MigrationBase } from './core/MigrationBase';

export const Migrations = new InjectionToken<(typeof MigrationBase)[]>("migrations")

@Injectable()
export class Migration extends CoreMigration {
  public constructor(injector: Injector) {
    super(injector)
  }

  protected get migrations() {
    return this.injector.get(Migrations);
  }
}
