import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stripeHtml' })
export class StripeHtmlPipe implements PipeTransform {
  transform(value: string): string {
    try {
      const div = document.createElement('div');
      div.innerHTML = value;
      return div.textContent || div.innerText || '';
    } catch (e) {
      return value;
    }
  }
}
@NgModule({
  declarations: [StripeHtmlPipe],
  exports: [StripeHtmlPipe],
})
export class StripeHtmlPipeModule {}
