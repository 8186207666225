/**
 * Created by y.belinsky on 2/21/18.
 */


export function ConvertDataForRequest(): MethodDecorator {
  return function (target: Function, key: string, descriptor: any) {

    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const dataForAdjustment = args[2];
      if (dataForAdjustment) {
        if (dataForAdjustment instanceof FormData) return originalMethod.apply(this, args);
        let newData: Object = {};
        let dataKeys: string[] = Object.getOwnPropertyNames(dataForAdjustment);
        dataKeys.forEach((key: string) => {
          if (key.substring(0, 1) === '_') {
            newData[key.substring(1)] = dataForAdjustment[key];
          } else {
            newData[key] = dataForAdjustment[key];
          }
        });
        args[2] = newData;
      }
      return originalMethod.apply(this, args);
    };

    return descriptor;
  }
}
