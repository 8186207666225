<ion-content>
  <ion-item *ngIf="paidInvoiceOnDemandInProgress && currentFilterSet.tab === 'paid' && !!itemList.length" class="loading-invoices">
    <div class="loading-invoices-text">
      <ion-spinner name="lines"></ion-spinner>
      <ion-label>{{ 'invoices_loading' | translate }}...</ion-label>
    </div>
  </ion-item>
  <ion-list *ngIf="!isLoading" #ionList class="ion-no-padding ion-no-margin">
    <pos-invoice-list-item
      *ngFor="let item of itemList; trackByUuid"
      [item]="item"
      [isGastroMode]="isGastroMode"
      [tableChangeAnimUuid]="tableChangeAnimUuid"
      [activeInvoiceUuid]="activeInvoice?.uuid"
      [isOpenTab]="currentFilterSet?.tab === 'open'"
    ></pos-invoice-list-item>

    <no-invoices *ngIf="!itemList.length"></no-invoices>
  </ion-list>

  <pos-loader class="loader-contaner" [isLoading]="isLoading"></pos-loader>

  <ion-infinite-scroll threshold="40%" (ionInfinite)="loadMore($event)" [disabled]="isLoadMore">
    <ion-infinite-scroll-content loadingSpinner="crescent"> </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<pos-invoice-list-footer
  *ngIf="!!itemList.length && currentFilterSet?.tab !== 'paid'"
  [currentFilterSet]="currentFilterSet"
  [isGastroMode]="isGastroMode"
  [isTableForce]="isTableForce"
  [isDisabledMerge]="itemList.length < 2"
  [hasInvoices]="!!itemList.length"
></pos-invoice-list-footer>
