import {
  Component,
  ElementRef,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { InvoiceEntry, SpecialTaxing } from '../../../../common/classes/invoice-entry.class';
import { ImageLoaderService } from 'ionic-image-loader-v5';
import { CollectionViewService } from '../../../../common/services/system/collection-view.service';
import { IMAGE_SIZES } from '../../../../common/classes/image.class';
import { LogService } from '../../../../common/services/system/logger/log.service';
import { ImageUtils } from '../../../../common/services/utils/image.utils';
import { ChangeDetectorRef } from '@angular/core';
import { GiftCardProvider } from '@pos-modules/assing-gift-card/gift-card.provider';

@Component({
  selector: 'cart-invoice-entry',
  styleUrls: ['../common-invoice-entry.scss', 'cart-invoice-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'cart-invoice-entry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartInvoiceEntryComponent implements OnInit {
  private _invoiceEntry: InvoiceEntry;
  @Input() set invoiceEntry(value: InvoiceEntry) {
    this._invoiceEntry = new InvoiceEntry(value);
    this.updateInvoiceEntry();
  }
  get invoiceEntry() {
    return this._invoiceEntry;
  }
  private _showUnprintedKitchenItems: boolean;
  @Input() set showUnprintedKitchenItems(value: boolean) {
    this._showUnprintedKitchenItems = value || false;
    this.cdr.detectChanges();
  }
  get showUnprintedKitchenItems() {
    return this._showUnprintedKitchenItems;
  }
  @Output() onClick = new EventEmitter();
  @Output() onSwipe = new EventEmitter();
  public noteText: string;
  public specialTaxing = SpecialTaxing;
  private imageUrl: string;
  private isImageActive: boolean = true;

  constructor(
    private elementRef: ElementRef,
    private imageLoaderService: ImageLoaderService,
    private imageUtils: ImageUtils,
    private cdr: ChangeDetectorRef,
    private collectionViewService: CollectionViewService,
    private giftCardServise: GiftCardProvider,
    private renderer: Renderer2,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.isImageActive = this.collectionViewService.getRenderImagesStatus();
    this.invoiceEntry = new InvoiceEntry(this.invoiceEntry);
    this.setValues();
  }

  handleOnClick(event: any) {
    this.onClick.emit(event);
  }

  handleOnSwipe(event: any) {
    this.onSwipe.emit(event);
  }

  private setValues(): void {
    this.setNotes();
    this.setImage();
  }

  private updateInvoiceEntry() {
    this.setValues();
    this.cdr.detectChanges();
  }

  private setNotes() {
    const code = this.invoiceEntry.giftCardCode || this.invoiceEntry.giftCardPhysicalCode;
    const giftCardCode = this.giftCardServise.makeCardObfuscation(code);
    let noteText = '';
    if (this.invoiceEntry.note) {
      noteText += this.invoiceEntry.note;
    }
    if (giftCardCode) {
      noteText += ` ${giftCardCode}`;
    }
    this.noteText = noteText;
  }

  private setImage() {
    const imgHolder: any = this.elementRef.nativeElement.getElementsByClassName('img-holder')[0];
    if (this.invoiceEntry.image && this.isImageActive) {
      let imageUrl = this.invoiceEntry.image.getImageUrlBySize(IMAGE_SIZES.SMALL);
      if (this.imageUrl === imageUrl) {
        return;
      }
      this.imageUrl = imageUrl;
      imageUrl = imageUrl.replace('?height=300', '?height=100');
      this.imageLoaderService
        .getImagePath(imageUrl)
        .then((path) => {
          path = path.replace(/(\r\n|\n|\r)/gm, '');
          path = this.imageUtils.convertFileSrc(path);
          this.renderer.setStyle(imgHolder, 'background-image', `url("${path}")`);
        })
        .catch((err) => this.logService.error('InvoiceEntryComponent', 'setImageimageLoader:getImagePath:imageUrl' + imageUrl, err));
    } else {
      if (this.imageUrl === imgHolder.style.backgroundImage) {
        return;
      }
      const defaultImageUrl = "url('/assets/images/icons/svg/placeholder.svg')";
      this.renderer.setStyle(imgHolder, 'background-image', defaultImageUrl);
      this.imageUrl = defaultImageUrl;
    }
  }
}
