import { Injectable } from '@angular/core';
import { PAYMASH_PROFILE } from '@profile';
import * as S3 from 'aws-sdk/clients/s3';
import { LogService } from './logger/log.service';
import * as moment from 'moment';
import { FileEntry } from '@ionic-native/file/ngx';
import { FileService } from './file';

@Injectable()
export class AwsService {
  constructor(private logService: LogService, private fileService: FileService) {}

  public sendData(pathOnAws: string, fileName: string, data: Blob): Promise<any> {
    return new Promise((resolve, reject) => {
      const awsOptions = PAYMASH_PROFILE.ASW;
      const { accessKeyId, secretAccessKey, region } = awsOptions;
      const bucket = new S3({
        accessKeyId,
        region,
        secretAccessKey,
        correctClockSkew: true,
      });
      const params: S3.PutObjectRequest = {
        Body: data,
        Bucket: awsOptions.bucket,
        Key: `pos/${pathOnAws}${fileName}`,
      };

      const request = bucket.putObject(params);
      request.on('httpError', (err) => {
        reject(err);
      });
      request.send((err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  public sendLocalFile(pathOnAws: string, fileLocalPath: string, directory: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const fileName = fileLocalPath.substring(1);
      this.getFileByPath(fileLocalPath, directory)
        .then((file) => this.fileService.readFileInternalAsync(file))
        .then((fileData) => this.fileService.createBlob(fileData, 'application/json'))
        .then((fileData) => this.sendData(pathOnAws, fileName, fileData))
        .then(resolve)
        .catch(reject);
    });
  }

  public getFileByPath(filePath: string, directory: string): Promise<FileEntry> {
    return new Promise((resolve, reject) => {
      if (window.cordova) {
        this.fileService.resolveDirectoryAsync(directory)
          .then((directory) => this.fileService.getFileAsync(directory, filePath))
          .then(resolve)
          .catch(reject);
      } else {
        reject('cordova_unavailable');
      }
    });
  }

  public makeAwsLink(companyId: string | number, platform: string, deviceUuid: string, folder: string): string {
    const today = moment().format('YYYY-MM-DD');
    const time = new Date().getTime();
    return `company/${companyId}/pos-system/${today}/${platform}/${deviceUuid}/${folder}/${time}/`;
  }

  public sendFilesToAws(files: FileEntry[], pathOnAws: string, directory: string, callback?: Function): void {
    if (files.length > 0) {
      const file: FileEntry = files.shift();
      this.logService.debug('AwsService:sendLogsViaAWS:getLogsFilesList:forEach:item', JSON.stringify(file));
      this.sendLocalFile(pathOnAws, file.fullPath, directory)
        .then(() => {
          this.logService.debug('AwsService', `sendLocalFile: ${file.fullPath} - success`);
          if (files.length === 0) {
            callback();
          } else {
            this.sendFilesToAws([...files], pathOnAws, directory, callback);
          }
        })
        .catch((err) => {
          callback(err);
          this.logService.error('AwsService', `sendLocalFile: ${file.fullPath}`, err);
        });
    }
  }
}
