import { BehaviorSubject, Subject } from "rxjs";
import { PrintingQueueItem } from "../services/system/receipt-printers/classes/receipt-printer.class";
import {scan} from "rxjs/operators";

export enum PrintingQueueActionType {
  UPSERT_ITEM = 'UPSERT_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM'
}

export interface PrintingQueueAction {
  type: PrintingQueueActionType;
  payload: PrintingQueueItem;
}

export class PrintingQueueStore extends BehaviorSubject<PrintingQueueItem[]> {
  private dispatcher: Subject<PrintingQueueAction>;

  constructor(initialState: PrintingQueueItem[]) {
    super(initialState);
    this.dispatcher = new Subject<PrintingQueueAction>();
    this.dispatcher
        .pipe(scan((state: PrintingQueueItem[], action: PrintingQueueAction) => this.reducer(state, action), initialState))
        .subscribe(state => super.next(state));
  }

  private reducer(state: PrintingQueueItem[], action: PrintingQueueAction) {
    let newState = [...state];
    switch (action.type) {
      case PrintingQueueActionType.UPSERT_ITEM:
        let itemExist = false;
        newState.forEach(i => {
          if (i.uuid === action.payload.uuid) {
            itemExist = true;
            i.printingStatus = action.payload.printingStatus;
            i.printingTriesCount = action.payload.printingTriesCount;
          }
        });
        if (!itemExist) {
          newState.push(action.payload);
        }
        break;
      case PrintingQueueActionType.REMOVE_ITEM:
        newState = state.filter(i => i.uuid !== action.payload.uuid);
        break;
    }
    return newState;
  }


  public getState = (): PrintingQueueItem[] => this.value;

  public dispatch = (action: PrintingQueueAction): void => this.dispatcher.next(action);

}
