import { MathUtils } from '../../services/utils/math.utils';
import { PayCalculatorValue } from './pay-calculator-value.class';
import { PayCalculatorTypes } from '../../constants/pay-calculator-types.enum';

export class PayCalculator {
  private values = new Map<number, PayCalculatorValue>();
  isTipping: boolean = false;

  constructor() {
    this.setPayValues();
  }

  private setPayValues() {
    const keys = Object.keys(PayCalculatorTypes);
    keys.forEach((key, index) => this.values.set(index, new PayCalculatorValue()));
  }

  set total(decimal: number) {
    this.setDecimal(decimal, PayCalculatorTypes.Total);
  }

  get total() {
    return this.getDecimal(PayCalculatorTypes.Total);
  }

  get totalByTipping() {
    if (this.isTipping) {
      return this.totalAndTip;
    }
    return this.total;
  }

  set change(decimal: number) {
    this.setDecimal(decimal, PayCalculatorTypes.Change);
  }

  get change() {
    return this.getDecimal(PayCalculatorTypes.Change);
  }

  set givenCents(cents: number) {
    this.setGiven(cents);
  }

  set given(decimal: number) {
    const cents = this.getCentsFromDecimal(decimal);
    this.setGiven(cents);
  }

  private setGiven(cents: number) {
    this.setValueByCents(cents, PayCalculatorTypes.Given);
    this.calcChangeDif(this.change);
  }

  get givenCents() {
    return this.getGivenValue().cents;
  }

  get given() {
    return this.getGivenValue().decimal;
  }

  getGivenValue() {
    return this.getValue(PayCalculatorTypes.Given);
  }

  set givenForTipping(decimal: number) {
    const cents = this.getCentsFromDecimal(decimal);
    this.setGivenForTipping(cents);
  }

  private setGivenForTipping(cents: number) {
    this.setValueByCents(cents, PayCalculatorTypes.GivenForTipping);
  }

  get givenForTipping() {
    return this.getGivenForTippingValue().decimal;
  }

  getGivenForTippingValue() {
    return this.getValue(PayCalculatorTypes.GivenForTipping);
  }

  set tipCents(cents: number) {
    this.setValueByCents(cents, PayCalculatorTypes.Tip);
    this.calcTotalAndTip();
  }

  set tip(decimal: number) {
    const cents = this.getCentsFromDecimal(decimal);
    this.setValueByCents(cents, PayCalculatorTypes.Tip);
    this.calcTotalAndTip();
  }

  get tip() {
    return this.getTipValue().decimal;
  }

  getTipValue() {
    return this.getValue(PayCalculatorTypes.Tip);
  }

  get totalAndTip() {
    return this.getValue(PayCalculatorTypes.TotalAndTip).decimal;
  }

  set totalAndTipCents(cents: number) {
    this.setValueByCents(cents, PayCalculatorTypes.TotalAndTip);
  }

  set totalAndTip(decimal: number) {
    const cents = this.getCentsFromDecimal(decimal);
    this.setValueByCents(cents, PayCalculatorTypes.TotalAndTip);
  }

  getTotalAndTipValue() {
    return this.getValue(PayCalculatorTypes.TotalAndTip);
  }

  private setDecimal(decimal: number, type: PayCalculatorTypes) {
    const value = this.values.get(type);
    value.decimal = decimal;
    this.values.set(type, value);
  }

  private getDecimal(type: PayCalculatorTypes) {
    const value = this.values.get(type);
    return value.decimal;
  }

  private getValue(type: PayCalculatorTypes) {
    return this.values.get(type);
  }

  private setValueByCents(cents: number, type: PayCalculatorTypes) {
    const tempCents = cents.toString();
    const value = this.getValue(type);
    value.cents = parseFloat(tempCents);
    value.decimal = parseInt(tempCents) / 100;
    this.values.set(type, value);
  }

  private getCentsFromDecimal(decimal: number) {
    return MathUtils.roundHalfUp(decimal * 100, 2);
  }

  resetGiven() {
    this.givenCents = 0;
    this.change = 0;
  }

  calcTotalAndTip() {
    const totalAndTip = MathUtils.normalizeAddition(this.total, this.tip);
    this.totalAndTip = totalAndTip;
    this.given = totalAndTip;
  }

  calcTip() {
    const tip = MathUtils.normalizeAddition(this.totalAndTip, -this.total);
    this.tip = tip;
  }

  calcChangeDif(change: number) {
    let { total } = this;
    if (this.isTipping && this.totalAndTip > 0 && this.totalAndTip >= total) {
      total = this.totalAndTip;
    }
    const difGivenAndTotal = MathUtils.roundHalfUp(this.given - total, 2);
    const tempChange = MathUtils.normalizeAddition(difGivenAndTotal, change);
    if (!tempChange || tempChange <= 0) {
      this.change = 0;
      return;
    }
    this.change = tempChange;
  }
}
