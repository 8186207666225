import { CommonModule } from '@angular/common';
import {
  Component,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  Inject,
  forwardRef,
  ElementRef,
  ComponentRef,
  Renderer2,
  ViewEncapsulation,
  RendererStyleFlags2,
  NgModule,
} from '@angular/core';
import { DomController } from '@ionic/angular';
import { INullifyInitModal, INullifyModalOptions, INullifyModalOptionsStyles } from '@pos-common/interfaces';
import { NullifyModalService } from '@pos-common/services/system/nullify-modal.service';

@Component({
  selector: '<nullify-modal></nullify-modal>',
  styleUrls: ['nullify-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'nullify-modal.component.html',
  host: {
    '[class.vertical-center]': 'verticalCenter',
    '[class.not-mobile-fullscreen]': 'notMobileFullScreen',
    '[class.backdrop-active]': 'backdrop',
  },
})
export class NullifyModal {
  @ViewChild('backdrop', { static: true }) backdropEl: ElementRef;
  @ViewChild('content', { read: ViewContainerRef, static: true }) content: ViewContainerRef;
  @ViewChild('contentWrapper', { static: true }) contentWrapper: ElementRef;
  public verticalCenter: boolean = false;
  public notMobileFullScreen: boolean = false;
  public backdrop: boolean = false;
  public closeBlocking: boolean = false;

  private subComponent: ComponentRef<{}>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private renderer2: Renderer2,
    private domController: DomController,
    @Inject(forwardRef(() => NullifyModalService)) private NullifyModalService: NullifyModalService
  ) {}

  proceedInit(params: INullifyInitModal) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(params.component);
    this.subComponent = this.viewContainerRef.createComponent(factory);
    params.inputs && params.inputs.map((input) => Object.assign(this.subComponent.instance, input));
    this.content.insert(this.subComponent.hostView);

    this.setOptions(params.options);
  }

  closeModal() {
    !this.closeBlocking && this.NullifyModalService.destroyModal();
  }

  forceCloseModal() {
    this.NullifyModalService.destroyModal();
  }

  //Do not remove this method!
  subComponentDestroy() {
    this.subComponent.destroy();
  }

  private setOptions(options: INullifyModalOptions) {
    if (!options) {
      return;
    }
    this.domController.write(() => {
      if (window.innerWidth <= 750) {
        this.renderer2.addClass(this.contentWrapper.nativeElement, 'isMobile');
      }
      if (options.styles) {
        this.setStyles(options.styles);
      }
      if (typeof options.arrAbove === 'boolean') {
        this.renderer2.addClass(this.contentWrapper.nativeElement, options.arrAbove ? 'before' : 'after');
      }
      if (typeof options.arrLeft === 'boolean' && options.arrLeft) {
        this.renderer2.addClass(this.contentWrapper.nativeElement, 'arrowLeft');
      }
      this.verticalCenter = options.verticalCenter ?? false;
      this.notMobileFullScreen = options.notMobileFullScreen ?? false;
      this.closeBlocking = options.closeBlocking ?? false;
      if (options.className) {
        if (options.className.indexOf(' ') !== -1) {
          let classes = options.className.split(' ');
          classes.map((className) => this.renderer2.addClass(this.contentWrapper.nativeElement.parentNode, className));
        } else {
          this.renderer2.addClass(this.contentWrapper.nativeElement.parentNode, options.className);
        }
      }
      setTimeout(() => {
        this.backdrop = options.backdrop ?? false;
        const zIndex = (this.contentWrapper.nativeElement as HTMLElement).style.zIndex;
        if (zIndex) {
          const backdropIndex = parseInt(zIndex) - 1;
          this.renderer2.setStyle(this.backdropEl.nativeElement, 'zIndex', backdropIndex);
        }
      }, 1);
    });
  }

  private setStyles(styles: INullifyModalOptionsStyles) {
    Object.keys(styles).forEach((cssRule) => {
      let cssValue = styles[cssRule];
      let flags = undefined;
      if (typeof cssValue === 'string') {
        const cssValues = cssValue.split(' ');
        cssValue = cssValues.filter((css) => css !== '!important').join(' ');
        flags = cssValues.includes('!important') ? RendererStyleFlags2.Important : flags;
      }
      this.renderer2.setStyle(this.contentWrapper.nativeElement, cssRule, cssValue, flags);
    });
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [NullifyModal],
  exports: [NullifyModal],
})
export class NullifyModalModule {}
