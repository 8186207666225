import { GiftCardServiceInterface } from './gift-card.service.interface';
import { GiftCard } from './gift-card';
import { Observable } from 'rxjs';
import { IGiftCardValidators } from './interfaces/giftCardValidators.interface';

export abstract class GiftCardProvider implements GiftCardServiceInterface {
  abstract openAssignGiftCardModal(giftCardCode: string, validators?: IGiftCardValidators, isAssign?: boolean): Promise<GiftCard>;
  abstract getGiftCardByCode(code: string): Observable<GiftCard>;
  abstract getGiftCardByPhysicalCode(physicalCode: string): Observable<GiftCard>;
  abstract isOffline(): boolean;
  abstract makeCardObfuscation(value: string, showStars?: boolean, defaultSing?: string, addBrackets?: boolean): string;
}
