import { NgModule } from '@angular/core';
import { CalendarFilterGroupComponent } from './calendar-filter-group/calendar-filter-group.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CalendarFilterItemComponent } from './calendar-filter-item/calendar-filter-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DateComponentModule } from '../date/date.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponentModule } from '../icon/icon.component';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule.forChild(), ReactiveFormsModule, DateComponentModule, IconComponentModule],
  declarations: [CalendarFilterGroupComponent, CalendarFilterItemComponent],
  exports: [CalendarFilterGroupComponent, CalendarFilterItemComponent],
})
export class CalendarFilterModule {}
