export enum SunmiDeviceModel {
  T2s = 'T2s',
  V2sPlus = 'V2sPlus',
  V2s = 'V2s',
  Unknown = 'Unknown',
}

export enum SunmiPrinterWidth {
  w40mm,
  w58mm,
  w80mm,
}
