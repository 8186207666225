<h3 class="modal-title" *ngIf="onlySet">{{ 'assign_invoice_to_the_table' | translate }}</h3>

<div class="wrapper" [class.only-set]="onlySet && hallsList.length">
  <div class="mobile-hall-switcher" *ngIf="isMobile && isGastroMode">
    <div
      *ngIf="!onlySet"
      class="modile-paid-invoice"
      (click)="setCustomTab('paid')"
      [class.active]="currentSelects.tab === 'paid'"
      class="paid-invoice-tab t-all"
      ripple-effect
    >
      {{ 'invoice_status_paid' | translate }}
    </div>
    <swiper-container #mySlider posIonSlides [slideOptions]="slideOpts" class="swiper-container">
      <swiper-slide
        *ngIf="!onlySet"
        [class.active]="currentSelects.tab === 'open'"
        (click)="setCustomTab('open')"
        class="t-all swiper-slide"
        ripple-effect
      >
        {{ 'invoice_status_open' | translate }}
      </swiper-slide>
      <swiper-slide
        *ngIf="(!onlySet && !isTableForce) || (!onlySet && isTableForce && noRoomInvoices)"
        [class.active]="currentSelects.tab === 'no'"
        (click)="setCustomTab('no')"
        class="t-all swiper-slide"
        ripple-effect
      >
        {{ 'no_room' | translate }}
      </swiper-slide>
      <swiper-slide
        *ngFor="let hall of hallsList; trackBy: trackByFn"
        [ngClass]="{ active: currentSelects.tab === hall.uuid, selected: hall.uuid === activeInvoiceSelects.hall }"
        (click)="setHall(hall.uuid)"
        class="t-all swiper-slide"
        ripple-effect
      >
        <div class="slide-name">{{ hall.name }}</div>
      </swiper-slide>
      <swiper-slide *ngIf="onlySet && !isTableForce" (click)="onTableSelected(null)" class="t-all swiper-slide">
        {{ 'no_room' | translate }}
      </swiper-slide>
    </swiper-container>
  </div>

  <div class="halls-switcher">
    <ul class="open-invoices" *ngIf="!onlySet && hallsList.length === 0">
      <li class="paid-open-tab t-all" (click)="setCustomTab('open')" [class.active]="currentSelects.tab === 'open'" ripple-effect>
        {{ 'invoice_status_open' | translate }}
      </li>
    </ul>
    <ul class="gastro-invoices" *ngIf="isGastroMode && hallsList.length >= 1">
      <!-- <li *ngIf="!onlySet && hallsList.length >= 2" [class.active]="currentSelects.tab === 'open'" (click)="setCustomTab('open')" class="t-all">{{'invoice_status_open' |  translate}}</li> -->
      <li *ngIf="!onlySet" [class.active]="currentSelects.tab === 'open'" (click)="setCustomTab('open')" class="t-all" ripple-effect>
        {{ 'invoice_status_open' | translate }}
      </li>
      <li
        *ngIf="(!onlySet && !isTableForce) || (!onlySet && isTableForce && noRoomInvoices)"
        [class.active]="currentSelects.tab === 'no'"
        [class.selected]="activeInvoiceSelects.hall === 'no'"
        (click)="setCustomTab('no')"
        class="t-all"
        ripple-effect
      >
        {{ 'no_room' | translate }}
      </li>
      <ng-container *ngFor="let hall of hallsList; trackBy: trackByFn">
        <li
          *ngIf="hall.gastronomyTables.length !== 0"
          [ngClass]="{ active: currentSelects.tab === hall.uuid, selected: hall.uuid === activeInvoiceSelects.hall }"
          (click)="setHall(hall.uuid)"
          class="t-all"
          ripple-effect
        >
          {{ hall.name }}
          <!-- <span class="invoice-count" *ngIf="hall.busyTables && hall.busyTables !== 0" @scaleElm>{{hall.busyTables}}</span> -->
        </li>
      </ng-container>
      <li *ngIf="onlySet && !isTableForce" (click)="onTableSelected(null)" class="t-all" ripple-effect>{{ 'no_room' | translate }}</li>
    </ul>
    <ul class="paid-invoices" *ngIf="!onlySet">
      <li (click)="setCustomTab('paid')" [class.active]="currentSelects.tab === 'paid'" class="paid-invoice-tab t-all" ripple-effect>
        {{ 'invoice_status_paid' | translate }}
      </li>
    </ul>
  </div>

  <div
    class="tables-switcher"
    *ngIf="isGastroMode && hallsList.length >= 1 && currentTableSet.length >= 1 && currentSelects.tab !== 'paid'"
  >
    <div
      *ngIf="!onlySet && currentTableSet.length >= 1"
      (click)="setTable('all')"
      [class.active]="currentSelects.table === 'all'"
      class="all-tables t-all"
      ripple-effect
    >
      {{ 'all_tables' | translate }}
    </div>
    <ul>
      <li
        *ngIf="!onlySet && currentTableSet.length >= 1"
        (click)="setTable('all')"
        [class.active]="currentSelects.table === 'all'"
        class="mobile-all-tables t-all"
        ripple-effect
      >
        {{ 'all_tables' | translate }}
      </li>
      <li
        *ngFor="let table of currentTableSet; let i = index; trackBy: trackByFn"
        class="t-all"
        [ngClass]="{
          'has-invoices': table.isBusy,
          active: currentSelects.table === table.uuid,
          selected: table.uuid === activeInvoiceSelects.table
        }"
        (click)="setTable(table.uuid); onTableSelected(table)"
        ripple-effect
      >
        <span>{{ table.name }}</span>
        <span class="mobile-elm">
          <div>{{ table.name }}</div>
        </span>
      </li>
    </ul>
    <div class="selected-option" *ngIf="!onlySet">
      <div *ngIf="selectedOptionName.hall">
        <span *ngIf="!selectedOptionName.table">{{ 'all_tables' | translate }}</span>
        <span *ngIf="selectedOptionName.table">{{ 'restaurant_table' | translate }}: {{ selectedOptionName.table?.name }}</span>
      </div>
    </div>
  </div>

  <ion-list class="ion-header-list ion-no-padding ion-no-margin" *ngIf="!onlySet">
    <ion-list-header class="ion-no-margin">
      <div class="col col-id">{{ ('invoices_list_col_id' | translate).toUpperCase() }}</div>
      <div class="col col-employee">{{ ('invoices_list_col_employee' | translate).toUpperCase() }}</div>
      <div *ngIf="isGastroMode && currentSelects?.tab === 'open'" class="col col-store">
        {{ ('room' | translate).toUpperCase() }}
      </div>
      <div *ngIf="isGastroMode" class="col col-table">{{ ('restaurant_table' | translate).toUpperCase() }}</div>
      <div class="col col-customer">{{ ('invoices_list_col_customer' | translate).toUpperCase() }}</div>
      <div *ngIf="!isGastroMode" class="col col-store">{{ ('invoices_list_col_store' | translate).toUpperCase() }}</div>
      <div class="col col-date">{{ ('invoices_list_col_date' | translate).toUpperCase() }}</div>
      <div class="col col-amount">{{ ('invoices_list_col_amount' | translate).toUpperCase() }}</div>
    </ion-list-header>
  </ion-list>
</div>

<div class="no-tables-configured" *ngIf="onlySet && !hallsList.length">{{ 'no_tables_configured_msg' | translate }}</div>
