<h3>{{ title | translate }}</h3>
<h5 *ngIf="message">{{ message | translate }}</h5>
<processing-icons [icon]="activeScreen"></processing-icons>
<processing-buttons
  [cancelButtonTitle]="cancelButtonTitle"
  [actionButtonTitle]="retryButtonTitle"
  [showCancelButton]="showCancelButton"
  [showActionButton]="showRetryButton"
  (onCancel)="cancelButtonEvent()"
  (onAction)="retryButtonEvent()"
></processing-buttons>
<pm-signature-pad *ngIf="showSignaturePad" (onResult)="onSignaturePadResult($event)"> </pm-signature-pad>
