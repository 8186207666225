import { IDayReportData, IDayReportRowData, IProperties, IRevenue, IRevenueWithSales } from '../interfaces/day-report.interface';

export class Revenue implements IRevenue {
  nrOfInvoices: number;
  nrOfInvoicesCancelled: number;
  revenue: number;
  urlToInvoices: string;
  urlToStornos: string;

  constructor(data) {
    this.nrOfInvoices = data.nrOfInvoices || 0;
    this.nrOfInvoicesCancelled = data.nrOfInvoicesCancelled || 0;
    this.revenue = data.revenue || 0;
    this.urlToInvoices = data.nrOfInvoices || '';
    this.urlToStornos = data.urlToStornos || '';
  }
}

export class RevenueWithSales extends Revenue implements IRevenueWithSales {
  nrOfSales: number;
  name: string;

  constructor(data) {
    super(data);
    this.nrOfSales = data.nrOfSales || 0;
    this.name = data.name || '';
  }
}

export class Properties implements IProperties {
  currentRevenue: RevenueWithSales;
  revenues: Revenue[];
  productCategories: RevenueWithSales[];
  nrOfProductCategories: number;
  productVariants: RevenueWithSales[];
  nrOfProductVariants: number;
  employees: RevenueWithSales[];
  nrOfEmployees: number;
  customers: RevenueWithSales[];
  nrOfCustomers: number;
  stores: RevenueWithSales[];
  nrOfStores: number;
  payments: RevenueWithSales[];
  nrOfPayments: number;
  taxRates: RevenueWithSales[];
  nrOfTaxRates: number;
  discounts: RevenueWithSales[];
  nrOfDiscounts: number;
  taxRevenues: RevenueWithSales[];
  nrOfTaxRevenues: number;
  netRevenues: RevenueWithSales[];
  nrOfNetRevenues: number;
  onInvoiceReports: RevenueWithSales[];
  nrOfOnInvoiceReports: number;
  giftCards: RevenueWithSales[] = [];

  constructor(data) {
    this.currentRevenue = new RevenueWithSales(data.currentRevenue);

    this.revenues = [];
    for (let i of data.revenues) {
      this.revenues.push(new Revenue(i));
    }

    const initProp = (propname: string, propcountname: string) => {
      this[propcountname] = data[propcountname] || 0;

      this[propname] = [];
      if (data[propname])
        for (let i of data[propname]) {
          this[propname].push(new RevenueWithSales(i));
        }
    };

    initProp('productCategories', 'nrOfProductCategories');
    initProp('productVariants', 'nrOfProductVariants');
    initProp('employees', 'nrOfEmployees');
    initProp('customers', 'nrOfCustomers');
    initProp('stores', 'nrOfStores');
    initProp('payments', 'nrOfPayments');
    initProp('taxRates', 'nrOfTaxRates');
    initProp('taxRevenues', 'nrOfTaxRevenues');
    initProp('discounts', 'nrOfDiscounts');
    initProp('netRevenues', 'nrOfNetRevenues');
    initProp('onInvoiceReports', 'nrOfOnInvoiceReports');
    this.initGiftCardsReport(data.giftCards);
  }

  private initGiftCardsReport(giftCards: any) {
    if (!giftCards) {
      return;
    }
    const { nrOfInvoicesSold, nrOfInvoicesSoldCancelled, nrOfInvoicesRedeemed, nrOfInvoicesRedeemedCancelled } = giftCards;
    if (!nrOfInvoicesSold && !nrOfInvoicesSoldCancelled && !nrOfInvoicesRedeemed && !nrOfInvoicesRedeemedCancelled) {
      return;
    }
    this.initPropGirtCard(giftCards, 'sold');
    this.initPropGirtCard(giftCards, 'redeemed');
  }

  private initPropGirtCard(giftCards: any, propName: string) {
    const capitalizePropName = propName.charAt(0).toUpperCase() + propName.slice(1);
    const sales = new RevenueWithSales({
      name: `reports_gift_card_amount_${propName}_name`,
      revenue: giftCards[`amount${capitalizePropName}`],
      nrOfInvoices: giftCards[`nrOfInvoices${capitalizePropName}`],
      nrOfInvoicesCancelled: giftCards[`nrOfInvoices${capitalizePropName}Cancelled`],
    });
    this.giftCards.push(sales);
  }
}

export class DayReportData implements IDayReportData {
  type: string;
  properties: Properties;
  entities: any[];
  links: any[];

  constructor(data) {
    this.type = data.type || '';
    this.properties = new Properties(data.properties);
    this.links = data.links;
    this.entities = data.entities;
  }
}

export class DayReportRowData implements IDayReportRowData {
  name: string;
  value: RevenueWithSales[] = [];

  constructor(name: string, value: RevenueWithSales[]) {
    this.name = name;
    value.forEach((item) => {
      this.value.push(new RevenueWithSales(item));
    });
  }
}
