import { MyPosReceiptItem } from './my-pos-receipt-item.class';

const REGEX_TWO_LAST_ENTER = /\n{2}$/;
const REGEX_LAST_ENTER = /\n$/;
const REGEX_START_ENTER = /^[\s\uFEFF\xA0]+/g;
const REGEX_ONLY_ENTER = /^[\n]+$/;
const REGEX_TRIM_END = /[\s\uFEFF\xA0]+$/g;

export class MyPosHubReceiptItem extends MyPosReceiptItem {
  protected getText(text: string) {
    if (REGEX_ONLY_ENTER.test(text)) {
      if (REGEX_TWO_LAST_ENTER.test(text)) {
        return '\n';
      } else if (REGEX_LAST_ENTER.test(text)) {
        return '';
      }
    }
    text = text.replace(REGEX_START_ENTER, '');
    text = text.replace(REGEX_TRIM_END, '');
    return `\n${text}`;
  }
}
