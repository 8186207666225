import { Component, ViewEncapsulation, ChangeDetectionStrategy, Injector } from '@angular/core';
import { CALENDAR_VIEWS } from '@pos-common/constants';
import { KeyboardService } from '@pos-common/services/system/keyboard/keyboard.service';
import { CalendarFilterModalBaseComponent } from '@pos-modules/shared/components';

@Component({
  selector: 'pos-calendar-list-filter-modal',
  templateUrl: './calendar-list-filter-modal.page.html',
  styleUrls: ['./calendar-list-filter-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarListFilterModalPage extends CalendarFilterModalBaseComponent {
  protected view = CALENDAR_VIEWS.LIST;
  protected keyboardService: KeyboardService;

  constructor(protected injector: Injector) {
    super(injector);
    this.keyboardService = this.injector.get(KeyboardService);
  }

  closeModal(event?: Event): Promise<void> {
    if (this.keyboardService.isOpen) {
      event?.stopPropagation();
      return this.keyboardService.hide();
    }
    return super.closeModal();
  }
}
