/**
 * Created by maxkunitsa on 11/6/16.
 */

import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, Output, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ICustomPrice } from '@pos-common/interfaces';
import { CustomCurrencyPipeModule } from '@pos-common/pipes';
import { VirtualKeyboardModule } from '../virtual-keyboard/virtual-keyboard.component';

@Component({
  selector: 'free-pricing-picker',
  styleUrls: ['./free-pricing-picker.component.scss'],
  templateUrl: './free-pricing-picker.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FreePricingPicker {
  @Input() public customPrice: any;
  @Input() public customPriceCents: any;
  @Output() clickButton = new EventEmitter<ICustomPrice>();
  public keyboardClickHandlerCallback: Function;

  constructor(private cdr: ChangeDetectorRef) {
    this.keyboardClickHandlerCallback = this.keyboardClickHandler.bind(this);
  }

  public keyboardClickHandler(symbol: any, type: string) {
    if (type === 'number') {
      // break if current amount is 0 and user tried to add 0
      if (symbol === '0' && this.customPriceCents.length === 0) {
        return false;
      }

      // set temp cents string
      var TempCents = '' + this.customPriceCents + symbol;
      // converse cents string to int
      var TempDecimal = parseInt(TempCents) / 100;

      // check if temp amount don't break a limit
      // TODO REMOVE HARDCODED MAX AMOUNT
      if (TempDecimal < 1000000) {
        this.customPriceCents = TempCents;
        this.customPrice = TempDecimal;
        this.clickButton.emit({ customPriceCents: this.customPriceCents, customPrice: this.customPrice });
      }
    } else if (type === 'special') {
      var TempCents = '' + this.customPriceCents;
      var TempDecimal = parseInt(TempCents) / 100;

      switch (symbol) {
        case 'delete':
          if (TempCents.length > 0) {
            // remove last symbol from cent string
            TempCents = TempCents.substr(0, TempCents.length - 1);
            // converse cents string to int
            TempDecimal = parseInt(TempCents) / 100;
            // if all symbols removed
            if (TempCents.length === 0) {
              TempCents = '';
              TempDecimal = 0;
            }
            this.customPriceCents = TempCents;
            this.customPrice = TempDecimal;
            this.clickButton.emit({ customPriceCents: this.customPriceCents, customPrice: this.customPrice });
          }
          break;
        case 'double_zero':
          this.keyboardClickHandler(0, 'number');
          this.keyboardClickHandler(0, 'number');
          break;
      }
    }
    this.cdr.detectChanges();
  }
}

@NgModule({
  imports: [TranslateModule.forChild(), CustomCurrencyPipeModule, VirtualKeyboardModule],
  declarations: [FreePricingPicker],
  exports: [FreePricingPicker],
})
export class FreePricingPickerModule {}
