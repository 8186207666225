<lib-calendar
  #calendar
  [class.no-events]="inProcess"
  (getDate)="getDate($event)"
  (eventClick)="openAppointment($event.extendedProps)"
  (selectDate)="createAppointmentPopup($event.dateStr, $event.resource?.extendedProps?.uuid)"
  (navLinkDayClick)="handleSelectDay()"
></lib-calendar>
<div *ngIf="inProcess" class="spinner">
  <ion-spinner name="crescent"></ion-spinner>
</div>
