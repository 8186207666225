/**
 * Created by maksymkunytsia on 10/19/16.
 */

import * as moment from 'moment';
import { INVOICE_TYPES } from '../constants/invoice-types';
import { Invoice } from './invoice.class';
import { Entity } from './entity.class';
import { InvoiceEntry } from './invoice-entry.class';
import { DefaultPaymentMethods } from '@pos-common/constants/default-payment-methods.enum';
import { InvoicePayment } from './invoice-payment.class';
import { Employee } from './employee.class';
import { Customer } from './customer.class';
import { Store } from './store.class';
import { DiscountCode } from './discount-code.class';

export class InvoceInlist extends Entity {
  // general
  public uuid: string;
  public publicUuid: string;
  public isDraft: boolean;
  public isPaid: boolean;
  public isPartiallyPaid: boolean;
  public invoiceDisplayId: string;
  public amount: number;
  public discount: number;
  public discountCode: DiscountCode;
  public customerDiscount: number;
  public date: string;
  public modificationDate: string;
  public fieldToSort: string;
  public paymentMethod: string;
  // other / references
  public employee: Employee;
  public customer: Customer;
  public customerUuid: string;
  public store: Store;
  // cancellation
  public isCancelled: boolean;
  public isPartiallyCancelled: boolean;
  public cancellationReason: string;
  public cancellationStatus: string;
  public cancellingEmployee: Object;
  public invoiceType: string;
  public cancellationInvoiceReference: any;
  public originalInvoiceReference: any;
  public gastronomyTableName: string;
  public gastronomyTable: { uuid: string };
  public invoiceEntries: InvoiceEntry[] = [];
  public fulfillmentState: string;
  private invoicePayments: InvoicePayment[] = [];
  public hallName: string | undefined;

  constructor(data: Invoice) {
    // general fields
    super(data);
    this.publicUuid = data.publicUuid || null;
    this.isDraft = data.isDraft || false;
    this.isPaid = data.isPaid || false;
    this.isPartiallyPaid = data.isPartiallyPaid || false;
    this.invoiceDisplayId = this.getDisplayId(data?.sequentId, data?.invoiceId);
    this.amount = data.amount || 0;
    this.discount = data.discount || 0;
    this.discountCode = data.discountCode || null;
    this.customerDiscount = data.customerDiscount || 0;
    this.date = data.date ? moment.utc(data.date).toISOString() : null;
    this.modificationDate = moment.utc(data.modificationDate).toISOString() || moment.utc().toISOString();
    this.fieldToSort = (data as any)?.fieldToSort || new Date(this.date || this.modificationDate).valueOf().toString();
    this.gastronomyTableName = data.gastronomyTableName || null;
    this.gastronomyTable = data.gastronomyTable || null;
    this.paymentMethod = data.paymentMethod || '';
    this.customerUuid = data.customerUuid || null;

    // other / references
    if (data.employee) {
      this.employee = new Employee(data.employee);
    } else {
      this.employee = null;
    }
    if (data.customer) {
      this.customer = new Customer(data.customer);
    } else {
      this.customer = null;
    }
    if (data.store) {
      this.store = new Store(data.store);
    } else {
      this.store = null;
    }
    this.invoiceType = data.invoiceType;

    // cancellation
    this.isCancelled = data.isCancelled || false;
    this.isPartiallyCancelled = data.isPartiallyCancelled || false;
    if (this.invoiceType === INVOICE_TYPES.CANCELLATION) {
      this.isCancelled = true;
    }
    this.cancellationReason = data.cancellationReason || null;
    this.cancellationStatus = data.cancellationStatus || null;
    this.cancellingEmployee = data.cancellingEmployee || null;
    this.cancellationInvoiceReference = data.cancellationInvoiceReference || null;
    this.originalInvoiceReference = data.originalInvoiceReference || null;
    this.fulfillmentState = data.fulfillmentState || null;

    if (data.invoiceEntries && data.invoiceEntries.length) {
      for (let i = 0; i < data.invoiceEntries.length; i++) {
        this.invoiceEntries.push(new InvoiceEntry(data.invoiceEntries[i]));
      }
    }

    // invoice payments
    if (data.invoicePayments && data.invoicePayments.length) {
      for (let j = 0; j < data.invoicePayments.length; j++) {
        this.invoicePayments.push(new InvoicePayment(data.invoicePayments[j]));
      }
    } else {
      this.invoicePayments = [];
    }
  }

  isUnpaidInvoiceWithOnInvoicePaymentMethod(): boolean {
    const hasInvoicePayments = this.invoicePayments.some((payment) => !payment.deleted);
    return this.paymentMethod === DefaultPaymentMethods.ON_INVOICE && !hasInvoicePayments;
  }

  private getDisplayId(sequentId: number, invoiceId: string): string {
    if (sequentId && invoiceId) return `${sequentId} (${invoiceId})`;
    if (invoiceId) return invoiceId;
    return '-';
  }
}
