import { Injectable } from '@angular/core';
import { NullifyModalService } from './nullify-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { InvoicePayment } from '@pos-common/classes/invoice-payment.class';
import { ITransactionReceipt } from '@pos-common/interfaces/transaction-receipt.interface';

@Injectable()
export class PrintTransactionReceipts {
  constructor(private nullifyModalService: NullifyModalService, private translateService: TranslateService) {}

  public async printTransactionReceipts(transactionReceipts: ITransactionReceipt[]) {
    const component = (await import('./../../../common/components/terminal-receipts/terminal-receipts.component')).TerminalReceipts;
    this.nullifyModalService.initModal({
      component,
      options: {
        styles: { width: '360px' },
        verticalCenter: true,
        backdrop: true,
        notMobileFullScreen: true,
        closeBlocking: true,
      },
      inputs: [{ transactionReceipts }],
    });
  }

  public getTransactionReceipt(invoicePayments: InvoicePayment[]): ITransactionReceipt[] {
    return invoicePayments.reduce((transactionReceipts, invoicePayment) => {
      const { cardholderReceipt, merchantReceipt, paymentProvider } = invoicePayment;
      if (cardholderReceipt) {
        transactionReceipts.push(this.createTransactionReceipt(cardholderReceipt, paymentProvider, true));
      }
      if (merchantReceipt) {
        transactionReceipts.push(this.createTransactionReceipt(merchantReceipt, paymentProvider, false));
      }
      return transactionReceipts;
    }, [] as ITransactionReceipt[]);
  }

  private createTransactionReceipt(receipt: string, paymentProvider: string, isCardholder: boolean): ITransactionReceipt {
    const methodName = isCardholder ? 'Cardholder' : 'Merchant';
    return {
      receipt,
      paymentProvider: `${paymentProvider}  ${methodName} ${this.translateService.instant('receipt_printer_receipt_toggle')}`,
    };
  }

  public checkTransactionReceipt(invoicePayments: InvoicePayment[]) {
    return invoicePayments.some((invoicePayment) => !!invoicePayment.cardholderReceipt || !!invoicePayment.merchantReceipt);
  }

  public shootdownModal() {
    this.nullifyModalService.destroyModal();
  }
}
