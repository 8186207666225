import { Injectable, Injector } from '@angular/core';
import { CommonPrinterService, OnDiscover, OnError, OnLogEvent } from '../common-printer.service';
import { PrinterServiceInterface } from '@pos-common/interfaces/printer-service.interface';
import { PrinterDto } from '@pos-common/services/system/receipt-printers/dto/printer.dto';
import { EpsonprintPlugin } from '@paymash/capacitor-epsonprint-plugin';
import { PrinterType } from '../../enum/printer-type.enum';

@Injectable()
export class EpsonPrinterService extends CommonPrinterService implements PrinterServiceInterface {
  constructor(injector: Injector) {
    super(injector);
  }

  protected get pluginName() {
    return 'EpsonPrinterService';
  }

  protected get discoverMethodName() {
    return '';
  }

  protected get stopDiscoverMethodName(): string {
    return '';
  }

  protected get printMethodName(): string {
    return '';
  }

  protected callPrintReceiptMethod(printer: PrinterDto, receipt: any[]) {
    return EpsonprintPlugin.printReceipt(printer, receipt);
  }

  protected callDiscoverMethod(onDiscover: OnDiscover, onError: OnError) {
    EpsonprintPlugin.setDiscoverDevicesListener({
      onMessage: (data) => {
        data?.forEach((printer: any) => (printer.deviceType = PrinterType.Epson));
        onDiscover(data);
      },
      onError: (error) => onError(error),
    });
    EpsonprintPlugin.startDiscoverDevices().catch((error) => onError(error));
  }

  protected async callStopDiscoverMethod(): Promise<void> {
    await EpsonprintPlugin.stopDiscoverDevices();
  }
}
