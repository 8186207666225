import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { ReceiptPrintersService } from './services/receipt-printers.service';
import { StarPrinterService, starPrintersServiceFactory } from './services/star-printer';
import { EpsonPrinterService, epsonPrintersServiceFactory } from './services/epsonprint';
import { ReceiptPrinterManualConnectionService } from './services/receipt-printer-manual-connection.service';
import { MyPosPrinterService } from './services/my-pos-printer/my-pos-printer.service';
import { myPosPrintersServiceFactory } from './services/my-pos-printer/my-pos-printer.service.factory';
import { ISunmiPrinterService, sunmiPrinterServiceFactory } from './services/sunmi-printer';
import { IAdyenPrinterService, adyenPrinterServiceFactory } from './services/adyen-printer';
import { ISunmiService } from '@pos-common/services/system/devices/sunmi/sunmi.service';
import { AdyenPaymentApi } from '@pos-common/services/system/adyen/services/api';
import { AdyenUtils } from '@pos-common/services/system/adyen/adyen-utils';
import { IAdyenService } from '@pos-common/services/system/devices/adyen/adyen.service';

@NgModule()
export class ReceiptPrintersModule {
  static forRoot(): ModuleWithProviders<ReceiptPrintersModule> {
    return {
      ngModule: ReceiptPrintersModule,
      providers: [
        ReceiptPrintersService,
        ReceiptPrinterManualConnectionService,
        {
          provide: StarPrinterService,
          useFactory: starPrintersServiceFactory,
          deps: [Injector],
        },
        {
          provide: EpsonPrinterService,
          useFactory: epsonPrintersServiceFactory,
          deps: [Injector],
        },
        {
          provide: MyPosPrinterService,
          useFactory: myPosPrintersServiceFactory,
          deps: [Injector],
        },
        {
          provide: ISunmiPrinterService,
          useFactory: sunmiPrinterServiceFactory,
          deps: [Injector, ISunmiService],
        },
        {
          provide: IAdyenPrinterService,
          useFactory: adyenPrinterServiceFactory,
          deps: [Injector, AdyenPaymentApi, AdyenUtils, IAdyenService],
        },
      ],
    };
  }
}
