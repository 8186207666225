import { Injectable } from '@angular/core';
import {
  IDeviceReportingService,
  IServiceWithReporting,
  IDeviceReportingRegistration,
  ServiceReport,
} from '@pos-common/services/system/device-report';
import { ICustomerDisplayDevice } from './ICustomerDisplayDevice';
import { CustomerDisplayService } from './CustomerDisplayService';

type ReportingEventType = 'connected' | 'disconnected' | 'added' | 'removed';

@Injectable({ providedIn: 'root' })
export class CustomerDisplayReporting implements IServiceWithReporting {
  private reporting: IDeviceReportingRegistration<ReportingEventType>;

  constructor(private readonly customerDisplayService: CustomerDisplayService, deviceReporting: IDeviceReportingService) {
    this.reporting = deviceReporting.registerService<ReportingEventType>('CustomerDisplay', this);
  }

  createReportForAllDevices() {
    return this.customerDisplayService.devices.map((device) => this.createReportingDataForDevice(device));
  }

  private createReportingDataForDevice(device: ICustomerDisplayDevice): ServiceReport {
    return {
      device: {
        name: device.name,
      },
    };
  }

  private report(type: ReportingEventType, device: ICustomerDisplayDevice) {
    this.reporting.report(type, this.createReportingDataForDevice(device));
  }

  deviceConnected(device: ICustomerDisplayDevice) {
    this.report('connected', device);
  }

  deviceDisconnected(device: ICustomerDisplayDevice) {
    this.report('disconnected', device);
  }

  deviceAdded(device: ICustomerDisplayDevice) {
    this.report('added', device);
  }

  deviceRemoved(device: ICustomerDisplayDevice) {
    this.report('removed', device);
  }
}
