import { IDatabase } from '@paymash/capacitor-database-plugin';

export interface MigrationInfo {
  companyId: string;
}

export abstract class MigrationBase {
  readonly version: number;
  readonly name: string;

  abstract up(database: IDatabase, info: MigrationInfo): Promise<void>;

  getCollection(collection: string, companyId: string) {
    return `${companyId}_${collection}`;
  }
}
