<ion-backdrop tappable (ionBackdropTap)="dismiss()"></ion-backdrop>
<div class="calendar-notification-content">
  <pos-header>
    <button tappable (click)="dismiss()" header-start>
      <pos-icon customName="custom-icon-cancel-search"></pos-icon>
    </button>
    <ion-title header-title>{{ 'calendar_unread_notifications' | translate }}</ion-title>
    <button class="clear-all-button" tappable (click)="clearNotificationList()" header-end [disabled]="disabledClearButton">
      <span class="mobile-hide">{{ 'common_clear_all' | translate}}</span>
      <pos-icon class="tablet-hide" customName="custom-icon-paid-invoices-trash"></pos-icon>
    </button>
  </pos-header>
  <ion-list class="calendar-notification-list" [hidden]="isLoading">
    <pos-calendar-notification-item
      *ngFor="let notification of notificationList; trackByProperty: 'appointmentUuid'"
      [notification]="notification"
      (posOpen)="openAppointment($event)"
      (posRemove)="clearNotification($event)"
    ></pos-calendar-notification-item>
    <div *ngIf="!notificationList.length" class="no-items">{{ 'calendar_no_new_notifications' | translate }}</div>
  </ion-list>
  <pos-loader class="loader-contaner" [isLoading]="isLoading"></pos-loader>
</div>
