import { SERVER_CONFIG } from '../constants/server.const';
import { Entity } from './entity.class';

export class Document extends Entity {
  public uuid: string;
  public src: string;

  constructor(data) {
    super(data);
    this.src = data.src || null;
  }

  public getSrc() {
    if (this.src) {
      return SERVER_CONFIG.SERVER_URL + this.src;
    }
    return SERVER_CONFIG.PUBLIC_DOCUMENTS_URL + this.uuid + '?height=300';
  }
}
