import { trigger, state, animate, style, transition } from '@angular/animations';

export function scaleElm() {
  return trigger('scaleElm', [
    state(
      'void',
      style({
        opacity: 0,
        transform: 'scale(0.7)',
      })
    ),
    state(
      '*',
      style({
        opacity: 1,
        transform: 'scale(1)',
      })
    ),
    transition('void => *', animate('0.2s ease-in')),
    transition('* => void', animate('0.2s ease-out')),
  ]);
}

export function fadeElm() {
  return trigger('fadeElm', [
    state(
      'void',
      style({
        opacity: 0,
      })
    ),
    state(
      '*',
      style({
        opacity: 1,
      })
    ),
    transition('void => *', animate('1s ease-in')),
    transition('* => void', animate('1s ease-out')),
  ]);
}

export function newInvoiceEntryElm() {
  return trigger('newInvoiceEntryElm', [
    state(
      'void',
      style({
        transform: 'translateX(-100%)',
        opacity: 0,
      })
    ),
    state(
      '*',
      style({
        transform: 'translateX(0)',
        opacity: 1,
      })
    ),
    transition('void => *', animate('.3s ease-in')),
  ]);
}

export function modalPageAnimation() {
  return trigger('modalPageAnimation', [
    state('void', style({ transform: 'translateX(100%)' })),
    state('*', style({ transform: 'translateX(0)' })),
    transition('void => *', animate('.2s ease-in')),
    transition('* => void', animate('.2s ease-out')),
  ]);
}

export const newAppointmentElement = () => {
  return trigger('newAppointmentElement', [
    state(
      'void',
      style({
        transform: 'translateX(-100%)',
        opacity: 0,
      })
    ),
    state(
      '*',
      style({
        transform: 'translateX(0)',
        opacity: 1,
      })
    ),
    transition('void => *', animate('.3s ease-in')),
    transition('* => void', animate('.2s ease-out')),
  ]);
};

export const hideCheckImage = () => {
  return trigger('hideCheckImage', [
    state(
      'void',
      style({
        opacity: 1,
      })
    ),
    state(
      '*',
      style({
        opacity: 0,
      })
    ),
    transition('void => *', animate('1.8s ease-in')),
  ]);
};

export const showQrCodeImage = () => {
  return trigger('showQrCodeImage', [
    state(
      'void',
      style({
        opacity: 0,
      })
    ),
    state(
      '*',
      style({
        opacity: 1,
      })
    ),
    transition('void => *', animate('.3s 1.5s ease-in')),
  ]);
};
