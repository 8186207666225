import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { LogService } from '../../../services/system/logger/log.service';
import { PaymentProcessingUserActions } from '../payment-processing-user-actions.enum';
import { PaymentProcessingUserActionInterface } from '../payment-processing-user-action.interface';
import { PaymentProcessingActions } from '../payment-processing-actions.enum';

@Component({
  selector: 'processing-content',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'processing-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingContentComponent implements OnChanges {
  @Input() activeScreen: PaymentProcessingActions;
  @Input() terminalMessage: string;
  @Input() retryButtonOff: boolean = false;
  @Input() cancelButtonOff: boolean = false;
  @Output() userAction: EventEmitter<PaymentProcessingUserActionInterface> = new EventEmitter();
  title: string;
  message: string;
  showCancelButton: boolean;
  showRetryButton: boolean;
  showSignaturePad: boolean;
  cancelButtonTitle: string;
  retryButtonTitle: string;

  constructor(private logService: LogService) {}

  ngOnChanges() {
    this.logService.debug('ProcessingContentComponent', 'ngOnChanges');
    this.setComponentData(this.activeScreen, this.terminalMessage);
    this.setButtonsState();
  }

  setButtonsState() {
    if (this.retryButtonOff) {
      this.showRetryButton = false;
    }
    if (this.cancelButtonOff) {
      this.showCancelButton = false;
    }
  }

  setComponentData(activeScreen: PaymentProcessingActions, message: string) {
    this.showCancelButton = false;
    this.showRetryButton = false;
    this.showSignaturePad = false;
    switch (activeScreen) {
      case PaymentProcessingActions.connecting:
        this.title = 'payment_processing_wizard_payment_processing_connecting';
        this.message = 'payment_processing_wizard_payment_processing_connecting_to_terminal';
        this.cancelButtonTitle = 'payment_processing_wizard_failed_cancel_btn';
        this.showCancelButton = true;
        break;
      case PaymentProcessingActions.processing:
        this.title = 'payment_processing_wizard_payment_processing_title';
        this.message = message ? message : 'payment_processing_wizard_payment_processing_msg';
        break;
      case PaymentProcessingActions.provideCard:
        this.title = 'payment_processing_wizard_provide_card_title';
        this.message = 'payment_processing_wizard_provide_card_msg';
        break;
      case PaymentProcessingActions.providePin:
        this.title = 'payment_processing_wizard_provide_pin_code_title';
        this.message = 'payment_processing_wizard_provide_pin_code_msg';
        break;
      case PaymentProcessingActions.provideSignature:
      case PaymentProcessingActions.drawSignature:
        this.title = 'payment_processing_wizard_provide_signature_title';
        this.message = 'payment_processing_wizard_provide_signature_msg';
        if (activeScreen === PaymentProcessingActions.drawSignature) {
          this.showSignaturePad = true;
        }
        break;
      case PaymentProcessingActions.warning:
        this.title = 'payment_processing_wizard_warning_title';
        this.message = message;
        this.cancelButtonTitle = 'payment_processing_wizard_failed_cancel_btn';
        this.showCancelButton = true;
        break;
      case PaymentProcessingActions.retry:
        this.title = 'payment_processing_wizard_failed_title';
        this.message = message;
        this.cancelButtonTitle = 'payment_processing_wizard_failed_cancel_btn';
        this.retryButtonTitle = 'payment_processing_wizard_failed_retry_btn';
        this.showCancelButton = true;
        this.showRetryButton = true;
        break;
      case PaymentProcessingActions.succeed:
        this.title = 'payment_processing_wizard_succeed_title';
        this.message = 'payment_processing_wizard_succeed_msg';
        this.cancelButtonTitle = 'common_continue';
        this.showCancelButton = true;
        break;
    }
  }

  dispatchUserEvent(eventType: PaymentProcessingUserActions, payload?: any) {
    this.userAction.emit({
      type: eventType,
      payload: payload,
    });
  }

  cancelButtonEvent() {
    this.dispatchUserEvent(PaymentProcessingUserActions.close);
  }

  retryButtonEvent() {
    this.dispatchUserEvent(PaymentProcessingUserActions.retry);
  }

  onSignaturePadResult(imageBase64: string) {
    this.dispatchUserEvent(PaymentProcessingUserActions.drawSignatureDone, imageBase64);
  }
}
