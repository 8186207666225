import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentNotification } from '@pos-common/classes/appointment/appointment-notification.class';
import { APPOINTMENT_NOTIFICATION_CREATED_BY, APPOINTMENT_NOTIFICATION_TYPES } from '@pos-common/constants';
import { LOCALE } from '@pos-common/constants/locale.const';
import { SecurityService } from '@pos-common/services/system/security.service';
import * as moment from 'moment';

@Pipe({ name: 'appointmentNotificationText' })
export class AppointmentNotificationTextPipe implements PipeTransform {
  private translateMap = new Map<string, string>();
  constructor(private translateSertive: TranslateService, private securityService: SecurityService) {
    this.setTranslateKey();
  }

  transform(notification: AppointmentNotification): string {
    const company = this.securityService.getLoggedCompanyData();
    const { locale: companyLocale } = company;
    const { DateFormat, TimeFormat } = LOCALE;
    const key = `${notification.createdBy}_${notification.type}`;
    const translateKey = this.translateMap.get(key);
    const params = {
      date: moment(notification.startedAt).format(DateFormat[companyLocale.dateFormat]),
      time: moment(notification.startedAt).format(TimeFormat[companyLocale.timeFormat]),
    };
    return this.translateSertive.instant(translateKey, params);
  }

  private setTranslateKey() {
    const { CREATE, UPDATE, DELETE, CANCEL_BY_CUSTOMER, MESSAGE } = APPOINTMENT_NOTIFICATION_TYPES;
    const { EMPLOYEE, CUSTOMER } = APPOINTMENT_NOTIFICATION_CREATED_BY;
    this.translateMap.set(`${EMPLOYEE}_${CREATE}`, 'appointment_notification_create');
    this.translateMap.set(`${EMPLOYEE}_${UPDATE}`, 'appointment_notification_update');
    this.translateMap.set(`${EMPLOYEE}_${DELETE}`, 'appointment_notification_delete');
    this.translateMap.set(`${EMPLOYEE}_${CANCEL_BY_CUSTOMER}`, 'appointment_notification_cancel_by_customer');
    this.translateMap.set(`${EMPLOYEE}_${MESSAGE}`, 'appointment_notification_message');
    this.translateMap.set(`${CUSTOMER}_${CREATE}`, 'appointment_notification_create_by_customer');
  }
}
@NgModule({
  declarations: [AppointmentNotificationTextPipe],
  exports: [AppointmentNotificationTextPipe],
})
export class AppointmentNotificationTextPipeModule {}
