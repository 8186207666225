<div class="calendar-notification" (click)="handleOpen()">
  <div class="calendar-notification-header">
    <div class="calendar-notification-time">
      {{ notification.createdAt | CompanyLocalized : 'dateTimeFormat' }}
    </div>
    <div class="close" (click)="handleRemove($event)" tappable>
      <pos-icon customName="close-outline-icon"></pos-icon>
    </div>
  </div>
  <div class="calendar-notification-text">
    <span [class.calendar-notification-active]="!notification.isAppointmentDeleted">
      {{ 'appointment_number' | translate }} {{ notification.appointmentId }}
    </span>
    {{ notification | appointmentNotificationText }}
  </div>
</div>
