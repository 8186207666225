/**
 * Created by maksymkunytsia on 8/22/16.
 */

import { Injectable } from '@angular/core'

@Injectable()
export class UuidService {
    public limitUI04:number = this.maxFromBits(4);
    public limitUI06:number = this.maxFromBits(6);
    public limitUI08:number = this.maxFromBits(8);
    public limitUI12:number = this.maxFromBits(12);
    public limitUI14:number = this.maxFromBits(14);
    public limitUI16:number = this.maxFromBits(16);
    public limitUI32:number = this.maxFromBits(32);
    public limitUI40:number = this.maxFromBits(40);
    public limitUI48:number = this.maxFromBits(48);

    constructor() {}

    public generate() :string {
        return this.fromParts(
            this.randomUI32(),
            this.randomUI16(),
            0x4000 | this.randomUI12(),
            0x80 | this.randomUI06(),
            this.randomUI08(),
            this.randomUI48()
        );
    }

    // Based on: http://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
    public validate(uuid:string) :boolean {
        var testPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return testPattern.test(uuid);
    }

    public maxFromBits(bits:number) :number {
        return Math.pow(2, bits);
    }

    public getRandomInt(min:number, max:number) :number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public randomUI06() :number {
        return this.getRandomInt(0, this.limitUI06-1);
    }

    public randomUI08() :number {
        return this.getRandomInt(0, this.limitUI08-1);
    }

    public randomUI12() :number {
        return this.getRandomInt(0, this.limitUI12-1);
    }

    public randomUI16() :number {
        return this.getRandomInt(0, this.limitUI16-1);
    }

    public randomUI32() :number {
        return this.getRandomInt(0, this.limitUI32-1);
    }

    public randomUI48() :number {
        return (0 | Math.random() * (1 << 30)) + (0 | Math.random() * (1 << 48 - 30)) * (1 << 30);
    }

    public paddedString(string:string, length:number, z?:any) :string {
        string = String(string);
        z = (!z) ? '0' : z;
        var i = length - string.length;
        for (; i > 0; i >>>= 1, z += z) {
            if (i & 1) {
                string = z + string;
            }
        }
        return string;
    }

    public fromParts(timeLow:number, timeMid:number, timeHiAndVersion:number, clockSeqHiAndReserved:number, clockSeqLow:number, node:number) {
        var hex = this.paddedString(timeLow.toString(16), 8)
            + '-'
            + this.paddedString(timeMid.toString(16), 4)
            + '-'
            + this.paddedString(timeHiAndVersion.toString(16), 4)
            + '-'
            + this.paddedString(clockSeqHiAndReserved.toString(16), 2)
            + this.paddedString(clockSeqLow.toString(16), 2)
            + '-'
            + this.paddedString(node.toString(16), 12);
        return hex;
    }

}
