/**
 * Created by maksymkunytsia on 11/24/16.
 */

export const PAGINATION = {
  INVOICES_ITEMS_COUNT: 20,
  CUSTOMERS_ITEMS_COUNT: 20,
  PRODUCTS_ITEMS_COUNT: 20,
  APPOINTMENT_ITEMS_COUNT: 25,
  IMAGE_ITEMS_COUNT: 10,
  LIST_UPDATE_SOURCE: {
    INIT: 'init',
    TAB: 'tab',
    SEARCH: 'search',
    UPDATE: 'update',
    MORE: 'more',
    DELETE: 'delete',
  },
};
