import { TranslateService } from '@ngx-translate/core';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'durationTime' })
export class DurationTimePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    if (!value) {
      return value;
    }
    const values = value.split(':');
    const hours = parseInt(values[0]);
    const minutes = parseInt(values[1]);
    let time = '';

    if (hours) {
      time = this.translateService.instant('calendar_duration_time_hours', { hours }) + ' ';
    }
    time += this.translateService.instant('calendar_duration_time_minutes', { minutes });
    return time;
  }
}
@NgModule({
  declarations: [DurationTimePipe],
  exports: [DurationTimePipe],
})
export class DurationTimePipeModule {}
