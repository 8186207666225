import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserObject, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { MyPosService } from '../devices/my-pos/my-pos.service';
import { IAdyenService } from '../devices/adyen/adyen.service';

type TargetType = '_blank' | '_self' | '_system';

@Injectable({ providedIn: 'root' })
export class InAppBrowserService {
  constructor(private inAppBrowser: InAppBrowser, private myPosService: MyPosService, private adyenService: IAdyenService) {}

  create(url: string, target?: TargetType, options?: string | InAppBrowserOptions): InAppBrowserObject {
    return this.inAppBrowser.create(url, target, options);
  }

  openUrl(url: string, target: TargetType = '_blank') {
    if (this.myPosService.isMyPosDevice || this.adyenService.isAdyenDevice) {
      this.inAppBrowser.create(url, target);
      return;
    }
    window.open(url, target);
  }
}
