import { Injectable } from '@angular/core';
import { DbDaoService } from '../db/db-dao.service';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { Observable } from 'rxjs';
import { DbEntityProvider } from './db-entity.provider';
import { Employee } from '../../classes/employee.class';
import { Query } from '@paymash/capacitor-database-plugin';

@Injectable()
export class EmployeesProvider extends DbEntityProvider {
  constructor(dbDaoService: DbDaoService) {
    super(UPDATES_TYPES.Employee.type, dbDaoService);
  }

  getByUuid(uuid: string): Observable<Employee> {
    return <Observable<Employee>>this.getEntityByUuid(uuid);
  }

  getList(): Observable<Employee[]> {
    return <Observable<Employee[]>>this.getEntitiesList();
  }

  getListByParams(queryParams: Query): Observable<Employee[]> {
    return <Observable<Employee[]>>this.getEntitiesListByParams(queryParams).pipe(this.mapPipeTransformEntities());
  }
}
