import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { AppointmentInList } from '@pos-common/classes/appointment/appointment-in-list.class';
import { APPOINTMENT_STATUS } from '@pos-common/constants';

@Component({
  selector: 'pos-calendar-list-item',
  templateUrl: './calendar-list-item.component.html',
  styleUrls: ['./calendar-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarListItemComponent {
  @Input() itemInList: AppointmentInList;
  @Output() posOpen = new EventEmitter<void>();
  @Output() posCancel = new EventEmitter<Event>();

  get isCancelInvoice() {
    return this.itemInList.status === APPOINTMENT_STATUS.CANCELED_BY_CUSTOMER;
  }

  get isAllowCancellation() {
    return !!!this.itemInList.invoice;
  }

  get text() {
    return this.itemInList.isCancel ? 'appointment_cancel_appointment' : 'common_delete';
  }

  handleOpen() {
    this.posOpen.emit();
  }

  handleCancel(event: Event) {
    this.posCancel.emit(event);
  }

  close(slide: IonItemSliding) {
    slide.close();
  }
}
