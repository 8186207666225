import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'order' })
export class OrderPipe implements PipeTransform {
  transform(array: Array<string>, args?: boolean): Array<string> {
    if (args) return array;

    if (!array || array === undefined || array.length === 0) return null;

    array.sort((as, bs) => {
      let a,
        b,
        a1,
        b1,
        i = 0,
        n,
        L,
        rx = /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;
      if (as === bs) return 0;
      a = as.toLowerCase().match(rx);
      b = bs.toLowerCase().match(rx);
      L = a.length;
      while (i < L) {
        if (!b[i]) return 1;
        (a1 = a[i]), (b1 = b[i++]);
        if (a1 !== b1) {
          n = a1 - b1;
          if (!isNaN(n)) return n;
          return a1 > b1 ? 1 : -1;
        }
      }
      return b[i] ? -1 : 0;
    });
    return array;
  }
}
@NgModule({
  declarations: [OrderPipe],
  exports: [OrderPipe],
})
export class OrderPipeModule {}
