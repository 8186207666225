import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { ShippingStatusType } from '@pos-common/constants';

@Pipe({
  name: 'invoiceShippingStatus',
})
export class InvoiceShippingStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case ShippingStatusType.open:
        return 'invoice_shopping_status_open';
      case ShippingStatusType.readyForShipping:
        return 'invoice_shopping_status_ready_for_shipping';
      case ShippingStatusType.fulfilled:
        return 'invoice_shopping_status_fulfilled';
      default:
        return value;
    }
  }
}
@NgModule({
  declarations: [InvoiceShippingStatusPipe],
  exports: [InvoiceShippingStatusPipe],
})
export class InvoiceShippingStatusPipeModule {}
