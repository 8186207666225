<ion-backdrop tappable (ionBackdropTap)="closeModal()"></ion-backdrop>
<div class="cart-modal-inner">
  <pos-header>
    <button tappable (click)="closeModal()" header-start>
      <pos-icon customName="custom-icon-cancel-search"></pos-icon>
    </button>
    <ion-title header-title>{{ 'calendar_filter_title' | translate }}</ion-title>
    <button [attr.disabled]="isDisabled ? '' : null" (click)="confirmModal()" header-end tappable>
      <pos-icon customName="custom-icon-select"></pos-icon>
    </button>
  </pos-header>
  <ion-content>
    <div class="cart-modal-body">
      <ng-container *ngFor="let group of filterForm.formGroups">
        <div class="row row-label-value" [hidden]="group.isHidden">
          <pos-calendar-filter-group
            [filterGroup]="group"
            [items]="group.items"
            (posSelectedItem)="setDisabled()"
          ></pos-calendar-filter-group>
        </div>
      </ng-container>
    </div>
  </ion-content>
  <div class="cart-modal-footer">
    <ng-container *ngIf="showRemoveButton">
      <pos-button class="cart-modal-button-remove" label="common_clear" color="--pos-red" (onClick)="clearFilters()"></pos-button>
      <pos-button
        class="cart-modal-button-done"
        [attr.disabled]="isDisabled ? '' : null"
        label="common_done"
        (onClick)="confirmModal()"
      ></pos-button>
    </ng-container>
    <pos-button
      *ngIf="!showRemoveButton"
      [attr.disabled]="isDisabled ? '' : null"
      label="common_done"
      (onClick)="confirmModal()"
    ></pos-button>
  </div>
</div>
