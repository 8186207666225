<ion-header>
  <ion-toolbar primary>
    <ion-buttons slot="start">
      <ion-menu-toggle>
        <button>
          <pos-icon ionName="menu-outline"></pos-icon>
        </button>
      </ion-menu-toggle>
      <sync-icon></sync-icon>
    </ion-buttons>
    <ion-title>{{"global_shifts_title" | translate}}</ion-title>
    <ion-buttons slot="primary">
      <shift-control-buttons pos="true"></shift-control-buttons>
      <button class="btn-current-user">
        <current-user></current-user>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content no-bounce scrollY="false">
  <cash-journal #shifts></cash-journal>
</ion-content>
