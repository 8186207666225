import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { query, oneOf, contains, by } from '@paymash/capacitor-database-plugin';
import { IMAGE_SIZES } from '@pos-common/classes/image.class';
import { ProductItem } from '@pos-common/classes/product-item.class';
import { Product } from '@pos-common/classes/product.class';
import { PAGINATION } from '@pos-common/constants/pagination.const';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';
import { ILoadDataOptions } from '@pos-common/interfaces';
import { IInventoryEvent } from '@pos-common/interfaces/inventory-event.interface';
import { SearchUtils } from '@pos-common/services';
import { ProductProvider } from '@pos-common/services/resources/product-db-entity.provider';
import { InfiniteScrollBaseComponent } from '@pos-modules/shared/components';

@Component({
  selector: 'pos-tipping-product-search',
  templateUrl: './tipping-product-search.component.html',
  styleUrls: ['./tipping-product-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TippingProductSearchComponent extends InfiniteScrollBaseComponent<ProductItem> {
  @Input() activeProductItem: ProductItem;
  @Output() posSelectProduct = new EventEmitter<ProductItem>();
  protected loadMoreCount = PAGINATION.PRODUCTS_ITEMS_COUNT;

  get IMAGE_SIZES() {
    return IMAGE_SIZES;
  }

  constructor(protected cdr: ChangeDetectorRef, private productProvider: ProductProvider, private searchUtils: SearchUtils) {
    super(cdr);
  }

  handleSearchValue(value: string) {
    value = this.searchUtils.replaceForSearch(value);
    this.searchValue = value;
    super.handleSearchValue(value);
  }

  clearSearch() {
    this.showLoader();
  }

  selectProduct(event: IInventoryEvent) {
    const productItem: ProductItem = event.dataset;
    this.posSelectProduct.emit(productItem);
  }

  protected loadData({ start, end, searchValue }: ILoadDataOptions): Promise<ProductItem[]> {
    return this.getProducts(start, end, searchValue).then((products) => {
      this.isLoading = false;
      return products.map((p) => this.addProductToUiList(p));
    });
  }

  private getProducts(start: number, end: number, searchValue: string): Promise<Product[]> {
    const queryParams = query({ deleted: false, visible: true, isGiftCard: false, type: oneOf(PRODUCT_TYPES.PRODUCT) });

    if (searchValue) {
      queryParams.push({ fieldToSearch: contains(searchValue) });
    }

    const options = {
      offset: start,
      limit: end,
      order: [by('fieldToSort')],
      select: ['uuid', 'bgColor', 'name', 'images'],
    };
    return this.productProvider.getListByParams(queryParams, options).toPromise();
  }

  private addProductToUiList(currentProduct: Product) {
    const productItem = new ProductItem(currentProduct, null);
    productItem.name = currentProduct.name;
    return productItem;
  }
}
