<ion-header [class.gastroMode]="isGastroMode">
  <ion-toolbar primary>
    <ion-buttons slot="start">
      <ion-menu-toggle [class.no-events]="isBarcodeScannerOpened">
        <button>
          <pos-icon ionName="menu-outline"></pos-icon>
        </button>
      </ion-menu-toggle>
      <button tappable (click)="openBarcodeScanner()" class="btn-barcode-scanner-open-invoices">
        <pos-icon customName="custom-icon-barcode"></pos-icon>
      </button>
      <div *ngIf="isGastroMode">
        <ng-container *ngTemplateOutlet="webshopInvoicesRef"></ng-container>
      </div>
      <sync-icon></sync-icon>
    </ion-buttons>
    <ion-buttons slot="primary">
      <button class="btn-current-user" [disabled]="isBarcodeScannerOpened" [class.no-events]="isBarcodeScannerOpened">
        <current-user [class.no-events]="isBarcodeScannerOpened"></current-user>
      </button>
      <div *ngIf="!isGastroMode">
        <ng-container *ngTemplateOutlet="webshopInvoicesRef"></ng-container>
      </div>
    </ion-buttons>
  </ion-toolbar>
  <div class="invoices-camera-scanner-div"></div>
</ion-header>
<ion-content class="invoices-page" [class.gastroMode]="isGastroMode" [hidden]="isBarcodeScannerOpened" scrollY="false" scrollX="false">
  <div class="invoices-page-container" slot="fixed">
    <invoice-filter [onlySet]="false"></invoice-filter>
    <invoice-list2></invoice-list2>
  </div>
</ion-content>

<ng-template #webshopInvoicesRef>
  <button *ngIf="isWebshopEnabled" class="btn-combine-invoice t-all" tappable (click)="openWebshopInvoices()">
    <span class="text mobile-hide">{{ 'invoices_webshop' | translate }}</span>
    <i class="custom-icon cart-outline-icon"></i>
  </button>
</ng-template>
