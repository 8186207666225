import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { mdTransitionAnimation, NavController } from '@ionic/angular';
import { AppointmentInList } from '@pos-common/classes/appointment/appointment-in-list.class';
import { CALENDAR_VIEWS } from '@pos-common/constants';
import { PAGINATION } from '@pos-common/constants/pagination.const';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';
import { ICalendarUpdateStatus, ICancellationAppointmentParams, ILoadDataOptions } from '@pos-common/interfaces';
import { AppointmentApiService } from '@pos-common/services/api/appointment-api.service';
import { AppointmentModalService } from '@pos-common/services/system/appointment-modal/appointment-modal.service';
import { CalendarService } from '@pos-common/services/system/calendar/calendar.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { InfiniteScrollBaseComponent } from '@pos-modules/shared/components';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pos-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class CalendarListComponent extends InfiniteScrollBaseComponent<AppointmentInList> implements OnInit {
  @Output() posOpenInvoice = new EventEmitter<string>();

  protected loadMoreCount = PAGINATION.APPOINTMENT_ITEMS_COUNT;
  private view = CALENDAR_VIEWS.LIST;

  constructor(
    protected cdr: ChangeDetectorRef,
    private appointmentApiService: AppointmentApiService,
    private appointmentModalService: AppointmentModalService,
    private navController: NavController,
    private calendarService: CalendarService,
    private subSinkService: SubSinkService,
    private logService: LogService
  ) {
    super(cdr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subSinkService.sink = this.calendarService.getUpdateStatus().subscribe((updateStatus) => {
      this.showLoader();
      this.updateList(updateStatus);
    });
  }

  protected loadData({ page, searchValue }: ILoadDataOptions): Promise<any[]> {
    const filterForm = this.calendarService.getFilterFormValue(this.view);
    filterForm.filterParams = {
      page: page + 1,
      pageSize: this.loadMoreCount,
      search: searchValue,
    };
    this.calendarService.setFilterForm(this.view, filterForm);

    return new Promise((resolve, reject) => {
      const subscription = this.calendarService
        .getEvents(this.view)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((resp) => {
          const { list } = resp;
          const appointments = list.map((appointment) => new AppointmentInList(appointment));
          resolve(appointments);
          if (subscription) {
            subscription.unsubscribe();
          }
          this.subSinkService.subs.add(subscription);
        }, reject);
      this.calendarService.loadEvents(this.view);
    });
  }

  openAppointment(appointmentInList: AppointmentInList) {
    this.navController
      .navigateForward(ROUTE_URLS.appointment, { state: { appointmentUuid: appointmentInList.uuid }, animation: mdTransitionAnimation })
      .catch((error) => this.logService.error('CalendarListComponent', 'openAppointment', error));
  }

  handleCancel(appointmentInList: AppointmentInList) {
    const { isCancel } = appointmentInList;
    let promise: Promise<any> = null;
    if (isCancel) {
      const hasCustomer = !!appointmentInList.customer?.dataToShowInList || false;
      promise = this.appointmentModalService.showCancelConfirmation(hasCustomer);
    } else {
      promise = this.appointmentModalService.showDeleteConfirmation();
    }

    promise.then((data) => {
      if (!data) {
        return;
      }
      const { isDelete, ...params } = data;
      this.deleteAppointment(appointmentInList.uuid, params);
    });
  }

  private deleteAppointment(uuid: string, params: ICancellationAppointmentParams = null) {
    this.appointmentApiService.deleteAppointment(uuid, params).subscribe(() => {
      this.calendarService.setUpdateStatus({ status: this.LIST_UPDATE_SOURCE.DELETE, appointmentUuid: uuid });
      this.calendarService.updateNotifications();
    });
  }

  private updateList(updateStatus: ICalendarUpdateStatus) {
    switch (updateStatus.status) {
      case this.LIST_UPDATE_SOURCE.UPDATE:
        const indexUpdate = this.itemList.findIndex((appointment) => appointment.uuid === updateStatus.appointmentUuid);
        const activePage = Math.floor(indexUpdate / this.loadMoreCount);
        this.search(this.LIST_UPDATE_SOURCE.UPDATE, activePage);
        return;
      case this.LIST_UPDATE_SOURCE.DELETE:
        const indexDelete = this.itemList.findIndex((appointment) => appointment.uuid === updateStatus.appointmentUuid);
        this.itemList.splice(indexDelete, 1);
        this.isLoading = false;
        this.cdr.detectChanges();
        return;
      default:
        return this.handleSearchValue('');
    }
  }
}
