import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { CalendarService } from '@pos-common/services/system/calendar/calendar.service';
import { ModalService } from '@pos-common/services/system/modal.service';
import { CalendarNotificationsModalPage } from '@pos-modules/modals/calendar-notifications-modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'pos-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationButtonComponent implements OnInit {
  public count$: Observable<number>;
  constructor(private calendarService: CalendarService, private modalService: ModalService) {}

  ngOnInit() {
    this.count$ = this.calendarService.getNotificationCount();
  }

  async handleClick() {
    const modal = await this.modalService.presentModal(CalendarNotificationsModalPage);
    await modal.present();
  }
}
