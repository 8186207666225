import { GiftCardInterface } from './gift-card.interface';
import { EntityInterface } from '../../common/interfaces/entity.interface';

export class GiftCard implements GiftCardInterface {
  amount: number;
  balance: number;
  code: string;
  physicalCode: string;
  customer: EntityInterface;
  deleted: boolean;
  expirationDate: string;
  invoice: EntityInterface;
  issueDate: string;
  product: EntityInterface;
  uuid: string;

  constructor(data) {
    this.amount = data.amount;
    this.balance = data.balance;
    this.code = data.code;
    this.physicalCode = data.physicalCode;
    this.customer = data.customer;
    this.deleted = data.deleted;
    this.expirationDate = data.expirationDate;
    this.invoice = data.invoice;
    this.issueDate = data.issueDate;
    this.product = data.product;
    this.uuid = data.uuid;
  }
}
