import * as Sentry from '@sentry/capacitor';
import { PAYMASH_PROFILE } from '@profile';
import { Integrations, BrowserTracing, routingInstrumentation, BrowserProfilingIntegration, init } from '@sentry/angular-ivy';

export const initSentry = () => {
  Sentry.init(
    {
      dsn: PAYMASH_PROFILE.sentryUrl,
      environment: PAYMASH_PROFILE.sentryEnvironment,
      integrations: [
        new Integrations.Breadcrumbs({
          console: false,
          xhr: false,
        }),
        new BrowserTracing({
          tracePropagationTargets: ['localhost', /\.mypaymash\.com/],
          routingInstrumentation,
        }) as any,
        new BrowserProfilingIntegration(),
      ],
      tracesSampleRate: 1.0,
      profilesSampleRate: 1.0,
    },
    init
  );
};
