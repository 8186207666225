import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewEncapsulation } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { ScannerAppService } from '@pos-common/services/system/scanner-app.service';

@Component({
  selector: 'scanner-app-select',
  templateUrl: './scanner-app-select.component.html',
  styleUrls: ['./scanner-app-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScannerAppSelectModal implements OnInit {
  constructor(private viewController: ModalController, private scannerAppService: ScannerAppService, private logService: LogService) {}

  ngOnInit() {
    this.scannerAppService.initAppScanner();
  }

  public getScannersFound() {
    return this.scannerAppService.scannersList;
  }

  public getCurrentScanner() {
    return this.scannerAppService.activeScanner;
  }

  public onAppSelected(app: string) {
    this.scannerAppService.connectToDevice(app);
  }

  public onDisconnect() {
    const currentScanner = this.getCurrentScanner();
    if (!currentScanner) {
      return;
    }
    this.scannerAppService.disconnectDevice();
  }

  public closeModal() {
    const currentScanner = this.getCurrentScanner();

    this.viewController
      .dismiss(currentScanner)
      .catch((err) => this.logService.error('ScannerAppSelectModal', 'closeModal:ViewController:dismiss', err));
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule.forChild()],
  declarations: [ScannerAppSelectModal],
  exports: [ScannerAppSelectModal],
})
export class ScannerAppSelectModalModule {}
