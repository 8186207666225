import { CALENDAR_FILTER_ITEM_TYPES, CALENDAR_FILTER_TYPES } from '@pos-common/constants';
import { FilterItem } from './filter-item.class';

export class FilterGroup {
  public type: CALENDAR_FILTER_TYPES;
  public title: string;
  public isOpen: boolean;
  public canOpen: boolean;
  public isHidden: boolean;
  public itemType: CALENDAR_FILTER_ITEM_TYPES = CALENDAR_FILTER_ITEM_TYPES.CHECKBOX;
  public items: FilterItem[] = [];

  constructor(type: CALENDAR_FILTER_TYPES, title: string, isOpen: boolean = false) {
    this.type = type;
    this.title = title;
    this.isOpen = isOpen;
    this.isHidden = false;
    this.canOpen = true;
  }

  hasSelectedItems() {
    return this.items.some((item) => item.isSelected);
  }

  getSelectedItems() {
    return this.items.filter((item) => item.isSelected);
  }

  clear() {
    this.getSelectedItems().forEach((item) => (item.isSelected = false));
  }
}
