<li *ngIf="!isMultipleGuests" class="course-group" [class.multiple-guest]="isMultipleGuests">
  <div class="title">
    {{ 'common_all_items' | translate }}
  </div>
  <printer-link
    posMode="kitchen"
    [invoice]="activeInvoice"
    [onlyIcon]="true"
    [isArrowRight]="true"
    [showUnprintedKitchenItems]="showUnprintedKitchenItems"
    [showGuestOnReceipt]="true"
    [tableEnforce]="true"
  ></printer-link>
</li>
<ng-container *ngFor="let item of entryList; trackBy: trackByFn">
  <li *ngIf="item.isGuest">
    <cart-invoice-entry-guest
      [activeInvoice]="activeInvoice"
      [invoiceEntryGuest]="item"
      [showUnprintedKitchenItems]="showUnprintedKitchenItems"
      [isGastroMode]="isGastroMode"
      (onSelectGuest)="selectGuest($event)"
      (onEditGuest)="editGuest($event)"
      (onRemoveGuest)="removeGuest($event)"
    ></cart-invoice-entry-guest>
  </li>
  <li *ngIf="!item.isGuest" @newInvoiceEntryElm>
    <cart-invoice-entry
      [invoiceEntry]="item"
      [showUnprintedKitchenItems]="showUnprintedKitchenItems"
      (click)="invoiceEntryClick(item.uuid)"
      (swipe)="invoiceEntrySwipe($event, item.uuid)"
      tappable
    ></cart-invoice-entry>
  </li>
</ng-container>
