import { DbEntityProvider } from "./db-entity.provider";
import { DbDaoService } from "../db/db-dao.service";
import { UPDATES_TYPES } from "../../constants/updates-types.const";
import { Observable } from "rxjs";
import { VirtualPrinter } from "../../classes/virtual-printer.class";
import { Injectable } from "@angular/core";


@Injectable()
export class VirtualPrintersProvider extends DbEntityProvider {


  constructor(dbDaoService: DbDaoService) {
    super(UPDATES_TYPES.VirtualPrinter.type, dbDaoService);
  }

  getByUuid(uuid: string): Observable<VirtualPrinter> {
    return <Observable<VirtualPrinter>>this.getEntityByUuid(uuid);
  }

  getList(): Observable<VirtualPrinter[]> {
    return <Observable<VirtualPrinter[]>>this.getEntitiesList();
  }

}
