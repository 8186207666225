import { Component, ViewEncapsulation, ChangeDetectionStrategy, EventEmitter, Output, NgModule } from '@angular/core';
import { GuestButtonComponentModule } from '../guest-button/guest-button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'pos-add-guest-button',
  templateUrl: './add-guest-button.component.html',
  styleUrls: ['./add-guest-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddGuestButtonComponent {
  @Output() onClick = new EventEmitter();

  createNewGuest() {
    this.onClick.emit();
  }
}

@NgModule({
  imports: [TranslateModule.forChild(), GuestButtonComponentModule],
  declarations: [AddGuestButtonComponent],
  exports: [AddGuestButtonComponent],
})
export class AddGuestButtonComponentModule {}
