import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'processing-buttons',
  styleUrls: ['processing-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'processing-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingButtonsComponent {
  @Input() showCancelButton: boolean = false;
  @Input() showActionButton: boolean = false;
  @Input() cancelButtonTitle;
  @Input() actionButtonTitle;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onAction = new EventEmitter<void>();

  cancelButtonEvent() {
    this.onCancel.emit();
  }

  actionButtonEvent() {
    this.onAction.emit();
  }
}
