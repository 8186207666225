<div
  class="products-holder"
  inventoryEvents
  [tagName]="'pos-virtual-product-item'"
  (touchStart)="handleTouchStart($event)"
  (touchEnd)="handleTouchEnd($event)"
>
  <virtual-scroller #virtualScroll [items]="productItemList" [parentScroll]="parentScroll" [enableUnequalChildrenSizes]="true">
    <div *ngFor="let item of virtualScroll.viewPortItems; trackByUuid">
      <pos-virtual-product-group
        [productItemGroup]="item"
        [cellsQuantity]="cellsQuantity"
        [cellWidth]="cellWidth"
      ></pos-virtual-product-group>
    </div>
  </virtual-scroller>
  <ng-content *ngIf="!productItemList.length"></ng-content>
</div>
