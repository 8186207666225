import { EpsonPrinterService } from './epson-printer.service';
import { EpsonPrinterMockService } from './epson-printer.mock.service';
import { PlatformService } from '../../../platform/platform.service';
import { Injector } from '@angular/core';

export function epsonPrintersServiceFactory(injector: Injector) {
  const platformService = injector.get(PlatformService);
  if (platformService.isNativePlatform) {
    return new EpsonPrinterService(injector);
  }
  return new EpsonPrinterMockService(injector);
}
