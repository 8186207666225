import { Observable, BehaviorSubject } from 'rxjs';
import { ReceiptPrinter } from '../classes/receipt-printer.class';
import { PrinterServiceInterface } from '../../../../interfaces/printer-service.interface';
import { ReceiptBuilder } from '../classes/receipt-builder.class';
import { PrinterDto } from '../dto/printer.dto';
import { ReceiptPrintersStore, ReceiptPrintersStoreActions } from '../receipt-printers.store';
import { PrinterType } from '../enum/printer-type.enum';
import { LogService } from '../../logger/log.service';
import { stringifySafety } from '@pos-common/services/system/logger';
import { PermissionService } from '../../permission/permission.service';
import { PlatformService } from '../../platform/platform.service';
import { PermissionTypes } from '@pos-common/constants/permission-types.enum';
import { Injector } from '@angular/core';

export type OnDiscover = (data: unknown) => void;
export type OnError = (error: unknown) => void;
export type OnLogEvent = (data: { message: string }) => void;

export abstract class CommonPrinterService implements PrinterServiceInterface {
  protected abstract get pluginName(): string;

  protected abstract get discoverMethodName(): string;

  protected abstract get stopDiscoverMethodName(): string;

  protected abstract get printMethodName(): string;

  protected isAndroid: boolean;
  protected scanningInterval: number = 30000;
  protected platformService: PlatformService;
  protected permissionService: PermissionService;
  protected logService: LogService;

  private scanningAbortTaskNumber = null;
  private _discoverInProgress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private hasPermissionOnAndroid = false;

  protected constructor(protected injector: Injector) {
    this.platformService = injector.get(PlatformService);
    this.permissionService = injector.get(PermissionService);
    this.logService = injector.get(LogService);
    this.logsSubscription();
    this.isAndroid = this.platformService.isAndroid;
  }

  protected stopDiscoverAndClearInterval() {
    const clearTask = () => {
      clearTimeout(this.scanningAbortTaskNumber);
      this.scanningAbortTaskNumber = null;
      this._discoverInProgress.next(false);
    };

    this.stopDiscover()
      .then(() => clearTask())
      .catch(() => clearTask());
  }

  protected _printersList: ReceiptPrintersStore = new ReceiptPrintersStore([]);

  protected discover(): Observable<ReceiptPrinter[]> {
    this.logService.debug('CommonPrinterService', `${this.pluginName}:discover`);
    this._printersList.dispatch({
      type: ReceiptPrintersStoreActions.CLEAR_STORE,
    });
    this.callDiscoverMethod((data) => {
      this.handleDiscover(data);
    }, (error) => {
      this.logService.error('CommonPrinterService', `${this.pluginName}:discover`, error);
    });
    return this.printerList;
  }

  protected callDiscoverMethod(
    onDiscover: OnDiscover,
    onError: OnError,
  ) {
    window.cordova.exec(
      (data) => onDiscover(data),
      (err) => onError(err),
      this.pluginName,
      this.discoverMethodName,
      [],
    );
  }

  protected handleDiscover(success: any) {
    if (success instanceof Array) {
      this._printersList.dispatch({
        type: ReceiptPrintersStoreActions.ADD_PRINTERS,
        payload: success.map((printer) => new ReceiptPrinter(printer)),
      });
    } else if (success && success.devices && success.devices instanceof Array) {
      this._printersList.dispatch({
        type: ReceiptPrintersStoreActions.ADD_PRINTERS,
        payload: success.devices.map((printer) => new ReceiptPrinter(printer)),
      });
    }
  }

  protected stopDiscover(): Promise<void> {
    this.logService.debug('CommonPrinterService', `${this.pluginName}:stopDiscover`);
    return this.callStopDiscoverMethod();
  }

  protected callStopDiscoverMethod(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (window.cordova) {
        window.cordova.exec(resolve, reject, this.pluginName, this.stopDiscoverMethodName, []);
      } else {
        resolve();
      }
    });
  }

  get printerList(): Observable<ReceiptPrinter[]> {
    if (!this.scanningAbortTaskNumber) {
      this.scanningAbortTaskNumber = setTimeout(() => this.stopDiscoverAndClearInterval(), this.scanningInterval);
      this.checkPermissionsOnAndroid()
        .then((hasPermission) => {
          if (hasPermission) {
            this._discoverInProgress.next(true);
            this.discover();
          }
        })
        .catch((err) => this.logService.error('CommonPrinterService', 'printerList:checkPermissionsOnAndroid', err));
    }
    return this._printersList.asObservable();
  }

  public print(device: ReceiptPrinter, data: ReceiptBuilder): Promise<void> {
    this.logService.debug('CommonPrinterService', `${this.pluginName}:print`);
    let printer: PrinterDto;
    let receipt: any = [];
    switch (device.deviceType) {
      case PrinterType.Star:
        receipt = data.receiptForStar;
        printer = new PrinterDto(device.deviceType, device.target, device.printerModel, device.ipAddress, device.macAddress.toLowerCase());
        break;
      default:
        receipt = data.receiptForEpson;
        printer = new PrinterDto(device.deviceType, device.target, device.printerModel, device.ipAddress, device.macAddress);
        break;
    }
    this.logService.debug(
      'CommonPrinterService',
      `${this.pluginName}:print:pluginRequestData:
    printer - ${stringifySafety(printer)},
    receipt - ${stringifySafety(receipt, ['image'])}
    `,
    );
    return this.printReceipt(receipt, printer);
  }

  public checkPermissionsOnAndroid(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.hasPermissionOnAndroid) {
          return resolve(this.hasPermissionOnAndroid);
        }

        if (this.platformService.isNativePlatform && this.isAndroid) {
          let hasLocationPermission = true;
          if (this.platformService.isAndroid10AndMore) {
            hasLocationPermission = await this.permissionService.checkAndRequestPermission(PermissionTypes.ACCESS_COARSE_LOCATION);
          }
          let hasBluetoothConnectPermission = true;
          let hasBluetoothScanPermission = true;
          if (this.platformService.isAndroid12AndMore) {
            hasBluetoothConnectPermission = await this.permissionService.checkAndRequestPermission(PermissionTypes.BLUETOOTH_CONNECT);
            hasBluetoothScanPermission = await this.permissionService.checkAndRequestPermission(PermissionTypes.BLUETOOTH_SCAN);
          }
          this.hasPermissionOnAndroid = hasLocationPermission && hasBluetoothConnectPermission && hasBluetoothScanPermission;
          return resolve(this.hasPermissionOnAndroid);
        }
        this.hasPermissionOnAndroid = true;
        resolve(this.hasPermissionOnAndroid);
      } catch (err) {
        this.logService.error('CommonPrinterService', 'checkPermissionsOnAndroid', err);
        reject(err);
      }
    });
  }

  public get discoverInProgressState(): Observable<boolean> {
    return this._discoverInProgress.asObservable();
  }

  protected logsSubscription(): void {
    document.addEventListener('deviceready', () => {
      this.callGetLogsMethod(({ message }) => {
        this.logService.debug('CommonPrinterService', `${this.pluginName}:logsSubscription:${message}`);
      });
    });
  }

  protected callGetLogsMethod(onLogEvent: OnLogEvent) {
    window.cordova.exec(
      (message: string) => {
        onLogEvent({ message });
      },
      undefined,
      this.pluginName,
      'getLogs',
      [],
    );
  }

  private printReceipt(receipt: any[], printer: PrinterDto): Promise<void> {
    const splitReceipts = this.getSplitReceipts(receipt, printer.deviceType);

    return splitReceipts.reduce((promise, splitReceipt) => {
      const fullReceipt = [].concat(...splitReceipt);
      return promise.then(() => this.callPrintReceiptMethod(printer, fullReceipt));
    }, Promise.resolve());
  }

  protected callPrintReceiptMethod(printer: PrinterDto, receipt: any[]): Promise<void> {
    return new Promise((resolve, reject) =>
      window.cordova.exec(resolve, reject, this.pluginName, this.printMethodName, [{ receipt, printer }]),
    );
  }

  private getSplitReceipts(receipt: any[], printerType: PrinterType): any[][] {
    const splitValue = JSON.stringify({ cut: 'Nothing' });
    const length = receipt.length - 1;
    let splitReceipts: any = receipt.reduce((result: any[], value, index) => {
      let arr = result.pop() || [];
      arr = [...arr, value];
      if (JSON.stringify(value) === splitValue && index < length) {
        return [...result, arr, []];
      }
      return [...result, arr];
    }, []);

    const chunkSize = printerType === PrinterType.Star ? splitReceipts.length : 7;
    splitReceipts = [...Array(Math.ceil(splitReceipts.length / chunkSize))].map((_) => splitReceipts.splice(0, chunkSize));
    return splitReceipts;
  }
}
