import { ConnectionStatus, ConnectionType } from '../constants/tim-api.enum';
import { TimApiTerminalConnectionParams, TimApiTerminalInterface } from '@paymash/capacitor-tim-api-plugin';

export class TimApiTerminal implements TimApiTerminalInterface {
  private _terminalId: string;
  private _ipAddress: string;
  private _connectionStatus: ConnectionStatus;
  private _tipAllowed: boolean;

  constructor(terminalId: string, ipAddress: string, connectionStatus: ConnectionStatus) {
    this._connectionStatus = connectionStatus;
    this._terminalId = terminalId;
    this._ipAddress = ipAddress;
    this._tipAllowed = false;
  }

  get connectionStatus(): ConnectionStatus {
    return this._connectionStatus;
  }

  set connectionStatus(value: ConnectionStatus) {
    this._connectionStatus = value;
  }

  get terminalId(): string {
    return this._terminalId;
  }

  get ipAddress(): string {
    return this._ipAddress;
  }

  get tipAllowed(): boolean {
    return this._tipAllowed;
  }

  set tipAllowed(value: boolean) {
    this._tipAllowed = value;
  }

  get viewName(): string {
    if (this.ipAddress) {
      return this.ipAddress;
    }
    if (this.terminalId) {
      return this.terminalId;
    }
    return null;
  }

  get connectionType(): ConnectionType {
    if (this.ipAddress) {
      return ConnectionType.fixedIp;
    }
    if (this.terminalId) {
      return ConnectionType.broadcast;
    }
  }

  get connectionParams(): TimApiTerminalConnectionParams {
    const connectionParams: TimApiTerminalConnectionParams = {
      connectionType: this.connectionType,
    };
    if (this.connectionType === ConnectionType.fixedIp) {
      connectionParams.ip = this.ipAddress;
    } else {
      connectionParams.id = this.terminalId;
    }
    return connectionParams;
  }
}
