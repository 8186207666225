import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  BackgroundColorOptions,
  StatusBar,
  StatusBarPlugin,
  StatusBarStyle,
  StyleOptions,
} from '@pos-common/classes/capacitor-plugins/capacitor-plugins.class';
import { PlatformService } from '../platform/platform.service';

@Injectable({ providedIn: 'root' })
export class StatusBarService {
  private statusBar: StatusBarPlugin;

  get isPluginAvailable() {
    return Capacitor.isPluginAvailable('StatusBar');
  }

  constructor(private platformService: PlatformService) {
    this.statusBar = StatusBar;
  }

  getStatusBarStyle() {
    return this.platformService.isIOS ? StatusBarStyle.Light : StatusBarStyle.Dark;
  }

  setStyle(options: StyleOptions): Promise<void> {
    if (this.isPluginAvailable) {
      return this.statusBar.setStyle(options);
    }
    return Promise.resolve();
  }

  setBackgroundColor(options: BackgroundColorOptions): Promise<void> {
    if (this.isPluginAvailable && this.platformService.isAndroid) {
      return this.statusBar.setBackgroundColor(options);
    }
    return Promise.resolve();
  }
}
