<ion-header>
  <common-header>
    <div header-start>
      <button *ngIf="!backButtonTitle" class="variant-modal-close-button" tappable (click)="setVariant()">
        <i class="custom-icon custom-icon-cancel-search"></i>
      </button>
      <button *ngIf="backButtonTitle" tappable (click)="backButtonClick()">
        <i class="fi flaticon-arrows"></i>
      </button>
    </div>
    <h2 header-title>{{ productVariantModalTitle }}</h2>
  </common-header>
</ion-header>
<div class="content">
  <div class="steps-holder" [ngSwitch]="currentStep">
    <div class="step-block animate-switch" *ngSwitchCase="0">
      <div class="options-list">
        <ul>
          <li *ngFor="let option of optionsListsData[currentStep] | order : isPositionExist; trackBy: trackByFn">
            <a tappable (click)="setPickedOption(option, currentStep)">{{ option | replacePipe : option_prefix }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="step-block animate-switch" *ngSwitchCase="1">
      <div class="options-list">
        <ul>
          <li *ngFor="let option of optionsListsData[currentStep] | order : isPositionExist; trackBy: trackByFn">
            <a tappable (click)="setPickedOption(option, currentStep)">{{ option | replacePipe : option_prefix }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="step-block animate-switch" *ngSwitchCase="2">
      <div class="options-list">
        <ul>
          <li *ngFor="let option of optionsListsData[currentStep] | order : isPositionExist; trackBy: trackByFn">
            <a tappable (click)="setPickedOption(option, currentStep)">{{ option | replacePipe : option_prefix }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="step-block animate-switch free-price-step" *ngSwitchCase="3">
      <free-pricing-picker
        [customPrice]="customPrice"
        [customPriceCents]="customPriceCents"
        (clickButton)="setCustomPrice($event)"
      ></free-pricing-picker>
      <div class="add-to-cart-button-holder">
        <button type="button" class="button-with-border" tappable (click)="showFreePriceOrDismiss()">
          {{ 'variants_product_modal_add_to_cart' | translate }}
        </button>
      </div>
    </div>
    <div class="step-block animate-switch" *ngSwitchCase="4">
      <pos-employee-picker class="options-list" (posClick)="setPickedEmployee($event)"></pos-employee-picker>
    </div>
  </div>
</div>
