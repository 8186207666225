import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { PAGINATION } from '@pos-common/constants/pagination.const';
import { ILoadDataOptions } from '@pos-common/interfaces';

@Component({
  template: '',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class InfiniteScrollBaseComponent<T> implements OnInit, OnChanges {
  @Input() searchValue = '';
  protected abstract loadMoreCount: number;
  protected abstract loadData(options: ILoadDataOptions): Promise<T[]>;

  public isLoading = true;
  public isLoadMore = false;
  public itemList: T[] = [];
  public activePage = 0;

  protected get LIST_UPDATE_SOURCE() {
    return PAGINATION.LIST_UPDATE_SOURCE;
  }

  constructor(protected cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.search(this.LIST_UPDATE_SOURCE.INIT);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { searchValue } = changes;
    if (searchValue && !searchValue.isFirstChange()) {
      this.handleSearchValue(searchValue.currentValue);
    }
  }

  loadMore(event?: Event) {
    const target = (<InfiniteScrollCustomEvent>event)?.target;
    const { activePage } = this;
    this.search(this.LIST_UPDATE_SOURCE.MORE).then(() => {
      target?.complete();
      this.isLoadMore = activePage === this.activePage;
      this.cdr.detectChanges();
    });
  }

  handleSearchValue(value: string) {
    let sourse = this.LIST_UPDATE_SOURCE.INIT;
    if (value) {
      sourse = this.LIST_UPDATE_SOURCE.SEARCH;
    }
    this.isLoadMore = true;
    this.showLoader();
    this.search(sourse).then(() => this.resetInfiniteScroll());
  }

  protected search(source: string, activePage?: number) {
    let page = activePage || this.activePage;
    switch (source) {
      case this.LIST_UPDATE_SOURCE.MORE:
        page += 1;
        break;
      case this.LIST_UPDATE_SOURCE.UPDATE:
        break;
      default:
        page = 0;
        this.itemList = [];
        break;
    }

    const options: ILoadDataOptions = {
      start: page * this.loadMoreCount,
      end: this.loadMoreCount,
      page,
      searchValue: this.searchValue,
    };
    return this.loadData(options).then((data) => {
      const isLessData = !data.length || data.length < options.end;
      if (source === this.LIST_UPDATE_SOURCE.MORE && isLessData) {
        page -= 1;
      }
      if (isLessData) {
        this.isLoadMore = true;
      }

      if (source === this.LIST_UPDATE_SOURCE.UPDATE) {
        this.itemList.splice(options.start, data.length, ...data);
      } else {
        this.itemList = [...this.itemList, ...data];
        this.activePage = page;
      }
      this.isLoading = false;
      this.cdr.detectChanges();
    });
  }

  protected updateItems(start: number, end: number) {
    const options: ILoadDataOptions = {
      start,
      end,
      page: this.activePage,
      searchValue: this.searchValue,
    };
    return this.loadData(options).then((data) => {
      this.itemList.splice(start, end, ...data);
      this.itemList = [...this.itemList];
      this.cdr.detectChanges();
    });
  }

  protected showLoader() {
    this.isLoading = true;
    this.cdr.detectChanges();
  }

  protected resetInfiniteScroll() {
    this.isLoadMore = false;
    this.cdr.detectChanges();
  }
}
