import { IDriver } from '../IDriver';
import { ConnectionInfo, Device } from './Device';
import { DataWrapper } from './DataWrapper';
import { Socket } from '@spryrocks/capacitor-socket-connection-plugin';
import { ErrorLevel, ILogger, ILoggerFactory } from '@spryrocks/logger';
import { Watchdog } from './Watchdog';
import { PingTimer } from './PingTimer';
import { createPingMessage } from '@pos-common/services/system/customer-display/universal/Messages';
import PQueue from 'p-queue';
import { ignorePromiseError } from '@pos-common/services/utils/promise.utils';

export class NetworkDriver extends IDriver<Device, ConnectionInfo> {
  private readonly logger: ILogger;
  private connectingPromise?: Promise<void>;
  private socket?: {
    instance: Socket;
    dataWrapper: DataWrapper;
  };
  private onMessageReceivedCallback?: (message: object) => void;
  private onClosedCallback?: () => void;

  private readonly watchdogTimeout = 30000;
  private readonly pingInterval = 5000;

  private readonly watchdog: Watchdog;
  private readonly pingTimer: PingTimer;

  private readonly sendQueue: PQueue;

  constructor(private readonly device: Device, loggerFactory: ILoggerFactory) {
    super();
    this.logger = loggerFactory.createLogger("NetworkDriver");
    this.logger.updateParams({ device });
    this.watchdog = new Watchdog(
      this.watchdogHandler.bind(this),
      {
        timeout: this.watchdogTimeout,
      },
    );
    this.pingTimer = new PingTimer(
      this.sendMessage.bind(this),
      {
        timeout: this.pingInterval,
        message: createPingMessage(),
      }
    );
    this.sendQueue = new PQueue({concurrency: 1});
  }

  async connect() {
    const device = this.device;
    this.logger.debug("Connect to device");
    if (this.socket) throw new Error('Already connected');
    if (this.connectingPromise) throw new Error('Connecting in progress');
    const { host, port } = device.connectionInfo;
    const socket = new Socket();
    this.connectingPromise = socket.open(host, port);
    await this.connectingPromise;
    socket.onClose = this.onSocketClosed.bind(this);
    socket.onError = this.onSocketError.bind(this);
    this.logger.info("Device connected, make setup");
    const dataWrapper = new DataWrapper(socket);
    dataWrapper.onMessageReceived = this.onMessageReceived.bind(this);
    this.socket = {
      instance: socket,
      dataWrapper,
    };
    this.connectingPromise = undefined;
    this.pingTimer.start();
    this.watchdog.notifyAndWatch();
    this.logger.info("Connection setup finished");
  }

  async disconnect() {
    this.logger.debug("Disconnect from device");
    await this.clearSocketInternal(true, true);
    this.logger.info("Disconnected from device");
  }

  async sendMessage(message: object) {
    this.logger.trace("Send message to device", { message });
    const socket = this.socket;
    if (!socket) {
      this.logger.error(undefined, "Cannot send message because device was disconnected");
      return;
    }
    try {
      await this.sendQueue.add(() => socket.dataWrapper.sendMessage(message));
      this.logger.trace("Message sent successfully", { message });
    } catch (e) {
      this.logger.error(e, "An error was occurred while sending message", {level: ErrorLevel.Medium}, { message });
      throw e;
    }
  }

  setOnError(callback: (error: Error) => void) {
  }

  setOnMessageReceived(callback: (message: object) => void) {
    this.onMessageReceivedCallback = callback;
  }

  setOnClosed(callback: () => void): void {
    this.onClosedCallback = callback;
  }

  private async clearSocketInternal(waitConnecting: boolean, close: boolean) {
    if (waitConnecting) {
      this.logger.warning("Waiting connection established before close it");
      while (this.connectingPromise) {
        await this.connectingPromise;
      }
      this.logger.info("Waiting connection established before close it, done");
    }

    const socket = this.socket;
    if (!socket) {
      return;
    }

    this.socket = undefined;

    this.pingTimer.stop();
    this.watchdog.stop();

    if (close) {
      this.logger.debug("Close socket connection");
      await socket.instance.close().catch(ignorePromiseError);
      this.logger.debug("Socket connection closed");
    }

    if (this.onClosedCallback) {
      this.onClosedCallback();
    }
  }

  private onSocketClosed() {
    this.logger.info("Socket closed");
    this.clearSocketInternal(false, false).catch(ignorePromiseError);
  }

  private onSocketError(error: unknown) {
    this.logger.error(error, "Socket closed with error");
    this.clearSocketInternal(false, false).catch(ignorePromiseError);
  }

  private onMessageReceived(message: object) {
    this.logger.debug("Message from customer receiver", {message});
    this.watchdog.notifyAndWatch();
    if (!this.onMessageReceivedCallback) {
      this.logger.info("onMessageReceivedCallback is undefined");
      return;
    }
    this.onMessageReceivedCallback(message);
  }

  private watchdogHandler() {
    this.logger.info('Watchdog handler called, destroy connection');
    this.clearSocketInternal(false, true).catch(ignorePromiseError);
  }
}
