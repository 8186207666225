/**
 * Created by maksymkunytsia on 9/29/16.
 */

export const UPDATES_SOURCES = {
  UPDATE: 'update',
  UPDATE_ON_DEMAND: 'update_on_demand',
  MORE: 'more',
  INITIAL: 'initial',
};
