/**
 * Created by maksymkunytsia on 8/18/16.
 */

// Vendors
import { Injectable, EventEmitter } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { HTMLIonLoadingElementCustom } from '@pos-common/interfaces/html-ion-loading-element-custom.interface';

@Injectable()
export class LoadingService {
  public loadingItem: Promise<HTMLIonLoadingElementCustom>;
  private actionInTimeout: any;
  private syncInProgressTimeout: any;
  private syncInProgressStopTimeout: any;
  private syncIsRunuing: boolean = false;
  public syncStatusChangeEvent: EventEmitter<any> = new EventEmitter();

  constructor(public loadingController: LoadingController) {}

  public showLoadingItem(duration?: number, content?: string, withBlur?: boolean) {
    if (!this.loadingItem) {
      this.loadingItem = this.loadingController
        .create({
          spinner: 'crescent',
          duration: duration || 0,
          message: content || '',
        })
        .then((alert: HTMLIonLoadingElementCustom) => {
          this.actionInTimeout = setTimeout(() => {
            if (!alert.isDismiss) {
              alert.present();
            }
          }, 50);
          return alert;
        });
    }
  }

  public hideLoadingItem() {
    if (this.actionInTimeout) {
      clearTimeout(this.actionInTimeout);
    }
    if (this.loadingItem) {
      this.loadingItem.then((alert) => {
        alert.isDismiss = true;
        alert.dismiss();
      });
      this.loadingItem = null;
    }
  }

  public syncStart() {
    if (this.syncInProgressStopTimeout) clearTimeout(this.syncInProgressStopTimeout);
    if (!this.syncIsRunuing) {
      this.syncInProgressTimeout = setTimeout(() => {
        this.setSyncIsRuningStatus(true);
      }, 300);
    }
  }

  public syncFinish() {
    if (this.syncInProgressTimeout) clearTimeout(this.syncInProgressTimeout);
    if (this.syncIsRunuing) {
      if (this.syncInProgressStopTimeout) clearTimeout(this.syncInProgressStopTimeout);
      this.syncInProgressStopTimeout = setTimeout(() => {
        this.setSyncIsRuningStatus(false);
      }, 300);
    }
  }
  public setSyncIsRuningStatus(syncIsRunuingStatus: boolean) {
    this.syncIsRunuing = syncIsRunuingStatus;
    this.syncStatusChangeEvent.next(syncIsRunuingStatus);
  }
}
