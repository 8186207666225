/**
 * Created by maksymkunytsia on 9/30/16.
 */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  EventEmitter,
  ViewEncapsulation,
  Output,
  AfterContentInit,
  OnInit,
  NgModule,
} from '@angular/core';
import { PrinterService } from '../../services/system/printer.service';
import { VirtualKeyboardTypes } from '../../constants/virtual-keyboard-types.enum';
import { Renderer2 } from '@angular/core';
import { TotalBlockButtons } from '../../constants/total-block-buttons.enum';
import { MathUtils } from '../../services/utils/math.utils';
import { VirtualKeyboardActionTypes } from '../../constants/virtual-keyboard-action-types.enum';
import { IPopularNote } from '../../interfaces/popular-note.interface';
import { IVirtualKeyboardEvent } from '@pos-common/interfaces/virtual-keyboard-event.interface';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';
import { ICustomPrice } from '@pos-common/interfaces';
import { VirtualKeyboardCell } from './virtual-keyboard-cell/virtual-keyboard-cell';
import { CommonModule } from '@angular/common';
import { PrinterLinkModule } from '../printer-link/printer-link.component';

@Component({
  selector: 'virtual-keyboard',
  templateUrl: './virtual-keyboard.component.html',
  styleUrls: ['./virtual-keyboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualKeyboard implements OnInit, AfterContentInit {
  @Input() keyboardType: string;
  private entriesTotalAmount: any;
  @Input()
  set cartEntriesTotalAmount(value: any) {
    this.entriesTotalAmount = value < 0 ? 0 : value;
    this.setTopPopularNote(this.entriesTotalAmount);
  }
  get cartEntriesTotalAmount() {
    return this.entriesTotalAmount;
  }
  @Input() clickCallback: Function;
  @Input() reCalculateKeyboard: EventEmitter<any>;
  public zeroCellColspan: number = 1;
  public cashRegisterOpenInProgress: boolean = false;
  @Input() public disabledKeyboard: boolean = false;
  @Input() public displayPopularNotes: boolean = false;
  @Input() public dispayTip: boolean = false;
  public actionType = VirtualKeyboardActionTypes.Note;
  public activeTotalBlockButton = TotalBlockButtons.Given;
  public get VirtualKeyboardActionTypes() {
    return VirtualKeyboardActionTypes;
  }
  @Input()
  set totalBlockButton(value: TotalBlockButtons) {
    this.activeTotalBlockButton = value;
    this.setTopPopularNote(this.cartEntriesTotalAmount);
    this.setPopularNotes();
  }
  @Input() price: number;
  @Input() shouldCleanPrice: boolean = true;
  @Output() clickButton = new EventEmitter<IVirtualKeyboardEvent>();
  @Output() posGetCustomPrice = new EventEmitter<ICustomPrice>();
  public totalBlockButtons = TotalBlockButtons;
  public showTopPopularNote = false;
  public virtualKeyboardTypes = VirtualKeyboardTypes;
  public popularNotesList: IPopularNote[] = [];
  public topPopularNote: IPopularNote = null;
  private priceCents: string;

  constructor(
    public printerService: PrinterService,
    public renderer: Renderer2,
    public elementRef: ElementRef,
    private setTimeoutUtil: SetTimeoutUtil
  ) {}

  ngOnInit(): void {
    this.setPriceCents();
  }

  ngAfterContentInit() {
    const { Numeric, Checkout } = VirtualKeyboardTypes;
    if (!this.keyboardType) this.keyboardType = Numeric;
    if (this.keyboardType === Numeric) this.zeroCellColspan = 2;
    if (this.keyboardType === Checkout) this.launchCalculation();
    if (this.reCalculateKeyboard) this.reCalculateKeyboard.subscribe(() => this.launchCalculation());
    this.setPopularNotes();
  }

  launchCalculation() {
    this.setTimeoutUtil.addVisualEffect(300).then(() => {
      const currentElem = this.elementRef.nativeElement;
      const nextElem = currentElem.nextElementSibling;
      if (currentElem && nextElem) {
        const top: any = currentElem.getBoundingClientRect().top;
        const bottom: number = nextElem.getBoundingClientRect().top;
        const height: number = bottom - top - 30;
        const maxHeight = height < 450 ? height : 450;
        this.renderer.setStyle(currentElem, 'height', `${maxHeight}px`);
      }
    });
  }

  handleOnClick(data: IVirtualKeyboardEvent) {
    if (this.disabledKeyboard) {
      return;
    }
    const { action, actionType } = data;
    if (this.clickCallback) {
      this.clickCallback(action, actionType);
    }
    this.clickButton.emit(data);
    const { Amount } = VirtualKeyboardTypes;
    if (this.keyboardType === Amount) {
      this.keyboardClickHandler(action, actionType);
    }
  }

  public openCashRegister() {
    this.cashRegisterOpenInProgress = true;
    this.printerService
      .openCashRegister()
      .then(() => {
        this.cashRegisterOpenInProgress = false;
      })
      .catch(() => {
        this.cashRegisterOpenInProgress = false;
      });
  }

  public setTopPopularNote(totalAmount: number) {
    if (this.activeTotalBlockButton === TotalBlockButtons.Given) {
      this.topPopularNote = this.createPopularNote(totalAmount);
    } else if (this.activeTotalBlockButton === TotalBlockButtons.TotalAndTip) {
      this.setPopularNotes();
    }
  }

  public setPopularNotes() {
    let values = [];
    this.showTopPopularNote = false;
    const isFloat = MathUtils.isFloat(this.cartEntriesTotalAmount);

    if (this.displayPopularNotes) {
      values = [10, 20, 50];
      this.actionType = VirtualKeyboardActionTypes.Note;
      this.showTopPopularNote = true;
    }
    if (this.dispayTip && this.activeTotalBlockButton !== TotalBlockButtons.Given) {
      this.showTopPopularNote = true;
      values = [1, 2, 5];
      if (this.activeTotalBlockButton === TotalBlockButtons.Tip) {
        if (isFloat) {
          const totalAround = Math.ceil(this.cartEntriesTotalAmount);
          const decimal = MathUtils.roundHalfUp(totalAround - this.cartEntriesTotalAmount, 2);
          this.topPopularNote = this.createPopularNote(decimal);
        } else {
          this.topPopularNote = this.createPopularNote(0);
        }
      } else if (this.activeTotalBlockButton === TotalBlockButtons.TotalAndTip) {
        let total = this.cartEntriesTotalAmount;
        if (isFloat) {
          total = Math.ceil(total);
        }
        this.topPopularNote = this.createPopularNote(total);
        values = values.map((value) => value + total);
      }
    }

    this.popularNotesList = values.map((value) => this.createPopularNote(value));
  }

  public keyboardClickHandler(symbol: any, type: string) {
    if (this.shouldCleanPrice) {
      this.shouldCleanPrice = false;
      this.priceCents = '';
    }

    const { priceCents } = this;
    if (this.price === undefined) {
      return;
    }
    if (type === 'number') {
      if (symbol === '0' && priceCents.length === 0) {
        return false;
      }
      const tempCents = '' + priceCents + symbol;
      const tempDecimal = parseInt(tempCents) / 100;
      // TODO REMOVE HARDCODED MAX AMOUNT
      if (tempDecimal < 1000000) {
        this.priceCents = tempCents;
        this.handleUpdatePrice(tempDecimal, tempCents);
      }
    } else if (type === 'special') {
      let tempCents = '' + priceCents;
      let tempDecimal = parseInt(tempCents) / 100;
      switch (symbol) {
        case 'delete':
          if (tempCents.length > 0) {
            tempCents = tempCents.substring(0, tempCents.length - 1);
            tempDecimal = parseInt(tempCents) / 100;

            if (tempCents.length === 0) {
              tempCents = '';
              tempDecimal = 0;
            }

            this.priceCents = tempCents;
            this.handleUpdatePrice(tempDecimal, tempCents);
          }
          break;
        case 'double_zero':
          this.keyboardClickHandler(0, 'number');
          this.keyboardClickHandler(0, 'number');
          break;
      }
    }
  }

  private handleUpdatePrice(customPrice: number, customPriceCents: string) {
    this.posGetCustomPrice.emit({ customPrice, customPriceCents });
  }

  private setPriceCents() {
    if (this.price === undefined) {
      return;
    }
    this.priceCents = '0' + MathUtils.roundHalfUp(this.price * 100, 2).toString();
  }

  private createPopularNote(value: number) {
    const cellText = value.toFixed(2);
    const popularNote: IPopularNote = {
      cellText,
      cellValue: value,
    };
    return popularNote;
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}
@NgModule({
  imports: [CommonModule, PrinterLinkModule],
  declarations: [VirtualKeyboard, VirtualKeyboardCell],
  exports: [VirtualKeyboard],
})
export class VirtualKeyboardModule {}
