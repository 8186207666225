<pos-search
  *ngIf="showSearch"
  placeholder="common_search"
  [autofocus]="true"
  (onSearch)="handleSearchValue($event)"
  (onClearValue)="clearSearch()"
></pos-search>
<pos-list-header>
  <pos-list-col col="id" class="col-appointment-id">{{ 'common_list_col_id' | translate }}</pos-list-col>
  <pos-list-col col="start-date">{{ 'common_list_col_start_date' | translate }}</pos-list-col>
  <pos-list-col col="service">{{ 'common_list_col_service' | translate }}</pos-list-col>
  <pos-list-col col="duration">{{ 'common_list_col_duration' | translate }}</pos-list-col>
  <pos-list-col col="price">{{ 'common_list_col_price' | translate }}</pos-list-col>
  <pos-list-col col="customer">{{ 'common_list_col_customer' | translate }}</pos-list-col>
  <pos-list-col col="employee">{{ 'common_list_col_employee' | translate }}</pos-list-col>
</pos-list-header>
