import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replacePipe' })
export class ReplacePipe implements PipeTransform {
  transform(value: any, what: string, on: string = '') {
    return value.replace(what, on);
  }
}
@NgModule({
  declarations: [ReplacePipe],
  exports: [ReplacePipe],
})
export class ReplacePipeModule {}
