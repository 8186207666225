<div class="mobile-table-guest-selection-holder" (click)="sectionClick()" tappable>
  <div *ngIf="isGastroMode && !isMultipleGuests">
    <a class="btn-select-table" (click)="changeTable()" tappable>
      <i class="custom-icon custom-icon-table"></i>
      <span class="table-name" *ngIf="!activeInvoice.gastronomyTableName">{{ 'no_table' | translate }}</span>
      <span class="table-name" *ngIf="activeInvoice.gastronomyTableName">{{ activeInvoice.gastronomyTableName }}</span>
    </a>
    <printer-link class="btn-print-gastro-kitchen-invoice" posMode="kitchen" [invoice]="activeInvoice"> </printer-link>
  </div>

  <div *ngIf="isGastroMode && isMultipleGuests" class="multiple-guest">
    <a class="btn-select-table" (click)="changeTable()" tappable>
      <i class="custom-icon custom-icon-table"></i>
      <span class="table-name" *ngIf="!activeInvoice.gastronomyTableName">{{ 'no_table' | translate }}</span>
      <span class="table-name" *ngIf="activeInvoice.gastronomyTableName">{{ activeInvoice.gastronomyTableName }}</span>
    </a>
    <printer-link
      class="btn-print-gastro-kitchen-invoice"
      posMode="kitchen"
      [invoice]="activeInvoice"
      [onlyIcon]="isMultipleGuests"
      [showUnprintedKitchenItems]="showUnprintedKitchenItems"
      [showGuestOnReceipt]="isMultipleGuests"
      [showGuestPopup]="showGuestPopup"
    >
    </printer-link>
    <guest-button
      [showCheckbox]="false"
      [marker]="guestNumber"
      [name]="guestName"
      colorMode="blue"
      (onClick)="openSelectGuestsPopup()"
      (swipe)="removeActiveGuest($event)"
    ></guest-button>
  </div>

  <div *ngIf="!isGastroMode && isMultipleGuests">
    <guest-button
      [showCheckbox]="false"
      [marker]="guestNumber"
      [name]="guestName"
      colorMode="blue"
      (onClick)="openSelectGuestsPopup()"
      (swipe)="removeActiveGuest($event)"
    ></guest-button>
  </div>
</div>
