import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Service } from '@pos-common/classes/service/service.class';
import { AlertService } from '../alert.service';
import { LogService } from '../logger/log.service';
import { ModalService } from '../modal.service';

@Injectable()
export class AppointmentModalService {
  constructor(
    private modalService: ModalService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private logService: LogService
  ) {}

  public openInternalNoteModal(note: string) {
    return this.openNoteModal(note, 'appointment_internal_note');
  }

  public openExternalNoteModal(note: string) {
    return this.openNoteModal(note, 'appointment_external_note', true);
  }

  public async openColorModal(color: string) {
    return new Promise<{ color?: string }>(async (resolve, reject) => {
      try {
        const { ColorModalPage } = await import('../../../../modules/modals/color-modal');
        const modal = await this.modalService.presentModal(ColorModalPage, { color });
        await modal.present();
        modal.onWillDismiss().then(({ data }) => resolve(data));
      } catch (error) {
        this.logService.error('AppointmentService', 'openColorModal', error);
        reject(error);
      }
    });
  }

  public async openCustomServiceModal(service: Service, isNew: boolean = false) {
    return new Promise<{ service?: Service }>(async (resolve, reject) => {
      try {
        const { CustomServiceModalPage } = await import('../../../../modules/modals/custom-service-modal');
        const modal = await this.modalService.presentModal(CustomServiceModalPage, { activeService: service, isNew });
        await modal.present();
        modal.onWillDismiss().then(({ data }) => resolve(data));
      } catch (error) {
        this.logService.error('AppointmentService', 'openCustomServiceModal', error);
        reject(error);
      }
    });
  }

  public async openDatesModal(startedAt: string, endedAt: string) {
    return new Promise<{ startedAt?: string }>(async (resolve, reject) => {
      try {
        const { SelectStartDateModalPage } = await import('../../../../modules/modals/select-start-date-modal');
        const modal = await this.modalService.presentModal(SelectStartDateModalPage, { startedAt, endedAt });
        await modal.present();
        modal.onWillDismiss().then(({ data }) => resolve(data));
      } catch (error) {
        this.logService.error('AppointmentService', 'openStartDateModal', error);
        reject(error);
      }
    });
  }

  public showDeleteConfirmation() {
    return new Promise<{ isDelete: boolean }>(async (resolve) => {
      const deleteConfirm = await this.alertService.create({
        header: this.translateService.instant('appointment_delete_confirm_title'),
        message: this.translateService.instant('appointment_delete_confirm_msg'),
        buttons: [
          {
            text: this.translateService.instant('common_cancel'),
            handler: () => resolve(null),
          },
          {
            text: this.translateService.instant('common_delete'),
            handler: () => resolve({ isDelete: true }),
          },
        ],
      });
      await deleteConfirm.present();
    });
  }

  public showCancelConfirmation(hasCustomer: boolean) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { CancelAppointmentModalPage } = await import('../../../../modules/modals/cancel-appointment-modal');
        const modal = await this.modalService.presentModal(CancelAppointmentModalPage, { hasCustomer });
        await modal.present();
        modal.onWillDismiss().then(({ data }) => resolve(data));
      } catch (error) {
        this.logService.error('AppointmentService', 'openStartDateModal', error);
        reject(error);
      }
    });
  }

  private openNoteModal(note: string, title: string, isDisabledEdit: boolean = false) {
    return new Promise<{ note?: string }>(async (resolve, reject) => {
      try {
        const { AppointmentNoteModalPage } = await import('../../../../modules/modals/appointment-note-modal');
        const modal = await this.modalService.presentModal(AppointmentNoteModalPage, { note, title, isDisabledEdit });
        await modal.present();
        modal.onWillDismiss().then(({ data }) => resolve(data));
      } catch (error) {
        this.logService.error('AppointmentService', 'openNoteModal', error);
        reject(error);
      }
    });
  }
}
