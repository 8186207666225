import { IMAGE_SIZES } from '@pos-common/classes/image.class';
import { arrayIsNotEmpty, query, Query } from '@paymash/capacitor-database-plugin';
import { Product as ProductEntity } from '@pos-common/classes/product.class';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { DbDaoService } from '@pos-common/services/db/db-dao.service';

export type ImageSize = typeof IMAGE_SIZES[keyof typeof IMAGE_SIZES];

const get3RandomProductsQueries = (): Query[] => {
  const visibleOnCustomerDisplay = {
    visibleOnCustomerDisplay: true,
  };
  const visible = {
    visible: true,
  };
  const isNew = {
    isNew: true,
  };
  const isSale = {
    isSale: true,
  };
  const images = {
    images: arrayIsNotEmpty(),
  };
  type variantTypes = 'new' | 'sale';
  const createQuery = (...types: variantTypes[]) => {
    const queryBuilder = query(visible, visibleOnCustomerDisplay, images);
    if (types.indexOf('new') > -1) queryBuilder.push(isNew);
    if (types.indexOf('sale') > -1) queryBuilder.push(isSale);
    return queryBuilder;
  };
  return [
    //
    createQuery('new'),
    createQuery('sale'),
    createQuery(),
  ];
};

export const getRandomProducts = async (
  dbDaoService: DbDaoService,
  processProducts: (product: ProductEntity, cancel: () => void) => Promise<void>,
  options: { pageSize: number },
) => {
  const queries = get3RandomProductsQueries();
  let isCancel = false;
  const cancel = () => isCancel = true;
  for (let query of queries) {
    const iterator = await dbDaoService.getAllDataIterator<object>(UPDATES_TYPES.Product.type, query, { pageSize: options.pageSize });
    try {
      let products: object[];
      while ((products = await iterator.next()) !== undefined) {
        for (let product of products) {
          await processProducts(new ProductEntity(product), cancel);
          if (isCancel) return;
        }
        if (isCancel) return;
      }
    } finally {
      await iterator.dispose();
    }
    if (isCancel) return;
  }
};
