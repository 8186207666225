import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { CALENDAR_DATE_RANGE, CALENDAR_TIME_RANGE } from '@pos-common/constants';
import { ICalendarDateRange } from '@pos-common/interfaces';
import { CalendarService } from '@pos-common/services/system/calendar/calendar.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { LocalizationUtils } from '@pos-common/services/utils/localization.utils';
import * as moment from 'moment';

@Component({
  selector: 'pos-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class DateRangeComponent implements OnInit {
  @Input() activeTimeRange: CALENDAR_TIME_RANGE;
  public title: string;

  get isCalendarView() {
    return this.activeTimeRange !== CALENDAR_TIME_RANGE.list;
  }

  constructor(
    private calendarService: CalendarService,
    private localizationUtils: LocalizationUtils,
    private subSinkService: SubSinkService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subSinkService.sink = this.calendarService.getCurrentDate().subscribe((currentDate) => {
      this.title = this.getTitle(currentDate);
      this.cdr.detectChanges();
    });
  }

  handleClickPrev() {
    this.calendarService.setDateRange(CALENDAR_DATE_RANGE.PREV);
  }

  handleClickNext() {
    this.calendarService.setDateRange(CALENDAR_DATE_RANGE.NEXT);
  }

  private getTitle(currentDate: ICalendarDateRange) {
    const { start, end } = currentDate;
    switch (this.activeTimeRange) {
      case CALENDAR_TIME_RANGE.month:
        return moment(start).format('MMMM YYYY');
      case CALENDAR_TIME_RANGE.week:
        const dateStart = this.localizationUtils.getFormattedDate(start, 'dateFormat');
        const dateEnd = this.localizationUtils.getFormattedDate(end, 'dateFormat');
        return `${dateStart} - ${dateEnd}`;
      default:
        return this.localizationUtils.getFormattedDate(start, 'dateFormat');
    }
  }
}
