import { Injectable } from '@angular/core';
import { SplashScreen } from '@pos-common/classes/capacitor-plugins/capacitor-plugins.class';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';

@Injectable()
export class SplashScreenService {
  constructor(private setTimeoutUtil: SetTimeoutUtil) {}

  hide(): void {
    const splashScreenEl: HTMLElement = document.getElementById('splash-holder');
    if (!splashScreenEl) {
      return;
    }
    this.setTimeoutUtil.addVisualEffect(1000).then(() => {
      splashScreenEl.remove();
      SplashScreen.hide();
    });
  }

  show(): void {
    SplashScreen.show();
  }
}
