import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { CALENDAR_VIEWS } from '@pos-common/constants';
import { CalendarFilterModalBaseComponent } from '@pos-modules/shared/components';

@Component({
  selector: 'pos-calendar-filter-modal',
  templateUrl: './calendar-filter-modal.page.html',
  styleUrls: ['./calendar-filter-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarFilterModalPage extends CalendarFilterModalBaseComponent {
  protected view = CALENDAR_VIEWS.CALENDAR;
}
