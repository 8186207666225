/**
 * Created by y.belinsky on 10/25/16.
 */

export const PRINTER_RECEIPT = {
  receiptTestData: {
    testInvoice: {
      "invoiceEntries": [{
        "GenerateRandomColor": {},
        "uuid": "4a4ad1d8-1e5b-4ca2-be9a-b5df9323c433",
        "type": "PRODUCT",
        "name": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "quantity": 1,
        "price": 12,
        "taxRate": 0.08,
        "totalTaxAmount": 0.89,
        "totalAmount": 12,
        "position": 0,
        "note": null,
        "bgColor": "hsla(4.804804804804805,46%,65%,1)",
        "deleted": false,
        "discountedPrice": 12,
        "discount": 0,
        "discountPercentage": 0,
        "totalDiscountAmount": 0,
        "employee": {"uuid": "40a6b2a5-b746-42a4-b9b6-5082fc76d23e"},
        "productVariant": {"uuid": "dcd74cdb-61d0-4f40-872f-2f4f9fa24314"},
        "productCategory": null,
        "store": {"uuid": "4f6da737-0b6f-45a6-a29d-ebcc317f6639"},
        "inventoryStore": {"uuid": "4f6da737-0b6f-45a6-a29d-ebcc317f6639"}
      }, {
        "GenerateRandomColor": {},
        "uuid": "a6cdcc23-abca-4189-97c1-111f469babe3",
        "type": "PRODUCT",
        "name": "First product",
        "quantity": 1,
        "price": 123,
        "taxRate": 0.08,
        "totalTaxAmount": 9.11,
        "totalAmount": 123,
        "position": 1,
        "note": null,
        "bgColor": "hsla(146.06606606606607,42%,65%,1)",
        "deleted": false,
        "discountedPrice": 123,
        "discount": 0,
        "discountPercentage": 0,
        "totalDiscountAmount": 0,
        "employee": {"uuid": "40a6b2a5-b746-42a4-b9b6-5082fc76d23e"},
        "productVariant": {"uuid": "f95e4eb4-5295-4283-8917-7469c7b98c50"},
        "productCategory": null,
        "store": {"uuid": "4f6da737-0b6f-45a6-a29d-ebcc317f6639"},
        "inventoryStore": {"uuid": "4f6da737-0b6f-45a6-a29d-ebcc317f6639"}
      }, {
        "GenerateRandomColor": {},
        "uuid": "661e6836-b601-4f18-9b6d-3bc18272d17c",
        "type": "INDIVIDUAL",
        "name": "Custom",
        "quantity": 1,
        "price": 3,
        "taxRate": 0.08,
        "totalTaxAmount": 0.22,
        "totalAmount": 3,
        "position": 2,
        "note": null,
        "bgColor": "hsla(179.93993993993993,45%,65%,1)",
        "deleted": false,
        "discountedPrice": 3,
        "discount": 0,
        "discountPercentage": 0,
        "totalDiscountAmount": 0,
        "employee": {"uuid": "40a6b2a5-b746-42a4-b9b6-5082fc76d23e"},
        "productVariant": null,
        "productCategory": null,
        "store": null,
        "inventoryStore": null
      }],
      "invoicePayments": [{
        "uuid": "2f8e1166-90d1-4750-ba3c-d626d2054eff",
        "method": "CASH",
        "amount": 138,
        "amountGiven": 200,
        "merchantReceipt": null,
        "cardholderReceipt": null
      }],
      "uuid": "81779fbf-1955-4d49-8ba6-239770671242",
      "publicUuid": "c956519a96c6413f9e76ac4fce894e0b",
      "isDraft": false,
      "isPaid": true,
      "invoiceId": "15-15",
      "amount": 138,
      "date": "2016-10-27T12:46:52.000Z",
      "modificationDate": "2016-10-27T12:47:15.592Z",
      "isNet": false,
      "salesChannel": "POS",
      "receiptType": "EPSON",
      "discount": 0,
      "discountPercentage": 0,
      "customerDiscount": 0,
      "customerDiscountPercentage": 0,
      "discountCode": null,
      "employee": {"uuid": "40a6b2a5-b746-42a4-b9b6-5082fc76d23e"},
      "customer": null,
      "billingAddress": null,
      "store": {"uuid": "4f6da737-0b6f-45a6-a29d-ebcc317f6639"},
      "inventoryStore": {"uuid": "4f6da737-0b6f-45a6-a29d-ebcc317f6639"},
      "gastronomyTable": null,
      "gastronomyTableName": null,
      "isCancelled": false,
      "cancellationReason": null,
      "cancellingEmployee": null,
      "totalTaxesAmount": 10.22,
      "totalProductsCount": 3
    },
    testEmployee: {
      "uuid": "40a6b2a5-b746-42a4-b9b6-5082fc76d23e",
      "firstName": "Admin",
      "lastName": "",
      "email": "y@y.ch",
      "image": null,
      "shortPassword": "",
      "shortPasswordType": "NONE",
      "hasReportPermission": true,
      "hasCancellationPermission": true,
      "hasSettingsPermission": true,
      "hasAdminPermission": true
    },
    testCompany: {
      "uuid": "f765de98-6de0-4c48-8ec2-845bad3e76a5",
      "name": "Company name",
      "address": {
        "address": "Street",
        "postalCode": "Zip code",
        "city": "City",
        "country": "UA",
        "phoneNrMobile": "Phone"
      },
      "locale": {
        "dateFormat": "DD_MM_YYYY_DOT",
        "timeFormat": "H24",
        "startWeekOn": "MONDAY",
        "numberFormat": "APOSTROPHE_DOT",
        "currency": "CHF",
        "currencyRounding": "ZERO_DOT_ZERO_FIVE",
        "unitSystem": "METRIC",
        "weightUnit": "KILOGRAM"
      },
      "email": "y@y.ch",
      "vatNumber": "CHE- 123.456.789",
      "isQRCodeOnReceiptEnabled": false,
      "isRestaurantEnabled": false,
      "defaultPaymentMethod": {"uuid": "35c5e998-0830-4d87-86b7-160a492a65bc"},
      "defaultVATRate": {"uuid": "4543ed30-8bdb-4aa7-bde0-a3e2379ec585"},
      "meta": {"revision": 2, "created": 1477562499505, "version": 0, "updated": 1477564216144},
    },
    testStore: {
      "name": "Store Name",
      "uuid": "4f6da737-0b6f-45a6-a29d-ebcc317f6639"
    }
  }
};
