<ion-header>
  <ion-toolbar primary>
    <ion-buttons slot="start">
      <ion-menu-toggle>
        <button>
          <pos-icon ionName="menu-outline"></pos-icon>
        </button>
      </ion-menu-toggle>
      <button tappable (click)="getReportData()" wave-effect>
        <i class="custom-icon custom-icon-webview-refresh"></i>
      </button>
      <button tappable (click)="printPdf()" wave-effect>
        <i class="custom-icon custom-icon-paid-invoices-printer"></i>
      </button>
      <button tappable (click)="printPlain()" wave-effect>
        <i class="custom-icon custom-icon-paid-invoices-epson"></i>
      </button>
      <button *ngIf="terminalProvider" tappable (click)="getBalance()" class="balance-btn" wave-effect>
        <ion-icon name="calculator-outline"></ion-icon>
        <span>{{'balance' | translate}}</span>
      </button>
      <sync-icon></sync-icon>
    </ion-buttons>
    <ion-buttons slot="primary">
      <button class="btn-current-user">
        <current-user></current-user>
      </button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <div class="report-type">
      <a [class.active]="shift === 1" tappable (click)="tabChangeEvent(1)" ripple-effect>{{'day_report_yesterday' | translate}}</a>
      <a [class.active]="shift === 0" tappable (click)="tabChangeEvent(0)" ripple-effect>{{'day_report_today' | translate}}</a>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content no-bounce>
  <day-report-details *ngIf="plainData" [plainData]="plainData.properties" [reportDate]="reportDate"> </day-report-details>
</ion-content>
