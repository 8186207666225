export class KassenSichVReceipt {
  qrCode: string;
  transactionId: string;
  transactionNumber: number;
  signatureCounter: number;
  tssId: string;
  timeStart: string;
  timeEnd: string;
  signature: string;
  signatureAlgorithm: string;
  certificateSerial: string;

  constructor(data: any) {
    this.qrCode = data.qrCode || null;
    this.transactionId = data.transactionId || null;
    this.transactionNumber = data.transactionNumber || null;
    this.signatureCounter = data.signatureCounter || null;
    this.tssId = data.tssId || null;
    this.timeStart = data.timeStart || null;
    this.timeEnd = data.timeEnd || null;
    this.signature = data.signature || null;
    this.signatureAlgorithm = data.signatureAlgorithm || null;
    this.certificateSerial = data.certificateSerial || null;
  }
}
