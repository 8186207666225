import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SecurityService } from '../../common/services/system/security.service';
import { PrinterService } from '../../common/services/system/printer.service';
import { AlertService } from '../../common/services/system/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { DayReportData } from '../../common/classes/dayreport.class';
import { GoogleAnalyticsService } from '../../common/services/system/google-analitycs.service';
import * as moment from 'moment';
import { ReceiptPrinter } from '../../common/services/system/receipt-printers/classes/receipt-printer.class';
import { IngenicoService } from '../../common/services/system/ingenico.service';
import { PaymentService } from '../../common/services/system/payment.service';
import { PAYMENT_PROVIDERS } from '../../common/constants/payment-providers.const';
import { LoadingService } from '../../common/services/system/loading.service';
import { ReceiptBuilder } from '../../common/services/system/receipt-printers/classes/receipt-builder.class';
import { ReceiptTextAlign } from '../../common/services/system/receipt-printers/enum/receipt-text-align.enum';
import { TimApiService } from '../../common/services/system/timApi.service';
import { ReceiptPrinterModeTypes } from '../../common/constants/receipt-printer-mode-types';
import { ReceiptBuilderService } from '../../common/services/system/receipt-builder.service';
import { LogService } from '../../common/services/system/logger/log.service';
import { ReportApiService } from '@pos-common/services/api/report-api.service';
import { stringifySafety } from '@pos-common/services/system/logger';
import { ErrorLevel } from '@spryrocks/logger';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';

@Component({
  selector: 'day-report-page',
  templateUrl: './day-report.page.html',
  styleUrls: ['./day-report.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DayReportPage implements OnInit, OnDestroy {
  public loadingBarWidth: any = false;
  plainData: DayReportData;
  public printerErrorAlert: any;
  public shift: number = 0;
  private reportQueryParameters: string = null;
  public reportDate: any;
  public terminalProvider: any = null;

  constructor(
    private reportApiService: ReportApiService,
    private securityService: SecurityService,
    private GoogleAnalyticsService: GoogleAnalyticsService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private PaymentService: PaymentService,
    private loadingService: LoadingService,
    private IngenicoService: IngenicoService,
    private timApiService: TimApiService,
    private receiptBuilderService: ReceiptBuilderService,
    private printerService: PrinterService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.terminalProvider = this.PaymentService.activeTerminals();
    this.GoogleAnalyticsService.trackView('DayReportPage');
    this.tabChangeEvent(this.shift);
  }

  ngOnDestroy() {
    if (this.printerErrorAlert) {
      this.printerErrorAlert.dismiss();
    }
  }

  private async makeTimApiDayliClouser() {
    try {
      const terminal = this.timApiService.getTerminalInstantly();
      await this.timApiService.connectToTerminal(terminal);
      return this.timApiService.balance();
    } catch (error) {
      throw this.timApiService.getTimApiExceptionMessage(error);
    }
  }

  private setReportQueryParameters(): void {
    let queryParameters = '';
    const dataForQuery = moment().subtract(this.shift, 'day');
    const date = moment(dataForQuery).format('YYYY-MM-DD');
    queryParameters += `?date=${date}&fromIso=${date}`;
    this.reportDate = moment(dataForQuery);
    const showSubcategories = false;
    queryParameters += `&showSubcategories=${showSubcategories}`;
    const tf = 'day';
    queryParameters += `&tf=${tf}&toIso=${date}`;
    const storeUuid = this.securityService.getActiveStore().uuid;
    queryParameters += `&storeuuid=${storeUuid}`;
    queryParameters += `&lang=${this.translateService.currentLang}`;
    this.reportQueryParameters = queryParameters;
  }

  public getReportData(): void {
    this.plainData = null;
    this.reportApiService.getReportData(this.reportQueryParameters).subscribe(
      (data) => {
        this.logService.info(
          'DayReportPage',
          `getReportData: Day report total revenues - ${stringifySafety(data.data.properties.revenues)}`
        );
        this.plainData = new DayReportData(data.data);
      },
      (err) => this.logService.error('DayReportPage', 'getReportData:reportApiService:getReportData', err, ErrorLevel.Low)
    );
  }

  public printPdf() {
    const url = `${SERVER_CONFIG.API_URL}report/pdf${this.reportQueryParameters}`;
    this.printerService
      .printPdfFileByUrl(url)
      .catch((err) => this.logService.error('DayReportPage', 'printPdf:printerService:printPdfFileByUrl', err, ErrorLevel.Low));
  }

  private async showAlertMsg(translationKey) {
    this.printerErrorAlert = await this.alertService.create({
      header: this.translateService.instant('common_error'),
      subHeader: this.translateService.instant(translationKey),
      buttons: ['OK'],
    });
    await this.printerErrorAlert.present();
  }

  public printPlain() {
    let printingErrorCount = 0;
    this.printerService.getPrinterListOnce(ReceiptPrinterModeTypes.POS).subscribe((printers) => {
      if (printers.length > 0) {
        printers.forEach((printer: ReceiptPrinter) => {
          this.printerService
            .printReceipt(printer, this.receiptBuilderService.makeDayReportReceiptDataForPrinter(this.plainData, printer, this.reportDate))
            .catch(async (error) => {
              printingErrorCount++;
              if (printingErrorCount === printers.length)
                this.printerErrorAlert = await this.printerService.showPrinterErrorMessageByType(error.type);
            });
        });
        this.GoogleAnalyticsService.trackEvent('Printer', 'printDayReport');
      } else {
        this.showAlertMsg('receipt_printer_default_printer_does_not_set');
      }
    });
  }

  public tabChangeEvent(shift: number) {
    this.shift = shift;
    this.setReportQueryParameters();
    this.getReportData();
  }

  public getBalance() {
    this.loadingService.showLoadingItem();

    switch (this.terminalProvider) {
      case PAYMENT_PROVIDERS.SIX:
        this.makeTimApiDayliClouser()
          .then((receipt) => this.receiptBuilderService.makeTerminalReceipt(receipt))
          .then((data) => this.notifyMsg(data))
          .catch((err) => this.notifyMsg(null, err));
        break;
      case PAYMENT_PROVIDERS.OPI:
        this.IngenicoService.balance()
          .then((data) => {
            if (!this.IngenicoService.useIngenicoPrinter.get()) {
              const receiptData = data.filter((val) => val !== undefined && String.fromCharCode).join('\n');
              const receiptBuilder = new ReceiptBuilder();
              receiptBuilder.addTextAlign(ReceiptTextAlign.center);
              receiptBuilder.addText(receiptData);
              receiptBuilder.addCut();
              this.notifyMsg(receiptBuilder);
            } else {
              this.loadingService.hideLoadingItem();
            }
          })
          .catch((err) => this.notifyMsg(null, err));
        break;
      default:
        this.loadingService.hideLoadingItem();
        break;
    }
  }

  private async notifyMsg(msg: ReceiptBuilder, err?: any) {
    this.loadingService.hideLoadingItem();
    const balanceAlert = await this.alertService.create();

    if (err) {
      const message = typeof err === 'object' ? JSON.stringify(err) : err;
      balanceAlert.header = this.translateService.instant('common_error');
      balanceAlert.message = message;
      balanceAlert.buttons = ['OK'];
    } else {
      const message = msg ? msg.receiptAsPlainText : '';
      const htmlMsg = message.replace(/\n/g, '<br/>');
      balanceAlert.header = this.translateService.instant('terminal_balance');
      balanceAlert.message = `<pre>${htmlMsg}</pre>`;
      balanceAlert.cssClass = 'balance-reciept';
      balanceAlert.buttons = [
        {
          text: this.translateService.instant('common_cancel'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('common_print'),
          handler: () => {
            balanceAlert.dismiss().catch((err) => this.logService.error('DayReportPage', 'notifyMsg:dismiss', err));
            this.printerService.printReceiptOnMultiplePrinters(msg).catch((err) => {
              this.showAlertMsg(err);
            });
            return false;
          },
        },
      ];
    }

    await balanceAlert.present();
  }
}
