import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { CALENDAR_TIME_RANGE } from '@pos-common/constants';

@Component({
  selector: 'pos-time-range',
  templateUrl: './time-range.component.html',
  styleUrls: ['./time-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeRangeComponent implements OnInit {
  @Input() activeTimeRange: CALENDAR_TIME_RANGE;
  @Output() posSelectTime = new EventEmitter<CALENDAR_TIME_RANGE>();
  @Output() posSelectToday = new EventEmitter<void>();

  public timeRangeList = [];
  public slideOpts = {
    autoplay: false,
    slidesPerView: 5,
  };

  ngOnInit() {
    this.timeRangeList = Object.values(CALENDAR_TIME_RANGE);
  }

  selectTime(timeRange: CALENDAR_TIME_RANGE) {
    if (timeRange === CALENDAR_TIME_RANGE.today) {
      this.posSelectToday.emit();
      return;
    }

    if (timeRange === this.activeTimeRange) {
      return;
    }
    this.activeTimeRange = timeRange;
    this.posSelectTime.emit(timeRange);
  }
}
