/**
 * Created by maksymkunytsia on 7/5/16.
 */

import { Injectable } from '@angular/core';
import { SecurityService } from '../system/security.service';
import { SERVER_CONFIG } from '../../constants/server.const';
import { Invoice } from '../../classes/invoice.class';
import { SecuredResponse } from '../../classes/secured-response.class';

@Injectable()
export class InvoicesApiService {
  constructor(private SecurityService: SecurityService) {}

  public getInvoiceByUuid(uuid: string) {
    return this.SecurityService.doSecureRequest(`${SERVER_CONFIG.API_URL}invoices/${uuid}`, 'get', null).then(
      (response: SecuredResponse) => new Invoice(response.data.properties)
    );
  }

  public upsertInvoice(invoiceData) {
    return this.SecurityService.doSecureRequest(`${SERVER_CONFIG.API_URL}invoices/${invoiceData.uuid}`, 'put', invoiceData);
  }

  public deleteInvoiceDraft(invoiceUUID: string) {
    return this.SecurityService.doSecureRequest(`${SERVER_CONFIG.API_URL}invoices/${invoiceUUID}`, 'delete', null);
  }

  public updateInvoice(invoice: Invoice): Promise<Invoice> {
    return this.upsertInvoice(invoice).then((data) => new Invoice(data.data.properties));
  }

  public upsertCancelPartially(invoiceData: any) {
    return this.SecurityService.doSecureRequest(
      `${SERVER_CONFIG.API_URL}invoices/${invoiceData.uuid}/cancelPartially`,
      'post',
      invoiceData
    ).then((data) => new Invoice(data.data.properties));
  }

  public upsertCancelFully(invoiceData: any) {
    return this.SecurityService.doSecureRequest(`${SERVER_CONFIG.API_URL}invoices/${invoiceData.uuid}/cancel`, 'post', invoiceData).then(
      (data) => new Invoice(data.data.properties)
    );
  }
}
