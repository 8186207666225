import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SetTimeoutUtil {
  addVisualEffect(time: number = 0) {
    return new Promise<void>((resolve) => setTimeout(resolve, time));
  }

  fixAngularZoneConflict(time: number = 0) {
    return new Promise<void>((resolve) => setTimeout(resolve, time));
  }

  waitTimeAndDo(time: number = 0) {
    return new Promise<void>((resolve) => setTimeout(resolve, time));
  }
}
