import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
  NgModule,
} from '@angular/core';
import { PickerColumn, PickerColumnOption } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as Pikaday from 'pikaday';
import { PickerColumnComponentModule } from '../picker-column/picker-column.component';

@Component({
  selector: 'pos-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit {
  @Input() value: string;
  @Output() posSetValue = new EventEmitter<string>();
  @ViewChild('calendar', { static: true }) calendarEl: ElementRef;
  @HostBinding('class.pos-calendar') isCalendar = true;
  @HostBinding('class.show-picker') showPicker = false;
  @HostBinding('class.pos-picker') isPosPicker = true;
  public colMonths: PickerColumn;
  public colYears: PickerColumn;
  private calendar: Pikaday;
  private readonly diffYear = 2;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.calendar = this.createCalendar();
    this.colYears = this.getColYears();
    this.colMonths = this.getColMonths();
  }

  @HostListener('touchstart', ['$event'])
  handleOnTouch(event: PointerEvent) {
    this.setShowPicker(event.target as HTMLElement);
    event.stopPropagation();
  }

  @HostListener('click', ['$event'])
  handleOnClick(event: PointerEvent) {
    this.setShowPicker(event.target as HTMLElement);
  }

  handleMonthsColChange(col: PickerColumn) {
    const selectedValue = col.options[col.selectedIndex].value;
    const date = new Date(this.value);
    date.setMonth(selectedValue);
    this.updateValue(date);
  }

  handleYearsColChange(col: PickerColumn) {
    const selectedValue = col.options[col.selectedIndex].value;
    const date = new Date(this.value);
    date.setFullYear(selectedValue);
    this.updateValue(date);
  }

  private updateValue(date: Date) {
    const dateISO = date.toISOString();
    this.value = dateISO;
    this.posSetValue.emit(dateISO);
    this.calendar.setDate(date);
  }

  private getColMonths() {
    const currentMonth = new Date(this.value).getMonth();
    const months: string[] = this.translateService.instant('calendar_months').split(',');
    let options: PickerColumnOption[] = [];
    for (let index = 0; index < months.length; index++) {
      options = [...options, { value: index, text: months[index] }];
    }
    const selectedIndex = options.findIndex((opt) => opt.value === currentMonth);
    return { name: 'months', options, selectedIndex };
  }

  private getColYears() {
    const currentYear = new Date(this.value).getFullYear();

    const minYear = currentYear - this.diffYear;
    const maxYear = currentYear + this.diffYear;
    let options: PickerColumnOption[] = [];
    for (let index = minYear; index <= maxYear; index++) {
      options = [...options, { value: index, text: index.toString() }];
    }
    const selectedIndex = options.findIndex((opt) => opt.value === currentYear);
    return { name: 'years', options, selectedIndex };
  }

  private setCurrentDate(date: Date) {
    const currentDate = new Date(this.value);
    const year = date.getFullYear();
    this.colYears = this.updateCol(this.colYears, year);
    const month = date.getMonth();
    this.colMonths = this.updateCol(this.colMonths, month);
    const dayOfMonth = date.getDate();

    currentDate.setFullYear(year, month, dayOfMonth);
    const dateISO = currentDate.toISOString();
    this.value = dateISO;
    this.posSetValue.emit(dateISO);
  }

  private updateCol(col: PickerColumn, value: number) {
    const selectedIndex = col.options.findIndex((opt) => opt.value === value);
    return { ...col, selectedIndex };
  }

  private createCalendar() {
    const date = new Date(this.value);
    const minDate = moment(date).subtract(this.diffYear, 'years').month(0).toDate();
    const maxDate = moment(date).add(this.diffYear, 'years').month(11).toDate();
    const calendar = new Pikaday({
      field: this.calendarEl.nativeElement,
      defaultDate: date,
      setDefaultDate: true,
      container: this.calendarEl.nativeElement,
      minDate,
      maxDate,
      firstDay: 1,
      onClose: () => {
        calendar.show();
      },
      onSelect: (date: Date) => {
        this.setCurrentDate(date);
      },
      i18n: {
        previousMonth: '',
        nextMonth: '',
        months: this.translateService.instant('calendar_months').split(','),
        weekdays: [],
        weekdaysShort: this.translateService.instant('calendar_weekdays_short').split(','),
      },
    });
    calendar.show();
    return calendar;
  }

  private setShowPicker(target: HTMLElement) {
    if (target.classList.contains('pika-label')) {
      this.showPicker = !this.showPicker;
    }
  }
}
@NgModule({
  imports: [PickerColumnComponentModule],
  declarations: [DatePickerComponent],
  exports: [DatePickerComponent],
})
export class DatePickerComponentModule {}
