import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { NavController, mdTransitionAnimation } from '@ionic/angular';
import { ProductDetailsModal } from '../product-details-modal/product-details-modal.component';
import { Invoice } from '../../../common/classes/invoice.class';
import { CartService } from '../../../common/services/system/cart.service';
import { DbDaoService } from '../../../common/services/db/db-dao.service';
import { PRODUCT_TYPES } from '../../../common/constants/product-types';
import { UPDATES_TYPES } from '../../../common/constants/updates-types.const';
import { SERVER_CONFIG } from '../../../common/constants/server.const';
import { ModalService } from '../../../common/services/system/modal.service';
import { SecurityService } from '../../../common/services/system/security.service';
import { InvoicesService } from '../../../common/services/system/invoices.service';
import { PrinterService } from '../../../common/services/system/printer.service';
import { CameraScannerService } from '../../../common/services/system/camera-scanner.service';
import { InvoiceEntry } from '../../../common/classes/invoice-entry.class';
import * as lodash from 'lodash';
import { TableEnforceService } from '../../../common/services/system/table-select-enforcement.service';
import { IndividualProductModal } from '../individual-product-modal/individual-product-modal.component';
import { InvoiceDiscountModal } from '../invoice-discount-modal/invoice-discount-modal.component';
import { GoogleAnalyticsService } from '../../../common/services/system/google-analitycs.service';
import { LogService } from '../../../common/services/system/logger/log.service';
import { SwipeDirection } from '../../../common/constants/swipe-direction.enum';
import { RouteNavigationService } from '../../../common/services/system/route-navigation/route-navigation.service';
import { ROUTE_URLS } from '../../../common/constants/route-urls.const';
import { newInvoiceEntryElm } from 'app/app.animations';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import { InvoiceDetailsSwipeEvent } from '@pos-modules/invoice-details/interfaces/invoice-details.events';
import { MultipleGuestsService } from '@pos-common/services/system/multiple-guests/multiple-guests.service';
import { GuestDetailsModalComponent } from '@pos-common/components/guest-details-modal/guest-details-modal.component';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';

@Component({
  selector: '<cart></cart>',
  styleUrls: ['cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'cart.component.html',
  animations: [newInvoiceEntryElm()],
  providers: [SubSinkService],
})
export class CartComponent implements OnInit {
  public individualProductModal = null;
  public productDetailsModal: any = null;
  public invoiceDiscountModal = null;
  public activeInvoice: Invoice = null;
  public SERVER_CONFIG: any = SERVER_CONFIG;
  public reimburseDenyAlert: any;
  public invoiceEntries: InvoiceEntry[] = [];
  public invoiceEntryGuests: InvoiceEntryGuest[] = [];
  public isGastroMode: boolean = false;
  public showUnprintedKitchenItems = false;
  public isMultipleGuests = false;
  private _isBarcodeScannerOpened = false;

  constructor(
    private navController: NavController,
    private CartService: CartService,
    private ModalService: ModalService,
    private DbDaoService: DbDaoService,
    private SecurityService: SecurityService,
    private InvoicesService: InvoicesService,
    private printerService: PrinterService,
    private CameraScannerService: CameraScannerService,
    private elementRef: ElementRef,
    private tableEnforceService: TableEnforceService,
    private GoogleAnalyticsService: GoogleAnalyticsService,
    private routeNavigationService: RouteNavigationService,
    private platformService: PlatformService,
    private multipleGuestsService: MultipleGuestsService,
    private cdr: ChangeDetectorRef,
    private subSinkService: SubSinkService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.isGastroMode = this.SecurityService.getLoggedCompanyData()['isRestaurantEnabled'];
    this.activeInvoice = this.CartService.getActiveInvoice();
    if (this.activeInvoice) {
      const activeInvoiceEntries = this.activeInvoice.getActiveInvoiceEntries();
      this.invoiceEntries = this.InvoicesService.mergeInvoiceEntries(activeInvoiceEntries);
      this.invoiceEntries = lodash.sortBy(this.invoiceEntries, ['guestNumber']);
    }
    this.subSinkService.sink = this.CartService.activeInvoiceUpdated.subscribe((data) => {
      this.activeInvoice = new Invoice(data);
      this.activeInvoice.invoiceEntries = this.InvoicesService.mergeInvoiceEntries(this.activeInvoice.getActiveInvoiceEntries());
      const { current } = this.routeNavigationService;
      const employeeSelectPageIsActive = current === ROUTE_URLS.employee;
      if (this.activeInvoice.invoiceEntries.length === 0 && !employeeSelectPageIsActive && this.platformService.isMobile) {
        this.navController
          .navigateRoot(ROUTE_URLS.collection)
          .catch((err) => this.logService.error('CartComponent', 'ngOnInit:navigateRoot:collection', err));
      }
      this.handleInvoiceEntryUpdates(this.activeInvoice.invoiceEntries);

      if (this.multipleGuestsService.isMultipleGuests) {
        const shouldResetGuests = this.activeInvoice.isPaid || this.activeInvoice.isSeparated || this.activeInvoice.paymentMethod;
        if (shouldResetGuests) {
          this.multipleGuestsService.reset();
        }
      }
      this.cdr.detectChanges();
    });
    this.subSinkService.sink = this.CameraScannerService.getBarcodeScannerOpenedStateEvent().subscribe(
      (isOpened) => (this._isBarcodeScannerOpened = isOpened)
    );
    this.showUnprintedKitchenItems = this.isGastroMode && this.printerService.getShowUnprintedKitchenItems();
    this.isMultipleGuests = this.multipleGuestsService.isMultipleGuests;
  }

  ngOnDestroy() {
    if (this.reimburseDenyAlert) this.reimburseDenyAlert.dismiss();
  }

  public isBarcodeScannerOpened() {
    return this._isBarcodeScannerOpened;
  }

  private handleInvoiceEntryUpdates(invoiceEntries: Array<InvoiceEntry>) {
    if (this.invoiceEntries.length === 0) {
      this.invoiceEntries = invoiceEntries;
      return;
    }
    const newItems = lodash.differenceBy(invoiceEntries, this.invoiceEntries, 'uuid');
    if (newItems.length) {
      this.scrollToCartBottom();
    }
    this.invoiceEntries = [...invoiceEntries];
  }

  private scrollToCartBottom(): void {
    setTimeout(() => {
      let cartElementsList = this.elementRef.nativeElement.getElementsByClassName('cart-entries')[0].getElementsByTagName('ul')[0];
      cartElementsList.scrollTop = cartElementsList.scrollHeight;
    }, 200);
  }

  public invoiceEntryClick(invoiceEntryUuid: string) {
    if (
      this.isBarcodeScannerOpened() ||
      this.CartService.checkPartialPaymentInInvoice() ||
      this.tableEnforceService.checkForTableEnforcementAndShowAlert()
    ) {
      return;
    }
    const invoiceEntry = this.CartService.getEntryFromActiveInvoice(invoiceEntryUuid);
    if (invoiceEntry.type === PRODUCT_TYPES.INDIVIDUAL) {
      // this.openIndividualProductModal.emit(invoiceEntry);
      this.openIndividualProductModal(invoiceEntry);
    } else {
      this.DbDaoService.getDataByUUID(UPDATES_TYPES.ProductVariant.type, invoiceEntry.productVariant['uuid'])
        .then(async () => {
          this.productDetailsModal = await this.ModalService.presentModal(
            ProductDetailsModal,
            {
              product: null,
              source: 'cart',
              invoiceEntry: invoiceEntry,
            },
            {}
          );
          this.productDetailsModal.onDidDismiss().then(() => (this.productDetailsModal = null));
          return this.productDetailsModal.present();
        })
        .catch((err) => {
          this.logService.error('CartComponent', 'invoiceEntryClick:getDataByUUID', err);
          invoiceEntry.type = PRODUCT_TYPES.INDIVIDUAL;
          invoiceEntry.productVariant = null;
          invoiceEntry.productCategory = null;
          invoiceEntry.note = null;
          this.openIndividualProductModal(invoiceEntry);
          // this.openIndividualProductModal.emit(invoiceEntry);
        });
    }
  }

  public invoiceEntrySwipe(event: InvoiceDetailsSwipeEvent<string>) {
    if (this.CartService.checkPartialPaymentInInvoice() || this.tableEnforceService.checkForTableEnforcementAndShowAlert()) {
      return;
    }
    const { direction, entity: invoiceEntryUuid } = event;
    const invoiceEntry = this.CartService.getEntryFromActiveInvoice(invoiceEntryUuid);
    if (direction === SwipeDirection.BACK) {
      this.CartService.changeEntryQuantity(invoiceEntry, -1);
    } else if (direction === SwipeDirection.FORWARD) {
      if (invoiceEntry.type === PRODUCT_TYPES.GIFT_CARD) {
        return;
      }
      this.CartService.changeEntryQuantity(invoiceEntry, 1);
    }
  }

  public discountSwipe($event, discountType: string) {
    if (this.CartService.checkPartialPaymentInInvoice()) {
      return;
    }
    if ($event.direction === SwipeDirection.BACK) {
      switch (discountType) {
        case 'invoice':
          this.CartService.removeInvoiceDiscount();
          break;
        case 'customer':
          this.showCustomersModal();
          break;
      }
    } else if ($event.direction === SwipeDirection.FORWARD) {
      switch (discountType) {
        case 'invoice':
          this.CartService.openInvoiceDiscountModal(this.activeInvoice)
            .then(() => {})
            .catch((err) => this.logService.error('CartComponent', 'discountSwipe:openPrintersModal', err));
          break;
        case 'customer':
          this.showCustomersModal();
          break;
      }
    }
  }

  public discountTap(discountType: string) {
    if (this.isBarcodeScannerOpened() || this.CartService.checkPartialPaymentInInvoice()) {
      return;
    }
    switch (discountType) {
      case 'invoice':
        this.CartService.openInvoiceDiscountModal(this.activeInvoice)
          .then(() => {})
          .catch((err) => this.logService.error('CartComponent', 'discountTap:openPrintersModal', err));
        break;
      case 'customer':
        this.showCustomersModal();
        break;
    }
  }

  addNewGuest() {
    this.scrollToCartBottom();
  }

  public showCustomersModal() {
    this.GoogleAnalyticsService.trackEvent('SelectCustomer', 'showCustomersModal', 'cart');
    this.navController
      .navigateForward(ROUTE_URLS.customers, { animated: true, animation: mdTransitionAnimation, animationDirection: 'forward' })
      .catch((err) => this.logService.error('CartComponent', 'showCustomersModal:navController:navigateForward:customers', err));
  }

  public async openIndividualProductModal(data) {
    if (this.tableEnforceService.checkForTableEnforcementAndShowAlert()) {
      return;
    }
    this.individualProductModal = await this.ModalService.presentModal(IndividualProductModal, { invoiceEntry: data }, {});
    this.individualProductModal.onDidDismiss().then(() => (this.individualProductModal = null));
    return this.individualProductModal.present();
  }

  public async openInvoiceDiscountModal(data) {
    this.invoiceDiscountModal = await this.ModalService.presentModal(InvoiceDiscountModal, { invoiceDiscountData: data }, {});
    this.invoiceDiscountModal.onDidDismiss().then(() => (this.invoiceDiscountModal = null));
    return this.invoiceDiscountModal.present();
  }

  public openInvoices() {
    this.navController
      .navigateRoot(ROUTE_URLS.invoices)
      .catch((err) => this.logService.error('CartComponent', 'openInvoices:navController:navigateRoot:invoices', err));
  }

  trackByFn(index: number, item: any) {
    return item?.uuid || index;
  }

  public async openGuestDetailsModal(invoiceEntryGuest: InvoiceEntryGuest) {
    try {
      const guestDetailsModal = await this.ModalService.presentModal(GuestDetailsModalComponent, { invoiceEntryGuest });
      await guestDetailsModal.present();
    } catch (error) {
      this.logService.error('CartComponent', 'openGuestDetailsModal:ModalService:present', error);
    }
  }
}
