import { ProductImage } from './image.class';
import { Entity } from './entity.class';
import { ProductVariant } from './product-variant.class';
import { ServiceOptions } from './service-options.class';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';
import { GenerateRandomColor } from './generate-random-color.class';

export class Product extends Entity {
  public uuid: string;
  public productCategoriesUuid: string;
  public additionalInformation: string;
  public forcePriceInputOnSale: boolean;
  public hasCustomOptions: boolean;
  public highestPrice: number;
  public isNew: boolean;
  public name: string;
  public images: Array<ProductImage>;
  public price: number;
  public variants: ProductVariant[];
  public deleted: boolean;
  public visible: boolean;
  public visibleInWebshop: boolean;
  public bgColor: string;
  public isSale: boolean;
  public isGiftCard: boolean;
  public type: string;
  public isService: boolean;
  public wasPrice: number;
  public virtualPrinter: Entity;
  public productInCategories: { uuid: string; category?: Entity }[];
  public visibleOnCustomerDisplay: boolean;
  public storeUuids: string[];
  public serviceOptions: ServiceOptions;

  constructor(data: any) {
    super(data);
    this.productCategoriesUuid = data.productCategoriesUuid || '';
    this.additionalInformation = data.additionalInformation || '';
    this.forcePriceInputOnSale = data.forcePriceInputOnSale || false;
    this.hasCustomOptions = data.hasCustomOptions || false;
    this.highestPrice = data.highestPrice || 0;
    this.isNew = data.isNew || false;
    this.name = data.name || '';
    this.images = this.convertImages(data.images);
    this.price = data.price || 0;
    this.variants = data.variants || [];
    this.visible = data.visible || false;
    this.deleted = data.deleted || false;
    this.visibleInWebshop = data.visibleInWebshop || false;
    this.bgColor = data.colorCode || data.bgColor || new GenerateRandomColor().generateColorByText(this.name);
    this.wasPrice = data.wasPrice || 0;
    this.isSale = this.wasPrice && this.wasPrice > this.price;
    this.virtualPrinter = data.virtualPrinter || null;
    this.productInCategories = data.productInCategories || [];
    this.isGiftCard = data.isGiftCard || false;
    this.visibleOnCustomerDisplay = data.visibleOnCustomerDisplay || false;
    this.storeUuids = this.getStoreUuids(data.stores);
    this.type = this.getType(data.type);
    this.isService = this.type === PRODUCT_TYPES.SERVICE;
    this.serviceOptions = data.serviceOptions ? new ServiceOptions(data.serviceOptions) : null;
  }

  private convertImages(images: ProductImage[]): ProductImage[] {
    if (images) {
      return images.map((item) => new ProductImage(item));
    }
    return [];
  }

  private getStoreUuids(stores: any[]) {
    if (!stores) {
      return [];
    }
    return stores.map((store) => {
      if (store.uuid) {
        return store.uuid;
      }
      return store;
    });
  }

  public getCategoriesList(): string[] {
    if (this.productCategoriesUuid === 'root') return [];
    let categoriesArray: string[] = [];
    this.productCategoriesUuid.split(',').map((element) => {
      if (element.length > 3) {
        categoriesArray.push(element);
      }
    });
    return categoriesArray;
  }

  private getType(type?: string): string {
    if (!type && this.isGiftCard) {
      return PRODUCT_TYPES.GIFT_CARD;
    }
    return PRODUCT_TYPES[type] || PRODUCT_TYPES.PRODUCT;
  }
}
