import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, NgModule, OnInit, Output, Renderer2 } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'pos-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() label: string;
  @Input() color: string;
  @Output() onClick = new EventEmitter();

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.setColor();
  }

  private setColor() {
    let { color } = this;
    if (color) {
      if (color.match('pos')) {
        color = `var(${color})`;
      }
      this.renderer.setStyle(this.element.nativeElement, 'color', color);
      this.renderer.setStyle(this.element.nativeElement, 'borderColor', color);
    }
  }

  handleClick() {
    this.onClick.emit();
  }
}
@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
})
export class ButtonComponentModule {}
