/**
 * Created by maksymkunytsia on 9/28/16.
 */

// Vendors
import { Injectable } from '@angular/core';
import { LogService } from './logger/log.service';
import { BehaviorSubject } from 'rxjs';
import { WifiInfoPlugin, WifiInfo } from '@paymash/capacitor-wifi-info-plugin';
import { stringifySafety } from '@pos-common/services/system/logger';

interface WifiDataInterface {
  GatewayIP: string;
  SSID: string;
  BSSID: string;
  IPAddress: string;
}

export class WifiData implements WifiDataInterface {
  BSSID: string;
  GatewayIP: string;
  IPAddress: string;
  SSID: string;

  constructor(data?: WifiInfo) {
    this.BSSID = data?.bssid || '';
    this.GatewayIP = data?.gatewayIp;
    this.IPAddress = data?.ipAddress;
    this.SSID = data?.ssid;
  }

  isEqual(data: WifiData) {
    return JSON.stringify(data) === JSON.stringify(this);
  }
}

@Injectable()
export class NetworkService {
  public internetStatusChangeEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly url: string = 'https://app.mypaymash.com/robots.txt';
  readonly checkWifiDataInterval = 5000;
  private isOffline: boolean = false;

  public get isOfflineSubject() {
    return this.internetStatusChangeEvent;
  }

  private wifiData: WifiData = new WifiData();

  constructor(private logService: LogService) {
  }

  public getConnectionStatus(): boolean {
    return this.isOffline;
  }

  public setConnectionStatus(isOffline: boolean) {
    if (this.isOffline !== isOffline) {
      this.logService.info('NetworkService', `setConnectionStatus:isOffline - ${isOffline}`);
      this.isOffline = isOffline;
      this.internetStatusChangeEvent.next(this.isOffline);
    }

    if (this.isOffline) {
      this.logWifiInfo();
    }
  }

  public startNetworkMonitoring() {
    document.addEventListener(
      'offline',
      () => {
        this.logService.info('NetworkService', 'network:OFFLINE');
        this.logWifiInfo();
        this.setConnectionStatus(true);
      },
      false,
    );
    document.addEventListener(
      'online',
      () => {
        this.logService.info('NetworkService', 'network:ONLINE');
        this.logWifiInfo();
      },
      false,
    );

    if (window.cordova) {
      setInterval(() => {
        this.compareWifiDataAndLog();
      }, this.checkWifiDataInterval);
    }
  }

  private checkConnection(): string {
    const networkState = navigator.connection.type;

    try {
      const states = {};

      states[Connection.UNKNOWN] = 'Unknown connection';
      states[Connection.ETHERNET] = 'Ethernet connection';
      states[Connection.WIFI] = 'WiFi connection';
      states[Connection.CELL_2G] = 'Cell 2G connection';
      states[Connection.CELL_3G] = 'Cell 3G connection';
      states[Connection.CELL_4G] = 'Cell 4G connection';
      states[Connection.CELL] = 'Cell generic connection';
      states[Connection.NONE] = 'No network connection';

      return states[networkState];
    } catch (e) {
      return networkState;
    }
  }

  private logWifiInfo() {
    this.logService.debug('NetworkService', `connectionType:${this.checkConnection()}`);
    this.getWifiData()
      .then((info) => {
        this.logService.info('NetworkService', `WifiDataPlugin: ${stringifySafety(info)}`);
      })
      .catch(() => {
      });
  }

  private getWifiData(): Promise<WifiData> {
    if (window.cordova) {
      return WifiInfoPlugin.getData().then(info => new WifiData(info));
    } else {
      return Promise.reject('cordova_not_avaliable');
    }
  }

  private compareWifiDataAndLog() {
    this.getWifiData()
      .then((info) => {
        if (!this.wifiData.isEqual(info)) {
          this.logService.info('NetworkService', `WifiDataPlugin: ${stringifySafety(info)}`);
          this.wifiData = info;
        }
      })
      .catch(() => {
      });
  }
}
