<div class="date-range">
  <ng-container *ngIf="isCalendarView">
    <div class="date-range-buttons">
      <button class="date-range-prev" (click)="handleClickPrev()">
        <pos-icon className="fi flaticon-arrows"></pos-icon>
      </button>
    </div>
    <div class="date-range-calendar">{{ title }}</div>
    <div class="date-range-buttons">
      <button class="date-range-next" (click)="handleClickNext()">
        <pos-icon className="fi flaticon-arrows"></pos-icon>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="!isCalendarView">
    <p class="date-range-list">{{ 'appointment_list' | translate }}</p>
  </ng-container>
</div>
