<div class="product-item-group-wrapper" [ngClass]="'order-' + productItemGroup.order">
  <pos-virtual-product-item
    *ngFor="let item of productItemGroup.productItemList; trackByUuid"
    [externalProductItem]="item"
    [cellsQuantity]="cellsQuantity"
    [cellWidth]="cellWidth"
    class="product-item"
    [class.category-item]="item.isCategory"
    [class.hide]="!item.visible"
    [class.placeholder]="!item.uuid"
    [attr.data-uuid]="item.uuid"
    [class.expanded]="item.expanded"
  ></pos-virtual-product-item>
</div>
