import { Directive, EventEmitter, Input, Output, ElementRef, NgZone, OnInit, NgModule } from '@angular/core';
import { IInventoryEvent } from '@pos-common/interfaces/inventory-event.interface';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { filter, fromEvent } from 'rxjs';

@Directive({
  selector: '[inventoryEvents]',
  providers: [SubSinkService],
})
export class InventoryEventsDirective implements OnInit {
  @Input() tagName: string;
  @Output() touchStart = new EventEmitter<IInventoryEvent>();
  @Output() touchEnd = new EventEmitter<IInventoryEvent>();
  private touchStartEvent: any = null;
  private touchMoveEvent: any = null;
  private touchStartTimeStamp: number = null;
  private launchProductDetailsModalTimeout: any;
  private isTouchEvent = false;
  private readonly maxDistance = 23;

  constructor(private ngZone: NgZone, private subSinkService: SubSinkService, private host: ElementRef) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.subSinkService.sink = fromEvent(this.host.nativeElement, 'touchstart').subscribe((event) => this.onTouchStart(event));
      this.subSinkService.sink = fromEvent(this.host.nativeElement, 'touchmove')
        .pipe(filter(() => !!this.touchStartEvent))
        .subscribe((event) => this.onTouchMove(event));
      this.subSinkService.sink = fromEvent(this.host.nativeElement, 'touchend').subscribe((event) => this.onTouchEnd(event));
      this.subSinkService.sink = fromEvent(this.host.nativeElement, 'mousedown').subscribe((event) => this.onMouseDown(event));
      this.subSinkService.sink = fromEvent(this.host.nativeElement, 'mousemove')
        .pipe(filter(() => !!this.touchStartEvent))
        .subscribe((event) => this.onMouseMove(event));
      this.subSinkService.sink = fromEvent(this.host.nativeElement, 'mouseup').subscribe((event) => this.onMouseUp(event));
    });
  }

  private onTouchStart(event) {
    if (this.skipEvent(event)) {
      return;
    }
    this.isTouchEvent = true;
    this.handleTouchStart(event);
  }

  private onTouchMove(event) {
    if (this.skipEvent(event)) {
      return;
    }
    this.touchMoveEvent = event;
  }

  private onTouchEnd(event) {
    if (this.skipEvent(event)) {
      return;
    }
    if (this.touchStartEvent) {
      this.handleTouchEnd(event, this.touchStartEvent, event.timeStamp);
    }
    this.clearTouchStates();
  }

  private onMouseDown(event) {
    if (this.skipMouseEvent(event)) {
      return;
    }
    if (!this.isTouchEvent) {
      this.handleTouchStart(event);
    }
  }

  private onMouseMove(event) {
    if (this.skipMouseEvent(event)) {
      return;
    }
    if (!this.isTouchEvent && this.touchStartEvent) {
      this.touchMoveEvent = event;
    }
  }

  private onMouseUp(event) {
    if (this.skipMouseEvent(event)) {
      return;
    }
    if (!this.isTouchEvent && this.touchStartEvent) {
      this.handleTouchEnd(event, this.touchStartEvent, event.timeStamp);
    }
    this.clearTouchStates();
    this.isTouchEvent = false;
  }

  private skipEvent(event: any) {
    return event.target.tagName !== this.tagName.toUpperCase();
  }

  private skipMouseEvent(event: any) {
    return this.skipEvent(event) || this.isTouchEvent;
  }

  private handleTouchStart(event: any) {
    this.touchStartEvent = event;
    this.touchStartTimeStamp = event.timeStamp;
    this.launchProductDetailsModalTimeout = setTimeout(() => {
      if (this.touchMoveEvent) {
        const distance = this.getDistance(event, this.touchMoveEvent, ['screenX', 'screenY']);
        if (distance > this.maxDistance) {
          this.clearTouchStates();
          return;
        }
      }
      this.clearTouchStates();
      setTimeout(() => this.touchStart.emit({ dataset: event.target.dataset }), 50);
    }, 500);
  }

  private handleTouchEnd(eventChanged: any, startChanged: any, timeStamp: number) {
    clearTimeout(this.launchProductDetailsModalTimeout);
    const distance = this.getDistance(eventChanged, startChanged, ['screenX', 'screenY']);
    const timeDif = timeStamp - this.touchStartTimeStamp;
    if (distance < this.maxDistance && timeDif < 300) {
      setTimeout(() => this.touchEnd.emit({ dataset: startChanged.target.dataset, eventTarget: startChanged.target }), 50);
    }
  }

  private getDistance(p1: any, p2: any, props: string[] = ['x', 'y']): number {
    if (this.isTouchEvent) {
      p1 = p1.changedTouches[0];
      p2 = p2.changedTouches[0];
    }
    const x = p2[props[0]] - p1[props[0]];
    const y = p2[props[1]] - p1[props[1]];
    return Math.sqrt(x * x + y * y);
  }

  private clearTouchStates() {
    this.touchStartEvent = null;
    this.touchMoveEvent = null;
  }
}
@NgModule({
  declarations: [InventoryEventsDirective],
  exports: [InventoryEventsDirective],
})
export class InventoryEventsDirectiveModule {}
