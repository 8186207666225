import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  OnInit,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Invoice } from '@pos-common/classes/invoice.class';
import { CartService } from '@pos-common/services/system/cart.service';
import { InvoiceEntry } from '@pos-common/classes/invoice-entry.class';
import { newInvoiceEntryElm } from 'app/app.animations';
import { InvoiceDetailsSwipeEvent } from '@pos-modules/invoice-details/interfaces/invoice-details.events';
import { InvoiceEntityOrGuestType } from '@pos-common/types/invoice-entity-or-guest.type';
import { MultipleGuestsService } from '@pos-common/services/system/multiple-guests/multiple-guests.service';
import { SubSink } from 'subsink';
import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';

@Component({
  selector: 'cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [newInvoiceEntryElm()],
})
export class CartListComponent implements OnInit, OnDestroy {
  @Input() activeInvoice: Invoice;
  @Input() invoiceEntries: InvoiceEntry[] = [];
  @Input() showUnprintedKitchenItems: boolean;
  @Input() isMultipleGuests: boolean;
  @Input() isGastroMode: boolean;
  @Output() onClickInvoiceEntry = new EventEmitter<string>();
  @Output() onSwipeInvoiceEntry = new EventEmitter<InvoiceDetailsSwipeEvent<string>>();
  @Output() onAddNewGuest = new EventEmitter<any>();
  @Output() onEditGuest = new EventEmitter<InvoiceEntryGuest>();

  invoiceEntryGuests: InvoiceEntryGuest[] = [];
  entryList: InvoiceEntityOrGuestType[] | any[] = [];
  private readonly subs = new SubSink();

  private readonly invoiceEntriesKey = 'invoiceEntries';

  constructor(
    private multipleGuestsService: MultipleGuestsService,
    private cartService: CartService,
    private setTimeoutUtil: SetTimeoutUtil,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.multipleGuestsService.getAddNewGuestEvent().subscribe(() => this.createNewGuest());
    this.updateInvoiceEntries();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const invoiceEntriesChanges = changes[this.invoiceEntriesKey];
    if (invoiceEntriesChanges && !invoiceEntriesChanges.isFirstChange()) {
      this.updateInvoiceEntries();
    }
  }

  ngOnDestroy(): void {
    this.multipleGuestsService.reset();
    this.subs.unsubscribe();
  }

  updateInvoiceEntries() {
    this.setInvoiceEntryGuests();
    this.selectActiveInvoiceEntryGuest();
    this.setEntryList();
  }

  invoiceEntryClick(invoiceEntryUuid: string) {
    this.onClickInvoiceEntry.emit(invoiceEntryUuid);
  }

  invoiceEntrySwipe(event: any, invoiceEntryUuid: string) {
    this.onSwipeInvoiceEntry.emit({
      direction: event.direction,
      entity: invoiceEntryUuid,
    });
  }

  trackByFn(index: number, item: any) {
    return item.uuid || index;
  }

  editGuest(invoiceEntryGuest: InvoiceEntryGuest) {
    this.onEditGuest.emit(invoiceEntryGuest);
  }

  createNewGuest() {
    this.cartService.addNewGuest();
    this.onAddNewGuest.emit();
  }

  selectGuest(guestNumber: number) {
    const invoiceEntryGuest = this.invoiceEntryGuests.find((invoiceEntryGuest) => invoiceEntryGuest.guestNumber === guestNumber);
    if (invoiceEntryGuest) {
      this.multipleGuestsService.setActiveInvoiceEntryGuest(invoiceEntryGuest);
      this.selectActiveInvoiceEntryGuest();
      this.setEntryList();
    }
  }

  async removeGuest(guestNumber: number) {
    if (this.cartService.checkPartialPaymentInInvoice()) {
      return;
    }

    const invoiceEntries = await this.multipleGuestsService.removeActiveGuest(guestNumber, this.invoiceEntries);
    if (!invoiceEntries.length) {
      return;
    }
    this.invoiceEntries = invoiceEntries.filter((entry) => !entry.deleted);
    this.setInvoiceEntryGuests();
    this.selectGuest(this.multipleGuestsService.activeGuestNumber);
    this.cartService.changeEntries(invoiceEntries);
  }

  private setInvoiceEntryGuests() {
    const invoiceEntryGuests = this.multipleGuestsService.getInvoiceEntryGuests(this.invoiceEntries);
    this.multipleGuestsService.setDefaultGuestNumbers(invoiceEntryGuests);

    const { isFirstGuest } = this.multipleGuestsService;
    if (this.isMultipleGuests && !invoiceEntryGuests.length && isFirstGuest) {
      this.addFirstGuest();
      return;
    }

    this.invoiceEntryGuests = invoiceEntryGuests;
  }

  private selectActiveInvoiceEntryGuest() {
    this.invoiceEntryGuests = this.invoiceEntryGuests.map((guest) => {
      guest.isSelected = guest.guestNumber === this.multipleGuestsService.activeGuestNumber;
      return new InvoiceEntryGuest(guest);
    });
  }

  private setEntryList() {
    this.entryList = this.multipleGuestsService.getInvoiceEntryOrGuestList(this.invoiceEntryGuests, this.invoiceEntries);
    this.cdr.detectChanges();
  }

  private addFirstGuest() {
    if (this.activeInvoice.hasGuestInvoiceEntry()) {
      return;
    }
    this.multipleGuestsService.isFirstGuest = false;
    this.setTimeoutUtil.fixAngularZoneConflict().then(() => {
      const saveRequired = !!this.activeInvoice.gastronomyTable;
      this.cartService.addNewGuest(saveRequired);
    });
  }
}
