import { IFilterFormParams } from '@pos-common/interfaces';
import { FilterGroup } from './filter-group.class';
import { FilterItem } from './filter-item.class';

export class FilterForm {
  public isInit = true;
  public interval?: FilterGroup;
  public amount?: FilterGroup;
  public status?: FilterGroup;
  public employee?: FilterGroup;
  public store?: FilterGroup;
  public filterParams: IFilterFormParams = {};

  get formGroups(): FilterGroup[] {
    return this.getFilterGroupProperties()
      .filter((property) => !!this[property])
      .map((property) => this[property]);
  }

  get selectedGroups(): FilterGroup[] {
    return this.formGroups.filter((group) => group.hasSelectedItems());
  }

  copy() {
    const clone = JSON.parse(JSON.stringify(this));
    const filterForm = Object.setPrototypeOf(clone, FilterForm.prototype);
    const properties = this.getFilterGroupProperties();
    properties.forEach((property) => {
      Object.setPrototypeOf(filterForm[property], FilterGroup.prototype);
      (filterForm[property] as FilterGroup).items.forEach((item) => Object.setPrototypeOf(item, FilterItem.prototype));
    });
    return filterForm;
  }

  private getFilterGroupProperties() {
    return Object.keys(this).filter((key) => this[key] instanceof FilterGroup);
  }
}
