import { InvoiceEntry } from '@pos-common/classes/invoice-entry.class';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';

export class InvoiceEntryGuest extends InvoiceEntry {
  isSelected: boolean;
  invoiceEntryCount: number;
  isCustomerGuest: boolean;

  constructor(data) {
    super(data);
    this.isSelected = data?.isSelected || false;
    this.invoiceEntryCount = data?.invoiceEntryCount || 0;
    this.isCustomerGuest = data?.isCustomerGuest || false;
    this.type = PRODUCT_TYPES.CATEGORY;
  }
}
