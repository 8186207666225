import { ISunmiPrinterService, SunmiPrinterService } from './sunmi-printer.service';
import { Injector } from '@angular/core';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { ISunmiService } from '@pos-common/services/system/devices/sunmi/sunmi.service';
import { SunmiPrinterServiceMock } from './sunmi-printer.service.mock';

export function sunmiPrinterServiceFactory(injector: Injector): ISunmiPrinterService {
  const platformService = injector.get(PlatformService);
  if (platformService.isNativePlatform) {
    return new SunmiPrinterService(injector, injector.get(ISunmiService));
  }
  return new SunmiPrinterServiceMock(injector);
}
