import { ReceiptTextAlign } from '../../enum/receipt-text-align.enum';
import { MyPosReceipItemBase } from './my-pos-receipt-item-base.abstract';

enum MyPosMiniAlignType {
  left = 'LEFT',
  center = 'CENTER',
  right = 'RIGHT',
}

enum MyPosMiniFontType {
  single = 'SINGLE',
  double = 'DOUBLE',
}

export class MyPosMiniReceiptItem extends MyPosReceipItemBase<MyPosMiniReceiptItem> {
  type = 'row';
  text?: string;
  align?: string;
  fontSize?: string;

  protected getEmptyItem(text?: string) {
    const receiptItem = new MyPosMiniReceiptItem();
    receiptItem.text = text;
    receiptItem.align = MyPosMiniAlignType.center;
    receiptItem.fontSize = MyPosMiniFontType.single;
    return receiptItem;
  }

  protected getFont(receiptItem: MyPosMiniReceiptItem, isDoubleSize: boolean) {
    receiptItem.fontSize = isDoubleSize ? MyPosMiniFontType.double : MyPosMiniFontType.single;
    return receiptItem;
  }

  protected getAlign(receiptItem: MyPosMiniReceiptItem, textAlign: ReceiptTextAlign) {
    let align = MyPosMiniAlignType.center;
    if (textAlign === ReceiptTextAlign.left) {
      align = MyPosMiniAlignType.left;
    } else if (textAlign === ReceiptTextAlign.right) {
      align = MyPosMiniAlignType.right;
    }
    receiptItem.align = align;
    return receiptItem;
  }

  protected getCut(receiptItem: MyPosMiniReceiptItem) {
    receiptItem.text = '\n';
    return receiptItem;
  }

  protected getImage(receiptItem: MyPosMiniReceiptItem, image: any) {
    receiptItem.text = '';
    return receiptItem;
  }
  protected getQrCode(receiptItem: MyPosMiniReceiptItem, qrCode: any) {
    receiptItem.text = '';
    return receiptItem;
  }
}
