import { MigrationBase, MigrationInfo } from './MigrationBase';
import { Database, IDatabase } from '@paymash/capacitor-database-plugin';
import { Injector } from '@angular/core';
import { DbDaoUtils } from '@pos-common/services/db/db-dao.utils';

export abstract class Migration {
  private readonly dbDaoUtils: DbDaoUtils;

  constructor(protected readonly injector: Injector) {
    this.dbDaoUtils = injector.get(DbDaoUtils);
  }

  protected abstract get migrations(): (typeof MigrationBase)[];

  private async getDbVersion(companyId: string): Promise<number | undefined> {
    try {
      const dbVersion = await this.dbDaoUtils.getDbVersion(companyId);
      if (!dbVersion) return null;
      return parseInt(dbVersion);
    } catch (e) {
      return null;
    }
  }

  private async setDbVersion(companyId: string, dbVersion: number) {
    await this.dbDaoUtils.setDbVersion(dbVersion.toString(), companyId);
  }

  public async runMigrations(companyId: string): Promise<void> {
    const database: IDatabase = Database;
    const migrations = this.migrations.map((migration) => this.injector.get(migration));

    const dbVersion = await this.getDbVersion(companyId);

    const info: MigrationInfo = { companyId };

    for (const migration of migrations) {
      if (dbVersion && dbVersion >= migration.version) continue;

      await migration.up(database, info);
      await this.setDbVersion(companyId, migration.version);
    }
  }
}
