import {
  Component,
  Input,
  Output,
  ViewEncapsulation,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Invoice } from '@pos-common/classes/invoice.class';
import { MultipleGuestsService } from '@pos-common/services/system/multiple-guests/multiple-guests.service';
import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import { SwipeDirection } from '@pos-common/constants/swipe-direction.enum';
import { CartService } from '@pos-common/services/system/cart.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { ModalService } from '@pos-common/services/system/modal.service';
import { SelectGuestsModalComponent } from '../../../common/components/select-guests-modal/select-guests-modal.component';
import { SELECT_GUEST_MODAL_ACTION } from '@pos-common/constants';
import { GuestDetailsModalComponent } from '@pos-common/components/guest-details-modal/guest-details-modal.component';

@Component({
  selector: 'table-guest-section-holder',
  templateUrl: './table-guest-section-holder.component.html',
  styleUrls: ['./table-guest-section-holder.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableGuestSectionHolderComponent implements OnInit, OnChanges {
  @Input() activeInvoice: Invoice;
  @Input() isGastroMode: boolean;
  @Input() productVariantIsActive: boolean;

  @Output() onClickSection = new EventEmitter<any>();
  @Output() onChangeTable = new EventEmitter<any>();

  public showUnprintedKitchenItems = false;
  public showGuestPopup = false;
  public guestNumber = 0;
  public guestName = '';

  private invoiceEntryGuests: InvoiceEntryGuest[] = [];
  private readonly activeInvoiceKey = 'activeInvoice';

  get activeGuestName() {
    return this.multipleGuestsService.activeGuestName;
  }

  get activeGuestNumber() {
    return this.multipleGuestsService.activeGuestNumber;
  }

  get isMultipleGuests() {
    return this.multipleGuestsService.isMultipleGuests;
  }

  constructor(
    private multipleGuestsService: MultipleGuestsService,
    private cartService: CartService,
    private platformService: PlatformService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    this.showGuestPopup = this.platformService.isMobile;
    this.updateInvoiceEntries();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const activeInvoiceChanges = changes[this.activeInvoiceKey];
    if (activeInvoiceChanges && !activeInvoiceChanges.isFirstChange()) {
      this.updateInvoiceEntries();
    }
  }

  private setNameAndGuestNumber(invoiceEntryGuest: InvoiceEntryGuest) {
    this.guestNumber = invoiceEntryGuest.guestNumber;
    this.guestName = invoiceEntryGuest.name;
  }

  private updateInvoiceEntries() {
    this.setInvoiceEntryGuests();
    this.selectActiveInvoiceEntryGuest();
  }

  private setInvoiceEntryGuests() {
    const invoiceEntryGuests = this.multipleGuestsService.getInvoiceEntryGuests(this.activeInvoice.invoiceEntries);
    this.multipleGuestsService.setDefaultGuestNumbers(invoiceEntryGuests);
    this.invoiceEntryGuests = invoiceEntryGuests;
  }

  private selectActiveInvoiceEntryGuest() {
    const invoiceEntryGuest = this.invoiceEntryGuests.find((entry) => entry.guestNumber === this.activeGuestNumber);
    if (invoiceEntryGuest) {
      this.setNameAndGuestNumber(invoiceEntryGuest);
    }
    this.cdr.detectChanges();
  }

  sectionClick() {
    this.onClickSection.emit();
  }

  changeTable() {
    if (!this.productVariantIsActive) {
      this.onChangeTable.emit();
    }
  }

  async openSelectGuestsPopup() {
    try {
      const selectGuestsModal = await this.modalService.getGuestsModal(
        SelectGuestsModalComponent,
        this.invoiceEntryGuests,
        this.activeGuestNumber
      );
      selectGuestsModal.componentProps.onEdit.subscribe(async (value: any) => {
        const data = value.data || {};
        await this.selectGuestAction(value.role, this.invoiceEntryGuests, data.activeGuestNumber);
        selectGuestsModal.dismiss();
      });

      selectGuestsModal.onDidDismiss().then((value) => {
        const data = value.data || {};
        this.selectGuestAction(value.role, this.invoiceEntryGuests, data.activeGuestNumber);
      });

      await selectGuestsModal.present();
    } catch (error) {
      this.logService.error('TableGuestSectionHolderComponent', 'openGuestsPopup', error);
    }
  }

  private createNewGuest() {
    this.cartService.addNewGuest();
  }

  async removeActiveGuest(event: Event | SwipeDirection, deleteGuestNumber?: number) {
    if (this.cartService.checkPartialPaymentInInvoice()) {
      return;
    }
    const direction: SwipeDirection = (<any>event)?.direction ?? event;

    let { activeGuestNumber } = this.multipleGuestsService;
    if (deleteGuestNumber) {
      activeGuestNumber = deleteGuestNumber;
    }

    if (direction === SwipeDirection.BACK) {
      const invoiceEntries = await this.multipleGuestsService.removeActiveGuest(activeGuestNumber, this.activeInvoice.invoiceEntries);
      if (invoiceEntries.length) {
        this.cartService.changeEntries(invoiceEntries);
      }
    }
    this.cdr.detectChanges();
  }

  private async selectGuestAction(action: string, invoiceEntryGuests: InvoiceEntryGuest[], activeGuestNumber?: number) {
    const { SELECT, ADD_NEW_GUEST, DELETE, EDIT } = SELECT_GUEST_MODAL_ACTION;
    const invoiceEntryGuest = invoiceEntryGuests.find((entry) => entry.guestNumber === activeGuestNumber);

    switch (action) {
      case SELECT:
        this.multipleGuestsService.setActiveInvoiceEntryGuest(invoiceEntryGuest);
        this.selectActiveInvoiceEntryGuest();
        break;
      case ADD_NEW_GUEST:
        this.createNewGuest();
        break;
      case DELETE:
        this.removeActiveGuest(SwipeDirection.BACK, invoiceEntryGuest.guestNumber);
        break;
      case EDIT:
        await this.openGuestDetailsModal(invoiceEntryGuest);
        break;
    }
  }

  private async openGuestDetailsModal(invoiceEntryGuest: InvoiceEntryGuest) {
    try {
      const guestDetailsModal = await this.modalService.presentModal(GuestDetailsModalComponent, { invoiceEntryGuest });
      guestDetailsModal.animated = false;
      await guestDetailsModal.present();
      guestDetailsModal.animated = true;
    } catch (error) {
      this.logService.error('TableGuestSectionHolderComponent', 'openGuestDetailsModal:presentModal', error);
    }
  }
}
