import * as moment from 'moment';
import { Address } from './address.class';
import { SALUTATIONS_TYPES } from '../constants/salutations-types.const';
import { Image } from './image.class';
import { Entity } from './entity.class';
import { LOCALE } from '@pos-common/constants';

export class Customer extends Entity {
  public firstName: string;
  public lastName: string;
  public salutation: string;
  public email: string;
  public image: Image;
  public address: Address;
  public birthday: any;
  public note: string;
  public discountPercentage: number;
  public discount: number;
  public firstVisit: any;
  public lastVisit: any;
  public acceptsMarketing: boolean;
  public totalSales: number;
  public dataToShowInList: string;
  public deleted: boolean;
  public isSynced: boolean = false;
  public vatNumber: string;

  constructor(data) {
    super(data);
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.salutation = (data.salutation && SALUTATIONS_TYPES[data.salutation.toUpperCase()].title) || SALUTATIONS_TYPES.EMPTY.title;
    this.email = data.email || '';
    this.image = data.image ? new Image(data.image) : null;
    this.address = data.address ? new Address(data.address) : new Address({});
    this.note = data.note || '';
    this.discountPercentage = data.discountPercentage || 0;
    this.discount = data.discount || 0;
    this.acceptsMarketing = data.acceptsMarketing || false;
    this.totalSales = data.totalSales || 0;
    this.vatNumber = data.vatNumber || '';
    const { YYYY_MM_DD_DASH } = LOCALE.DateFormat;
    this.birthday = data.birthday ? moment(data.birthday).format(YYYY_MM_DD_DASH) : null;
    this.firstVisit = data.firstVisit ? moment(data.firstVisit).format(YYYY_MM_DD_DASH) : null;
    this.lastVisit = data.lastVisit ? moment(data.lastVisit).format(YYYY_MM_DD_DASH) : null;
    this.dataToShowInList = this.createDataToShowInList();
    this.deleted = data.deleted || false;
    this.isSynced = data.isSynced === undefined ? true : data.isSynced;
  }

  private createDataToShowInList(): string {
    if (this.firstName || this.lastName) {
      return this.firstName + ' ' + this.lastName;
    } else if (this.address?.business) {
      return this.address.business;
    } else if (this.email) {
      return this.email;
    }
    return null;
  }
}

export interface CustomersListAction {
  action: string;
  data: Customer[];
}

export const CUSTOMERS_LIST_ACTIONS = {
  REMOVE_CUSTOMERS: 'REMOVE_CUSTOMERS',
  ADD_CUSTOMERS: 'ADD_CUSTOMERS',
  CLEAN_CUSTOMERS_LIST: 'CLEAN_CUSTOMERS_LIST',
};
