import { IDriver } from '../IDriver';
import { Device, ConnectionInfo } from './Device';
import { CustomerDisplayPlugin } from './Plugin';
import { ILogger, ILoggerFactory } from '@spryrocks/logger';
import { SubSink } from 'subsink';

export class InAppDriver extends IDriver<Device, ConnectionInfo> {
  private readonly logger: ILogger;
  private readonly sub = new SubSink();
  private onMessageReceivedCallback?: (message: object) => void;
  private onClosedCallback?: () => void;

  constructor(private readonly device: Device, loggerFactory: ILoggerFactory) {
    super();
    this.logger = loggerFactory.createLogger("InAppDriver");
    this.logger.updateParams({device});
  }

  async connect() {
    this.logger.debug("Connect to device");
    const displayId = this.device.connectionInfo.displayId;
    this.sub.sink = CustomerDisplayPlugin.onDisabled(displayId)
      .subscribe(this.onDisabled.bind(this));
    await CustomerDisplayPlugin.enable('customer', displayId);
    this.sub.sink = CustomerDisplayPlugin.receive(displayId)
      .subscribe(this.onMessageReceived.bind(this));
    this.logger.info("Connected to device");
  }

  async disconnect() {
    this.logger.debug("Disconnect from device");
    const displayId = this.device.connectionInfo.displayId;
    await CustomerDisplayPlugin.disable(displayId);
    this.logger.info("Disconnected from device");
    this.onClosedInternal();
  }

  private onClosedInternal() {
    if (this.onClosedCallback) {
      this.onClosedCallback();
    }
    this.dispose();
  }

  async sendMessage(message: object) {
    this.logger.trace("Send message to device", {message});
    await CustomerDisplayPlugin.send(this.device.connectionInfo.displayId, message);
    this.logger.trace("Message sent successfully", {message});
  }

  setOnError(_callback: (error: Error) => void) {}

  setOnMessageReceived(callback: (message: object) => void) {
    this.onMessageReceivedCallback = callback;
  }

  setOnClosed(callback: () => void): void {
    this.onClosedCallback = callback;
  }

  private onMessageReceived(message: object) {
    this.logger.debug("Message from customer receiver", {message});
    if (!this.onMessageReceivedCallback) {
      this.logger.info("onMessageReceivedCallback is undefined");
      return;
    }
    this.onMessageReceivedCallback(message);
  }

  private onDisabled() {
    this.logger.debug('On disabled event received');
    this.onClosedInternal();
  }

  private dispose() {
    this.onMessageReceivedCallback = undefined;
    this.onClosedCallback = undefined;
    this.sub.unsubscribe();
  }
}
