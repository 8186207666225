/**
 * Created by maksymkunytsia on 8/18/16.
 */

import { HttpResponse } from '@angular/common/http';

export class SecuredResponse {
  status: number;
  statusText: string;
  url: string;
  data: any;

  constructor(IncomeResponse: HttpResponse<any>) {
    this.status = IncomeResponse.status;
    this.statusText = IncomeResponse.statusText;
    this.url = IncomeResponse.url;
    let responseType: string = String(typeof IncomeResponse['body']);
    if (responseType !== 'object' &&  responseType !== 'ProgressEvent') {
      try {
        this.data = IncomeResponse['body'];
      }
      catch (err) {
        this.data = IncomeResponse['body'];
      }
    } else {
      this.data = IncomeResponse['body'];
    }
    if (!this.data) {
      responseType = String(typeof IncomeResponse['error']);
      try {
          this.data = IncomeResponse['error'];
      }
      catch (err) {
          this.data = IncomeResponse['error'];
      }
    }
  }
}
