import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  NgModule,
} from '@angular/core';
import { INullifyModalOptionsStyles } from '@pos-common/interfaces';
import { NullifyModalService } from '@pos-common/services/system/nullify-modal.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { DurationPickerComponent } from '../duration-picker/duration-picker.component';
import { DurationTimePipeModule } from '@pos-common/pipes';

@Component({
  selector: 'pos-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class DurationComponent {
  @Input() maxHours = 24;
  @Input() step = 5;
  @Input() value: string;
  @Output() posSetValue = new EventEmitter<string>();

  constructor(private nullifyModalService: NullifyModalService, private subSinkService: SubSinkService, private cdr: ChangeDetectorRef) {}

  openPopup(event: Event) {
    const target = event.target as HTMLElement;
    const targetRect = target.getBoundingClientRect();
    const height = 175;
    const padding = 10;
    const expectedHeight = window.innerHeight - targetRect.bottom;
    const arrAbove = expectedHeight > height;
    const top = expectedHeight > height ? targetRect.bottom + padding : targetRect.top - height - padding;

    const styles: INullifyModalOptionsStyles = {
      top: `${top}px !important`,
      right: '0',
      left: 'initial !important',
      maxWidth: '100%',
      margin: '0 20px !important',
      padding: '25px !important',
      width: '182px !important',
      height: `${height}px !important`,
      zIndex: 30001,
    };
    const posSetValue = new EventEmitter<string>();
    this.subSinkService.sink = posSetValue.subscribe((value) => {
      this.value = value;
      this.posSetValue.emit(value);
      this.cdr.detectChanges();
    });
    const { value, step, maxHours } = this;
    this.nullifyModalService.initModal({
      component: DurationPickerComponent,
      options: { styles, className: 'pos-content-picker', arrAbove },
      inputs: [{ value, step, maxHours, posSetValue }],
    });
  }
}
@NgModule({
  imports: [DurationTimePipeModule],
  declarations: [DurationComponent],
  exports: [DurationComponent],
})
export class DurationComponentModule {}
