<div class="filter-title" (click)="collapse()" tappable>
  <span>{{ filterGroup.title | translate }}</span>
  <pos-icon *ngIf="filterGroup.canOpen" className="fi flaticon-arrows arr-forward"></pos-icon>
</div>
<div class="filter-content" [hidden]="!filterGroup.isOpen">
  <pos-calendar-filter-item
    *ngFor="let item of items"
    [itemType]="filterGroup.itemType"
    [item]="item"
    (posSelectedItem)="selectItem()"
  ></pos-calendar-filter-item>
</div>
