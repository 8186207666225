import { APPOINTMENT_STATUS } from '@pos-common/constants';
import { IListViewPlusMore } from '@pos-common/interfaces';
import { Customer } from '../customer.class';
import { Invoice } from '../invoice.class';

export class AppointmentInList {
  public uuid: string;
  public appointmentId: string;
  public startedAt: string;
  public customerDisplay: string;
  public totalDuration: string;
  public status: string;
  public totalPrice: string;
  public serviceNames: IListViewPlusMore[];
  public employeeNames: IListViewPlusMore[];
  public customer: Customer;
  public invoice: Invoice;
  public isCustomerVisit: boolean;

  constructor(data: any = {}) {
    this.uuid = data.uuid || '';
    this.appointmentId = data.appointmentId || 0;
    this.startedAt = data.startedAt || '';
    this.status = APPOINTMENT_STATUS[data.status] || APPOINTMENT_STATUS.DRAFT;
    this.totalDuration = data.totalDuration || '00:00';
    this.totalPrice = data.totalPrice || '';
    this.serviceNames = data.serviceNames || [];
    this.employeeNames = data.employeeNames?.length ? data.employeeNames : ['-'];
    this.invoice = data.invoice ? new Invoice(data.invoice) : null;
    this.customer = data.customer ? new Customer(data.customer) : null;
    this.customerDisplay = this.customer?.dataToShowInList || '-';
    this.isCustomerVisit = typeof data.isCustomerVisit === 'boolean' ? !!data.isCustomerVisit : true;
  }

  get isCancel() {
    return new Date(this.startedAt) > new Date() && this.status !== APPOINTMENT_STATUS.CANCELED_BY_CUSTOMER;
  }
}
