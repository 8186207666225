import { Injectable } from '@angular/core';
import { SecurityService } from '@pos-common/services/system/security.service';
import { ShiftApiResponse } from '@paymash/paymash-shifts/dist/types/interfaces/shift-api-response';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { LoadShiftsOptions } from '@paymash/paymash-shifts/dist/types/interfaces/load-shifts-options';
import { ShiftUpdateRequest } from '@paymash/paymash-shifts/dist/types/models/shift-update-request';

@Injectable()
export class ShiftsApiService {
  constructor(private securityService: SecurityService) {}

  getShifts(options: LoadShiftsOptions): Promise<ShiftApiResponse> {
    const storeUuid = this.securityService.getActiveStore().uuid;
    const query = `/find?dateFrom=${options.dateFrom}&dateTo=${options.dateTo}&storeUuid=${storeUuid}`;
    return this.securityService.doSecureRequest(`${SERVER_CONFIG.API_LEGACY_URL}shifts${query}`, 'get').then((response) => response.data);
  }

  updateShift(shiftData: ShiftUpdateRequest): Promise<ShiftApiResponse> {
    shiftData.storeUuid = this.securityService.getActiveStore().uuid;
    const method = shiftData.id === 0 ? 'post' : 'put';
    return this.securityService
      .doSecureRequest(`${SERVER_CONFIG.API_LEGACY_URL}shifts/${shiftData.id}`, method, shiftData)
      .then((response) => response.data);
  }
}
