import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { ProductItemGroup } from '@pos-common/classes/product-item-group.class';

@Component({
  selector: 'pos-virtual-product-group',
  templateUrl: './virtual-product-group.component.html',
  styleUrls: ['./virtual-product-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualProductGroupComponent {
  @Input() cellsQuantity: number;
  @Input() cellWidth: number;
  @Input() productItemGroup: ProductItemGroup;
}
