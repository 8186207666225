<ion-content>
  <ion-list *ngIf="!isLoading">
    <pos-calendar-list-item
      *ngFor="let item of itemList; trackByUuid"
      [itemInList]="item"
      (posOpen)="openAppointment(item)"
      (posCancel)="handleCancel(item)"
    ></pos-calendar-list-item>
    <div *ngIf="!itemList.length" class="no-items">{{ 'calendar_no_appointments' | translate }}</div>
  </ion-list>

  <pos-loader class="loader-contaner" [isLoading]="isLoading"></pos-loader>

  <ion-infinite-scroll threshold="20px" (ionInfinite)="loadMore($event)" [disabled]="isLoadMore">
    <ion-infinite-scroll-content loadingSpinner="crescent"> </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
