import { IDatabase } from '@paymash/capacitor-database-plugin';
import { MigrationBase, MigrationInfo } from './core/MigrationBase';
import { Injectable } from '@angular/core';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';

@Injectable()
export class Migration_5_Indexes extends MigrationBase {
  version = 5;
  name = 'Add indexes';

  async up(database: IDatabase, info: MigrationInfo) {
    const companyId = info.companyId;

    let collection = this.getCollection(UPDATES_TYPES.Product.type, companyId);
    await database.createIndex(collection, 'ProductIndexes', [
      'uuid',
      'visible',
      'categoryUuid',
      'storeUuid',
      'ext_type',
      'productUuid',
    ]);

    collection = this.getCollection(UPDATES_TYPES.Invoice.type, companyId);
    await database.createIndex(collection, 'InvoiceIndexes', [
      'isDraft',
      'gastronomyTable',
      'deleted',
      'storeUuid',
    ]);
  }
}
