import { ReceiptTextAlign } from '../../enum/receipt-text-align.enum';
import { ReceiptFont } from '../../enum/printer-receipt-font.enum';
import { IReceiptItem } from '../../interfaces/receipt-item.interface';

const ENTER_REGEX = /^[\n]+$/;

export abstract class MyPosReceipItemBase<T> {
  type: string;
  text?: string;

  protected abstract getEmptyItem(text?: string);
  protected abstract getFont(receiptItem: T, isDoubleSize: boolean): T;
  protected abstract getAlign(receiptItem: T, textAlign: ReceiptTextAlign): T;
  protected abstract getCut(receiptItem: T): T;
  protected abstract getImage(receiptItem: T, image: any): T;
  protected abstract getQrCode(receiptItem: T, qrCode: any): T;

  getReceipt(receipt: IReceiptItem[]) {
    const newReceipt = [];
    let receiptItem = this.getEmptyItem();

    receipt.forEach((item) => {
      const key = this.getProperty(item);
      switch (key) {
        case 'textAlign':
          receiptItem = this.getAlign(receiptItem, item.textAlign);
          break;
        case 'font':
          const isDoubleSize = item.font === ReceiptFont.condensed;
          receiptItem = this.getFont(receiptItem, isDoubleSize);
          break;
        case 'cut':
          receiptItem = this.getEmptyItem();
          receiptItem = this.getCut(receiptItem);
          newReceipt.push(receiptItem);
          receiptItem = this.getEmptyItem();
          break;
        case 'image':
          receiptItem = this.getImage(receiptItem, item.image);
          newReceipt.push(receiptItem);
          receiptItem = this.getEmptyItem();
          break;
        case 'qrCode':
          receiptItem = this.getQrCode(receiptItem, item.qrCode);
          newReceipt.push(receiptItem);
          receiptItem = this.getEmptyItem();
          break;
        case 'text':
          receiptItem = this.addText(receiptItem, item.text);
          if (receiptItem.text) {
            newReceipt.push(receiptItem);
          }
          receiptItem = this.getEmptyItem();
          break;

        default:
          break;
      }
    });
    return newReceipt;
  }

  private getProperty(item: IReceiptItem) {
    const key = Object.keys(item);
    return key[0];
  }

  private addText(receiptItem: any, text: string) {
    const isOnlyEnters = ENTER_REGEX.test(text);
    if (isOnlyEnters) {
      text = this.getText(text);
      return this.getEmptyItem(text);
    }
    text = this.getText(text);
    text = this.getTextUsingLatinSymbols(text);
    receiptItem.text = text;
    return receiptItem;
  }

  protected getText(text: string) {
    return text;
  }

  private getTextUsingLatinSymbols(text: string) {
    return text
      .replace(/à|á|â|ã|ä|å|æ/g, 'a')
      .replace(/ç/g, 'c')
      .replace(/è|é|ê|ë/g, 'e')
      .replace(/ì|í|î|ï/g, 'i')
      .replace(/ð/g, 'd')
      .replace(/ñ/g, 'n')
      .replace(/ò|ó|ô|õ|ö|ø/g, 'o')
      .replace(/ù|ú|û|ü/g, 'u')
      .replace(/ý|þ|ÿ/g, 'y')
      .replace(/Œ|œ/g, 'o')
      .replace(/š/g, 's')
      .replace(/ƒ/g, 'f')
      .replace(/ž/, 'z')
      .replace(/Ä|Å/g, 'A')
      .replace(/Ö/g, 'O')
      .replace(/Ü/g, 'U')
      .replace(/ß/g, 'ss')
      .replace(/É/g, 'E')
      .replace(/Š/g, 'S')
      .replace(/Ñ/g, 'N');
  }
}
