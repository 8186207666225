import { Injectable } from '@angular/core';
import { GiftCardsApiService } from '../api/gift-cards-api.service';
import { Observable } from 'rxjs';
import { GiftCard } from '../../../modules/assing-gift-card/gift-card';
import { LogService } from '../system/logger/log.service';
import { GiftCardsListInterface } from '../../../modules/assing-gift-card/gift-cards-list.interface';
import { GiftCardsProviderInterface } from './gift-cards-provider.interface';
import { GiftCardsProviderAbstract } from './gift-cards.provider.abstract';

@Injectable()
export class GiftCardsProvider extends GiftCardsProviderAbstract implements GiftCardsProviderInterface {
  constructor(private giftCardsApiService: GiftCardsApiService, private logService: LogService) {
    super();
  }

  getGiftCardByUuid(uuid: string): Observable<GiftCard> {
    return this.giftCardsApiService.getGiftCardByUuid(uuid);
  }

  getGiftCardByCode(code: string): Observable<GiftCard> {
    return this.giftCardsApiService.getGiftCardByCode(code);
  }

  getGiftCardByPhysicalCode(physicalCode: string): Observable<GiftCard> {
    return this.giftCardsApiService.getGiftCardByPhysicalCode(physicalCode);
  }

  /**
   * Method returns only success result
   * */
  getGiftCardsList(uuidList: string[]): Observable<GiftCardsListInterface> {
    return new Observable((observer) => {
      let giftCardsList: GiftCardsListInterface = {};
      let completedRequests = 0;
      const completion = () => {
        completedRequests++;
        if (uuidList.length === completedRequests) {
          observer.next(giftCardsList);
          observer.complete();
        }
      };
      uuidList.forEach((uuid) => {
        this.getGiftCardByUuid(uuid).subscribe(
          (data) => {
            giftCardsList[data.uuid] = data;
            completion();
          },
          (error) => {
            completion();
            this.logService.error('GiftCardsProvider', `Can't get gift card`, error);
          }
        );
      });
    });
  }
}
