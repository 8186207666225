import { Observable, Subject } from 'rxjs';
import { ReceiptPrinter } from '../../classes/receipt-printer.class';
import { StarPrinterScannerEventAction } from '../../enum/star-printer-scanner-event-action';
import { StarPrinterScannerEventState } from '../../enum/star-printer-scanner-event-state';
import { Injectable, Injector } from '@angular/core';
import { CommonPrinterService } from '../common-printer.service';
import { PrinterType } from '../../enum/printer-type.enum';
import { stringifySafety } from '@pos-common/services/system/logger';
import { BarcodeReaderResultMessage } from '@paymash/capacitor-star-printer-plugin';

@Injectable()
export class StarPrinterMockService extends CommonPrinterService {
  private _scannerEvents: Subject<BarcodeReaderResultMessage> = new Subject<BarcodeReaderResultMessage>();

  constructor(injector: Injector) {
    super(injector);
  }

  protected get discoverMethodName(): string {
    return '';
  }

  protected get pluginName(): string {
    return 'StarPrinterMockService';
  }

  protected get printMethodName(): string {
    return '';
  }

  protected get stopDiscoverMethodName(): string {
    return '';
  }

  get scannerEvents(): Observable<BarcodeReaderResultMessage> {
    return this._scannerEvents.asObservable();
  }

  protected browserMockPerform(): void {
    setTimeout(
      () =>
        this.handleDiscover({
          devices: [
            {
              deviceType: PrinterType.Star,
              deviceName: 'Star Micronics',
              ipAddress: '',
              macAddress: '00:11:62:0d:53:f8',
              target: 'TCP:192.168.178.112',
            },
            {
              deviceType: PrinterType.Star,
              deviceName: 'TSP143IIIBI GY',
              ipAddress: '',
              macAddress: '00:11:62:17:19:e2',
              target: 'BT:TSP100',
            },
            {
              deviceType: PrinterType.Star,
              deviceName: 'TSP654 (STR_T-001)',
              ipAddress: '',
              macAddress: '00:11:62:0d:53:f7',
              target: 'TCP:192.168.178.111',
            },
          ],
        }),
      1000
    );
    setTimeout(
      () =>
        this.handleDiscover({
          devices: [
            {
              deviceType: PrinterType.Star,
              deviceName: 'MCP20 (STR-001)',
              macAddress: '00:11:62:1b:e2:04',
              target: 'TCP:192.168.1.77',
            },
            {
              deviceType: PrinterType.Star,
              deviceName: 'MCP21 (STR-001)',
              macAddress: '00:11:62:1c:a3:a7',
              target: 'TCP:192.168.2.37',
            },
            {
              deviceType: PrinterType.Star,
              deviceName: 'MCP31 (STR-001)',
              macAddress: '00:11:62:1d:22:f4',
              target: 'TCP:192.168.1.241',
            },
            {
              deviceType: PrinterType.Star,
              deviceName: 'MCP21LB',
              macAddress: 'BT:mC-Print2',
              target: 'BT:mC-Print2',
            },
          ],
        }),
      2000
    );
    setTimeout(() => this.handleDiscover({ success: true }), 3000);
  }

  protected discover(): Observable<ReceiptPrinter[]> {
    this.browserMockPerform();
    return this.printerList;
  }

  startBarcodeReader(device: ReceiptPrinter): Observable<BarcodeReaderResultMessage> {
    this.logService.debug('StarPrinterMockService:startBarcodeReader', stringifySafety(device));

    this._scannerEvents.next({
      action: StarPrinterScannerEventAction.stateChanged,
      newState: StarPrinterScannerEventState.started,
    });

    setTimeout(() => {
      this._scannerEvents.next({
        action: StarPrinterScannerEventAction.stateChanged,
        newState: StarPrinterScannerEventState.readerConnected,
      });
    }, 500);

    setTimeout(() => {
      this._scannerEvents.next({
        action: StarPrinterScannerEventAction.dataReceived,
        data: '1234567890',
      });
    }, 3000);

    return this.scannerEvents;
  }

  stopBarcodeReader(): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        this._scannerEvents.next({
          action: StarPrinterScannerEventAction.stateChanged,
          newState: StarPrinterScannerEventState.stopped,
        });
        resolve();
      }, 1000);
    });
  }
}
