<ion-backdrop tappable (ionBackdropTap)="dismiss()"></ion-backdrop>
<div #variantModalContent class="variant-modal-content">
  <span #arrow class="arrow" [class.right]="isRightArrow" [style.top]="arrowOffsetTop + 'px'"></span>
  <product-variant-picker
    *ngIf="product"
    [product]="product"
    [changeVariantCallback]="changeVariantCallback"
    [handleFreePricingInComponent]="true"
    [handleEmployeeInComponent]="handleEmployeeInComponent"
    (posSetVariant)="handleDismiss($event)"
  ></product-variant-picker>
</div>
