<a (click)="selectCustomer()" tappable [class.active]="isActive">
  <div class="customer-list-item-image">
    <pos-image-loader-cap *ngIf="imageUrl" [url]="imageUrl"></pos-image-loader-cap>
    <person-placeholder width="50" height="50"></person-placeholder>
  </div>
  <div class="customer-list-item-info">
    <p class="customer-list-item-name" [class.edit-customer]="showEditButton && isActive">
      {{ customer.dataToShowInList || ('customers_unknown' | translate) }}
    </p>
    <p class="customer-list-item-note">
      <span *ngIf="customer.note">{{ customer.note }}</span>
    </p>
    <div *ngIf="showEditButton && isActive" class="customer-list-item-edit" (click)="editCustomer($event)" tappable>
      <pos-icon customName="custom-icon-edit"></pos-icon>
    </div>
  </div>
</a>
