import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  HostBinding,
  NgModule,
} from '@angular/core';
import { PickerColumn, PickerColumnOption } from '@ionic/angular';
import { PickerColumnComponentModule } from '../picker-column/picker-column.component';

@Component({
  selector: 'pos-duration-picker',
  templateUrl: './duration-picker.component.html',
  styleUrls: ['./duration-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationPickerComponent implements OnInit {
  @Input() maxHours = 24;
  @Input() step = 5;
  @Input() value: string;
  @Output() posSetValue = new EventEmitter<string>();
  @HostBinding('class.pos-picker') isPosPicker = true;
  public col: PickerColumn;

  ngOnInit() {
    this.col = this.getDuration();
  }

  handleColChange(col: PickerColumn) {
    const selectedValue = col.options[col.selectedIndex].value;
    this.posSetValue.emit(selectedValue);
  }

  private getDuration() {
    const { maxHours, step, value } = this;
    const maxMinutes = 60;
    const twoDigitNumber = 10;
    const values = value.split(':');
    const currentHour = parseInt(values[0]);
    const currentMinute = parseInt(values[1]);
    let options: PickerColumnOption[] = [];
    let selectedIndex = 0;
    for (let hour = 0; hour <= maxHours; hour++) {
      for (let minute = 0; minute < maxMinutes; minute += step) {
        const text = hour > 0 ? `${hour} h ${minute} min` : `${minute} min`;
        const hourValue = this.getTimeValue(hour, twoDigitNumber);
        const minuteValue = this.getTimeValue(minute, twoDigitNumber);
        const value = `${hourValue}:${minuteValue}`;
        const opt: PickerColumnOption = { value, text };
        if (hour === currentHour && minute === currentMinute) {
          selectedIndex = options.length;
        }
        options = [...options, opt];
        if (hour === maxHours && minute === 0) {
          break;
        }
      }
    }
    return { name: 'duration', options, selectedIndex };
  }

  private getTimeValue(value: number, twoDigitNumber: number) {
    return value >= twoDigitNumber ? value : `0${value}`;
  }
}
@NgModule({
  imports: [PickerColumnComponentModule],
  declarations: [DurationPickerComponent],
  exports: [DurationPickerComponent],
})
export class DurationPickerComponentModule {}
