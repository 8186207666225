import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingService } from '../../../services/system/loading.service';
import { ReceiptPrinter } from '../../../services/system/receipt-printers/classes/receipt-printer.class';
import { Invoice } from '../../../classes/invoice.class';
import { PrinterService } from '../../../services/system/printer.service';
import { CartService } from '../../../services/system/cart.service';
import { InvoicesService } from '../../../services/system/invoices.service';
import { AlertService } from '../../../services/system/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ReceiptPrinterModeTypes } from '../../../constants/receipt-printer-mode-types';
import { ReceiptPrintersService } from '../../../services/system/receipt-printers/services/receipt-printers.service';
import { LogService } from '../../../services/system/logger/log.service';
import { PAYMENT_PROVIDERS } from '../../../constants/payment-providers.const';
import { SubSink } from 'subsink';
import { IPrintersListCoordinates } from '@pos-common/interfaces/printers-list-coordinates.inteface';

@Component({
  selector: 'printers-list-modal',
  templateUrl: './printers-list.html',
  styleUrls: ['./printers-list.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrintersListModal {
  @Input() invoice: Invoice;
  @Input() guestNumber: number;
  @Input() coordinates: IPrintersListCoordinates;
  @Input() posMode = ReceiptPrinterModeTypes.POS;

  public printers: Array<ReceiptPrinter> = [];
  public printersListIsLoading = true;
  public maxHeight: number;
  public receiptPrinterModeTypes = ReceiptPrinterModeTypes;
  private subs = new SubSink();

  constructor(
    private viewCtrl: ModalController,
    private printerService: PrinterService,
    private receiptPrintersService: ReceiptPrintersService,
    private LoadingService: LoadingService,
    private CartService: CartService,
    private InvoicesService: InvoicesService,
    private AlertService: AlertService,
    private translateService: TranslateService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.invoice = this.invoice ? new Invoice(this.invoice) : this.invoice;
    this.setCoordinates();

    setTimeout(() => {
      this.printersListIsLoading = false;
    }, 5000);
    this.subs.sink = this.printerService.getPrintersList().subscribe(
      (printers) => {
        this.setPrinter(printers);
        this.calcMaxHeight();
        this.printersListIsLoading = false;
      },
      () => {
        this.printersListIsLoading = false;
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private setCoordinates() {
    const { KITCHEN, CASH_REGISTER } = ReceiptPrinterModeTypes;
    if (this.posMode === KITCHEN || this.posMode === CASH_REGISTER) {
      this.coordinates.offsetLeft = 'auto';
      this.coordinates.offsetRight = '5px';
      return;
    }

    this.coordinates.offsetRight = 'auto';
  }

  private setPrinter(printers: Array<ReceiptPrinter>): void {
    if (printers.length === 0) return;
    this.printers = printers;
  }

  private calcMaxHeight() {
    const { offsetBottom, offsetTop, bodyMaxHeight } = this.coordinates;
    const maxHeight = 276;
    this.maxHeight = this.printers.length > 4 ? maxHeight : 69 * this.printers.length;
    if (this.printers.length === 0) {
      this.maxHeight = 49;
    }

    if (offsetTop === 'auto' && this.maxHeight === maxHeight) {
      const offsetBottomNumber = offsetBottom.replace('px', '');
      const diffOffsetTop = bodyMaxHeight - this.maxHeight - parseInt(offsetBottomNumber);
      if (diffOffsetTop < 0) {
        const paddingTop = 5;
        this.maxHeight = this.maxHeight + diffOffsetTop - paddingTop;
      }
    }
  }

  public printTestReceipt(printer: ReceiptPrinter) {
    this.printerService.testPrint(printer);
    this.dismiss();
  }

  public selectPrinter(printer: ReceiptPrinter) {
    if (printer.printerConfiguration.model === undefined && printer.deviceName !== PAYMENT_PROVIDERS.MYPOS) {
      this.printerService
        .showSelectModelPopUp(printer)
        .then((printerData) => {
          this.saveAndPrint(new ReceiptPrinter(printerData));
        })
        .catch(() => {});
    } else {
      this.saveAndPrint(printer);
    }
  }

  private saveAndPrint(printer: ReceiptPrinter) {
    this.receiptPrintersService.setActivePrinter(printer, this.posMode);
    if (this.posMode === ReceiptPrinterModeTypes.CASH_REGISTER) {
      this.printerService.openCashRegister().catch((err) => this.logService.error('PrintersListModal', 'saveAndPrint:', err));
      this.dismiss(true);
    } else {
      this.printReceipt(printer);
    }
  }

  public printReceipt(printer: ReceiptPrinter) {
    if (!this.invoice) {
      this.printingNotPossibleAlertShow();
      return;
    }
    this.CartService.setActiveInvoiceId();
    this.LoadingService.showLoadingItem();
    this.logService.debug('PrinterList', `Invoice ${this.invoice.uuid} will be printered as printReceipt`);
    this.printerService
      .createReceiptFromInvoice(this.invoice, printer, this.posMode, false, this.guestNumber)
      .then((receipt) => {
        if (receipt) {
          this.printerService
            .printReceipt(printer, receipt, {
              uuid: this.invoice.uuid,
              name: this.invoice.invoiceDisplayId,
            })
            .then(() => {
              if (this.posMode === ReceiptPrinterModeTypes.KITCHEN || this.posMode === ReceiptPrinterModeTypes.BILL) {
                this.CartService.cleanInvoiceEntriesKitchenQuantity(this.guestNumber);
              }
              if (this.invoice.isPaid && !this.invoice.isPrinted && this.posMode !== ReceiptPrinterModeTypes.KITCHEN) {
                this.invoice.isPrinted = true;
                this.InvoicesService.saveInvoice(this.invoice).catch((err) =>
                  this.logService.error('PrintersListModal', 'printReceipt:InvoicesService:saveInvoice', err)
                );
              }
              this.LoadingService.hideLoadingItem();
            })
            .catch((err) => {
              this.logService.error('PrintersListModal', 'printReceipt:printerService:printReceipt', err);
              this.LoadingService.hideLoadingItem();
            });
        } else {
          this.LoadingService.hideLoadingItem();
          this.printingNotPossibleAlertShow();
        }
      })
      .catch((err) => this.logService.error('PrintersListModal', 'printReceipt:printerService:createReceiptFromInvoice', err));
    this.dismiss(true);
  }

  private async printingNotPossibleAlertShow() {
    let printingNotPossible = await this.AlertService.create({
      header: this.translateService.instant('receipt_printer_printing_not_possible_title'),
      subHeader: this.translateService.instant('receipt_printer_printing_not_possible_msg'),
      buttons: ['OK'],
    });
    printingNotPossible
      .present()
      .catch((err) => this.logService.error('PrintersListModal', 'printingNotPossibleAlertShow:printingNotPossible:present', err));
  }

  dismiss(printerSelect?: Boolean) {
    this.viewCtrl.dismiss(printerSelect).catch((err) => this.logService.error('PrintersListModal', 'dismiss:viewCtrl:dismiss', err));
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}
