import { APPOINTMENT_NOTIFICATION_CREATED_BY, APPOINTMENT_NOTIFICATION_TYPES } from '@pos-common/constants';

export class AppointmentNotification {
  public uniqueIdentifier: string;
  public appointmentUuid: string;
  public appointmentId: string;
  public createdAt: string;
  public startedAt: string;
  public text: string;
  public type: string;
  public isAppointmentDeleted: boolean;
  public createdBy: string;

  constructor(data: any = {}) {
    this.uniqueIdentifier = data.uniqueIdentifier || '';
    this.appointmentUuid = data.appointmentUuid || '';
    this.appointmentId = data.appointmentId || 0;
    this.createdAt = this.getDateAt(data.createdAt);
    this.startedAt = data.startedAt || new Date().toISOString();
    this.type = APPOINTMENT_NOTIFICATION_TYPES[data.type] || APPOINTMENT_NOTIFICATION_TYPES.CREATE;
    this.createdBy = APPOINTMENT_NOTIFICATION_CREATED_BY[data.createdBy] || APPOINTMENT_NOTIFICATION_CREATED_BY.EMPLOYEE;
    this.text = data.text || '';
    this.isAppointmentDeleted = data.isAppointmentDeleted || false;
  }

  private getDateAt(dateAt: string) {
    if (!dateAt) {
      return new Date().toISOString();
    }
    const [day, month, year, time] = dateAt.replace('T', '-').split('-');
    return `${year}-${month}-${day}T${time}`;
  }
}
