export class PrinterDto {
  public deviceType: number;
  public target: string;
  public deviceName: string;
  public ipAddress: string;
  public macAddress: string;


  constructor(deviceType: number, target: string, deviceName: string, ipAddress: string, macAddress: string) {
    this.deviceType = deviceType;
    this.target = target;
    this.deviceName = deviceName;
    this.ipAddress = ipAddress;
    this.macAddress = macAddress;
  }
}
