import { EntityInterface } from "../interfaces/entity.interface";

export class VirtualPrinter implements EntityInterface {
  uuid: string;
  name: string;
  deleted: boolean;


  constructor(data) {
    this.uuid = data.uuid;
    this.name = data.name;
    this.deleted = data.deleted;
  }

}
