<pos-search
  [placeholder]="placeholderSearch"
  [autofocus]="showAutofocus"
  (onSearch)="handleSearchValue($event)"
  (onClearValue)="clearSearch()"
></pos-search>
<ion-content>
  <pos-product-search-list
    *ngIf="!isLoading"
    [productItems]="itemList"
    [placeholderNoResult]="placeholderNoResult"
    (posProductLongClick)="productLongClick($event)"
    (posProductClick)="productClick($event)"
  ></pos-product-search-list>

  <pos-loader class="loader-contaner" [isLoading]="isLoading"></pos-loader>

  <ion-infinite-scroll threshold="20px" (ionInfinite)="loadMore($event)" [disabled]="isLoadMore">
    <ion-infinite-scroll-content loadingSpinner="crescent"> </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
