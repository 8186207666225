import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PayCalculator } from '@pos-common/classes/pay-calculator/pay-calculator.class';

@Injectable()
export class CheckoutAsideService {
  private payCalculator$ = new BehaviorSubject<PayCalculator>(new PayCalculator());
  private isOnInvoiceActive$ = new BehaviorSubject<boolean>(false);

  setPayCalculator(payCalculator: PayCalculator) {
    this.payCalculator$.next(payCalculator);
  }

  getPayCaclulator() {
    return this.payCalculator$;
  }

  setIsOnInvoiceActive(value: boolean) {
    this.isOnInvoiceActive$.next(value);
  }

  getIsOnInvoiceActive() {
    return this.isOnInvoiceActive$;
  }
}
