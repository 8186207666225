export class ServiceOptions {
  public bookingLimit: number;
  public serviceDuration: string;
  public servicePreparationTime: string;
  public servicePostProcessingTime: string;

  constructor(data: any = {}) {
    this.bookingLimit = data.bookingLimit || -1;
    this.serviceDuration = data.serviceDuration || '';
    this.servicePreparationTime = data.servicePreparationTime || '';
    this.servicePostProcessingTime = data.servicePostProcessingTime || '';
  }
}
