import { Buffer } from 'buffer';
import { Socket } from '@spryrocks/capacitor-socket-connection-plugin';

export class DataWrapper {
  public onMessageReceived?: (data: object) => void;

  private buffer: Buffer;

  constructor(private readonly socket: Socket) {
    this.buffer = Buffer.alloc(0);

    socket.onData = this.onSocketData.bind(this);
  }

  async sendMessage(data: object): Promise<void> {
    const dataString = JSON.stringify(data);
    const dataBuffer = Buffer.from(dataString);
    const messageLength = dataBuffer.length;
    const messageLengthBuffer = Buffer.alloc(4);
    messageLengthBuffer.writeInt32BE(messageLength, 0);
    await this.socket.write(messageLengthBuffer);
    await this.socket.write(dataBuffer);
  }

  clearCallbacks() {
    this.onMessageReceived = undefined;
  }

  private onSocketData(data: Buffer) {
    this.buffer = Buffer.concat([this.buffer, data]);

    // noinspection StatementWithEmptyBodyJS
    while (this.processData());
  }

  private processData(): boolean {
    const buffer = this.buffer;

    if (buffer.length < 4) return false;

    const messageBufferStart = 4;
    const messageBufferLength = buffer.readInt32BE(0);

    const fullMessageReceived = buffer.length >= messageBufferStart + messageBufferLength;
    if (!fullMessageReceived) return false;

    const messageBuffer = Buffer.alloc(messageBufferLength);
    buffer.copy(
      messageBuffer,
      0,
      messageBufferStart,
      messageBufferStart + messageBufferLength,
    );

    const newBufferStart = messageBufferStart + messageBufferLength;
    const newBufferLength = buffer.length - messageBufferStart - messageBufferLength;
    const newBuffer = Buffer.alloc(newBufferLength);
    buffer.copy(newBuffer, 0, newBufferStart, newBufferStart + newBufferLength);
    this.buffer = newBuffer;

    this.processMessage(messageBuffer);

    return true;
  }

  private processMessage(messageBuffer: Buffer) {
    const data = JSON.parse(messageBuffer.toString());

    if (this.onMessageReceived) {
      this.onMessageReceived(data);
    }
  }
}
