<pos-header>
  <button tappable (click)="dismiss()" header-start>
    <pos-icon customName="custom-icon-cancel-search"></pos-icon>
  </button>
  <ion-title header-title>{{ 'invoice_print_kitchen_receipt' | translate }}</ion-title>
  <button (click)="handleDone()" header-end tappable>
    <pos-icon customName="custom-icon-select"></pos-icon>
  </button>
</pos-header>
<ion-content>
  <ion-list class="select-guest-list">
    <ion-item class="all-guests-button" lines="full" (click)="selectGuest()">
      <div class="pos-checkbox">
        <div class="checkbox-icon" [class.checkbox-checked]="isAllGuestsSelected">
          <div class="checkbox-inner"></div>
        </div>
      </div>
      <ion-label>{{ 'common_all_guest' | translate }}</ion-label>
    </ion-item>
    <ion-item
      *ngFor="let invoiceEntryGuest of invoiceEntryGuests; trackByUuid"
      lines="full"
      [class.disabled]="!!!invoiceEntryGuest.quantityForKitchenReceipt"
      (click)="selectGuest(invoiceEntryGuest.guestNumber)"
    >
      <guest-button
        [isSelected]="invoiceEntryGuest.guestNumber === activeGuestNumber"
        [marker]="invoiceEntryGuest.guestNumber"
        [name]="invoiceEntryGuest.name"
        colorMode="blue"
        [isDisabled]="!!!invoiceEntryGuest.quantityForKitchenReceipt"
      ></guest-button>
      <pos-printer-icon slot="end" [invoice]="invoice" [guestNumber]="invoiceEntryGuest.guestNumber"></pos-printer-icon>
    </ion-item>
  </ion-list>
</ion-content>
