import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { Injectable } from '@angular/core';
import { DirectoryEntry, Entry, FileEntry, Flags } from '@ionic-native/file/ngx';
import { CodecUtils } from '@pos-common/services/utils/codec.utils';
import { FileReaderUtils } from '@pos-common/services/utils/file-reader/file-reader.utils';
import { PAYMASH_PROFILE } from '@profile';

type FileWriter = {
  write(data: string | Blob): Promise<void>;
  seek(length: number): void;
  get length(): number;
};

type WriteFileAsyncOptions = {
  create?: boolean;
  append?: boolean;
};

type UseFileWriterAsyncOptions = {
  create?: boolean;
};

@Injectable()
export class FileService {
  constructor(private platformService: PlatformService) {}

  public resolveDirectoryAsync(directory: string): Promise<DirectoryEntry> {
    return new Promise((resolve, reject) => {
      window['resolveLocalFileSystemURL'](
        directory,
        (entry: Entry) => {
          resolve(<DirectoryEntry>entry);
        },
        reject
      );
    });
  }

  public resolveDataDirectoryAsync() {
    return this.resolveDirectoryAsync(cordova.file.dataDirectory);
  }

  public createFileAsync(parent: DirectoryEntry, name: string): Promise<FileEntry> {
    return this.getFileAsync(parent, name, { create: true });
  }

  public getFileAsync(parent: DirectoryEntry, name: string, flags?: Flags): Promise<FileEntry> {
    return new Promise((resolve, reject) => {
      parent.getFile(
        name,
        flags,
        (file) => resolve(file),
        (error) => reject(error)
      );
    });
  }

  public getFilesInternalAsync(directory: DirectoryEntry): Promise<FileEntry[]> {
    return new Promise((resolve, reject) => {
      directory.createReader().readEntries(
        (entries) => resolve(entries.filter((e) => e.isFile) as FileEntry[]),
        (error) => reject(error)
      );
    });
  }

  public getDirectoriesInternalAsync(directory: DirectoryEntry): Promise<FileEntry[]> {
    return new Promise((resolve, reject) => {
      directory.createReader().readEntries(
        (entries) => resolve(entries.filter((e) => e.isDirectory) as FileEntry[]),
        (error) => reject(error)
      );
    });
  }

  public getDirectoryAsync(parent: DirectoryEntry, name: string, flags?: Flags): Promise<DirectoryEntry> {
    return new Promise((resolve, reject) => {
      parent.getDirectory(
        name,
        flags,
        (directory) => resolve(directory),
        (error) => reject(error)
      );
    });
  }

  public createDirectoryAsync(parent: DirectoryEntry, name: string): Promise<DirectoryEntry> {
    return this.getDirectoryAsync(parent, name, { create: true });
  }

  public moveDirectoryAsync(directory: DirectoryEntry, parent: DirectoryEntry, target: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      directory.moveTo(
        parent,
        target,
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public moveFileAsync(file: FileEntry, parent: DirectoryEntry, target: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      file.moveTo(
        parent,
        target,
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public copyDirectoryAsync(directory: DirectoryEntry, parent: DirectoryEntry, target: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      directory.copyTo(
        parent,
        target,
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public async writeFileAsync(parent: DirectoryEntry, fileName: string, data: Blob | string, options?: WriteFileAsyncOptions) {
    const file = await this.getFileAsync(parent, fileName, { create: options?.create ?? true });
    return this.writeFileInternalAsync(file, data, options);
  }

  public writeFileInternalAsync(file: FileEntry, data: Blob | string, options?: WriteFileAsyncOptions) {
    return this.useFileWriterInternalAsync(file, async (writer) => {
      if (options?.append) {
        writer.seek(writer.length);
      }
      await writer.write(data);
    });
  }

  public async useFileWriterAsync(
    parent: DirectoryEntry,
    fileName: string,
    writer: (fileWriter: FileWriter) => Promise<void>,
    options?: UseFileWriterAsyncOptions
  ) {
    const file = await this.getFileAsync(parent, fileName, { create: options?.create ?? true });
    return this.useFileWriterInternalAsync(file, writer);
  }

  public useFileWriterInternalAsync(file: FileEntry, writer: (fileWriter: FileWriter) => Promise<void>) {
    return new Promise<void>((resolve, reject) => {
      file.createWriter(
        (fileWriter_) => {
          let writePromise: Promise<void>;

          const fileWriter: FileWriter = {
            write(data: string | Blob) {
              if (writePromise) {
                return Promise.reject('wait until previous write operation finished');
              }

              writePromise = new Promise<void>((resolve) => {
                fileWriter_.onwriteend = () => {
                  writePromise = undefined;
                  resolve();
                };

                fileWriter_.onerror = (err) => {
                  writePromise = undefined;
                  reject(err);
                };

                fileWriter_.write(data);
              });
              return writePromise;
            },

            seek(length: number) {
              fileWriter_.seek(length);
            },

            get length() {
              return fileWriter_.length;
            },
          };

          writer(fileWriter)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public readFileInternalAsync(file: FileEntry): Promise<string> {
    return new Promise((resolve, reject) => {
      file.file(
        (file) => {
          const reader = FileReaderUtils.getFileReader();
          reader.onloadend = (event: ProgressEvent) => {
            const contents = event.target['result'];
            if (contents instanceof ArrayBuffer) {
              try {
                const result = CodecUtils.decodeUtf8ToString(contents);
                resolve(result);
              } catch (err) {
                reject(err);
              }
            } else if (contents.length === 0) {
              resolve(null);
            } else {
              resolve(contents);
            }
          };

          if (this.platformService.isIOS && file.size > PAYMASH_PROFILE.lokiJS.maxFileSize) {
            reader.readAsArrayBuffer(file);
          } else {
            reader.readAsText(file);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public getFileSizeInternalAsync(file: FileEntry): Promise<number> {
    return new Promise((resolve, reject) => {
      file.file(
        (file) => {
          resolve(file.size);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public async isFileAsync(parent: DirectoryEntry, name: string): Promise<boolean> {
    try {
      const file = await this.getFileAsync(parent, name);
      return file.isFile;
    } catch (e) {
      return false;
    }
  }

  public async isDirectoryExistsAsync(parent: DirectoryEntry, name: string): Promise<boolean> {
    try {
      const directory = await this.getDirectoryAsync(parent, name);
      return directory.isDirectory;
    } catch (_) {
      return false;
    }
  }

  public async deleteDirectoryAsync(parent: DirectoryEntry, name: string): Promise<boolean> {
    try {
      const dir = await this.getDirectoryAsync(parent, name);
      return new Promise<boolean>((resolve) => {
        if (dir.isDirectory || dir.isFile) {
          dir.removeRecursively(
            () => resolve(true),
            () => resolve(false)
          );
          return;
        }

        resolve(true);
      });
    } catch (_) {
      return false;
    }
  }

  public async removeFileAsync(parent: DirectoryEntry, name: string): Promise<boolean> {
    try {
      const file = await this.getFileAsync(parent, name);
      return this.removeFileInternalAsync(file);
    } catch (_) {
      return false;
    }
  }

  public removeFileInternalAsync(file: FileEntry) {
    return new Promise<boolean>((resolve) => {
      if (file.isFile) {
        file.remove(
          () => resolve(true),
          () => resolve(false)
        );
        return;
      }

      resolve(true);
    });
  }

  // adapted from http://stackoverflow.com/questions/15293694/blob-constructor-browser-compatibility
  public createBlob(data: any, datatype: string = 'text/plain') {
    let blob;

    try {
      blob = new Blob([data], { type: datatype });
    } catch (err) {
      window['BlobBuilder'] = window['BlobBuilder'] || window['WebKitBlobBuilder'] || window['MozBlobBuilder'] || window['MSBlobBuilder'];

      if (err.name === 'TypeError' && window['BlobBuilder']) {
        let bb = new window['BlobBuilder']();
        bb.append(data);
        blob = bb.getBlob(datatype);
      } else if (err.name === 'InvalidStateError') {
        // InvalidStateError (tested on FF13 WinXP)
        blob = new Blob([data], { type: datatype });
      } else {
        // We're screwed, blob constructor unsupported entirely
        throw new Error('Unable to create blob' + JSON.stringify(err));
      }
    }
    return blob;
  }
}
