import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import { defineCustomElements } from '@paymash/paymash-shifts/loader';
import { PAYMASH_PROFILE } from '@profile';
import { enableProdMode } from '@angular/core';

/* eslint-disable no-console */
if (PAYMASH_PROFILE.APP_ENV === 'production') {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => defineCustomElements(window))
  .catch((e) => console.error('bootstrapModule', e));
