import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IMAGE_SIZES } from '@pos-common/classes/image.class';
import { ProductItem } from '@pos-common/classes/product-item.class';
import { IInventoryEvent } from '@pos-common/interfaces/inventory-event.interface';
import { ImageLoaderCapComponentModule } from '../image-loader-cap/image-loader-cap.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImageUrlPipeModule } from '@pos-common/pipes';
import { NgForTrackByUuidDirectiveModule } from '@pos-modules/shared/directives';

@Component({
  selector: 'pos-product-search-list',
  templateUrl: './product-search-list.component.html',
  styleUrls: ['./product-search-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSearchListComponent {
  @Input() productItems: ProductItem[] = [];
  @Input() placeholderNoResult = 'inventory_search_no_products';
  @Input() showActiveProduct = false;
  @Input() activeProductItem: ProductItem;
  @Output() posProductLongClick = new EventEmitter<ProductItem>();
  @Output() posProductClick = new EventEmitter<IInventoryEvent>();

  get IMAGE_SIZES() {
    return IMAGE_SIZES;
  }

  productLongClick(productItem: ProductItem) {
    this.posProductLongClick.emit(productItem);
  }

  productClick(productItem: ProductItem, eventTarget: any) {
    if (this.activeProductItem && this.activeProductItem.uuid === productItem.uuid) {
      return;
    }
    this.posProductClick.emit({ dataset: productItem, eventTarget });
    if (this.showActiveProduct) {
      this.activeProductItem = productItem;
    }
  }
}
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    ImageLoaderCapComponentModule,
    ImageUrlPipeModule,
    NgForTrackByUuidDirectiveModule,
  ],
  declarations: [ProductSearchListComponent],
  exports: [ProductSearchListComponent],
})
export class ProductSearchListComponentModule {}
