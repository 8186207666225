<ion-backdrop tappable class="dark"></ion-backdrop>
<div class="modal-inner">
  <common-header>
    <button class="back-link" [hidden]="selectedIndividualProductData" tappable (click)="closePopover()" header-start>
      <i class="custom-icon custom-icon-cancel-search"></i>
    </button>
    <h2 header-title>{{ 'individual_product_modal_title' | translate }}</h2>
    <button class="confirm-link" tappable (click)="closePopover(IndividualProductModalButtons.CONFIRM)" header-end>
      <i class="custom-icon custom-icon-select"></i>
    </button>
  </common-header>
  <div class="modal-body">
    <form [formGroup]="individualProductForm">
      <div class="name-block">
        <p class="row row-label-value">
          <span class="row-label">{{ 'individual_product_modal_name' | translate }}</span>
          <span class="row-value">
            <input type="text" formControlName="name" placeholder="{{ 'individual_product_modal_name_placeholder' | translate }}" />
          </span>
        </p>
      </div>
      <div class="name-block">
        <p class="row row-label-value">
          <span class="row-label">{{ 'product_details_modal_note' | translate }}</span>
          <span class="row-value">
            <input type="text" formControlName="note" placeholder="{{ 'product_details_modal_note' | translate }}" />
          </span>
        </p>
      </div>
      <div class="quantity-block">
        <ul>
          <li class="quantity-subtract">
            <a tappable (click)="changeQuantity('subtract')"><i class="custom-icon custom-icon-minus-border"></i></a>
          </li>
          <li class="quantity-value">
            <input formControlName="quantity" type="number" />
          </li>
          <li class="quantity-add">
            <a tappable (click)="changeQuantity('add')"><i class="custom-icon custom-icon-plus-border"></i></a>
          </li>
        </ul>
      </div>
      <div class="price-block">
        <p class="row row-label-value">
          <span class="row-label">{{ 'individual_product_modal_price' | translate }}</span>
          <span class="row-value">{{ individualProductForm.controls['priceDecimal'].value | CustomCurrency : false }}</span>
        </p>
      </div>
    </form>

    <virtual-keyboard [keyboardType]="'AMOUNT'" [clickCallback]="keyboardClickCallback"></virtual-keyboard>

    <div class="tax-rates-block tax-rates-length-{{ listVATRate.length }}">
      <ul *ngIf="listVATRate.length">
        <li *ngFor="let item of listVATRate; trackBy: trackByFn" [class.active]="vatRate && vatRate.uuid === item.uuid">
          <a tappable (click)="changeVatRate(item)">{{ item.displayValue }}</a>
        </li>
      </ul>
      <ng-template *ngIf="!listVATRate.length">
        <a class="btn" *ngIf="listUpdateInProgress">
          <ion-spinner name="lines"></ion-spinner>
        </a>
        <a class="btn" *ngIf="!listUpdateInProgress" tappable (click)="getListUpdate()">{{ 'get_update_vat_rates' | translate }}</a>
      </ng-template>
    </div>

    <div class="add-button-holder">
      <a
        class="btn btn-remove"
        tappable
        (click)="closePopover(IndividualProductModalButtons.REMOVE)"
        [hidden]="!selectedIndividualProductData"
        ><i class="custom-icon custom-icon-paid-invoices-trash"></i
      ></a>
      <a
        class="btn btn-add-product"
        tappable
        (click)="closePopover(IndividualProductModalButtons.ADD)"
        [hidden]="selectedIndividualProductData"
        >{{ 'individual_product_modal_add_to_cart' | translate }}</a
      >
      <a
        class="btn btn-done"
        tappable
        (click)="closePopover(IndividualProductModalButtons.DONE)"
        [hidden]="!selectedIndividualProductData"
        >{{ 'individual_product_modal_done' | translate }}</a
      >
    </div>
  </div>
</div>
