import { Entity } from "./entity.class";
/**
 * Created by maksymkunytsia on 8/31/16.
 */

export class PaymentMethod extends Entity {
  uuid: string;
  name: string;
  method: string;
  visiblePOS: boolean;
  visibleDESKTOP: boolean;
  visibleWEBSHOP: boolean;
  position: number;
  deleted: boolean;
  isDefaultMethod: boolean;


  constructor(data) {
    super(data);
    this.name = data.name || '';
    this.method = data.method || '';
    this.visiblePOS = data.visiblePOS || false;
    this.position = data.position || 0;
  }
}
