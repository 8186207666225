import { StarPrinterService } from './star-printer.service';
import { StarPrinterMockService } from './star-printer.mock.service';
import { PlatformService } from '../../../platform/platform.service';
import { Injector } from '@angular/core';

export function starPrintersServiceFactory(injector: Injector) {
  const platformService = injector.get(PlatformService);
  if (platformService.isNativePlatform) {
    return new StarPrinterService(injector);
  }
  return new StarPrinterMockService(injector);
}
