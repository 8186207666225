<ion-backdrop tappable (click)="dismiss()" class="dark"></ion-backdrop>
<ion-header>
  <common-header>
    <button tappable (click)="dismiss()" header-start>
      <i class="custom-icon custom-icon-cancel-search"></i>
    </button>
    <ion-title header-title>{{'receipt_printer_select_printer_modal_title' | translate}}</ion-title>
  </common-header>
</ion-header>
<ion-content
  class="printers-list-modal-content"
  [class.bottom]="coordinates.bottom"
  [class.kitchen]="posMode === receiptPrinterModeTypes.KITCHEN"
  [class.arrow-right]="coordinates.arrowDirection === 'right'"
  [style.top]="coordinates.offsetTop"
  [style.left]="coordinates.offsetLeft"
  [style.right]="coordinates.offsetRight"
  [style.bottom]="coordinates.offsetBottom"
  [style.max-height]="maxHeight + 'px'"
>
  <div class="scroll-content">
    <div class="printers-list-loading" [hidden]="!printersListIsLoading">
      <ion-spinner name="crescent" color="primary"></ion-spinner>
    </div>
    <ion-list class="printers-list" [hidden]="printersListIsLoading">
      <ion-item *ngIf="printers.length === 0" class="printers-no-found">
        <h2>{{'receipt_printer_printers_do_not_found' | translate}}</h2>
      </ion-item>
      <ion-item *ngFor="let printer of printers; trackBy:trackByFn">
        <ion-label>
          <h2>{{printer.printerName}}</h2>
          <p>{{printer.deviceName}}</p>
        </ion-label>
        <ion-button fill="clear" tappable (click)="printTestReceipt(printer)">{{'receipt_printer_test_printer' | translate}}</ion-button>
        <span class="buttons-divider">|</span>
        <ion-button fill="clear" slot="end" tappable (click)="selectPrinter(printer)"
          >{{'receipt_printer_select_printer' | translate}}</ion-button
        >
      </ion-item>
    </ion-list>
  </div>
</ion-content>
