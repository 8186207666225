import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['inventory.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InventoryComponent {
  @Input() inventorySearchActive = false;
}
