import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable()
export class AutofillInputService {
  private renderer2: Renderer2;
  constructor(rendererFactory: RendererFactory2) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  addAutofillToAlertInput(alert: HTMLIonAlertElement, inputClass: string, value: string) {
    if (!value) {
      return;
    }

    let inputWrapperElement: Element = null;
    let inputElement: Element = null;
    alert.querySelectorAll('.alert-input-wrapper').forEach((alertInput) => {
      const input = alertInput.querySelector(`.${inputClass}`);
      if (input) {
        inputWrapperElement = alertInput;
        inputElement = input;
      }
    });

    if (!inputWrapperElement || !inputElement) {
      return;
    }

    alert.cssClass += ' autofill-alert';
    const divWrapper = this.renderer2.createElement('div');
    this.renderer2.addClass(divWrapper, 'autofill-alert-list');

    const spanElement = this.renderer2.createElement('span');
    this.renderer2.addClass(spanElement, 'autofill-alert-item');
    this.renderer2.setProperty(spanElement, 'innerHTML', value);
    this.renderer2.appendChild(divWrapper, spanElement);
    this.renderer2.setAttribute(inputElement, 'autocomplete', 'off');

    const onFocusListen = this.renderer2.listen(inputElement, 'focus', () => this.onFocus(inputWrapperElement, divWrapper));
    const onBlueListen = this.renderer2.listen(inputElement, 'blur', () => this.onBlur(divWrapper));
    const onMousedownAlertListen = this.renderer2.listen(divWrapper, 'mousedown', () => {
      this.onMousedownAlert(alert, inputClass, value);
      this.onBlur(divWrapper);
    });

    let listenEvents = [onFocusListen, onBlueListen, onMousedownAlertListen];

    alert.onDidDismiss().then(() => listenEvents.forEach((listenEvent) => listenEvent()));
  }

  private onFocus(element: Element, divWrapper: HTMLElement) {
    this.renderer2.setStyle(divWrapper, 'display', 'block');
    this.renderer2.appendChild(element, divWrapper);
  }

  private onBlur(divWrapper: HTMLElement) {
    this.renderer2.setStyle(divWrapper, 'display', 'none');
  }

  private onMousedownAlert(alert: HTMLIonAlertElement, inputClass: string, tipValue: string) {
    const inputElements = alert.querySelectorAll('input');
    const inputs = [...alert.inputs];
    alert.inputs = inputs.map((input, index) => {
      let { value } = inputElements[index];
      if (input.cssClass.includes(inputClass)) {
        value = tipValue;
      }
      input.value = value;
      this.renderer2.setProperty(inputElements[index], 'value', value);
      return input;
    });
  }
}
