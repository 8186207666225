import { Component, Input, OnChanges, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, NgModule } from '@angular/core';
import { DbDaoService } from '../../services/db/db-dao.service';
import { Customer } from '../../classes/customer.class';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { IMAGE_SIZES } from '../../classes/image.class';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageLoaderCapComponentModule } from '@pos-modules/shared/components';
import { PersonPlaceholderModule } from '../person-placeholder/person-placeholder.component';
import { ImageUrlPipeModule } from '@pos-common/pipes';

@Component({
  selector: '<customer-btn></customer-btn>',
  styleUrls: ['customer-btn.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'customer-btn.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerBtn implements OnChanges {
  @Input()
  customerInputData: Customer = null;
  readonly IMAGE_SIZES: any = IMAGE_SIZES;
  customer: Customer = null;
  readonly customerInputDataKey = 'customerInputData';

  constructor(public dbDaoService: DbDaoService, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes) {
    const customerInputData = changes[this.customerInputDataKey];
    const shoudUpdateCustomerInputData = this.shoudUpdateCustomerInputData(customerInputData);
    if (!shoudUpdateCustomerInputData) {
      return;
    }

    if (customerInputData.currentValue) {
      this.setCustomer(customerInputData.currentValue);
    } else {
      setTimeout(() => {
        this.cleanCustomer();
      }, 50);
    }
  }

  private setCustomer(currentValue) {
    setTimeout(() => {
      this.dbDaoService
        .getDataByUUID(UPDATES_TYPES.Customer.type, currentValue.uuid)
        .then((data) => {
          if (data.data && data.status === 200) {
            this.customer = null;
            setTimeout(() => {
              this.customer = new Customer(data.data);
              this.cdr.markForCheck();
            });
          } else {
            this.cleanCustomer();
          }
        })
        .catch(() => this.cleanCustomer());
    }, 50);
  }

  private cleanCustomer() {
    this.customer = null;
    this.cdr.markForCheck();
  }

  private shoudUpdateCustomerInputData(customerInputData) {
    if (customerInputData.previousValue && customerInputData.currentValue) {
      const previousValue = JSON.stringify(customerInputData.previousValue).toLocaleLowerCase();
      const currentValue = JSON.stringify(customerInputData.currentValue).toLocaleLowerCase();
      return previousValue !== currentValue;
    }
    return true;
  }
}
@NgModule({
  imports: [CommonModule, TranslateModule.forChild(), ImageLoaderCapComponentModule, PersonPlaceholderModule, ImageUrlPipeModule],
  declarations: [CustomerBtn],
  exports: [CustomerBtn],
})
export class CustomerBtnModule {}
