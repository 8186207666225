import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input, NgModule } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import { SELECT_GUEST_MODAL_ACTION } from '@pos-common/constants';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { HeaderComponentModule, IconComponentModule } from '@pos-modules/shared/components';
import { GuestButtonComponentModule } from '../guest-button/guest-button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pos-transfer-guests-modal',
  templateUrl: './transfer-guests-modal.component.html',
  styleUrls: ['./transfer-guests-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferGuestsModalComponent implements OnInit {
  @Input() invoiceEntryGuests: InvoiceEntryGuest[];
  @Input() selectedGuestNumber: number = 0;

  public activeGuestNumber: number;

  get isAllGuestsSelected() {
    return this.activeGuestNumber === 0;
  }

  constructor(private modalController: ModalController, private cdr: ChangeDetectorRef, private logService: LogService) {}

  ngOnInit() {
    this.selectGuest(this.selectedGuestNumber);
  }

  dismiss(data?: any, action?: string) {
    action = action || SELECT_GUEST_MODAL_ACTION.CANCEL;

    this.modalController
      .dismiss(data, action)
      .catch((err) => this.logService.error('TransferGuestsModalPage', 'dismiss:modalController:dismiss', err));
  }

  selectGuest(guestNumber: number = 0) {
    this.activeGuestNumber = guestNumber;
    this.cdr.detectChanges();
  }

  handleDone() {
    const data = {
      activeGuestNumber: this.activeGuestNumber,
    };
    this.dismiss(data, SELECT_GUEST_MODAL_ACTION.SELECT);
  }
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    IonicModule,
    TranslateModule.forChild(),
    HeaderComponentModule,
    IconComponentModule,
    GuestButtonComponentModule,
  ],
  declarations: [TransferGuestsModalComponent],
  exports: [TransferGuestsModalComponent],
})
export class TransferGuestsModalComponentModule {}
