import { ReceiptPrinter } from '../../classes/receipt-printer.class';
import { Observable } from 'rxjs';
import { EpsonPrinterService } from './epson-printer.service';
import { Injectable } from '@angular/core';
import { PrinterType } from '../../enum/printer-type.enum';

@Injectable()
export class EpsonPrinterMockService extends EpsonPrinterService {
  protected get pluginName(): string {
    return 'EpsonPrinterMockService';
  }

  private static cordovaMocks(): ReceiptPrinter[] {
    return [
      new ReceiptPrinter({
        deviceType: PrinterType.Epson,
        deviceName: 'TM-m30',
        ipAddress: '10.0.1.6',
        macAddress: '00:01:90:C2:BB:7D',
        target: 'TCP:00:01:90:C2:BB:7D',
      }),
      new ReceiptPrinter({
        deviceType: PrinterType.Epson,
        deviceName: 'TM-m30',
        ipAddress: '10.0.1.10',
        macAddress: '00:01:90:C2:BB:7C',
        target: 'TCP:00:01:90:C2:BB:7C',
      }),
      new ReceiptPrinter({
        deviceType: PrinterType.Epson,
        deviceName: 'TM-P20_005325',
        ipAddress: '',
        macAddress: '00:01:90:C2:BB:7B',
        target: 'BT:00:01:90:C2:BB:7B',
      }),
      new ReceiptPrinter({
        deviceType: PrinterType.Epson,
        deviceName: 'TM-P20',
        ipAddress: '',
        target: 'BT:64:EB:8C:FE:26:CY',
        macAddress: '64:EB:8C:FE:26:CY',
      }),
    ];
  }

  protected browserMockPerform(): void {
    setTimeout(() => this.handleDiscover('OK'), 500);
    setTimeout(() => this.handleDiscover(EpsonPrinterMockService.cordovaMocks()), 1500);
    setTimeout(() => this.handleDiscover(EpsonPrinterMockService.cordovaMocks()), 30000);
    setTimeout(() => this.handleDiscover(EpsonPrinterMockService.cordovaMocks()), 4500);
  }

  protected discover(): Observable<ReceiptPrinter[]> {
    this.browserMockPerform();
    return this.printerList;
  }

  protected callStopDiscoverMethod() {
    return Promise.resolve();
  }
}
