import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { CalendarService } from '@pos-common/services/system/calendar/calendar.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';

@Component({
  selector: 'pos-calendar-list-filter',
  templateUrl: './calendar-list-filter.component.html',
  styleUrls: ['./calendar-list-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class CalendarListFilterComponent implements OnInit, OnDestroy {
  @Output() posSearchValue = new EventEmitter<string>();
  @Output() posClear = new EventEmitter<void>();
  public showSearch = false;

  constructor(private calendarService: CalendarService, private subSinkService: SubSinkService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subSinkService.sink = this.calendarService.getShowSearch().subscribe((showSearch) => {
      this.showSearch = showSearch;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.calendarService.setShowSearch(false);
  }

  handleSearchValue(value: string) {
    this.posSearchValue.emit(value);
  }

  clearSearch() {
    this.posClear.emit();
  }
}
