import { SavedDeviceInfo as SavedDeviceInfoBase, UniversalCustomerDisplayDevice } from '../UniversalCustomerDisplayDevice';
import { InAppDriver } from './InAppDriver';

export class Device extends UniversalCustomerDisplayDevice<ConnectionInfo> {
  setName(name: string) {
    this._name = name;
  }

  createDriver() {
    return new InAppDriver(this, this.loggerFactory);
  }
}

export class ConnectionInfo {
  displayId: number;
}

export interface SavedDeviceInfo extends SavedDeviceInfoBase {
  connectionInfo: ConnectionInfo;
}
