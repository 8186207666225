/**
 * Created by maksymkunytsia on 9/22/16.
 */

// Vendors
import { Injectable } from '@angular/core';

// Common
import { SecurityService } from '../system/security.service';
import { SERVER_CONFIG } from '../../constants/server.const';
import { Customer } from '../../classes/customer.class';

@Injectable()
export class CustomersApiService {

  constructor(public SecurityService: SecurityService) {
  }

  public upsertCustomer(customerData: Customer) {
    return this.SecurityService.doSecureRequest(`${SERVER_CONFIG.API_URL}customers/${customerData.uuid}`, 'put', customerData);
  }

  public deleteCustomer(customerUUID: string) {
    return this.SecurityService.doSecureRequest(`${SERVER_CONFIG.API_URL}customers/${customerUUID}`, 'delete', null);
  }

  public getInvoicesByCustomer(customerUUID: string) {
    return this.SecurityService.doSecureRequest(`${SERVER_CONFIG.API_URL}customers/${customerUUID}/invoices`, 'get', null);
  }

}

