/**
 * Created by y.belinsky on 3/22/17.
 */

export const INVOICE_TYPES = {
  PROPOSAL: 'PROPOSAL',
  INVOICE: 'INVOICE',
  DELIVERY_SLIP: 'DELIVERY_SLIP',
  CANCELLATION: 'CANCELLATION'
};
