import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AppointmentNotification } from '@pos-common/classes/appointment/appointment-notification.class';

@Component({
  selector: 'pos-calendar-notification-item',
  templateUrl: './calendar-notification-item.component.html',
  styleUrls: ['./calendar-notification-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarNotificationItemComponent {
  @Input() notification: AppointmentNotification;
  @Output() posOpen = new EventEmitter<string>();
  @Output() posRemove = new EventEmitter<string>();

  handleOpen() {
    if (this.notification.isAppointmentDeleted) {
      return;
    }
    this.posOpen.emit(this.notification.appointmentUuid);
  }

  handleRemove(event: Event) {
    event.stopPropagation();
    this.posRemove.emit(this.notification.uniqueIdentifier);
  }
}
