import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Injector } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FilterForm } from '@pos-common/classes/calendar/filter-form.class';
import { FilterGroup } from '@pos-common/classes/calendar/filter-group.class';
import { CALENDAR_VIEWS } from '@pos-common/constants';
import { PAGINATION } from '@pos-common/constants/pagination.const';
import { IFilterFormParams } from '@pos-common/interfaces';
import { CalendarService } from '@pos-common/services/system/calendar/calendar.service';
import { SubSink } from 'subsink';

@Component({
  template: '',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class CalendarFilterModalBaseComponent implements OnInit, OnDestroy {
  public filterForm = new FilterForm();
  public filterFormParams: IFilterFormParams = {};
  public isDisabled = false;
  public showRemoveButton = false;
  protected modalController: ModalController;
  protected calendarService: CalendarService;
  protected cdr: ChangeDetectorRef;
  protected filterFormInit = new FilterForm();
  protected abstract view: CALENDAR_VIEWS;
  protected subs = new SubSink();

  constructor(protected injector: Injector) {
    this.modalController = this.injector.get(ModalController);
    this.calendarService = this.injector.get(CalendarService);
    this.cdr = this.injector.get(ChangeDetectorRef);
  }

  ngOnInit() {
    this.subs.sink = this.calendarService.getFilterForm(this.view).subscribe((filterForm) => {
      this.filterForm = filterForm;
      this.cdr.detectChanges();
    });
    const filterForm = this.calendarService.getFilterFormValue(this.view);
    this.filterFormInit = filterForm.copy();
    const defaultFilterForm = this.calendarService.getDefaultFilterForm(this.view);
    this.setRemoveButton(defaultFilterForm.selectedGroups);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async closeModal() {
    await this.dismiss();
    this.calendarService.setFilterForm(this.view, this.filterFormInit);
  }

  async confirmModal() {
    await this.dismiss();
    this.calendarService.setUpdateStatus({ status: PAGINATION.LIST_UPDATE_SOURCE.INIT });
  }

  async clearFilters() {
    await this.dismiss();
    const defaultFilterForm = this.calendarService.getDefaultFilterForm(this.view);
    this.calendarService.setFilterForm(this.view, defaultFilterForm.copy());
    this.calendarService.setUpdateStatus({ status: PAGINATION.LIST_UPDATE_SOURCE.INIT });
  }

  setDisabled() {
    const selectedGroups = this.filterForm.selectedGroups.filter((group) => !group.isHidden);
    this.isDisabled = !!!selectedGroups.length;
  }

  private dismiss() {
    return this.modalController.dismiss();
  }

  private setRemoveButton(defaultGroup: FilterGroup[]) {
    let { selectedGroups } = this.filterForm;
    defaultGroup = defaultGroup.filter((filterGroup) => !filterGroup.isHidden);
    selectedGroups = selectedGroups.filter((filterGroup) => !filterGroup.isHidden);

    this.showRemoveButton = JSON.stringify(defaultGroup) !== JSON.stringify(selectedGroups);
    this.cdr.detectChanges();
  }
}
