export type PingTimerHandler = (message: object) => void;

export class PingTimer {
  private timerId: NodeJS.Timer | undefined;

  constructor(
    private readonly handler: PingTimerHandler,
    private readonly options: {timeout: number; message: object},
  ) {}

  start() {
    this.stop();
    this.timerId = setInterval(() => this.onNotified(), this.options.timeout);
  }

  stop() {
    if (this.timerId) clearInterval(this.timerId);
    this.timerId = undefined;
  }

  private onNotified() {
    this.handler(this.options.message);
  }
}
