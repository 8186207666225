export type WatchdogHandler = () => void;

export class Watchdog {
  private timerId: NodeJS.Timer | undefined;

  constructor(
    private readonly handler: WatchdogHandler,
    private readonly options: {timeout: number},
  ) {}

  public notifyAndWatch() {
    this.stop();
    this.timerId = setTimeout(() => this.onNotified(), this.options.timeout);
  }

  public stop() {
    if (this.timerId) clearTimeout(this.timerId);
    this.timerId = undefined;
  }

  private onNotified() {
    this.stop();
    this.handler();
  }
}
