import { Injectable } from '@angular/core';
import { DbDaoService, GetAllDataOptions } from '../db/db-dao.service';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { Observable } from 'rxjs';
import { DbEntityProvider } from './db-entity.provider';
import { Customer } from '@pos-common/classes/customer.class';
import { Query } from '@paymash/capacitor-database-plugin';

@Injectable()
export class CustomersProvider extends DbEntityProvider {
  constructor(dbDaoService: DbDaoService) {
    super(UPDATES_TYPES.Customer.type, dbDaoService);
  }

  getByUuid(uuid: string): Observable<Customer> {
    return <Observable<Customer>>this.getEntityByUuid(uuid);
  }

  getList(): Observable<Customer[]> {
    return <Observable<Customer[]>>this.getEntitiesList().pipe(this.mapPipeTransformEntities());
  }

  getListByParams(queryParams: Query, options?: GetAllDataOptions): Observable<Customer[]> {
    return <Observable<Customer[]>>this.getEntitiesListByParams(queryParams, options).pipe(this.mapPipeTransformEntities());
  }

  getCountByParams(queryParams: Query): Observable<number> {
    return this.getEntitiesCountByParams(queryParams);
  }
}
