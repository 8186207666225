<ion-header>
  <ion-toolbar primary>
    <ion-buttons slot="start">
      <ion-menu-toggle>
        <button *ngIf="(isMobile && !cView) || !isMobile">
          <pos-icon ionName="menu-outline"></pos-icon>
        </button>
      </ion-menu-toggle>
      <button *ngIf="isMobile && cView" (click)="closeView()">
        <i class="fi flaticon-arrows"></i>
      </button>
      <sync-icon></sync-icon>
    </ion-buttons>
    <ion-title>{{(cView && isMobile ? "global_" + cView : "global_settings_title") | translate}}</ion-title>
    <ion-buttons slot="primary">
      <button class="btn-current-user">
        <current-user [showStore]="false"></current-user>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="sidebar-menu" ion-fixed [class.active]="cView" slot="fixed">
    <ion-content>
      <ion-list>
        <div (click)="setView('general')" class="sm-item" [class.active]="cView === 'general'">
          <div class="sm-icon icon-general"></div>
          {{"global_general" | translate}} <i class="fi flaticon-arrows"></i>
        </div>
        <div (click)="setView('maintenance')" class="sm-item" [class.active]="cView === 'maintenance'">
          <div class="sm-icon icon-maintenance"></div>
          {{"global_maintenance" | translate}} <i class="fi flaticon-arrows"></i>
        </div>
        <div class="menu-devider">{{"global_devices_title" | translate}}</div>
        <div (click)="setView('terminals')" class="sm-item" [class.active]="cView === 'terminals'" *ngIf="!isMyPosDevice">
          <div class="sm-icon icon-terminals"></div>
          {{"global_terminals" | translate}} <i class="fi flaticon-arrows"></i>
        </div>
        <div (click)="setView('printers')" class="sm-item" [class.active]="cView === 'printers'">
          <div class="sm-icon icon-printers"></div>
          {{"global_printers" | translate}} <i class="fi flaticon-arrows"></i>
        </div>
        <div (click)="setView('scanners')" class="sm-item" [class.active]="cView === 'scanners'">
          <div class="sm-icon icon-scanners"></div>
          {{"global_scanners" | translate}} <i class="fi flaticon-arrows"></i>
        </div>
        <div (click)="setView('displays')" class="sm-item" [class.active]="cView === 'displays'">
          <div class="sm-icon icon-displays"></div>
          {{"global_displays" | translate}} <i class="fi flaticon-arrows"></i>
        </div>
      </ion-list>
    </ion-content>
  </div>
  <div class="router-outlet t-all-f" [class.active]="cView">
    <ng-template #routerOutlet></ng-template>
  </div>
</ion-content>
