import { UniversalCustomerDisplayDevice } from './UniversalCustomerDisplayDevice';

export abstract class IDriver<Device extends UniversalCustomerDisplayDevice<ConnectionInfo>, ConnectionInfo> {
  abstract connect(): Promise<void>;

  abstract disconnect(): Promise<void>;

  abstract sendMessage(message: object): Promise<void>;

  abstract setOnMessageReceived(callback: (message: object) => void): void;

  abstract setOnClosed(callback: () => void): void;

  abstract setOnError(callback: (error: Error) => void): void;
}
