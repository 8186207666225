export class ErrorTranslationHelper {
  private messages: Map<string, string>;

  setupMessages(info: [key: string, value: string][]) {
    this.messages = new Map<string, string>(info);
  }

  getTranslationKey(key: string | undefined): string | undefined {
    if (!key) {
      return;
    }
    const keys = Array.from(this.messages.keys());
    const foundKey = keys.find((value) => key === value) || keys.find((value) => key.includes(value));
    return this.messages.get(foundKey);
  }
}
