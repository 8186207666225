import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'pos-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() set isLoading(value: boolean) {
    this.showLoader = value;
  }
  @HostBinding('class.is-loading') showLoader = false;
}
@NgModule({
  imports: [IonicModule],
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
})
export class LoaderComponentModule {}
