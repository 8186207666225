import { Entity } from './entity.class';
/**
 * Created by maksymkunytsia on 9/1/16.
 */

export class VatRate extends Entity {
  uuid: string;
  value: number;
  displayValue: string;

  constructor(data) {
    super(data);
    this.value = data.value || 0;
    this.displayValue = (this.value * 1000) / 10 + '%';
  }
}
