import { Keyboard } from '@capacitor/keyboard';
import { StatusBarPlugin, StatusBar, Style as StatusBarStyle, StyleOptions, BackgroundColorOptions } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Plugins } from '@capacitor/core';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';

const { PushNotifications } = Plugins;

const requestCameraPermissions = () => {
  return Camera.requestPermissions({ permissions: ['camera'] }).then((status) => status.camera);
};

const requestGalleryPermissions = () => {
  return Camera.requestPermissions({ permissions: ['photos'] }).then((status) => status.photos);
};

export {
  Keyboard,
  StatusBar,
  StatusBarPlugin,
  StatusBarStyle,
  StyleOptions,
  BackgroundColorOptions,
  SplashScreen,
  PushNotifications,
  Camera,
  requestCameraPermissions,
  requestGalleryPermissions,
  CameraSource,
  CameraResultType,
};
