import { APPOINTMENT_DEFAULT_COLOR, APPOINTMENT_EVENT_TYPE, APPOINTMENT_STATUS } from '@pos-common/constants';
import { LOCALE } from '@pos-common/constants/locale.const';
import * as moment from 'moment';
import { Customer } from '../customer.class';
import { Employee } from '../employee.class';
import { Entity } from '../entity.class';
import { Invoice } from '../invoice.class';
import { Service } from '../service/service.class';
import { Store } from '../store.class';

export class Appointment extends Entity {
  public appointmentId: number;
  public publicToken: string;
  public colorInCalendar: string;
  public createdAt: string;
  public startedAt: string;
  public endedAt: string;
  public eventType: APPOINTMENT_EVENT_TYPE;
  public internalNote: string;
  public externalNote: string;
  public isPersist: boolean;
  public isPublic: boolean;
  public isShowInCalendar: boolean;
  public status: APPOINTMENT_STATUS;
  public createdByCustomer: Customer;
  public createdByEmployee: Employee;
  public customer: Customer;
  public invoice: Invoice;
  public services: Service[];
  public store: Store;
  public isCustomerVisit: boolean;
  public isNotifyCustomer: boolean;
  public isCancelled: boolean;

  constructor(data: any = {}) {
    super(data);
    this.appointmentId = data.appointmentId || 0;
    this.publicToken = data.publicToken || '';
    this.colorInCalendar = data.colorInCalendar || APPOINTMENT_DEFAULT_COLOR;
    const currentDate = moment().format(LOCALE.DateFormat.YYYY_MM_DD_HH_mm_DASH);
    this.createdAt = data.createdAt || currentDate;
    this.startedAt = data.startedAt || currentDate;
    this.endedAt = data.endedAt || currentDate;
    this.eventType = APPOINTMENT_EVENT_TYPE[data.eventType] || APPOINTMENT_EVENT_TYPE.APPOINTMENT;
    this.internalNote = data.internalNote || '';
    this.externalNote = data.externalNote || '';
    this.isPersist = data.isPersist || false;
    this.isPublic = data.isPublic || false;
    this.isShowInCalendar = data.isShowInCalendar || false;
    this.status = APPOINTMENT_STATUS[data.status] || APPOINTMENT_STATUS.DRAFT;
    const services = data.services || [];
    this.services = services.map((service) => new Service(service));
    this.customer = data.customer ? new Customer(data.customer) : null;
    this.createdByCustomer = data.createdByCustomer ? new Customer(data.createdByCustomer) : null;
    this.createdByEmployee = data.createdByEmployee ? new Employee(data.createdByEmployee) : null;
    this.store = data.store ? new Store(data.store) : null;
    this.invoice = data.invoice ? new Invoice(data.invoice) : null;
    this.isCustomerVisit = typeof data.isCustomerVisit === 'boolean' ? !!data.isCustomerVisit : true;
    this.isNotifyCustomer = data.isNotifyCustomer || false;
    this.isCancelled = this.status === APPOINTMENT_STATUS.CANCELED_BY_CUSTOMER;
  }

  get isDisabledEdit() {
    return this.status === APPOINTMENT_STATUS.CANCELED_BY_CUSTOMER || !!this.invoice;
  }

  get isCancel() {
    return this.isFuture && this.status !== APPOINTMENT_STATUS.CANCELED_BY_CUSTOMER;
  }

  get isFuture() {
    return new Date(this.startedAt) > new Date();
  }
}
