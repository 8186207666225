<div [ngSwitch]="icon">
  <svg
    *ngSwitchCase="paymentProcessingActions.connecting"
    width="140"
    height="180"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140 173"
  >
    <style>
      .st0 {
        fill: none;
        stroke: #8996be;
        stroke-width: 7;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        animation: fade 1.1s infinite;
        opacity: 0;
      }

      .semi1 {
        animation-delay: 600ms;
      }

      .semi2 {
        animation-delay: 400ms;
      }

      .semi3 {
        animation-delay: 200ms;
      }

      .semi4 {
        animation-delay: 0ms;
      }

      @keyframes fade {
        0% {
          opacity: 1;
          animation-timing-function: ease-out;
        }
        100% {
          opactiy: 0;
          animation-timing-function: ease-in;
        }
      }
    </style>
    <g class="connecting">
      <path class="st0 semi1" d="M88.7,158.1L88.7,158.1c17.3-16.9,28.1-40.4,28.3-66.3c0.2-26-10.2-49.6-27.1-66.7" />
      <path class="st0 semi2" d="M74.5,145.1C74.5,145,74.5,145,74.5,145.1c14-13.7,22.8-32.7,22.9-53.7c0.2-21.1-8.2-40.2-22-54.1" />
      <path class="st0 semi3" d="M60.2,134.4C60.3,134.3,60.3,134.3,60.2,134.4c10.8-10.7,17.4-25.5,17.6-41.9C78,76,71.5,61.1,61,50.2" />
      <path class="st0 semi4" d="M46,119.8C46,119.8,46,119.8,46,119.8c7.5-7.4,12.1-17.7,12.2-29c0.1-11.3-4.4-21.6-11.7-29.1" />
    </g>
  </svg>
  <svg
    *ngSwitchCase="paymentProcessingActions.processing"
    width="215"
    height="180"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 215 150"
  >
    <style>
      .st1 {
        fill: none;
        stroke: #8996be;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }

      .st3 {
        fill: #c2cbe0;
      }

      .st5 {
        fill: #ffffff;
      }

      .st6 {
        fill: none;
        stroke: #ffffff;
        stroke-width: 2;
        stroke-miterlimit: 10;
      }

      .st7 {
        fill: #8996be;
      }

      .st8 {
        fill: none;
        stroke: #8996be;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }

      .clock {
        transform-origin: 152px 10px;
        transform: scale(0);
        animation-name: clock;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      }

      @keyframes clock {
        0% {
          transform: scale(0);
        }
        50% {
          transform: scale(1.3);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1);
        }
      }

      .big-arrow {
        transform-origin: 154px 9px;
        transform: rotate(0deg);
        animation-name: big-arrow;
        animation-delay: 0.4;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
      }

      @keyframes big-arrow {
        0% {
          transform: rotate(0deg);
          animation-timing-function: linear;
        }
        99.9% {
          transform: rotate(360deg);
          animation-timing-function: linear;
        }
        100% {
          transform: rotate(0deg);
          animation-timing-function: linear;
        }
      }

      .small-arrow {
        transform-origin: 154px 9px;
        transform: rotate(0deg);
        animation-name: big-arrow;
        animation-delay: 0.4;
        animation-duration: 22s;
        animation-iteration-count: infinite;
      }
    </style>
    <g class="card" style="transform: translate(20px, 50px)">
      <g class="back">
        <rect width="152" height="96" rx="10" ry="10" style="fill: #ffffff; stroke-width: 5; stroke: #8598c2" />
        <rect y="20" width="152" height="10" style="fill: #8598c2"></rect>
        <path
          class="st1"
          d="M16,80.3c0-0.2,1.6-10.7,14.5-13.2c10-1.9-0.3,16.8-3.3,17.8c-3,1-12.2-6,8.7-9c2.2-0.3,1.9,1.7,2.1,2.2
              c0.4,1.5,1.8,2,5.1,0.4c3.2-1.5,2.3-0.2,3.8,1.2c1.4,1.3,3.9-0.6,5.3-1c1.8-0.5,3.2-1.5,5.7,0c2.5,1.5,3.4,1.3,5.3,0.7"
        />
      </g>
      <g class="clock">
        <circle r="38" cx="152" cy="7" fill="#ffffff"></circle>
        <circle r="30" cx="152" cy="7" fill="none" stroke="#8996be" stroke-width="5"></circle>
        <rect class="big-arrow" x="151" y="-10" width="5" height="21" rx="3" ry="3" fill="#8996be"></rect>
        <rect class="small-arrow" x="140" y="6" width="16" height="5" rx="3" ry="3" fill="#8996be"></rect>
      </g>
    </g>
  </svg>
  <svg
    *ngSwitchCase="paymentProcessingActions.succeed"
    width="142"
    height="180"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 142 135"
    enable-background="new 0 0 142 135"
  >
    <style>
      .st0 {
        fill: none;
        stroke: #8996be;
        stroke-width: 5;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }

      .circle {
        stroke-dasharray: 343.6174011230469;
        stroke-dashoffset: 343.6174011230469;
        animation-name: circle;
        animation-duration: 0.9s;
        animation-fill-mode: forwards;
      }

      .check {
        stroke-dasharray: 135.8316650390625;
        stroke-dashoffset: 135.8316650390625;
        animation-name: check;
        animation-duration: 0.4s;
        animation-delay: 0.4s;
        animation-fill-mode: forwards;
      }

      @keyframes circle {
        0% {
          stroke-dashoffset: 343.6174011230469;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }

      @keyframes check {
        0% {
          stroke-dashoffset: 135.8316650390625;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }
    </style>
    <path
      class="st0 circle"
      d="M110.1 26.8C87.9 2.9 50.6 1.6 26.8 23.9s-25.1 59.6-2.9 83.4 59.6 25.1 83.4 2.9c8.4-7.8 14-17.6 16.8-27.9 2-7.6 2.5-15.5 1.5-23.2-.4-3-1.1-6.1-1.9-9"
    />
    <path class="st0 check" d="M42 63l26 28 67-71" />
  </svg>

  <svg *ngSwitchCase="paymentProcessingActions.retry" width="200" height="180">
    <style>
      .cross1 {
        transform-origin: 100px 100px;
        transform: rotate(45deg);
      }

      .cross2 {
        transform-origin: 100px 100px;
        transform: rotate(-45deg);
      }

      .retry-circle {
        transform-origin: 100px 100px;
        transform: scale(0);
        animation-name: retry-circle, retry-circle-scale;
        animation-duration: 0.5s, 4s;
        animation-fill-mode: forwards, forwards;
        animation-iteration-count: 1, infinite;
        animation-delay: 0, 5s;
      }

      .cross {
        transform-origin: 100px 100px;
        animation-name: cross;
        animation-duration: 4s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
      }

      @keyframes cross {
        0% {
          transform: rotate(0);
        }
        2% {
          transform: rotate(10deg);
        }
        4% {
          transform: rotate(-10deg);
        }
        6% {
          transform: rotate(10deg);
        }
        8% {
          transform: rotate(-10deg);
        }
        10% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(0);
        }
      }

      @keyframes retry-circle {
        0% {
          transform: scale(0);
        }
        50% {
          transform: scale(1.2);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1);
        }
      }

      @keyframes retry-circle-scale {
        0% {
          transform: scale(1);
        }
        10% {
          transform: scale(1.2);
        }
        20% {
          transform: scale(0.9);
        }
        30% {
          transform: scale(1);
        }
        100% {
          transform: scale(1);
        }
      }
    </style>
    <g class="retry-circle">
      <circle r="59" cx="100" cy="100" fill="none" stroke="#DC001B" stroke-width="5" />
      <g class="cross">
        <rect class="cross1" x="98" y="68" fill="#DC001B" width="5" height="65" rx="3" ry="3" />
        <rect class="cross2" x="98" y="68" fill="#DC001B" width="5" height="65" rx="3" ry="3" />
      </g>
    </g>
  </svg>

  <svg *ngSwitchCase="paymentProcessingActions.warning" width="200" height="180">
    <style>
      .cross1 {
        transform-origin: 100px 100px;
        transform: rotate(0);
      }

      .cross2 {
        transform-origin: 100px 100px;
        transform: rotate(0);
      }

      .retry-circle {
        transform-origin: 100px 100px;
        transform: scale(0);
        animation-name: retry-circle, retry-circle-scale;
        animation-duration: 0.5s, 4s;
        animation-fill-mode: forwards, forwards;
        animation-iteration-count: 1, infinite;
        animation-delay: 0, 5s;
      }

      .cross {
        transform-origin: 100px 100px;
        animation-name: cross;
        animation-duration: 4s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
      }

      @keyframes cross {
        0% {
          transform: rotate(0);
        }
        2% {
          transform: rotate(10deg);
        }
        4% {
          transform: rotate(-10deg);
        }
        6% {
          transform: rotate(10deg);
        }
        8% {
          transform: rotate(-10deg);
        }
        10% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(0);
        }
      }

      @keyframes retry-circle {
        0% {
          transform: scale(0);
        }
        50% {
          transform: scale(1.2);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1);
        }
      }

      @keyframes retry-circle-scale {
        0% {
          transform: scale(1);
        }
        10% {
          transform: scale(1.2);
        }
        20% {
          transform: scale(0.9);
        }
        30% {
          transform: scale(1);
        }
        100% {
          transform: scale(1);
        }
      }
    </style>
    <g class="retry-circle">
      <circle r="59" cx="100" cy="100" fill="none" stroke="#ffca22" stroke-width="5" />
      <g class="cross">
        <rect class="cross1" x="92" y="58" fill="#ffca22" width="15" height="60" rx="3" ry="3" />
        <rect class="cross2" x="92" y="128" fill="#ffca22" width="15" height="15" rx="3" ry="3" />
      </g>
    </g>
  </svg>

  <svg
    *ngSwitchCase="paymentProcessingActions.provideCard"
    width="200"
    xmlns="http://www.w3.org/2000/svg"
    height="140"
    viewBox="0 0 200 200"
  >
    <style>
      .st1 {
        fill: none;
        stroke: #8996be;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }

      .st3 {
        fill: #c2cbe0;
      }

      .st5 {
        fill: #ffffff;
      }

      .st6 {
        fill: none;
        stroke: #ffffff;
        stroke-width: 2;
        stroke-miterlimit: 10;
      }

      .st7 {
        fill: #8996be;
      }

      .st8 {
        fill: none;
        stroke: #8996be;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }

      .back {
        transform-origin: 76px 48px;
        transform: perspective(2000px) rotateY(0deg);
        animation-name: back;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-duration: 2.5s;
      }

      .front {
        transform-origin: 76px 48px;
        transform: perspective(2000px) rotateY(94deg);
        opacity: 0;
        animation-name: front;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-duration: 2.5s;
      }

      @keyframes back {
        0% {
          transform: perspective(2000px) rotateY(0deg);
          opacity: 1;
          animation-timing-function: ease-in;
        }
        24.99% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 1;
          animation-timing-function: ease-in;
        }
        25% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 0;
          animation-timing-function: ease-in;
        }
        74.8% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 0;
          animation-timing-function: ease-in;
        }
        74.99% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 1;
          animation-timing-function: ease-in;
        }
        75% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 1;
          animation-timing-function: ease-out;
        }
        100% {
          transform: perspective(2000px) rotateY(0deg);
          opacity: 1;
          animation-timing-function: ease-out;
        }
      }

      @keyframes front {
        0% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 0;
          animation-timing-function: ease-out;
        }
        24.99% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 0;
          animation-timing-function: ease-out;
        }
        25% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 1;
          animation-timing-function: ease-out;
        }
        50% {
          transform: perspective(2000px) rotateY(180deg);
          opacity: 1;
          animation-timing-function: ease-in;
        }
        74.8% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 1;
          animation-timing-function: ease-out;
        }
        74.99% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 0;
          animation-timing-function: ease-in;
        }
        75% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 0;
          animation-timing-function: ease-out;
        }
        100% {
          transform: perspective(2000px) rotateY(94deg);
          opacity: 0;
          animation-timing-function: ease-out;
        }
      }
    </style>
    <g class="card" style="transform: translate(20px, 20px)">
      <g class="front">
        <rect width="152" height="96" rx="10" ry="10" style="fill: #ffffff; stroke-width: 5; stroke: #8598c2" />
        <g>
          <circle class="st3" cx="13.5" cy="81.5" r="4" />
          <circle class="st3" cx="27.4" cy="82" r="4" />
          <circle class="st3" cx="41.3" cy="82" r="4" />
          <circle class="st3" cx="55.2" cy="82" r="4" />
          <circle class="st3" cx="69.1" cy="82" r="4" />
          <circle class="st3" cx="82.9" cy="82" r="4" />
          <circle class="st3" cx="96.8" cy="82" r="4" />
          <circle class="st3" cx="110.7" cy="82" r="4" />
          <circle class="st3" cx="124.6" cy="82" r="4" />
          <circle class="st3" cx="138.5" cy="82" r="4" />
        </g>
        <g>
          <path class="st7" d="M136.8,53h-13c-2.8,0-5-2.2-5-5v-7c0-2.8,2.2-5,5-5h13c2.8,0,5,2.2,5,5v7C141.8,50.8,139.6,53,136.8,53z" />
          <path class="st6" d="M131.8,53h-3c-1.7,0-3-1.3-3-3V39c0-1.7,1.3-3,3-3h3c1.6,0,3,1.3,3,3v11C134.8,51.7,133.5,53,131.8,53z" />
          <rect x="135" y="40" class="st5" width="9" height="2" />
          <rect x="135" y="47" class="st5" width="9" height="2" />
          <rect x="117" y="40" class="st5" width="9" height="2" />
          <rect x="117" y="47" class="st5" width="9" height="2" />
        </g>
        <g>
          <path class="st8" d="M19.3,16c-2,2.3-3.2,5.5-3.3,9c0,3.5,1.2,6.7,3.1,9" />
          <path class="st8" d="M23.4,17.6c-2,1.9-3.2,4.5-3.3,7.3c0,2.9,1.2,5.5,3.1,7.4" />
          <path class="st8" d="M26.6,19.3c-1.5,1.5-2.4,3.5-2.5,5.7c0,2.2,0.9,4.3,2.4,5.7" />
          <path class="st8" d="M29.9,21.7C29.9,21.7,29.9,21.7,29.9,21.7c-1,0.9-1.6,2.2-1.6,3.7c0,1.4,0.6,2.7,1.6,3.7" />
        </g>
      </g>
      <g class="back">
        <rect width="152" height="96" rx="10" ry="10" style="fill: #ffffff; stroke-width: 5; stroke: #8598c2" />
        <rect y="20" width="152" height="10" style="fill: #8598c2"></rect>
        <path
          class="st1"
          d="M16,80.3c0-0.2,1.6-10.7,14.5-13.2c10-1.9-0.3,16.8-3.3,17.8c-3,1-12.2-6,8.7-9c2.2-0.3,1.9,1.7,2.1,2.2
              c0.4,1.5,1.8,2,5.1,0.4c3.2-1.5,2.3-0.2,3.8,1.2c1.4,1.3,3.9-0.6,5.3-1c1.8-0.5,3.2-1.5,5.7,0c2.5,1.5,3.4,1.3,5.3,0.7"
        />
      </g>
    </g>
  </svg>

  <svg
    *ngSwitchCase="paymentProcessingActions.providePin"
    width="230"
    height="180"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 290 220"
    enable-background="new 0 0 290 180"
  >
    <style>
      .st0,
      .st1 {
        fill: none;
        stroke: #8497c0;
        stroke-miterlimit: 10;
      }

      .st0 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-width: 5;
      }

      .st1 {
        stroke-width: 8;
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cursor {
        opacity: 0;
        animation-duration: 2.8s;
        animation-fill-mode: forwards;
        animation-timing-function: none;
      }

      .cursor1 {
        animation-name: cursor1;
        opacity: 1;
      }

      .cursor2 {
        animation-name: cursor2;
        animation-duration: 2.2s;
      }

      .cursor3 {
        animation-name: cursor3;
        animation-duration: 2.9s;
      }

      .cursor4 {
        animation-name: cursor3;
        animation-duration: 4s;
      }

      @keyframes cursor1 {
        0% {
          opacity: 1;
        }
        24.9% {
          opacity: 1;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        75% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }

      @keyframes cursor2 {
        0% {
          opacity: 0;
        }
        25% {
          opacity: 0;
        }
        49.9% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        74.9% {
          opacity: 1;
        }
        75% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }

      @keyframes cursor3 {
        0% {
          opacity: 0;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        74.9% {
          opacity: 0;
        }
        75% {
          opacity: 1;
        }
        99.9% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      @keyframes cursor4 {
        0% {
          opacity: 0;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        74.9% {
          opacity: 0;
        }
        75% {
          opacity: 1;
        }
        99.9% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      .lock {
        transform-origin: 145px 48px;
        transform: scale(1);
        animation-name: lock;
        animation-duration: 5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-delay: 4.2s;
      }

      @keyframes lock {
        0% {
          transform: scale(1);
        }
        10% {
          transform: scale(1.3);
        }
        20% {
          transform: scale(0.9);
        }
        30% {
          transform: scale(1);
        }
        100% {
          transform: scale(1);
        }
      }

      .star1,
      .star2,
      .star3,
      .star4 {
        transform: scale(0);
        animation-name: star1;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      }

      .star1 {
        transform-origin: 55px 140px;
        animation-delay: 1s;
      }

      .star2 {
        transform-origin: 115px 140px;
        animation-delay: 2s;
      }

      .star3 {
        transform-origin: 175px 140px;
        animation-delay: 3s;
      }

      .star4 {
        transform-origin: 235px 140px;
        animation-delay: 4s;
      }

      @keyframes star1 {
        0% {
          transform: scale(0);
        }
        50% {
          transform: scale(1.3);
        }
        75% {
          transform: scale(1);
        }
        100% {
          transform: scale(1);
        }
      }
    </style>
    <g style="transform: translate(0, 20px)">
      <g class="lock">
        <path
          class="st0"
          d="M164.5 71h-39c-4.4 0-8-3.6-8-8V33c0-4.4 3.6-8 8-8h39c4.4 0 8 3.6 8 8v30c0 4.4-3.6 8-8 8zm-36.7-47.5c0-9.4 7.6-17 17-17s17 7.6 17 17"
        />
        <rect x="143" y="40" width="5" height="18" rx="3" ry="3" fill="#8996be"></rect>
        <circle cx="145.4" cy="44" r="5" fill="#8996be" />
      </g>
      <path class="st0" d="M250 173.5H40c-18.2 0-33-14.8-33-33s14.8-33 33-33h210c18.2 0 33 14.8 33 33s-14.8 33-33 33z" />
      <g class="stars-group">
        <path class="st1 star1" d="M45.5 124.5l19 31m0-31l-19 31M36.8 140h36.4" />
        <path class="st1 star2" d="M105.5 124.5l19 31m0-31l-19 31M96.8 140h36.4" />
        <path class="st1 star3" d="M165.5 124.5l19 31m0-31l-19 31m-8.7-15.5h36.4" />
        <path class="st1 star4" d="M225.5 124.5l19 31m0-31l-19 31m-8.7-15.5h36.4" />
      </g>
      <rect class="cursor1 cursor" x="31" y="119" width="5" height="42" rx="3" ry="3" fill="#8996be" />
      <rect class="cursor2 cursor" x="91" y="119" width="5" height="42" rx="3" ry="3" fill="#8996be" />
      <rect class="cursor3 cursor" x="153" y="119" width="5" height="42" rx="3" ry="3" fill="#8996be" />
      <rect class="cursor4 cursor" x="213" y="119" width="5" height="42" rx="3" ry="3" fill="#8996be" />
    </g>
  </svg>

  <svg
    *ngSwitchCase="paymentProcessingActions.provideSignature"
    width="230"
    height="180"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 255 126"
    enable-background="new 0 0 255 126"
  >
    <style>
      .signature {
        fill: none;
        stroke: #8996be;
        stroke-width: 5;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 481.7015075683594;
        stroke-dashoffset: 481.7015075683594;
        animation-name: signature;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }

      @keyframes signature {
        0% {
          stroke-dashoffset: 481.7015075683594;
          opacity: 1;
        }
        75% {
          stroke-dashoffset: 0;
          opacity: 1;
        }
        100% {
          stroke-dashoffset: 0;
          opacity: 0;
        }
      }
    </style>
    <path
      class="signature"
      d="M9 85.9c0-.9 7.9-53.1 72.1-65.2 50-9.4-1.4 82.9-16.2 87.8-14.8 5-60.6-29.6 43.2-44.6 10.8-1.6 9.5 8.3 10.3 11 2.2 7.3 9.1 9.7 25.3 2.2 16.1-7.6 11.6-.7 18.7 5.9 7.1 6.6 19.5-2.8 26.1-4.8 9-2.7 16.1-7.3 28.3 0 12.3 7.3 16.7 6.3 26.1 3.5"
    />
  </svg>
</div>
