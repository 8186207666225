import { Injectable } from '@angular/core';

@Injectable()
export class FileReaderUtils {
  static getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any).__zone_symbol__originalInstance;
    return zoneOriginalInstance || fileReader;
  }
}
