/**
 * Created by y.belinsky on 10/17/17.
 */
import { Document } from './document.class';
import { Entity } from './entity.class';

/**
 * @constant
 * @type {Object}
 * Sizes definition:
 * * SMALL - 50x50
 * * MEDIUM - 160x160
 * * NORMAL - 300x300
 * * BIG - 600x600
 */
export const IMAGE_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  NORMAL: 'normal',
  BIG: 'big',
};

/**
 * Interface for classes that represent an image thumb.
 *
 * @interface
 */
export interface ImageThumbInterface {
  size: string;
  url: string;
}

/**
 * Interface for classes that represent an image item.
 *
 * @interface
 */
export interface ImageInterface {
  uuid: string;
  thumbs: Array<ImageThumbInterface>;
}

/**
 * Creates a new Image.
 * @class
 */
export class Image extends Document implements ImageInterface {
  uuid: string;
  thumbs: Array<ImageThumbInterface> = [];
  /**
   * @deprecated since Play 2
   */
  src: string = null;

  /**
   * @constructs Image
   * @param imageData {ImageInterface}
   */
  constructor(imageData) {
    super(imageData);
    this.thumbs = imageData.thumbs || [];
  }

  /**
   * @name getImageUrlBySize
   * @param imageSize {string} - available image sizes described in IMAGE_SIZES constant
   * @returns {String} - Image src url
   * */
  public getImageUrlBySize(imageSize: (typeof IMAGE_SIZES)[keyof typeof IMAGE_SIZES]): string {
    if (this.thumbs.length) {
      return this.getThumbUrl(imageSize);
    }
    return this.getSrc();
  }

  /**
   * @name getThumbUrl
   * @param imageSize {string} - available image sizes described in IMAGE_SIZES constant
   * @returns {ImageThumbInterface} - Image thumb
   * */
  private getThumbUrl(imageSize): string {
    const currentThumb = this.thumbs.find((thumb) => thumb.size === imageSize);
    return currentThumb?.url || null;
  }
}

/**
 * Interface for classes that represent an Image.
 *
 * @interface
 */
export interface ProductImageInterface {
  altText: string;
  position: number;
  uuid: string;
  image: ImageInterface;
}

/**
 * Creates a new ProductImage.
 * @class
 */
export class ProductImage extends Entity implements ProductImageInterface {
  altText: string;
  position: number;
  uuid: string;
  image: Image;

  constructor(productImageData: ProductImageInterface) {
    super(productImageData);
    this.altText = productImageData.altText || null;
    this.position = productImageData.position || 0;
    this.image = new Image(productImageData.image);
  }
}

/**
 * Interface for classes that represent an ProductImage.
 *
 * @interface
 */
export interface ProductVariantImageInterface {
  altText: string;
  position: number;
  uuid: string;
  document: ImageInterface;
}

/**
 * Creates a new ProductVariantImage.
 * @class
 */
export class ProductVariantImage extends Entity implements ProductVariantImageInterface {
  altText: string;
  position: number;
  uuid: string;
  document: Image;

  constructor(productImageData: ProductVariantImageInterface) {
    super(productImageData);
    this.altText = productImageData.altText || null;
    this.position = productImageData.position || 0;
    this.document = new Image(productImageData.document);
  }
}
