import { ReceiptFont } from '../../enum/printer-receipt-font.enum';
import { ReceiptKitchenPrinterFontSize } from '../../enum/receipt-kitchen-printer-font-size';
import { ReceiptWidthConfigurationInterface } from '../../interfaces/receipt-printer-configuration.interface';
import { ReceiptBuilder, IReceiptBuilder, ReceiptTextSize } from '../receipt-builder.class';

export class KitchenReceiptBuilder extends ReceiptBuilder implements IReceiptBuilder {
  private kitchenBigTextRowWidth: number;
  private kitchenSmallTextRowWidth: number;

  constructor(options: ReceiptWidthConfigurationInterface) {
    super(options);
    this.kitchenBigTextRowWidth = options.kitchenBigTextRowWidth;
    this.kitchenSmallTextRowWidth = options.kitchenSmallTextRowWidth;
    this.lineWidth = options.kitchenBigTextRowWidth;
  }

  setKitchenPrinterFontSize(fontSize: ReceiptKitchenPrinterFontSize, isMyPos: boolean = false) {
    if (fontSize === ReceiptKitchenPrinterFontSize.SMALL || isMyPos) {
      this.addTextSize(ReceiptTextSize.textSizeOneHeightOne);
      this.addFont(ReceiptFont.basic);
      this.lineWidth = this.kitchenSmallTextRowWidth;
      return;
    }
    this.addTextSize(ReceiptTextSize.textSizeTwoHeightTwo);
    this.addFont(ReceiptFont.condensed);
    this.lineWidth = this.kitchenBigTextRowWidth;
  }
}
