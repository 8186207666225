export const AdyenPaymentErrors = {
  userCancel: '108 Shopper cancelled tx',
  provideTerminalInfo: 'settings_provide_terminal_info',
  currencyNotSupported: 'is not a supported currency',
  refundNotEnabled: 'Value: Refund, Reason: Not Enabled',
  notEnoughBalance: 'Refusal, Reason: NotEnoughBalanceForAccount',
};

export const AdyenApiErrors = {
  unauthorized: 'Unauthorized',
};
