import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from './logger/log.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { KeyboardService } from './keyboard/keyboard.service';
import { AlertService } from '@pos-common/services/system/alert.service';
import { ToastService } from './toast.service';
import { BarcodeScannerTypes } from '@pos-common/constants/barcode-scanner-types.enum';
import { MyPosFacade } from '@pos-modules/my-pos/my-pos.facade';
import { MyPosService } from './devices/my-pos/my-pos.service';
import { CameraScannerAdapterService } from './camera-scanner-adapter/camera-scanner-adapter.service';
import { PlatformService } from './platform/platform.service';
import { ISunmiService } from '@pos-common/services/system/devices/sunmi/sunmi.service';
import { SunmiScannerPlugin, SunmiScannerType } from '@paymash/capacitor-sunmi-plugin';
import { MyPosScannerType } from '@paymash/capacitor-mypos-plugin';
import { IBarcodeScannerPluginSetting } from '@pos-common/services/system/camera-scanner-adapter/wrappers';

@Injectable()
export class CameraScannerService {
  private _isBarcodeScannerOpened: boolean = false;
  private _barcodeScannerOpenedChangeEvent: Subject<boolean> = new BehaviorSubject(false);
  private _newBarcodeEvent: Subject<string> = new Subject();
  private element: Element = null;

  constructor(
    private translateService: TranslateService,
    private toastService: ToastService,
    private keyboardService: KeyboardService,
    private alertService: AlertService,
    private myPosFacade: MyPosFacade,
    private myPosService: MyPosService,
    private cameraScannerAdapterService: CameraScannerAdapterService,
    private platformService: PlatformService,
    private logService: LogService,
    private sunmiService: ISunmiService
  ) {}

  public get newBarcodeEvent(): Observable<string> {
    return this._newBarcodeEvent.asObservable();
  }

  public makeTrim = (str) => str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');

  public setBarcodeScannerType(barcodeScannerType: BarcodeScannerTypes) {
    if (this.myPosService.isMyPosDevice) {
      barcodeScannerType = BarcodeScannerTypes.Paymash;
    }
    this.cameraScannerAdapterService.setBarcodeScanner(barcodeScannerType);
  }

  public openBarcodeScanner(element: Element): void {
    this.element = element;

    if (this.platformService.isWeb && !this._isBarcodeScannerOpened) {
      this.setBarcodeScannerOpenedState(true);
      this.toggleElementClasses(true);
      return;
    }

    if (this._isBarcodeScannerOpened) {
      this.closeBarcodeScanner();
      return;
    }

    this.checkAndRequestPermission()
      .then((isAvailable) => {
        if (!isAvailable) {
          this.setBarcodeScannerOpenedState(false);
          return this.showPermitionError();
        }
        this.open();
      })
      .catch((err) => {
        this.logService.error('CameraScannerService', 'openBarcodeScanner:isCameraAvailable', err);
        this.closeBarcodeScanner();
      });
  }

  private checkAndRequestPermission(): Promise<boolean> {
    return this.cameraScannerAdapterService.checkPermission();
  }

  private open() {
    if (this.myPosService.isMyPosScannerableDevice) {
      return this.openMyPosScanner();
    }

    if (this.sunmiService.isSunmiDevice && this.sunmiService.hasAppScanner) {
      return this.openSunmiScanner();
    }

    this.setBarcodeScannerOpenedState(true);
    if (this.keyboardService.isOpen) {
      this.keyboardService.getCloseEventOnce().subscribe(() => this.openScanner());
      return;
    }
    this.openScanner();
  }

  private openScanner() {
    if (!this.element) {
      return;
    }
    this.toggleElementClasses(true);
    const scannerSettings = this.getScannerSettings(this.element);
    this.cameraScannerAdapterService.startScan(
      scannerSettings,
      (data) => this.onCodeReceived(data),
      (error) => this.openScannerError(error)
    );
  }

  private openMyPosScanner() {
    this.myPosFacade.startScanner(MyPosScannerType.App).subscribe(
      (data) => this.onCodeReceived(data.value),
      (error) => this.openScannerError(error)
    );
  }

  private openSunmiScanner() {
    SunmiScannerPlugin.startScanner(SunmiScannerType.App).subscribe(
      (result) => this.onCodeReceived(result.value),
      (error) => this.openScannerError(error)
    );
  }

  private onCodeReceived(value: string) {
    this._newBarcodeEvent.next(this.makeTrim(value));
  }

  private openScannerError(error: any): void {
    this.logService.error('CameraScannerService', 'Error scanning barcode', error);
    this.showBarcodeScannerMsg(this.translateService.instant('common_barcode_access_to_camera_not_allowed'), 3000);
    this.setBarcodeScannerOpenedState(false);
  }

  private setBarcodeScannerOpenedState(isBarcodeScannerOpened: boolean): void {
    this._isBarcodeScannerOpened = isBarcodeScannerOpened;
    this._barcodeScannerOpenedChangeEvent.next(isBarcodeScannerOpened);
  }

  public getBarcodeScannerOpenedStateEvent(): Observable<boolean> {
    return this._barcodeScannerOpenedChangeEvent.asObservable();
  }

  public async showBarcodeScannerMsg(msgKey: string, duration: number, success?: boolean) {
    const toast = await this.toastService.create({
      message: this.translateService.instant(msgKey),
      duration,
      position: 'top',
      cssClass: success ? 'success-barcode-toast' : 'failure-barcode-toast',
    });
    await toast.present();
  }

  closeBarcodeScanner() {
    if (this._isBarcodeScannerOpened) {
      if (this.element) {
        this.toggleElementClasses(false);
        this.element = null;
      }
      if (window.cordova) {
        this.cameraScannerAdapterService.stopScan();
      }
      this.setBarcodeScannerOpenedState(false);
    }
  }

  private toggleElementClasses(isOpenBarcoseScanner: boolean) {
    if (!this.element) {
      return;
    }

    const barcodeScannerType = this.cameraScannerAdapterService.getBarcodeScannerType();
    if (isOpenBarcoseScanner) {
      if (barcodeScannerType === BarcodeScannerTypes.Community) {
        this.element.classList.add('hidden');
        return;
      }
      this.element.classList.add('no-events');
      return;
    }
    if (barcodeScannerType === BarcodeScannerTypes.Community) {
      this.element.classList.remove('hidden');
      return;
    }
    this.element.classList.remove('no-events');
    return;
  }

  private showPermitionError() {
    return this.alertService.showAlert({ header: 'common_error', subHeader: 'common_barcode_access_to_camera_not_allowed' });
  }

  private getScannerSettings(element: Element): IBarcodeScannerPluginSetting {
    return {
      x: element.getBoundingClientRect().left,
      y: element.getBoundingClientRect().top,
      width: element.clientWidth,
      height: element.clientHeight,
    };
  }
}
