<ion-item-group>
  <div class="label-caption">{{ 'scanner_foundScanner' | translate }}</div>
  <div class="no-items" *ngIf="listScanners.length === 0">{{ 'star_scanner_searching' | translate }}...</div>
  <div class="list-group">
    <ion-grid class="list-table">
      <ion-row *ngFor="let scanner of listScanners; trackByUuid">
        <ion-col size="8">
          <span class="status-circle" [class.offline]="!scanner.isScannerActive"></span>
          {{ scanner.printerViewName }}<br />
          <span class="subname">{{ scanner.deviceName }}</span>
        </ion-col>
        <ion-col size="4">
          <div class="is-btn connect-btn connect-or-disconenct-btn" *ngIf="!scanner.isScannerActive" (click)="connectToScanner(scanner)">
            {{ 'common_connect' | translate }}
          </div>
          <div
            class="is-btn disconnect-btn connect-or-disconenct-btn"
            *ngIf="scanner.isScannerActive"
            (click)="disconnectFromScanner(scanner)"
          >
            {{ 'common_disconnect' | translate }}
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-item-group>
<ion-item-group class="current-scanner">
  <div class="label-caption">{{ 'scanner_currentScanner' | translate }}</div>
  <div class="list-group">
    <ion-item *ngIf="!scannerNames.length" class="list-item">
      <span>{{ 'scanner_noCurrentScanner' | translate }}</span>
    </ion-item>
    <ng-container *ngIf="scannerNames.length">
      <ion-item *ngFor="let scannerName of scannerNames; trackByUuid" class="list-item">
        <span>{{ scannerName }}</span>
      </ion-item>
    </ng-container>
    <ion-item *ngIf="!socketScannerSearchInProgress && !scannerNames.length" class="list-item" (click)="connectScanner()">
      <span class="connect-btn">
        {{ 'common_connect' | translate }}
      </span>
    </ion-item>
    <ion-item *ngIf="socketScannerSearchInProgress" class="list-item">
      <span class="connecting-btn">
        {{ 'payment_processing_wizard_payment_processing_connecting' | translate }}
      </span>
    </ion-item>
    <ion-item *ngIf="!socketScannerSearchInProgress && scannerNames.length" class="list-item" (click)="disconnectScanner()">
      <span class="disconnect-btn">
        {{ 'common_disconnect' | translate }}
      </span>
    </ion-item>
  </div>
</ion-item-group>
<ion-item-group *ngIf="!isAndroid" class="scanner-app">
  <div class="label-caption">{{ 'scanner_app' | translate }}</div>
  <div class="list-group">
    <ion-item class="list-item" tappable (click)="openScannerAppSelect()">
      <span>{{ activeScannerName || 'scanner_connect' | translate }}</span>
      <span *ngIf="!activeScannerName" class="connect-btn" slot="end">{{ 'common_connect' | translate }}</span>
      <span *ngIf="activeScannerName" class="disconnect-btn" slot="end">{{ 'common_disconnect' | translate }}</span>
    </ion-item>
  </div>
</ion-item-group>
