import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  Output,
  NgModule,
} from '@angular/core';
import { INullifyModalOptionsStyles } from '@pos-common/interfaces';
import { NullifyModalService } from '@pos-common/services/system/nullify-modal.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { TimePickerComponent } from '../time-picker/time-picker.component';
import { CompanyLocalizedPipeModule } from '@pos-common/pipes';

@Component({
  selector: 'pos-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class TimeComponent {
  @Input() value: string;
  @Output() posSetValue = new EventEmitter<string>();

  constructor(private nullifyModalService: NullifyModalService, private subSinkService: SubSinkService, private cdr: ChangeDetectorRef) {}

  openPopup(event: Event) {
    const target = event.target as HTMLElement;
    if (this.nullifyModalService.isNullifyModalExist()) {
      return;
    }
    const targetRect = target.getBoundingClientRect();
    const height = 175;
    const padding = 10;
    const top = targetRect.top - height - padding;
    const styles: INullifyModalOptionsStyles = {
      top: `${top}px !important`,
      right: '0',
      left: 'initial !important',
      maxWidth: '100%',
      margin: '0 20px !important',
      padding: '25px !important',
      width: '182px !important',
      height: `${height}px !important`,
      zIndex: 30001,
    };
    const posSetValue = new EventEmitter<string>();
    this.subSinkService.sink = posSetValue.subscribe((value) => {
      this.value = value;
      this.posSetValue.emit(value);
      this.cdr.detectChanges();
    });
    const { value } = this;
    this.nullifyModalService.initModal({
      component: TimePickerComponent,
      options: { styles, className: 'pos-content-picker', arrAbove: false },
      inputs: [{ value, posSetValue }],
    });
  }
}
@NgModule({
  imports: [CompanyLocalizedPipeModule],
  declarations: [TimeComponent],
  exports: [TimeComponent],
})
export class TimeComponentModule {}
