import { PAYMASH_PROFILE } from '@profile';

export const SERVER_CONFIG: any = {};

Object.defineProperties(SERVER_CONFIG, {
  localStorageApi: {
    get: function () {
      const api = localStorage.getItem('debugAleternateApiInput');
      return !!api && api !== '' ? api : '';
    },
  },
  APP_ENV: { value: PAYMASH_PROFILE.APP_ENV, writable: true, enumerable: true },
  LOCAL_IMAGES_URL: { value: 'assets/images/', enumerable: true },
  SERVER_URL: {
    enumerable: true,
    get: function () {
      return this.localStorageApi || PAYMASH_PROFILE.SERVER_URL;
    },
  },
  API_URL: {
    enumerable: true,
    get: function () {
      return this.SERVER_URL + '/api/v1/';
    },
  },
  API_V2_URL: {
    enumerable: true,
    get: function () {
      return this.SERVER_URL + '/api/v2/';
    },
  },
  API_LEGACY_URL: {
    enumerable: true,
    get: function () {
      return this.SERVER_URL + '/api/legacy/';
    },
  },
  PUBLIC_DOCUMENTS_URL: {
    enumerable: true,
    get: function () {
      return this.SERVER_URL + '/public/documents/';
    },
  },
  PUBLIC_URL: {
    enumerable: true,
    get: function () {
      return this.SERVER_URL;
    },
  },
  ADYEN_MANAGEMENT_URL: { value: PAYMASH_PROFILE.adyen.managementUrl + '/v1/', enumerable: true },
  ADYEN_TERMINAL_API_URL: { value: PAYMASH_PROFILE.adyen.terminalUrl + '/', enumerable: true },
});
