import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FilterGroup } from '@pos-common/classes/calendar/filter-group.class';
import { FilterItem } from '@pos-common/classes/calendar/filter-item.class';

@Component({
  selector: 'pos-calendar-filter-group',
  templateUrl: './calendar-filter-group.component.html',
  styleUrls: ['./calendar-filter-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarFilterGroupComponent implements OnInit {
  @Input() filterGroup: FilterGroup;
  @Input() items: FilterItem[];
  @Output() posSelectedItem = new EventEmitter<void>();
  @HostBinding('class.collapsed') isCollapsed = false;

  ngOnInit() {
    this.isCollapsed = !this.filterGroup.isOpen;
  }

  collapse() {
    if (!this.filterGroup.canOpen) {
      return;
    }
    this.filterGroup.isOpen = !this.filterGroup.isOpen;
    this.isCollapsed = !this.filterGroup.isOpen;
  }

  selectItem() {
    this.posSelectedItem.emit();
  }
}
