import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { mdTransitionAnimation, ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { AppointmentNotification } from '@pos-common/classes/appointment/appointment-notification.class';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';
import { AppointmentApiService } from '@pos-common/services/api/appointment-api.service';
import { CalendarService } from '@pos-common/services/system/calendar/calendar.service';
import { LoadingService } from '@pos-common/services/system/loading.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { SecurityService } from '@pos-common/services/system/security.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';

@Component({
  selector: 'pos-calendar-notifications-modal',
  templateUrl: './calendar-notifications-modal.page.html',
  styleUrls: ['./calendar-notifications-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class CalendarNotificationsModalPage implements OnInit {
  public notificationList: AppointmentNotification[] = [];
  public isLoading = true;
  public disabledClearButton = true;

  constructor(
    private securityService: SecurityService,
    private modalController: ModalController,
    private calendarService: CalendarService,
    private appointmentApiService: AppointmentApiService,
    private subSinkService: SubSinkService,
    private navController: NavController,
    private cdr: ChangeDetectorRef,
    private setTimeoutUtil: SetTimeoutUtil,
    private loadingService: LoadingService,
    private logService: LogService
  ) {}

  ngOnInit() {
    const activeEmployee = this.securityService.getActiveEmployee();
    this.subSinkService.sink = this.appointmentApiService.getNotificationList(activeEmployee.uuid).subscribe((notifications) => {
      this.setNotifications(notifications);
      this.isLoading = false;
      this.updateStates();
    });
  }

  dismiss() {
    return this.modalController.dismiss();
  }

  openAppointment(appointmentUuid: string) {
    this.loadingService.showLoadingItem();
    const options: NavigationOptions = { state: { appointmentUuid }, animation: mdTransitionAnimation };
    this.dismiss()
      .then(() => this.navController.navigateForward(ROUTE_URLS.appointment, options))
      .catch((error) => this.logService.error('CalendarPage', 'createNewAppointment', error));
  }

  clearNotification(uuid: string) {
    this.removeNotifications([uuid]).subscribe(() => {
      const notifications = this.notificationList.filter((notification) => notification.uniqueIdentifier !== uuid);
      this.updateNotificatios(notifications);
    });
  }

  clearNotificationList() {
    const uuids = this.notificationList.map((notification) => notification.uniqueIdentifier);
    this.removeNotifications(uuids).subscribe(() => {
      this.updateNotificatios([]);
    });
  }

  private updateNotificatios(notifications: AppointmentNotification[]) {
    this.setNotifications(notifications);
    this.updateStates();
    this.closeModal();
  }

  private setNotifications(notifications: AppointmentNotification[]) {
    this.notificationList = notifications;
  }

  private removeNotifications(uuids: string[]) {
    const activeEmployee = this.securityService.getActiveEmployee();
    return this.appointmentApiService.removeNotifications(uuids, activeEmployee.uuid);
  }

  private updateStates() {
    const { length } = this.notificationList;
    this.disabledClearButton = this.isLoading || !!!length;
    this.cdr.detectChanges();
    this.calendarService.setNotificationCount(length);
  }

  private closeModal() {
    if (this.notificationList.length) {
      return;
    }
    this.setTimeoutUtil.addVisualEffect(200).then(() => this.dismiss());
  }
}
