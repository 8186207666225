import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ReceiptPrinter } from '../../classes/receipt-printer.class';
import { CommonPrinterService } from '../../services/common-printer.service';
import { PrinterServiceInterface } from '@pos-common/interfaces/printer-service.interface';
import { ISunmiService } from '@pos-common/services/system/devices/sunmi/sunmi.service';
import { MYPOS } from '@pos-common/constants/mypos.const';
import { ReceiptBuilder } from '../../classes/receipt-builder.class';
import { SunmiPrinterPlugin } from '@paymash/capacitor-sunmi-plugin';
import { SunmiPrinterWidth } from '@pos-common/services/system/devices/sunmi/sunmi.service.model';
import { ignorePromiseError } from '@pos-common/services/utils/promise.utils';

export abstract class ISunmiPrinterService extends CommonPrinterService implements PrinterServiceInterface {}

@Injectable()
export class SunmiPrinterService extends ISunmiPrinterService {
  constructor(injector: Injector, private readonly sunmiService: ISunmiService) {
    super(injector);
  }

  protected get discoverMethodName(): string {
    return '';
  }

  protected get pluginName(): string {
    return '';
  }

  protected get printMethodName(): string {
    return '';
  }

  protected get stopDiscoverMethodName(): string {
    return '';
  }

  protected logsSubscription() {
    return;
  }

  protected discover(): Observable<ReceiptPrinter[]> {
    this.getPrinter();
    return this.printerList;
  }

  protected stopDiscover() {
    return Promise.resolve();
  }

  public checkPermissionsOnAndroid(): Promise<boolean> {
    return Promise.resolve(true);
  }

  private getPrinter(): Observable<ReceiptPrinter[]> {
    const { isSunmiDevice, hasPrinter } = this.sunmiService;
    const { sunmiDeviceModel, printerWidth } = this.sunmiService;

    if (isSunmiDevice) {
      const deviceName = 'Sunmi';
      const deviceType = sunmiDeviceModel.toString();

      const printerType = (() => {
        switch (printerWidth) {
          case SunmiPrinterWidth.w40mm:
            return 'SUNMI_40mm';
          case SunmiPrinterWidth.w58mm:
            return 'SUNMI_58mm';
          case SunmiPrinterWidth.w80mm:
            return 'SUNMI_80mm';
        }
      })();

      if (hasPrinter) {
        const printer = new ReceiptPrinter({
          printerName: deviceName,
          deviceName,
          deviceType,
          ipAddress: MYPOS.IP_ADDRESS,
          isActive: true,
          macAddress: MYPOS.MAC_ADDRESS,
          printerNameForModelPick: printerType,
          target: MYPOS.TARGET,
        });

        this.handleDiscover([printer]);
        return;
      }
    }
    this.handleDiscover([]);
  }

  print(device: ReceiptPrinter, data: ReceiptBuilder) {
    const printData = data.receiptForSunmi;
    return Promise.resolve()
      .then(() => SunmiPrinterPlugin.bindService())
      .then(() => SunmiPrinterPlugin.printReceipt(printData))
      .then(() => SunmiPrinterPlugin.unBindService().catch(ignorePromiseError));
  }
}
