import { Injectable } from '@angular/core';
import { ReceiptPrinter } from '../classes/receipt-printer.class';
import { ReceiptPrintersService } from './receipt-printers.service';
import { ReceiptPrinterConnectionTypeEnum } from '../enum/receipt-printer-connection-type.enum';
import { ReceiptPrinterInterface } from '../interfaces/receipt-printer.interface';
import { PrinterType } from '../enum/printer-type.enum';

@Injectable()
export class ReceiptPrinterManualConnectionService {

  readonly startDefaultDeviceName: string = 'Star';

  constructor(
    private receiptPrintersService: ReceiptPrintersService
  ) {
  }


  makeConnection(printer: ReceiptPrinter): void {
    printer.macAddress = ReceiptPrinterManualConnectionService.formatMacAddress(printer.macAddress);
    printer.generateTarget();
    this.receiptPrintersService.addToSaved(printer);
  }

  /**
   * @throws Will throw an error if barcode string would be wrong.
   */
  parseConnectionBarcode(barcode: string): ReceiptPrinter {
    if (barcode.indexOf('star') !== -1) {
      return this.parseStarBarcode(barcode);
    } else {
      return this.parseEpsonBarcode(barcode);
    }
  }

  public static formatMacAddress(macAddress: string): string {
    return macAddress
      .trim()
      .replace(/:/g, '')
      .replace(/-/g, '')
      .match(/.{1,2}/g)
      .join(':');
  }

  /**
   * @param barcode {string} Star barcode connection string
   *"jp.star-m.mcp2://LAN?ip=192.168.1.77&mac=0011621BE204"
   *"jp.star-m.mcp3://BT?dev=mC-Print3-F0025&ios=mC-Print3&mac=0011621FA281"
   * **/
  private parseStarBarcode(barcode: string): ReceiptPrinter {
    const printer: ReceiptPrinterInterface = <ReceiptPrinterInterface>{};
    const connectionData = barcode.split('//')[1];
    const [connectionType, connectionParamsString] = connectionData.split('?');
    const connectionParamsPairs = connectionParamsString.split('&');
    const connectionParams: any = connectionParamsPairs.reduce((current, newVal) => {
      const [key, value] = newVal.split('=');
      current[key] = value;
      return current;
    }, {});
    printer.deviceType = PrinterType.Star;
    printer.macAddress = ReceiptPrinterManualConnectionService.formatMacAddress(connectionParams.mac);
    printer.target = `${connectionType}:${printer.macAddress}`;
    if (connectionParams.ip) {
      printer.ipAddress = connectionParams.ip;
      printer.target = `${ReceiptPrinterConnectionTypeEnum.TCP}:${printer.ipAddress}`;
    }
    if (connectionParams.dev) {
      printer.deviceName = connectionParams.dev;
    } else if (connectionParams.ios && ReceiptPrinter.isIos) {
      printer.deviceName = connectionParams.ios;
    } else {
      printer.deviceName = this.startDefaultDeviceName;
    }
    return new ReceiptPrinter(printer);
  }

  /**
   * @param barcode {string} Epson barcode connection string
   * "WF:64EB8CFE26CB\nDN:TM-m30"
   * "BT:000190C2BB7B\nDN:TM-P20"
   * **/
  private parseEpsonBarcode(barcode: string): ReceiptPrinter {
    const printer: ReceiptPrinterInterface = <ReceiptPrinterInterface>{};
    const [targetString, deviceNameData] = barcode.split('\n');
    const [connectionType, macAddress] = targetString.split(':');
    printer.deviceType = PrinterType.Epson;
    printer.macAddress = ReceiptPrinterManualConnectionService.formatMacAddress(macAddress);
    printer.target = `${ReceiptPrinter.getConnectionType(connectionType)}:${printer.macAddress}`;
    printer.deviceName = deviceNameData.slice(3);
    return new ReceiptPrinter(printer);
  }
}
