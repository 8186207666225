import { Injectable } from '@angular/core';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { Image, IMAGE_SIZES } from '../../classes/image.class';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { ImageLoaderService } from 'ionic-image-loader-v5';
import { LogService } from './logger/log.service';
import { DbDaoService } from '../db/db-dao.service';
import { UuidService } from '../utils/uuid.utils';
import { PlatformService } from './platform/platform.service';
import { ImageToLoad } from '@pos-common/classes/image-to-load.class';
import { PAGINATION } from '@pos-common/constants/pagination.const';
import { oneOf } from '@paymash/capacitor-database-plugin';
import { delay, filter, skip, switchMap } from 'rxjs/operators';

export interface ImageLoadingState {
  inProgress: boolean;
  total: number;
  loaded: number;
}

@Injectable()
export class ImageLoadingService {
  private imagesToBeLoaded: ImageToLoad[] = [];
  private isBackgroundProcess = false;

  private _loadingState: Subject<ImageLoadingState> = new BehaviorSubject({
    inProgress: false,
    total: 0,
    loaded: 0,
  });

  constructor(
    private imageLoaderService: ImageLoaderService,
    private dbDaoService: DbDaoService,
    private uuidService: UuidService,
    private platformService: PlatformService,
    private logService: LogService
  ) {}

  public findAndPrepareImages(item) {
    try {
      switch (item.type) {
        case UPDATES_TYPES.Product.type:
          this.addImageListToCacheList(item.properties.images, 'image');
          break;
        case UPDATES_TYPES.ProductVariant.type:
          this.addImageListToCacheList(item.properties.images, 'document');
          break;
        default:
          this.addImageToCacheList(item.properties.image, IMAGE_SIZES.NORMAL);
          break;
      }
    } catch (e) {
      this.logService.error('ImageLoadingService', 'findAndPrepareImages:', e);
    }
  }

  public get loadingState(): Observable<ImageLoadingState> {
    return this._loadingState.asObservable();
  }

  public savePreloadImages() {
    if (!this.platformService.isNativePlatform) {
      return Promise.resolve();
    }
    return this.dbDaoService.upsertDataToCollection(UPDATES_TYPES.ImagesToLoad.type, this.imagesToBeLoaded);
  }

  public async startLoading() {
    if (!this.platformService.isNativePlatform) {
      return;
    }
    const imagesCount = await this.dbDaoService.count(UPDATES_TYPES.ImagesToLoad.type);
    if (!imagesCount || this.isBackgroundProcess) {
      return;
    }
    this.isBackgroundProcess = true;
    const allImages = await this.dbDaoService.getAllData(UPDATES_TYPES.ImagesToLoad.type);
    this.startBackgroundLoading(allImages.data);
  }

  private startBackgroundLoading(images: ImageToLoad[]) {
    const chunkSize = PAGINATION.IMAGE_ITEMS_COUNT;
    const maxImagesToDelete = 100;
    let imagesToLoad = images.splice(0, chunkSize);
    let imagesToBeDeleted: string[] = [];
    const subscription = this.loadingState
      .pipe(
        skip(1),
        filter((state) => !state.inProgress),
        switchMap(() => {
          imagesToBeDeleted = [...imagesToBeDeleted, ...imagesToLoad.map((image) => image.uuid)];
          const shouldDeleteImages = imagesToBeDeleted.length && (imagesToBeDeleted.length >= maxImagesToDelete || !images.length);
          if (shouldDeleteImages) {
            const imagesToDelete = imagesToBeDeleted;
            imagesToBeDeleted = [];
            return from(this.dbDaoService.remove(UPDATES_TYPES.ImagesToLoad.type, { uuid: oneOf(...imagesToDelete) }));
          }
          return from(Promise.resolve());
        }),
        delay(1000)
      )
      .subscribe(() => {
        if (!images.length) {
          subscription?.unsubscribe();
          this.isBackgroundProcess = false;
          this.dbDaoService.deleteDatabase(UPDATES_TYPES.ImagesToLoad.type);
          return;
        }
        imagesToLoad = images.splice(0, chunkSize);
        return this.checkLoadingQueue(imagesToLoad, imagesToLoad.length);
      });
    this.checkLoadingQueue(imagesToLoad, imagesToLoad.length);
  }

  private addImageListToCacheList(images: any[], property: string) {
    if (!images || (images && !images.length)) return;
    for (const img of images) {
      const imageData = img[property];
      if (!imageData) continue;
      const imgObj = new Image(imageData);
      const imagesSize = this.platformService.isMobile ? IMAGE_SIZES.MEDIUM : IMAGE_SIZES.NORMAL;
      this.addImageToCacheList(imgObj, imagesSize);
      this.addImageToCacheList(imgObj, IMAGE_SIZES.SMALL);
    }
  }

  private addImageToCacheList(image: any, size: string): void {
    if (!image) return;
    const imgSrc = new Image(image).getImageUrlBySize(size);
    const imageToLoad = new ImageToLoad({ uuid: this.uuidService.generate(), src: imgSrc });
    this.imagesToBeLoaded.push(imageToLoad);
  }

  private checkLoadingQueue(images: ImageToLoad[], imagesTotal: number) {
    const imagesToLoad = [...images];
    if (imagesToLoad.length > 0) {
      this.logService.debug(
        'ImageLoadingService',
        `checkLoadingQueue:imagesToLoad:length - ${imagesToLoad.length}, total - ${imagesTotal}`
      );
      this._loadingState.next({ inProgress: true, total: imagesTotal, loaded: imagesTotal - images.length });
      const imageUrl = imagesToLoad.shift();
      this.imageLoaderService
        .preload(imageUrl.src)
        .then(() => {
          this.logService.debug('ImageLoadingService', 'checkLoadingQueue:imageLoader:preload:then - ' + imageUrl.src);
          this.checkLoadingQueue(imagesToLoad, imagesTotal);
        })
        .catch((e) => {
          this.logService.error('ImageLoadingService', 'checkLoadingQueue:imageLoader:preload - ' + imageUrl.src, e);
          this.checkLoadingQueue(imagesToLoad, imagesTotal);
        });
      return;
    }
    this.logService.debug(
      'ImageLoadingService',
      `checkLoadingQueue:imagesToLoad:length:else - ${imagesToLoad.length}, total - ${imagesTotal}`
    );
    this._loadingState.next({ inProgress: false, total: imagesTotal, loaded: imagesTotal - images.length });
  }
}
