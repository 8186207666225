<div class="buttons">
  <a class="discount-button" tappable (click)="clickBtnInvoiceDiscountModal()">
    <i class="custom-icon custom-icon-add-percent"></i>
    <span>{{ 'common_discount' | translate }}</span>
  </a>
  <a tappable (click)="openIndividualProductModal()">
    <span>{{ 'individual_product_name' | translate }}</span>
    <i class="custom-icon add-individual-product"></i>
  </a>
</div>
<div
  class="btn-pay-container"
  [class.gastro]="isGastroModeEnabled"
  [class.one-click]="(oneClickPaymentStatus && isConfirmationScreen && isMobile) || (oneClickPaymentStatus && !isMobile)"
>
  <printer-link
    class="make-bill"
    [invoice]="activeInvoice"
    posMode="bill"
    [showGuestOnReceipt]="true"
    [tableEnforce]="true"
    ripple-effect
  ></printer-link>
  <button class="btn-pay" [class.pressed]="isPayButtonPressed" [disabled]="isButtonDisabled" (click)="continueWithoutCheckout()">
    <ion-spinner name="lines"></ion-spinner>
    <div class="btn-title flex-all-center">
      {{ cartButtonTitle }}
    </div>
    <div
      class="btn-amount text-ellipsis flex-all-center"
      [class.btn-amount-one-click]="oneClickPaymentStatus && isConfirmationScreen && isMobile"
    >
      {{ amountForButton | CustomCurrency : true : 0 }}
    </div>
  </button>
  <button class="btn-regular-flow" (click)="continueWithCheckout()">></button>
</div>
