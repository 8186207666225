<ion-header>
  <common-header>
    <ion-buttons header-start slot="secondary">
      <button class="invoice-cancel-send-modal-close-button" (click)="dismiss()" tappable>
        <i class="custom-icon custom-icon-cancel-search"></i>
      </button>
    </ion-buttons>
    <ion-title header-title>{{ title | translate }}</ion-title>
    <ion-buttons header-end slot="primary">
      <button class="btn-barcode-scanner-open" (click)="toggleCameraOrKeyboard()" tappable>
        <i class="custom-icon" [ngClass]="{ 'custom-icon-barcode': !isCameraOpen, 'custom-icon-keyboard': isCameraOpen }"></i>
      </button>
    </ion-buttons>
  </common-header>
</ion-header>
<ion-content class="camera-area-content">
  <div class="camera-area">
    <form [formGroup]="giftCardForm" (ngSubmit)="applyGiftCard()">
      <input
        type="text"
        formControlName="giftCardCode"
        placeholder="{{ 'product_details_modal_assign_gift_card_code_input_placeholder' | translate }}"
      />
      <div *ngIf="giftCardForm.invalid && (giftCardForm.dirty || giftCardForm.touched)" class="gift-card-errors">
        <div *ngIf="giftCardForm.controls.giftCardCode.errors?.pattern">{{ 'giftcard_slash_error_message' | translate }}</div>
      </div>
      <button class="paymash-btn" type="submit" [class.pressed]="inProcess">
        <span>{{ 'common_continue' | translate }}</span>
        <ion-spinner *ngIf="inProcess" name="lines"></ion-spinner>
      </button>
    </form>
  </div>
</ion-content>
