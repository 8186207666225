import { DbDaoService } from '@pos-common/services/db/db-dao.service';
import { ReportingEvent } from './device-report.models';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { Injectable } from '@angular/core';
import { oneOf } from '@paymash/capacitor-database-plugin';

@Injectable()
export class DeviceReportingStore {
  private events: ReportingEvent[] = [];

  constructor(private readonly dbDaoService: DbDaoService) {
    // this.loadData();
  }

  async addEvents(events: ReportingEvent[]) {
    await this.dbDaoService.upsertDataToCollection(UPDATES_TYPES.DeviceReport.type, events);
    this.events.push(...events);
  }

  getUnsavedEvents(): ReportingEvent[] {
    return this.events.filter((event: ReportingEvent) => event.internal && !event.internal.saved);
  }

  async markEventsAsSaved(unsavedEvents: ReportingEvent[]): Promise<void> {
    unsavedEvents.forEach((event) => {
      event.internal = { saved: true };
    });
    const uuids = unsavedEvents.map((event) => event.uuid);

    await this.dbDaoService.remove(UPDATES_TYPES.DeviceReport.type, { uuid: oneOf(...uuids) });
    this.events = this.getUnsavedEvents();
  }

  // private async loadData() {
  //   const events = await this.dbDaoService.getAllDataRaw<ReportingEvent>(UPDATES_TYPES.DeviceReport.type);
  //   this.events = [...events];
  // }
}
