/**
 * Created by y.belinsky on 11/4/16.
 */
import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, Output, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as lodash from 'lodash';
import { Product } from '../../classes/product.class';
import { ProductVariant } from '../../classes/product-variant.class';
import { LogService } from '../../services/system/logger/log.service';
import { ICustomPrice } from '@pos-common/interfaces';
import { Employee } from '@pos-common/classes/employee.class';
import { ProductVariantProvider } from '@pos-common/services/resources/product-variant-db-entity.provider';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CommonHeaderComponentModule } from '../common-header/common-header.component';
import { OrderPipeModule, ReplacePipeModule } from '@pos-common/pipes';
import { FreePricingPickerModule } from '../free-pricing-picker/free-pricing-picker.component';
import { EmployeePickerComponentModule } from '../employee-picker/employee-picker.component';

@Component({
  selector: 'product-variant-picker',
  templateUrl: './product-variant-picker.component.html',
  styleUrls: ['./product-variant-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductVariantPicker {
  @Input() public product: Product;
  @Input() public variants: Array<ProductVariant>;
  @Input() public handleFreePricingInComponent: boolean = false;
  @Input() public handleEmployeeInComponent: boolean = false;
  @Input() setVariantCallback: Function;
  @Input() changeVariantCallback: Function;
  @Output() posSetVariant = new EventEmitter();

  private transaltionStrings: any = {};
  private freePriceForceCall: boolean = false;

  public productVariantModalTitle: string;
  public currentStep: number = 0;
  public optionsTree: any;
  public pickedOptions: Array<string> = [];
  public optionsListsData: Array<any> = [];
  public optionNames: Array<string> = [];
  public backButtonTitle: string;
  public customPrice: number = 0;
  public customPriceCents: any = 0;
  public isPositionExist: boolean = true;
  readonly option_prefix = 'option_';
  private employee: Employee;

  constructor(
    private productVariantProvider: ProductVariantProvider,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private logService: LogService
  ) {
    this.getTransaltions();
  }

  ngOnInit() {
    this.freePriceForceCall = !this.product.hasCustomOptions && this.product.forcePriceInputOnSale;
    if (this.variants) {
      this.setInitialData();
    } else {
      this.productVariantProvider.getListByParams({ productUuid: this.product.uuid }).subscribe(
        (variants) => {
          this.variants = variants;
          this.setInitialData();
        },
        (err) => this.logService.error('ProductVariantPicker', 'ngOnInit:DbDaoService.getDataByParams', err)
      );
    }
  }

  private setInitialData() {
    if (this.freePriceForceCall) {
      this.showFreePriceOrDismiss();
    } else if (this.handleEmployeeInComponent) {
      this.showEmployeeOrDismiss();
    } else {
      this.optionsTree = this.generateOptions(this.variants).optionsGroupedData;
      this.optionNames = this.generateOptions(this.variants).optionNames;
      this.setOptionsListsData();
    }
    this.setTitles();
    this.cdr.detectChanges();
  }

  private getTransaltions() {
    const translationStrings = {
      freePriceTitle: 'variants_product_modal_price',
      commonBack: 'common_back',
      selectEmployee: 'common_select_employee',
    };
    lodash.forEach(translationStrings, (value, key) => {
      this.translate.get(value).subscribe((res: string) => {
        this.transaltionStrings[key] = res;
      });
    });
  }

  private setOptionsListsData() {
    switch (this.currentStep) {
      case 0:
        this.optionsListsData[this.currentStep] = this.getOptionValues(this.optionsTree);
        break;
      case 1:
        this.optionsListsData[this.currentStep] = this.getOptionValues(this.optionsTree[this.pickedOptions[this.currentStep - 1]]);
        break;
      case 2:
        this.optionsListsData[this.currentStep] = this.getOptionValues(
          this.optionsTree[this.pickedOptions[this.currentStep - 2]][this.pickedOptions[this.currentStep - 1]]
        );
        break;
    }
    this.optionsListsData[this.currentStep] = this.optionsListsData[this.currentStep];
    if (this.changeVariantCallback) this.changeVariantCallback(this.optionsListsData[this.currentStep]);
  }

  private addOptionsName(optionNames: Array<string>, optionName: string) {
    if (optionName && optionNames.indexOf(optionName) === -1) {
      optionNames.push(optionName);
    }
    return optionNames;
  }

  private ObjectKeySortV8Fix = (option: string | number): string => {
    return this.option_prefix + option;
  };

  private generateOptions(variants: Array<ProductVariant>) {
    variants.forEach((item) => {
      if (item.position === undefined || item.position === 0) this.isPositionExist = false;
    });
    if (this.isPositionExist) {
      variants.sort((a, b) => (a.position > b.position ? 1 : b.position > a.position ? -1 : 0));
    }

    let optionNames: Array<string> = [];
    let optionsGroupedData: any = {};
    for (let i = 0; i < variants.length; i++) {
      let options: Array<any> = variants[i].options;
      optionNames = this.addOptionsName(optionNames, options[0].name);
      if (options[1]) optionNames = this.addOptionsName(optionNames, options[1].name);
      if (options[2]) optionNames = this.addOptionsName(optionNames, options[2].name);
      // if 'option1' field is already defined
      if (optionsGroupedData[this.ObjectKeySortV8Fix(options[0].value)]) {
        // if 'options3' array is already defined
        if (options[1] && optionsGroupedData[this.ObjectKeySortV8Fix(options[0].value)][this.ObjectKeySortV8Fix(options[1].value)]) {
          // adding 'option3' to array of options3
          if (options[2])
            optionsGroupedData[this.ObjectKeySortV8Fix(options[0].value)][this.ObjectKeySortV8Fix(options[1].value)][
              this.ObjectKeySortV8Fix(options[2].value)
            ] = [];
        } else {
          // if 'options3' array is NOT defined, creating new array for options3
          if (options[1])
            var option3 = (optionsGroupedData[this.ObjectKeySortV8Fix(options[0].value)][this.ObjectKeySortV8Fix(options[1].value)] = {});
          // adding new value to options3
          if (options[2]) {
            option3[this.ObjectKeySortV8Fix(options[2].value)] = [];
          }
        }
      } else {
        // creating new object for options2
        optionsGroupedData[this.ObjectKeySortV8Fix(options[0].value)] = {};
        // creating new array for options3
        if (options[1])
          var option3 = (optionsGroupedData[this.ObjectKeySortV8Fix(options[0].value)][this.ObjectKeySortV8Fix(options[1].value)] = {});
        // adding new value to options3
        if (options[2]) {
          option3[this.ObjectKeySortV8Fix(options[2].value)] = [];
        }
      }
    }
    return { optionsGroupedData: optionsGroupedData, optionNames: optionNames };
  }

  private setTitles() {
    switch (this.currentStep) {
      case 4:
        this.productVariantModalTitle = this.transaltionStrings.selectEmployee;
        this.backButtonTitle = this.freePriceForceCall ? this.transaltionStrings.freePriceTitle : '';
        break;
      case 3:
        this.productVariantModalTitle = this.transaltionStrings.freePriceTitle;
        this.backButtonTitle = this.optionNames[this.currentStep - 1];
        break;

      default:
        this.productVariantModalTitle = this.optionNames[this.currentStep];
        this.backButtonTitle = this.optionNames[this.currentStep - 1];
        if (this.currentStep === 0 && !this.handleFreePricingInComponent) {
          this.backButtonTitle = this.transaltionStrings.commonBack;
        }
        break;
    }
  }

  private getVariantByOptions(pickedOptionsArray: Array<string>, variants: Array<ProductVariant>, optionNames: Array<string>) {
    if (this.freePriceForceCall) return variants[0];
    let filteredVariants: Array<ProductVariant> = variants;
    for (let i = 0; i < pickedOptionsArray.length; i++) {
      filteredVariants = lodash.reject(filteredVariants, (variant) => {
        let partialOption: Object = { name: optionNames[i], value: pickedOptionsArray[i] };
        return !lodash.find(variant.options, partialOption);
      });
    }
    return filteredVariants[0];
  }

  public setCustomPrice(data: ICustomPrice) {
    this.customPriceCents = data.customPriceCents;
    this.customPrice = data.customPrice;
  }

  public getOptionValues(optionsData: any) {
    return lodash.keys(optionsData);
  }

  public cleanKeyFromPrefix(option: string) {
    return option.replace(this.option_prefix, '');
  }

  public setPickedOption(optionValue: string, currentStep: number) {
    this.pickedOptions[currentStep] = optionValue;
    const maxSteps = this.optionNames.length - 1;
    if (currentStep < maxSteps) {
      this.currentStep = currentStep + 1;
      this.setOptionsListsData();
      this.setTitles();
    } else {
      this.showFreePriceOrDismiss();
    }
    this.cdr.detectChanges();
  }

  public showFreePriceOrDismiss() {
    let pickedOptions = this.pickedOptions.map((i) => this.cleanKeyFromPrefix(i));
    let variant = lodash.cloneDeep(this.getVariantByOptions(pickedOptions, this.variants, this.optionNames));
    if (this.product.forcePriceInputOnSale && this.customPrice === 0 && this.handleFreePricingInComponent) {
      this.customPrice = lodash.clone(variant.price);
      this.currentStep = 3;
      this.setTitles();
    } else if (this.handleEmployeeInComponent) {
      this.showEmployeeOrDismiss();
    } else {
      const productShortName = pickedOptions.join(' ');
      variant.price = this.customPrice === 0 ? variant.price : this.customPrice;
      this.setVariant(variant, productShortName);
    }
    if (this.changeVariantCallback) this.changeVariantCallback();
  }

  public setPickedEmployee(employee: Employee) {
    this.employee = employee;
    this.handleEmployeeInComponent = false;
    this.showFreePriceOrDismiss();
  }

  private showEmployeeOrDismiss() {
    this.currentStep = 4;
    this.setTitles();
    if (this.changeVariantCallback) this.changeVariantCallback();
  }

  public backButtonClick() {
    if (this.currentStep === 0) {
      this.setVariant();
    } else {
      if (this.currentStep === 3) {
        this.customPrice = 0;
        this.customPriceCents = 0;
      }
      this.currentStep = this.currentStep - 1;
      this.setOptionsListsData();
      this.setTitles();
    }
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  setVariant(variant?: ProductVariant, productShortName?: string) {
    const { employee } = this;
    if (this.setVariantCallback) {
      this.setVariantCallback(variant, productShortName);
    }
    this.posSetVariant.emit({ variant, productShortName, employee });
  }
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    CommonHeaderComponentModule,
    OrderPipeModule,
    ReplacePipeModule,
    FreePricingPickerModule,
    EmployeePickerComponentModule,
  ],
  declarations: [ProductVariantPicker],
  exports: [ProductVariantPicker],
})
export class ProductVariantPickerModule {}
