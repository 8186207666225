import { IDatabase } from '@paymash/capacitor-database-plugin';
import { MigrationBase, MigrationInfo } from './core/MigrationBase';
import { Injectable } from '@angular/core';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';

@Injectable()
export class Migration_7_Indexes extends MigrationBase {
  version = 7;
  name = 'Add indexes';

  async up(database: IDatabase, info: MigrationInfo) {
    const companyId = info.companyId;

    let collection = this.getCollection(UPDATES_TYPES.Invoice.type, companyId);

    await database.deleteIndex(collection, "InvoiceIndexes");
    await database.createIndex(collection, 'InvoiceIndexes', [
      'uuid',
      'isDraft',
      'gastronomyTable',
      'deleted',
      'storeUuid',
      'salesChannel',
      'ext_type',
    ]);
  }
}
