<pos-search placeholder="customers_search_customer" (onSearch)="handleSearchValue($event)" (onClearValue)="clearSearch()"></pos-search>
<pos-customer-list
  [customers]="itemList"
  [isLoading]="isLoading"
  [hideLoadButton]="isLoadMore"
  [selectedCustomerUuid]="selectedCustomerUuid"
  [loadInterface]="loadInterface"
  [showEditButton]="showEditButton"
  (onLoadMore)="loadMore($event)"
  (onSelectCustomer)="selectCustomer($event)"
  (onEditCustomer)="editCustomer($event)"
></pos-customer-list>
