import { Injectable } from '@angular/core';
import { BarcodeScannerTypes } from '@pos-common/constants/barcode-scanner-types.enum';
import {
  ScannerPluginWrapperBase,
  PaymashScannerPluginWrapper,
  CommunityScannerPluginWrapper,
  IBarcodeScannerPluginSetting,
} from './wrappers';

@Injectable()
export class CameraScannerAdapterService {
  constructor(
    private readonly paymashScannerPluginWrapper: PaymashScannerPluginWrapper,
    private readonly communityScannerPluginWrapper: CommunityScannerPluginWrapper
  ) {
    this.barcodeScannerPlugin = this.getBarcodeScannerPlugin();
  }

  private barcodeScannerPlugin: ScannerPluginWrapperBase;
  private barcodeScannerType = BarcodeScannerTypes.Paymash;

  setBarcodeScanner(barcodeScannerType: BarcodeScannerTypes) {
    this.barcodeScannerType = barcodeScannerType;
    this.barcodeScannerPlugin = this.getBarcodeScannerPlugin();
  }

  getBarcodeScannerType() {
    return this.barcodeScannerType;
  }

  startScan(settings: IBarcodeScannerPluginSetting, successCallback: (data: string) => void, errorCallback: (error) => void): void {
    this.barcodeScannerPlugin.setScanCallback((result) => {
      successCallback(result.code);
    });
    this.barcodeScannerPlugin.startScan(settings).catch((error) => errorCallback(error));
  }

  stopScan(): Promise<void> {
    return this.barcodeScannerPlugin.stopScan();
  }

  private getBarcodeScannerPlugin(): ScannerPluginWrapperBase {
    if (this.barcodeScannerType === BarcodeScannerTypes.Community) {
      return this.communityScannerPluginWrapper;
    }
    return this.paymashScannerPluginWrapper;
  }

  checkPermission(): Promise<boolean> {
    return this.barcodeScannerPlugin.checkPermission();
  }
}
