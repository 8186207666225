/**
 * Created by abilozor on 1/19/17.
 */

// Vendors
import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from './google-analitycs.service';
import { ScannerAppPlugin } from '@paymash/capacitor-scanner-app-plugin';

@Injectable()
export class ScannerAppService {
  private scannerInited: boolean = false;
  //public scannersListChangedEvent: EventEmitter<any> = new EventEmitter();
  public newBarcodeEvent: EventEmitter<string> = new EventEmitter();
  public activeScannerChangedEvent: EventEmitter<any> = new EventEmitter();
  public activeScanner: string = '';
  public scannersList: Array<string> = [];

  constructor(public TranslateService: TranslateService, public GoogleAnalyticsService: GoogleAnalyticsService) {}

  public initAppScanner() {
    if (this.scannerInited || !ScannerAppPlugin) return;

    this.scannerInited = true;
    //needed to send command in case of autoconnection
    this.connectToDevice('');
    ScannerAppPlugin.init().subscribe(
      (scannersList) => {
        this.scannersList = scannersList.map((val) => val.displayName);
        // this.scannersListChangedEvent.emit();
      },
      () => {}
    );
  }

  public connectToDevice(displayName: string) {
    this.GoogleAnalyticsService.trackEvent('ScannerApp', 'tryingConnectToScannerApp');
    ScannerAppPlugin.connect(displayName).subscribe(
      (status) => {
        if (status.connected) {
          this.activeScanner = status.appInfo.displayName;
          //this.activeScannerChangedEvent.emit(appConnected);
          ScannerAppPlugin.waitForBarcode().subscribe(
            (barcode) => {
              this.GoogleAnalyticsService.trackEvent('ScannerApp', 'receivedBarcode');
              this.newBarcodeEvent.emit(barcode.code);
            },
            () => {}
          );
        } else {
          this.activeScanner = '';
          //this.activeScannerChangedEvent.emit('');
        }
      },
      () => {}
    );
  }

  public disconnectDevice() {
    ScannerAppPlugin.disconnectDevice().then(
      () => {},
      () => {}
    );
  }

  public disconnect() {
    if (!this.scannerInited) return;
    this.scannerInited = false;
    ScannerAppPlugin.disconnectSession().then(
      () => {},
      () => {}
    );
  }
}
