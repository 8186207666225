<div *ngIf="showNotify" class="notify-wrapper" (click)="hideNotify()">
  <div *ngIf="notifier" class="notify-box" @scaleElm>
    <div [ngSwitch]="notifyType">
      <ng-container *ngSwitchCase="'combine'">
        {{ 'invoice_has_been_merged' | translate }}
        <i class="notify-merge-invoice-icon"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'new-invoice'">
        <span>
          {{ 'invoice_has_been_created' | translate }}
          <span *ngIf="payload">{{ 'for_table' | translate }} {{ payload }}</span>
        </span>
        <i class="notify-new-invoice-icon"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'set-table'">
        <span>
          {{ 'table_was_changed' | translate }}
          <span *ngIf="payload"> {{ 'to' | translate }} {{ payload }}</span>
        </span>
        <i class="notify-new-invoice-icon"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'add-printer'">
        <span>{{ 'printer_has_been_added' | translate }}</span>
        <i class="notify-add-printer-icon"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'test-printer'">
        <span>{{ 'printer_test_started' | translate }}</span>
        <i class="notify-test-printer-icon"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'add-start-scanner'">
        <span>{{ 'scanner_foundScanner' | translate }}</span>
        <i class="notify-add-printer-icon"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'invoice-paid'">
        <span>{{ 'success_text' | translate }}</span>
        <i class="notify-invoice-paid-icon"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'logs-sent'">
        <span>{{ 'settings_logs_sent_successfully' | translate }}</span>
        <i class="notify-invoice-paid-icon"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'logs-error'">
        <div>{{ 'settings_logs_failed' | translate }}</div>
        <i class="custom-icon notify-logs-send-error-icon"></i>
      </ng-container>
      <ng-container *ngSwitchCase="'appointment-save'">
        <div>{{ 'appointment_success_text' | translate }}</div>
        <i class="notify-invoice-paid-icon"></i>
      </ng-container>
    </div>
  </div>
</div>
