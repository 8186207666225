import { Entity } from '../entity.class';
import { InvoiceEntryCancel } from './invoice-entry-cancel.class';
import { InvoiceEntry } from '../invoice-entry.class';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';

export class InvoicePartiallyCancel extends Entity {
  cancellationReason: string;
  refundPaymentMethod: string;
  restockOnCancellation: boolean;
  invoiceEntriesToCancel: InvoiceEntryCancel[];
  refundToGiftCardIdentifier: string;
  cancellingEmployee: any;

  constructor(data: any, refundPaymentMethod: string) {
    super(data);
    this.cancellationReason = data.cancellationReason || null;
    this.restockOnCancellation = data.restockOnCancellation || false;
    this.refundPaymentMethod = refundPaymentMethod;
    this.invoiceEntriesToCancel = [];
    this.cancellingEmployee = null;
    if (data.cancellingEmployee) {
      this.cancellingEmployee = { uuid: data.cancellingEmployee.uuid };
    }
  }

  setInvoiceEntries(invoiceEntries: InvoiceEntry[]) {
    this.invoiceEntriesToCancel = invoiceEntries
      .filter((invoiceEntry) => invoiceEntry.type !== PRODUCT_TYPES.SHIPPING_COST)
      .map(({ uuid, quantity }: InvoiceEntry) => new InvoiceEntryCancel(uuid, quantity));
  }
}
