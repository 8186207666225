import { Directive, ElementRef, Input, NgModule, OnInit } from '@angular/core';
import { IonicSlides } from '@ionic/angular';

@Directive({ selector: 'swiper-container[posIonSlides], ion-slides[posIonSlides]' })
export class IonSlidesDirective implements OnInit {
  @Input() slideOptions: { [key: string]: any } = {};
  private readonly ionSlidesTag = 'ION-SLIDES';
  constructor(private swiperEl: ElementRef) {}

  ngOnInit(): void {
    const element = this.swiperEl.nativeElement as HTMLElement;
    if (element.tagName === this.ionSlidesTag) {
      const { pagination: pager, scrollbar, ...options } = this.slideOptions;
      Object.assign(this.swiperEl.nativeElement, { pager, scrollbar, options });
      return;
    }
    Object.assign(this.swiperEl.nativeElement, { modules: [IonicSlides], ...this.normalizeOptions(), ...this.slideOptions });
  }

  private normalizeOptions() {
    return {
      effect: undefined,
      direction: 'horizontal',
      initialSlide: 0,
      loop: false,
      parallax: false,
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 300,
      slidesPerColumn: 1,
      slidesPerColumnFill: 'column',
      slidesPerGroup: 1,
      centeredSlides: false,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      touchEventsTarget: 'container',
      autoplay: false,
      freeMode: false,
      freeModeMomentum: true,
      freeModeMomentumRatio: 1,
      freeModeMomentumBounce: true,
      freeModeMomentumBounceRatio: 1,
      freeModeMomentumVelocityRatio: 1,
      freeModeSticky: false,
      freeModeMinimumVelocity: 0.02,
      autoHeight: false,
      setWrapperSize: false,
      zoom: {
        maxRatio: 3,
        minRatio: 1,
        toggle: false,
      },
      touchRatio: 1,
      touchAngle: 45,
      simulateTouch: true,
      touchStartPreventDefault: false,
      shortSwipes: true,
      longSwipes: true,
      longSwipesRatio: 0.5,
      longSwipesMs: 300,
      followFinger: true,
      threshold: 0,
      touchMoveStopPropagation: true,
      touchReleaseOnEdges: false,
      iOSEdgeSwipeDetection: false,
      iOSEdgeSwipeThreshold: 20,
      resistance: true,
      resistanceRatio: 0.85,
      watchSlidesProgress: false,
      watchSlidesVisibility: false,
      preventClicks: true,
      preventClicksPropagation: true,
      slideToClickedSlide: false,
      loopAdditionalSlides: 0,
      noSwiping: true,
      runCallbacksOnInit: true,
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      flipEffect: {
        slideShadows: true,
        limitRotation: true,
      },
      cubeEffect: {
        slideShadows: true,
        shadow: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      },
      fadeEffect: {
        crossFade: false,
      },
    };
  }
}

@NgModule({
  declarations: [IonSlidesDirective],
  exports: [IonSlidesDirective],
})
export class IonSlidesDirectiveModule {}
