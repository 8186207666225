import {
  Customer as CustomerEntity,
  InvoiceEntry,
  InvoiceGuest,
  Product,
} from './entities';

export type Message =
  | Initialize
  | Cart
  | Customer
  | ScreensaverProducts
  | RequestEmailAddress
  | EmailModelUpdated
  | SubmitEmailModel
  | InvoiceSent
  | Ping;

export type Initialize = {
  type: 'initialize';
  currency: string;
  lang: string;
  image: string | undefined;
};

export type Cart = {
  type: 'cart';
  entries: InvoiceEntry[];
  guests: InvoiceGuest[] | undefined;
  amount: number;
};

export type Customer = {
  type: 'customer';
  customer: CustomerEntity | undefined;
};

export type ScreensaverProducts = {
  type: 'screensaverProducts';
  products: Product[];
};

export type RequestEmailAddress = {
  type: 'requestEmailAddress';
  amount: number;
  qrCode: string | undefined;
};

export type EmailModelUpdated = {
  type: 'emailModelUpdated';
  emailAddress: string | undefined;
  inputEnabled: boolean | undefined;
  rememberEmail: boolean | undefined;
};

export type SubmitEmailModel = {
  type: 'submitEmailModel';
  emailAddress: string;
  rememberEmail: boolean;
};

export type InvoiceSent = {
  type: 'invoiceSent';
  emailAddress: string;
};

export type Ping = {
  type: 'ping';
};

const createMessage = (message: Message): Message => message;

export const createCartMessage = (
  entries: InvoiceEntry[],
  guests: InvoiceGuest[],
  amount: number,
) =>
  createMessage({
    type: 'cart',
    entries,
    guests,
    amount,
  });

export const createCustomerMessage = (customer: CustomerEntity) =>
  createMessage({type: 'customer', customer});

export const createInitializeMessage = (
  currency: string,
  lang: string,
  image: string | undefined,
) =>
  createMessage({
    type: 'initialize',
    currency,
    lang,
    image,
  });

export const createScreensaverProductsMessage = (products: Product[]) =>
  createMessage({
    type: 'screensaverProducts',
    products,
  });

export const createRequestEmailAddressMessage = (amount: number, qrCode: string) =>
  createMessage({
    type: 'requestEmailAddress',
    amount,
    qrCode,
  });

export const createEmailModelUpdatedMessage = ({
                                                 emailAddress,
                                                 inputEnabled,
                                                 rememberEmail,
                                               }: {
  emailAddress?: string;
  inputEnabled?: boolean;
  rememberEmail?: boolean;
}) =>
  createMessage({
    type: 'emailModelUpdated',
    emailAddress,
    inputEnabled,
    rememberEmail,
  });

export const createSubmitEmailModelMessage = ({
                                                emailAddress,
                                                rememberEmail,
                                              }: {
  emailAddress: string;
  rememberEmail: boolean;
}) => createMessage({type: 'submitEmailModel', emailAddress, rememberEmail});

export const createInvoiceSentMessage = (emailAddress: string) =>
  createMessage({type: 'invoiceSent', emailAddress});

export const createPingMessage = () => createMessage({type: 'ping'});
