import { EventEmitter } from '@angular/core';
import { ICustomerDisplayDevice } from './ICustomerDisplayDevice';
import { Invoice } from '@pos-common/classes/invoice.class';
import { Observable } from 'rxjs';

export type EmailModelChanged = { emailAddress: string; save: boolean; submit: boolean };

export abstract class ICustomerDisplayService {
  abstract turnOn(): Promise<void>;

  abstract turnOff(): Promise<void>;

  abstract get devices(): Array<ICustomerDisplayDevice>;

  abstract get devicesChangeEvent(): EventEmitter<Array<ICustomerDisplayDevice>>;

  abstract connectDevice(device: ICustomerDisplayDevice): Promise<void>;

  abstract disconnectDevice(device: ICustomerDisplayDevice): Promise<void>;

  abstract processInvoiceChange(invoice: Invoice | undefined): Promise<void>;

  abstract lockUserInput(): Promise<void>;

  abstract unlockUserInput(): Promise<void>;

  abstract requestEmailAddress(amount: number, qrCode: string): Promise<void>;

  abstract invoiceSent(): Promise<void>;

  abstract setEmailAddress(value: string): Promise<void>;

  abstract emailModelChanged(): Observable<EmailModelChanged>;
}
