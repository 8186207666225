import { UniversalCustomerDisplayDevice, SavedDeviceInfo as SavedDeviceInfoBase } from '../UniversalCustomerDisplayDevice';
import { NetworkDriver } from './NetworkDriver';

export class Device extends UniversalCustomerDisplayDevice<ConnectionInfo> {
  createDriver() {
    return new NetworkDriver(this, this.loggerFactory);
  }
}

export interface ConnectionInfo {
  host: string;
  port: number;
}

export interface SavedDeviceInfo extends SavedDeviceInfoBase {
  connectionInfo: ConnectionInfo;
}
