import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '../utils/localstorage.utils';
import { SecurityService } from './security.service';
import { PaymentResult } from '../../classes/payment-result.class';
import { PAYMENT_PROVIDERS } from '../../constants/payment-providers.const';
import { PaymentProcessingService } from '../../components/payment-processing/payment-processing.service';
import { TranslateService } from '@ngx-translate/core';
import { TimApiTerminal } from '../../classes/tim-api-terminal.class';
import { ConnectionStatus, TransactionStatus } from '../../constants/tim-api.enum';
import { LogService } from './logger/log.service';
import { PaymentProcessingActions } from '../../components/payment-processing/payment-processing-actions.enum';
import { TimApiTerminalStatus, TimApiError, TimApiErrorResultCode } from '@paymash/capacitor-tim-api-plugin';
import { distinctUntilChanged } from 'rxjs/operators';
import { ErrorTranslationHelper } from '@pos-common/classes';
import { PlatformService } from './platform/platform.service';
import { TimApiPlugin } from '@paymash/capacitor-tim-api-plugin'
import { ignorePromiseError } from '@pos-common/services/utils/promise.utils';

@Injectable()
export class TimApiService {
  private currentTerminal: BehaviorSubject<TimApiTerminal> = new BehaviorSubject(
    new TimApiTerminal(null, null, ConnectionStatus.disconnected)
  );
  private localStorageKey: string = 'timApiTerminal-';
  private _paymentProcessingWizardFinished: boolean = false;
  private errorTranslationHelper = new ErrorTranslationHelper();

  constructor(
    private localStorage: LocalStorage,
    private paymentProcessingService: PaymentProcessingService,
    private securityService: SecurityService,
    private logService: LogService,
    private platformService: PlatformService,
    private translateService: TranslateService
  ) {
    this.setupErrorMessages();
    TimApiPlugin.statusUpdate
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.connectionStatus === curr.connectionStatus && prev.transactionStatus === curr.transactionStatus;
        })
      )
      .subscribe((terminalStatus: TimApiTerminalStatus) => {
        this.logService.debug('TimApiService:terminalStatusChanged', JSON.stringify(terminalStatus));
        if (!this._paymentProcessingWizardFinished) {
          this.handleConnectionStatus(terminalStatus.connectionStatus);
          this.handleTransactionStatus(terminalStatus.transactionStatus);
        }
      });
  }

  private setupErrorMessages() {
    this.errorTranslationHelper.setupMessages([
      [TimApiErrorResultCode.API_CONNECT_FAIL_TERMINAL, 'tim_api_terminal_connection_fail'],
      [TimApiErrorResultCode.BUSY_MAINTENANCE, 'tim_api_terminal_is_busy'],
      [TimApiErrorResultCode.BUSY_OTHER_CONTROLLER, 'tim_api_terminal_is_busy'],
      [TimApiErrorResultCode.CARDHOLDER_STOP, 'terminal_transaction_error_user_cancel'],
      [TimApiErrorResultCode.CARDHOLDER_TIMEOUT, 'terminal_transaction_error_user_cancel'],
    ]);
  }

  public saveTerminal(terminalId: string, ipAddress: string) {
    const terminal = new TimApiTerminal(terminalId, ipAddress, ConnectionStatus.disconnected);
    if (terminalId || ipAddress) {
      TimApiPlugin.subscribeToStatusUpdates().catch(ignorePromiseError);
    }
    this.setTerminal(terminal);
  }

  private setTerminal(terminal: TimApiTerminal) {
    this.currentTerminal.next(terminal);
    const companyUuid = this.securityService.getLoggedCompanyData().uuid;
    this.localStorage.setObject(this.localStorageKey + companyUuid, terminal);
  }

  public getTerminalInstantly(): TimApiTerminal {
    return this.currentTerminal.getValue();
  }

  public geTerminalFromLocalStorage(): TimApiTerminal {
    try {
      const companyUuid = this.securityService.getLoggedCompanyData().uuid;
      const terminalData: any = this.convertObj(this.localStorage.getObject(`${this.localStorageKey}${companyUuid}`));
      const terminal = new TimApiTerminal(terminalData.terminalId, terminalData.ipAddress, ConnectionStatus.disconnected);
      terminal.tipAllowed = terminalData.tipAllowed || false;
      return terminal;
    } catch (e) {
      return new TimApiTerminal(null, null, ConnectionStatus.disconnected);
    }
  }

  private convertObj(dataForAdjustment: any) {
    const dataKeys = Object.getOwnPropertyNames(dataForAdjustment);
    return dataKeys.reduce((newData: any, dataKey: string) => {
      let key = dataKey;
      if (key.substring(0, 1) === '_') {
        key = key.substring(1);
      }

      newData[key] = dataForAdjustment[dataKey];
      return newData;
    }, {});
  }

  public async connectToTerminal(terminal: TimApiTerminal) {
    if (this.platformService.isNativePlatform) {
      const { connectionParams } = terminal;
      return TimApiPlugin.connect(connectionParams);
    }
  }

  public async disconnectFromTerminal() {
    if (this.platformService.isNativePlatform) {
      return TimApiPlugin.disconnect().then(() => this.saveTerminal(null, null));
    }
    return this.saveTerminal(null, null);
  }

  public makeTransaction(amount: number, currency: string): Promise<PaymentResult> {
    this._paymentProcessingWizardFinished = false;
    return new Promise((resolve, reject) => {
      this.paymentProcessingService.init();
      const terminal: TimApiTerminal = this.getTerminalInstantly();
      if (!terminal.viewName) {
        this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, {
          message: this.translateService.instant('mPrime_terminal_does_not_connect'),
          retryButtonOff: true,
        });
        this._paymentProcessingWizardFinished = true;
        return reject(this.translateService.instant('mPrime_terminal_does_not_connect'));
      }
      this.paymentProcessingService.dispatchAction(PaymentProcessingActions.connecting, { cancelButtonOff: true });
      this.connectToTerminal(terminal)
        .then(() => {
          this.logService.debug('TimApiService:connectToTerminal', 'connected');
          return TimApiPlugin.purchase({amount, currency, tipAllowed: terminal.tipAllowed});
        })
        .then((timApiPaymentResult) => {
          const paymentResult: PaymentResult = new PaymentResult(PAYMENT_PROVIDERS.SIX);
          paymentResult.setPaymentResultData(timApiPaymentResult);
          this._paymentProcessingWizardFinished = true;
          this.logService.debug('TimApiService:purchase', JSON.stringify(timApiPaymentResult));
          resolve(paymentResult);
        })
        .catch((error) => {
          this.logService.error('TimApiService', 'handleTimApiException', JSON.stringify(error));
          this._paymentProcessingWizardFinished = true;
          const message = this.getTimApiExceptionMessage(error);
          this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, { message, retryButtonOff: true });
          reject(error);
        });
    });
  }

  getTimApiExceptionMessage(err: TimApiError): string {
    const message = this.errorTranslationHelper.getTranslationKey(err.resultCode);
    return message ? this.translateService.instant(message) : err.resultCode;
  }

  public async balance(): Promise<string> {
    return TimApiPlugin.balance().then((timApiBalanceResult) => timApiBalanceResult.receipts[0].value);
  }

  public toggleTippingOnTerminal(tipAllowed: boolean) {
    const terminal = this.getTerminalInstantly();
    terminal.tipAllowed = tipAllowed;
    this.setTerminal(terminal);
  }

  private handleConnectionStatus(connectionStatus: ConnectionStatus): void {
    const terminal = this.getTerminalInstantly();
    terminal.connectionStatus = connectionStatus;
    this.currentTerminal.next(terminal);
  }

  private handleTransactionStatus(transactionStatus: TransactionStatus) {
    switch (transactionStatus) {
      case TransactionStatus.waitForCard:
        this.paymentProcessingService.dispatchAction(PaymentProcessingActions.provideCard, {
          cancelButtonOff: true,
          retryButtonOff: true,
        });
        break;
      case TransactionStatus.pinEntry:
        this.paymentProcessingService.dispatchAction(PaymentProcessingActions.providePin);
        break;
      case TransactionStatus.processing:
      case TransactionStatus.waitForCommit:
        this.paymentProcessingService.dispatchAction(PaymentProcessingActions.processing);
        break;
      case TransactionStatus.signatureCapture:
        this.paymentProcessingService.dispatchAction(PaymentProcessingActions.provideSignature);
        break;
    }
  }
}
