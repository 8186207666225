import { ReceiptTextAlign } from '../../enum/receipt-text-align.enum';
import { MyPosReceipItemBase } from './my-pos-receipt-item-base.abstract';
import * as QRious from 'qrious';
import { MYPOS } from '@pos-common/constants/mypos.const';

enum MyPosAlignType {
  left = 'ALIGN_LEFT',
  center = 'ALIGN_CENTER',
  right = 'ALIGN_RIGHT',
}

enum MyPosType {
  text = 'TEXT',
  image = 'IMAGE',
}

export class MyPosReceiptItem extends MyPosReceipItemBase<MyPosReceiptItem> {
  type = MyPosType.text;
  text?: string;
  alignment?: string;
  doubleHeight = false;
  doubleWidth = false;
  imageEncoded?: string;

  protected getEmptyItem(text?: string) {
    const receiptItem = new MyPosReceiptItem();
    receiptItem.text = text;
    receiptItem.alignment = MyPosAlignType.center;
    return receiptItem;
  }

  protected getFont(receiptItem: MyPosReceiptItem, isDoubleSize: boolean) {
    receiptItem.doubleHeight = isDoubleSize;
    receiptItem.doubleWidth = isDoubleSize;
    return receiptItem;
  }

  protected getAlign(receiptItem: MyPosReceiptItem, textAlign: ReceiptTextAlign) {
    let align = MyPosAlignType.center;
    if (textAlign === ReceiptTextAlign.left) {
      align = MyPosAlignType.left;
    } else if (textAlign === ReceiptTextAlign.right) {
      align = MyPosAlignType.right;
    }
    receiptItem.alignment = align;
    return receiptItem;
  }

  protected getCut(receiptItem: MyPosReceiptItem) {
    receiptItem.text = '';
    return receiptItem;
  }

  protected getImage(receiptItem: MyPosReceiptItem, image: any) {
    receiptItem.type = MyPosType.image;
    receiptItem.imageEncoded = image;
    return receiptItem;
  }

  protected getQrCode(receiptItem: MyPosReceiptItem, qrCode: any) {
    const qr: QRious = new QRious({
      value: qrCode,
      size: MYPOS.QR_SIZE,
    });
    const base = qr.toDataURL().split(';base64,')[1];
    return this.getImage(receiptItem, base);
  }
}
