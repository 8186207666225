import { Injectable } from '@angular/core';
import { LocalStorage } from '../utils/localstorage.utils';
import { PaymentMethod } from '../../classes/payment-method.class';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from './logger/log.service';
import { StorageKeys } from '../../constants/storage.const';
import { IDeviceSettings, ProductSettingsInterface } from '../../interfaces';
import { Product } from '../../classes/product.class';
import { Subject } from 'rxjs';

@Injectable()
export class PosSettingsService {
  private companyUuid: string = null;
  readonly invoicesKeepDurationOptions = [3, 7, 14];
  readonly settingsKeys = StorageKeys.settings;
  private tippingStatusEvent = new Subject<boolean>();
  private defaultTipProductEvent = new Subject<ProductSettingsInterface>();

  constructor(
    private localStorage: LocalStorage,
    private alertService: AlertService,
    private translateService: TranslateService,
    private logService: LogService
  ) {}

  setCompanyUuid(companyUuid: string) {
    this.companyUuid = companyUuid;
  }

  private getStorageKey(storageKey: string): string {
    return `${storageKey}-${this.companyUuid}`;
  }

  setInvoicesKeepDuration(invoicesKeepDuration: number) {
    this.localStorage.set(StorageKeys.invoicesKeepDurationKey, invoicesKeepDuration.toString());
  }

  getInvoicesKeepDuration(): number {
    const invoicesKeepDuration = this.localStorage.get(StorageKeys.invoicesKeepDurationKey);
    if (invoicesKeepDuration) {
      return parseInt(invoicesKeepDuration);
    }
    return this.invoicesKeepDurationOptions[0];
  }

  getInvoicesKeepDurationOptions(): number[] {
    return [...this.invoicesKeepDurationOptions];
  }

  setOneClickPaymentStatus(oneClickPaymentStatus: boolean) {
    this.setValueToLocalStorage(this.settingsKeys.oneClickPaymentStatusKey, oneClickPaymentStatus);
  }

  getOneClickPaymentStatus(): boolean {
    return this.getValueFromLocalStorage(this.settingsKeys.oneClickPaymentStatusKey);
  }

  setTippingStatus(tippingStatus: boolean) {
    this.setValueToLocalStorage(this.settingsKeys.tippingStatusKey, tippingStatus);
    this.tippingStatusEvent.next(tippingStatus);
  }

  getTippingStatus(): boolean {
    const tippingStatus = this.getValueFromLocalStorage(this.settingsKeys.tippingStatusKey);
    return !!tippingStatus;
  }

  getTippingStatusEvent() {
    return this.tippingStatusEvent;
  }

  setDefaultPaymentMethod(defaultPaymentMethod: PaymentMethod) {
    this.setValueToLocalStorage(this.settingsKeys.defaultPaymentMethodKey, defaultPaymentMethod);
  }

  getDefaultPaymentMethod(): PaymentMethod {
    return this.getObjectFromLocalStorage(this.settingsKeys.defaultPaymentMethodKey);
  }

  setDefaultTipProduct(defaultTipProduct: ProductSettingsInterface) {
    this.setValueToLocalStorage(this.settingsKeys.defaultTipProductKey, defaultTipProduct);
    this.defaultTipProductEvent.next(defaultTipProduct);
  }

  getDefaultTipProduct(): ProductSettingsInterface {
    return this.getValueFromLocalStorage(this.settingsKeys.defaultTipProductKey);
  }

  getDefaultTippingProductEvent() {
    return this.defaultTipProductEvent;
  }

  setMultipleGuestsStatus(multipleGuestsStatus: boolean) {
    this.setValueToLocalStorage(this.settingsKeys.multipleGuestsStatusKey, multipleGuestsStatus);
  }

  getMultipleGuestsStatus() {
    const status = this.getValueFromLocalStorage(this.settingsKeys.multipleGuestsStatusKey);
    return typeof status === 'boolean' ? status : false;
  }

  setOnInvocePaymentStatus(status: boolean) {
    this.setValueToLocalStorage(this.settingsKeys.onInvoicePaymentStatusKey, status);
  }

  getOnInvocePaymentStatus() {
    const status = this.getValueFromLocalStorage(this.settingsKeys.onInvoicePaymentStatusKey);
    return typeof status === 'boolean' ? status : true;
  }

  setInitialPaidInvoicesStatus(isInitstatus: boolean) {
    this.setValueToLocalStorage(StorageKeys.initialPaidInvoicesStatus, isInitstatus);
  }

  getInitialPaidInvoicesStatus() {
    return this.getValueFromLocalStorage(StorageKeys.initialPaidInvoicesStatus);
  }

  handlePaymentMethodUpdate(paymentMethodsContainer: { data: PaymentMethod }[]): void {
    const defaultPaymentMethod = this.getDefaultPaymentMethod();
    if (!defaultPaymentMethod.method || !paymentMethodsContainer?.length) {
      return;
    }
    const paymentMethods = paymentMethodsContainer.map((i) => i.data);
    const hasDefaultPaymentMethod = paymentMethods.some((p) => p.method === defaultPaymentMethod.method && (p.deleted || !p.visiblePOS));
    if (hasDefaultPaymentMethod) {
      this.setOneClickPaymentStatus(false);
      this.setDefaultPaymentMethod(<any>{});
      this.showRemovedNotification(
        'global_default_payment_method_was_removed',
        'methodName',
        defaultPaymentMethod.name,
        'handlePaymentMethodUpdate'
      );
    }
  }

  handleTipProductUpdate(productsContainer: { data: Product }[]): void {
    const defaultTipProduct = this.getDefaultTipProduct();
    if (!defaultTipProduct || !productsContainer?.length) {
      return;
    }
    const products = productsContainer.map((i) => i.data);
    const hasDefaultTipProduct = products.some(
      (product) => product.uuid === defaultTipProduct.uuid && (product.deleted || !product.visible)
    );
    if (hasDefaultTipProduct) {
      this.setTippingStatus(false);
      this.setDefaultTipProduct(null);
      this.showRemovedNotification(
        'global_default_tip_product_was_removed',
        'tipProduct',
        defaultTipProduct.name,
        'handleTipProductUpdate'
      );
    }
  }

  resetCompanyData() {
    const settingsValues = Object.values(this.settingsKeys);
    const values = [...settingsValues, StorageKeys.initialPaidInvoicesStatus];
    values.forEach((value) => {
      this.localStorage.remove(this.getStorageKey(value));
    });
    this.localStorage.remove(StorageKeys.invoicesKeepDurationKey);
  }

  async showRemovedNotification(message: string, property: string, value: string, methodName: string = '') {
    const alert = await this.alertService.create({
      header: this.translateService.instant('common_error'),
      message: this.translateService.instant(message, {
        [property]: this.translateService.instant(value),
      }),
      buttons: [{ text: 'OK', role: 'cancel' }],
    });
    alert.present().catch((e) => this.logService.error('PosSettingsService', `${methodName}:showRemovedNotification:alert:present`, e));
  }

  getDeviceSettings(): IDeviceSettings {
    return this.getObjectFromLocalStorage(this.settingsKeys.device);
  }

  setDeviceSettings(deviceSettings: IDeviceSettings) {
    this.setValueToLocalStorage(this.settingsKeys.device, deviceSettings);
  }

  private setValueToLocalStorage(key: string, value: any) {
    this.localStorage.setObject(this.getStorageKey(key), value);
  }

  private getValueFromLocalStorage(key: string) {
    return JSON.parse(this.localStorage.get(this.getStorageKey(key)));
  }

  private getObjectFromLocalStorage(key: string) {
    return this.localStorage.getObject(this.getStorageKey(key));
  }
}
