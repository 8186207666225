import { IAdyenPrinterService, AdyenPrinterService } from './adyen-printer.service';
import { Injector } from '@angular/core';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { AdyenPrinterServiceMock } from './adyen-printer.service.mock';
import { AdyenPaymentApi } from '@pos-common/services/system/adyen/services/api';
import { AdyenUtils } from '@pos-common/services/system/adyen/adyen-utils';
import { IAdyenService } from '@pos-common/services/system/devices/adyen/adyen.service';

export function adyenPrinterServiceFactory(
  injector: Injector,
  adyenPaymentApi: AdyenPaymentApi,
  utils: AdyenUtils,
  adyenService: IAdyenService,
): IAdyenPrinterService {
  const platformService = injector.get(PlatformService);
  if (platformService.isNativePlatform) {
    return new AdyenPrinterService(injector, adyenPaymentApi, utils, adyenService);
  }
  return new AdyenPrinterServiceMock(injector);
}
