import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ShiftsApiService } from './shifts-api.service';
import { LogService } from '../../common/services/system/logger/log.service';
import { InvoicesFilterService } from '../../common/services/system/invoice-filter.service';
import { DbDaoService } from '../../common/services/db/db-dao.service';
import { UPDATES_TYPES } from '../../common/constants/updates-types.const';
import { Invoice } from '../../common/classes/invoice.class';
import { PrinterService } from '../../common/services/system/printer.service';
import { UserNotificationService } from '../../common/services/system/user-notification/user-notification.service';
import { SERVER_CONFIG } from '../../common/constants/server.const';
import { NetworkService } from '../../common/services/system/network.service';
import { SecurityService } from '../../common/services/system/security.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorLevel } from '@spryrocks/logger';
import { InAppBrowserService } from '@pos-common/services/system';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { filter } from 'rxjs';
import { ViewWillLeave } from '@ionic/angular';

@Component({
  selector: 'shifts',
  templateUrl: './shifts.page.html',
  styleUrls: ['./shifts.page.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SubSinkService],
})
export class ShiftsPage implements OnInit, ViewWillLeave {
  @ViewChild('shifts', { static: true }) shiftsComponent: ElementRef;

  constructor(
    private shiftsApiService: ShiftsApiService,
    private dbDaoService: DbDaoService,
    private invoicesFilterService: InvoicesFilterService,
    private printerService: PrinterService,
    private userNotificationService: UserNotificationService,
    public inAppBrowserService: InAppBrowserService,
    public networkService: NetworkService,
    public securityService: SecurityService,
    public translateService: TranslateService,
    private subSinkService: SubSinkService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    const company = this.securityService.getLoggedCompanyData();
    this.shiftsComponent.nativeElement.currency = company.locale.currency;
    this.shiftsComponent.nativeElement.language = this.translateService.currentLang;
    this.shiftsComponent.nativeElement.employeeReference = { uuid: this.securityService.getActiveEmployee().uuid };
    this.shiftsComponent.nativeElement.loadShifts = (options, callback) => this.getShifts(options, callback);
    this.shiftsComponent.nativeElement.updateShift = (shiftData, callback) => this.updateShift(shiftData, callback);
    this.shiftsComponent.nativeElement.openInvoice = (invoiceData) => this.openInvoice(invoiceData);
    this.shiftsComponent.nativeElement.openCashRegister = () => this.openCashRegister();
    this.networkService.internetStatusChangeEvent
      .pipe(
        filter((isOffline) => isOffline),
        this.subSinkService.takeUntilDestroy()
      )
      .subscribe(() => {
        this.userNotificationService.showNoConnectionMessage();
      });
  }

  ionViewWillLeave(): void {
    this.shiftsComponent.nativeElement.destroyModal();
  }

  getShifts(options, callback) {
    this.shiftsApiService
      .getShifts(options)
      .then((shiftsApiResponse) => {
        callback(shiftsApiResponse);
      })
      .catch((error) => this.logService.error('ShiftsPage', 'getShifts', error));
  }

  updateShift(shiftData, callback) {
    this.shiftsApiService
      .updateShift(shiftData)
      .then((shiftsApiResponse) => {
        callback(shiftsApiResponse);
      })
      .catch((error) => {
        if (error.status === 400) {
          this.handleBadRequest(error.data);
        } else {
          this.userNotificationService.showNoConnectionMessage();
        }
        this.logService.error('ShiftsPage', 'updateShift', error, ErrorLevel.Low);
      });
  }

  openInvoice(invoiceData) {
    this.dbDaoService
      .getDataByUUID(UPDATES_TYPES.Invoice.type, invoiceData.uuid)
      .then((data) => {
        this.invoicesFilterService.clickInvoiceHandler(new Invoice(data.data));
      })
      .catch((error) => {
        this.inAppBrowserService.openUrl(`${SERVER_CONFIG.API_LEGACY_URL}proposals/${invoiceData.uuid}/pdf`, '_system');
        this.logService.error('ShiftsPage', 'openInvoice:getDataByUUID', error, ErrorLevel.Low);
      });
  }

  openCashRegister() {
    this.printerService.openCashRegister().catch((error) => this.logService.error('ShiftsPage', 'openCashRegister', error));
  }

  handleBadRequest(data) {
    if (data && data.errors) {
      data.errors.forEach((error) => {
        this.userNotificationService.showMessage(error.message);
      });
    }
  }
}
