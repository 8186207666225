import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { GiftCardProvider } from '@pos-modules/assing-gift-card/gift-card.provider';

@Pipe({ name: 'CardNumberObfuscation' })
export class CardNumberObfuscation implements PipeTransform {
  constructor(private giftCardService: GiftCardProvider) {}
  transform(value: string, showStars: boolean = true, defaultSing: string = '...', addBrackets: boolean = false): any {
    return this.giftCardService.makeCardObfuscation(value, showStars, defaultSing, addBrackets);
  }
}
@NgModule({
  declarations: [CardNumberObfuscation],
  exports: [CardNumberObfuscation],
})
export class CardNumberObfuscationPipeModule {}
