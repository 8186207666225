import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyLocalizedPipeModule } from '@pos-common/pipes';

@Component({
  selector: 'pos-select-start-date',
  templateUrl: './select-start-date.component.html',
  styleUrls: ['./select-start-date.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectStartDateComponent {
  @Input() startedAt: string;
  @Input() endedAt: string;
  @Output() posClick = new EventEmitter<void>();

  openDatesModal() {
    this.posClick.emit();
  }
}
@NgModule({
  imports: [TranslateModule.forChild(), CompanyLocalizedPipeModule],
  declarations: [SelectStartDateComponent],
  exports: [SelectStartDateComponent],
})
export class SelectStartDateComponentModule {}
