import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavController, IonList } from '@ionic/angular';
import { filter, skip, switchMap, takeUntil } from 'rxjs/operators';
import { InvoicesProvider } from '@pos-common/services/resources/invoices-db-entity.provider';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { from } from 'rxjs';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { CompanyProperties } from '@pos-common/constants';
import { InfiniteScrollBaseComponent } from '@pos-modules/shared/components';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';
import { InvoceInlist } from '@pos-common/classes/invoice-in-list.class';
import { Invoice } from '@pos-common/classes/invoice.class';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';
import { CartService } from '@pos-common/services/system/cart.service';
import { InvoicesFilterService } from '@pos-common/services/system/invoice-filter.service';
import { InvoicesService } from '@pos-common/services/system/invoices.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { NotifyPopupsService } from '@pos-common/services/system/notify-popups.service';
import { SecurityService } from '@pos-common/services/system/security.service';
import { UpdatesService } from '@pos-common/services/system/updates.service';
import { fadeElm } from 'app/app.animations';
import { PAGINATION } from '@pos-common/constants/pagination.const';
import { ILoadDataOptions } from '@pos-common/interfaces';

@Component({
  selector: 'invoice-list2',
  templateUrl: './invoice-list2.component.html',
  styleUrls: ['./invoice-list2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeElm()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class InvoiceList2 extends InfiniteScrollBaseComponent<InvoceInlist> implements OnInit, OnDestroy {
  @ViewChild('ionList', { static: false }) ionList?: IonList;
  public currentFilterSet: any = {};
  public isGastroMode = false;
  public isTableForce = false;
  public activeInvoice: Invoice;
  public tableChangeAnimUuid: any = null;
  public paidInvoiceOnDemandInProgress = false;
  protected loadMoreCount = PAGINATION.INVOICES_ITEMS_COUNT;
  private readonly stopError = 'Stop loadData';

  constructor(
    protected cdr: ChangeDetectorRef,
    private invoicesFilterService: InvoicesFilterService,
    private notifyPopupsService: NotifyPopupsService,
    private navController: NavController,
    private invoicesService: InvoicesService,
    private cartService: CartService,
    private securityService: SecurityService,
    private invoicesProvider: InvoicesProvider,
    private updatesService: UpdatesService,
    private setTimeoutUtil: SetTimeoutUtil,
    private subSinkService: SubSinkService,
    private logService: LogService
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.activeInvoice = this.cartService.getActiveInvoice();
    this.isGastroMode = this.securityService.getLoggedCompanyData()['isRestaurantEnabled'];
    this.isTableForce = this.securityService.getLoggedCompanyData()['tablesEnforced'];

    this.securityService
      .observableCompanyProperties(CompanyProperties.isRestaurantEnabled, CompanyProperties.tablesEnforced)
      .pipe(takeUntil(this.subSinkService.destroy$))
      .subscribe((company) => {
        this.isGastroMode = company.isRestaurantEnabled;
        this.isTableForce = company.tablesEnforced;
        this.cdr.detectChanges();
      });

    this.invoicesFilterService
      .getInvoiceFilter()
      .pipe(
        skip(1),
        filter((data) => !!data?.tab),
        filter((data) => JSON.stringify(this.currentFilterSet) !== JSON.stringify(data)),
        takeUntil(this.subSinkService.destroy$)
      )
      .subscribe((data) => {
        this.currentFilterSet = { ...data };
        this.getInvoices();
      });

    this.updatesService
      .getUpdateEmmiterByType(UPDATES_TYPES.Invoice.type)
      .pipe(
        filter((updateData) => !!updateData?.length),
        takeUntil(this.subSinkService.destroy$)
      )
      .subscribe(() => {
        this.updateList();
      });

    this.invoicesFilterService
      .getUpdateInvoiceList()
      .pipe(takeUntil(this.subSinkService.destroy$))
      .subscribe(() => {
        this.getInvoices();
      });

    this.subSinkService.sink = this.invoicesFilterService.newTableInvoiceEvent.subscribe((data) => {
      data && this.newTableInvoice();
    });

    this.subSinkService.sink = this.invoicesFilterService.newInvoiceEvent.subscribe((data) => data && this.newInvoice());
    this.subSinkService.sink = this.cartService.tableChangedEvent.subscribe((data) => {
      if (data) {
        this.setTimeoutUtil.addVisualEffect().then(() => {
          this.tableChangeAnimUuid = data.invoice;
          this.cdr.detectChanges();
        });
        this.setTimeoutUtil.addVisualEffect(1300).then(() => {
          this.tableChangeAnimUuid = null;
          this.cdr.detectChanges();
        });
      }
    });
    this.subSinkService.sink = this.updatesService.onDemandUpdateStatus.subscribe((inProgress) => {
      this.paidInvoiceOnDemandInProgress = inProgress;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.invoicesFilterService.setInvoices([]);
  }

  public closeSlidingItem() {
    this.ionList?.closeSlidingItems();
  }

  public deleteInvoice(invoiceToDelete: Invoice) {
    const { uuid, gastronomyTable } = invoiceToDelete;

    if (gastronomyTable) {
      let activeOrNot = false;
      let currentUuid = gastronomyTable.uuid;
      if (this.currentFilterSet.table !== 'all') {
        currentUuid = this.itemList.length === 1 ? gastronomyTable.uuid : null;
      }
      if (this.activeInvoice.gastronomyTable) {
        activeOrNot = this.activeInvoice.uuid === uuid;
      }
      this.invoicesFilterService.emitDeleteInvoiceEvent({ active: activeOrNot, uuid: currentUuid });
    }

    invoiceToDelete.deleted = true;
    this.cartService
      .calculateGastronomyTableInvoices(invoiceToDelete)
      .catch((err) => this.logService.error('InvoiceList', 'deleteInvoice:calculateGastronomyTableInvoices error', err));

    this.logService.debug(
      'InvoiceList',
      `deleteInvoice ${JSON.stringify({
        msg: 'deleting of invoice from db',
        uuid: invoiceToDelete.uuid,
        invoiceEntries: invoiceToDelete.invoiceEntries,
        isDreft: invoiceToDelete.isDraft,
        isPaid: invoiceToDelete.isPaid,
        isGastroMode: this.isGastroMode,
        isTableForce: this.isTableForce,
      })}`
    );

    this.invoicesProvider
      .getCountByParams({ uuid })
      .pipe(
        filter((count) => !!count),
        switchMap(() => from(this.invoicesService.deleteInvoice(invoiceToDelete))),
        takeUntil(this.subSinkService.destroy$)
      )
      .subscribe(
        () => {
          this.updateList();
        },
        (e) => this.logService.error('InvoiceList', 'deleteInvoice:getCountByParams', e)
      );
  }

  public clickInvoiceHandler(invoice: InvoceInlist) {
    if (
      this.isGastroMode &&
      invoice.isDraft &&
      !invoice.isPaid &&
      this.currentFilterSet.tab !== 'paid' &&
      this.currentFilterSet.tab !== 'open'
    ) {
      if (
        (invoice.uuid === this.activeInvoice.uuid && invoice.invoiceEntries && invoice.invoiceEntries.length === 0) ||
        (invoice.uuid !== this.activeInvoice.uuid && this.activeInvoice.invoiceEntries && this.activeInvoice.invoiceEntries.length === 0)
      ) {
        this.logService.debug('InvoiceList', 'clickInvoiceHandler:should launch deleting of invoice');
        this.deleteInvoice(this.activeInvoice);
      }
    }
    setTimeout(() => this.invoicesFilterService.clickInvoiceHandler(invoice, true));
  }

  public updateList() {
    const end = this.loadMoreCount * this.activePage + this.loadMoreCount;
    this.updateItems(0, end);
  }

  protected search(source: string, activePage?: number): Promise<void> {
    return super
      .search(source, activePage)
      .then(() => {
        this.invoicesFilterService.setInvoices(this.itemList);
      })
      .catch((error) => {
        if (error?.message !== this.stopError) {
          this.logService.error('InvoiceList2', 'search', error);
        }
      });
  }

  protected updateItems(start: number, end: number): Promise<void> {
    return super
      .updateItems(start, end)
      .then(() => {
        this.invoicesFilterService.setInvoices(this.itemList);
        this.invoicesFilterService.emitUpdateEvent();
      })
      .catch((error) => {
        if (error?.message !== this.stopError) {
          this.logService.error('InvoiceList2', 'updateItems', error);
        }
      });
  }

  protected loadData({ start, end }: ILoadDataOptions): Promise<InvoceInlist[]> {
    const { tab, table } = this.currentFilterSet;
    return this.invoicesFilterService.getDataFromDb2(start, end).then((invoices) => {
      if (tab !== this.currentFilterSet.tab || table !== this.currentFilterSet.table) {
        throw new Error(this.stopError);
      }
      this.isLoading = false;
      return invoices;
    });
  }

  private async getInvoices() {
    this.handleSearchValue('');
  }

  private newTableInvoice() {
    const activeInvoice = new Invoice(this.activeInvoice);
    this.cartService.createInvoice();
    if (this.isGastroMode && this.currentFilterSet.entities && this.currentFilterSet.entities.table) {
      if (!activeInvoice.invoiceEntries.length) {
        this.logService.debug('InvoiceList', 'newTableInvoice:should launch deleting of invoice');
        this.deleteInvoice(activeInvoice);
      }
      this.setTimeoutUtil.waitTimeAndDo().then(() => {
        this.cartService.setTable(this.currentFilterSet.entities.table);
        this.notifyPopupsService.launchNotification('new-invoice', this.currentFilterSet.entities.table.name);
        this.navController
          .navigateRoot(ROUTE_URLS.collection)
          .catch((e) => this.logService.error('InvoiceList', 'newTableInvoice:navigateRoot:collection error', e));
      });
    }
  }

  private newInvoice() {
    const activeInvoice = new Invoice(this.activeInvoice);
    this.cartService.createInvoice();
    if (this.isGastroMode && activeInvoice.gastronomyTable) {
      if (!activeInvoice.invoiceEntries.length) {
        this.logService.debug('InvoiceList', `should launch deleting of invoice`);
        this.deleteInvoice(activeInvoice);
      }
    }
    this.setTimeoutUtil.waitTimeAndDo().then(() => {
      this.isGastroMode && this.notifyPopupsService.launchNotification('new-invoice');
      this.navController
        .navigateRoot(ROUTE_URLS.collection)
        .catch((e) => this.logService.error('InvoiceList', 'newInvoice:navigateRoot:collection', e));
    });
  }
}
