<a tappable (click)="linkTap()">
  <i *ngIf="posMode === receiptPrinterModeTypes.POS" class="custom-icon custom-icon-paid-invoices-epson"></i>
  <ng-container *ngIf="showUnprintedKitchenItems && posMode === receiptPrinterModeTypes.KITCHEN">
    <pos-badge *ngIf="unprintedKitchenItems" class="unprinted-kitchen-items-indicator" [value]="unprintedKitchenItems"></pos-badge>
    <i class="course-fire-icon" [class.disabled]="!unprintedKitchenItems"></i>
  </ng-container>
  <i *ngIf="!showUnprintedKitchenItems && posMode === receiptPrinterModeTypes.KITCHEN" class="course-fire-icon"></i>
  <i *ngIf="posMode === receiptPrinterModeTypes.BILL" class="bill-icon"></i>
  <i *ngIf="posMode === receiptPrinterModeTypes.CASH_REGISTER" class="custom-icon custom-icon-pay-unlock"></i>
  <span *ngIf="!onlyIcon && posMode !== receiptPrinterModeTypes.BILL" class="link-text">{{ 'invoice_print_receipt' | translate }}</span>
  <span *ngIf="!onlyIcon && posMode === receiptPrinterModeTypes.BILL" class="link-text bill-text">{{
    'invoice_print_bill' | translate
  }}</span>
</a>
