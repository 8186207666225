export class InvoiceTax {
  taxRate: string;
  netAmount: number;
  taxAmount: number;
  totalAmount: number;

  constructor(data: any) {
    this.taxRate = data.taxRate || null;
    this.netAmount = parseFloat(data.netAmount) || 0;
    this.taxAmount = parseFloat(data.taxAmount) || 0;
    this.totalAmount = parseFloat(data.totalAmount) || 0;
  }
}
