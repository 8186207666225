import { Injectable } from '@angular/core';
import { SecurityService } from '@pos-common/services/system/security.service';
import { UuidService } from '@pos-common/services/utils/uuid.utils';
import { IDeviceReportingRegistration, IDeviceReportingService, IServiceWithReporting } from './device-report.interface';
import { DeviceReportingRegistration } from './device-reporting-registration';
import { ReportingEvent, ReportingDevice, ReportingStatusEventType, ReportingEventExtra } from './device-report.models';
// import { DeviceReportingSync } from './device-report.sync';
import { DeviceReportingStore } from './device-report.store';
import * as moment from 'moment/moment';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
// import { PlatformService } from '../platform/platform.service';

@Injectable()
export class DeviceReportingService implements IDeviceReportingService {
  private static readonly LAST_REPORTED_KEY = 'DeviceReportPeriodic_LastReported';

  private readonly services = new Map<
    string,
    {
      name: string;
      registration: IDeviceReportingRegistration<string>;
      service: IServiceWithReporting;
    }
  >();

  constructor(
    private readonly localStorage: LocalStorage,
    private readonly uuidService: UuidService,
    private readonly securityService: SecurityService,
    private readonly store: DeviceReportingStore // private readonly sync: DeviceReportingSync, // private platformService: PlatformService
  ) {
    // if (PAYMASH_PROFILE.isLoggerActive && this.platformService.isNativePlatform) {
    //   this.sync.start();
    //   DeviceReportPeriodic.start(this);
    // }
  }

  registerService<TEventType extends string>(name: string, service: IServiceWithReporting): IDeviceReportingRegistration<TEventType> {
    // const registration = new DeviceReportingRegistration<TEventType>(this, name);
    const registration = new DeviceReportingRegistration<TEventType>();
    this.services.set(name, { name, registration, service });
    return registration;
  }

  createEvent(eventType: string, serviceName: string, device: ReportingDevice, extra: ReportingEventExtra) {
    const event: ReportingEvent = {
      uuid: this.uuidService.generate(),
      source: {
        posIdentifier: this.securityService.getDeviceIdentifier(),
      },
      type: eventType,
      service: serviceName,
      created: new Date(),
      device,
      extra,
      internal: { saved: false },
    };
    return event;
  }

  report(...events: ReportingEvent[]) {
    this.reportInternal(events).then();
  }

  private async reportInternal(events: ReportingEvent[]) {
    await this.store.addEvents(events);
  }

  createFullReport() {
    const services = Array.from(this.services.values());
    const events = new Array<ReportingEvent>();

    services.forEach(({ name: serviceName, service }) => {
      const reports = service.createReportForAllDevices();
      reports.forEach(({ device, extra }) => {
        const eventType: ReportingStatusEventType = 'status';
        const event = this.createEvent(eventType, serviceName, device, extra);
        events.push(event);
      });
    });

    if (!events.length) return;

    this.report(...events);

    this.setLastFullReportDate(new Date());
  }

  private setLastFullReportDate(date: Date) {
    const isoString = moment(date).toISOString();
    this.localStorage.set(DeviceReportingService.LAST_REPORTED_KEY, isoString);
  }

  getLastFullReportDate(): Date | undefined {
    const isoString = this.localStorage.get(DeviceReportingService.LAST_REPORTED_KEY);
    if (!isoString) return undefined;
    return moment(isoString).toDate();
  }
}
