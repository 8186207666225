import {
  Component,
  ElementRef,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Invoice } from '../../../../common/classes/invoice.class';
import { CartService } from '../../../../common/services/system/cart.service';
import { IndividualProductModal } from '../../individual-product-modal/individual-product-modal.component';
import { ModalService } from '../../../../common/services/system/modal.service';
import { TableEnforceService } from '../../../../common/services/system/table-select-enforcement.service';
import { CollectionViewService } from '../../../../common/services/system/collection-view.service';
import { IonicModule, NavController, mdTransitionAnimation } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CHECKOUT_STEPS } from '../../../../common/constants/checkout-steps.const';
import { GoogleAnalyticsService } from '../../../../common/services/system/google-analitycs.service';
import { LogService } from '../../../../common/services/system/logger/log.service';
import { PosSettingsService } from '../../../../common/services/system/pos-settings.service';
import { NotifyPopupsService } from '../../../../common/services/system/notify-popups.service';
import { PaymentMethod } from '../../../../common/classes/payment-method.class';
import { InvoiceProcessingService } from '../../../../common/services/system/invoice-processing/invoice-processing.service';
import { ReceiptAutoPrintingService } from '../../../../common/services/system/receipt-auto-printing/receipt-auto-printing.service';
import { ROUTE_URLS } from '../../../../common/constants/route-urls.const';
import { SecurityService } from '../../../../common/services/system/security.service';
import { INVOICE_TYPES } from '@pos-common/constants/invoice-types';
import { GiftCard } from '@pos-modules/assing-gift-card/gift-card';
import { DefaultPaymentMethods } from '@pos-common/constants/default-payment-methods.enum';
import { GiftCardProvider } from '@pos-modules/assing-gift-card/gift-card.provider';
import { InvoicesService } from '@pos-common/services/system/invoices.service';
import { MultipleGuestsService } from '@pos-common/services/system/multiple-guests/multiple-guests.service';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';
import { CameraScannerService } from '@pos-common/services/system/camera-scanner.service';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { PaymentProcessingService } from '@pos-common/components/payment-processing/payment-processing.service';
import { PaymentProcessingActions } from '@pos-common/components/payment-processing/payment-processing-actions.enum';
import { PrinterLinkModule } from '@pos-common/components/printer-link/printer-link.component';
import { CustomCurrencyPipeModule } from '@pos-common/pipes';
import { CompanyProperties } from '@pos-common/constants';

@Component({
  selector: 'cart-buttons',
  templateUrl: './cart-buttons.component.html',
  styleUrls: ['./cart-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SubSinkService],
})
export class CartButtonsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public activeInvoice: Invoice;
  @Input() public isConfirmationScreen: boolean = false;
  public isGastroModeEnabled: boolean = false;
  public isBarcodeScannerOpened: boolean = false;
  private invoiceDiscountModal: any;
  private individualProductModal: any;
  public cartButtonTitle: string;
  public isPayButtonPressed: boolean = false;
  public amountForButton: number = 0;
  public isButtonDisabled = false;

  public oneClickPaymentStatus: boolean = false;
  public defaultPaymentMethod: PaymentMethod;
  public isMobile: boolean = false;
  private selectedGiftCard: GiftCard = null;
  private rollbackInvoiceBeforeSplit: Invoice = null;

  constructor(
    private cameraScannerService: CameraScannerService,
    private cartService: CartService,
    private modalService: ModalService,
    private tableEnforceService: TableEnforceService,
    private collectionViewService: CollectionViewService,
    private elementRef: ElementRef,
    private navController: NavController,
    private translateService: TranslateService,
    private GoogleAnalyticsService: GoogleAnalyticsService,
    private posSettingsService: PosSettingsService,
    private invoiceProcessingService: InvoiceProcessingService,
    private receiptAutoPrintingService: ReceiptAutoPrintingService,
    private securityService: SecurityService,
    private notifyPopupsService: NotifyPopupsService,
    private giftCardService: GiftCardProvider,
    private invoicesService: InvoicesService,
    private multipleGuestsService: MultipleGuestsService,
    private platformService: PlatformService,
    private paymentProcessingService: PaymentProcessingService,
    private setTimeoutUtil: SetTimeoutUtil,
    private renderer: Renderer2,
    private subSinkService: SubSinkService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.isMobile = this.platformService.isMobile;
    this.oneClickPaymentStatus = this.posSettingsService.getOneClickPaymentStatus();
    this.defaultPaymentMethod = this.posSettingsService.getDefaultPaymentMethod();
    this.setButtonData(this.activeInvoice);
    const loggedCompanyData = this.securityService.getLoggedCompanyData();
    this.isGastroModeEnabled = loggedCompanyData?.isRestaurantEnabled ?? false;
    this.subSinkService.sink = this.cameraScannerService.getBarcodeScannerOpenedStateEvent().subscribe((data) => {
      this.isBarcodeScannerOpened = data;
    });
    this.securityService
      .observableCompanyProperties(CompanyProperties.isRestaurantEnabled)
      .pipe(this.subSinkService.takeUntilDestroy())
      .subscribe((company) => {
        this.isGastroModeEnabled = company.isRestaurantEnabled;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const activeInvoiceChanges = changes.activeInvoice;
    if (activeInvoiceChanges && !activeInvoiceChanges.isFirstChange()) {
      this.setButtonData(activeInvoiceChanges.currentValue);
    }
  }

  ngOnDestroy() {
    if (this.invoiceDiscountModal) this.invoiceDiscountModal.dismiss();
  }

  private setButtonData(invoice: Invoice) {
    if (invoice.invoiceType === INVOICE_TYPES.CANCELLATION) {
      return;
    }
    this.activeInvoice = new Invoice(invoice);
    const isAmountForPay = this.isConfirmationScreen || (!this.isMobile && this.oneClickPaymentStatus && this.activeInvoice.isNet);
    this.amountForButton = isAmountForPay ? this.activeInvoice.getAmountForPay() : this.activeInvoice.refundedAmount;
    this.setCartStatusClass(this.activeInvoice);
    this.setButtonTitle();
    this.isButtonDisabled = this.collectionViewService.paySeparatelyModeActive && this.activeInvoice.totalProductsCount === 0;
  }

  private setButtonTitle() {
    if (this.activeInvoice) {
      const { totalProductsCount } = this.activeInvoice;
      if (this.activeInvoice.isRefund) {
        this.cartButtonTitle = this.getTranslateButtonTitle('cart_refund_product', totalProductsCount);
      } else {
        this.cartButtonTitle = this.getTranslateButtonTitle('cart_pay_product', totalProductsCount);
      }
    }
    if (this.oneClickPaymentStatus && ((this.isConfirmationScreen && this.isMobile) || !this.isMobile)) {
      this.cartButtonTitle = `${this.translateService.instant(this.defaultPaymentMethod.name)}`;
    }
  }

  private getTranslateButtonTitle(translate: string, totalProductsCount: number) {
    translate = totalProductsCount === 1 ? `${translate}s` : translate;
    return this.translateService.instant(translate).replace('xxx', this.activeInvoice.totalProductsCount);
  }

  private setCartStatusClass(activeInvoice: Invoice) {
    if (activeInvoice?.totalProductsCount) {
      this.renderer.addClass(this.elementRef.nativeElement, 'cartNotEmpty');
      return;
    }
    this.renderer.removeClass(this.elementRef.nativeElement, 'cartNotEmpty');
  }

  public async clickBtnInvoiceDiscountModal() {
    this.closeBarcodeScanner();
    if (this.tableEnforceService.checkForTableEnforcementAndShowAlert() || this.cartService.checkPartialPaymentInInvoice()) {
      return;
    }
    this.invoiceDiscountModal = await this.cartService.openInvoiceDiscountModal(this.activeInvoice);
  }

  public async openIndividualProductModal(): Promise<any> {
    this.closeBarcodeScanner();
    if (this.tableEnforceService.checkForTableEnforcementAndShowAlert() || this.cartService.checkPartialPaymentInInvoice()) {
      return;
    }
    this.individualProductModal = await this.modalService.presentModal(IndividualProductModal, { invoiceEntry: null }, {});
    return await this.individualProductModal.present();
  }

  public async goToCheckout() {
    if (this.isPayButtonPressed) {
      return;
    }
    this.GoogleAnalyticsService.trackEvent(
      'PayButtonPressed',
      'payButtonPressed',
      'numOfProducts',
      this.activeInvoice.invoiceEntries.length.toString()
    );
    this.isPayButtonPressed = true;
    this.closeBarcodeScanner();
    await this.setTimeoutUtil.addVisualEffect(200);

    if (this.isConfirmationScreen) {
      this.collectionViewService.setCurrentSidebarSlide(CHECKOUT_STEPS.MOBILE_CHECKOUT);
      this.isPayButtonPressed = false;
      return;
    }

    this.navController
      .navigateForward(ROUTE_URLS.checkout, { animation: mdTransitionAnimation })
      .then(() => {
        this.isPayButtonPressed = false;
      })
      .catch((err) => {
        this.logService.error('CartButtonsComponent', 'goToCheckout:navController:push:CheckoutPage', err);
      });
  }

  public continueWithoutCheckout() {
    if (!this.activeInvoice.isWebshop && this.tableEnforceService.checkForTableEnforcementAndShowAlert()) {
      return;
    }
    if (this.oneClickPaymentStatus && ((this.isConfirmationScreen && this.isMobile) || !this.isMobile)) {
      this.payInvoice();
      return;
    }
    this.goToCheckout();
  }

  public continueWithCheckout() {
    if (!this.activeInvoice.isWebshop && this.tableEnforceService.checkForTableEnforcementAndShowAlert()) {
      return;
    }
    this.goToCheckout();
  }

  private payInvoice() {
    if (this.isPayButtonPressed) return;
    this.isPayButtonPressed = true;
    this.closeBarcodeScanner();
    if (this.defaultPaymentMethod.method === DefaultPaymentMethods.GIFTCARD) {
      this.openGiftCardModal();
      return;
    }
    this.goToSuccessPage();
  }

  private openGiftCardModal() {
    this.selectedGiftCard = null;
    const isRequredPositiveBalance = this.activeInvoice.amount >= 0;
    this.giftCardService
      .openAssignGiftCardModal(null, { expired: true, balance: isRequredPositiveBalance })
      .then((giftCard) => {
        if (giftCard) {
          this.selectedGiftCard = giftCard;
          this.goToSuccessPage();
          return;
        }
        this.isPayButtonPressed = false;
      })
      .catch((error) => {
        this.logService.error('CheckoutAside', `openAssignGiftCardModal`, error);
      });
  }

  private async goToSuccessPage() {
    const { isSeparated } = this.activeInvoice;
    if (isSeparated) {
      this.rollbackInvoiceBeforeSplit = new Invoice(this.activeInvoice);
      const invoiceEntryGuests = this.activeInvoice.getGuestInvoiceEntries();
      const { firstInvoice, secondInvoice } = this.invoicesService.splitInvoice(this.activeInvoice);
      this.activeInvoice = firstInvoice;
      if (invoiceEntryGuests.length > 1) {
        await this.cartService.setCustomerFromGuestToInvoiceCustomer(secondInvoice);
      }
      this.cartService.setSeparetedInvoice(secondInvoice);
    }
    this.payActiveInvoice();
  }

  private async payActiveInvoice() {
    await this.setTimeoutUtil.addVisualEffect(200);
    const paymentMethod = this.defaultPaymentMethod.method;
    const { amount, uuid } = this.activeInvoice;
    this.logService.info(
      'CartButtonsComponent',
      `payInvoice: oneClickPayment ${paymentMethod} with amount ${amount} and amountGiven ${amount} will been added to invoice ${uuid}`
    );

    this.cartService
      .addPaymentToInvoice(this.activeInvoice, paymentMethod, amount, amount, this.selectedGiftCard)
      .then((invoice) => this.multipleGuestsService.deleteEmptyGuestsFromPaidInvoice(invoice))
      .then((invoice) => this.invoiceProcessingService.processPaidInvoice(invoice))
      .then((invoice) => {
        this.receiptAutoPrintingService.processAutoPrinting(invoice, paymentMethod);
        this.paymentProcessingService.dispatchAction(PaymentProcessingActions.succeed);
        this.rollbackInvoiceBeforeSplit = null;
        this.isPayButtonPressed = false;
        this.notifyPopupsService.launchNotification('invoice-paid');
        if (this.isConfirmationScreen) {
          this.collectionViewService.setCurrentSidebarSlide(CHECKOUT_STEPS.COLLECTION_VIEW);
          return;
        }
        const loggedCompanyData = this.securityService.getLoggedCompanyData();
        return loggedCompanyData.isRequireOnlineInvoiceProcessing ? 200 : 0;
      })
      .then((timeout) => this.setTimeoutUtil.waitTimeAndDo(timeout))
      .then(() => this.cartService.setDataAfterSuccessPay())
      .catch((e) => {
        this.isPayButtonPressed = false;
        this.logService.error('CartButtonsComponent', 'payInvoice', e);
        this.rollbackActiveInvoice();
      });
  }

  private rollbackActiveInvoice() {
    if (!this.rollbackInvoiceBeforeSplit) {
      return;
    }
    this.activeInvoice = this.rollbackInvoiceBeforeSplit;
    this.rollbackInvoiceBeforeSplit = null;
  }

  private closeBarcodeScanner() {
    if (!this.isBarcodeScannerOpened) {
      return;
    }
    this.cameraScannerService.closeBarcodeScanner();
  }
}
@NgModule({
  imports: [IonicModule, TranslateModule.forChild(), PrinterLinkModule, CustomCurrencyPipeModule],
  declarations: [CartButtonsComponent],
  exports: [CartButtonsComponent],
})
export class CartButtonsComponentModule {}
