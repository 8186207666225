import { Injectable } from '@angular/core';
import { IMyPosReceiptItem } from '@pos-common/services/system/receipt-printers/classes/receipt-builder.class';
import { PAYMENT_PROVIDERS, PAYMENT_TRANSACTION } from '@pos-common/constants/payment-providers.const';
import { PaymentResult } from '@pos-common/classes/payment-result.class';
import { PaymentProcessingService } from '@pos-common/components/payment-processing/payment-processing.service';
import { MyPosTransactionCodeError, MyPosTransactionMessageError } from './constants/my-pos-transaction-errors.enum';
import { PaymentProcessingActions } from '@pos-common/components/payment-processing/payment-processing-actions.enum';
import { TranslateService } from '@ngx-translate/core';
import {
  IMyPosInfo,
  MyPosN5Plugin,
  IMyPosTransactionResult,
  MyPosScannerResult,
  MyposN5PluginError,
  MyPosScannerType,
} from '@paymash/capacitor-mypos-plugin';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { Observable } from 'rxjs';
import { MyPosMakeTransactionOptions } from './my-pos-types';

@Injectable()
export class MyPosFacade {
  constructor(
    private paymentProcessingService: PaymentProcessingService,
    private translateService: TranslateService,
    private logService: LogService
  ) {}

  info(): Promise<IMyPosInfo> {
    return MyPosN5Plugin.info();
  }

  pay(options: MyPosMakeTransactionOptions): Promise<IMyPosTransactionResult> {
    const { amount, currency, invoiceUuid } = options;
    return MyPosN5Plugin.pay({
      amount,
      currency,
      paymentMethod: undefined,
      foreignTransactionId: invoiceUuid,
    });
  }

  refund(options: MyPosMakeTransactionOptions): Promise<IMyPosTransactionResult> {
    const { amount, currency, invoiceUuid } = options;
    return MyPosN5Plugin.refund({
      amount: Math.abs(amount),
      currency,
      paymentMethod: undefined,
      foreignTransactionId: invoiceUuid,
    });
  }

  print(printData: IMyPosReceiptItem[]): Promise<void> {
    return MyPosN5Plugin.print(printData);
  }

  startScanner(scannerType: MyPosScannerType): Observable<MyPosScannerResult> {
    return MyPosN5Plugin.startScanner(scannerType);
  }

  openCashDrawer(): Promise<void> {
    return MyPosN5Plugin.openCashDrawer();
  }

  makeTransaction(options: MyPosMakeTransactionOptions): Promise<PaymentResult> {
    return new Promise((resolve, reject) => {
      const { transactionType, amount } = options;
      Promise.resolve()
        .then(() => {
          if (transactionType === PAYMENT_TRANSACTION.PAYMENT_REFUND || amount < 0) {
            return this.refund(options);
          }
          return this.pay(options);
        })
        .then((transactionResult: IMyPosTransactionResult) => {
          const paymentResult = new PaymentResult(PAYMENT_PROVIDERS.MYPOS);
          paymentResult.setPaymentResultData(transactionResult);
          resolve(paymentResult);
        })
        .catch((error: MyposN5PluginError) => {
          this.logService.error('MyPosFacade', 'makeTransaction', error);
          let { status, message } = error;
          message = MyPosTransactionMessageError[status] || message;
          if (status === MyPosTransactionCodeError.USER_CANCEL_PAYMENT) {
            reject(message);
            return;
          }
          this.paymentProcessingService.init(undefined, () => {
            reject(message);
          });
          this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, {
            message: this.translateService.instant(message),
            retryButtonOff: true,
          });
        });
    });
  }
}
