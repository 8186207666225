export const REGEXPS = {
  ONLY_DASH: /^[-]+$/g,
  ANY_SPACES: /\s+/g,
  TWO_START_SPACES: /^\s{2}/g,
  TWO_END_SPACES: /\s{2}$/g,
  MORE_THREE_SPACES: /\s{3,}/g,
  TWO_LAST_ENTERS: /[\n\r]{2}$/g,
  TWO_ENTERS: /[\n\r]{2}/g,
  ONLY_NUMBERS: /^\d+$/g,
  AMOUNT: /[^\d,.]+/g,
  HEX: /^[0-9A-Fa-f]{6}$/,
  REMOTE_IMAGE_SERVER: /.s3.amazonaws.com/g,
  EMAIL_VALIDATION: /^[\w+-.]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/,
};
