import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IP_REGEX } from '../../../common/constants/ip-address-validation.const';
import { IngenicoService } from '../../../common/services/system/ingenico.service';
import { AlertService } from '../../../common/services/system/alert.service';
import { TimApiService } from '../../../common/services/system/timApi.service';
import { LocalStorage } from '../../../common/services/utils/localstorage.utils';
import { LoadingService } from '../../../common/services/system/loading.service';
import { LogService } from '../../../common/services/system/logger/log.service';
import { StorageKeys } from '../../../common/constants/storage.const';
import { MyPosMiniFacade } from '../../../../src/modules/my-pos-mini/my-pos-mini.facade';
import { SecurityService } from '../../../common/services/system/security.service';
import { IActiveTerminal, IDeviceSettings } from '../../../common/interfaces';
import { MyPosService } from '@pos-common/services/system/devices/my-pos/my-pos.service';
import { ReceiptPrintersService } from '@pos-common/services/system/receipt-printers';
import { PermissionService } from '@pos-common/services/system/permission/permission.service';
import { IShowMessageOneTime } from '@pos-common/interfaces/show-message-one-time.interface';
import { AlertButton } from '@ionic/core';
import { IStoragePermission } from '@pos-common/interfaces/storage-permission.interface';
import { PermissionTypes } from '@pos-common/constants/permission-types.enum';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { TerminalNames } from '@pos-common/constants/terminal-names.enum';
import { PosSettingsService } from '@pos-common/services/system/pos-settings.service';
import { AutofillInputService } from '@pos-common/services/utils/autofill-input/autofill-input.service';
import { AdyenTerminalProvider } from '@pos-common/services/system/adyen';
import { PrinterType } from '@pos-common/services/system/receipt-printers/enum/printer-type.enum';
import { PaymentService } from '@pos-common/services/system/payment.service';
import { PAYMENT_METHOD_NAMES } from '@pos-common/constants';
import { TimApiError } from '@paymash/capacitor-tim-api-plugin';

@Component({
  selector: 'terminals',
  templateUrl: './terminals.component.html',
  styleUrls: ['./terminals.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Terminals {
  public activeTerminal: IActiveTerminal = null;
  public terminalPrinter: boolean = false;
  public hasMyPosMiniPlugin: boolean = false;
  public isMyPosDevice: boolean = false;
  public TerminalNames = TerminalNames;
  private opiConnectFirstConnect: boolean = true;
  private OPITerminalIpAddress: any;
  private deviceSettings: IDeviceSettings;
  public ingenicoIsConnecting: boolean;

  constructor(
    public translateService: TranslateService,
    private alertService: AlertService,
    private timApiService: TimApiService,
    private ingenicoService: IngenicoService,
    private loadingService: LoadingService,
    private localStorage: LocalStorage,
    private myPosMiniFacade: MyPosMiniFacade,
    private SecurityService: SecurityService,
    private myPosService: MyPosService,
    public receiptPrintersService: ReceiptPrintersService,
    private permissionService: PermissionService,
    private platformService: PlatformService,
    private posSettingsService: PosSettingsService,
    private autofillInputService: AutofillInputService,
    private paymentService: PaymentService,
    private adyenTerminalProvider: AdyenTerminalProvider,
    private logService: LogService
  ) {
    this.hasMyPosMiniPlugin = true;
  }

  ngOnInit() {
    const opiTerminal = this.ingenicoService.getCurrentTerminal();
    const sixTerminal = this.timApiService.getTerminalInstantly();
    const activeTerminal = this.localStorage.get(StorageKeys.activeTerminal);
    this.deviceSettings = this.posSettingsService.getDeviceSettings();
    this.isMyPosDevice = this.myPosService.isMyPosDevice;
    if (activeTerminal) {
      this.activeTerminal = JSON.parse(activeTerminal);
      if (this.activeTerminal.name) {
        switch (this.activeTerminal.name) {
          case TerminalNames.SIX:
            if (!sixTerminal) this.activeTerminal = null;
            break;
          case TerminalNames.OPI:
            opiTerminal ? (this.terminalPrinter = this.ingenicoService.useIngenicoPrinter.get()) : (this.activeTerminal = null);
            break;
        }
      }
    }
  }

  public connectTo(activeTerminal: IActiveTerminal) {
    this.activeTerminal = activeTerminal;
    this.localStorage.setObject(StorageKeys.activeTerminal, activeTerminal);
    this.paymentService.createNewPaymentMethod(activeTerminal.title);
  }

  public connectTerminal(terminalName: string) {
    switch (terminalName) {
      case TerminalNames.PAYMASH_PAY:
        this.connectToAdyenTerminal();
        break;
      case TerminalNames.MINI:
        this.checkLocationPermissionForMyPos();
        break;
      case TerminalNames.SUMUP:
        this.connectToSumup();
        break;
      case TerminalNames.SIX:
        this.connectToMprimeTerminal();
        break;
      case TerminalNames.OPI:
        this.connectToOpiTerminal();
        break;
    }
  }

  public async disconnect(param) {
    let switchCase = (param) => {
      this.activeTerminal = null;
      this.localStorage.remove(StorageKeys.activeTerminal);
      switch (param) {
        case TerminalNames.OPI:
          this.disconnectOpiTerminal();
          break;
        case TerminalNames.SIX:
          this.disconnectMprimeTerminal();
          break;
        case TerminalNames.MINI:
          this.disconnectMyPosMiniTerminal();
          break;
      }
    };
    const dialog = await this.alertService.create({
      header: this.translateService.instant('settings_app_reset_confirm_two_text'),
      buttons: [
        { text: this.translateService.instant('common_cancel'), role: 'cancel' },
        { text: this.translateService.instant('common_disconnect'), handler: () => switchCase(param) },
      ],
    });
    dialog.present().catch((err) => this.logService.error('Terminals', 'connectToTerminal:dialog:present', err));
  }

  public connectToOpiTerminal() {
    if (this.opiConnectFirstConnect) {
      this.ingenicoService
        .startIngenicoIpCheck()
        .then((ip) => {
          if (ip) {
            this.connectTo({
              name: TerminalNames.OPI,
              title: PAYMENT_METHOD_NAMES.OPI,
              connected: true,
              ip: ip + ':5577',
              printer: true,
            });
          } else {
            this.manualConnectToOpiTerminal();
          }
        })
        .catch(() => {});
      this.opiConnectFirstConnect = false;
    } else {
      this.manualConnectToOpiTerminal();
    }
  }

  public async manualConnectToOpiTerminal() {
    let ingenicoConnectionDialog = await this.alertService.create({
      header: 'Terminal OPI',
      message: this.translateService.instant('settings_ccv_fly_enter_terminal_ip_and_port'),
      inputs: [
        {
          name: 'ingenicoTerminalConnectionIp',
          placeholder: this.translateService.instant('ingenico_ip_of_terminal'),
          type: 'text',
          value: this.OPITerminalIpAddress,
        },
        {
          name: 'ingenicoTerminalConnectionPort',
          placeholder: this.translateService.instant('ingenico_port_number'),
          type: 'number',
          value: '5577',
        },
      ],
      buttons: [
        {
          text: this.translateService.instant('common_cancel'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('common_save'),
          handler: (data) => {
            if (IP_REGEX.test(data.ingenicoTerminalConnectionIp)) {
              this.OPITerminalIpAddress = data.ingenicoTerminalConnectionIp;
            } else {
              this.OPITerminalIpAddress = '';
            }
            if (data.ingenicoTerminalConnectionIp && data.ingenicoTerminalConnectionPort) {
              this.ingenicoIsConnecting = true;
              if (window['cordova']) {
                this.ingenicoService
                  .connectToIngenicoTerminal(data.ingenicoTerminalConnectionIp, parseInt(data.ingenicoTerminalConnectionPort))
                  .then((data) => {
                    this.ingenicoIsConnecting = false;
                    this.connectTo({
                      name: TerminalNames.OPI,
                      title: PAYMENT_METHOD_NAMES.OPI,
                      connected: true,
                      ip: data.ipAddress + ':' + data.port,
                      printer: true,
                    });
                  })
                  .catch(() => {
                    this.messageComposer('ingenico_no', 'manualConnectToOpiTerminal');
                    this.ingenicoIsConnecting = false;
                    return false;
                  });
              } else {
                this.messageComposer('ingenico_no', 'manualConnectToOpiTerminal');
                this.ingenicoIsConnecting = false;
                return false;
              }
            } else {
              this.messageComposer('ingenico_please_enter_ip_and_port', 'manualConnectToOpiTerminal');
              this.ingenicoIsConnecting = false;
              return false;
            }
          },
        },
      ],
    });

    ingenicoConnectionDialog
      .present()
      .catch((err) => this.logService.error('Terminals', 'manualConnectToOpiTerminal:ingenicoConnectionDialog:present', err));
  }

  private async messageComposer(message: string, methodName: string) {
    const alert = await this.alertService.create({
      header: this.translateService.instant('common_error'),
      message: this.translateService.instant(message),
      buttons: [{ text: 'OK', role: 'cancel' }],
    });
    alert.present().catch((err) => this.logService.error('Terminals', `${methodName}:alert:present`, err));
  }

  public disconnectOpiTerminal() {
    this.ingenicoService.disconnectIngenicoTerminal().catch((err) => this.logService.error('Terminals', 'disconnectOpiTerminal', err));
  }

  public useTerminalPrinter(useTerminalPrinter: boolean) {
    if (this.activeTerminal.name === TerminalNames.OPI) {
      this.ingenicoService.useIngenicoPrinter.set(useTerminalPrinter);
    }
  }

  public connectToSumup() {
    this.connectTo({
      name: TerminalNames.SUMUP,
      title: PAYMENT_METHOD_NAMES.SUMUP,
      connected: true,
    });
  }

  async checkLocationPermissionForMyPos() {
    try {
      if (!this.platformService.isAndroid || this.myPosService.isMyPosDevice) {
        return this.connectToMyPosMiniTerminal();
      }

      const locationPermission = await this.permissionService.checkLocationPermission();
      if (!locationPermission) {
        this.showLocationPermissionAlert();
        return;
      }

      const showMessageOneTime: IShowMessageOneTime = this.localStorage.getObject(StorageKeys.showMessageOneTime);
      if (locationPermission && !showMessageOneTime.showBackgroundLocationMessage) {
        this.showBackgroundLocationAlert();
        return;
      }

      this.connectToMyPosMiniTerminal();
    } catch (error) {
      this.logService.error('Terminals', 'checkLocationPermissionForMyPos', error);
    }
  }

  private async showLocationPermissionAlert() {
    try {
      const alert = await this.alertService.getLocationPermissionAlert();
      const [, acceptButton] = alert.buttons as AlertButton[];
      acceptButton.handler = async () => {
        const hasPermission = await this.permissionService.checkAndRequestPermission(PermissionTypes.ACCESS_COARSE_LOCATION);
        const permissions: IStoragePermission = this.localStorage.getObject(StorageKeys.permissions);
        permissions.location = hasPermission;
        this.localStorage.setObject(StorageKeys.permissions, permissions);
        this.showBackgroundLocationAlert();
      };
      await alert.present();
    } catch (error) {
      this.logService.error('Terminals', 'showLocationAlert', error);
    }
  }

  private async showBackgroundLocationAlert() {
    try {
      const showMessageOneTime: IShowMessageOneTime = this.localStorage.getObject(StorageKeys.showMessageOneTime);
      if (showMessageOneTime.showBackgroundLocationMessage && !this.platformService.isAndroid10AndMore) {
        this.connectToMyPosMiniTerminal();
        return;
      }

      const alert = await this.alertService.getBackgroundLocationAlert();
      const [cancelButton] = alert.buttons as AlertButton[];
      cancelButton.handler = () => {
        this.connectToMyPosMiniTerminal();
      };
      await alert.present();
      showMessageOneTime.showBackgroundLocationMessage = true;
      this.localStorage.setObject(StorageKeys.showMessageOneTime, showMessageOneTime);
    } catch (error) {
      this.logService.error('Terminals', 'showBackgroundLocationAlert', error);
    }
  }

  private async connectToMyPosMiniTerminal() {
    try {
      const company = this.SecurityService.getLoggedCompanyData();
      const currency = company?.locale?.currency || null;
      const { currentLang: language } = this.translateService;
      const hasPrinter = await this.myPosMiniFacade.connectAndGetPrinter(currency, language);
      this.connectTo({
        name: TerminalNames.MINI,
        title: PAYMENT_METHOD_NAMES.MYPOS,
        connected: true,
        printer: hasPrinter,
      });
      this.receiptPrintersService.printersList;
    } catch (error) {
      this.activeTerminal = null;
      this.localStorage.remove(StorageKeys.activeTerminal);
      this.logService.error('Terminals', 'connectToMyposMiniTerminal', error);
    }
  }

  private disconnectMyPosMiniTerminal() {
    const savedPrinters = this.receiptPrintersService.getSavedPrinters();
    const myPosComboPrinter = savedPrinters.find((item) => item.deviceType === PrinterType.MyPosMini);
    if (myPosComboPrinter) {
      this.receiptPrintersService.removeFromSaved(myPosComboPrinter);
    }
  }

  public isDevice(name) {
    return this.activeTerminal.name === name;
  }

  public async connectToMprimeTerminal() {
    const mprimeConnectionDialog = await this.alertService.create({
      header: this.translateService.instant('settings_m_prime_terminal_section_title'),
      message: this.translateService.instant('settings_m_prime_enter_terminal_id'),
      inputs: [
        {
          name: 'mprimeTerminalId',
          placeholder: '123456789',
          type: 'number',
          cssClass: 'mprimeTerminalId',
        },
        {
          name: 'mprimeIp',
          placeholder: this.translateService.instant('ingenico_ip_of_terminal'),
          type: 'text',
          cssClass: 'mprimeIp',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {},
        },
        {
          text: 'Save',
          handler: (data) => {
            const terminalId: string = data['mprimeTerminalId'];
            const ipAddress: string = data['mprimeIp'];
            if (ipAddress && !IP_REGEX.test(ipAddress)) return;
            if (!terminalId && !ipAddress) return;
            this.timApiService.saveTerminal(terminalId, ipAddress);
            this.loadingService.showLoadingItem();
            const terminal = this.timApiService.getTerminalInstantly();
            this.timApiService
              .connectToTerminal(terminal)
              .then(() => {
                this.loadingService.hideLoadingItem();
                const activeTerminal: IActiveTerminal = {
                  name: TerminalNames.SIX,
                  title: PAYMENT_METHOD_NAMES.SIX,
                  connected: true,
                };
                if (terminalId) {
                  activeTerminal.id = terminalId;
                } else {
                  activeTerminal.ip = ipAddress;
                }
                this.saveDeviceSettings(terminalId, ipAddress);
                this.connectTo(activeTerminal);
              })
              .catch((err: TimApiError) => {
                this.loadingService.hideLoadingItem();
                this.timApiService.saveTerminal(null, null);
                this.logService.error('Terminals', 'connectToMprimeTerminal:handler:connectToTerminal', err);
                const message = this.timApiService.getTimApiExceptionMessage(err);
                this.messageComposer(message, 'connectToMprimeTerminal');
              });
          },
        },
      ],
    });
    mprimeConnectionDialog
      .present()
      .then(() => this.addAutofillTimApiAddress(mprimeConnectionDialog))
      .catch((err) => this.logService.error('Terminals', 'connectToMprimeTerminal:mprimeConnectionDialog:present', err));
  }

  useTippingOnTerminal(tipAllowed: boolean) {
    const { activeTerminal } = this;
    if (activeTerminal.name !== TerminalNames.SIX) {
      return;
    }
    activeTerminal.tipAllowed = tipAllowed;
    this.timApiService.toggleTippingOnTerminal(tipAllowed);
    this.connectTo(activeTerminal);
  }

  disconnectMprimeTerminal() {
    this.timApiService.disconnectFromTerminal().catch((err) => this.logService.error('Terminals', 'disconnectMprimeTerminal', err));
  }

  private addAutofillTimApiAddress(alert: HTMLIonAlertElement) {
    const { timApiId, timApiIp } = this.deviceSettings;
    if (timApiId) {
      this.autofillInputService.addAutofillToAlertInput(alert, 'mprimeTerminalId', timApiId);
    }
    if (timApiIp) {
      this.autofillInputService.addAutofillToAlertInput(alert, 'mprimeIp', timApiIp);
    }
  }

  private saveDeviceSettings(terminalId: string, ipAddress: string) {
    if (terminalId) {
      this.deviceSettings.timApiId = terminalId;
    }
    if (ipAddress) {
      this.deviceSettings.timApiIp = ipAddress;
    }
    this.posSettingsService.setDeviceSettings(this.deviceSettings);
  }

  private async connectToAdyenTerminal() {
    try {
      const terminal = await this.adyenTerminalProvider.connectToTerminal();
      if (!terminal) {
        return;
      }
      this.connectTo({
        connected: true,
        name: TerminalNames.PAYMASH_PAY,
        title: PAYMENT_METHOD_NAMES.PAYMASH_PAY,
        ...terminal,
      });
    } catch (error) {
      this.logService.error('Terminals', 'connectToAdyenTerminal:connectToTerminal', error);
    }
  }
}
