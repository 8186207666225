<div class="common-invoice-entry">
  <div (click)="handleOnClick($event)" (swipe)="handleOnSwipe($event)" tappable>
    <div class="img-holder">
      <p class="item-placeholder">
        <i class="custom-icon custom-icon-discount-cash" *ngIf="discount"></i>
        <i class="custom-icon custom-icon-discount-percent" *ngIf="discountPercentage"></i>
      </p>
    </div>
    <div class="entry-name">
      <span class="name">{{ name | translate }}</span>
    </div>
    <div class="item-info">
      <p class="item-total">{{ totalInvoiceDiscountAmount | CustomCurrency: false }}</p>
      <p class="item-quantity-price" [attr.amount]="'1x'">
        <span class="item-price">
          <span *ngIf="discount">{{ discount | CustomCurrency: false }}</span>
          <span *ngIf="discountPercentage">{{ discountPercentage + '%' }}</span>
        </span>
      </p>
    </div>
  </div>
</div>
