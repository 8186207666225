import { LocalStorage } from './../../../utils/localstorage.utils';
import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { MANUFACTURER_TYPES } from '@pos-common/constants/manufacturer-types';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { TerminalNames } from '@pos-common/constants/terminal-names.enum';

@Injectable()
export class MyPosService {
  private _isMyPosPAXDevice = false;
  private _isMyPosXGDDevice = false;
  private _isMyPosSPRDDevice = false;
  private _isMyPosLEPHONEDevice = false;
  private _isMyPosQUALCOMMDevice = false;

  constructor(private device: Device, private localStorageService: LocalStorage) {
    this.init();
  }

  private init() {
    const { manufacturer } = this.device;
    this._isMyPosXGDDevice = manufacturer === MANUFACTURER_TYPES.XGD;
    this._isMyPosPAXDevice = manufacturer === MANUFACTURER_TYPES.PAX;
    this._isMyPosSPRDDevice = manufacturer === MANUFACTURER_TYPES.SPRD;
    this._isMyPosLEPHONEDevice = manufacturer === MANUFACTURER_TYPES.LEPHONE;
    this._isMyPosQUALCOMMDevice = manufacturer === MANUFACTURER_TYPES.QUALCOMM && this.device?.model?.toUpperCase() === 'F20';
  }

  get isMyPosDevice() {
    return this.isMyPosN5Device || this.isMyPosHubDevice || this.isMyPosCarbonDevice || this.isMyPosSlimDevice || this.isMyPosProDevice;
  }

  get isMyPosN5Device() {
    return this._isMyPosXGDDevice;
  }

  get isMyPosHubDevice() {
    return this._isMyPosPAXDevice;
  }

  get isMyPosCarbonDevice() {
    return this._isMyPosSPRDDevice;
  }

  get isMyPosSlimDevice() {
    return this._isMyPosLEPHONEDevice;
  }

  get isMyPosProDevice() {
    return this._isMyPosQUALCOMMDevice;
  }

  get isMyPosScannerableDevice() {
    return this.isMyPosN5Device || this.isMyPosHubDevice;
  }

  getModelName() {
    return this.device.model;
  }

  get isMyPosTerminal() {
    const activeTerminal = this.localStorageService.getObject(StorageKeys.activeTerminal);
    return activeTerminal.name === TerminalNames.MINI;
  }

  get isMyPosTerminalAndPrinter() {
    const activeTerminal = this.localStorageService.getObject(StorageKeys.activeTerminal);
    return activeTerminal.name === TerminalNames.MINI && activeTerminal.printer;
  }

  get hasModernWebView() {
    return this.isMyPosN5Device;
  }

  get hasBroadcastScanner() {
    return this.isMyPosSlimDevice;
  }

  get isDisableEffect() {
    return this.isMyPosN5Device || this.isMyPosHubDevice || this.isMyPosCarbonDevice;
  }

  get isDisableModalAnimation() {
    return this.isMyPosN5Device || this.isMyPosCarbonDevice;
  }

  get hasMyPosPrinter() {
    return (
      this.isMyPosN5Device || this.isMyPosHubDevice || this.isMyPosCarbonDevice || this.isMyPosTerminalAndPrinter || this.isMyPosProDevice
    );
  }
}
