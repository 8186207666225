import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TippingSearchModalPage } from './page/tipping-search-modal.page';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import components from './components';
import {
  HeaderComponentModule,
  IconComponentModule,
  LoaderComponentModule,
  ProductSearchListComponentModule,
  SearchComponentModule,
} from '@pos-modules/shared/components';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    LoaderComponentModule,
    ProductSearchListComponentModule,
    IconComponentModule,
    SearchComponentModule,
    HeaderComponentModule,
  ],
  declarations: [TippingSearchModalPage, ...components],
})
export class TippingSearchModalModule {}
