<ion-content>
  <ion-list [hidden]="isLoading">
    <pos-customer-list-item
      *ngFor="let customer of customers; trackByUuid"
      [isActive]="selectedCustomerUuid && selectedCustomerUuid === customer.uuid"
      [customer]="customer"
      [showEditButton]="showEditButton"
      (onSelectCustomer)="handleSelectCustomer($event)"
      (onEditCustomer)="handleEditCustomer($event)"
    ></pos-customer-list-item>

    <div *ngIf="!customers.length" class="no-items">
      {{ 'customers_no_customers' | translate }}
    </div>
  </ion-list>

  <pos-loader class="loader-contaner" [isLoading]="isLoading"></pos-loader>

  <div [ngSwitch]="loadInterface" [hidden]="isLoading">
    <div *ngSwitchCase="'scroll'">
      <ion-infinite-scroll threshold="40%" (ionInfinite)="handleLoadMore($event)" [disabled]="hideLoadButton">
        <ion-infinite-scroll-content loadingSpinner="crescent"></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </div>
    <div *ngSwitchDefault>
      <pos-button [hidden]="hideLoadButton" label="common_load_more" (onClick)="handleLoadMore()"></pos-button>
    </div>
  </div>
</ion-content>
