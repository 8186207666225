import { Injectable } from '@angular/core';
import { SecurityService } from '../system/security.service';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';

@Injectable()
export class PaymentMethodsApiService {
  constructor(private securityService: SecurityService) {}

  create(paymentMethodName: string) {
    const data = {
      name: paymentMethodName,
      visibleDESKTOP: false,
      visiblePOS: true,
      visibleWEBSHOP: false,
    };
    const url = `${SERVER_CONFIG.API_URL}paymentMethods`;
    return this.securityService.doSecureRequestObservable(url, 'post', data);
  }
}
