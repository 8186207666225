import { Injectable } from '@angular/core';
import { DbEntityProvider } from './db-entity.provider';
import { DbDaoService, GetAllDataOptions } from '../db/db-dao.service';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { Observable } from 'rxjs';
import { Product } from '../../classes/product.class';
import { Query } from '@paymash/capacitor-database-plugin';

@Injectable()
export class ProductProvider extends DbEntityProvider {
  constructor(dbDaoService: DbDaoService) {
    super(UPDATES_TYPES.Product.type, dbDaoService);
  }

  getByUuid(uuid: string): Observable<Product> {
    return <Observable<Product>>this.getEntityByUuid(uuid);
  }

  getList(): Observable<Product[]> {
    return <Observable<Product[]>>this.getEntitiesList();
  }

  getListByParams(queryParams: Query, options?: GetAllDataOptions): Observable<Product[]> {
    return <Observable<Product[]>>this.getEntitiesListByParams(queryParams, options).pipe(this.mapPipeTransformEntities());
  }
}
