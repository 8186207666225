import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FileReaderUtils } from './file-reader/file-reader.utils';
import * as QRious from 'qrious';
import { PlatformService } from '../system/platform/platform.service';

@Injectable()
export class ImageUtils {
  constructor(private platformService: PlatformService) {}

  public imageToDataURL(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = FileReaderUtils.getFileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
        reader.onerror = () => {
          reject();
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = () => {
        reject();
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  public base64toBlob(base64: string, contentType?: string, sliceSize?: number) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  public convertFileSrc(path: string): string {
    if (!path) {
      return path;
    }
    if (this.platformService.isIOS) {
      return path.replace('http://localhost:8080/', 'ionic://localhost:8080/_capacitor_file_/');
    } else if (this.platformService.isAndroid) {
      return Capacitor.convertFileSrc(path);
    }
    return path;
  }

  public createQrCodeDataUrl(value: string, size: number = 200): string {
    const qr: QRious = new QRious({ value, size });
    return qr.toDataURL();
  }
}
