import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { PaymentProcessingService } from './payment-processing.service';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ProcessingButtonsComponent } from './processing-buttons/processing-buttons.component';
import { ProcessingIcons } from './processing-icons/processing-icons.component';
import { ProcessingContentComponent } from './processing-content/processing-content.component';
import { PaymentProcessing } from './payment-processing.component';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [BrowserModule, SignaturePadModule, TranslateModule.forChild()],
  declarations: [SignaturePadComponent, ProcessingButtonsComponent, ProcessingContentComponent, ProcessingIcons, PaymentProcessing],
})
export class PaymentProcessingModule {
  constructor(@Optional() @SkipSelf() parentModule: PaymentProcessingModule) {
    if (parentModule) {
      throw new Error('PaymentProcessingModule is already loaded. Import it in the AppModule only');
    }
  }
  static forRoot(): ModuleWithProviders<PaymentProcessingModule> {
    return {
      ngModule: PaymentProcessingModule,
      providers: [PaymentProcessingService],
    };
  }
}
