import { Injectable } from '@angular/core';
import { Invoice } from '../../../classes/invoice.class';
import { UPDATES_TYPES } from '../../../constants/updates-types.const';
import { SyncListItem } from '../../../classes/sync-list-item.class';
import { CHECKOUT_STEPS } from '../../../constants/checkout-steps.const';
import { SyncService } from '../sync.service';
import { LoadingService } from '../loading.service';
import { InvoicesService } from '../invoices.service';
import { CartService } from '../cart.service';
import { CollectionViewService } from '../collection-view.service';
import { LogService } from '../logger/log.service';
import { SecurityService } from '../security.service';
import { filter, first, map } from 'rxjs/operators';

@Injectable()
export class InvoiceProcessingService {
  constructor(
    private syncService: SyncService,
    private loadingService: LoadingService,
    private invoicesService: InvoicesService,
    private cartService: CartService,
    private collectionViewService: CollectionViewService,
    private securityService: SecurityService,
    private logService: LogService
  ) {}

  public processPaidInvoice(invoice: Invoice): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      const company = this.securityService.getLoggedCompanyData();
      let processingResultPromise: Promise<Invoice>;
      if (company.isRequireOnlineInvoiceProcessing) {
        const timeout = company.isKassenSichVEnabled || company.isRKSVEnabled ? 5000 : 30000;
        processingResultPromise = this.finishInvoicePaymentInOnline(invoice, timeout);
      } else {
        processingResultPromise = this.finishInvoicePaymentRegularFlow(invoice, false);
      }
      processingResultPromise
        .then((invoiceData) => resolve(new Invoice(invoiceData)))
        .catch(() => {
          this.finishInvoicePaymentRegularFlow(invoice, false).catch((error) =>
            this.logService.error('InvoiceProcessingService', 'processPaidInvoice', error)
          );
          reject();
        });
    });
  }

  private finishInvoicePaymentInOnline(invoice: Invoice, timeout: number): Promise<Invoice> {
    this.loadingService.showLoadingItem(null, null, true);
    return new Promise((resolve, reject) => {
      this.invoicesService
        .saveInvoice(invoice, true)
        .then(() => this.syncService.addDataToSyncList(new SyncListItem(invoice, UPDATES_TYPES.Invoice.type)))
        .then(() => {
          const timeoutSelector = setTimeout(() => {
            syncDataSubscription.unsubscribe();
            this.finishInvoicePaymentRegularFlow(invoice, false).then(resolve).catch(reject);
          }, timeout);

          const syncDataSubscription = this.syncService.dataToSaveSubject
            .pipe(
              filter((item) => item.type === UPDATES_TYPES.Invoice.type),
              map((item) => new Invoice(item.properties)),
              filter((item) => item.uuid === invoice.uuid && !!item.sequentId),
              first()
            )
            .subscribe((invoice) => {
              this.logService.debug('InvoiceProcessingService', `finishInvoicePaymentInOnline:invoiceSynced - ${invoice.uuid}`);
              clearTimeout(timeoutSelector);
              this.finishInvoicePaymentRegularFlow(invoice, true).then(resolve).catch(reject);
            });
        })
        .catch(reject);
    });
  }

  private finishInvoicePaymentRegularFlow(invoice: Invoice, withoutSync: boolean): Promise<Invoice> {
    this.cartService.setActiveInvoice(invoice);
    this.collectionViewService.setCurrentSidebarSlide(CHECKOUT_STEPS.SUCCESS);
    this.loadingService.hideLoadingItem();
    return this.invoicesService.saveInvoice(invoice, withoutSync);
  }
}
