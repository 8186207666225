export const StorageKeys = {
  socketScannerConnectedKey: 'socketScannerConnected',
  loggedUserData: 'loggedUserData',
  activeStore: 'activeStore-',
  defaultSecondPaymentMethod: 'defaultSecondPaymentMethod',
  selectedPaymentMethod: 'selectedPaymentMethod',
  activeTerminal: 'activeTerminal',
  preferredLanguage: 'preferredLanguage',
  companyLogo: 'companyLogo',
  invoicesKeepDurationKey: 'invoicesKeepDuration',
  settings: {
    oneClickPaymentStatusKey: 'settings.onclickpaymentstatus',
    defaultPaymentMethodKey: 'settings.defaultpaymentmethod',
    tippingStatusKey: 'settings.tippingstatus',
    defaultTipProductKey: 'settings.defaulttipproduct',
    multipleGuestsStatusKey: 'settings.multipleGuestsStatus',
    onInvoicePaymentStatusKey: 'settings.onInvoicePaymentStatus',
    device: 'settings.device',
  },
  showSumUpRecommendation: 'showSumUpRecommendation',
  printersSettings: 'printersSettings',
  initialPaidInvoicesStatus: 'initialPaidInvoicesStatus',
  permissions: 'permissions',
  showMessageOneTime: 'showMessageOneTime',
  paymashPayKey: 'paymashPayKey',
  paymashPayMerchantId: 'paymashPayMerchantId',
  deviceIdentifier: 'deviceIdentifier',
  deviceFingerprint: 'deviceFingerprint',
};
