import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { LogService } from './log.service';
import { PaymashErrorHandler } from './paymash-error-handler';
import { ElasticLogger } from './elastic-logger';

@NgModule({
  providers: [
    LogService,
    ElasticLogger,
    {
      provide: ErrorHandler,
      useClass: PaymashErrorHandler,
    },
  ],
})
export class LoggerModule {
  constructor(@Optional() @SkipSelf() parentModule: LoggerModule) {
    if (parentModule) {
      throw new Error('LoggerModule is already loaded. Import it in the AppModule only');
    }
  }
}
