import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { LocalizationUtils } from '../../services/utils/localization.utils';

type Format = 'dateFormat' | 'currencyRounding' | 'dateTimeFormat' | 'dateTimeListFormat' | 'dateAt' | 'timeFormat' | '';

@Pipe({ name: 'CompanyLocalized' })
export class CompanyLocalized implements PipeTransform {
  constructor(private localizationUtils: LocalizationUtils) {}

  transform(value: any, format: Format) {
    return this.localizationUtils.getFormattedDate(value, format);
  }
}
@NgModule({
  declarations: [CompanyLocalized],
  exports: [CompanyLocalized],
})
export class CompanyLocalizedPipeModule {}
