export enum ConnectionStatus {
  disconnected,
  loggedOut,
  loggedIn,
}

export enum TransactionStatus {
  busy = 'busy',
  idle = 'idle',
  waitForCard = 'waitForCard',
  readingCard = 'readingCard',
  applicationSelection = 'applicationSelection',
  waitForProceed = 'waitForProceed',
  dccSelection = 'dccSelection',
  enterTip = 'enterTip',
  pinEntry = 'pinEntry',
  signatureCapture = 'signatureCapture',
  processing = 'processing',
  waitForCommit = 'waitForCommit',
}

export enum CardReaderStatus {
  cardReaderClosed,
  cardManuallyEntered,
  cardSwiped,
  cardNotRemoved,
  cardPresented,
  cardReaderEmpty,
  cardInserted,
  cardEjected,
}

export enum ConnectionType {
  broadcast,
  fixedIp,
}
