import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterItem } from '@pos-common/classes/calendar/filter-item.class';
import { CALENDAR_FILTER_ITEM_TYPES, REGEXPS } from '@pos-common/constants';
import { LocalizationUtils } from '@pos-common/services/utils/localization.utils';
import * as moment from 'moment';
import { LOCALE } from '@pos-common/constants/locale.const';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';

@Component({
  selector: 'pos-calendar-filter-item',
  templateUrl: './calendar-filter-item.component.html',
  styleUrls: ['./calendar-filter-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class CalendarFilterItemComponent implements OnInit {
  @Input() itemType: CALENDAR_FILTER_ITEM_TYPES;
  @Input() item: FilterItem;
  @Output() posSelectedItem = new EventEmitter<void>();
  get CALENDAR_FILTER_ITEM_TYPES() {
    return CALENDAR_FILTER_ITEM_TYPES;
  }
  public placeholder: string;
  public formGroup = new FormGroup({
    amount: new FormControl(''),
    interval: new FormControl(''),
  });

  constructor(private localizationUtils: LocalizationUtils, private subSinkService: SubSinkService) {}

  ngOnInit(): void {
    this.setPlaceholder();
    this.setForm();
  }

  selectItem(filterItem: FilterItem) {
    filterItem.isSelected = !filterItem.isSelected;
    this.posSelectedItem.emit();
  }

  setIntervalValue(interval: string) {
    this.formGroup.patchValue({ interval });
  }

  private setPlaceholder() {
    if (this.itemType === CALENDAR_FILTER_ITEM_TYPES.AMOUNT) {
      this.placeholder = this.localizationUtils.getFormattedCurrency(0, false);
    }
  }

  private setForm() {
    switch (this.itemType) {
      case CALENDAR_FILTER_ITEM_TYPES.AMOUNT:
        return this.setAmount(this.item.value as string);
      case CALENDAR_FILTER_ITEM_TYPES.DATE:
        return this.setInterval(this.item.value as string);
    }
  }

  private setAmount(amount: string) {
    if (amount) {
      amount = this.localizationUtils.getFormattedCurrency(amount, false);
    }
    this.formGroup.controls.amount.patchValue(amount);
    this.subSinkService.sink = this.formGroup.controls.amount.valueChanges.subscribe((newAmount: string) => {
      const amount = newAmount.replace(REGEXPS.AMOUNT, '');
      this.formGroup.controls.amount.setValue(amount, { emitEvent: false });
      this.item.value = amount;
      this.item.isSelected = !!amount;
    });
  }

  private setInterval(interval: string) {
    if (interval) {
      interval = moment(interval).format(LOCALE.DateFormat.YYYY_MM_DD_DASH);
    }
    this.formGroup.controls.interval.patchValue(interval);
    this.subSinkService.sink = this.formGroup.controls.interval.valueChanges.subscribe((newInterval: string) => {
      this.formGroup.controls.amount.setValue(newInterval, { emitEvent: false });
      this.item.value = newInterval;
      this.item.isSelected = !!newInterval;
    });
  }
}
