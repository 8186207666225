import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'person-placeholder',
  templateUrl: './person-placeholder.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PersonPlaceholder {
  @Input() width: string;
  @Input() height: string;
  @Input() fillColor: string = 'var(--pos-dark-grey-border)';
  @Input() isFill: boolean;
}

@NgModule({
  imports: [CommonModule],
  declarations: [PersonPlaceholder],
  exports: [PersonPlaceholder],
})
export class PersonPlaceholderModule {}
