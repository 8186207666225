<pos-header>
  <button tappable (click)="dismiss()" header-start>
    <pos-icon customName="custom-icon-cancel-search"></pos-icon>
  </button>
  <ion-title header-title>{{ 'change_table' | translate }}</ion-title>
  <button (click)="handleDone()" header-end tappable>
    <pos-icon customName="custom-icon-select"></pos-icon>
  </button>
</pos-header>
<ion-item class="select-guest-message" lines="full">
  <span>{{ 'change_table_message' | translate }}</span>
</ion-item>
<ion-content>
  <ion-list class="select-guest-list">
    <ion-item class="all-guests-button" lines="full" (click)="selectGuest()">
      <div class="pos-checkbox">
        <div class="checkbox-icon" [class.checkbox-checked]="isAllGuestsSelected">
          <div class="checkbox-inner"></div>
        </div>
      </div>
      <ion-label>{{ 'common_all_guest' | translate }}</ion-label>
    </ion-item>
    <ion-item *ngFor="let invoiceEntryGuest of invoiceEntryGuests" lines="full">
      <guest-button
        [isSelected]="invoiceEntryGuest.guestNumber === activeGuestNumber"
        [marker]="invoiceEntryGuest.guestNumber"
        [name]="invoiceEntryGuest.name"
        colorMode="blue"
        (onClick)="selectGuest(invoiceEntryGuest.guestNumber)"
      ></guest-button>
    </ion-item>
  </ion-list>
</ion-content>
