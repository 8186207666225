/**
 * Created by f.latyshev on 3/16/20.
 */

export const MYPOS = {
  QR_SIZE: 250,
  LINE_WIDTH: 32,
  BIG_LINE_WIDTH: 21,
  IP_ADDRESS: '10.0.1.10',
  MAC_ADDRESS: '00:01:90:C2:BB:7C',
  TARGET: 'TCP:00:01:90:C2:BB:7C',
};

export const MYPOSMINI = {
  LINE_WIDTH: 32,
  BIG_LINE_WIDTH: 17,
  IP_ADDRESS: '10.0.1.11',
  MAC_ADDRESS: '00:01:90:C2:BB:7D',
  TARGET: 'TCP:00:01:90:C2:BB:7D',
};

export const LangMap = {
  de: 5,
  es: 4,
  fr: 6,
  it: 2,
  pt: 17,
  en: 0,
};

export const CurrencyMap = {
  HRK: 1,
  CZK: 2,
  DKK: 3,
  HUF: 4,
  ISK: 5,
  NOK: 6,
  SEK: 7,
  CHF: 8,
  GBP: 9,
  USD: 10,
  RON: 11,
  BGN: 12,
  EUR: 13,
  PLN: 14,
  default: 15,
};

export const AvailableCurrencies = Object.keys(CurrencyMap).slice(0, -1);
