import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  NgModule,
} from '@angular/core';
import { PickerColumn, PickerColumnOption } from '@ionic/angular';
import { PickerColumnComponentModule } from '../picker-column/picker-column.component';

@Component({
  selector: 'pos-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent implements OnInit {
  @Input() value: string;
  @Output() posSetValue = new EventEmitter<string>();
  @HostBinding('class.pos-picker') isPosPicker = true;
  public colHours: PickerColumn;
  public colMinute: PickerColumn;
  constructor() {}

  ngOnInit() {
    this.colHours = this.getHours();
    this.colMinute = this.getMinutes();
    this.setValue();
  }

  handleHoursColChange(col: PickerColumn) {
    const selectedValue = col.options[col.selectedIndex].value;
    const date = new Date(this.value);
    date.setHours(selectedValue);
    this.updateValue(date);
  }

  handleMinutesColChange(col: PickerColumn) {
    const selectedValue = col.options[col.selectedIndex].value;
    const date = new Date(this.value);
    date.setMinutes(selectedValue);
    this.updateValue(date);
  }

  private updateValue(date: Date) {
    const dateISO = date.toISOString();
    this.value = dateISO;
    this.posSetValue.emit(dateISO);
  }

  private getHours() {
    const hours = new Array(24).fill('').map((_, index) => index);
    const options = [...hours.map((hour) => ({ value: hour, text: hour.toString().padStart(2, '0') } as PickerColumnOption))];
    return { name: 'hours', options };
  }

  private getMinutes() {
    const minutes = new Array(60).fill('').map((_, index) => index);
    minutes.push(0);
    const options = [...minutes.map((minute) => ({ value: minute, text: minute.toString().padStart(2, '0') } as PickerColumnOption))];
    return { name: 'minutes', options };
  }

  private setValue() {
    if (!this.value) {
      return;
    }
    const date = new Date(this.value);
    const hour = date.getHours();
    const selectedIndexHour = this.colHours.options.findIndex((opt) => opt.value === hour);
    this.colHours.selectedIndex = selectedIndexHour;
    const minute = date.getMinutes();
    const selectedIndexMinute = this.colMinute.options.findIndex((opt) => opt.value === minute);
    this.colMinute.selectedIndex = selectedIndexMinute;
  }
}
@NgModule({
  imports: [PickerColumnComponentModule],
  declarations: [TimePickerComponent],
  exports: [TimePickerComponent],
})
export class TimePickerComponentModule {}
