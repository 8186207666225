import {
  SavedDeviceInfo as SavedDeviceInfoBase,
  UniversalCustomerDisplayDevice,
} from '../UniversalCustomerDisplayDevice';
import { WebDemoDriver } from './WebDemoDriver';

export class Device extends UniversalCustomerDisplayDevice<ConnectionInfo> {
  createDriver() {
    return new WebDemoDriver();
  }
}

export class ConnectionInfo {}

export interface SavedDeviceInfo extends SavedDeviceInfoBase {
  connectionInfo: ConnectionInfo;
}
