/**
 * Created by y.belinsky on 10/24/16.
 */
import { Component, Input, ElementRef, OnInit, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
import { OnChanges, SimpleChanges } from '@angular/core';
import { PrintersListModal } from './printers-list-modal/printers-list';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../services/system/modal.service';
import { LoadingService } from '../../services/system/loading.service';
import { PrinterService } from '../../services/system/printer.service';
import { AlertService } from '../../services/system/alert.service';
import { CartService } from '../../services/system/cart.service';
import { InvoicesService } from '../../services/system/invoices.service';
import { Invoice } from '../../classes/invoice.class';
import { ReceiptPrinterModeTypes } from '../../constants/receipt-printer-mode-types';
import { LogService } from '../../services/system/logger/log.service';
import { SubSink } from 'subsink';
import { IPrintersListCoordinates } from '@pos-common/interfaces/printers-list-coordinates.inteface';
import { MultipleGuestsService } from '@pos-common/services/system/multiple-guests/multiple-guests.service';
import { PrintGuestsModalComponent } from '../print-guests-modal/print-guests-modal.component';
import { SELECT_GUEST_MODAL_ACTION } from '@pos-common/constants';
import { TableEnforceService } from '@pos-common/services/system/table-select-enforcement.service';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BadgeComponentModule } from '../badge/badge.component';
import { CommonHeaderComponentModule } from '../common-header/common-header.component';

@Component({
  selector: 'printer-link',
  templateUrl: './printer-link.component.html',
  styleUrls: ['./printer-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterLink implements OnInit, OnDestroy, OnChanges {
  @Input() invoice: Invoice;
  @Input() posMode: string = ReceiptPrinterModeTypes.POS;
  @Input() onlyIcon: boolean = false;
  @Input() showUnprintedKitchenItems = false;
  @Input() isArrowRight = false;
  @Input() showGuestOnReceipt = false;
  @Input() guestNumber = 0;
  @Input() tableEnforce = false;
  @Input() showGuestPopup = false;

  coordinates: IPrintersListCoordinates = {} as any;
  printerIsBusyAlert: any;
  receiptPrinterModeTypes = ReceiptPrinterModeTypes;
  unprintedKitchenItems = 0;
  private subs = new SubSink();
  private readonly invoiceKey = 'invoice';
  private readonly showUnprintedKitchenItemsKey = 'showUnprintedKitchenItems';

  constructor(
    public printerService: PrinterService,
    public modalService: ModalService,
    public elementRef: ElementRef,
    public alertService: AlertService,
    public translateService: TranslateService,
    public loadingService: LoadingService,
    public cartService: CartService,
    public invoicesService: InvoicesService,
    private multipleGuestsService: MultipleGuestsService,
    private tableEnforceService: TableEnforceService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.calculatePosition();
    this.setUnprintedKitchenItemsIndicator();
  }

  ngOnDestroy() {
    if (this.printerIsBusyAlert) this.printerIsBusyAlert.dismiss();
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    const invoice = changes[this.invoiceKey];
    const showUnprintedKitchenItems = changes[this.showUnprintedKitchenItemsKey];
    const shouldUpdate = (invoice && !invoice.isFirstChange()) || (showUnprintedKitchenItems && !showUnprintedKitchenItems.isFirstChange());
    if (shouldUpdate) {
      this.setUnprintedKitchenItemsIndicator();
    }
  }

  private setUnprintedKitchenItemsIndicator() {
    if (this.showUnprintedKitchenItems && this.invoice) {
      const invoiceEntries = this.invoice.getActiveInvoiceEntriesWithoutGuests();
      const invoiceEntriesForKitchen = this.invoicesService.getInvoiceEntriesForKitchen(invoiceEntries, this.guestNumber);
      this.unprintedKitchenItems = invoiceEntriesForKitchen.reduce((counter, invoiceEntry) => {
        const { quantityForKitchenReceipt } = invoiceEntry;
        return quantityForKitchenReceipt ? counter + quantityForKitchenReceipt : counter;
      }, 0);
    }
  }

  async linkTap() {
    if (this.tableEnforce && this.tableEnforceService.checkForTableEnforcementAndShowAlert()) {
      return;
    }

    if (this.showGuestPopup) {
      const isCancel = await this.selectGuestAlert();
      if (isCancel) {
        return;
      }
    }

    this.calculatePosition();
    if (!this.invoice && this.posMode !== ReceiptPrinterModeTypes.CASH_REGISTER) {
      this.printingNotPossibleAlertShow('receipt_printer_printing_not_possible_msg');
      return;
    }
    if (this.posMode === ReceiptPrinterModeTypes.KITCHEN) {
      const invoiceEntries = this.invoice.getActiveInvoiceEntriesWithoutGuests();
      const invoiceEntriesForKitchen = this.invoicesService.getInvoiceEntriesForKitchen(invoiceEntries, this.guestNumber);
      if (invoiceEntriesForKitchen.length === 0) {
        this.printingNotPossibleAlertShow('receipt_printer_all_items_already_printed_msg');
        this.loadingService.hideLoadingItem();
        return;
      }
    }
    if (this.posMode === ReceiptPrinterModeTypes.BILL || this.posMode === ReceiptPrinterModeTypes.KITCHEN) {
      this.cartService.setActiveInvoiceId();
      this.invoice = new Invoice(this.cartService.getActiveInvoice());
    }
    const activePrinter = await this.printerService.getPrinterListOnce(<ReceiptPrinterModeTypes>this.posMode).toPromise();
    if (activePrinter.length) {
      this.loadingService.showLoadingItem();
      if (this.posMode === ReceiptPrinterModeTypes.CASH_REGISTER) {
        this.printerService.openCashRegister().finally(() => {
          this.loadingService.hideLoadingItem();
        });
      } else {
        this.startPrintingAction();
      }
    } else {
      this.openPrintersModal();
    }
  }

  private startPrintingAction() {
    if (this.posMode === ReceiptPrinterModeTypes.KITCHEN) {
      this.subs.sink = this.printerService.checkVirtualPrinterAssignment().subscribe((isAssigned) => {
        if (isAssigned) {
          this.createAndPrintReceipt();
        } else {
          this.loadingService.hideLoadingItem();
          this.printerService.notifyUserOfPrinterAssignment();
        }
      });
    } else {
      this.createAndPrintReceipt();
    }
  }

  private createAndPrintReceipt() {
    this.printerService
      .printInvoiceReceiptMultiple(this.invoice, this.guestNumber, this.posMode as ReceiptPrinterModeTypes)
      .then(() => {
        if (this.posMode === ReceiptPrinterModeTypes.BILL || this.posMode === ReceiptPrinterModeTypes.KITCHEN) {
          this.cartService.cleanInvoiceEntriesKitchenQuantity(this.guestNumber);
        }
        if (
          this.invoice.isPaid &&
          !this.invoice.isPrinted &&
          this.posMode !== ReceiptPrinterModeTypes.KITCHEN &&
          this.posMode !== ReceiptPrinterModeTypes.BILL
        ) {
          this.cartService.setPrintedStatusToInvoice(this.invoice);
        }
        this.loadingService.hideLoadingItem();
      })
      .catch(() => {
        if (this.posMode !== ReceiptPrinterModeTypes.KITCHEN) {
          this.openPrintersModal();
        }
        if (this.posMode === ReceiptPrinterModeTypes.BILL || this.posMode === ReceiptPrinterModeTypes.KITCHEN) {
          this.cartService.cleanInvoiceEntriesKitchenQuantity(this.guestNumber);
        }
        this.loadingService.hideLoadingItem();
      });
  }

  private async openPrintersModal() {
    try {
      const printerListModal = await this.modalService.presentModal(
        PrintersListModal,
        {
          invoice: this.invoice,
          coordinates: this.coordinates,
          posMode: this.posMode,
          guestNumber: this.guestNumber,
        },
        {
          showBackdrop: true,
          backdropDismiss: true,
        }
      );
      printerListModal.present();
    } catch (err) {
      this.logService.error('PrinterLink', 'openPrintersModal:printerListModal:present', err);
    }
  }

  private calculatePosition(): void {
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = (<HTMLElement>this.elementRef.nativeElement).getBoundingClientRect();
    const offsetTop = elemRect.bottom + 20;
    const offsetLeft = elemRect.left;
    const offsetBottom = bodyRect.bottom - elemRect.top + 20;
    this.coordinates.offsetLeft = Math.round(offsetLeft) + 'px';
    if (elemRect.top < 150) {
      this.coordinates.offsetTop = Math.round(offsetTop) + 'px';
      this.coordinates.offsetBottom = 'auto';
      this.coordinates.bottom = false;
    } else {
      this.coordinates.offsetTop = 'auto';
      this.coordinates.offsetBottom = Math.round(offsetBottom) + 'px';
      this.coordinates.bottom = true;
    }
    if (this.isArrowRight) {
      this.coordinates.arrowDirection = 'right';
    }
    this.coordinates.bodyMaxHeight = bodyRect.height;
  }

  private printingNotPossibleAlertShow(message: string) {
    return this.alertService.showAlert({
      header: 'receipt_printer_printing_not_possible_title',
      message,
    });
  }

  private selectGuestAlert(): Promise<boolean> {
    return new Promise(async (resolve) => {
      try {
        this.guestNumber = 0;

        if (!this.multipleGuestsService.isMultipleGuests) {
          return resolve(false);
        }

        const invoiceEntryGuests = this.multipleGuestsService.getInvoiceEntryGuests(this.invoice.getActiveInvoiceEntries());
        const printGuestsModal = await this.modalService.getGuestsModal(PrintGuestsModalComponent, invoiceEntryGuests);
        printGuestsModal.componentProps = {
          ...printGuestsModal.componentProps,
          invoice: this.invoice,
        };

        printGuestsModal.onDidDismiss().then((value) => {
          const data = value.data || {};
          this.guestNumber = data.activeGuestNumber || 0;
          const isCancel = value.role === SELECT_GUEST_MODAL_ACTION.CANCEL;
          resolve(isCancel);
        });

        await printGuestsModal.present();
      } catch (error) {
        this.logService.error('InvoiceFilter', 'transferInvoicePopup', error);
        resolve(true);
      }
    });
  }
}
@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule.forChild(), BadgeComponentModule, CommonHeaderComponentModule],
  declarations: [PrintersListModal, PrinterLink],
  exports: [PrinterLink],
})
export class PrinterLinkModule {}
