/**
 * Created by maksymkunytsia on 9/12/16.
 */

export const UPDATES_TYPES = {
  Company: {
    type: 'Company',
    URL: 'company',
  },
  Store: {
    type: 'Store',
    URL: 'stores',
  },
  Employee: {
    type: 'Employee',
    URL: 'employees',
  },
  PaymentMethod: {
    type: 'PaymentMethod',
    URL: 'paymentmethods',
  },
  VATRate: {
    type: 'VATRate',
    URL: 'vatrates',
  },
  ProductCategory: {
    type: 'ProductCategory',
    URL: 'productCategories',
  },
  ProductVariant: {
    type: 'ProductVariant',
    URL: 'productVariants',
  },
  Product: {
    type: 'Product',
    URL: 'products',
  },
  Inventory: {
    type: 'Inventory',
    URL: 'inventories',
  },
  Invoice: {
    type: 'Invoice',
    URL: 'invoices',
  },
  Customer: {
    type: 'Customer',
    URL: 'customers',
  },
  GastronomyHall: {
    type: 'GastronomyHall',
    URL: 'gastronomyHalls',
  },
  VirtualPrinter: {
    type: 'VirtualPrinter',
    URL: 'virtualPrinters',
  },
  DeviceReport: {
    type: 'DeviceReport',
  },
  ImagesToLoad: {
    type: 'ImagesToLoad',
  },
  //DiscountCode: {
  //    type: 'DiscountCode',
  //    URL: 'discountcodes'
  //},
};
