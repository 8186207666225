import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { InvoicesService } from '@pos-common/services/system/invoices.service';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';
import { DomController } from '@ionic/angular';
import { ProductVariant } from '@pos-common/classes/product-variant.class';
import { Product } from '@pos-common/classes/product.class';
import { CartService } from '@pos-common/services/system/cart.service';
import { CollectionViewService } from '@pos-common/services/system/collection-view.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { PRODUCT_VARIANT_LIST_TYPE } from '@pos-common/constants';
import { AppointmentService } from '@pos-common/services/system/appointment/appointment.service';
import { Employee } from '@pos-common/classes/employee.class';
import { LogService, stringifySafety } from '@pos-common/services/system/logger';

//Classes

@Component({
  selector: 'product-variant-modal',
  templateUrl: './product-variant-modal.page.html',
  styleUrls: ['./product-variant-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SubSinkService],
})
export class ProductVariantModal {
  @ViewChild('variantModalContent', { static: true }) variantModalContent: ElementRef;
  @ViewChild('arrow', { static: true }) arrowElement: ElementRef;
  public product: Product = null;
  public elementParams: any = {
    coordinates: { left: 0 },
  };
  public isRightArrow: boolean = false;
  public arrowOffsetTop: number;
  public handleEmployeeInComponent: boolean = false;
  private modalWidth: number = 240;
  private coordinates: any;
  private inventoryWrapperElement: any;
  private listType: string;
  public changeVariantCallback: Function;
  public productCategoryUuid: string;
  public centerInventory: any;
  private modalPositionFinal: boolean = false;
  readonly MODAL_POSITION_SOURCES = {
    CONSTRUCT: 'CONSTRUCT',
    OPEN_MODAL: 'OPEN_MODAL',
    CHANGE_VARIANT: 'CHANGE_VARIANT',
    DISMISS: 'DISMISS',
  };
  private eventListenerResize: () => void = null;

  constructor(
    public cartService: CartService,
    public elementRef: ElementRef,
    public collectionViewService: CollectionViewService,
    private invoicesService: InvoicesService,
    private renderer: Renderer2,
    private setTimeoutUtil: SetTimeoutUtil,
    private domController: DomController,
    private appointmentService: AppointmentService,
    private subSinkService: SubSinkService,
    private cdr: ChangeDetectorRef,
    private logService: LogService
  ) {
    this.changeVariantCallback = this.changeVariant.bind(this);
  }

  ngOnInit() {
    this.inventoryWrapperElement = this.elementRef.nativeElement.parentElement.querySelector('.inventory-wrapper');
    this.centerInventory = {
      top: this.inventoryWrapperElement.offsetHeight / 2,
      left: this.inventoryWrapperElement.offsetWidth / 2,
    };
    this.setModalPosition(this.MODAL_POSITION_SOURCES.CONSTRUCT, this.centerInventory.left, this.centerInventory.top);

    this.collectionViewService
      .getProductVariantModalDataChangeEmitter()
      .pipe(this.subSinkService.takeUntilDestroy())
      .subscribe((data) => this.setData(data));
    this.setHandleEmployeeInComponent();
    this.eventListenerResize = this.handleResize.bind(this);
    window.addEventListener('resize', this.eventListenerResize);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.eventListenerResize);
  }

  private setData(data: any) {
    if (!data) {
      this.modalPositionFinal = false;
      this.product = null;
      this.renderer.removeClass(this.elementRef.nativeElement, 'visible');
      this.cdr.detectChanges();
      return;
    }
    const { eventTarget } = data;
    this.listType = data.listType;
    this.productCategoryUuid = data.productCategoryUuid || null;
    this.coordinates = this.getParentElement(eventTarget, this.listType).getBoundingClientRect();
    this.setModalPosition(
      this.MODAL_POSITION_SOURCES.OPEN_MODAL,
      this.elementParams.coordinates.left,
      this.inventoryWrapperElement.offsetHeight
    );

    const modalWidthMultilayer = 3;
    this.modalWidth = this.collectionViewService.getCellWidth() * modalWidthMultilayer;

    this.setElementParamsData();
    this.product = data.product;
    this.setHandleEmployeeInComponent();

    this.setTimeoutUtil.addVisualEffect(100).then(() => {
      this.renderer.addClass(this.elementRef.nativeElement, 'visible');
      this.cdr.detectChanges();
    });
  }

  private handleResize() {
    this.setTimeoutUtil.addVisualEffect(200).then(() => {
      if (this.product) {
        this.setElementParamsData();
        this.setModalPositionAfterVariantChanged();
      }
    });
  }

  private setElementParamsData(): void {
    this.elementParams.coordinates.left = this.getOffsetLeft(this.coordinates);
    this.elementParams.coordinates.top = this.getOffsetTop(this.coordinates, this.inventoryWrapperElement.offsetHeight).modalTopOffset;
    this.elementParams.maxHeight = this.inventoryWrapperElement.offsetHeight - 40;

    this.domController.write(() => {
      this.renderer.setStyle(this.variantModalContent.nativeElement, 'width', `${this.modalWidth}px`);
      this.renderer.setStyle(this.variantModalContent.nativeElement, 'maxHeight', `${this.elementParams.maxHeight}px`);
    });
  }

  public changeVariant() {
    this.setTimeoutUtil.waitTimeAndDo().then(() => {
      this.setModalPositionAfterVariantChanged();
    });
    this.setTimeoutUtil.waitTimeAndDo(500).then(() => {
      this.setModalPositionAfterVariantChanged();
    });
    this.setTimeoutUtil.waitTimeAndDo(200).then(() => {
      this.renderer.setStyle(this.variantModalContent.nativeElement, 'opacity', '1');
    });
  }

  private setModalPositionAfterVariantChanged(): void {
    const offsetTop = this.getOffsetTop(this.coordinates, this.inventoryWrapperElement.offsetHeight);
    this.elementParams.coordinates.top = offsetTop.modalTopOffset;
    this.setModalPosition(
      this.MODAL_POSITION_SOURCES.CHANGE_VARIANT,
      this.elementParams.coordinates.left,
      this.elementParams.coordinates.top
    );
    const pickerElement: HTMLElement = this.elementRef.nativeElement.getElementsByClassName('content')[0];
    this.domController.write(() => {
      this.renderer.setStyle(this.arrowElement.nativeElement, 'transform', `translate3D(0, ${offsetTop.arrowTopOffset}px, 0)`);
      if (pickerElement) {
        const maxHeight = this.elementParams.maxHeight - 64;
        this.renderer.setStyle(pickerElement, 'maxHeight', `${maxHeight}px`);
      }
    });
  }

  private setModalPosition(source: string, left: number, top: number): void {
    if (!this.modalPositionFinal || source === this.MODAL_POSITION_SOURCES.CHANGE_VARIANT) {
      this.modalPositionFinal = source === this.MODAL_POSITION_SOURCES.CHANGE_VARIANT;
      this.renderer.setStyle(this.variantModalContent.nativeElement, 'transform', `translate3D(${left}px, ${top}px,  0)`);
    }
  }

  private getOffsetLeft(coordinates: any) {
    const { modalWidth } = this;
    if (coordinates.left > modalWidth) {
      this.isRightArrow = true;
      return coordinates.left - modalWidth;
    }
    this.isRightArrow = false;
    return coordinates.right;
  }

  private getOffsetTop(coordinates: any, inventoryWrapperHeight: number): { modalTopOffset: number; arrowTopOffset: number } {
    const top: number = (coordinates.bottom - coordinates.top) / 2 + coordinates.top;
    const modalHeight: number = this.variantModalContent.nativeElement.offsetHeight;
    const modalHalfHeight: number = modalHeight / 2;
    const bottomDifference: number = inventoryWrapperHeight + 54 - top - modalHalfHeight;
    let modalTopOffset: number = top - modalHalfHeight;
    if (bottomDifference < 0) {
      modalTopOffset = top - modalHalfHeight - Math.abs(bottomDifference) - 10;
    }

    let topDifference = top - modalHalfHeight;
    if (topDifference < 60) {
      modalTopOffset = 60;
    }

    let arrowTopOffset = top - 13 - modalTopOffset;
    if (arrowTopOffset > modalHeight) {
      arrowTopOffset = modalHeight - 10 - 26;
    }

    if (arrowTopOffset < 10) {
      arrowTopOffset = 10;
    }

    return { modalTopOffset, arrowTopOffset };
  }

  handleDismiss(event: any) {
    this.addItemToCart(event?.variant, event?.productShortName, event?.employee);
    this.dismiss();
  }

  dismiss() {
    this.collectionViewService.setProducVariantModalData(null);
    this.renderer.removeClass(this.elementRef.nativeElement, 'visible');
    this.modalPositionFinal = false;
    this.product = null;
    this.setModalPosition(this.MODAL_POSITION_SOURCES.DISMISS, this.centerInventory.left, this.inventoryWrapperElement.offsetHeight);
    this.cdr.detectChanges();
  }

  private getParentElement(element: HTMLElement, listType: string) {
    const productListTypes = [PRODUCT_VARIANT_LIST_TYPE.PRODUCTS, PRODUCT_VARIANT_LIST_TYPE.SERVICES];
    const searchListTypes = [PRODUCT_VARIANT_LIST_TYPE.SEARCH, PRODUCT_VARIANT_LIST_TYPE.SEARCH_SERVICES];
    let className = 'item-link';
    className = productListTypes.includes(listType) ? 'product-item' : className;
    className = searchListTypes.includes(listType) ? 'product-search' : className;
    const targetItem = productListTypes.includes(listType) ? 'product-item-wrapper' : 'item-image';
    if (element.className.indexOf(className) !== -1) {
      return element.getElementsByClassName(targetItem)[0];
    }
    return this.getParentElement(element.parentElement, listType);
  }

  private addItemToCart(variant?: ProductVariant, productShortName?: string, employee?: Employee) {
    if (!variant) {
      return;
    }
    if (this.isServiceList()) {
      this.addServiceToCart(variant, this.product, employee);
      return;
    }
    this.addProductToCart(variant, this.product, this.productCategoryUuid, productShortName);
  }

  private addProductToCart(variant: ProductVariant, product: Product, productCategoryUuid: string, productShortName: string) {
    const newEntryData = this.invoicesService.makeInvoiceEntryToAddData(variant, product, productShortName, productCategoryUuid);
    newEntryData.image = this.cartService.getEntryImage(product, variant);
    try {
      this.cartService.addEntry(newEntryData, product.type, 1);
    } catch (error) {
      this.logService.debug('ProductVariantModal', `addProductToCart:addEntry ${stringifySafety(newEntryData)}`);
      this.logService.error('ProductVariantModal', 'addProductToCart:addEntry', error);
    }
  }

  private addServiceToCart(variant: ProductVariant, product: Product, employee: Employee) {
    const service = this.appointmentService.makeServiceToAddData(product, variant.price, employee);
    this.appointmentService.addServiceToActiveAppointment(service);
  }

  private isServiceList() {
    const serviceListType = [PRODUCT_VARIANT_LIST_TYPE.SERVICES, PRODUCT_VARIANT_LIST_TYPE.SEARCH_SERVICES];
    return serviceListType.includes(this.listType);
  }

  private setHandleEmployeeInComponent() {
    const isServiceList = this.isServiceList();
    this.handleEmployeeInComponent = isServiceList;
  }
}
