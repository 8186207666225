import { IAdyenPrinterService } from './adyen-printer.service';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ReceiptPrinter } from '@pos-common/services/system/receipt-printers/classes/receipt-printer.class';
import { MYPOS } from '@pos-common/constants/mypos.const';
import { ReceiptBuilder } from '@pos-common/services/system/receipt-printers/classes/receipt-builder.class';

export class AdyenPrinterServiceMock extends IAdyenPrinterService {
  constructor(injector: Injector) {
    super(injector);
  }

  protected get discoverMethodName(): string {
    return '';
  }

  protected get pluginName(): string {
    return '';
  }

  protected get printMethodName(): string {
    return '';
  }

  protected get stopDiscoverMethodName(): string {
    return '';
  }

  protected discover(): Observable<ReceiptPrinter[]> {
    this.getPrinter();
    return this.printerList;
  }

  protected stopDiscover() {
    return Promise.resolve();
  }

  private getPrinter(): Observable<ReceiptPrinter[]> {
    const isAdyenPrinterEnabled = true;

    if (isAdyenPrinterEnabled) {
      const deviceName = 'Adyen';
      const deviceType = 'Remote';
      const printerType = 'ADYEN';

      if (isAdyenPrinterEnabled) {
        const printer = new ReceiptPrinter({
          printerName: deviceName,
          deviceName,
          deviceType,
          ipAddress: MYPOS.IP_ADDRESS,
          isActive: true,
          macAddress: MYPOS.MAC_ADDRESS,
          printerNameForModelPick: printerType,
          target: MYPOS.TARGET,
        });

        this.handleDiscover([printer]);
        return;
      }
    }
    this.handleDiscover([]);
  }

  print(device: ReceiptPrinter, data: ReceiptBuilder) {
    return Promise.resolve();
  }
}
