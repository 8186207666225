<ion-backdrop (click)="closeModal(null)" class="dark" tappable></ion-backdrop>
<div class="modal-inner">
  <common-header class="modal-header">
    <button *ngIf="newDiscount" class="back-link" (click)="closeModal(null, 'header-close')" header-start tappable>
      <i class="custom-icon custom-icon-cancel-search"></i>
    </button>
    <h2 class="header-title" header-title>{{ 'common_add_discount' | translate }}</h2>
    <button class="confirm-link" (click)="closeModal(true, 'header-confirm')" header-end tappable>
      <i class="custom-icon custom-icon-select"></i>
    </button>
  </common-header>
  <div class="modal-body">
    <discount-picker
      [discountType]="discountType"
      [discountCents]="discountCents"
      [discountDecimal]="discountDecimal"
      (updateDiscount)="discountTypeOrAmountChanged($event)"
    ></discount-picker>
    <div class="add-button-holder">
      <a class="btn btn-remove" (click)="closeModal(true, 'footer-remove')" [hidden]="newDiscount" tappable
        ><i class="custom-icon custom-icon-paid-invoices-trash"></i
      ></a>
      <a class="btn btn-add-product" (click)="closeModal(true, 'footer-add')" [hidden]="!newDiscount" tappable>{{
        'individual_product_modal_add_to_cart' | translate
      }}</a>
      <a class="btn btn-done" (click)="closeModal(true, 'footer-done')" [hidden]="newDiscount" tappable>{{ 'common_done' | translate }}</a>
    </div>
  </div>
</div>
