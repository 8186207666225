import { Injectable } from '@angular/core';
import * as accounting from 'accounting';

@Injectable()
export class MathUtils {
  constructor() {}

  public static roundByFinalRoundingSize(value: number, finalRoundingSize: number): number {
    const factor = 1 / finalRoundingSize;
    const multipliedValue = value * factor;
    return this.roundHalfUp(multipliedValue, 0) / factor;
  }

  public static roundHalfUp(value: number, decimals: number): number {
    const absValue = Math.abs(value);
    const sign = Math.sign(value);
    return sign * Number(Math.round(parseFloat(absValue + 'e' + decimals)) + 'e-' + decimals);
  }

  public static getRandomInt(max: number = 10000) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  public static normalizeAddition(x: number, y: number): number {
    const sum = x + y;
    const float = accounting.toFixed(sum, 2);
    return parseFloat(float);
  }

  public static normalizeSubtraction(x: number, y: number): number {
    const sub = x - y;
    const float = accounting.toFixed(sub, 2);
    return parseFloat(float);
  }

  public static isFloat(value: number) {
    return Number(value) === value && value % 1 !== 0;
  }

  public static isInt(value: number) {
    return Number(value) === value && value % 1 === 0;
  }
}
