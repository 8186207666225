<table cellspacing="0" cellpadding="0">
  <tbody>
    <tr *ngIf="keyboardType === 'CHECKOUT'">
      <td colspan="2">
        <virtual-keyboard-cell
          *ngIf="activeTotalBlockButton !== totalBlockButtons.Given && showTopPopularNote"
          class="btn-number btn-number-full-amount"
          [cellText]="topPopularNote.cellText"
          [cellValue]="topPopularNote.cellValue"
          [actionType]="VirtualKeyboardActionTypes.Note"
          (onClickCell)="handleOnClick($event)"
        >
        </virtual-keyboard-cell>
        <virtual-keyboard-cell
          *ngIf="activeTotalBlockButton === totalBlockButtons.Given"
          class="btn-number btn-number-full-amount"
          [cellText]="topPopularNote.cellText"
          [cellValue]="topPopularNote.cellValue"
          [actionType]="VirtualKeyboardActionTypes.Total"
          (onClickCell)="handleOnClick($event)"
        >
        </virtual-keyboard-cell>
      </td>
      <td>
        <printer-link class="btn-number" posMode="cash-register" [isArrowRight]="true"></printer-link>
      </td>
    </tr>
    <tr *ngIf="displayPopularNotes || dispayTip">
      <td *ngFor="let item of popularNotesList; trackBy: trackByFn">
        <virtual-keyboard-cell
          [cellText]="item.cellText"
          [cellValue]="item.cellValue"
          [actionType]="actionType"
          (onClickCell)="handleOnClick($event)"
        >
        </virtual-keyboard-cell>
      </td>
    </tr>
    <tr>
      <td><virtual-keyboard-cell cellValue="7" (onClickCell)="handleOnClick($event)"></virtual-keyboard-cell></td>
      <td><virtual-keyboard-cell cellValue="8" (onClickCell)="handleOnClick($event)"></virtual-keyboard-cell></td>
      <td><virtual-keyboard-cell cellValue="9" (onClickCell)="handleOnClick($event)"></virtual-keyboard-cell></td>
    </tr>
    <tr>
      <td><virtual-keyboard-cell cellValue="4" (onClickCell)="handleOnClick($event)"></virtual-keyboard-cell></td>
      <td><virtual-keyboard-cell cellValue="5" (onClickCell)="handleOnClick($event)"></virtual-keyboard-cell></td>
      <td><virtual-keyboard-cell cellValue="6" (onClickCell)="handleOnClick($event)"></virtual-keyboard-cell></td>
    </tr>
    <tr>
      <td><virtual-keyboard-cell cellValue="1" (onClickCell)="handleOnClick($event)"></virtual-keyboard-cell></td>
      <td><virtual-keyboard-cell cellValue="2" (onClickCell)="handleOnClick($event)"></virtual-keyboard-cell></td>
      <td><virtual-keyboard-cell cellValue="3" (onClickCell)="handleOnClick($event)"></virtual-keyboard-cell></td>
    </tr>
    <tr>
      <td *ngIf="keyboardType === 'AMOUNT' || keyboardType === 'CHECKOUT'">
        <virtual-keyboard-cell
          cellText="00"
          cellValue="double_zero"
          [actionType]="VirtualKeyboardActionTypes.Special"
          (onClickCell)="handleOnClick($event)"
        ></virtual-keyboard-cell>
      </td>
      <td [colSpan]="zeroCellColspan">
        <virtual-keyboard-cell cellValue="0" (onClickCell)="handleOnClick($event)"></virtual-keyboard-cell>
      </td>
      <td>
        <virtual-keyboard-cell
          cellValue="delete"
          [actionType]="VirtualKeyboardActionTypes.Special"
          (onClickCell)="handleOnClick($event)"
        ></virtual-keyboard-cell>
      </td>
    </tr>
  </tbody>
</table>
