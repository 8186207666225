import { Entity } from './entity.class';
import { PaymentResult } from './payment-result.class';
import { DefaultPaymentMethods } from '@pos-common/constants/default-payment-methods.enum';
import { EntityInterface } from '@pos-common/interfaces/entity.interface';
import { GiftCard } from '@pos-modules/assing-gift-card/gift-card';
/**
 * Created by maksymkunytsia on 10/11/16.
 */

export class InvoicePayment extends Entity {
  uuid: string;
  method: string;
  amount: number;
  amountGiven: number;
  merchantReceipt: string;
  cardholderReceipt: string;
  deleted: boolean;
  amountForShow: number;
  paymentProvider: string;
  date: string;
  terminalTransaction: {};
  giftCard: EntityInterface;

  constructor(data) {
    super(data);
    this.method = data.method || null;
    this.amount = data.amount || 0;
    this.amountGiven = data.amountGiven || 0;
    this.amountForShow = data.method === DefaultPaymentMethods.CASH ? this.amountGiven : this.amount;
    this.merchantReceipt = data.merchantReceipt || null;
    this.cardholderReceipt = data.cardholderReceipt || null;
    this.deleted = data.deleted || false;
    this.paymentProvider = data.paymentProvider || null;
    this.date = data.date || null;
    this.terminalTransaction = data.terminalTransaction || null;
    this.giftCard = data.giftCard ? new GiftCard(data.giftCard) : null;
  }

  setPaymentResultData(paymentResult: PaymentResult) {
    this.paymentProvider = paymentResult.paymentProvider;
    this.date = paymentResult.date;
    this.merchantReceipt = paymentResult.merchantReceipt;
    this.cardholderReceipt = paymentResult.cardholderReceipt;
    if (paymentResult.terminalTransaction) {
      this.terminalTransaction = paymentResult.terminalTransaction;
    }
  }
}
