import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { VirtualKeyboardActionTypes } from '@pos-common/constants';
import { IVirtualKeyboardEvent } from '@pos-common/interfaces';

@Component({
  selector: 'virtual-keyboard-cell',
  styleUrls: ['virtual-keyboard-cell.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'virtual-keyboard-cell.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualKeyboardCell implements OnInit {
  @Input() cellText: string;
  @Input() cellValue: string | number;
  @Input() actionType = VirtualKeyboardActionTypes.Number;
  @Output() onClickCell = new EventEmitter<IVirtualKeyboardEvent>();
  public isDeleteButton = false;

  ngOnInit() {
    this.cellText = this.cellText || this.cellValue.toString();
    this.isDeleteButton = this.cellValue === 'delete';
  }

  public handleClick() {
    const data: IVirtualKeyboardEvent = {
      action: this.cellValue,
      actionType: this.actionType,
    };
    this.onClickCell.emit(data);
  }
}
