<pos-terminal-view
  *ngIf="activeTerminal"
  [activeTerminal]="activeTerminal"
  [useTerminalPrinter]="terminalPrinter"
  (onUseTerminalPrinter)="useTerminalPrinter($event)"
  (onTipAllowed)="useTippingOnTerminal($event)"
  (onDisconnect)="disconnect($event)"
></pos-terminal-view>

<div class="device-choice" *ngIf="!activeTerminal">
  <div class="device-group">
    <div class="device-group-label">{{ 'choose_type_of_terminal_to_connect' | translate }}:</div>
    <div class="device-group-row">
      <div class="device-label paymashpay" (click)="connectTerminal(TerminalNames.PAYMASH_PAY)" tappable>
        <div class="bg-img"></div>
      </div>
      <div *ngIf="hasMyPosMiniPlugin && !isMyPosDevice" class="device-label mini" (click)="connectTerminal(TerminalNames.MINI)" tappable>
        <div class="bg-img"></div>
      </div>
      <div class="device-label sumup" (click)="connectTerminal(TerminalNames.SUMUP)" tappable>
        <div class="bg-img"></div>
      </div>
      <div class="device-label six" (click)="connectTerminal(TerminalNames.SIX)" tappable>
        <div class="bg-img"></div>
      </div>
    </div>
  </div>
  <div class="device-group">
    <div class="device-group-label">{{ 'or_choose_protocol' | translate }}:</div>
    <div class="device-group-row">
      <div class="device-label opi" (click)="connectTerminal(TerminalNames.OPI)" tappable>
        <div class="bg-img">OPI</div>
      </div>
    </div>
  </div>
</div>
