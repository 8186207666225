import { IDiscovery } from '../IDiscovery';
import { Subject } from 'rxjs';
import { UniversalCustomerDisplayProvider } from '../UniversalCustomerDisplayProvider';
import { Device, ConnectionInfo, SavedDeviceInfo } from './Device';
import { CustomerDisplayStatus } from '../../ICustomerDisplayDevice';
import { CustomerDisplayPlugin } from './Plugin';
import { ILogger, ILoggerFactory } from '@spryrocks/logger';

type Display = { displayId: number };

export class InAppDiscovery extends IDiscovery<Device, ConnectionInfo> {
  private readonly logger: ILogger

  public readonly discoveryId = 'InAppDiscovery';

  private readonly devices_: Device[] = [];
  private readonly devicesSubject = new Subject<Device[]>();

  constructor(
    private readonly provider: UniversalCustomerDisplayProvider,
    private readonly loggerFactory: ILoggerFactory,
  ) {
    super();
    this.logger = loggerFactory.createLogger("InAppDiscovery")
  }

  get devices() {
    return this.devicesSubject;
  }

  private isDiscoveryStarted = false;

  async startDiscovery() {
    if (this.isDiscoveryStarted) {
      this.logger.debug("Discovery already started, skipping...");
      return;
    }
    this.isDiscoveryStarted = true;

    this.logger.debug("Start discovery");

    const arrayContainsDevice = (list: Array<Device>, device: Device) => {
      return list.find((d) => d.id === device.id);
    };

    const addDevice = (device: Device) => {
      this.logger.debug("Add device", {device});
      if (arrayContainsDevice(this.devices_, device)) {
        this.logger.info('This device already added', { device });
        return;
      }

      this.devices_.push(device);
      this.logger.info('Device added successfully', { device });
    };

    const removeDevice = (device: Device) => {
      this.logger.debug("Remove device", {device});
      const index = this.devices_.findIndex((d) => d.id === device.id);
      if (index <= -1) {
        this.logger.info('This device not exists in the list', { device });
        return;
      }
      this.devices_.splice(index, 1);
      this.logger.info('Device removed successfully', { device });
    };

    CustomerDisplayPlugin.availableDisplays().subscribe((result) => {
      this.logger.info("List of available displays changed", {result})
      if (result.action === 'list') {
        const foundDevices = result.displays.map(d => this.createDeviceFromDisplay(d));
        for (const device of [...this.devices_]) {
          if (!arrayContainsDevice(foundDevices, device)) {
            removeDevice(device);
          }
        }

        for (const device of foundDevices) {
          if (!arrayContainsDevice(this.devices_, device)) {
            addDevice(device);
          }
        }
      } else if (result.action === 'added') {
        const device = this.createDeviceFromDisplay({ displayId: result.displayId });
        addDevice(device);
      } else if (result.action === 'removed') {
        const device = this.createDeviceFromDisplay({ displayId: result.displayId });
        removeDevice(device);
      }

      this.devices_.forEach((device, i, { length }) => {
        let name = 'External Screen';
        if (length > 1) name += ` ${i + 1}`;
        device.setName(name);
      });

      this.updateDevices();
    });
  }

  async stopDiscovery() {
    this.logger.debug("Discovery stopped, but this method makes no sense");
  }

  private updateDevices() {
    const devices = [...this.devices_];
    this.logger.debug("Device list updated", {devices});
    this.devicesSubject.next(devices);
  }

  getDeviceInfo(device: Device): SavedDeviceInfo {
    return {
      discoveryId: device.discoveryId,
      providerId: device.providerId,
      deviceId: device.id,
      deviceName: device.name,
      connectionInfo: device.connectionInfo,
      autoConnect: device.autoConnect,
      isOffline: device.status !== 'disconnected',
    };
  }

  private createDeviceFromDisplay({ displayId }: Display) {
    const id = `inapp_display_${displayId}`;
    const name = `Display ${displayId}`;
    const connectionInfo: ConnectionInfo = {
      displayId,
    };
    return this.createDevice(
      id, name,
      'disconnected',
      connectionInfo,
      true, true,
    );
  }

  private createDevice(
    id: string,
    name: string,
    status: CustomerDisplayStatus,
    connectionInfo: ConnectionInfo,
    discovered: boolean,
    autoConnect: boolean | undefined,
    temporary: boolean = true,
  ) {
    return new Device(
      this.loggerFactory,
      this.provider.providerId,
      this.discoveryId,
      id, name,
      status, connectionInfo,
      discovered,
      autoConnect, temporary,
    );
  }

  restoreDeviceFromInfo(deviceInfo: SavedDeviceInfo): Device {
    return this.createDevice(
      deviceInfo.deviceId,
      deviceInfo.deviceName,
      deviceInfo.isOffline ? 'offline' : 'disconnected',
      deviceInfo.connectionInfo,
      false,
      deviceInfo.autoConnect,
    );
  }
}
