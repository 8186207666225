import { ElasticReporterNativeFs as ElasticReporterNativeFsBase } from '@paymash/logger-capacitor';
import { DirectoryEntry, FileEntry } from '@ionic-native/file/ngx';
import { FileService } from '@pos-common/services/system/file';

export class ElasticReporterNativeFs implements ElasticReporterNativeFsBase<FileEntry, DirectoryEntry> {
  constructor(private readonly fileService: FileService) {
  }

  readFile(fileEntry: FileEntry) {
    return this.fileService.readFileInternalAsync(fileEntry);
  }

  async removeFile(file: FileEntry) {
    await this.fileService.removeFileInternalAsync(file);
  }

  async getFiles(directory: DirectoryEntry) {
    return this.fileService.getFilesInternalAsync(directory);
  }

  appendFile(file: FileEntry, text: string) {
    return this.fileService.writeFileInternalAsync(file, text, { append: true });
  }

  createDirectory(
    parent: DirectoryEntry,
    name: string,
  ) {
    return this.fileService.createDirectoryAsync(parent, name);
  }

  createFile(parent: DirectoryEntry, name: string) {
    return this.fileService.createFileAsync(parent, name);
  }

  getFileSize(file: FileEntry) {
    return this.fileService.getFileSizeInternalAsync(file);
  }

  resolveDataDirectory() {
    return this.fileService.resolveDataDirectoryAsync();
  }
}
