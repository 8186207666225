/**
 * Created by maksymkunytsia on 10/11/16.
 */

export const LOCALE = {
  DateFormat: {
    DD_MM_YYYY_DOT: 'DD.MM.YYYY',
    DD_MM_YYYY_SLASH: 'DD/MM/YYYY',
    DD_MM_YYYY_DASH: 'DD-MM-YYYY',
    MM_DD_YYYY_SLASH: 'MM/DD/YYYY',
    YYYY_MM_DD_DASH: 'YYYY-MM-DD',
    YYYY_MM_DD_DOT: 'YYYY.MM.DD',
    YYYY_MM_DD_SLASH: 'YYYY/MM/DD',
    YYYY_MM_DD_HH_mm_DASH: 'YYYY-MM-DDTHH:mm',
  },
  DateFormatInvoicesList: {
    DD_MM_YYYY_DOT: 'DD.MM',
    DD_MM_YYYY_SLASH: 'DD/MM',
    DD_MM_YYYY_DASH: 'DD-MM',
    MM_DD_YYYY_SLASH: 'MM/DD',
    YYYY_MM_DD_DASH: 'MM-DD',
    YYYY_MM_DD_DOT: 'MM.DD',
    YYYY_MM_DD_SLASH: 'MM/DD',
  },
  StartWeekOn: {
    MONDAY: 'MONDAY',
    SUNDAY: 'SUNDAY',
    SATURDAY: 'SATURDAY',
  },
  NumberFormat: {
    APOSTROPHE_DOT: {
      thousand: "'",
      decimal: '.',
    },
    COMMA_DOT: {
      thousand: ',',
      decimal: '.',
    },
    DOT_COMMA: {
      thousand: '.',
      decimal: ',',
    },
    BLANK_COMMA: {
      thousand: ' ',
      decimal: ',',
    },
  },
  TimeFormat: {
    H24: 'HH:mm',
    H12: 'hh:mm',
    HH: 'HH:mm',
    hh: 'hh:mm',
  },
  Currency: {
    CHF: 'CHF',
    EUR: 'EUR',
    USD: 'USD',
  },
  UnitSystem: {
    METRIC: 'METRIC',
    IMPERIAL: 'IMPERIAL',
  },
  WeightUnit: {
    KILOGRAM: 'KILOGRAM',
    POUND: 'POUND',
  },
  CurrencyRounding: {
    ZERO_DOT_ZERO_ONE: 0.01,
    ZERO_DOT_ZERO_FIVE: 0.05,
    ZERO_DOT_ONE_ZERO: 0.1,
    ZERO_DOT_FIVE_ZERO: 0.5,
    ONE_DOT_ZERO_ZERO: 1,
  },
};
