import { EventEmitter, Injectable } from '@angular/core';

import { UPDATES_TYPES } from '../../constants/updates-types.const';

import { SyncService } from './sync.service';
import { DbDaoService, GetAllDataOptions } from '../db/db-dao.service';
import { SecurityService } from './security.service';
import { InvoiceListService } from './invoice-list.service';
import { InvoicesService } from './invoices.service';
import { CartService } from './cart.service';

import { Invoice } from '@pos-common/classes/invoice.class';
import { Subject, BehaviorSubject } from 'rxjs';
import { TableEnforceService } from './table-select-enforcement.service';
import { LogService } from './logger/log.service';
import { NavController, mdTransitionAnimation } from '@ionic/angular';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';
import { query, group, or, isNull, Query, by, oneOf, from, property, leftJoin, contains } from '@paymash/capacitor-database-plugin';
import { map } from 'rxjs/operators';
import { InvoicesProvider } from '../resources/invoices-db-entity.provider';
import { InvoceInlist } from '@pos-common/classes/invoice-in-list.class';
import { StoresProvider } from '../resources/stores-db-entity.provider';
import { SALES_CHANNEL_TYPES } from '@pos-common/constants/sales-channel-types';
import { DbResponse } from '../db/db-dao.utils';
import { INVOICE_TYPES } from '@pos-common/constants/invoice-types';

@Injectable()
export class InvoicesFilterService {
  constructor(
    public SyncService: SyncService,
    public DbDaoService: DbDaoService,
    private invoicesProvider: InvoicesProvider,
    public InvoiceListService: InvoiceListService,
    public InvoicesService: InvoicesService,
    public CartService: CartService,
    public TableEnforceService: TableEnforceService,
    public SecurityService: SecurityService,
    public navController: NavController,
    private storesProvider: StoresProvider,
    private logService: LogService
  ) {}

  public ivoiceFilterData: Subject<any> = new BehaviorSubject<any>(null);
  public invoiceListSubject: Subject<any> = new BehaviorSubject<any>(null);
  public deleteEvent: Subject<any> = new BehaviorSubject<any>(null);
  private updateEvent$ = new Subject<void>();
  private updateInvoiceList$ = new Subject<void>();
  public openInvoiceCount: Subject<any> = new BehaviorSubject<any>(null);
  public newTableInvoiceEvent = new EventEmitter();
  public newInvoiceEvent = new EventEmitter();
  public currentFilterSet: any = null;
  public hallList: any = [];

  public getInvoiceFilter() {
    return this.ivoiceFilterData.asObservable();
  }

  public setInvoiceFilter(newData) {
    this.currentFilterSet = newData;
    this.ivoiceFilterData.next(newData);
  }

  public emitDeleteInvoiceEvent(uuid) {
    this.deleteEvent.next(uuid);
  }

  public deleteInvoiceEvent() {
    return this.deleteEvent.asObservable();
  }

  public emitUpdateEvent() {
    this.updateEvent$.next();
  }

  public getUpdateEvent() {
    return this.updateEvent$.asObservable();
  }

  public updateInvoiceList() {
    this.updateInvoiceList$.next();
  }

  public getUpdateInvoiceList() {
    return this.updateInvoiceList$.asObservable();
  }

  public getInvoices() {
    return this.invoiceListSubject.asObservable();
  }

  public setInvoices(data: InvoceInlist[]) {
    this.invoiceListSubject.next(data);
  }

  public getInvoiceCountFromDB() {
    const { isDraft, isNotDeleted, storeUuid, isPosSalesChannel } = this.queryHelpers();
    const queryParams = query(isDraft, storeUuid, isPosSalesChannel, isNotDeleted);
    return this.invoicesProvider
      .getCountByParams(queryParams)
      .toPromise()
      .then((count) => count);
  }

  public checkTableBusyStatus(tableUuid: string): Promise<boolean> {
    const { isDraft, isNotDeleted, storeUuid, isPosSalesChannel } = this.queryHelpers();
    const queryParams = query({ 'gastronomyTable.uuid': tableUuid }, isDraft, storeUuid, isPosSalesChannel, isNotDeleted);
    return this.invoicesProvider
      .getCountByParams(queryParams)
      .pipe(map((value) => !!value))
      .toPromise();
  }

  private buildFilter(): Query {
    const queryParams: Query = query({
      'i.ext_type': isNull(),
    });

    let { isDraft, isNotDeleted, storeUuid, isPosSalesChannel } = this.queryHelpers('i.');

    if (!this.currentFilterSet) {
      return undefined;
    }

    if (this.currentFilterSet.tab === 'open' || this.currentFilterSet.tab === 'paid') {
      isDraft = { 'i.isDraft': this.currentFilterSet.tab === 'open' };
    } else if (this.currentFilterSet.tab === 'no') {
      queryParams.push({ 'i.gastronomyTable': isNull() });
    } else {
      if (this.currentFilterSet.table === 'all') {
        queryParams.push({ 'i.gastronomyTable.uuid': oneOf(...this.currentFilterSet.tableList) });
      } else {
        queryParams.push({ 'i.gastronomyTable.uuid': this.currentFilterSet.table });
      }
    }

    queryParams.push(isDraft, storeUuid, isNotDeleted, isPosSalesChannel);

    return queryParams;
  }

  private async loadGastronomyHallItems(): Promise<void> {
    this.hallList = await this.DbDaoService.getAllDataRaw(UPDATES_TYPES.GastronomyHall.type);
  }

  private prepareOpenInvoices(prepared: InvoceInlist[]) {
    prepared.forEach((invoice) => {
      if (!invoice.gastronomyTable) return;
      this.hallList.forEach((hall) => {
        hall.gastronomyTables.forEach((table) => {
          if (table.uuid === invoice.gastronomyTable.uuid) invoice.hallName = hall.name;
        });
      });
    });
  }

  public getDataFromDb2(start: number, end: number) {
    const queryParams = this.buildFilter();
    const options: GetAllDataOptions = {
      join: [
        leftJoin(UPDATES_TYPES.Invoice.type, 'e', {
          'e.uuid': property('i.employee.uuid'),
          'e.ext_type': 'employee',
        }),
        leftJoin(UPDATES_TYPES.Invoice.type, 'c', {
          'c.uuid': property('i.customer.uuid'),
          'c.ext_type': 'customer',
        }),
        leftJoin(UPDATES_TYPES.Invoice.type, 's', {
          's.uuid': property('i.store.uuid'),
          's.ext_type': 'store',
        }),
      ],
      offset: start,
      limit: end,
      order: [by('i.fieldToSort', 'descending')],
    };
    let promise = Promise.resolve();
    if (this.currentFilterSet?.tab === 'open' && start === 0) {
      promise = this.loadGastronomyHallItems();
    }

    return promise
      .then(() => this.DbDaoService.getAllData(from(UPDATES_TYPES.Invoice.type, 'i'), queryParams, options))
      .then((data) => {
        return data.data.map((d) => ({
          ...d.i,
          employee: d.e,
          customer: d.c,
          store: d.s,
        }));
      })
      .then((data) => {
        const prepared = this.InvoiceListService.prepareListForUI(data);
        if (this.currentFilterSet?.tab === 'open') {
          this.prepareOpenInvoices(prepared);
        }
        return prepared;
      });
  }

  public getWebshopInvoiceList(start: number, end: number, searchValue: string) {
    const { isNotDeleted } = this.queryHelpers('i.');
    const queryParams: Query = query(
      {
        'i.ext_type': isNull(),
        'i.salesChannel': SALES_CHANNEL_TYPES.WEBSHOP,
        'i.invoiceType': oneOf(INVOICE_TYPES.INVOICE, INVOICE_TYPES.CANCELLATION),
      },
      isNotDeleted
    );
    const options: GetAllDataOptions = {
      join: [
        leftJoin(UPDATES_TYPES.Invoice.type, 'c', {
          'c.uuid': property('i.customer.uuid'),
          'c.ext_type': 'customer',
        }),
      ],
      offset: start,
      limit: end,
      order: [by('i.fieldToSort', 'descending')],
    };

    if (searchValue) {
      queryParams.push(group({ 'i.fieldToSearch': contains(searchValue) }, or({ 'c.fieldToSearch': contains(searchValue) })));
    }

    return this.getWebshopStore()
      .then((store) => {
        if (store?.uuid) {
          queryParams.push({ 'i.storeUuid': store.uuid });
          return this.DbDaoService.getAllData(from(UPDATES_TYPES.Invoice.type, 'i'), queryParams, options);
        }
        return { data: [] } as DbResponse;
      })
      .then((data) => {
        return data.data.map((d) => ({
          ...d.i,
          customer: d.c,
        }));
      })
      .then((data) => {
        const prepared = this.InvoiceListService.prepareListForUI(data);
        return prepared;
      });
  }

  public async loadHalls(list: InvoceInlist[]) {
    await this.loadGastronomyHallItems();
    this.prepareOpenInvoices(list);
  }

  public changeTablesInDb(tableUuid, tableName) {
    // console.log('here changeTablesInDb', tableUuid, tableName);
    const queryHelpers = this.queryHelpers();
    const { isDraft, isNotDeleted, storeUuid, isPosSalesChannel } = queryHelpers;
    const queryParams = query({ 'gastronomyTable.uuid': tableUuid }, isDraft, storeUuid, isPosSalesChannel, isNotDeleted);
    this.DbDaoService.getAllData(UPDATES_TYPES.Invoice.type, queryParams)
      .then((data) => {
        if (data && data['data'] && data['data'].length >= 1) {
          data['data'].map((invoice) => {
            if (!tableName) {
              invoice.gastronomyTable = null;
              invoice.gastronomyTableName = null;
            } else {
              invoice.gastronomyTableName = tableName;
            }
            this.InvoicesService.saveInvoice(invoice).catch((err) =>
              this.logService.error('InvoicesFilterService', 'changeTablesInDb:saveInvoice', err)
            );
          });
        }
      })
      .catch((err) => this.logService.error('InvoicesFilterService', 'changeTablesInDb:getDataByParams', err));
  }

  public getNoRoomInvoices() {
    const { isDraft, isNotDeleted, storeUuid, isPosSalesChannel } = this.queryHelpers();
    const queryParams = query({ gastronomyTable: isNull() }, isDraft, storeUuid, isPosSalesChannel, isNotDeleted);
    return this.invoicesProvider
      .getCountByParams(queryParams)
      .pipe(map((value) => !!value))
      .toPromise();
  }

  public sortInvoicesByDate(list) {
    return list.sort((a, b) =>
      a.date && b.date
        ? new Date(b.date).valueOf() - new Date(a.date).valueOf()
        : new Date(b.modificationDate).valueOf() - new Date(a.modificationDate).valueOf()
    );
  }

  public setTableToHall(tableUuid) {
    let tableName = this.hallList.filter(
      (hall) => hall.gastronomyTables.filter((table) => table.uuid === tableUuid && hall.name).length !== 0 && hall.name
    );
    return tableName && tableName.length !== 0 ? tableName[0].name : null;
  }

  public async checkTableExist(tableUuid): Promise<string | null> {
    await this.loadGastronomyHallItems();
    let tableName = this.hallList.filter(
      (hall) => hall.gastronomyTables.filter((table) => table.uuid === tableUuid && !table.deleted).length !== 0 && hall.name
    );
    return tableName && tableName.length !== 0 ? tableName[0].name : null;
  }

  public clickInvoiceHandler(invoice: any, shouldUpdateData: boolean = false) {
    if (!invoice.isDraft) {
      this.navController
        .navigateForward(ROUTE_URLS.success, {
          state: { invoiceUUID: invoice.uuid, fromState: 'Invoices' },
          animation: mdTransitionAnimation,
        })
        .catch((err) => this.logService.error('InvoicesFilterService', 'clickInvoiceHandler:navigateForward:success', err));
      return;
    }
    this.DbDaoService.getDataByUUID(UPDATES_TYPES.Invoice.type, invoice.uuid)
      .then((data) => {
        if (data['data']) {
          this.navController
            .navigateRoot(ROUTE_URLS.collection, { state: { fromState: 'Invoices' } })
            .catch((err) => this.logService.error('InvoicesFilterService', 'clickInvoiceHandler:navigateRoot:collection', err));
          this.CartService.setActiveInvoice(new Invoice(data['data']));
        }
      })
      .catch((err) => this.logService.error('InvoicesFilterService', 'clickInvoiceHandler:getDataByUUID', err));
  }

  public newTableInvoice() {
    this.newTableInvoiceEvent.emit(true);
  }

  public newInvoice() {
    this.newInvoiceEvent.emit(true);
  }

  public combineInvoice() {
    return this.navController.navigateForward(ROUTE_URLS.invoicesCombine, { animation: mdTransitionAnimation });
  }

  private queryHelpers(prefix: string = '') {
    const storeUuid = this.SecurityService.getActiveStore().uuid;
    return {
      isDraft: { [prefix + 'isDraft']: true },
      isNotDeleted: group({ [prefix + 'deleted']: isNull() }, or({ [prefix + 'deleted']: false })),
      storeUuid: { [prefix + 'storeUuid']: storeUuid },
      isPosSalesChannel: { [prefix + 'salesChannel']: SALES_CHANNEL_TYPES.POS },
    };
  }

  private getWebshopStore() {
    const { isNotDeleted } = this.queryHelpers();
    const queryParams = query({ isWebshopStore: true }, isNotDeleted);
    return this.storesProvider
      .getListByParams(queryParams, { select: ['uuid'] })
      .pipe(map((stores) => (!!stores.length ? stores[0] : null)))
      .toPromise();
  }
}
