import { Observable } from 'rxjs';
import { ICustomerDisplayDevice } from './ICustomerDisplayDevice';
import { ICustomerDisplaySessionDelegate } from './ICustomerDisplaySessionDelegate';
import { ICustomerDisplaySession } from './ICustomerDisplaySession';

export abstract class ICustomerDisplayProvider {
  abstract turnOn(): Promise<void>;

  abstract turnOff(): Promise<void>;

  abstract get providerId(): string;

  abstract initialize(delegate: ICustomerDisplaySessionDelegate): Promise<void>;

  abstract get devices(): Observable<ICustomerDisplayDevice[]>;

  abstract startDeviceDiscovery(): void;

  abstract stopDeviceDiscovery(): void;

  abstract connect(device: ICustomerDisplayDevice): Promise<void>;

  abstract disconnect(device: ICustomerDisplayDevice, session: ICustomerDisplaySession | undefined): Promise<void>;
}
