<ng-container [ngSwitch]="itemType">
  <div class="row row-label-value filter-item" *ngSwitchCase="CALENDAR_FILTER_ITEM_TYPES.CHECKBOX" (click)="selectItem(item)" tappable>
    <span class="row-label">{{ item.title }}</span>
    <div class="pos-checkbox">
      <div class="checkbox-icon" [class.checkbox-checked]="item.isSelected">
        <div class="checkbox-inner"></div>
      </div>
    </div>
  </div>

  <form [formGroup]="formGroup">
    <div class="row row-label-value filter-item" *ngSwitchCase="CALENDAR_FILTER_ITEM_TYPES.AMOUNT">
      <span class="row-label">{{ item.title }}</span>
      <span class="row-value">
        <input type="text" formControlName="amount" [placeholder]="placeholder" autocomplete="off" />
      </span>
    </div>

    <div class="row row-label-value filter-item" *ngSwitchCase="CALENDAR_FILTER_ITEM_TYPES.DATE">
      <span class="row-label">{{ item.title }}</span>
      <span class="row-value">
        <pos-date [value]="formGroup.controls.interval.value" (posSetValue)="setIntervalValue($event)"></pos-date>
      </span>
    </div>
  </form>
</ng-container>
