import { Component, Input, ElementRef, ViewEncapsulation, ChangeDetectorRef, OnInit, Renderer2, NgModule } from '@angular/core';
import { AlertService } from '../../services/system/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from '../../services/system/network.service';
import { SyncService } from '../../services/system/sync.service';
import { UpdatesService } from '../../services/system/updates.service';
import { AlertOptions } from '../../interfaces/alert.inteface';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'sync-icon',
  templateUrl: './sync-icon.component.html',
  styleUrls: ['./sync-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SubSinkService],
})
export class SyncIcon implements OnInit {
  @Input() public speed: number;
  public isSyncDownInProgress: boolean = false;
  public isSyncUpInProgress: boolean = false;
  public isOffline: boolean = false;
  public itemsOnSync: number = 0;

  constructor(
    private syncService: SyncService,
    private updatesService: UpdatesService,
    private NetworkService: NetworkService,
    private elementRef: ElementRef,
    private AlertService: AlertService,
    private TranslateService: TranslateService,
    private subSinkService: SubSinkService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!this.speed) this.speed = 300;
    this.isOffline = this.NetworkService.getConnectionStatus();
    this.subSinkService.sink = this.syncService.synchronizationCountSubject.subscribe((itemsOnSync) => {
      this.itemsOnSync = itemsOnSync;
      this.cdr.detectChanges();
    });
    this.subSinkService.sink = this.NetworkService.internetStatusChangeEvent.subscribe((isOffline) => {
      this.isOffline = isOffline;
      if (isOffline) {
        this.renderer.addClass(this.elementRef.nativeElement, 'offline');
      } else {
        this.renderer.removeClass(this.elementRef.nativeElement, 'offline');
      }
      this.cdr.detectChanges();
    });
    this.subSinkService.sink = this.syncService.synchronizationInProgressSubject.subscribe((isSyncUpInProgress) => {
      this.isSyncUpInProgress = isSyncUpInProgress;
      this.cdr.detectChanges();
    });
    this.subSinkService.sink = this.updatesService.getSyncGettingDataStatusEvent().subscribe((isSyncDownInProgress) => {
      this.isSyncDownInProgress = isSyncDownInProgress;
      this.cdr.detectChanges();
    });
  }

  async syncInfo() {
    const alertData: AlertOptions = {};
    if (this.isOffline) {
      alertData.header = this.TranslateService.instant('global_status_offline_title');
      alertData.subHeader = this.TranslateService.instant('global_status_offline_message');
    } else {
      alertData.header = this.TranslateService.instant('global_status_unsynced_items_subtitle', { count: this.itemsOnSync });
      alertData.subHeader = this.TranslateService.instant('global_status_unsynced_items_message');
    }
    alertData.buttons = ['OK'];
    const alert = await this.AlertService.create(alertData);
    await alert.present();
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [SyncIcon],
  exports: [SyncIcon],
})
export class SyncIconModule {}
