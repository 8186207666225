import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Device } from '@ionic-native/device/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Market } from '@ionic-native/market/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HTTP as AdvancedHTTP } from '@ionic-native/http/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { CartService } from './services/system/cart.service';
import { UserIdleService } from './services/system/idle.service';
import { LoadingService } from './services/system/loading.service';
import { MessagesService } from './services/system/messages.service';
import { NetworkService } from './services/system/network.service';
import { SecurityService } from './services/system/security.service';
import { SyncService } from './services/system/sync.service';
import { UpdatesService } from './services/system/updates.service';
import { LocalStorage } from './services/utils/localstorage.utils';
import { UuidService } from './services/utils/uuid.utils';
import { MathUtils } from './services/utils/math.utils';
import { ImageLoadService } from './services/system/image-load.service';
import { ImageLoadWbService } from './services/system/image-load-wb.service';
import { PrinterService } from './services/system/printer.service';
import { IntercomService } from '@pos-common/services/system/intercom/intercom.service';
import { ModalService } from './services/system/modal.service';
import { PosInfoService } from './services/system/pos-info.service';
import { AlertService } from './services/system/alert.service';
import { InvoiceListService } from './services/system/invoice-list.service';
import { InvoicesService } from './services/system/invoices.service';
import { TableEnforceService } from './services/system/table-select-enforcement.service';
import { AppUpdateService } from './services/system/app-update.service';
import { PaymentService } from './services/system/payment.service';
import { SocketScannerService } from './services/system/socket-scanner/socket-scanner.service';
import { SocketScannerPluginProxy } from './services/system/socket-scanner/socket-scanner-plugin.proxy';
import { ScannerAppService } from './services/system/scanner-app.service';
import { CameraScannerService } from './services/system/camera-scanner.service';
import { RequestsWbService } from './services/system/requests-wb.service';
import { GoogleAnalyticsService } from './services/system/google-analitycs.service';
import { CollectionViewService } from './services/system/collection-view.service';
import { InvoiceSendService } from './services/system/invoice-send.service';
import { AdminPageService } from './services/system/admin-page.service';
import { IngenicoService } from './services/system/ingenico.service';
import { InvoicesFilterService } from './services/system/invoice-filter.service';
import { NotifyPopupsService } from './services/system/notify-popups.service';
import { NullifyModalService } from './services/system/nullify-modal.service';
import { ImageUtils } from './services/utils/image.utils';
import { PaymentProcessingService } from './components/payment-processing/payment-processing.service';
import { TimApiService } from './services/system/timApi.service';
import { ReceiptBuilderService } from './services/system/receipt-builder.service';
import { BarcodeScannerService } from './services/system/barcode-scanner.service';
import { ImageLoadingService } from './services/system/image-loading.service';
import { AwsService } from './services/system/aws.service';
import { PosSettingsService } from './services/system/pos-settings.service';
import { PaymentMethodsDbEntityProvider } from './services/resources/payment-methods-db-entity.provider';
import { PrintTransactionReceipts } from './services/system/print-transaction-receipts.service';
import { VirtualPrintersProvider } from './services/resources/virtual-printers-db-entity.provider';
import { EmployeesProvider } from './services/resources/employees-db-entity.provider';
import { StoresProvider } from './services/resources/stores-db-entity.provider';
import { InvoicesProvider } from './services/resources/invoices-db-entity.provider';
import { ProductVariantProvider } from './services/resources/product-variant-db-entity.provider';
import { ProductProvider } from './services/resources/product-db-entity.provider';
import { ProductCategoryProvider } from './services/resources/product-category-db-entity.provider';
import { ToastService } from './services/system/toast.service';
import { UserNotificationService } from './services/system/user-notification/user-notification.service';
import { ReceiptAutoPrintingService } from './services/system/receipt-auto-printing/receipt-auto-printing.service';
import { InvoiceProcessingService } from './services/system/invoice-processing/invoice-processing.service';
import { GiftCardsProvider } from './services/resources/gift-cards.provider';
import { GiftCardsProviderAbstract } from './services/resources/gift-cards.provider.abstract';
import { PosSelfCheckService } from './services/system/pos-self-check.service';
import { Ionic3Utils } from './services/utils/ionic3.utils';
import { InitResolver } from './guards/init.resolver';
import { AuthGuard } from './guards/auth.guard';
import { CodecUtils } from './services/utils/codec.utils';
import { CheckoutAsideService } from './services/system/checkout-aside/checkout-aside.service';
import { InvoiceCancellationService } from './services/system/invoice-cancellation/invoice-cancellation.service';
import { FileReaderUtils } from './services/utils/file-reader/file-reader.utils';
import { MyPosService } from './services/system/devices/my-pos/my-pos.service';
import { PlatformService } from './services/system/platform/platform.service';
import { KeyboardService } from './services/system/keyboard/keyboard.service';
import { TippingService } from './services/system/tipping/tipping.service';
import { PermissionService } from './services/system/permission/permission.service';
import { MultipleGuestsService } from './services/system/multiple-guests/multiple-guests.service';
import { CameraScannerAdapterService } from './services/system/camera-scanner-adapter/camera-scanner-adapter.service';
import { ICustomerDisplayService } from '@pos-common/services/system/customer-display';
import { CustomerDisplayService } from '@pos-common/services/system/customer-display/CustomerDisplayService';
import { CustomersProvider, GastronomyHallsProvider, VatRateProvider } from './services/resources';
import { SumUpService } from './services/system/sumup/sumup.service';
import { IDeviceReportingService, DeviceReportingService } from '@pos-common/services/system/device-report';
import { ISunmiService, SunmiService } from './services/system/devices/sunmi/sunmi.service';
import { SearchUtils } from './services';
import { AdyenService, IAdyenService } from '@pos-common/services/system/devices/adyen/adyen.service';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { ImageLoaderService } from 'ionic-image-loader-v5';
import { ImageLoaderLibService } from './services/system/image-loader-lib.service';
import { ShiftsApiService } from '../pages/shifts/shifts-api.service';
import { FileService } from './services/system/file';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { DeviceReportingStore } from './services/system/device-report/device-report.store';
import { DeviceReportingSync } from './services/system/device-report/device-report.sync';
import { SubSinkService } from './services/system/sub-sink/sub-sink.service';
import { AutofillInputService } from './services/utils/autofill-input/autofill-input.service';
import { EffectService } from './services/system/effect/effect.service';
import { ActionSheetService } from './services/system/action-sheet/action-sheet.service';
import { AppointmentService } from './services/system/appointment/appointment.service';
import { AppointmentModalService } from './services/system/appointment-modal/appointment-modal.service';
import { CalendarService } from './services/system/calendar/calendar.service';
import { CameraService } from './services/system/camera/camera.service';
import { SplashScreenService, TranslateSystemService } from './services/system';

@NgModule({
  providers: [
    NativeStorage,
    Device,
    OpenNativeSettings,
    AppVersion,
    InAppBrowser,
    Network,
    Market,
    SplashScreen,
    AdvancedHTTP,
    StatusBar,
    AndroidPermissions,
    CartService,
    UserIdleService,
    LoadingService,
    MessagesService,
    NetworkService,
    SecurityService,
    SyncService,
    UpdatesService,
    LocalStorage,
    UuidService,
    MathUtils,
    ImageLoadService,
    ImageLoadWbService,
    PrinterService,
    TranslateService,
    ModalService,
    IntercomService,
    PosInfoService,
    AlertService,
    InvoiceListService,
    InvoicesService,
    TableEnforceService,
    AppUpdateService,
    PaymentService,
    SumUpService,
    SocketScannerService,
    SocketScannerPluginProxy,
    ScannerAppService,
    CameraScannerService,
    RequestsWbService,
    GoogleAnalyticsService,
    CollectionViewService,
    InvoiceSendService,
    AdminPageService,
    {
      provide: ICustomerDisplayService,
      useClass: CustomerDisplayService,
    },
    IngenicoService,
    InvoicesFilterService,
    NotifyPopupsService,
    NullifyModalService,
    ImageUtils,
    PaymentProcessingService,
    TimApiService,
    ReceiptBuilderService,
    BarcodeScannerService,
    ImageLoadingService,
    AwsService,
    PosSettingsService,
    PaymentMethodsDbEntityProvider,
    PrintTransactionReceipts,
    VirtualPrintersProvider,
    EmployeesProvider,
    CustomersProvider,
    StoresProvider,
    VatRateProvider,
    InvoicesProvider,
    GastronomyHallsProvider,
    ProductVariantProvider,
    ProductProvider,
    ProductCategoryProvider,
    ToastService,
    UserNotificationService,
    ReceiptAutoPrintingService,
    InvoiceProcessingService,
    PosSelfCheckService,
    { provide: GiftCardsProviderAbstract, useClass: GiftCardsProvider },
    CodecUtils,
    Ionic3Utils,
    AuthGuard,
    InitResolver,
    InvoiceCancellationService,
    CheckoutAsideService,
    FileReaderUtils,
    MyPosService,
    PlatformService,
    KeyboardService,
    TippingService,
    PermissionService,
    MultipleGuestsService,
    CameraScannerAdapterService,
    {
      provide: ISunmiService,
      useClass: SunmiService,
    },
    {
      provide: IAdyenService,
      useClass: AdyenService,
    },
    {
      provide: IDeviceReportingService,
      useClass: DeviceReportingService,
    },
    SearchUtils,
    WebView,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ImageLoaderService,
      useClass: ImageLoaderLibService,
    },
    ShiftsApiService,
    FileService,
    NetworkInterface,
    DeviceReportingStore,
    DeviceReportingSync,
    SubSinkService,
    AutofillInputService,
    EffectService,
    ActionSheetService,
    AppointmentService,
    AppointmentModalService,
    CalendarService,
    CameraService,
    SplashScreenService,
    TranslateSystemService,
  ],
})
export class AppCommonModule {
  constructor(@Optional() @SkipSelf() parentModule: AppCommonModule) {
    if (parentModule) {
      throw new Error('AppCommonModule is already loaded. Import it in the AppModule only');
    }
  }
}
