import { Injectable } from '@angular/core';
import { MyPosService } from '../devices/my-pos/my-pos.service';

@Injectable()
export class EffectService {
  constructor(private myPosService: MyPosService) {}

  isDisableEffect(): boolean {
    return this.myPosService.isDisableEffect;
  }

  setBackgroundColor(element: HTMLElement, color: string) {
    if (color) {
      let backgroundColor = color;
      if (backgroundColor.match('pos')) {
        backgroundColor = `var(${backgroundColor})`;
      }
      element.style.backgroundColor = backgroundColor;
    }
  }

  setOpacity(element: HTMLElement, opacity: string) {
    if (opacity) {
      element.style.opacity = opacity;
    }
  }

  setAnimationDuration(element: HTMLElement, animationDuration: string) {
    if (animationDuration) {
      element.style.animationDuration = `${animationDuration}s`;
    }
  }

  getPosition(event: any, hostElement: HTMLElement, element: HTMLElement) {
    let x = 0;
    let y = 0;
    const eventType = event.type;
    if (eventType === 'click') {
      x = event.pageX;
      y = event.pageY;
    } else if (eventType === 'touchstart') {
      try {
        let origEvent;

        if (typeof event.changedTouches !== 'undefined') {
          origEvent = event.changedTouches[0];
        } else {
          origEvent = event.originalEvent;
        }

        x = origEvent.pageX;
        y = origEvent.pageY;
      } catch (e) {
        // fall back to center of el
        x = element.offsetWidth / 2;
        y = element.offsetHeight / 2;
      }
    }

    const offsets = this.getOffsets(hostElement);
    const dinstance = Math.max(hostElement.clientWidth, hostElement.clientHeight);
    const left = x - offsets.left - dinstance / 2 + 'px';
    const top = y - offsets.top - dinstance / 2 + 'px';

    return { top, left };
  }

  private getOffsets(element: HTMLElement) {
    const de = document.documentElement;
    const box = element.getBoundingClientRect();
    const top = box.top + window.pageYOffset - de.clientTop;
    const left = box.left + window.pageXOffset - de.clientLeft;
    return { top, left };
  }
}
