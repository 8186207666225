<ion-backdrop tappable></ion-backdrop>
<div class="cart-modal-inner">
  <pos-header>
    <button tappable (click)="closeModal()" header-start>
      <pos-icon customName="custom-icon-cancel-search"></pos-icon>
    </button>
    <ion-title header-title>{{ 'customers_select_customer' | translate }}</ion-title>
    <button (click)="confirmModal()" header-end tappable>
      <pos-icon customName="custom-icon-select"></pos-icon>
    </button>
  </pos-header>
  <div class="cart-modal-body">
    <div class="row row-label-value customer-create-row">
      <button class="create-customer-button" (click)="createNewCustomer()" tappable>
        <pos-icon customName="custom-icon-add-custom-product"></pos-icon>
        <span>{{ 'customers_new_customer' | translate }}</span>
      </button>
    </div>
    <div class="row row-label-value customer-search-row">
      <pos-customer-search
        [showEditButton]="true"
        [selectedCustomerUuid]="customer?.uuid"
        (onSelectCustomer)="selectCustomer($event)"
        (onEditCustomer)="editCustomer($event)"
      ></pos-customer-search>
    </div>
  </div>
  <div class="cart-modal-footer">
    <ng-container *ngIf="showClearButton">
      <pos-button
        class="cart-modal-button-remove"
        label="common_remove"
        color="--pos-red"
        (onClick)="removeCustomerFromInvoice()"
      ></pos-button>
      <pos-button class="cart-modal-button-done" label="common_done" (onClick)="confirmModal()"></pos-button>
    </ng-container>
    <pos-button *ngIf="!showClearButton" label="common_done" (onClick)="confirmModal()"></pos-button>
  </div>
</div>
