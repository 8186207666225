import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import { Invoice } from '@pos-common/classes/invoice.class';
import { SwipeDirection } from '@pos-common/constants/swipe-direction.enum';
import { MultipleGuestsService } from '@pos-common/services/system/multiple-guests/multiple-guests.service';

@Component({
  selector: 'cart-invoice-entry-guest',
  templateUrl: './cart-invoice-entry-guest.component.html',
  styleUrls: ['./cart-invoice-entry-guest.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartInvoiceEntryGuestComponent implements OnInit {
  @Input() activeInvoice: Invoice;
  @Input() invoiceEntryGuest: InvoiceEntryGuest;
  @Input() showUnprintedKitchenItems: boolean;
  @Input() isGastroMode: boolean;
  @Output() onSelectGuest = new EventEmitter<number>();
  @Output() onEditGuest = new EventEmitter<InvoiceEntryGuest>();
  @Output() onRemoveGuest = new EventEmitter<number>();

  public isChangeableGuestButton: boolean;

  constructor(private multipleGuestsService: MultipleGuestsService) {}

  ngOnInit() {
    this.isChangeableGuestButton = this.multipleGuestsService.isMultipleGuests;
  }

  selectGuest() {
    this.onSelectGuest.emit(this.invoiceEntryGuest.guestNumber);
  }

  editGuest() {
    this.onEditGuest.emit(this.invoiceEntryGuest);
  }

  removeGuest(event: any) {
    if (event.direction === SwipeDirection.BACK) {
      this.onRemoveGuest.emit(this.invoiceEntryGuest.guestNumber);
    }
  }
}
