import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ViewEncapsulation } from '@angular/core';
import { General } from './general/general.component';
import { Printers } from './printers/printers.component';
import { Maintenance } from './maintenance/maintenance.component';
import { Terminals } from './terminals/terminals.component';
import { Scanners } from './scanners/scanners.component';
import { Displays } from './displays/displays.component';
import { MyPosService } from '@pos-common/services/system/devices/my-pos/my-pos.service';
import { PreloadNextModules } from '@pos-common/services/system';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';

@Component({
  selector: 'settings-page',
  styleUrls: ['settings.page.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'settings.page.html',
})
export class SettingsPage {
  private component: ComponentRef<{}>;
  public cView: string = null;
  public isMobile: boolean = false;
  public isMyPosDevice: boolean = false;

  @ViewChild('routerOutlet', { read: ViewContainerRef, static: true })
  viewContainerRef: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private myPosService: MyPosService,
    private preloadNextModules: PreloadNextModules
  ) {}

  ngOnInit() {
    this.isMyPosDevice = this.myPosService.isMyPosDevice;
    this.isMobile = window.innerWidth > 750 ? false : true;
    if (!this.isMobile) {
      this.setView('general');
    }
    this.preloadNextModules.preloadNextPages(ROUTE_URLS.settings);
  }

  setView(param) {
    if (this.cView !== param) {
      this.cView = param;
      switch (param) {
        case 'general':
          this.setComponent(General);
          break;
        case 'maintenance':
          this.setComponent(Maintenance);
          break;
        case 'terminals':
          this.setComponent(Terminals);
          break;
        case 'printers':
          this.setComponent(Printers);
          break;
        case 'scanners':
          this.setComponent(Scanners);
          break;
        case 'displays':
          this.setComponent(Displays);
          break;
      }
    }
  }

  setComponent(component: any) {
    if (this.component) {
      this.component.destroy();
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.viewContainerRef.clear();
    this.component = this.viewContainerRef.createComponent<any>(componentFactory);
  }

  closeView() {
    this.cView = null;
    if (this.component) {
      this.component.destroy();
    }
  }
}
