/**
 * Created by maksymkunytsia on 9/22/16.
 */

export const SALUTATIONS_TYPES = {
    MR: {
        title: 'MR'
    },
    MRS: {
        title: 'MRS'
    },
    EMPTY: {
        title: 'EMPTY'
    }
};
