/**
 * Created by maksymkunytsia on 11/11/16.
 */

// Vendors
import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { MathUtils } from '../../../common/services/utils/math.utils';
import { DISCOUNTS_TYPES } from '../../../common/constants/discounts-types.const';
import { LogService } from '../../../common/services/system/logger/log.service';
import { IDiscountUpdate } from '@pos-common/interfaces';

@Component({
  selector: 'invoice-discount-modal',
  templateUrl: './invoice-discount-modal.component.html',
  styleUrls: ['./invoice-discount-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvoiceDiscountModal {
  // input data
  public discount: number = 0;
  public discountPercentage: number = 0;
  public newDiscount: boolean = true;
  // output data
  public discountType: string = DISCOUNTS_TYPES.PERCENTAGE;
  public discountCents: any = '';
  public discountDecimal: any = 0;

  constructor(
    public ViewController: ModalController,
    public navParams: NavParams,
    public MathUtils: MathUtils,
    private logService: LogService
  ) {}

  ngOnInit() {
    let invoiceDiscountData: any = this.navParams.get('invoiceDiscountData');

    if (invoiceDiscountData.discount) {
      this.discountType = DISCOUNTS_TYPES.DISCOUNT;
      this.discount = invoiceDiscountData.discount;
      this.discountPercentage = 0;
      this.newDiscount = false;
      this.setDiscountCents('0' + MathUtils.roundHalfUp(invoiceDiscountData.discount * 100, 2).toString(), invoiceDiscountData.discount);
    } else if (invoiceDiscountData.discountPercentage) {
      this.discountType = DISCOUNTS_TYPES.PERCENTAGE;
      this.discount = 0;
      this.discountPercentage = invoiceDiscountData.discountPercentage;
      this.newDiscount = false;
      this.setDiscountCents('0' + invoiceDiscountData.discountPercentage.toString(), invoiceDiscountData.discountPercentage);
    }
  }

  public setDiscountCents(discountCents: string, discountDecimal: number) {
    this.discountCents = 0;
    this.discountDecimal = 0;
    this.discountCents = discountCents;
    this.discountDecimal = discountDecimal;
  }

  public discountTypeOrAmountChanged(discountUpdate: IDiscountUpdate) {
    const { discountType, discountCents, discountDecimal } = discountUpdate;
    this.discountType = discountType;
    this.setDiscountCents(discountCents, discountDecimal);
  }

  public closeModal(addDiscountToCart: boolean, type?: string) {
    if (addDiscountToCart) {
      if (type === 'footer-remove') {
        this.ViewController.dismiss({ closedByUser: true }).catch((err) =>
          this.logService.error('InvoiceDiscountModal', 'closeModal:ViewController:dismiss', err)
        );
      } else {
        this.ViewController.dismiss({
          closedByUser: true,
          discountDecimal: this.discountDecimal,
          discountType: this.discountType,
        }).catch((err) => this.logService.error('InvoiceDiscountModal', 'closeModal:ViewController:dismiss', err));
      }
    } else {
      this.ViewController.dismiss().catch((err) => this.logService.error('InvoiceDiscountModal', 'closeModal:ViewController:dismiss', err));
    }
  }
}
