import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, NgModule } from '@angular/core';
import { IListViewPlusMore } from '@pos-common/interfaces';
import { BadgeComponentModule } from '../badge/badge.component';

@Component({
  selector: 'pos-list-view-plus-more',
  templateUrl: './list-view-plus-more.component.html',
  styleUrls: ['./list-view-plus-more.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListViewPlusMoreComponent implements OnChanges {
  @Input() list: IListViewPlusMore[] | string[];
  public name: string;
  public count: number;

  ngOnChanges(changes: SimpleChanges): void {
    const { list } = changes;
    if (!list.currentValue.length) {
      return;
    }
    this.setName(list.currentValue);
    this.setCount(list.currentValue);
  }

  private setName(list: IListViewPlusMore[]) {
    const [firstItem] = list;
    this.name = '-';
    if (typeof firstItem === 'string') {
      this.name = firstItem;
      return;
    }
    if (firstItem.name) {
      this.name = firstItem.name;
      return;
    }
    if (firstItem.firstName) {
      this.name = firstItem.lastName ? firstItem.firstName + ' ' + firstItem.lastName : firstItem.firstName;
      return;
    }
  }

  private setCount(list: IListViewPlusMore[]) {
    const [, ...restItems] = list;
    if (!restItems.length) {
      return;
    }
    this.count = restItems.length;
  }
}

@NgModule({
  imports: [CommonModule, BadgeComponentModule],
  declarations: [ListViewPlusMoreComponent],
  exports: [ListViewPlusMoreComponent],
})
export class ListViewPlusMoreComponentModule {}
