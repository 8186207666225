import { ModuleWithProviders, NgModule } from '@angular/core';
import { MyPosMiniFacade } from './my-pos-mini.facade';

@NgModule({})
export class MyPosMiniModule {
  static forRoot(): ModuleWithProviders<MyPosMiniModule> {
    return {
      ngModule: MyPosMiniModule,
      providers: [MyPosMiniFacade],
    };
  }
}
