import {
  IDeviceReportingRegistration,
  IServiceWithReporting,
  ServiceReport,
  ReportingDevice,
  IDeviceReportingService,
} from '@pos-common/services/system/device-report';
import {} from '@pos-common/services/system/device-report/device-report.models';
import { ReceiptPrinter } from '@pos-common/services/system/receipt-printers/classes/receipt-printer.class';
import { ReceiptPrintersService } from '@pos-common/services/system/receipt-printers';

type ReportingEventType = 'Added' | 'Updated' | 'Removed';

interface ReceiptPrinterReportingDevice extends ReportingDevice {
  deviceIdentifier: string;
}

export class ReceiptPrintersReporting implements IServiceWithReporting {
  private reporting: IDeviceReportingRegistration<ReportingEventType>;

  constructor(private readonly receiptPrintersService: ReceiptPrintersService, deviceReportingService: IDeviceReportingService) {
    this.reporting = deviceReportingService.registerService('ReceiptPrinters', this);
  }

  private createDeviceReport(printer: ReceiptPrinter): ServiceReport<ReceiptPrinterReportingDevice> {
    return {
      device: {
        name: printer.deviceName,
        deviceIdentifier: printer.macAddress,
      },
      extra: printer,
    };
  }

  createReportForAllDevices() {
    return this.receiptPrintersService.getSavedPrinters().map((printer) => this.createDeviceReport(printer));
  }

  sendEvent(event: ReportingEventType, printer: ReceiptPrinter) {
    this.reporting.report(event, this.createDeviceReport(printer));
  }
}
