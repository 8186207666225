import { Injectable } from '@angular/core';
import { SecurityService } from '../system/security.service';
import { Employee } from '@pos-common/classes/employee.class';
import { SecuredResponse } from '@pos-common/classes/secured-response.class';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';

@Injectable()
export class EmployeesApiService {
  private readonly endpoint = 'employees/';
  constructor(private SecurityService: SecurityService) {}

  public getCurrentUser() {
    return this.SecurityService.doSecureRequest(`${SERVER_CONFIG.API_URL}${this.endpoint}current`, 'get', null).then(
      (response: SecuredResponse) => new Employee(response.data.properties)
    );
  }
}
