import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ToggleCustomEvent } from '@ionic/angular';
import { TerminalNames } from '@pos-common/constants/terminal-names.enum';
import { IActiveTerminal } from '@pos-common/interfaces';

@Component({
  selector: 'pos-terminal-view',
  templateUrl: './terminal-view.component.html',
  styleUrls: ['./terminal-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminalViewComponent {
  @Input() activeTerminal: IActiveTerminal;
  @Input() useTerminalPrinter: boolean = false;
  @Output() onDisconnect = new EventEmitter<string>();
  @Output() onUseTerminalPrinter = new EventEmitter<boolean>();
  @Output() onTipAllowed = new EventEmitter<boolean>();

  get isOpiTerminal() {
    return this.isDevice(TerminalNames.OPI);
  }

  get isMiniTerminal() {
    return this.isDevice(TerminalNames.MINI);
  }

  get isSixTerminal() {
    return this.isDevice(TerminalNames.SIX);
  }

  get status() {
    return this.activeTerminal.connected ? 'receipt_printer_status_connected' : 'receipt_printer_status_disconnected';
  }

  handleDisconnect() {
    this.onDisconnect.emit(this.activeTerminal.name);
  }

  handleUseTerminalPrinter(event: Event) {
    const useTerminalPrinter = (<ToggleCustomEvent>event).detail.checked;
    this.onUseTerminalPrinter.emit(useTerminalPrinter);
  }

  handleTipAllowed(event: Event) {
    const isTipAllowed = (<ToggleCustomEvent>event).detail.checked;
    this.onTipAllowed.emit(isTipAllowed);
  }

  private isDevice(name: string) {
    return this.activeTerminal.name === name;
  }
}
