import { Component, ViewEncapsulation, ChangeDetectionStrategy, NgModule } from '@angular/core';

@Component({
  selector: 'common-header',
  styleUrls: ['common-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'common-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonHeaderComponent {}

@NgModule({
  declarations: [CommonHeaderComponent],
  exports: [CommonHeaderComponent],
})
export class CommonHeaderComponentModule {}
