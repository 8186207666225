import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { CALENDAR_VIEWS, CALENDAR_TIME_RANGE, CALENDAR_DATE_RANGE } from '@pos-common/constants';
import { ModalService } from '@pos-common/services/system/modal.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { CalendarFilterModalPage } from '@pos-modules/modals/calendar-filter-modal';
import { CalendarService } from '@pos-common/services/system/calendar/calendar.service';
import { CalendarListFilterModalPage } from '@pos-modules/modals/calendar-list-filter-modal';
import { mdTransitionAnimation, NavController, ViewWillEnter } from '@ionic/angular';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';
import { PreloadNextModules } from '@pos-common/services/system';

@Component({
  selector: 'pos-calendar',
  templateUrl: './calendar.page.html',
  styleUrls: ['./calendar.page.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarPage implements OnInit, ViewWillEnter {
  public activeTimeRange = CALENDAR_TIME_RANGE.day;
  public activeView = CALENDAR_VIEWS.CALENDAR;
  get CALENDAR_VIEWS() {
    return CALENDAR_VIEWS;
  }

  constructor(
    private modalService: ModalService,
    private calendarService: CalendarService,
    private navController: NavController,
    private preloadNextModules: PreloadNextModules,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.calendarService.initFilterForms();
    this.preloadNextModules.preloadNextPages(ROUTE_URLS.calendar);
  }

  ionViewWillEnter(): void {
    this.calendarService.setRenderCalendar();
  }

  selectTimeRange(timeRange: CALENDAR_TIME_RANGE) {
    if (timeRange === CALENDAR_TIME_RANGE.list) {
      this.activeView = CALENDAR_VIEWS.LIST;
    } else {
      this.activeView = CALENDAR_VIEWS.CALENDAR;
    }
    this.activeTimeRange = timeRange;
  }

  selectToday() {
    this.selectTimeRange(CALENDAR_TIME_RANGE.day);
    this.calendarService.setDateRange(CALENDAR_DATE_RANGE.TODAY);
  }

  selectDay() {
    this.selectTimeRange(CALENDAR_TIME_RANGE.day);
  }

  async createNewAppointment() {
    try {
      await this.navController.navigateForward(ROUTE_URLS.appointment, { animation: mdTransitionAnimation });
    } catch (error) {
      this.logService.error('CalendarPage', 'createNewAppointment', error);
    }
  }

  openFilter() {
    if (this.activeView === CALENDAR_VIEWS.CALENDAR) {
      this.openCalendarFilter();
      return;
    }
    this.openCalendarListFilter();
  }

  toggleListSearch() {
    this.selectTimeRange(CALENDAR_TIME_RANGE.list);
    this.calendarService.setShowSearch(true);
  }

  private async openCalendarFilter() {
    try {
      const modal = await this.modalService.presentModal(CalendarFilterModalPage);
      await modal.present();
    } catch (error) {
      this.logService.error('CalendarPage', 'openCalendarFilter', error);
    }
  }

  private async openCalendarListFilter() {
    try {
      const modal = await this.modalService.presentModal(CalendarListFilterModalPage);
      await modal.present();
    } catch (error) {
      this.logService.error('CalendarPage', 'openCalendarFilter', error);
    }
  }
}
