import { Injectable } from '@angular/core';
import { Employee } from '@pos-common/classes/employee.class';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { PushNotifications } from '@pos-common/classes/capacitor-plugins/capacitor-plugins.class';
import { PlatformService } from '../platform/platform.service';
import { IntercomUserUpdateOptions, Intercom } from '@paymash/capacitor-intercom-plugin';
import { Company } from '@pos-common/classes/company.class';
import { PAYMASH_PROFILE } from '@profile';
import { ILogger } from '@spryrocks/logger';
import { LogService } from '@pos-common/services/system/logger';

@Injectable()
export class IntercomService {
  private readonly logger: ILogger;

  constructor(
    private localStorage: LocalStorage,
    private platformService: PlatformService,
    logService: LogService,
    ) {
    this.logger = logService.createLogger('IntercomService');
  }

  public async boot(company: Company, employee: Employee) {
    if (this.platformService.isIOS) await PushNotifications.register();
    if (this.platformService.isWeb) await Intercom['start'](PAYMASH_PROFILE.intercom.appId);

    await Intercom.registerIdentifiedUser({
      userId: employee.uuid,
      email: employee.email,
    });

    await this.updateUser({
      userId: employee.uuid,
      email: employee.email,
      name: employee.employeeDisplayName,
      languageOverride: 'DE',
      customAttributes: {},
    });

    if (this.platformService.isAndroid) {
      await Intercom.hideLauncher();
    }
    const userOptions = this.getUserOptions(company, employee);
    await this.updateUser(userOptions);
  }

  public shutdown(): Promise<void> {
    return Intercom.logout();
  }

  public show(): Promise<void> {
    return Intercom.displayMessenger();
  }

  public restart(company: Company, employee: Employee): Promise<void> {
    return this.shutdown()
      .then(() => this.boot(company, employee))
      .catch((error) => this.logger.error(error, "Restart intercom error"));
  }

  private updateUser(_options: IntercomUserUpdateOptions): Promise<void> {
    return Intercom.updateUser(_options);
  }

  private getUserOptions(company: Company, employee: Employee): IntercomUserUpdateOptions {
    return {
      userId: employee.uuid,
      email: employee.email,
      name: employee.employeeDisplayName,
      languageOverride: 'DE',
      customAttributes: {
        companies: [
          {
            company_id: company.uuid,
            name: company.name,
            plan: {
              type: 'plan',
              name: 'Paid',
            },
          },
        ],
        is_manager: employee.hasAdminPermission,
        can_cancel_invoice: employee.hasCancellationPermission,
        app_type: PAYMASH_PROFILE.intercom.appType,
        currency: company.locale.currency,
        is_restaurant_enabled: company.isRestaurantEnabled,
        vat_number: company.vatNumber,
        performance_score: this.localStorage.get('performanceTest') || null,
      },
    };
  }
}
