/**
 * Created by y.belinsky on 3/29/17.
 */

export class RksvReceipt {
  public type: string;
  public qr: string;
  public cashRegisterSerialNo: string;
  public receiptNumber: number;
  public signingDate: string;

  constructor(data) {
    this.type = data.type || null;
    this.qr = data.qr || null;
    this.receiptNumber = data.receiptNumber || 0;
    this.cashRegisterSerialNo = data.cashRegisterSerialNo || null;
    this.signingDate = data.signingDate || null;
  }
}
