import { CommonPrinterService } from '@pos-common/services/system/receipt-printers/services/common-printer.service';
import { PrinterServiceInterface } from '@pos-common/interfaces/printer-service.interface';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ReceiptPrinter } from '@pos-common/services/system/receipt-printers/classes/receipt-printer.class';
import { MYPOS } from '@pos-common/constants/mypos.const';
import { ReceiptBuilder } from '@pos-common/services/system/receipt-printers/classes/receipt-builder.class';
import { AdyenPaymentApi } from '@pos-common/services/system/adyen/services/api';
import { AdyenUtils } from '@pos-common/services/system/adyen/adyen-utils';
import {
  PrinterTextItem,
  PrintRequest,
  PrintReceiptRequestOptions,
  PrintTextRequest,
  PrintQrCodeRequest,
  PrintImageRequest,
} from '@pos-common/services/system/adyen/adyen-types';
import { IAdyenService } from '@pos-common/services/system/devices/adyen/adyen.service';

type PrintItemText = {
  text: string;
};

type PrintItemQrCode = {
  qrCode: string;
};

type PrintItemImage = {
  image: string;
};

type PrintData = (PrintItemText | PrintItemQrCode | PrintItemImage)[];

export abstract class IAdyenPrinterService extends CommonPrinterService implements PrinterServiceInterface {}

export class AdyenPrinterService extends IAdyenPrinterService {
  constructor(
    injector: Injector,
    private readonly adyenPaymentApi: AdyenPaymentApi,
    private readonly utils: AdyenUtils,
    private readonly adyenService: IAdyenService
  ) {
    super(injector);
  }

  protected get discoverMethodName(): string {
    return '';
  }

  protected get pluginName(): string {
    return '';
  }

  protected get printMethodName(): string {
    return '';
  }

  protected get stopDiscoverMethodName(): string {
    return '';
  }

  protected logsSubscription() {
    return;
  }

  protected discover(): Observable<ReceiptPrinter[]> {
    this.getPrinter();
    return this.printerList;
  }

  protected stopDiscover() {
    return Promise.resolve();
  }

  public checkPermissionsOnAndroid(): Promise<boolean> {
    return Promise.resolve(true);
  }

  private getPrinter(): Observable<ReceiptPrinter[]> {
    const isAdyenPrinterEnabled = this.adyenService.isPrinterConnected;

    if (isAdyenPrinterEnabled) {
      const deviceName = 'Adyen';
      const deviceType = 'Printer';
      const printerType = 'ADYEN';

      const printer = new ReceiptPrinter({
        printerName: deviceName,
        deviceName,
        deviceType,
        ipAddress: MYPOS.IP_ADDRESS,
        isActive: true,
        macAddress: MYPOS.MAC_ADDRESS,
        printerNameForModelPick: printerType,
        target: MYPOS.TARGET,
      });

      this.handleDiscover([printer]);
      return;
    }
    this.handleDiscover([]);
  }

  async print(device: ReceiptPrinter, data: ReceiptBuilder) {
    const printData = data.receiptForSunmi;
    const printRequests = this.createPrintRequests(printData);
    for (let request of printRequests) {
      await this.adyenPaymentApi.printReceipt(this.createPrintOptions(request));
    }
  }

  private createPrintOptions(request: PrintRequest): PrintReceiptRequestOptions {
    return {
      ...this.utils.createCommonRequestOptions(),
      ...request,
    };
  }

  private createPrintRequests(printData: PrintData): PrintRequest[] {
    const requests: PrintRequest[] = [];
    let printTextRequest: PrintTextRequest | undefined;

    const pushPrintTextRequest = () => {
      if (!printTextRequest) return;
      requests.push(printTextRequest);
      printTextRequest = undefined;
    };

    const pushPrintQrCodeRequest = (request: PrintQrCodeRequest) => {
      requests.push(request);
    };

    const pushPrintImageRequest = (request: PrintImageRequest) => {
      requests.push(request);
    };

    const addTextItem = (textItem: PrinterTextItem) => {
      if (!printTextRequest) printTextRequest = { type: 'text', textItems: [] };
      printTextRequest.textItems.push(textItem);
    };

    const addQrCode = (qrCode: string) => {
      pushPrintTextRequest();
      pushPrintQrCodeRequest({
        type: 'qrCode',
        qrCode,
      });
    };

    const addImage = (imageBase64: string) => {
      pushPrintTextRequest();
      pushPrintImageRequest({
        type: 'image',
        imageBase64,
      });
    };

    const processTextItem = ({ text: multilineText }: PrintItemText) => {
      if (multilineText.endsWith('\n')) {
        multilineText = multilineText.substring(0, multilineText.length - 1);
      }
      for (let line of multilineText.split('\n')) {
        addTextItem({
          Text: line,
          EndOfLineFlag: true,
        });
      }
    };

    const processQrCodeItem = ({ qrCode }: PrintItemQrCode) => {
      addQrCode(qrCode);
    };

    const processImageItem = ({ image: imageBase64 }: PrintItemImage) => {
      addImage(imageBase64);
    };

    for (let dataItem of printData) {
      if ('text' in dataItem) {
        processTextItem(dataItem);
      } else if ('qrCode' in dataItem) {
        processQrCodeItem(dataItem);
      } else if ('image' in dataItem) {
        processImageItem(dataItem);
      }
    }

    pushPrintTextRequest();

    return requests;
  }
}
