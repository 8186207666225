import { ProductOrCategoryType } from '@pos-common/types/product-or-category.type';

export class ProductItem {
  uuid: string;
  visible: boolean;
  data: ProductOrCategoryType | any;
  isCategory: boolean;
  expanded: boolean;
  productCategoryUuid: string;
  name?: string;

  constructor(data: any, productCategoryUuid: string, isCategory: boolean = false) {
    this.isCategory = isCategory;
    this.data = data;
    this.uuid = data.uuid || '';
    this.visible = data.visible || false;
    this.productCategoryUuid = productCategoryUuid || '';
    this.expanded = data.expanded || false;
  }
}
