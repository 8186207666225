import { Injectable } from '@angular/core';
import { PAYMASH_PROFILE } from '@profile';
import { ElasticLogger as ElasticLoggerBase } from '@paymash/logger-capacitor';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable()
export class ElasticLogger extends ElasticLoggerBase {
  constructor(http: HTTP) {
    super(http, {
      serverUrl: PAYMASH_PROFILE.LOGGER.url,
      system: `pm.pos.${PAYMASH_PROFILE.APP_ENV}`,
      username: PAYMASH_PROFILE.LOGGER.username,
      password: PAYMASH_PROFILE.LOGGER.password,
      debug: true,
    });
  }
}
