import { Injectable } from '@angular/core';
import { DeviceReportingStore } from './device-report.store';
import { LogService } from '../logger/log.service';

@Injectable()
export class DeviceReportingSync {
  private static readonly interval = 5 * 1000;
  private timeout: NodeJS.Timeout | undefined;

  constructor(private store: DeviceReportingStore, private logService: LogService) {}

  start() {
    this.stop();
    this.sendEvents();
  }

  stop() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = undefined;
  }

  private async sendEvents() {
    if (!cordova) return;

    try {
      await this.sendEventsInternal();
    } catch (error) {
      this.logService.error('DeviceReportingSync', 'sendEventsInternal', error);
    } finally {
      this.scheduleNext();
    }
  }

  private async sendEventsInternal() {
    const unsavedEvents = this.store.getUnsavedEvents();
    if (!unsavedEvents.length) return;

    // const prepareEvent = ({ uuid, source, type, device, service, created, extra, internal: _ }: ReportingEvent) => ({
    //   subsystem: 'device-reporting',
    //   source,
    //   service,
    //   event: {
    //     uuid,
    //     type,
    //     created,
    //     device,
    //     extra,
    //   },
    // });

    // TODO: Need to catch error from elastic
    // await this.elasticLogger.sendLogs(unsavedEvents.map(prepareEvent));
    await this.store.markEventsAsSaved(unsavedEvents);
  }

  private scheduleNext() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.sendEvents(), DeviceReportingSync.interval);
  }
}
