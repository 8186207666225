<ion-menu side="start" swipeGesture="false" contentId="main">
  <ion-toolbar (click)="hideSideMenu()" primary>
    <div class="ion-toolbar-img">
      <img src="assets/images/Paymash-logo-blue.svg" width="150" />
    </div>
  </ion-toolbar>
  <ion-content id="content">
    <ion-list>
      <current-user class="tablet-hide"></current-user>
      <ion-item
        button
        detail-none
        *ngFor="let page of pages; trackBy:trackByFn"
        (click)="goToPage(page)"
        detail="false"
        [class.page-selected]="page.isSelected"
      >
        <i class="menu-icon menu-icon-{{page.icon}}"></i>
        <span>{{page.title | translate}}</span>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>

<!-- Disable swipe-to-go-back because it's poor UX to combine STGB with side menus -->
<ion-router-outlet id="main"></ion-router-outlet>
<notify-popups></notify-popups>
