import { NgModule, Optional, SkipSelf } from '@angular/core';
import { Migration, Migrations } from './migrations';
import { migrations } from './migrations/Migrations';
import { DbDaoService } from '@pos-common/services/db/db-dao.service';
import { DbDaoUtils } from '@pos-common/services/db/db-dao.utils';

@NgModule({
  providers: [
    DbDaoService,
    DbDaoUtils,
    Migration,
    {
      provide: Migrations,
      useValue: migrations,
    },
    ...migrations.map((migration) => ({
      provide: migration,
    })),
  ],
})
export class DbModule {
  constructor(@Optional() @SkipSelf() parentModule: DbModule) {
    if (parentModule) {
      throw new Error('DbModule is already loaded. Import it in the AppModule only');
    }
  }
}
