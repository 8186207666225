import { IDiscovery } from '../IDiscovery';
import { Subject } from 'rxjs';
import { UniversalCustomerDisplayProvider } from '../UniversalCustomerDisplayProvider';
import { Device, ConnectionInfo, SavedDeviceInfo } from './Device';
import { CustomerDisplayStatus } from '@pos-common/services/system/customer-display/ICustomerDisplayDevice';
import { ILoggerFactory } from '@spryrocks/logger';

export class WebDemoDiscovery extends IDiscovery<Device, ConnectionInfo> {
  public readonly discoveryId = 'WebDemoDiscovery';

  private readonly devices_: Device[] = [];
  private readonly devicesSubject = new Subject<Device[]>();

  constructor(
    private readonly provider: UniversalCustomerDisplayProvider,
    private readonly loggerFactory: ILoggerFactory,
  ) {
    super();
  }

  get devices() {
    return this.devicesSubject;
  }

  async startDiscovery() {
    if (this.devices_.length < 1) {
      this.devices_.push(this.createDevice('device_0', 'First testing display app', 'disconnected', true, false));
      this.devices_.push(this.createDevice('device_1', 'Second display app', 'disconnected', true, false));
    }
    this.updateDevices();
  }

  async stopDiscovery() {}

  private updateDevices() {
    this.devicesSubject.next([...this.devices_]);
  }

  getDeviceInfo(device: Device): SavedDeviceInfo {
    return {
      discoveryId: device.discoveryId,
      providerId: device.providerId,
      deviceId: device.id,
      deviceName: device.name,
      connectionInfo: device.connectionInfo,
      autoConnect: device.autoConnect,
      isOffline: device.status !== 'disconnected',
    };
  }

  private createDevice(id: string, name: string, status: CustomerDisplayStatus, discovered: boolean, autoConnect: boolean | undefined) {
    return new Device(this.loggerFactory, this.provider.providerId, this.discoveryId, id, name, status, {}, discovered, autoConnect);
  }

  restoreDeviceFromInfo(deviceInfo: SavedDeviceInfo): Device {
    return this.createDevice(
      deviceInfo.deviceId,
      deviceInfo.deviceName,
      deviceInfo.isOffline ? 'offline' : 'disconnected',
      false,
      deviceInfo.autoConnect
    );
  }
}
