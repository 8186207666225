<table class="table table-report-week">
  <thead>
    <tr>
      <td colspan="5" class="ion-no-padding">
        <h2 class="block-name">{{reportDate | CompanyLocalized: 'dateFormat'}}</h2>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr class="block-header">
      <td class="left-align">{{'label.reports.period' | translate}}</td>
      <td>{{'label.reports.nrOfSales' | translate}}</td>
      <td>{{'label.reports.nrOfInvoices' | translate}}</td>
      <td>{{'label.reports.stornos' | translate}}</td>
      <td>{{'label.reports.revenue' | translate}}</td>
    </tr>
    <tr>
      <td class="left-align">{{reportDate | CompanyLocalized: 'dateFormat'}}</td>
      <td>{{plainData.currentRevenue.nrOfSales}}</td>
      <td>{{plainData.currentRevenue.nrOfInvoices}}</td>
      <td>{{plainData.currentRevenue.nrOfInvoicesCancelled}}</td>
      <td>{{((plainData.currentRevenue.revenue && plainData.currentRevenue.revenue.toFixed(2)) || '0.00') | CustomCurrency: false}}</td>
    </tr>
  </tbody>
  <tbody *ngFor="let block of dataBlocks; let k = index; trackByUuid">
    <tr *ngIf="block.value.length > 0">
      <td colspan="5" class="ion-no-padding">
        <h2 class="block-name">{{block.name | translate}} {{'('+ block.value.length + ')'}}</h2>
      </td>
    </tr>
    <tr class="block-header" *ngIf="block.value.length > 0">
      <td class="left-align">{{'common_name' | translate}}</td>
      <td>{{'label.reports.nrOfSales' | translate}}</td>
      <td>{{'label.reports.nrOfInvoices' | translate}}</td>
      <td>{{'label.reports.stornos' | translate}}</td>
      <td>{{'label.reports.revenue' | translate}}</td>
    </tr>
    <tr class="block-row" *ngFor="let item of block.value; trackByUuid">
      <td class="left-align">
        <span class="text-ellipsis">{{item.name | translate}}</span>
      </td>
      <td>{{item.nrOfSales}}</td>
      <td>{{item.nrOfInvoices}}</td>
      <td>{{item.nrOfInvoicesCancelled}}</td>
      <td>{{((item.revenue && item.revenue.toFixed(2)) || '0.00') | CustomCurrency: false}}</td>
    </tr>
  </tbody>
  <tbody *ngIf="plainData.giftCards.length">
    <tr>
      <td colspan="5" class="ion-no-padding">
        <h2 class="block-name">{{ 'label.reports.giftcardreport' | translate}}</h2>
      </td>
    </tr>
    <tr class="block-header">
      <td class="left-align">{{'common_name' | translate}}</td>
      <td></td>
      <td>{{'label.reports.nrOfInvoices' | translate}}</td>
      <td>{{'label.reports.stornos' | translate}}</td>
      <td>{{'label.reports.revenue' | translate}}</td>
    </tr>
    <tr class="block-row" *ngFor="let revenueOnly of plainData.giftCards">
      <td class="left-align">{{revenueOnly.name | translate}}</td>
      <td></td>
      <td>{{revenueOnly.nrOfInvoices}}</td>
      <td>{{revenueOnly.nrOfInvoicesCancelled}}</td>
      <td>{{((revenueOnly.revenue && revenueOnly.revenue.toFixed(2)) || '0.00') | CustomCurrency: false}}</td>
    </tr>
  </tbody>
</table>
