import { GiftCardsProviderInterface } from './gift-cards-provider.interface';
import { GiftCardsListInterface } from '../../../modules/assing-gift-card/gift-cards-list.interface';
import { Observable } from 'rxjs';
import { GiftCard } from '../../../modules/assing-gift-card/gift-card';

export abstract class GiftCardsProviderAbstract implements GiftCardsProviderInterface {
  abstract getGiftCardByCode(code: string): Observable<GiftCard>;
  abstract getGiftCardByUuid(uuid: string): Observable<GiftCard>;
  abstract getGiftCardByPhysicalCode(physicalCode: string): Observable<GiftCard>;
  abstract getGiftCardsList(uuidList: string[]): Observable<GiftCardsListInterface>;
}
