import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from '@pos-common/constants';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import * as moment from 'moment';

@Injectable()
export class TranslateSystemService {
  constructor(private translateService: TranslateService, private localStorage: LocalStorage) {}

  setLanguage(language: string) {
    this.translateService.use(language);
    this.localStorage.set(StorageKeys.preferredLanguage, language);
    moment.locale(language);
  }

  setDefaultLanguage() {
    const defaultLanguage = LANGUAGES.de;
    this.translateService.setDefaultLang(defaultLanguage);
    const preferredLanguage = this.localStorage.get(StorageKeys.preferredLanguage);
    const browerLang = this.translateService.getBrowserLang();
    const lang = LANGUAGES[preferredLanguage] || LANGUAGES[browerLang] || defaultLanguage;
    this.setLanguage(lang);
  }
}
