import { ReportingDevice, ServiceReport } from './device-report.models';

type ServiceName = 'ReceiptPrinters' | 'CustomerDisplay';

export abstract class IDeviceReportingService {
  abstract registerService<TEventType extends string, TDevice extends ReportingDevice = ReportingDevice>(
    name: ServiceName,
    service: IServiceWithReporting
  ): IDeviceReportingRegistration<TEventType, TDevice>;

  abstract createFullReport(): void;
}

export interface IDeviceReportingRegistration<TEventType extends string, TDevice extends ReportingDevice = ReportingDevice> {
  report(type: TEventType, report: ServiceReport<TDevice>): void;
}

export interface IServiceWithReporting {
  createReportForAllDevices(): ServiceReport[];
}
