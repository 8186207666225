import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'invoicePayments' })
export class InvoicePaymentsPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, paymentMethods: any[] = []) {
    let payments = [...value];
    if (payments && payments.length !== 0) {
      payments = payments.map((payment) => {
        if (payment.method.indexOf('CUSTOM') > -1 && paymentMethods.length !== 0) {
          let newName = '';
          const paymentMethod = paymentMethods.find((p) => p.method === payment.method);
          if (paymentMethod) {
            newName = paymentMethod.name;
          }
          payment.name = newName;
          return payment;
        } else {
          payment.name = this.translateService.instant(payment.method);
          return payment;
        }
      });
    }
    return payments;
  }
}
@NgModule({
  declarations: [InvoicePaymentsPipe],
  exports: [InvoicePaymentsPipe],
})
export class InvoicePaymentsPipeModule {}
