import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ElementRef,
  Input,
  Renderer2,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IMAGE_SIZES } from '@pos-common/classes/image.class';
import { ProductCategory } from '@pos-common/classes/product-category.class';
import { ProductItem } from '@pos-common/classes/product-item.class';
import { Product } from '@pos-common/classes/product.class';
import { CollectionViewService } from '@pos-common/services/system/collection-view.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { Image } from '@pos-common/classes/image.class';
import { ImageUtils } from '@pos-common/services/utils/image.utils';
import { ImageLoaderService } from 'ionic-image-loader-v5';
import { filter } from 'rxjs/operators';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { REGEXPS } from '@pos-common/constants';

@Component({
  selector: 'pos-virtual-product-item',
  template: '',
  styleUrls: ['./virtual-product-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class VirtualProductItemComponent implements OnInit, OnChanges {
  @Input() externalProductItem: ProductItem;
  @Input() cellsQuantity: number;
  @Input() cellWidth: number;
  private product: Product;
  private category: ProductCategory;
  private isCategory: boolean = false;
  private isImageActive: boolean = true;

  constructor(
    private translate: TranslateService,
    private elementRef: ElementRef,
    private imageLoaderService: ImageLoaderService,
    private imageUtils: ImageUtils,
    private renderer: Renderer2,
    private collectionViewService: CollectionViewService,
    private subSinkService: SubSinkService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.isImageActive = this.collectionViewService.getRenderImagesStatus();
    this.isCategory = this.externalProductItem.isCategory;
    this.setWidthToElement(this.cellWidth);
    this.setItemAndRender(this.externalProductItem.data);

    this.subSinkService.sink = this.collectionViewService
      .getNewProductItemEvent()
      .pipe(filter((data) => data.uuid === this.externalProductItem.data.uuid))
      .subscribe((data) => {
        this.setItemAndRender(data);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const cellWidthChange = changes.cellWidth;
    if (cellWidthChange && !cellWidthChange.isFirstChange()) {
      this.setWidthToElement(cellWidthChange.currentValue);
    }
  }

  private setItemAndRender(data: any) {
    this.setItem(data);
    this.render();
  }

  private setItem(data: any) {
    if (this.isCategory) {
      this.category = new ProductCategory(data);
      return;
    }
    this.product = new Product(data);
  }

  private render() {
    if (this.isCategory) {
      this.renderCategory(this.category);
      return;
    }
    this.renderProduct(this.product);
  }

  private setWidthToElement(width: number) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${width}%`);
  }

  private renderProduct(item: Product) {
    if (!item.visible) {
      return;
    }
    let badge = '';
    if (item.isNew && !this.isCategory) {
      badge = `<span class="badge badge-new">${this.translate.instant('common_new')}</span>`;
    } else if (!item.isNew && item.isSale) {
      badge = `<span class="badge badge-sale">${this.translate.instant('common_sale')}</span>`;
    }
    const innerHTML =
      badge +
      `<div class="product-item-wrapper">
        <div class="img-holder" style="background-color: ${item.bgColor};">
          <div class="product-item-image"></div>
          <p class="item-placeholder">${item.name}</p>` +
      `</div>
      <p class="item-name"><span>${item.name}</span></p>
      <div class="ripple-container"><div class="ripple"></div></div>
      </div>`;
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', innerHTML);
    this.renderProductImage(item);
  }

  private renderProductImage(product: Product) {
    const imgHolder = this.elementRef.nativeElement.getElementsByClassName('product-item-image')[0];
    if (product.images.length > 0 && this.isImageActive) {
      this.renderImage(imgHolder, product.images[0].image);
    } else {
      this.renderer.removeClass(imgHolder, 'image-loaded');
    }
  }

  private renderCategory(item: ProductCategory) {
    const innerHTML = `<div class="category-bg"></div>
      <div class="product-item-wrapper">
      <div class="img-holder" style="background-color: ${item.bgColor};">
        <div class="product-item-image"></div>
        <p class="item-placeholder">${item.name}</p>
      </div>
      <p class="item-name"><span>${item.name}</span></p>
      <div class="ripple-container"></div>
      </div>`;
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', innerHTML);
    this.renderCategoryImage(item);
  }

  private renderCategoryImage(category: ProductCategory) {
    const imgHolder = this.elementRef.nativeElement.getElementsByClassName('product-item-image')[0];
    if (category.image && this.isImageActive) {
      this.renderImage(imgHolder, category.image);
    } else {
      this.renderer.removeClass(imgHolder, 'image-loaded');
    }
  }

  private renderImage(imgHolder: HTMLElement, image: Image) {
    const imageSize = this.getImageSize();
    const imageUrl = image.getImageUrlBySize(imageSize);
    this.imageLoaderService
      .getImagePath(imageUrl)
      .then((path) => {
        path = path.replace(/(\r\n|\n|\r)/gm, '');
        path = this.imageUtils.convertFileSrc(path);
        return this.loadImage(path);
      })
      .then((path) => {
        if (!path) {
          return;
        }
        this.renderer.setStyle(imgHolder, 'background-image', `url("${path}")`);
        this.renderer.addClass(imgHolder, 'item-image');
        this.renderer.addClass(imgHolder, 'image-loaded');
      })
      .catch((err) => this.logService.error('ProductItemComponent', `renderImage:ImageLoader.getImagePath:imageUrl ${imageUrl}`, err));
  }

  private getImageSize() {
    const isMobile = window.innerWidth < 751;
    return isMobile ? IMAGE_SIZES.MEDIUM : IMAGE_SIZES.NORMAL;
  }

  private loadImage(path: string) {
    return new Promise<string>((resolve) => {
      if (!REGEXPS.REMOTE_IMAGE_SERVER.test(path)) {
        return resolve(path);
      }
      const imgEl = document.createElement('img');
      imgEl.onload = () => resolve(path);
      imgEl.onerror = () => resolve('');
      imgEl.src = path;
    });
  }
}
