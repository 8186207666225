<div class="picker-column-opts">
  <button
    type="button"
    *ngFor="let opt of col.options; let i = index; trackByUuid"
    class="picker-column-opt"
    [attr.opt-index]="i"
    [value]="opt.value"
  >
    {{ opt.text }}
  </button>
</div>
<div *ngIf="col.suffix" class="picker-column-suffix">
  {{ col.suffix }}
</div>
