import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { Invoice } from '../../../common/classes/invoice.class';
import { UPDATES_TYPES } from '../../../common/constants/updates-types.const';
import { DbDaoService } from '../../../common/services/db/db-dao.service';
import { SecurityService } from '../../../common/services/system/security.service';
import { CartService } from '../../../common/services/system/cart.service';
import { InvoicesFilterService } from '../../../common/services/system/invoice-filter.service';
import { ScannerAppService } from '../../../common/services/system/scanner-app.service';
import { CameraScannerService } from '../../../common/services/system/camera-scanner.service';
import { GoogleAnalyticsService } from '../../../common/services/system/google-analitycs.service';
import { INVOICE_TYPES } from '../../../common/constants/invoice-types';
import { LogService } from '../../../common/services/system/logger/log.service';
import { ROUTE_URLS } from '../../../common/constants/route-urls.const';
import { SubSink } from 'subsink';
import { BarcodeScannerTypes } from '@pos-common/constants/barcode-scanner-types.enum';
import { ChangeDetectorRef } from '@angular/core';
import { CompanyProperties } from '@pos-common/constants';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { map, takeUntil } from 'rxjs/operators';
import { PreloadNextModules } from '@pos-common/services/system';

@Component({
  selector: 'invoices-page',
  templateUrl: './invoices.page.html',
  styleUrls: ['./invoices.page.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SubSinkService],
})
export class InvoicesPage implements OnInit, ViewWillEnter, ViewWillLeave {
  public notShowIsPaidInvoices: boolean = true;
  public INVOICE_TYPES: any = INVOICE_TYPES;
  public activeMergedBtn: boolean = true;
  public isGastroMode: boolean = false;
  public currentFilterSet: any = {};
  public isBarcodeScannerOpened: boolean = false;
  public isWebshopEnabled = false;
  private subsBarcode = new SubSink();

  constructor(
    private navController: NavController,
    private DbDaoService: DbDaoService,
    private SecurityService: SecurityService,
    private CartService: CartService,
    private InvoicesFilterService: InvoicesFilterService,
    private ScannerAppService: ScannerAppService,
    private CameraScannerService: CameraScannerService,
    private GoogleAnalyticsService: GoogleAnalyticsService,
    private preloadNextModules: PreloadNextModules,
    private subSinkService: SubSinkService,
    private cdr: ChangeDetectorRef,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.InvoicesFilterService.getInvoices()
      .pipe(
        takeUntil(this.subSinkService.destroy$),
        map((data) => data?.length || 0)
      )
      .subscribe((length) => {
        this.activeMergedBtn = length > 1;
        this.notShowIsPaidInvoices = this.currentFilterSet?.tab === 'paid' || !length;
      });
    this.InvoicesFilterService.getInvoiceFilter()
      .pipe(takeUntil(this.subSinkService.destroy$))
      .subscribe((data) => {
        this.currentFilterSet = data?.tab ? data : {};
      });
    this.SecurityService.observableCompanyProperties(CompanyProperties.isRestaurantEnabled, CompanyProperties.isWebshopEnabled)
      .pipe(takeUntil(this.subSinkService.destroy$))
      .subscribe((company) => {
        this.isGastroMode = company.isRestaurantEnabled;
        this.isWebshopEnabled = company.isWebshopEnabled;
      });
    this.CameraScannerService.getBarcodeScannerOpenedStateEvent()
      .pipe(takeUntil(this.subSinkService.destroy$))
      .subscribe((isOpened) => {
        this.isBarcodeScannerOpened = isOpened;
      });
    this.GoogleAnalyticsService.trackView('InvoicesPage');
    const company = this.SecurityService.getLoggedCompanyData();
    this.isGastroMode = company.isRestaurantEnabled;
    this.isWebshopEnabled = company.isWebshopEnabled;
    this.CameraScannerService.setBarcodeScannerType(BarcodeScannerTypes.Paymash);
    this.preloadNextModules.preloadNextPages(ROUTE_URLS.invoices);
  }

  ionViewDidLoad() {
    this.GoogleAnalyticsService.trackView('InvoicesPage');
    const company = this.SecurityService.getLoggedCompanyData();
    this.isGastroMode = company.isRestaurantEnabled;
    this.isWebshopEnabled = company.isWebshopEnabled;
  }

  ionViewWillEnter() {
    this.subsBarcode.sink = this.ScannerAppService.newBarcodeEvent.subscribe((barcode) => {
      this.handleScannedBarcode(barcode);
    });
    this.subsBarcode.sink = this.CameraScannerService.newBarcodeEvent.subscribe((barcode) => {
      this.handleScannedBarcode(barcode);
    });
  }

  ionViewWillLeave() {
    this.subsBarcode.unsubscribe();
    this.CameraScannerService.closeBarcodeScanner();
  }

  public openBarcodeScanner() {
    this.CameraScannerService.openBarcodeScanner(document.querySelector('.invoices-page'));
    this.cdr.detectChanges();
  }

  public handleScannedBarcode(barcode: string) {
    let publicUuid = barcode.split('/I/')[1];
    let foundInvoice: Invoice;
    this.DbDaoService.getAllData(UPDATES_TYPES.Invoice.type, { publicUuid: publicUuid })
      .then((data) => {
        foundInvoice = data['data'][0];
        if (!foundInvoice) {
          this.openBarcodeScanner();
          // return this.CameraScannerService.showBarcodeScannerMsg('common_barcode_unknown_invoice_message', 1500);
        }
        this.InvoicesFilterService.clickInvoiceHandler(foundInvoice);
      })
      .catch(() => this.CameraScannerService.showBarcodeScannerMsg('common_barcode_unknown_invoice_message', 1500));
  }

  public createNewInvoice() {
    this.navController
      .navigateRoot(ROUTE_URLS.collection)
      .catch((err) => this.logService.error('InvoicesPage', 'createNewInvoice:navController:navigateRoot:collection', err));
    this.CartService.createInvoice();
  }

  public combineInvoice() {
    this.InvoicesFilterService.combineInvoice().catch((err) => this.logService.error('InvoicesPage', 'combineInvoice', err));
  }

  public newTableInvoice() {
    this.InvoicesFilterService.newTableInvoice();
  }

  public newInvoice() {
    this.InvoicesFilterService.newInvoice();
  }

  public openWebshopInvoices() {
    this.navController
      .navigateRoot(ROUTE_URLS.invoicesWebshop)
      .catch((err) => this.logService.error('InvoicesPage', 'openWebshopInvoices:invoicesWebshop', err));
  }
}
