<ion-item-group>
  <ion-item *ngIf="!isProductionVersion" class="has-action" tappable (click)="createMultipleInvoices()">
    Create invoices for test (don't press)
  </ion-item>
  <ion-item class="has-action" (click)="sendDbBackupViaAWS()" tappable>
    {{ 'settings_send_db_backup_via_email' | translate }}
  </ion-item>
  <ion-item class="has-action" (click)="sendTimApiLogsViaEmail()" tappable>
    {{ 'settings_send_logs_via_email' | translate }} (SIX)
  </ion-item>
  <ion-item class="has-action" (click)="forceSynchronisationInvoices()" tappable>
    {{ 'settings_force_synchronisation' | translate }}
  </ion-item>
  <ion-item
    *ngIf="!isProductionVersion"
    class="has-action"
    onclick="location.href=location.href.replace('www/index.html', 'www/cdvtests/index.html');"
  >
    Open cordova tests
  </ion-item>
  <ion-item class="has-action item-reset-company" tappable (click)="showResetCompanyFirstConfirm()">
    <i class="custom-icon custom-icon-delete-store"></i> {{ 'settings_app_reset_company' | translate }}
  </ion-item>
</ion-item-group>
