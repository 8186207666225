import { Injectable } from '@angular/core';
import { SecurityService } from '../system/security.service';
import { SERVER_CONFIG } from '../../constants/server.const';

@Injectable()
export class PosApiService {
  constructor(private SecurityService: SecurityService) {}

  posVersions() {
    return this.SecurityService.doSecureRequestObservable(`${SERVER_CONFIG.API_URL}posVersions`, 'get');
  }

  posSystem(dataToUpdate: any) {
    return this.SecurityService.doSecureRequest(`${SERVER_CONFIG.API_URL}posSystem`, 'post', dataToUpdate, { showLoading: false });
  }
}
