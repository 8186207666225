/**
 * Created by y.belinsky on 8/30/17.
 */
import { Entity } from './entity.class';
import { UPDATES_TYPES } from '../constants/updates-types.const';
import { SyncRetryInterface } from '../interfaces/sync-retry.interface';

export class SyncListItem implements SyncRetryInterface {
  uuid: string;
  deleted: boolean = false;
  type: string;
  syncInProgress: boolean = false;
  reference: Entity[];
  retryCount: number;
  localLastSyncDate: string;

  constructor(data: any, type: string) {
    this.uuid = data.uuid;
    this.deleted = data.deleted || false;
    this.type = type;
    this.syncInProgress = data.syncInProgress || false;
    this.reference = data.reference || this.setReference(data);
    this.retryCount = data.retryCount || 0;
    this.localLastSyncDate = data.localLastSyncDate || new Date().toISOString();
  }

  private setReference(entityData: any): Entity[] {
    let reference: Entity[] = [];
    try {
      switch (entityData.type) {
        case UPDATES_TYPES.Invoice.type:
          if (entityData.customer) {
            reference.push(new Entity(entityData.customer));
          }
          if (entityData.originalInvoiceReference) {
            reference.push(new Entity(entityData.originalInvoiceReference));
          }
          break;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    return reference;
  }
}
