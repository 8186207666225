export enum ProtocolVersion {
  v3_0 = '3.0',
}

export enum MessageClass {
  Device = 'Device',
  Service = 'Service',
}

export enum MessageCategory {
  Payment = 'Payment',
  Print = "Print",
  Admin = "Admin",
}

export enum MessageType {
  Request = 'Request',
}
