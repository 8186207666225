import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { CancellationTypes } from '@pos-common/constants/cancellation-types.enum';
import { LogService } from '@pos-common/services/system/logger/log.service';

@Component({
  selector: 'pos-cancel-popover',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'cancel-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelPopoverComponent {
  cancellationTypes = CancellationTypes;
  isFullCancellation = true;
  isPartialCancellation = true;
  private cancelCallback: (cancellationType: CancellationTypes) => void;

  constructor(private popoverController: PopoverController, private params: NavParams, private logService: LogService) {
    this.cancelCallback = this.params.get('cancelCallback');
    const { all, partial, full } = CancellationTypes;
    const allowCancellationType = this.params.get('allowCancellationType');
    this.isFullCancellation = allowCancellationType === full || allowCancellationType === all;
    this.isPartialCancellation = allowCancellationType === partial || allowCancellationType === all;
  }

  cancelation(cancellationType: CancellationTypes) {
    if (typeof this.cancelCallback === 'function') {
      this.cancelCallback(cancellationType);
    }
    this.dismiss();
  }

  dismiss() {
    this.popoverController.dismiss().catch((err) => this.logService.error('CancelPopoverComponent', 'dismiss', err));
  }
}
