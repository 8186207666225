import { ICustomerDisplayProvider } from '../ICustomerDisplayProvider';
import { ICustomerDisplayDevice } from '../ICustomerDisplayDevice';
import { ICustomerDisplaySessionDelegate } from '../ICustomerDisplaySessionDelegate';
import { Subject } from 'rxjs';
import { Injector } from '@angular/core';
import { SavedDeviceInfo, UniversalCustomerDisplayDevice } from './UniversalCustomerDisplayDevice';
import { IDiscovery } from './IDiscovery';
import { NetworkDiscovery } from './network/NetworkDiscovery';
import { InAppDiscovery } from './inapp/InAppDiscovery';
import { UniversalCustomerDisplaySession } from './UniversalCustomerDisplaySession';
import { ICustomerDisplaySession } from '@pos-common/services/system/customer-display/ICustomerDisplaySession';
import { Platform } from '@ionic/angular';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { WebDemoDiscovery } from './web-demo/WebDemoDiscovery';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import moment from 'moment';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { CustomerDisplayReporting } from '@pos-common/services/system/customer-display/CustomerDisplayReporting';
import { ErrorLevel, ILogger, ILoggerFactory } from '@spryrocks/logger';
import { DurationInputArg1, DurationInputArg2 } from 'moment';
import { ignorePromiseError } from '@pos-common/services/utils/promise.utils';

type ReConnectAttemptSetup = [DurationInputArg1, DurationInputArg2];

export class UniversalCustomerDisplayProvider extends ICustomerDisplayProvider {
  private readonly logService: LogService;
  private readonly logger: ILogger;
  private delegate: ICustomerDisplaySessionDelegate;

  public readonly providerId = 'UniversalCustomerDisplayProvider';

  private readonly devices_: UniversalCustomerDisplayDevice<unknown>[] = [];
  private readonly devicesSubject = new Subject<ICustomerDisplayDevice[]>();

  private readonly discoveries: IDiscovery<UniversalCustomerDisplayDevice<unknown>, unknown>[];

  private readonly platformService: PlatformService;
  private readonly platform: Platform;
  private readonly localStorage: LocalStorage;

  constructor(injector: Injector, private readonly report: CustomerDisplayReporting) {
    super();

    this.platformService = injector.get(PlatformService);
    this.platform = injector.get(Platform);
    this.localStorage = injector.get(LocalStorage);
    this.logService = injector.get(LogService);
    this.logger = this.logService.createLogger("UniversalCustomerDisplayProvider");

    this.discoveries = this.getDiscoveries(this.logService);

    this.platform.resume.subscribe(() => {
      this.connectToOfflineDevices().then();
    });
  }

  private getDiscoveries(loggerFactory: ILoggerFactory): IDiscovery<UniversalCustomerDisplayDevice<unknown>, unknown>[] {
    const logger = this.logger.child();
    logger.updateParams({method: "getDiscoveries"})
    logger.debug("Build discoveries list");

    if (this.platformService.isWeb) {
      logger.info("Web platform, return WebDemoDiscovery");
      return [new WebDemoDiscovery(this, loggerFactory)];
    }

    const discoveries = [];

    if (this.platformService.isAndroid) {
      logger.info("Add InAppDiscovery")
      discoveries.push(new InAppDiscovery(this, loggerFactory));
    }

    if (this.platformService.isNativePlatform) {
      this.logger.info('Add NetworkDiscovery');
      discoveries.push(new NetworkDiscovery(this, this.platform, loggerFactory));
    }

    return discoveries;
  }

  async initialize(delegate: ICustomerDisplaySessionDelegate) {
    this.logger.debug('Initialize universal customer display provider');
    this.delegate = delegate;

    this.discoveries.forEach((d) => d.devices.subscribe((devices) => this.onDevicesDiscovered(d.discoveryId, devices)));

    this.logger.info('Universal customer display provider initialized');
  }

  private turnedOn: boolean;

  async turnOn() {
    this.logger.debug('Turn on provider');
    if (this.turnedOn) {
      this.logger.info('Provider already turned on, skipping');
      return;
    }
    this.turnedOn = true;

    await this.restoreConnectedDevices();

    this.startAutoConnectTimer();

    this.logger.info('Provider turned on');
  }

  async turnOff() {
    this.logger.debug('Turn off provider');
    if (!this.turnedOn) {
      this.logger.info('Provider already turned off, skipping');
      return;
    }
    this.turnedOn = false;

    this.stopAutoConnectTimer();

    this.logger.info('Provider turned off');
  }

  get devices() {
    return this.devicesSubject;
  }

  async connect(device: ICustomerDisplayDevice) {
    const logger = this.logger.child();
    logger.updateParams({device});

    logger.debug('Connect to device');

    if (!(device.status === 'disconnected' || device.status === 'offline')) {
      logger.warning('Device disconnected or offline, skipping', {device});
      return;
    }

    const device_ = device as UniversalCustomerDisplayDevice<unknown>;

    try {
      device_.status = 'offline';
      this.updateDevices();
      await this.saveConnectedDevices();

      device_.status = 'connecting';
      this.updateDevices();

      const driver = device_.createDriver();
      await driver.connect();

      device_.status = 'online';

      device_.failedConnectionAttempts = undefined;
      logger.info('Device connected, set online status and restore "failedConnectionAttempts" field');

      this.updateDevices();

      const session = new UniversalCustomerDisplaySession(device_, driver, this.logService);
      session.closed.subscribe(this.onSessionClosed.bind(this));

      logger.info('Notify that device connected');

      await this.delegate.onDeviceConnected(session);
    } catch (e) {
      logger.error(e, "Cannot connect to device", {level: ErrorLevel.Low}, {device});

      device_.status = 'offline';

      if (device_.failedConnectionAttempts) {
        device_.failedConnectionAttempts.last = new Date();
        device_.failedConnectionAttempts.count++;
      } else {
        device_.failedConnectionAttempts = { last: new Date(), count: 0 };
      }

      this.updateDevices();
    }
  }

  async disconnect(device: ICustomerDisplayDevice, session: ICustomerDisplaySession | undefined) {
    const logger = this.logger.child();
    logger.updateParams({device});

    logger.debug("Disconnect from device");

    if (session) {
      logger.info("Session exists, disconnect it");
      const session_ = session as UniversalCustomerDisplaySession;
      await session_.driver.disconnect().catch(ignorePromiseError);
      logger.info("Session disconnected");
    }

    const device_ = device as UniversalCustomerDisplayDevice<unknown>;
    device_.status = 'disconnected';
    if (device_.autoConnect) {
      device_.autoConnect = false;
    }
    this.updateDevices();

    await this.saveConnectedDevices();

    await this.delegate.onDeviceDisconnected(device, session);
    logger.info("Disconnected from device");
  }

  private isDeviceDiscoveryStarted: boolean;

  startDeviceDiscovery() {
    this.logger.debug('Start device discovery');
    if (this.isDeviceDiscoveryStarted) return;
    this.isDeviceDiscoveryStarted = true;
    this.discoveries.forEach((d) => d.startDiscovery());
    this.logger.info('Device discovery started');
  }

  stopDeviceDiscovery() {
    this.logger.debug('Stop device discovery');
    return;
    // noinspection UnreachableCodeJS
    if (!this.isDeviceDiscoveryStarted) return;
    this.isDeviceDiscoveryStarted = false;
    this.discoveries.forEach((d) => d.stopDiscovery());
    this.logger.info('Device discovery stopped');
  }

  private updateDevices() {
    const devices = [...this.devices_];
    this.logger.debug("Device list updated", {devices});
    this.devicesSubject.next(devices);
  }

  private onDevicesDiscovered(discoveryId: string, devices: UniversalCustomerDisplayDevice<unknown>[]) {
    this.logger.debug('Devices discovered', {devices, discoveryId});
    devices.forEach((device) => {
      const device_ = this.devices_.find((d) => d.equals(device));
      if (!device_) {
        this.devices_.push(device);
        this.onDeviceAdded(device);
      } else {
        device_.loadFrom(device);
        device_.discovered = true;
        this.onDeviceUpdated(device_);
      }
    });

    const notFoundDevices = this.devices_
      .filter((device) => device.discoveryId === discoveryId)
      .filter((device) => !devices.find((d) => d.equals(device)));

    const devicesToRemove: UniversalCustomerDisplayDevice<unknown>[] = [];

    notFoundDevices.forEach((device_) => {
      device_.discovered = false;
      if (device_.status === 'disconnected' || device_.temporary) {
        devicesToRemove.push(device_);
      }
    });

    devicesToRemove.forEach((device) => {
      this.devices_.splice(this.devices_.indexOf(device), 1);
      this.onDeviceRemoved(device);
    });

    this.updateDevices();
  }

  private onDeviceAdded(device: UniversalCustomerDisplayDevice<unknown>) {
    this.logger.debug('Device added', {device});
    this.report.deviceAdded(device);
    if (device.autoConnect && device.status === 'disconnected') {
      this.logger.info('Auto connect option is "true" and device status is "disconnected", connect to the device immediately', {device});
      this.connect(device).then();
    }
  }

  private onDeviceUpdated(device: UniversalCustomerDisplayDevice<unknown>) {
    this.logger.debug('Device updated', {device});
    if (device.status === 'offline') {
      this.logger.info('Device has state "offline", try re-connect', {device});
      this.connect(device).then();
    }
  }

  private onDeviceRemoved(device: UniversalCustomerDisplayDevice<unknown>) {
    this.report.deviceRemoved(device);
  }

  private async saveConnectedDevices() {
    const devices = this.devices_.filter((d) => d.status !== 'disconnected' || d.autoConnect !== undefined);

    const saveDevicesInfo: SavedDeviceInfo[] = [];
    for (const device of devices) {
      const discovery = this.findDiscoveryById(device.discoveryId);
      saveDevicesInfo.push(discovery.getDeviceInfo(device));
    }

    this.localStorage.set('UniversalCustomerDisplayProvider.SavedDevices', JSON.stringify(saveDevicesInfo));
  }

  private async restoreConnectedDevices() {
    this.logger.debug('Restore connected devices');

    const savedDevicesString = this.localStorage.get('UniversalCustomerDisplayProvider.SavedDevices');
    this.logger.info('Received "savedDevicesString" from localStorage', {savedDevicesString});
    if (!savedDevicesString) {
      this.logger.info('"savedDevicesString" undefined, skipping restore connected devices');
      return;
    }

    const savedDevicesInfo: SavedDeviceInfo[] = JSON.parse(savedDevicesString);
    for (const deviceInfo of savedDevicesInfo) {
      const discovery = this.findDiscoveryById(deviceInfo.discoveryId);
      if (!discovery) continue;
      const device = discovery.restoreDeviceFromInfo(deviceInfo);
      const device_ = this.devices_.find((d) => d.equals(device));
      if (!device_) {
        this.devices_.push(device);
      } else {
        device_.status = device.status;
        device_.autoConnect = device.autoConnect;
      }
      this.updateDevices();
    }
  }

  private async connectToOfflineDevices(options?: { connectEvenNotDiscovered?: boolean }) {
    const devices = this.devices_;

    // this.logger.debug('Connect to offline devices', { options, devices });

    const offlineDevices = devices.filter(d => d.status === 'offline');
    if (offlineDevices.length < 1) return;

    this.logger.debug('Connect to offline devices (found)', { options, offlineDevices });

    for (const device of offlineDevices) {
      if (device.status !== 'offline') continue;

      if (!device.discovered && !options.connectEvenNotDiscovered) {
        this.logger.info('Device offline, but it has not discovered and forced connection is not requested', { device });
        continue;
      }

      this.logger.info('Device offline, discovered or requested forced connection', { device });

      if (!this.isReadyToReconnect(device)) {
        this.logger.info('Skip re-connection because of last connection attempt time', { device });
        continue;
      }

      await this.connect(device);
    }
  }

  private isReadyToReconnect(device: UniversalCustomerDisplayDevice<unknown>): boolean {
    this.logger.info('Check is device ready to re-connect', { device });
    const failedAttempts = device.failedConnectionAttempts;

    if (!failedAttempts) {
      this.logger.info('"failedAttempts" undefined, return that device is ready to re-connect"', { device });
      return true;
    }

    const attemptsMap: { retriesCount: number, interval: ReConnectAttemptSetup }[] =
      [
        {retriesCount: 0, interval: [0, 'second']},
        {retriesCount: 1, interval: [10, 'seconds']},
        {retriesCount: 10, interval: [30, 'seconds']},
        {retriesCount: 20, interval: [1, 'minutes']},
      ];

    const findAttempt = (retriesCount: number) => {
      for (let attempt of attemptsMap.reverse()) {
        if (attempt.retriesCount <= retriesCount) return attempt.interval;
      }
      return attemptsMap[attemptsMap.length - 1];
    }

    const failedAttemptsCount = failedAttempts.count;
    const attempt = findAttempt(failedAttemptsCount);

    this.logger.info(`"failedAttemptsCount" equals ${failedAttemptsCount}, re-connect allowed after ${attempt[0]} ${attempt[1]}`, { device });

    const delay = moment.duration(attempt[0], attempt[1]);

    const readyToReConnect = moment().isSameOrAfter(moment(failedAttempts.last).add(delay));

    if (readyToReConnect) {
      this.logger.info('"Device ready to re-connect', { device });
    } else {
      this.logger.info('"Device not ready to re-connect', { device });
    }

    return readyToReConnect;
  }

  private findDiscoveryById(discoveryId: string): IDiscovery<UniversalCustomerDisplayDevice<unknown>, unknown> | undefined {
    return this.discoveries.find((d) => d.discoveryId === discoveryId);
  }

  private async onSessionClosed(session: UniversalCustomerDisplaySession) {
    this.logger.info('Session closed', {device: session.device});

    const device_ = this.devices_.find((d) => d.equals(session.device));
    if (device_) {
      this.logger.info('Device found in the list, set "offline" state', {device: device_});
      device_.status = 'offline';
      this.updateDevices();
    } else {
      this.logger.info('Device not found in the list', {device: session.device});
    }

    await this.delegate.onDeviceDisconnected(device_, session);
  }

  //region AutoConnectTimer
  private autoConnectTimerId: NodeJS.Timeout | undefined;

  private startAutoConnectTimer() {
    this.logger.info('Start auto connect timer');
    this.stopAutoConnectTimer();
    const intervalSec = 10;
    this.autoConnectTimerId = setInterval(() => this.connectToOfflineDevices({ connectEvenNotDiscovered: true }), 1000 * intervalSec);
    this.logger.info('Auto connect timer started');
  }

  private stopAutoConnectTimer() {
    this.logger.info('Stop auto connect timer');
    if (this.autoConnectTimerId === undefined) {
      this.logger.info('Auto connect timer not started, skipping');
      return;
    }

    clearInterval(this.autoConnectTimerId);
    this.autoConnectTimerId = undefined;
    this.logger.info('Auto connect timer stopped');
  }
  //endregion
}
