/**
 * Created by maksymkunytsia on 11/29/16.
 */

// Vendors
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';

// Common - services
import { SecurityService } from './security.service';
import { APP_INFO } from '../../constants/app-info.const';
import { LocalStorage } from '../utils/localstorage.utils';
import { LogService } from './logger/log.service';
import { MyPosService } from '@pos-common/services/system/devices/my-pos/my-pos.service';
import { PosApiService } from '../api/pos-api.service';
import { IAdyenService } from './devices/adyen/adyen.service';
import { stringifySafety } from '@pos-common/services/system/logger';

@Injectable()
export class PosInfoService {
  private devicePerformanceScore: any = null;

  constructor(
    public SecurityService: SecurityService,
    public platform: Platform,
    public device: Device,
    public LocalStorage: LocalStorage,
    private myPosService: MyPosService,
    private adyenService: IAdyenService,
    private posApiService: PosApiService,
    private logService: LogService
  ) {}

  public updatePosDataOnServer() {
    const dataToUpdate = {
      deviceIdentifier: this.SecurityService.getDeviceIdentifier(),
      deviceFingerprint: this.SecurityService.getDeviceFingerprint(),
      name: this.getDeviceModel(),
      deviceType: this.getDeviceType(),
      // TODO ADD TOKEN
      iosNotificationsToken: null,
      appVersion: APP_INFO.APP_VERSION + ' (' + APP_INFO.APP_BUILD + ')',
    };
    this.logService.debug('PosInfoService', `updatePosDataOnServerdoSecureRequest:data = ${stringifySafety(dataToUpdate)}`);
    this.posApiService
      .posSystem(dataToUpdate)
      .catch((err) => this.logService.error('PosInfoService', 'updatePosDataOnServer:doSecureRequest', err));
  }

  public getDeviceModel() {
    return window.cordova ? (cordova.plugins['deviceName'] ? cordova.plugins['deviceName'].name : this.device.model) : 'Web';
  }

  public getDeviceType() {
    return window.cordova ? this.device.model + ', ' + this.device.platform + ', ' + this.device.version : this.platform['_ua'];
  }

  public performanceTest() {
    try {
      let times = [];
      const testsNum = 70;
      for (let i = 0; i < testsNum; i++) {
        const perfomanceTime = this.getPerformanceTime();
        times = [...times, perfomanceTime];
      }
      times.sort();
      times = times.slice(10, times.length - 10);
      const total = times.reduce((result, time) => result + time, 0);
      const result = Math.round(total / times.length);
      return result;
    } catch (err) {
      this.logService.error('PosInfoService', 'performanceTest', err);
    }
  }

  public getDevicePerformance(): any {
    if (this.devicePerformanceScore) return this.devicePerformanceScore;
    return (this.devicePerformanceScore = this.LocalStorage.get('performanceTest'));
  }

  public getDevicePerformanceStatistic() {
    let devicePerfomace = this.getDevicePerformance();
    if (devicePerfomace) {
      devicePerfomace = Math.round(devicePerfomace);
      const maxScore = this.myPosService.isMyPosDevice || this.adyenService.isAdyenDevice ? 80 : 100;
      devicePerfomace = devicePerfomace < maxScore ? devicePerfomace : maxScore;
      devicePerfomace = 101 - devicePerfomace;
      return devicePerfomace;
    }
    return 1;
  }

  public setDevicePerformance(score): void {
    if (score) {
      this.devicePerformanceScore = score;
      this.LocalStorage.set('performanceTest', '' + score);
    }
  }

  public isSlowDevice(): boolean {
    if (this.getDevicePerformance()) {
      return parseInt(this.getDevicePerformance()) > APP_INFO.APP_MIN_PERFORMANCE_SCORE;
    } else {
      return false;
    }
  }

  public getWeakDevicePageIsShowenStatus(): boolean {
    return !!this.LocalStorage.get('WeakDevicePageIsShowen');
  }

  public setWeakDevicePageIsShowedStatus(): void {
    this.LocalStorage.set('WeakDevicePageIsShowen', 'yes');
  }

  public showWeakDevicePage(): boolean {
    return (
      this.isSlowDevice() && !this.getWeakDevicePageIsShowenStatus() && !this.myPosService.isMyPosDevice && !this.adyenService.isAdyenDevice
    );
  }

  private getPerformanceTime(): number {
    const startTime = performance.now();
    for (let i = 0; i < 1000; i++) {
      const div = document.createElement('div');
      div.className = 'perf-test';
      div.innerHTML = '<p>This is test</p>';
    }
    return performance.now() - startTime;
  }
}
