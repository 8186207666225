import { Injectable } from '@angular/core';
import { SocketScannerService } from './socket-scanner/socket-scanner.service';
import { ScannerAppService } from './scanner-app.service';
import { Observable, Subject } from 'rxjs';
import { PrinterService } from './printer.service';
import { ReceiptPrinterModeTypes } from '../../constants/receipt-printer-mode-types';
import { ReceiptPrintersService } from './receipt-printers';
import { NotifyPopupsService } from './notify-popups.service';
import { LogService } from './logger/log.service';
import { ISunmiService } from '@pos-common/services/system/devices/sunmi/sunmi.service';
import { SunmiScannerType, SunmiScannerPlugin } from '@paymash/capacitor-sunmi-plugin';
import { MyPosService } from './devices/my-pos/my-pos.service';
import { SubSink } from 'subsink';
import { MyPosFacade } from '@pos-modules/my-pos/my-pos.facade';
import { MyPosScannerType } from '@paymash/capacitor-mypos-plugin';
import { IAdyenService } from '@pos-common/services/system/devices/adyen/adyen.service';
import { AdyenScannerService } from '@pos-common/services/system/adyen/adyen-scanner.service';

@Injectable()
export class BarcodeScannerService {
  private _newBarcodeEvent = new Subject<string>();
  private showNotify = true;
  private subs = new SubSink();

  constructor(
    private socketScannerService: SocketScannerService,
    private scannerAppService: ScannerAppService,
    private printerService: PrinterService,
    private notifyPopupsService: NotifyPopupsService,
    private receiptPrintersService: ReceiptPrintersService,
    private myPosService: MyPosService,
    private myPosFacade: MyPosFacade,
    private logService: LogService,
    private readonly sunmiService: ISunmiService,
    private readonly adyenService: IAdyenService,
    private readonly adyenScanner: AdyenScannerService,
  ) {
    this.initScanners();
  }

  public get newBarcodeEvent(): Observable<string> {
    this.initScanners();
    return this._newBarcodeEvent.asObservable();
  }

  initScanners() {
    this.unsubscribe();

    this.subs.sink = this.socketScannerService.onData().subscribe((barcode) => this._newBarcodeEvent.next(barcode));
    this.subs.sink = this.scannerAppService.newBarcodeEvent.subscribe((barcode) => this._newBarcodeEvent.next(barcode));
    this.subs.sink = this.receiptPrintersService.newBarcodeEvent.subscribe((barcode) => this._newBarcodeEvent.next(barcode));

    this.initStartScanner();
  }

  private unsubscribe() {
    this.subs.unsubscribe();
  }

  initStartScanner() {
    this.subs.sink = this.printerService.getPrinterListOnce(ReceiptPrinterModeTypes.STAR_SCANNER).subscribe((printerList) => {
      if (printerList.length) {
        if (this.showNotify) {
          this.notifyPopupsService.launchNotification('add-start-scanner');
          this.showNotify = false;
        }
        const printer = printerList[0];
        this.receiptPrintersService.startBarcodeReader(printer);
      } else {
        this.showNotify = true;
      }
    });

    if (this.sunmiService.isSunmiDevice && this.sunmiService.hasBroadcastScanner) {
      this.subs.sink = SunmiScannerPlugin.startScanner(SunmiScannerType.Broadcast).subscribe(
        (result) => this._newBarcodeEvent.next(result.value),
        (err) => this.logService.error('BarcodeScannerService', 'initStartScanner:SunmiScannerPlugin:startScanner', err)
      );
    }

    if (this.myPosService.isMyPosDevice && this.myPosService.hasBroadcastScanner) {
      this.subs.sink = this.myPosFacade.startScanner(MyPosScannerType.Broadcast).subscribe(
        (result) => this._newBarcodeEvent.next(result.value),
        (err) => this.logService.error('BarcodeScannerService', 'initStartScanner:myPosFacade:startScanner', err)
      );
    }

    if (this.adyenService.isScannerConnected) {
      this.subs.sink = this.adyenScanner.startScanner().subscribe(
        (result) => this._newBarcodeEvent.next(result.code),
        (err) => this.logService.error('BarcodeScannerService', 'initStartScanner:adyen:startScanner', err)
      )
    }
  }

  stopScanners() {
    this.unsubscribe();
    this.receiptPrintersService
      .stopBarcodeReader()
      .catch((err) => this.logService.error('BarcodeScannerService', 'stopScanners:stopBarcodeReader', err));
  }
}
