import { GastronomyTable } from './gastronomy-table.class';
import { Entity } from "./entity.class";

export class GastronomyHall extends Entity {
  public uuid: string;
  public name: string;
  public deleted: boolean;
  public gastronomyTables: Array<GastronomyTable> = [];
  public store: { uuid: string };

  constructor(gastroHallData) {
    super(gastroHallData);
    this.name = gastroHallData.name || '';
    this.deleted = gastroHallData.deleted || false;
    this.store = gastroHallData.store || '';
    if (gastroHallData.gastronomyTables) {
      for (let i = 0; i < gastroHallData.gastronomyTables.length; i++) {
        if (!gastroHallData.gastronomyTables[i].deleted) this.gastronomyTables.push(new GastronomyTable(gastroHallData.gastronomyTables[i]));
      }
    }
  }

}
