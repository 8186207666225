/**
 * Created by y.belinsky on 11/14/16.
 */

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DayReportRowData, Properties } from '../../../common/classes/dayreport.class';

@Component({
  selector: 'day-report-details',
  templateUrl: './day-report-details.html',
  styleUrls: ['./day-report-details.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DayReportDetails implements OnInit {
  @Input() public plainData: Properties;
  @Input() public reportDate;
  public dataBlocks: DayReportRowData[] = [];

  ngOnInit() {
    this.dataBlocks.push(new DayReportRowData('label.reports.productcategoryreport', this.plainData.productCategories));
    this.dataBlocks.push(new DayReportRowData('label.reports.productvariantreport', this.plainData.productVariants));
    this.dataBlocks.push(new DayReportRowData('label.reports.employeereport', this.plainData.employees));
    this.dataBlocks.push(new DayReportRowData('label.reports.customerreport', this.plainData.customers));
    this.dataBlocks.push(new DayReportRowData('label.reports.storereport', this.plainData.stores));
    this.dataBlocks.push(new DayReportRowData('label.reports.netRevenues', this.plainData.netRevenues));
    this.dataBlocks.push(new DayReportRowData('label.reports.taxratereport', this.plainData.taxRates));
    this.dataBlocks.push(new DayReportRowData('label.reports.taxrevenuereport', this.plainData.taxRevenues));
    this.dataBlocks.push(new DayReportRowData('label.reports.paymentreport', this.plainData.payments));
    this.dataBlocks.push(new DayReportRowData('label.reports.onInvoiceReports', this.plainData.onInvoiceReports));
    this.dataBlocks.push(new DayReportRowData('label.reports.discountreport', this.plainData.discounts));
  }
}
