import { AfterViewInit, Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'pm-signature-pad',
  styleUrls: ['signature-pad.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'signature-pad.component.html',
})
export class SignaturePadComponent implements AfterViewInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @Output() onResult: EventEmitter<string> = new EventEmitter<string>();

  public signaturePadOptions: Object = {
    canvasWidth: 500,
    canvasHeight: 300,
  };
  readonly showCancelButton = true;
  readonly showContinueButton = true;
  readonly cancelButtonTitle = 'common_clear';
  readonly continueButtonTitle = 'common_continue';

  constructor() {}

  ngAfterViewInit(): void {
    this.clearPad();
    this.resizeCanvas();
  }

  // TODO: handle resize
  resizeCanvas() {}

  clearPad() {
    this.signaturePad.clear();
  }

  getSignatureAndFinish() {
    this.onResult.emit(this.signaturePad.toDataURL());
  }
}
