import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Invoice } from '@pos-common/classes/invoice.class';

@Pipe({ name: 'amountOfChange' })
export class AmountOfChangePipe implements PipeTransform {
  transform(value: any) {
    return new Invoice(value).getAmountOfChange();
  }
}
@NgModule({
  declarations: [AmountOfChangePipe],
  exports: [AmountOfChangePipe],
})
export class AmountOfChangePipeModule {}
