<pos-search
  [placeholder]="'inventory_search_product_placeholder' | translate"
  (onSearch)="handleSearchValue($event)"
  (onClearValue)="clearSearch()"
></pos-search>
<ion-content>
  <pos-product-search-list
    *ngIf="!isLoading"
    [productItems]="itemList"
    [showActiveProduct]="true"
    [activeProductItem]="activeProductItem"
    (posProductClick)="selectProduct($event)"
  ></pos-product-search-list>

  <pos-loader class="loader-contaner" [isLoading]="isLoading"></pos-loader>

  <ion-infinite-scroll threshold="20px" (ionInfinite)="loadMore($event)" [disabled]="isLoadMore">
    <ion-infinite-scroll-content loadingSpinner="crescent"> </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
