<ion-backdrop tappable (ionBackdropTap)="closeModal()" class="dark"></ion-backdrop>
<div class="modal-inner">
  <header class="modal-header">
    <a class="back-link" tappable (click)="closeModal()">
      <i class="custom-icon custom-icon-cancel-search"></i>
    </a>
    <h2 class="bold header-text">{{ 'scanner_connect' | translate }}</h2>
  </header>

  <div class="modal-body">
    <p class="white">{{ 'scanner_currentScanner' | translate }}</p>
    <p class="scanner-current" [class.gray]="getCurrentScanner() === ''" (click)="onDisconnect()">
      {{ getCurrentScanner() !== '' ? getCurrentScanner() : ('scanner_noCurrentScanner' | translate) }}
    </p>
    <p class="white">{{ 'scanner_foundScanner' | translate }}</p>

    <ion-list *ngIf="getScannersFound().length > 0">
      <ion-item *ngFor="let app of getScannersFound(); trackBy: trackByFn" (click)="onAppSelected(app)">
        <p class="scanner-name">{{ app }}</p>
      </ion-item>
    </ion-list>

    <p class="gray" *ngIf="getScannersFound().length <= 0">{{ 'scanner_noScannerFound' | translate }}</p>
  </div>
</div>
