import { NgModule } from '@angular/core';
import { VirtualProductListComponent } from './virtual-product-list/virtual-product-list.component';
import { VirtualProductItemComponent } from './virtual-product-item/virtual-product-item.component';
import { VirtualProductGroupComponent } from './virtual-product-group/virtual-product-group.component';
import { InventoryEventsDirectiveModule, NgForTrackByUuidDirectiveModule } from '@pos-modules/shared/directives';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, VirtualScrollerModule, InventoryEventsDirectiveModule, NgForTrackByUuidDirectiveModule],
  declarations: [VirtualProductListComponent, VirtualProductItemComponent, VirtualProductGroupComponent],
  exports: [VirtualProductListComponent],
})
export class VirtualProductListComponentModule {}
