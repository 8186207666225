import { from, Observable } from 'rxjs';
import { Entity } from '../../classes/entity.class';
import { DbDaoService, GetAllDataOptions } from '../db/db-dao.service';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { PaymentMethod } from '../../classes/payment-method.class';
import { VirtualPrinter } from '../../classes/virtual-printer.class';
import { Employee } from '../../classes/employee.class';
import { Invoice } from '../../classes/invoice.class';
import { Store } from '../../classes/store.class';
import { GastronomyHall } from '@pos-common/classes/gastronomy-hall.class';
import { Customer } from '@pos-common/classes/customer.class';
import { map } from 'rxjs/operators';
import { ProductVariant } from '@pos-common/classes/product-variant.class';
import { Product } from '@pos-common/classes/product.class';
import { ProductCategory } from '@pos-common/classes/product-category.class';
import { VatRate } from '@pos-common/classes/vat-rate.class';
import { Query } from '@paymash/capacitor-database-plugin';

export abstract class DbEntityProvider {
  protected constructor(protected entityType: string, protected dbDaoService: DbDaoService) {}

  abstract getList();

  abstract getByUuid(uuid: string);

  protected getEntitiesList(): Observable<PaymentMethod[] | VirtualPrinter[] | Entity[]> {
    return new Observable((observer) => {
      this.dbDaoService
        .getAllData(this.entityType)
        .then((dbResponse) => {
          if (dbResponse.status === 200) {
            observer.next(dbResponse.data);
          } else {
            observer.error('no_data');
          }
          observer.complete();
        })
        .catch(() => {
          observer.error('no_data');
          observer.complete();
        });
    });
  }

  protected getEntityByUuid(uuid: string): Observable<PaymentMethod | VirtualPrinter | Entity> {
    return new Observable((observer) => {
      this.dbDaoService
        .getDataByUUID(this.entityType, uuid)
        .then((dbResponse) => {
          if (dbResponse.status === 200) {
            observer.next(this.transformEntity(dbResponse.data));
          } else {
            observer.error('no_data');
          }
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  protected getEntitiesListByParams(
    queryParams: Query, options?: GetAllDataOptions
  ): Observable<PaymentMethod[] | VirtualPrinter[] | Entity[]> {
    return new Observable((observer) => {
      this.dbDaoService
        .getAllData(this.entityType, queryParams, options)
        .then((dbResponse) => {
          if (dbResponse.status === 200) {
            observer.next(dbResponse.data);
          } else {
            observer.error('no_data');
          }
          observer.complete();
        })
        .catch(() => {
          observer.error('no_data');
          observer.complete();
        });
    });
  }

  protected getEntitiesCountByParams(queryParams: Query): Observable<number> {
    return from(this.dbDaoService.count(this.entityType, queryParams));
  }

  protected mapPipeTransformEntities() {
    return map((entities: Entity[]) => entities.map((entity) => this.transformEntity(entity)));
  }

  protected transformEntity(entity: Entity): PaymentMethod | VirtualPrinter | Entity {
    switch (this.entityType) {
      case UPDATES_TYPES.PaymentMethod.type:
        return new PaymentMethod(entity);
      case UPDATES_TYPES.VirtualPrinter.type:
        return new VirtualPrinter(entity);
      case UPDATES_TYPES.Invoice.type:
        return new Invoice(entity);
      case UPDATES_TYPES.Employee.type:
        return new Employee(entity);
      case UPDATES_TYPES.Store.type:
        return new Store(entity);
      case UPDATES_TYPES.GastronomyHall.type:
        return new GastronomyHall(entity);
      case UPDATES_TYPES.Customer.type:
        return new Customer(entity);
      case UPDATES_TYPES.ProductVariant.type:
        return new ProductVariant(entity);
      case UPDATES_TYPES.Product.type:
        return new Product(entity);
      case UPDATES_TYPES.ProductCategory.type:
        return new ProductCategory(entity);
      case UPDATES_TYPES.VATRate.type:
        return new VatRate(entity);
      default:
        return entity;
    }
  }
}
