import { Observable } from 'rxjs';
import { SavedDeviceInfo, UniversalCustomerDisplayDevice } from './UniversalCustomerDisplayDevice';

export abstract class IDiscovery<Device extends UniversalCustomerDisplayDevice<ConnectionInfo>, ConnectionInfo> {
  abstract get discoveryId(): string;

  abstract startDiscovery(): void;

  abstract stopDiscovery(): void;

  abstract get devices(): Observable<Device[]>;

  abstract getDeviceInfo(device: Device): SavedDeviceInfo;

  abstract restoreDeviceFromInfo(deviceInfo: SavedDeviceInfo): Device;
}
