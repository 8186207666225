import { Employee } from '../employee.class';
import { Image } from '../image.class';

export class Service {
  uuid: string;
  serviceUUID: string;
  serviceName: string;
  price: string;
  duration: string;
  totalDuration: string;
  postProcessingTime: string;
  preparationTime: string;
  image: Image;
  employee: Employee;
  isNew: boolean;
  isFreeEmployeeChoose: boolean;

  constructor(data: any = {}) {
    this.uuid = data.uuid || null;
    this.serviceUUID = data.serviceUUID || '';
    this.serviceName = data.serviceName || '';
    this.price = data.price || '0';
    this.duration = data.duration || '00:30';
    this.totalDuration = data.totalDuration || '00:30';
    this.postProcessingTime = data.postProcessingTime || '00:00';
    this.preparationTime = data.preparationTime || '00:00';
    this.image = data.image ? new Image(data.image) : null;
    this.employee = data.employee ? new Employee(data.employee) : null;
    this.isNew = data.isNew || false;
    this.isFreeEmployeeChoose = data.isFreeEmployeeChoose || false;
  }
}
