export type CustomerDisplayStatus = 'disconnected' | 'offline' | 'connecting' | 'online';

export abstract class ICustomerDisplayDevice {
  abstract get id(): string;

  abstract get providerId(): string;

  abstract get name(): string;

  abstract get status(): CustomerDisplayStatus;

  abstract loadFrom(device: ICustomerDisplayDevice): void;

  equals(other: ICustomerDisplayDevice): boolean {
    if (!other) return false;
    if (this === other) return true;
    return this.id === other.id && this.providerId === other.providerId;
  }
}
