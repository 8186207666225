import { BarcodeScanner, ScanOptions } from '@capacitor-community/barcode-scanner';
import { ScannerPluginWrapperBase } from './abstract-scanner-plugin-wrapper';
import { IBarcodeScannerPluginSetting } from './model';
import { Injectable } from '@angular/core';

@Injectable()
export class CommunityScannerPluginWrapper extends ScannerPluginWrapperBase {
  private plugin = BarcodeScanner;
  startScan(options: IBarcodeScannerPluginSetting) {
    const scanOptions: ScanOptions = {};
    this.plugin.startScan(scanOptions).then((value) => {
      if (!value.hasContent) throw new Error('No content');
      if (!value.content) throw new Error('No content');
      this.onResult({ code: value.content });
    });
    return Promise.resolve();
  }

  stopScan() {
    return this.plugin.stopScan();
  }

  checkPermission(): Promise<boolean> {
    return this.plugin.checkPermission({ force: true }).then((result) => result.granted);
  }
}
