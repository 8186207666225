import { Image } from './image.class';
import { Entity } from './entity.class';
import { GenerateRandomColor } from './generate-random-color.class';

export class ProductCategory extends Entity {
  public uuid: string;
  public name: string;
  public childProductCategories: Array<any>;
  public parentCategoryUuid: string;
  public parentProductCategory: any;
  public positionInParentProductCategory: number;
  public image: Image;
  public smartCategory: boolean;
  public visible: boolean;
  public visibleInWebshop: boolean;
  public webshopShowImage: boolean;
  public deleted: boolean;
  public categoriesList: Array<any> = [];
  public productsList: Array<any> = [];
  public expanded: boolean;
  public bgColor: string;
  public virtualPrinter: Entity;

  constructor(data: any) {
    super(data);
    this.name = data.name || 'Category';
    this.childProductCategories = data.childProductCategories || [];
    this.parentCategoryUuid = data.parentCategoryUuid || null;
    this.parentProductCategory = data.parentProductCategory || null;
    this.positionInParentProductCategory = data.positionInParentProductCategory || 0;
    this.image = data.image ? new Image(data.image) : null;
    this.smartCategory = data.smartCategory || false;
    this.visible = data.visible || false;
    this.deleted = data.deleted || false;
    this.visibleInWebshop = data.visibleInWebshop || false;
    this.webshopShowImage = data.webshopShowImage || false;
    this.expanded = data.expanded || false;
    this.bgColor = data.colorCode || data.bgColor || new GenerateRandomColor().generateColorByText(this.name);
    this.virtualPrinter = data.virtualPrinter || null;
  }
}
