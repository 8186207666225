import { Component, ViewEncapsulation, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Invoice } from '../../../common/classes/invoice.class';
import { Product } from '../../../common/classes/product.class';
import { PRODUCT_TYPES } from '../../../common/constants/product-types';
import { UPDATES_TYPES } from '../../../common/constants/updates-types.const';
import { LoadingService } from '../../../common/services/system/loading.service';
import { DbDaoService } from '@pos-common/services/db/db-dao.service';
import { DbResponse } from '@pos-common/services/db//db-dao.utils';
import { InvoicesService } from '../../../common/services/system/invoices.service';
import { SecurityService } from '../../../common/services/system/security.service';
import { AlertService } from '../../../common/services/system/alert.service';
import { LogService } from '../../../common/services/system/logger/log.service';
import { AwsService } from '../../../common/services/system/aws.service';
import { FILE_ERROR } from '../../../common/constants/file-error.const';
import { PlatformType } from '../../../common/constants/platform-types.enum';
import { PAYMASH_PROFILE } from '@profile';
import { SyncService } from '../../../common/services/system/sync.service';
import { APP_INFO } from '../../../common/constants/app-info.const';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { NotifyPopupsService } from '@pos-common/services/system/notify-popups.service';
import { PAYMENT_TRANSACTION } from '@pos-common/constants/payment-providers.const';
import { ProductVariant } from '@pos-common/classes/product-variant.class';
import { ILogger } from '@spryrocks/logger';

export enum SystemDataFileType {
  SIXLOGS = 'sixlogs',
  BACKUP = 'backup',
}

@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Maintenance implements OnInit {
  private readonly logger: ILogger;
  public isProductionVersion: boolean = true;
  public invoicesKeepDuration: number;
  public invoicesKeepDurationOptions: number[] = [];

  constructor(
    private loadingService: LoadingService,
    private dbDaoService: DbDaoService,
    private invoicesService: InvoicesService,
    private securityService: SecurityService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private logService: LogService,
    private awsService: AwsService,
    private syncService: SyncService,
    private platformService: PlatformService,
    private notifyPopupsService: NotifyPopupsService
  ) {
    this.invoicesKeepDurationOptions = [3, 7, 14, 28];
    this.invoicesKeepDuration = this.invoicesKeepDurationOptions[0];
    this.logger = logService.createLogger('Maintenance');
  }

  ngOnInit() {
    this.isProductionVersion = PAYMASH_PROFILE.APP_ENV === 'production';
  }

  public async createMultipleInvoices() {
    this.loadingService.showLoadingItem();

    const start = new Date();

    try {
      this.logger.debug("createMultipleInvoices, start");
      await this.addTestInvoices(100);

      const end = new Date();

      this.logger.debug('createMultipleInvoices, end', {ms: end.getTime() - start.getTime()});
    } catch (e) {
      this.logger.error(undefined, 'createMultipleInvoices:dbDaoService:getAllDataFromCollection', e);
    } finally {
      this.loadingService.hideLoadingItem();
    }
  }

  private async addTestInvoices(count: number) {
    const products = await this.dbDaoService.getAllDataRaw(UPDATES_TYPES.Product.type, undefined, {limit: 1});
    const product = new Product(products[0]);
    const variant = new ProductVariant(await this.dbDaoService.getDataByUUIDRaw(UPDATES_TYPES.ProductVariant.type, product.variants[0]['uuid']));
    const amount = 100;

    const newEntryData: any = this.invoicesService.makeInvoiceEntryToAddData(variant, product, '', null);
    for (let i = 0; i < amount; i++) {
      let newInvoice: Invoice = this.invoicesService.createInvoice();
      newInvoice = this.invoicesService.addInvoiceEntryToInvoice(newInvoice, newEntryData, PRODUCT_TYPES.PRODUCT, 1);
      this.invoicesService.calculateInvoiceAmountAfterDiscount(newInvoice);
      newInvoice.invoiceId = this.invoicesService.getInvoiceId();
      const invoice = await this.invoicesService
        .addPaymentToInvoice(newInvoice, 'CASH', newInvoice.amount, newInvoice.amount, PAYMENT_TRANSACTION.PAYMENT_PURCHASE);
      await this.invoicesService.saveInvoice(invoice);
    }
  }

  private sendDataToAws(filesType: SystemDataFileType): void {
    this.loadingService.showLoadingItem(0, this.translateService.instant('settings_logs_loading'));
    const { cordova } = window;
    this.logger.debug(`sendFilesToAws:filesType - ${filesType}`);
    const currentCompany = this.securityService.getLoggedCompanyData();
    if (!currentCompany) {
      return;
    }
    const companyId = currentCompany.id || currentCompany.uuid;
    const deviceUuid = this.securityService.getDeviceIdentifier();

    const platform = this.platformService.isAndroid ? PlatformType.Android : PlatformType.iOS;
    const pathOnAws = this.awsService.makeAwsLink(companyId, platform, deviceUuid, filesType);

    let getFilesPromise;
    let localDirectory = cordova ? cordova.file.dataDirectory : '';

    switch (filesType) {
      case SystemDataFileType.SIXLOGS:
        if (!cordova) {
          this.loadingService.hideLoadingItem();
          this.showLogsAreUnavaliableNotification();
          return;
        }
        localDirectory = this.platformService.isAndroid ? cordova.file.dataDirectory : cordova.file.documentsDirectory;
        getFilesPromise = this.logService.getTimApiLogs(localDirectory).catch((err) => {
          this.loadingService.hideLoadingItem();
          if (err.code && err.code === FILE_ERROR.NOT_FOUND_ERR) {
            return this.showLogsAreUnavaliableNotification();
          }
          throw err;
        });
        break;
      case SystemDataFileType.BACKUP:
        getFilesPromise = this.dbDaoService.getFilesListForBackup(currentCompany.uuid);
        break;
    }

    getFilesPromise
      .then((data) => {
        this.awsService.sendFilesToAws(data, pathOnAws, localDirectory, (err) => {
          this.loadingService.hideLoadingItem();
          if (err) {
            this.logger.error(undefined, 'sendDataToAws:sendFilesToAws', err);
            this.notifyPopupsService.launchNotification('logs-error');
          } else {
            this.logger.debug(`sendDataToAws:sendFilesToAws:done`);
            this.notifyPopupsService.launchNotification('logs-sent');
          }
        });
      })
      .catch((err) => this.logger.error(undefined, `sendDataToAws:getFilesPromise:${filesType}`, err));
  }

  public sendDbBackupViaAWS() {
    this.sendDataToAws(SystemDataFileType.BACKUP);
  }

  public sendTimApiLogsViaEmail(): void {
    this.sendDataToAws(SystemDataFileType.SIXLOGS);
  }

  public async showResetCompanyFirstConfirm() {
    const resetCompanyFirstConfirm = await this.alertService.create({
      header: this.translateService.instant('settings_app_reset_confirm_one_title'),
      message: this.translateService.instant('settings_app_reset_confirm_one_text'),
      buttons: [
        { text: this.translateService.instant('common_cancel') },
        {
          text: this.translateService.instant('common_reset'),
          handler: async () => {
            await resetCompanyFirstConfirm.dismiss();
            this.showResetCompanySecondConfirm();
          },
        },
      ],
    });
    await resetCompanyFirstConfirm.present();
  }

  public async showResetCompanySecondConfirm() {
    const resetCompanySecondConfirm = await this.alertService.create({
      header: this.translateService.instant('settings_app_reset_confirm_two_title'),
      message: this.translateService.instant('settings_app_reset_confirm_two_text'),
      buttons: [
        { text: this.translateService.instant('common_cancel') },
        {
          text: this.translateService.instant('common_reset'),
          handler: () => this.resetCompany(),
        },
      ],
    });
    await resetCompanySecondConfirm.present();
  }

  public resetCompany() {
    this.securityService.badRefreshToken.next({ clearLocalDatabase: true });
  }

  private async showLogsAreUnavaliableNotification() {
    const alert = await this.alertService.create({
      header: this.translateService.instant('settings_app_info_title'),
      message: this.translateService.instant('settings_six_logs_are_unvailable'),
      buttons: [{ text: this.translateService.instant('common_close') }],
    });
    await alert.present();
  }

  forceSynchronisationInvoices() {
    this.loadingService.showLoadingItem();
    const promisesArray = [
      this.invoicesService.updateBrokenInvoicesWithDeletePayments(),
      this.invoicesService.updatePaidInvoicesWithoutSequentId(),
      this.syncService.syncInvoicesWithErrorState(),
    ];
    setTimeout(() => {
      Promise.all(promisesArray)
        .then(() => this.loadingService.hideLoadingItem())
        .catch((err) => {
          this.logger.error(undefined, 'forceSynchronisationInvoices:Promise.all', err);
          this.loadingService.hideLoadingItem();
        });
    }, 3000);
  }
}
