import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductItem } from '@pos-common/classes/product-item.class';
import { ProductSettingsInterface } from '@pos-common/interfaces';

@Component({
  selector: 'pos-tipping-search-modal',
  templateUrl: './tipping-search-modal.page.html',
  styleUrls: ['./tipping-search-modal.page.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TippingSearchModalPage implements OnInit {
  @Input() defaultTipProduct: ProductSettingsInterface;
  public activeProductItem: ProductItem;
  constructor(private modalController: ModalController) {}

  ngOnInit() {
    if (this.defaultTipProduct) {
      this.activeProductItem = new ProductItem(this.defaultTipProduct, null);
    }
  }

  dismiss(data?: any) {
    return this.modalController.dismiss(data);
  }

  handleDone() {
    this.dismiss(this.defaultTipProduct);
  }

  selectProductItem(productItem: ProductItem) {
    this.defaultTipProduct = { name: productItem.name, uuid: productItem.uuid };
  }
}
