import { Injectable } from '@angular/core';
import { PaymentResult } from '@pos-common/classes/payment-result.class';
import { PAYMENT_PROVIDERS } from '@pos-common/constants/payment-providers.const';
import { SecurityService } from '../security.service';
import { SumupPlugin } from '@paymash/capacitor-sumup-plugin';
import { PlatformService } from '../platform/platform.service';
import { PAYMASH_PROFILE } from '@profile';

@Injectable()
export class SumUpService {
  private plugin = SumupPlugin;

  constructor(private securityService: SecurityService, private platformService: PlatformService) {}

  async init(): Promise<void> {
    if (this.platformService.isWeb) {
      return;
    }
    return this.plugin.setApiKey({ apiKey: PAYMASH_PROFILE.sumup.apiKey });
  }

  async makeTransaction(amount: number): Promise<PaymentResult> {
    const paymentResult = new PaymentResult(PAYMENT_PROVIDERS.SUMUP);

    if (this.platformService.isWeb) {
      return paymentResult;
    }

    const currentCompany = this.securityService.getLoggedCompanyData();
    const transactionParams = {
      amount,
      currency: currentCompany.locale.currency,
    };
    const sumUpPaymentResult = await this.plugin.makeTransaction(transactionParams);

    paymentResult.setPaymentResultData(sumUpPaymentResult);
    return paymentResult;
  }
}
