import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cart-discount-entry',
  styleUrls: ['../common-invoice-entry.scss', 'cart-discount-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'cart-discount-entry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartDiscountEntryComponent {
  @Input() discount: number;
  @Input() discountPercentage: number;
  @Input() totalInvoiceDiscountAmount: number;
  @Input() name: string;
  @Output() onClick = new EventEmitter();
  @Output() onSwipe = new EventEmitter();

  handleOnClick(event: any) {
    this.onClick.emit(event);
  }

  handleOnSwipe(event: any) {
    this.onSwipe.emit(event);
  }
}
