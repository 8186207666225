export class GenerateRandomColor {
  generateColorByText(title: string, alpha: string = '1') {
    title = title ?? '';
    const crc = this.crc32(title);
    const randomNumber = crc % 1000;
    const randomSat = crc % 19;
    return 'hsla(' + (randomNumber * 240) / 999 + ',' + (+randomSat + 30) + '%,65%,' + alpha + ')';
  }

  private crc32(str: string) {
    const crcTable = this.makeCRCTable();
    let crc = 0 ^ -1;
    for (let i = 0; i < str.length; i++) {
      crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xff];
    }
    return (crc ^ -1) >>> 0;
  }

  private makeCRCTable() {
    let c: number;
    const crcTable = [];
    for (let n = 0; n < 256; n++) {
      c = n;
      for (let k = 0; k < 8; k++) {
        c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1;
      }
      crcTable[n] = c;
    }
    return crcTable;
  }
}
