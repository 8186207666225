import { IBarcodeScannerPluginSetting } from './model';

export type ScanResult = {
  code: string;
}

export type ScanResultCallback = (result: ScanResult) => void;

export abstract class ScannerPluginWrapperBase {
  private callback: ScanResultCallback | undefined;

  setScanCallback(callback: ScanResultCallback) {
    this.callback = callback;
  }

  abstract startScan(options: IBarcodeScannerPluginSetting): Promise<void>;

  abstract stopScan(): Promise<void>;

  abstract checkPermission(): Promise<boolean>;

  onResult(result: ScanResult) {
    if (this.callback) {
      this.callback(result);
    }
  }
}
