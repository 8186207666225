<!--<ion-backdrop class="transparent" [class.hide]="modalSource === 'collection'" tappable (click)="closeModal(null)"></ion-backdrop> -->
<div class="modal-inner" [class.free-pricing]="product?.forcePriceInputOnSale" [class.full]="modalSource === 'collection'">
  <div
    class="tabs-control"
    [class.hide]="variantSelectionActive || freePricingActive || discountActive || inventoriesActive || inventoryActive"
  >
    <a [class.active]="currentTab === tabs.INFO" tappable (click)="changeTab(tabs.INFO)">{{
      'product_details_modal_order_details' | translate
    }}</a>
    <a [class.active]="currentTab === tabs.IMAGES" tappable (click)="changeTab(tabs.IMAGES)">{{
      'product_details_modal_product_details' | translate
    }}</a>
  </div>
  <div class="tabs-holder">
    <div class="product-additional" [class.active]="currentTab === tabs.IMAGES">
      <div class="product-images">
        <div class="product-placeholder">
          <div class="table" *ngIf="product && product.images.length === 0">
            <div class="cell">
              <div class="img-holder" [ngStyle]="{ 'background-color': product.bgColor }">
                <div class="wrapper">
                  <p class="item-placeholder">{{ product.name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="slider-holder" *ngIf="product && product.images.length > 0">
            <swiper-container posIonSlides [slideOptions]="slideOpts" class="swiper-container">
              <swiper-slide *ngFor="let slide of product.images; trackBy: trackByFn" class="swiper-slide">
                <pos-image-loader-cap [url]="slide.image | ImageUrl : IMAGE_SIZES.NORMAL"></pos-image-loader-cap>
              </swiper-slide>
            </swiper-container>
          </div>
        </div>
      </div>
      <div class="product-description">
        <p *ngIf="!product?.additionalInformation">{{ 'product_details_modal_no_desc' | translate }}</p>
        <p *ngIf="product?.additionalInformation">{{ product?.additionalInformation | stripeHtml }}</p>
      </div>
    </div>

    <div class="product-info" [class.active]="currentTab === tabs.INFO">
      <div
        class="product-details-info"
        [class.hide]="variantSelectionActive || freePricingActive || discountActive || inventoriesActive || inventoryActive"
      >
        <common-header>
          <button header-start class="back-link" tappable (click)="closeModal(null, 'header-close')" [hidden]="!newEntry">
            <i class="custom-icon custom-icon-cancel-search"></i>
          </button>
          <h2 header-title>{{ product?.name }}</h2>
          <button header-end class="confirm-link" tappable (click)="closeModal(true, 'header-confirm')" [hidden]="newEntry">
            <i class="custom-icon custom-icon-select"></i>
          </button>
        </common-header>
        <div class="product-info-container">
          <ion-content>
            <div class="modal-body">
              <div *ngIf="product?.isGiftCard" class="assign-gift-card-block" (click)="openAssignGiftCardModal()" tappable>
                <p class="row row-label-value">
                  <span class="row-label">
                    {{ 'product_details_modal_assign_gift_card_number_title' | translate }}
                  </span>
                  <span class="row-value">
                    <span>{{ assignedGiftCardNumber | CardNumberObfuscation : false }}</span>
                    <i class="fi flaticon-arrows"></i>
                  </span>
                </p>
              </div>
              <div class="variant-block" *ngIf="product?.hasCustomOptions && variants" (click)="toggleVariantsSelection()" tappable>
                <p class="row row-label-value">
                  <span class="row-label">{{ 'common_variant' | translate }}</span>
                  <span class="row-value">
                    <span *ngIf="selectedVariant">{{ variantShortName }}</span>
                    <span *ngIf="!selectedVariant">-</span>
                    <i class="fi flaticon-arrows"></i>
                  </span>
                </p>
              </div>
              <div class="name-block">
                <p class="row row-label-value">
                  <span class="row-label">{{ 'product_details_modal_note' | translate }}</span>
                  <span class="row-value">
                    <input type="text" [(ngModel)]="note" placeholder="{{ 'product_details_modal_note' | translate }}" />
                  </span>
                </p>
              </div>

              <div class="service-duration-block" *ngIf="product?.isService">
                <p class="row row-label-value">
                  <span class="row-label">{{ 'product_details_modal_service_duration' | translate }}</span>
                  <span class="row-value">{{ product?.serviceOptions?.serviceDuration | durationTime }}</span>
                </p>
                <p class="row row-label-value">
                  <span class="row-label">{{ 'product_details_modal_service_preparation_time' | translate }}</span>
                  <span class="row-value">{{ product?.serviceOptions?.servicePreparationTime | durationTime }}</span>
                </p>
                <p class="row row-label-value">
                  <span class="row-label">{{ 'product_details_modal_service_post_processing_time' | translate }}</span>
                  <span class="row-value">{{ product?.serviceOptions?.servicePostProcessingTime | durationTime }}</span>
                </p>
              </div>

              <div
                class="inventories-block"
                *ngIf="invoiceEntry?.type !== productTypes.GIFT_CARD && product?.type === productTypes.PRODUCT"
              >
                <p class="row row-label-value" (click)="toogleInventory()" tappable>
                  <span class="row-label">{{ 'product_details_modal_inventory' | translate }}</span>
                  <span class="row-value">
                    <span *ngIf="selectedVariant && selectedVariant.trackInventory && inventories">
                      {{ selectedVariantInventoryInCurrentStore }}
                    </span>
                    <span *ngIf="!selectedVariant || (selectedVariant && !selectedVariant.trackInventory)">-</span>
                  </span>
                  <i
                    *ngIf="selectedVariant && selectedVariant.trackInventory && inventories && activeEmployee?.hasAdminPermission"
                    class="fi flaticon-arrows"
                  ></i>
                </p>
                <p
                  class="row row-label-value"
                  [class.clickable]="stores.length > 1 && selectedVariant && selectedVariant.trackInventory && inventories"
                  (click)="toggleInventories()"
                  tappable
                >
                  <span class="row-label">{{ 'product_details_modal_inventories_other_stores' | translate }}</span>
                  <span class="row-value">
                    <span *ngIf="selectedVariant && selectedVariant.trackInventory && inventories">
                      {{ selectedVariantInventoryInOtherStore }}
                    </span>
                    <span *ngIf="!selectedVariant || (selectedVariant && !selectedVariant.trackInventory)">-</span>
                  </span>
                  <i
                    *ngIf="stores.length > 1 && selectedVariant && selectedVariant.trackInventory && inventories"
                    class="fi flaticon-arrows"
                  ></i>
                </p>
              </div>

              <div class="price-block price-item-block">
                <p class="row row-label-value" *ngIf="product?.isSale">
                  <span class="row-label">{{ 'product_details_modal_item_strikethrough_price' | translate }}</span>
                  <span class="row-value">
                    <span>{{ product.wasPrice | CustomCurrency : false }}</span>
                  </span>
                </p>
                <p class="row row-label-value" (click)="toggleFreePricing()" tappable>
                  <span class="row-label">{{ 'product_details_modal_item_price' | translate }}</span>
                  <span class="row-value">
                    <span>{{ priceDecimal | CustomCurrency : false }}</span>
                    <i *ngIf="product?.forcePriceInputOnSale" class="fi flaticon-arrows"></i>
                  </span>
                </p>
              </div>

              <div class="quantity-block" *ngIf="invoiceEntry?.type !== productTypes.GIFT_CARD && !product?.isGiftCard">
                <ul>
                  <li class="quantity-subtract">
                    <a (click)="changeQuantity('subtract')" tappable><i class="custom-icon custom-icon-minus-border"></i></a>
                  </li>
                  <li class="quantity-value">
                    <form [formGroup]="productForm">
                      <input formControlName="quantity" type="number" />
                    </form>
                  </li>
                  <li class="quantity-add">
                    <a (click)="changeQuantity('add')" tappable><i class="custom-icon custom-icon-plus-border"></i></a>
                  </li>
                </ul>
              </div>
              <div class="discount-block" (click)="toggleDiscounts(false)" tappable>
                <p class="row row-label-value">
                  <span class="row-label">{{ 'product_details_modal_discount_per_item' | translate }}</span>
                  <span class="row-value">
                    <span *ngIf="discount === 0 && discountPercentage === 0">-</span>
                    <span *ngIf="discount && priceDecimal >= discountDecimal">{{ discountDecimal | CustomCurrency : true }}</span>
                    <span *ngIf="discount && priceDecimal < discountDecimal">{{ priceDecimal | CustomCurrency : true }}</span>
                    <span *ngIf="discountPercentage">{{ discountDecimal + '%' }}</span>
                    <i class="fi flaticon-arrows"></i>
                  </span>
                </p>
              </div>
              <div class="price-block price-total-block">
                <p class="row row-label-value">
                  <span class="row-label">{{ 'product_details_modal_price_total' | translate }}</span>
                  <span class="row-value">{{ discountedTotalAmount | CustomCurrency : false }}</span>
                </p>
              </div>

              <div class="add-button-holder">
                <!-- <vat-rates-switcher *ngIf="currentCompany.isTakeAwayEnabled && selectedVariant && selectedVariant.isTakeAwayAllowed" [selectedVatRate]="invoiceEntry.specialTaxing" (onRateChange)="onRateChange($event)"></vat-rates-switcher> -->
                <a class="btn btn-remove" (click)="closeModal(true, 'footer-remove')" [hidden]="newEntry" tappable
                  ><i class="custom-icon custom-icon-paid-invoices-trash"></i
                ></a>
                <a class="btn btn-add-product" (click)="closeModal(true, 'footer-add')" [hidden]="!newEntry" tappable>{{
                  'product_details_modal_add_to_cart' | translate
                }}</a>
                <a class="btn btn-done" (click)="closeModal(true, 'footer-done')" [hidden]="newEntry" tappable>{{
                  'common_done' | translate
                }}</a>
              </div>
            </div>
          </ion-content>
        </div>
      </div>

      <product-variant-picker
        *ngIf="variantSelectionActive"
        [product]="product"
        [variants]="variants"
        [setVariantCallback]="variantSelectedCallback"
        [handleFreePricingInComponent]="false"
      ></product-variant-picker>

      <pos-product-details-pricing-picker
        *ngIf="freePricingActive"
        class="free-pricing-block"
        [customPrice]="tempPriceDecimal"
        [customPriceCents]="tempPriceCents"
        (updatePrice)="setNewValue($event)"
        (goBack)="toggleFreePricing()"
      ></pos-product-details-pricing-picker>

      <pos-product-details-discount-picker
        *ngIf="discountActive"
        class="free-pricing-block"
        [discountType]="discountType"
        [discountCents]="discountCents"
        [discountDecimal]="discountDecimal"
        (updateDiscount)="updateDiscount($event)"
        (goBack)="toggleDiscounts(false)"
      ></pos-product-details-discount-picker>

      <pos-product-details-inventories-list
        *ngIf="inventoriesActive"
        class="free-pricing-block"
        [selectedVariant]="selectedVariant"
        [inventories]="inventories"
        [currentStore]="currentStore"
        (goBack)="toggleInventories()"
      ></pos-product-details-inventories-list>

      <pos-product-details-inventory
        *ngIf="inventoryActive"
        class="free-pricing-block"
        [selectedVariant]="selectedVariant"
        [inventory]="selectedVariantInventoryInCurrentStore"
        [currentStore]="currentStore"
        (goBack)="updateInventory($event)"
      ></pos-product-details-inventory>
    </div>
  </div>
</div>
