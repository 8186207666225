import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'pos-text-popover',
  template: '{{ text | translate }}',
  styleUrls: ['./text-popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextPopoverComponent {
  @Input() text: string;
}
@NgModule({
  imports: [TranslateModule.forChild()],
  declarations: [TextPopoverComponent],
  exports: [TextPopoverComponent],
})
export class TextPopoverComponentModule {}
