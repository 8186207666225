import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouteNavigationService } from '../services/system/route-navigation/route-navigation.service';

@Injectable({ providedIn: 'root' })
export class InitResolver implements Resolve<boolean> {
  constructor(private routeNavigationService: RouteNavigationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.routeNavigationService.isInitializationFinished();
  }
}
