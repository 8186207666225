import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'pos-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() ionName: string;
  @Input() customName: string;
  @Input() className: string;
}
@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [IconComponent],
  exports: [IconComponent],
})
export class IconComponentModule {}
