import { IDatabase } from '@paymash/capacitor-database-plugin';
import { MigrationBase, MigrationInfo } from './core/MigrationBase';
import { Injectable } from '@angular/core';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { DbDaoUtils } from '../db-dao.utils';

@Injectable()
export class Migration_6_Invoice_Search extends MigrationBase {
  version = 6;
  name = 'Invoice search';
  constructor(private dbDaoUtils: DbDaoUtils) {
    super();
  }

  async up(database: IDatabase, info: MigrationInfo) {
    const companyId = info.companyId;
    let data: any[] = undefined;

    const collectionName = UPDATES_TYPES.Invoice.type;
    let collection = this.getCollection(collectionName, companyId);
    const iterator = await database.findIterator(collection, { offset: 50 });
    while ((data = await iterator.next())) {
      data = data.map((item) => this.prepareItem(collectionName, item));
      await database.addOrUpdateMany(collection, data as object[]);
    }
  }

  private prepareItem(collectionName: string, item: any): object {
    const newItem = { ...item };
    if (newItem.ext_type) {
      this.dbDaoUtils.addExtTypeFieldsToSearch(collectionName, newItem);
    } else {
      this.dbDaoUtils.addFieldsToSearch(collectionName, newItem);
    }
    return newItem;
  }
}
