import { Component, ViewEncapsulation, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { Customer } from '@pos-common/classes/customer.class';

@Component({
  selector: 'pos-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerListComponent {
  @Input() selectedCustomerUuid: string;
  @Input() customers: Customer[];
  @Input() isLoading: boolean;
  @Input() hideLoadButton: boolean;
  @Input() showEditButton: boolean;
  @Input() loadInterface: string;

  @Output() onLoadMore = new EventEmitter<Event>();
  @Output() onSelectCustomer = new EventEmitter<Customer>();
  @Output() onEditCustomer = new EventEmitter<Customer>();

  handleLoadMore(event?: Event) {
    this.onLoadMore.emit(event);
  }

  handleSelectCustomer(customer: Customer) {
    this.onSelectCustomer.emit(customer);
  }

  handleEditCustomer(customer: Customer) {
    this.onEditCustomer.emit(customer);
  }
}
