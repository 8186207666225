import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { LoadingService } from './loading.service';
import { Platform } from '@ionic/angular';
import { LogService } from './logger/log.service';
import { stringifySafety } from '@pos-common/services/system/logger';
import { PlatformType } from '../../constants/platform-types.enum';
import {
  Keyboard,
  SplashScreen,
  StatusBar,
  Camera,
} from '@pos-common/classes/capacitor-plugins/capacitor-plugins.class';
import { TimApiPlugin } from '@paymash/capacitor-tim-api-plugin';
import { MyPosMiniPlugin } from '@pos-modules/my-pos-mini';
import { MyPosN5Plugin } from '@paymash/capacitor-mypos-plugin';
import { SunmiPrinterPlugin, SunmiScannerPlugin } from '@paymash/capacitor-sunmi-plugin';
import { SumupPlugin } from '@paymash/capacitor-sumup-plugin';
import { Database } from '@paymash/capacitor-database-plugin';
import { StarPrinterPlugin } from '@paymash/capacitor-star-printer-plugin';
import { EpsonprintPlugin } from '@paymash/capacitor-epsonprint-plugin';
import { SocketScannerPlugin } from '@paymash/capacitor-socket-scanner-plugin';
import { WifiInfoPlugin } from '@paymash/capacitor-wifi-info-plugin';
import { PrintPdfPlugin } from '@paymash/capacitor-print-pdf-plugin';
import { ScannerAppPlugin } from '@paymash/capacitor-scanner-app-plugin';
import { BarcodeScannerPlugin } from '@paymash/capacitor-barcode-scanner-plugin';
import { FingerprintPlugin } from '@paymash/capacitor-fingerprint-plugin';

interface IPluginItem {
  name: string;
  selector: any;
  platform?: PlatformType;
}

// tslint:disable: no-string-literal
@Injectable()
export class PosSelfCheckService {
  constructor(
    private alertService: AlertService,
    private loadingService: LoadingService,
    private platform: Platform,
    private logService: LogService,
  ) {
  }

  public checkPluginsState() {
    if (!window.cordova) {
      return;
    }
    this.loadingService.showLoadingItem(0, 'POS self check', true);
    const platformType = this.platform.is(PlatformType.iOS) ? PlatformType.iOS : PlatformType.Android;
    try {
      const pluginsList = this.getNotInstalledPlugins(platformType);
      if (pluginsList.length > 0) {
        this.showMissedPluginsNotification(pluginsList.join('\n'));
      }
    } catch (e) {
      this.logService.error('PosSelfCheckService', 'checkPluginsState', e);
      this.showMissedPluginsNotification(stringifySafety(e));
    }
    this.loadingService.hideLoadingItem();
  }

  private async showMissedPluginsNotification(message: string) {
    const alert = await this.alertService.create({
      header: 'Error',
      message: message,
      buttons: [{ text: 'OK' }],
    });
    alert.present().catch((error) => this.logService.error('PosSelfCheckService', 'showMissedPluginsNotification', error));
  }

  private getNotInstalledPlugins(platformType: PlatformType): string[] {
    const pluginList = this.getPluginList();
    const filteredPluginList = pluginList.filter((plugin) => !plugin.platform || plugin.platform === platformType);
    return filteredPluginList.filter((plugin) => !plugin.selector).map((item) => item.name);
  }

  private getPluginList() {
    const iOSPlaginList = this.getiOSPluginsList();
    const androidPlaginList = this.getAndroidPluginsList();
    const commonPluginList = this.getCommonPluginList();
    return [...iOSPlaginList, ...androidPlaginList, ...commonPluginList];
  }

  private getiOSPluginsList(): IPluginItem[] {
    return [
      {
        name: 'com.fabware.ScannerAppPlugin',
        platform: PlatformType.iOS,
        selector: ScannerAppPlugin,
      },
      {
        name: 'fabware-tim-api-plugin',
        platform: PlatformType.iOS,
        selector: TimApiPlugin,
      },
      {
        name: 'com.fabware.MyPosMiniPlugin',
        platform: PlatformType.iOS,
        selector: MyPosMiniPlugin,
      },
    ];
  }

  private getAndroidPluginsList(): IPluginItem[] {
    return [
      {
        name: 'fabware-tim-api-plugin',
        platform: PlatformType.Android,
        selector: TimApiPlugin,
      },
      {
        name: 'cordova-plugin-android-permissions',
        platform: PlatformType.Android,
        selector: cordova.plugins['permissions'],
      },
      {
        name: 'com.fabware.MyPosN5Plugin',
        platform: PlatformType.Android,
        selector: MyPosN5Plugin,
      },
      {
        name: 'com.fabware.MyPosMiniPlugin',
        platform: PlatformType.Android,
        selector: MyPosMiniPlugin,
      },
      {
        name: 'capacitor-sunmi-plugin',
        platform: PlatformType.Android,
        selector: SunmiPrinterPlugin,
      },
      {
        name: 'capacitor-sunmi-plugin',
        platform: PlatformType.Android,
        selector: SunmiScannerPlugin,
      },
    ];
  }

  private getCommonPluginList(): IPluginItem[] {
    const pluginsList: IPluginItem[] = [
      {
        name: 'com.fabware.SocketScannerPlugin',
        selector: SocketScannerPlugin,
      },
      {
        name: 'com.fabware.SumupPlugin',
        selector: SumupPlugin,
      },
      {
        name: 'com.fabware.WifiDataPlugin',
        selector: WifiInfoPlugin,
      },
      {
        name: 'com-nyxmo-plugins-ecr',
        selector: ecr,
      },
      {
        name: 'cordova-plugin-print-pdf',
        selector: PrintPdfPlugin,
      },
      {
        name: 'cordova-plugin-advanced-http',
        selector: cordova['plugin'].http,
      },
      {
        name: 'cordova-plugin-android-permissions',
        selector: cordova.plugins['permissions'],
      },
      {
        name: 'cordova-plugin-app-version',
        selector: cordova['getAppVersion'],
      },
      {
        name: 'capacitor-plugin-camera',
        selector: Camera,
      },
      {
        name: 'cordova-plugin-console',
        selector: console,
      },
      {
        name: 'cordova-plugin-device',
        selector: device,
      },
      {
        name: 'cordova-plugin-device-name',
        selector: cordova.plugins['deviceName'],
      },
      {
        name: 'cordova-plugin-file',
        selector: window['LocalFileSystem'],
      },
      {
        name: 'cordova-plugin-file-transfer',
        selector: window['FileTransfer'],
      },
      {
        name: 'cordova-plugin-globalization',
        selector: navigator.globalization,
      },
      {
        name: 'cordova-plugin-inappbrowser',
        selector: cordova['InAppBrowser'],
      },
      {
        name: 'capacitor-plugin-keyboard',
        selector: Keyboard,
      },
      {
        name: 'cordova-plugin-market',
        selector: cordova.plugins['market'],
      },
      {
        name: 'cordova-plugin-nativestorage',
        selector: window['NativeStorage'],
      },
      {
        name: 'cordova-plugin-network-information',
        selector: Connection,
      },
      {
        name: 'cordova-plugin-screen-orientation',
        selector: cordova.plugins['screenorientation'],
      },
      {
        name: 'capacitor-plugin-splashscreen',
        selector: SplashScreen,
      },
      {
        name: 'capacitor-plugin-statusbar',
        selector: StatusBar,
      },
      {
        name: 'com.paymash.DatabasePlugin',
        selector: Database,
      },
      {
        name: 'com.paymash.StarPrinter',
        selector: StarPrinterPlugin,
      },
      {
        name: 'com.paymash.Epsonprint',
        selector: EpsonprintPlugin,
      },
      {
        name: 'com-fabware-barcode-scanner',
        selector: BarcodeScannerPlugin,
      },
      {
        name: 'fingerprint-plugin',
        selector: FingerprintPlugin,
      },
    ];
    return pluginsList;
  }
}
// tslint:enable: no-string-literal
