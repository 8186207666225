import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Customer } from '@pos-common/classes/customer.class';
import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import { ICustomerPageData } from '@pos-common/interfaces/customer-page-data.interface';
import { CartService } from '@pos-common/services/system/cart.service';
import { CustomerService } from '@pos-common/services/system/customers.service';
import { KeyboardService } from '@pos-common/services/system/keyboard/keyboard.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { ModalService } from '@pos-common/services/system/modal.service';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { ButtonComponentModule, HeaderComponentModule, IconComponentModule } from '@pos-modules/shared/components';
import { CustomerSearchComponentModule } from '../customer-search/customer-search.component';

@Component({
  selector: 'pos-guest-details-modal',
  templateUrl: './guest-details-modal.component.html',
  styleUrls: ['./guest-details-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestDetailsModalComponent implements OnInit {
  @Input() invoiceEntryGuest: InvoiceEntryGuest;
  public guestDetailsForm: FormGroup;
  public showClearButton = false;
  private customer: Customer = null;
  private modal: HTMLIonModalElement;

  get defaultName() {
    return `${this.translateService.instant('common_guest')} ${this.invoiceEntryGuest.guestNumber}`;
  }

  constructor(
    private translateService: TranslateService,
    private viewController: ModalService,
    private cartService: CartService,
    private customerService: CustomerService,
    private platformService: PlatformService,
    private keyboardService: KeyboardService,
    private logService: LogService
  ) {
    this.initForm();
  }

  private initForm(): void {
    this.guestDetailsForm = new FormGroup({
      name: new FormControl(''),
    });
  }

  ngOnInit() {
    this.invoiceEntryGuest = new InvoiceEntryGuest(this.invoiceEntryGuest);
    this.viewController
      .getTop()
      .then((modal) => (this.modal = modal))
      .catch((err) => this.logService.error('GuestDetailsModalComponent', 'ngOnInit:viewController:getTop', err));
    this.setName();
    this.setShowClearButton();
  }

  private setName(): void {
    const { name, customerIdentifier } = this.invoiceEntryGuest;
    if (name !== this.defaultName && !customerIdentifier) {
      this.guestDetailsForm.patchValue({ name });
    }
  }

  private setShowClearButton() {
    const { name, customerIdentifier } = this.invoiceEntryGuest;
    if ((name !== this.defaultName && !customerIdentifier) || customerIdentifier) {
      this.showClearButton = true;
    }
  }

  confirmModal() {
    let { name: newName } = this.guestDetailsForm.value;
    const { name, customerIdentifier } = this.invoiceEntryGuest;
    if (this.customer) {
      newName = `${this.customer.firstName} ${this.customer.lastName}`;
      this.saveGuest(newName, this.customer.uuid);
      return;
    }
    if (newName && newName !== name) {
      this.saveGuest(newName);
      return;
    }
    if (!newName && name !== this.defaultName && !customerIdentifier) {
      this.saveGuest(this.defaultName);
      return;
    }
    this.closeModal();
  }

  private saveGuest(name: string, customerIdentifier: string = null) {
    if (this.invoiceEntryGuest.isCustomerGuest) {
      this.setGuestAsCustomer();
    }
    this.invoiceEntryGuest.update({ ...this.invoiceEntryGuest, name, customerIdentifier });
    this.cartService.changeEntries([this.invoiceEntryGuest]);
    this.closeModal();
  }

  private setGuestAsCustomer() {
    this.cartService.addCustomerToGuest(this.customer, this.invoiceEntryGuest.guestNumber);
    this.cartService.setCustomer(this.customer, false);
  }

  closeModal() {
    if (this.keyboardService.isOpen) {
      this.keyboardService.getCloseEventOnce().subscribe(() => this.dismiss());
      return;
    }
    this.dismiss();
  }

  private dismiss() {
    this.modal.dismiss().catch((err) => this.logService.error('GuestDetailsModalComponent', 'closeModal:viewController:dismiss', err));
  }

  selectCustomer(customer: Customer) {
    this.customer = customer;
  }

  removeGuestData() {
    this.saveGuest(this.defaultName);
  }

  async createNewCustomer() {
    const data: ICustomerPageData = {
      fromState: 'Guest',
      actionsType: 'new',
      guestNumber: this.invoiceEntryGuest.guestNumber,
      isCustomerGuest: this.invoiceEntryGuest.isCustomerGuest,
    };
    this.showCustomerPage(data);
  }

  async editCustomer(customer: Customer) {
    const data: ICustomerPageData = {
      fromState: 'Guest',
      customer,
      guestNumber: this.invoiceEntryGuest.guestNumber,
      isCustomerGuest: this.invoiceEntryGuest.isCustomerGuest,
    };
    this.showCustomerPage(data);
  }

  private async showCustomerPage(data: ICustomerPageData) {
    const animated = !this.platformService.isMobile;
    await this.customerService.showCustomerPage(data, animated);
    this.modal.animated = false;
    this.closeModal();
  }
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    ButtonComponentModule,
    CustomerSearchComponentModule,
    IconComponentModule,
    HeaderComponentModule,
  ],
  declarations: [GuestDetailsModalComponent],
  exports: [GuestDetailsModalComponent],
})
export class GuestDetailsModalComponentModule {}
