<ion-backdrop tappable (ionBackdropTap)="dismiss()"></ion-backdrop>
<div class="tipping-search-content">
  <pos-header>
    <button tappable (click)="dismiss()" header-start>
      <pos-icon customName="custom-icon-cancel-search"></pos-icon>
    </button>
    <ion-title header-title>{{ 'settings_tipping_default_product' | translate }}</ion-title>
    <button (click)="handleDone()" header-end tappable>
      <pos-icon customName="custom-icon-select"></pos-icon>
    </button>
  </pos-header>
  <pos-tipping-product-search
    [activeProductItem]="activeProductItem"
    (posSelectProduct)="selectProductItem($event)"
  ></pos-tipping-product-search>
</div>
