<div class="guest-button" [class.disabled]="isDisabled" (click)="selectGuest()">
  <div *ngIf="showCheckbox" class="pos-checkbox">
    <div class="checkbox-icon" [class.checkbox-checked]="isSelected">
      <div class="checkbox-inner"></div>
    </div>
  </div>
  <div class="guest-icon-block">
    <pos-icon customName="guest-icon"></pos-icon>
    <pos-badge [value]="marker"></pos-badge>
  </div>
  <div class="guest-button-name">
    <span class="guest-button-title">{{ name }}</span>
    <button *ngIf="isChangeable" class="guest-button-edit" (click)="editGuest($event)" tappable>
      <pos-icon customName="custom-icon-edit"></pos-icon>
    </button>
  </div>
</div>
