<ion-item-sliding class="item-in-list" #slide>
  <ion-item
    (click)="handleOpen()"
    [class.item-in-list-cancelled]="isCancelInvoice"
    [class.item-in-list-no-show]="!itemInList.isCustomerVisit"
  >
    <pos-list-grid>
      <pos-list-col col="id" class="col-appointment-id">{{ itemInList.appointmentId }}</pos-list-col>
      <pos-list-col col="start-date">{{ itemInList.startedAt | CompanyLocalized: 'dateTimeFormat' }}</pos-list-col>
      <pos-list-col col="service"><pos-list-view-plus-more [list]="itemInList.serviceNames"></pos-list-view-plus-more></pos-list-col>
      <pos-list-col col="duration">{{ itemInList.totalDuration | durationTime }}</pos-list-col>
      <pos-list-col col="price">{{ itemInList.totalPrice }}</pos-list-col>
      <pos-list-col col="customer">{{ itemInList.customerDisplay }}</pos-list-col>
      <pos-list-col col="employee"><pos-list-view-plus-more [list]="itemInList.employeeNames"></pos-list-view-plus-more></pos-list-col>
    </pos-list-grid>
  </ion-item>
  <ion-item-options side="end" (click)="close(slide)">
    <button class="item-in-list-cancel-button" *ngIf="isAllowCancellation" (click)="handleCancel($event)">
      <span>{{ text | translate }}</span>
    </button>
  </ion-item-options>
</ion-item-sliding>
