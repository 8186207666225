import { Component, ElementRef, NgModule, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { PrinterService } from '../../services/system/printer.service';
import { PrintingQueueItem } from '../../services/system/receipt-printers/classes/receipt-printer.class';
import { NavController } from '@ionic/angular';
import { LogService } from '../../services/system/logger/log.service';
import { ROUTE_URLS } from '../../constants/route-urls.const';
import { SubSink } from 'subsink';

@Component({
  selector: 'printer-status-icon',
  styleUrls: ['printer-status-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'printer-status-icon.component.html',
})
export class PrinterStatusIconComponent implements OnInit, OnDestroy {
  isRed: boolean = false;
  public printingQueueElementsCount: number = 0;
  private subs = new SubSink();

  constructor(
    private printerService: PrinterService,
    private navController: NavController,
    private elementRef: ElementRef,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.subs.sink = this.printerService.printingQueue.subscribe((items) => this.setUiData(items));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setUiData(printingQueueItems: PrintingQueueItem[]) {
    this.printingQueueElementsCount = printingQueueItems.length;
    this.elementRef.nativeElement.classList.toggle('hidden', this.printingQueueElementsCount === 0);
    this.isRed = printingQueueItems.reduce((hasPrintingError, printingQueueItem) => {
      if (printingQueueItem.printingTriesCount > 3) {
        hasPrintingError = true;
      }
      return hasPrintingError;
    }, false as boolean);
  }

  openPrintingQueue() {
    this.navController
      .navigateForward(ROUTE_URLS.printerQueue)
      .catch((err) => this.logService.error('PrinterStatusIconComponent', 'openPrintingQueue', err));
  }
}

@NgModule({
  declarations: [PrinterStatusIconComponent],
  exports: [PrinterStatusIconComponent],
})
export class PrinterStatusIconComponentModule {}
