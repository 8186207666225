import { Injectable } from '@angular/core';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { SecurityService } from '../system/security.service';

@Injectable()
export class ReportApiService {
  constructor(private SecurityService: SecurityService) {}

  public getReportData(reportQueryParameters: string) {
    const url = `${SERVER_CONFIG.API_URL}report${reportQueryParameters}`;
    const optionsData = {
      showLoading: true,
    };

    return this.SecurityService.doSecureRequestObservable(url, 'get', null, optionsData);
  }
}
