import { Injectable } from '@angular/core';
import { SecurityService } from './security.service';
import { Company } from '../../classes/company.class';
import { PaymentProcessingService } from '../../components/payment-processing/payment-processing.service';
import * as xmlbuilder from 'xmlbuilder';
import * as xml2js from 'xml2js';
import { PaymentResult } from '../../classes/payment-result.class';
import { PAYMENT_PROVIDERS } from '../../constants/payment-providers.const';
import { TranslateService } from '@ngx-translate/core';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { LoadingService } from './loading.service';
import { AlertService } from './alert.service';
import { LocalStorage } from '../utils/localstorage.utils';
import { LogService } from './logger/log.service';
import { PaymentProcessingActions } from '../../components/payment-processing/payment-processing-actions.enum';
import { stringifySafety } from '@paymash/logger-capacitor';

@Injectable()
export class IngenicoService {
  // ip: string = "10.0.1.144";
  // port: number = 5577;

  ip: string = null;
  port: number = null;
  port_listening: number = 5578; //20002;
  connect_timeout: number = 10;
  response_timeout: number = 60;
  waitForCard: any = null; // Fix to show provide card screen

  receipt_customer = [];
  receipt_merchant = [];

  readonly requestTypes: any = {
    CardServiceRequest: 'CardServiceRequest',
    ServiceRequest: 'ServiceRequest',
    DeviceRequest: 'DeviceRequest',
  };

  public useIngenicoPrinter = {
    get: () =>
      this.LocalStorage.getObject('OPIterminal') && this.LocalStorage.get('OPIterminalPrinting')
        ? JSON.parse(this.LocalStorage.get('OPIterminalPrinting'))
        : false,
    set: (condition) => this.LocalStorage.set('OPIterminalPrinting', condition),
  };

  constructor(
    public securityService: SecurityService,
    public TranslateService: TranslateService,
    public AlertService: AlertService,
    private networkInterface: NetworkInterface,
    public LoadingService: LoadingService,
    public LocalStorage: LocalStorage,
    public PaymentProcessingService: PaymentProcessingService,
    private logService: LogService
  ) {}

  connectToIngenicoTerminal(ip: string, port: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.logService.debug('IngenicoService', `connectToIngenicoTerminal ip ${ip}, port ${port}`);
      this.LoadingService.showLoadingItem(
        0,
        this.TranslateService.instant('payment_processing_wizard_payment_processing_connecting_to_terminal')
      );
      this.startLogin(true, ip, port)
        .then(() => {
          this.ip = ip;
          this.port = port;
          this.LocalStorage.setObject('OPIterminal', { ipAddress: ip, port: port });
          this.LoadingService.hideLoadingItem();
          this.logService.debug('IngenicoService', `connectToIngenicoTerminal successfully`);
          resolve({ ipAddress: ip, port: port });
        })
        .catch((err) => {
          this.LoadingService.hideLoadingItem();
          this.LocalStorage.remove('OPIterminal');
          this.logService.error('IngenicoService', `connectToIngenicoTerminal error`, err);
          reject();
        });
    });
  }

  disconnectIngenicoTerminal(): Promise<any> {
    return new Promise((resolve) => {
      this.ip = null;
      this.port = null;
      this.LocalStorage.remove('OPIterminal');
      this.logService.debug('IngenicoService', `disconnectIngenicoTerminal successfully`);
      resolve({ ipAddress: null, port: null });
    });
  }

  checkIngenicoTerminal(): boolean {
    const storageData = this.LocalStorage.getObject('OPIterminal');
    if (storageData?.ipAddress && storageData?.port) {
      this.ip = storageData.ipAddress;
      this.port = storageData.port;
    }
    return !!this.ip && !!this.port;
  }

  getCurrentTerminal(): any {
    return this.checkIngenicoTerminal() ? { ipAddress: this.ip, port: this.port } : null;
  }

  timeoutIsRunning: boolean = false;
  runTimeout = () => {
    if (!this.timeoutIsRunning) {
      this.timeoutIsRunning = true;
      setTimeout(() => (this.timeoutIsRunning = false), 30000);
    }
  };

  startIngenicoIpCheck() {
    return new Promise<unknown | undefined>((res) => {
      if (this.timeoutIsRunning) {
        this.notifyMsg(this.TranslateService.instant('ingenico_pls_wait_30_sec'));
      } else {
        if (window['cordova']) {
          this.networkInterface
            .getWiFiIPAddress()
            .then((data) => {
              //this.notifyMsg('TEST info message - ip of device: ' + data['ip']);
              if (data['ip'] !== '0.0.0.0') {
                this.LoadingService.showLoadingItem(0, this.TranslateService.instant('ingenico_searching_for_terminal'));

                let firstNumbers = data['ip'].split('.', 3).join('.');
                let lastNumber = data['ip'].split('.').pop();
                let foundIP = [];
                //let foundIP = ['10.0.1.230'];

                const startLogin = (ip) => {
                  return new Promise<void>((resolve) => {
                    this.startLogin(true, ip)
                      .then(() => {
                        foundIP.push(ip);
                        resolve();
                      })
                      .catch(() => resolve());
                  });
                };

                // const checkIps = () => {
                //   return new Promise(async resolve => {
                //     for (let i = 0; i < 255; i++) {
                //       if(lastNumber !== i.toString()) {
                //         await startLogin(firstNumbers + '.' + i.toString());
                //         if(i === 255) resolve();
                //       }
                //     }
                //   })
                // }

                const checkIps = () => {
                  return new Promise<void>((resolve) => {
                    let promises = [];
                    for (let i = 1; i < 255; i++) {
                      if (lastNumber !== i.toString()) promises.push(startLogin(firstNumbers + '.' + i.toString()));
                    }
                    Promise.all(promises)
                      .then(() => resolve())
                      .catch((err) => this.logService.error('IngenicoService', 'startIngenicoIpCheck:checkIps:Promise:all', err));
                  });
                };

                setTimeout(() => {
                  checkIps()
                    .then(() => {
                      this.LoadingService.hideLoadingItem();
                      setTimeout(() => {
                        if (foundIP.length !== 0) {
                          if (foundIP.length > 1) {
                            this.ipChooseCase(foundIP)
                              .then((ip) => {
                                this.connectToIngenicoTerminal(ip.toString(), 5577).catch((err) =>
                                  this.logService.error(
                                    'IngenicoService',
                                    'startIngenicoIpCheck:checkIps:then:ipChooseCase:then:connectToIngenicoTerminal',
                                    err
                                  )
                                );
                                this.runTimeout();
                                res(ip);
                              })
                              .catch((err) =>
                                this.logService.error('IngenicoService', 'startIngenicoIpCheck:checkIps:then:ipChooseCase', err)
                              );
                          } else {
                            this.notifyMsg(this.TranslateService.instant('ingenico_terminal_found_with_ip') + foundIP[0]);
                            this.connectToIngenicoTerminal(foundIP[0], 5577).catch((err) =>
                              this.logService.error('IngenicoService', 'startIngenicoIpCheck:checkIps:then:connectToIngenicoTerminal', err)
                            );
                            this.runTimeout();
                            res(foundIP[0]);
                          }
                        } else {
                          this.notifyMsg(this.TranslateService.instant('ingenico_no_terminal_was_found'));
                          this.runTimeout();
                          res(undefined);
                        }
                      }, 700);
                    })
                    .catch(() => {
                      this.LoadingService.hideLoadingItem();
                    });
                }, 700);
              } else {
                this.notifyMsg(this.TranslateService.instant('ingenico_no_terminal_was_found'));
                res(undefined);
              }
            })
            .catch(() => res(undefined));
        } else {
          res(undefined);
        }
      }
    });
  }

  ipChooseCase(ips) {
    return new Promise<unknown>(async (resolve) => {
      let radioInputs = [];
      ips.map((ip) => {
        radioInputs.push({
          type: 'radio',
          label: ip,
          value: ip,
        });
      });
      let radio = await this.AlertService.create({
        header: this.TranslateService.instant('ingenico_please_select_terminal'),
        inputs: radioInputs,
        buttons: [
          {
            text: 'Ok',
            handler: (data: any) => {
              resolve(data);
            },
          },
        ],
      });
      radio.present().catch((err) => this.logService.error('IngenicoService', 'ipChooseCase:notify:radio', err));
    });
  }

  async notifyMsg(msg) {
    let notify = await this.AlertService.create({
      header: 'Info',
      subHeader: msg,
      buttons: ['Ok'],
    });
    notify.present().catch((err) => this.logService.error('IngenicoService', 'notifyMsg:notify:present', err));
  }

  initIngenicoPayment(amountGiven, refund?: boolean, reversal?): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.checkIngenicoTerminal()) {
        this.logService.debug(
          'IngenicoService',
          `initIngenicoPayment amountGiven ${amountGiven}, refund ${refund}, reversal ${JSON.stringify(reversal)}`
        );
        let retryProc = (amount, msg = '') => {
          this.PaymentProcessingService.dispatchAction(PaymentProcessingActions.retry, { message: msg });
        };

        let processing = (amount, initialization?) => {
          if (initialization) {
            this.PaymentProcessingService.init(
              () => {
                processing(amount);
              },
              () => {
                reject(null);
              }
            );
          }
          this.PaymentProcessingService.dispatchAction(PaymentProcessingActions.connecting, { cancelButtonOff: true });

          this.startLogin()
            .then(() => {
              setTimeout(() => {
                this.waitForCard = null;
                this.PaymentProcessingService.dispatchAction(PaymentProcessingActions.provideCard);
                let transactionMethodType;
                if (refund) {
                  transactionMethodType = this.refundTransaction(amountGiven);
                } else if (reversal) {
                  transactionMethodType = this.paymentReversal(reversal);
                } else {
                  transactionMethodType = this.startCardPayment(amount);
                }
                transactionMethodType
                  .then(
                    (res) => {
                      //console.log("here Ingenico SUCCESS: " + JSON.stringify(res));
                      this.logService.debug('IngenicoService', `transactionMethodType ${stringifySafety(res)}`);
                      if (res && res.overallResult && res.overallResult === 'Success') {
                        let TimeStamp = res['raw'].CardServiceResponse.Tender[0].Authorisation[0]['$'].TimeStamp
                          ? new Date(res['raw'].CardServiceResponse.Tender[0].Authorisation[0]['$'].TimeStamp).toISOString()
                          : new Date().toISOString();
                        //let CardCircuit = res['raw'].CardServiceResponse.Tender[0].Authorisation[0]["$"].CardCircuit;
                        let paymentResult = new PaymentResult(PAYMENT_PROVIDERS.OPI);
                        let terminalTransaction = null;
                        if (
                          res['raw'].CardServiceResponse.Terminal[0]['$'].TerminalID &&
                          res['raw'].CardServiceResponse.Terminal[0]['$'].STAN &&
                          res['raw'].CardServiceResponse.Tender[0].Authorisation[0]['$'].TimeStamp
                        ) {
                          terminalTransaction = {
                            deviceId: res['raw'].CardServiceResponse.Terminal[0]['$'].TerminalID,
                            stan: res['raw'].CardServiceResponse.Terminal[0]['$'].STAN,
                            timestamp: res['raw'].CardServiceResponse.Tender[0].Authorisation[0]['$'].TimeStamp,
                          };
                        }
                        let prData = {
                          date: TimeStamp,
                          merchantReceipt: res.receipts.merchant,
                          cardholderReceipt: res.receipts.customer,
                          terminalTransaction: terminalTransaction,
                        };
                        // console.log('prData', prData)
                        this.logService.debug('IngenicoService', `prData ${stringifySafety(prData)}`);
                        paymentResult.setPaymentResultData(prData);
                        resolve(paymentResult);
                      } else if (res && res.overallResult && res.overallResult === 'Aborted') {
                        retryProc(amount, this.TranslateService.instant('ingenico_aborted'));
                      } else {
                        if (res['raw'].CardServiceResponse.Tender[0].TotalAmount[0]['$'].Currency === '') {
                          let currentCompany: Company = this.securityService.getLoggedCompanyData();
                          let msg = this.TranslateService.instant('ingenico_wrong_currency', { currency: currentCompany.locale.currency });
                          if (refund) msg = this.TranslateService.instant('ingenico_refund_unsuccessful');
                          if (reversal) msg = this.TranslateService.instant('ingenico_reversal_unsuccessful');
                          retryProc(amount, msg);
                        } else {
                          retryProc(amount, this.TranslateService.instant('ingenico_aborted'));
                        }
                      }
                    },
                    (err) => {
                      // console.log('Ingenico got procces error!', err);
                      retryProc(amount, err);
                    }
                  )
                  .catch((err) => {
                    this.logService.error('IngenicoService', 'initIngenicoPayment:transactionMethodType', err);
                    // console.log('Ingenico got same procces error!', err);
                  });
              }, 2000);
            })
            .catch((err) => {
              this.logService.error('IngenicoService', 'initIngenicoPayment:startLogin', err);
              retryProc(amount, this.TranslateService.instant('connection_timeout'));
            });
        };
        processing(amountGiven, true);
      } else {
        this.notifyMsg(this.TranslateService.instant('ingenico_no_terminal_configured'));
      }
    });
  }

  makeRefundTransaction(amountGiven) {
    return new Promise((resolve, reject) => {
      this.initIngenicoPayment(amountGiven, true)
        .then((paymentResult) => resolve(paymentResult))
        .catch((err) => reject(err));
    });
  }

  refundTransaction(amountGiven): Promise<any> {
    let currentCompany: Company = this.securityService.getLoggedCompanyData();
    let xmlObj = this.prepareRequestXML(this.requestTypes.CardServiceRequest);
    xmlObj.CardServiceRequest['@RequestType'] = 'PaymentRefund';
    xmlObj.CardServiceRequest['@RequestID'] = '1';
    xmlObj.CardServiceRequest['@WorkstationID'] = '1';
    xmlObj.CardServiceRequest['TotalAmount'] = {
      '@Currency': currentCompany.locale.currency,
      //'@Currency': 'EUR',
      '#text': amountGiven,
    };
    return this.runRequest(xmlObj);
  }

  makeReversalTransaction() {
    return new Promise((resolve, reject) => {
      if (this.originalTransactionData) {
        this.initIngenicoPayment(null, false, this.originalTransactionData)
          .then((paymentResult) => resolve(paymentResult))
          .catch((err) => reject(err));
        this.originalTransactionData = null;
      } else {
        reject('No Original Transaction Data');
      }
    });
  }

  private originalTransactionData = null;

  originalTransaction(invoice) {
    if (invoice.invoicePayments) {
      let payment = invoice.invoicePayments.filter((val) => val.paymentProvider === PAYMENT_PROVIDERS.OPI);
      if (
        payment[0] &&
        payment[0].terminalTransaction.stan &&
        payment[0].terminalTransaction.deviceId &&
        payment[0].terminalTransaction.timestamp
      ) {
        this.originalTransactionData = {
          deviceId: payment[0].terminalTransaction.deviceId,
          stan: payment[0].terminalTransaction.stan,
          timestamp: payment[0].terminalTransaction.timestamp,
        };
      } else {
        this.notifyMsg(this.TranslateService.instant('ingenico_reversal_unsuccessful'));
      }
    }
  }

  paymentReversal(originalTransaction): Promise<any> {
    let xmlObj = this.prepareRequestXML(this.requestTypes.CardServiceRequest);
    xmlObj.CardServiceRequest['@RequestType'] = 'PaymentReversal';
    xmlObj.CardServiceRequest['@RequestID'] = '1';
    xmlObj.CardServiceRequest['@WorkstationID'] = '1';
    xmlObj.CardServiceRequest['OriginalTransaction'] = {
      '@TerminalID': originalTransaction.deviceId,
      '@TerminalBatch': '',
      '@STAN': originalTransaction.stan,
      '@TimeStamp': originalTransaction.timestamp,
      '@ApplicationID': '',
    };
    return this.runRequest(xmlObj);
  }

  runDiagnostic() {
    let xmlObj = this.prepareRequestXML(this.requestTypes.ServiceRequest);
    xmlObj.ServiceRequest['@RequestType'] = 'Diagnosis';
    xmlObj.ServiceRequest['@RequestID'] = '1';
    xmlObj.ServiceRequest['@WorkstationID'] = '1';
    xmlObj.ServiceRequest['POSdata'] = {
      POSTimeStamp: new Date().toISOString(),
      DiagnosisMethod: 'OnLine',
    };
    return this.runRequest(xmlObj);
  }

  startLogoff() {
    let xmlObj = this.prepareRequestXML(this.requestTypes.ServiceRequest);
    xmlObj.ServiceRequest['@RequestType'] = 'Logoff';
    xmlObj.ServiceRequest['@RequestID'] = '1';
    xmlObj.ServiceRequest['@WorkstationID'] = '1';
    return this.runRequest(xmlObj);
  }

  startLogin(check = false, ip?, port = 5577): Promise<any> {
    let xmlObj = this.prepareRequestXML(this.requestTypes.ServiceRequest);
    xmlObj.ServiceRequest['@RequestType'] = 'Login';
    xmlObj.ServiceRequest['@RequestID'] = '1';
    xmlObj.ServiceRequest['@WorkstationID'] = '1';
    if (check) {
      return this.runRequest(xmlObj, true, ip, port);
    } else {
      if (this.useIngenicoPrinter.get()) {
        xmlObj.ServiceRequest['PrivateData'] = {
          PrinterParam: {
            '@Type': 'EFT-TerminalPrinter',
            Receipt: [
              { '@Type': 'Customer', '#text': 'yes' },
              { '@Type': 'Merchant', '#text': 'yes' },
              { '@Type': 'Administration', '#text': 'yes' },
            ],
          },
        };
      } else {
        xmlObj.ServiceRequest['PrivateData'] = {
          PrinterParam: [
            {
              '@Type': 'Printer',
              Receipt: [
                { '@Type': 'Merchant', '#text': 'yes' },
                { '@Type': 'Administration', '#text': 'yes' },
              ],
            },
            {
              '@Type': 'PrinterReceipt',
              Receipt: { '@Type': 'Customer', '#text': 'yes' },
            },
          ],
        };
      }
      return this.runRequest(xmlObj);
    }
  }

  startCardPayment(amount): Promise<any> {
    let currentCompany: Company = this.securityService.getLoggedCompanyData();
    let xmlObj = this.prepareRequestXML(this.requestTypes.CardServiceRequest);
    xmlObj.CardServiceRequest['@RequestType'] = 'CardPayment';
    xmlObj.CardServiceRequest['@RequestID'] = '1';
    xmlObj.CardServiceRequest['@WorkstationID'] = '1';
    xmlObj.CardServiceRequest['TotalAmount'] = {
      '@Currency': currentCompany.locale.currency,
      //'@Currency': 'EUR',
      '#text': amount,
    };
    return this.runRequest(xmlObj);
  }

  reconciliationWithClosure(): Promise<any> {
    let xmlObj = this.prepareRequestXML(this.requestTypes.ServiceRequest);
    xmlObj.ServiceRequest['@RequestType'] = 'ReconciliationWithClosure';
    xmlObj.ServiceRequest['@RequestID'] = '1';
    xmlObj.ServiceRequest['@WorkstationID'] = '1';
    return this.runRequest(xmlObj);
  }

  balance(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.startLogin()
        .then(() => {
          this.reconciliationWithClosure()
            .then((data) => {
              if (data.raw.ServiceResponse['$'] && data.raw.ServiceResponse['$'].OverallResult === 'Success') {
                resolve(data.receipts.merchant);
              } else {
                reject('here ERROR');
              }
            })
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  runRequestCheckWasLaunched: boolean = false;

  runRequest(xmlObj, check = false, ipAddress?, port?): Promise<any> {
    let self = this;
    this.receipt_customer = [];
    this.receipt_merchant = [];

    return new Promise((resolve, reject) => {
      this.logService.debug(
        'IngenicoService',
        `check ${check}, runRequestCheckWasLaunched ${this.runRequestCheckWasLaunched}, port_listening ${self.port_listening}`
      );
      if (!check || (check && !this.runRequestCheckWasLaunched)) {
        if (check && !this.runRequestCheckWasLaunched) this.runRequestCheckWasLaunched = true;
        this.logService.debug('IngenicoService', 'startDeviceServer');
        ecr.startDeviceServer(
          self.port_listening,
          (res) => {
            this.logService.debug('IngenicoService', `startDeviceServer:then ${JSON.stringify(res)}`);
            self
              .parseXml(res['request'])
              .then((reqObj) => {
                self.processAndRespondDeviceRequest(reqObj, res['id']);
              })
              .catch((err) => this.logService.error('IngenicoService', 'runRequest:parseXml', err));
          },
          (err) => {
            this.logService.error('IngenicoService', 'runRequest:startDeviceServer', err);
          }
        );
      }
      this.logService.debug('IngenicoService', `xmlObj ${JSON.stringify(xmlObj)}`);
      const data = xmlbuilder.create(xmlObj, { encoding: 'UTF-8' }).end({ pretty: false });
      const length_header = self.toBytesInt32(data.length);
      const xmlArray = self.str2ab(data);
      const dataBuffer = self.appendBuffer(length_header, xmlArray);
      const bufhex = this.buf2hex(data);

      if (!check) {
        ecr.doRequest(this.ip, this.port, this.connect_timeout, this.response_timeout, dataBuffer).then(
          (res) => {
            self
              .parseXml(res)
              .then((res) => {
                // console.log('here RESULT OBJ', JSON.stringify(res));
                this.logService.debug('IngenicoService', 'runRequest:!check:doRequest');
                const csr = res.CardServiceResponse ? res['CardServiceResponse']['$']['OverallResult'] : null;
                const result = {
                  overallResult: csr,
                  receipts: {
                    merchant: self.receipt_merchant,
                    customer: self.receipt_customer,
                  },
                  raw: res,
                };
                // console.log('here doRequest resolve', JSON.stringify(result));
                resolve(result);
              })
              .catch((err) => this.logService.error('IngenicoService', 'runRequest:parseXml', err));
          },
          (err) => {
            this.logService.error('IngenicoService', 'runRequest:!check:doRequest:error', err);
            // console.log("here runRequest failed: " + JSON.stringify(err));
            reject(err);
          }
        );
      } else {
        ecr.doRequest(ipAddress, port, 5, 30, dataBuffer).then(
          (res) => {
            this.logService.debug('IngenicoService', 'runRequest:else:doRequest');
            self
              .parseXml(res)
              .then((res) => resolve(res))
              .catch((err) => this.logService.error('IngenicoService', 'runRequest:parseXml', err));
          },
          (err) => {
            this.logService.error('IngenicoService', 'runRequest:else:doRequest:error', err);
            reject(err);
          }
        );
      }
    });
  }

  parseXml(xml): Promise<any> {
    return new Promise((resolve, reject) => {
      this.logService.debug('IngenicoService', `parseXml ${xml}`);
      xml = xml.replace(/\r?\n|\r/g, ' ');
      xml2js.parseString(xml, (err, result) => {
        resolve(result);
      });
    });
  }

  prepareRequestXML(type) {
    let xmlObj;
    switch (type) {
      case this.requestTypes.ServiceRequest:
        xmlObj = {
          ServiceRequest: {
            '@RequestType': '#set-later',
            '@ApplicationSender': 'NYXMOOPISDK',
            '@RequestID': '#set-later',
            '@POPID': '001',
            '@xmlns': 'http://www.nrf-arts.org/IXRetail/namespace',
            '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
            POSdata: {
              POSTimeStamp: new Date().toISOString(),
              //'POSTimeStamp': "2018-11-05T13:09:00"
            },
          },
        };
        break;
      case this.requestTypes.CardServiceRequest:
        xmlObj = {
          CardServiceRequest: {
            '@RequestType': '#set-later',
            '@ApplicationSender': 'NYXMOOPISDK',
            '@RequestID': '#set-later',
            '@POPID': '001',
            '@xmlns': 'http://www.nrf-arts.org/IXRetail/namespace',
            '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
            POSdata: {
              POSTimeStamp: new Date().toISOString(),
              //'POSTimeStamp': "2018-11-05T13:09:00"
            },
          },
        };
        break;
      case this.requestTypes.DeviceRequest:
        xmlObj = {
          DeviceRequest: {
            '@RequestType': '#set-later',
            '@ApplicationSender': 'NYXMOOPISDK',
            '@RequestID': '#set-later',
            '@POPID': '001',
            '@xmlns': 'http://www.nrf-arts.org/IXRetail/namespace',
            '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
            POSdata: {
              POSTimeStamp: new Date().toISOString(),
              //'POSTimeStamp': "2018-11-05T13:09:00"
            },
          },
        };
        break;
    }
    return xmlObj;
  }

  processAndRespondDeviceRequest(req, id) {
    this.logService.debug('IngenicoService', `processAndRespondDeviceRequest id ${id}, req ${JSON.stringify(req)}`);
    const xmlObj = {
      DeviceResponse: {
        '@ApplicationSender': 'NYXMOOPISDK',
        '@RequestID': req['DeviceRequest']['$']['RequestID'],
        '@xmlns': 'http://www.nrf-arts.org/IXRetail/namespace',
        '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
        OverallResult: 'Success',
        Output: {
          '@OutDeviceTarget': req['DeviceRequest']['Output'][0]['$']['OutDeviceTarget'],
          '@OutResult': 'Success',
        },
      },
    };

    // check if it is a display change
    switch (req['DeviceRequest']['Output'][0]['$']['OutDeviceTarget']) {
      case 'Printer': // Merchant print
        for (let line of req['DeviceRequest']['Output'][0]['TextLine']) {
          switch (typeof line) {
            case 'string':
              this.receipt_merchant.push(line);
              break;
            case 'object':
              this.receipt_merchant.push(line['_']);
              break;
          }
        }
        break;

      case 'PrinterReceipt': // Customer print
        for (let line of req['DeviceRequest']['Output'][0]['TextLine']) {
          switch (typeof line) {
            case 'string':
              this.receipt_customer.push(line);
              break;
            case 'object':
              this.receipt_customer.push(line['_']);
              break;
          }
        }
        break;

      case 'CashierDisplay':
        if (!this.waitForCard) {
          this.waitForCard = req['DeviceRequest']['Output'][0]['TextLine'][0]['_'];
        } else {
          if (this.waitForCard !== req['DeviceRequest']['Output'][0]['TextLine'][0]['_']) {
            this.waitForCard = req['DeviceRequest']['Output'][0]['TextLine'][0]['_'];
            this.PaymentProcessingService.dispatchAction(PaymentProcessingActions.processing);
          }
        }
        //console.log("here fire event [cashierDisplay:change]", req);
        this.PaymentProcessingService.dispatchAdditionalData(req['DeviceRequest']['Output'][0]['TextLine'][0]['_']);
        break;
    }

    // create response
    this.logService.debug('IngenicoService', `create response xmlObj ${JSON.stringify(xmlObj)}`);
    const xml = xmlbuilder.create(xmlObj, { encoding: 'UTF-8' }).end({ pretty: false });
    const length_header = this.toBytesInt32(xml.length);
    const xmlArray = this.str2ab(xml);
    const data = this.appendBuffer(length_header, xmlArray);
    const bufhex = this.buf2hex(data);

    ecr.respondDeviceRequest(id, data).then(
      () => {
        this.logService.debug('IngenicoService', 'processAndRespondDeviceRequest:respondDeviceRequest');
        // console.log("here respondDeviceRequest ok: ", ret);
      },
      (err) => {
        this.logService.error('IngenicoService', 'processAndRespondDeviceRequest:respondDeviceRequest', err);
        // console.log("here respondDeviceRequest failed: " + err);
      }
    );
  }

  toBytesInt32(num) {
    let arr = new ArrayBuffer(4); // an Int32 takes 4 bytes
    let view = new DataView(arr);
    view.setUint32(0, num, false); // byteOffset = 0; litteEndian = false
    return arr;
  }

  appendBuffer(buffer1, buffer2) {
    let tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp.buffer;
  }

  str2ab(str) {
    let array = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
      array[i] = str.charCodeAt(i);
    }
    return array.buffer;
  }

  ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }

  buf2hex(buffer) {
    // buffer is an ArrayBuffer
    return Array.prototype.map.call(new Uint8Array(buffer), (x) => ('00' + x.toString(16)).slice(-2)).join('');
  }
}
