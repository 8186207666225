import { APPOINTMENT_EVENT_TYPE, APPOINTMENT_STATUS } from '@pos-common/constants';
import { Customer } from '../customer.class';
import { Employee } from '../employee.class';

export class AppointmentInCalendar {
  public uuid: string;
  public id: string;
  public groupId: string;
  public resourceId: string;
  public start: string;
  public end: string;
  public eventType: APPOINTMENT_EVENT_TYPE;
  public title: string;
  public appointmentStart: string;
  public appointmentEnd: string;
  public status: string;
  public color: string;
  public hasInvoice: boolean;
  public employee: Employee;
  public customer: Customer;
  public isCustomerVisit: boolean;
  public className: string;

  constructor(data: any = {}) {
    this.uuid = data.uuid || '';
    this.id = data.id || 0;
    this.groupId = data.groupId || 0;
    this.resourceId = data.resourceId || 0;
    this.title = data.title || '';
    this.start = data.start || '';
    this.end = data.end || '';
    this.eventType = APPOINTMENT_EVENT_TYPE[data.eventType] || APPOINTMENT_EVENT_TYPE.APPOINTMENT;
    this.appointmentStart = data.appointmentStart || '';
    this.appointmentEnd = data.appointmentEnd || '';
    this.status = APPOINTMENT_STATUS[data.status] || APPOINTMENT_STATUS.DRAFT;
    this.hasInvoice = data.hasInvoice || false;
    this.employee = data.employee ? new Employee(data.employee) : null;
    this.customer = data.customer ? new Customer(data.customer) : null;
    this.color = this.getColor(data.color);
    this.isCustomerVisit = typeof data.isCustomerVisit === 'boolean' ? !!data.isCustomerVisit : true;
    this.className = this.getClassName();
  }

  private getColor(color: string) {
    if (this.status === APPOINTMENT_STATUS.CANCELED_BY_CUSTOMER) {
      return '#989898';
    }
    return color || '';
  }

  private getClassName() {
    if (this.status === APPOINTMENT_STATUS.CANCELED_BY_CUSTOMER) {
      return 'canceled-by-customer-appointment';
    }
    if (!this.isCustomerVisit) {
      return 'no-show-appointment';
    }
    return '';
  }
}
