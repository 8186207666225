import { Injectable } from '@angular/core';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { Observable } from 'rxjs';
import { DbDaoService } from '../db/db-dao.service';
import { DbEntityProvider } from './db-entity.provider';
import { GastronomyHall } from '@pos-common/classes/gastronomy-hall.class';
import { arrayContains } from '@paymash/capacitor-database-plugin';

@Injectable()
export class GastronomyHallsProvider extends DbEntityProvider {
  constructor(dbDaoService: DbDaoService) {
    super(UPDATES_TYPES.GastronomyHall.type, dbDaoService);
  }

  getByUuid(uuid: string): Observable<GastronomyHall> {
    return <Observable<GastronomyHall>>this.getEntityByUuid(uuid);
  }

  getList(): Observable<GastronomyHall[]> {
    return <Observable<GastronomyHall[]>>this.getEntitiesList();
  }

  getByGastronomyTable(gastronomyTable: any): Observable<GastronomyHall> {
    return new Observable((observer) => {
      if (!gastronomyTable) {
        observer.next(null);
        observer.complete();
        return;
      }

      this.dbDaoService
        .getAllData(this.entityType, { 'gastronomyTables.uuid': arrayContains(gastronomyTable.uuid) })
        .then((dbResponse) => {
          if (dbResponse.status === 200) {
            const gastonomyHalls: GastronomyHall[] = dbResponse.data.map((data) => this.transformEntity(data));
            if (gastonomyHalls.length) {
              observer.next(gastonomyHalls[0]);
              observer.complete();
            }
          }
          observer.error('no_data');
          observer.complete();
        })
        .catch(() => {
          observer.error('no_data');
          observer.complete();
        });
    });
  }
}
