import { Product } from './entities/Product';
import { ICustomerDisplayDevice } from './ICustomerDisplayDevice';
import { Observable } from 'rxjs';
import { EmailModelChanged } from './ICustomerDisplayService';
import { InvoiceEntry, InvoiceGuest } from './entities';

export abstract class ICustomerDisplaySession {
  abstract get device(): ICustomerDisplayDevice;

  abstract destroy(): Promise<void>;

  abstract initialize(currency: string, lang: string, image: string | undefined): Promise<void>;

  abstract setProductsForScreensaver(products: Product[]): Promise<void>;

  abstract addCustomer(name: string, image: string | undefined): Promise<void>;

  abstract removeCustomer(): Promise<void>;

  abstract updateInvoiceEntries(entries: InvoiceEntry[], guests: InvoiceGuest[], amount: number): Promise<void>;

  abstract requestEmailAddress(amount: number, qrCode: string): Promise<void>;

  abstract setEmailAddress(value: string): Promise<void>;

  abstract invoiceSent(): Promise<void>;

  abstract lockUserInput(): Promise<void>;

  abstract unlockUserInput(): Promise<void>;

  abstract emailModelChanged(): Observable<EmailModelChanged>;

  abstract get closed(): Observable<ICustomerDisplaySession>;
}
