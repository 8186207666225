import { IDeviceReportingRegistration } from './device-report.interface';
import { ReportingDevice, ServiceReport } from './device-report.models';
// import { DeviceReportingService } from './device-report.service';

export class DeviceReportingRegistration<TEventType extends string, TDevice extends ReportingDevice = ReportingDevice>
  implements IDeviceReportingRegistration<TEventType, TDevice>
{
  // constructor(private readonly deviceReportService: DeviceReportingService, private readonly serviceName: string) {}

  report(eventType: TEventType, report: ServiceReport): void {
    // const event = this.deviceReportService.createEvent(eventType, this.serviceName, report.device, report.extra);
    // this.deviceReportService.report(event);
  }
}
