import { Injectable, Injector } from '@angular/core';
import { CommonPrinterService, OnDiscover, OnError } from '../common-printer.service';
import { StarPrintersScannerInterface } from '../../interfaces/star-printers-scanner.interface';
import { Subject, Observable } from 'rxjs';
import { ReceiptPrinter } from '../../classes/receipt-printer.class';
import { PrinterDto } from '../../dto/printer.dto';
import { StarPrinterScannerEventErrorCodesEnum } from '../../enum/star-printer-scanner-event-error-codes.enum';
import { StarPrinterScannerEventErrorsInterface } from '../../interfaces/star-printer-scanner-event-errors.interface';
import { StarPrinterPlugin, BarcodeReaderResultMessage } from '@paymash/capacitor-star-printer-plugin';
import { stringifySafety } from '@pos-common/services/system/logger';
import { PrinterType } from '../../enum/printer-type.enum';

@Injectable()
export class StarPrinterService extends CommonPrinterService implements StarPrintersScannerInterface {
  private _scannerEvents: Subject<BarcodeReaderResultMessage> = new Subject<BarcodeReaderResultMessage>();

  constructor(injector: Injector) {
    super(injector);
  }

  protected get discoverMethodName(): string {
    return '';
  }

  protected get pluginName(): string {
    return 'StarPrinterService';
  }

  protected get printMethodName(): string {
    return '';
  }

  protected get stopDiscoverMethodName(): string {
    return '';
  }

  get scannerEvents(): Observable<BarcodeReaderResultMessage> {
    return this._scannerEvents.asObservable();
  }

  protected callDiscoverMethod(onDiscover: OnDiscover, onError: OnError) {
    StarPrinterPlugin.setDiscoverDevicesListener({
      onMessage: (data: any) => {
        if (data?.devices) {
          data.devices.forEach((printer) => {
            printer.deviceType = PrinterType.Star;
          });
        }
        onDiscover(data);
      },
      onError: (error) => onError(error),
    });
    StarPrinterPlugin.startDiscoverDevices().catch((error) => onError(error));
  }

  protected callStopDiscoverMethod(): Promise<void> {
    return StarPrinterPlugin.stopDiscoverDevices();
  }

  protected callPrintReceiptMethod(printer: PrinterDto, receipt: any[]): Promise<void> {
    return StarPrinterPlugin.print({ printer, receipt });
  }

  startBarcodeReader(device: ReceiptPrinter): Observable<BarcodeReaderResultMessage> {
    const { printerName, deviceName, connectionType, isScannerActive, printerModel } = device;
    const printerDeviceName = this.isAndroid ? deviceName : printerModel;
    const printer = new PrinterDto(device.deviceType, device.target, printerDeviceName, device.ipAddress, device.macAddress);
    const loggerDevice = { printerName, deviceName, connectionType, isScannerActive, printerModel };
    this.logService.warn('ActiveDevice', stringifySafety(loggerDevice));
    StarPrinterPlugin.setBarcodeMessageListener({
      onMessage: (message) => this._scannerEvents.next(message),
      onError: (error) => this.handleBarcodeScannerErrorEvents(error as any),
    });
    StarPrinterPlugin.startBarcodeReader({ printer }).catch((error) => this.handleBarcodeScannerErrorEvents(error));
    return this.scannerEvents;
  }

  stopBarcodeReader(): Promise<void> {
    return StarPrinterPlugin.stopBarcodeReader();
  }

  private handleBarcodeScannerErrorEvents(event: StarPrinterScannerEventErrorsInterface) {
    switch (event.code) {
      case StarPrinterScannerEventErrorCodesEnum.ACTION_ALREADY_RUNNING:
        this.logService.warn('StarPrinterService', `handleBarcodeScannerErrorEvents ${event.code}`);
        break;
      case StarPrinterScannerEventErrorCodesEnum.CONNECTION_FAILED:
        this.logService.warn('StarPrinterService', `handleBarcodeScannerErrorEvents ${event.code}`);
        break;
      default:
        this.logService.warn('StarPrinterService', `handleBarcodeScannerErrorEvents ${event.code}`);
        break;
    }
  }
}
