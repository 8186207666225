import { Injectable } from '@angular/core';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { SecurityService } from '../system/security.service';

@Injectable()
export class StoreApiService {
  constructor(private securityService: SecurityService) {}

  updateInventory(storeUuid: string, variantUuid: string, data: any) {
    const url = `${SERVER_CONFIG.API_V2_URL}stores/${storeUuid}/variants/${variantUuid}/inventory`;

    return this.securityService.doSecureRequestObservable(url, 'post', data);
  }
}
