<div #calendar></div>
<div class="date-picker">
  <div class="pos-picker-before"></div>
  <div class="pos-picker-select"></div>
  <div class="pos-picker-columns">
    <pos-picker-column [col]="colMonths" (posPickerColChange)="handleMonthsColChange($event)"></pos-picker-column>
    <pos-picker-column [col]="colYears" (posPickerColChange)="handleYearsColChange($event)"></pos-picker-column>
  </div>
  <div class="pos-picker-after"></div>
</div>
