import { ReceiptBuilder, IMyPosReceiptItem } from './receipt-builder.class';
import { ReceiptPrinterInterface } from '../interfaces/receipt-printer.interface';
import { PRINTER_MODELS } from '../printer-models.const';
import {
  ReceiptPrinterConfigurationInterface,
  ReceiptWidthConfigurationInterface,
} from '../interfaces/receipt-printer-configuration.interface';
import { VirtualPrinter } from '../../../../classes/virtual-printer.class';
import { ReceiptPrinterConnectionTypeEnum } from '../enum/receipt-printer-connection-type.enum';
import { PrinterType } from '../enum/printer-type.enum';

/**
 * Created by y.belinsky on 10/28/16.
 */

export class ReceiptPrinter implements ReceiptPrinterInterface {
  public deviceType: PrinterType;
  public target: string;
  public deviceName: string;
  public ipAddress: string;
  public macAddress: string;

  public printerName: string;
  public printerModel: string;
  public isActive: boolean;
  public isPosPrinter: boolean;
  public isKitchenPrinter: boolean;
  public isScannerActive: boolean;
  public printerViewName: string;
  public printerNameForModelPick: string;
  public connectionType: ReceiptPrinterConnectionTypeEnum;
  public saved: boolean;
  public virtualPrinters: VirtualPrinter[];
  public virtualPrintersUuid: string[];

  /**
   *
   * @param printerData
   * deviceType  // 0 == Epson, 1 == STAR
   * target // Used to identify printer and to send print command
   * deviceName // Used to display for user
   * ipAddress // Can be used to display to user
   * macAddress // Can be used to map with older printer dictionaries
   */
  constructor(printerData) {
    this.printerName = printerData.printerName || printerData.deviceName;
    this.deviceName = printerData.deviceName;
    this.ipAddress = printerData.ipAddress;
    this.macAddress = printerData.macAddress;
    this.target = printerData.target;
    this.printerNameForModelPick = printerData.printerNameForModelPick || printerData.deviceName;
    this.connectionType = ReceiptPrinter.getConnectionType(printerData.target, printerData.connectionType);
    this.printerModel = this.getPrinterModel();
    this.deviceType = this.getDeviceType(printerData.deviceType);
    this.isActive = printerData.isActive || false;
    this.isPosPrinter = printerData.isPosPrinter || false;
    this.isKitchenPrinter = printerData.isKitchenPrinter || false;
    this.isScannerActive = printerData.isScannerActive || false;
    this.printerViewName = printerData.printerViewName || null;
    this.saved = printerData.saved || false;
    this.virtualPrinters = printerData.virtualPrinters ? this.transformVirtualPrinters(printerData.virtualPrinters) : [];
    this.setVirtualPrintersUuid();

    if (!this.target) {
      this.generateTarget();
    }
  }

  private getDeviceType(type: PrinterType): PrinterType {
    switch (type) {
      case PrinterType.Epson:
      case PrinterType.Star:
      case PrinterType.MyPos:
      case PrinterType.MyPosMini:
      case PrinterType.Sunmi:
        return type;
      default:
        return this.printerConfiguration.printerType;
    }
  }

  public static get isIos(): boolean {
    return window['device'] && device.platform && device.platform.toLocaleLowerCase().indexOf('ios') !== -1;
  }

  public static getConnectionType(target: string, fallback?: ReceiptPrinterConnectionTypeEnum): ReceiptPrinterConnectionTypeEnum {
    if (!target) return fallback;
    if (target.indexOf(ReceiptPrinterConnectionTypeEnum.BT) !== -1) {
      return ReceiptPrinterConnectionTypeEnum.BT;
    }
    return ReceiptPrinterConnectionTypeEnum.TCP;
  }

  public generateTarget() {
    switch (this.deviceType) {
      case PrinterType.Epson:
        this.target = `${this.connectionType}:${this.macAddress}`;
        break;
      case PrinterType.Star:
        this.target = this.getTargetForStar();
        break;
    }
  }

  private getTargetForStar(): string {
    switch (this.connectionType) {
      case ReceiptPrinterConnectionTypeEnum.BT:
        if (ReceiptPrinter.isIos) {
          const { modelBT } = this.printerConfiguration;
          const currentModel = this.getModelForIos(modelBT);
          return `${this.connectionType}:${currentModel}`;
        }
        break;
      case ReceiptPrinterConnectionTypeEnum.TCP:
        if (this.ipAddress) {
          return `${this.connectionType}:${this.ipAddress}`;
        }
        break;
    }
    const macAddress = this.macAddress ? this.macAddress.toLowerCase() : this.macAddress;
    return `${this.connectionType}:${macAddress}`;
  }

  private getModelForIos(model: string) {
    const lastModelChar = model.slice(-1);
    if (lastModelChar === '-') {
      return model.slice(0, -1);
    }
    return model;
  }

  public setVirtualPrintersUuid() {
    this.virtualPrintersUuid = this.virtualPrinters.map((p) => p.uuid);
  }

  private transformVirtualPrinters(virtualPrinters: VirtualPrinter[]) {
    return virtualPrinters.map((p) => new VirtualPrinter(p));
  }

  private static getPrinterModelByDeviceName(deviceName: string, connectionType: ReceiptPrinterConnectionTypeEnum): string {
    const { model, modelBT } = ReceiptPrinter.getPrinterConfigurationByModel(deviceName);
    if (connectionType === ReceiptPrinterConnectionTypeEnum.BT) {
      return modelBT;
    }
    return model;
  }

  private getPrinterModel(): string {
    return ReceiptPrinter.getPrinterModelByDeviceName(this.printerNameForModelPick, this.connectionType);
  }

  public updateModel(newPrinter: ReceiptPrinter) {
    let currentModel = ReceiptPrinter.getPrinterModelByDeviceName(newPrinter.printerNameForModelPick, newPrinter.connectionType);
    this.printerModel = currentModel;
    this.printerNameForModelPick = currentModel;
  }

  public updateDeviceInfo(newPrinter: ReceiptPrinter): ReceiptPrinter {
    this.deviceName = newPrinter.deviceName;
    this.deviceType = newPrinter.deviceType;
    this.ipAddress = newPrinter.ipAddress;
    this.target = newPrinter.target;
    return new ReceiptPrinter(this);
  }

  public updatePrinterViewData(newPrinter: ReceiptPrinter) {
    this.printerName = newPrinter.printerName;
    this.isPosPrinter = newPrinter.isPosPrinter;
    this.isKitchenPrinter = newPrinter.isKitchenPrinter;
    this.printerViewName = newPrinter.printerViewName;
    this.virtualPrinters = newPrinter.virtualPrinters;
    this.virtualPrintersUuid = newPrinter.virtualPrinters?.map((virtual) => virtual.uuid);
    return new ReceiptPrinter(this);
  }

  public get rowsLength(): ReceiptWidthConfigurationInterface {
    return this.printerConfiguration.receiptLength;
  }

  public get printerConfiguration(): ReceiptPrinterConfigurationInterface {
    return ReceiptPrinter.getPrinterConfigurationByModel(this.printerModel);
  }

  public static getPrinterConfigurationByModel(model: string): ReceiptPrinterConfigurationInterface {
    const defaultPrinter = Object.assign({}, PRINTER_MODELS.DEFAULT);
    if (!model) return defaultPrinter;
    let printerModels: string[] = Object.keys(PRINTER_MODELS).filter((model) => model !== 'DEFAULT');
    const currentModel = model.toLocaleLowerCase();
    for (let i = 0; i < printerModels.length; i++) {
      const printerModelData = PRINTER_MODELS[printerModels[i]];
      if (
        currentModel.indexOf(printerModels[i].toLocaleLowerCase()) !== -1 ||
        currentModel.indexOf(printerModelData.model.toLocaleLowerCase()) !== -1 ||
        currentModel.indexOf(printerModelData.modelBT.toLocaleLowerCase()) !== -1
      ) {
        return {
          model: printerModelData.model,
          modelBT: printerModelData.modelBT,
          receiptLength: {
            smallTextRowWidth: printerModelData.receiptLength.smallTextRowWidth,
            bigTextRowWidth: printerModelData.receiptLength.bigTextRowWidth,
            kitchenSmallTextRowWidth: printerModelData.receiptLength.kitchenSmallTextRowWidth,
            kitchenBigTextRowWidth: printerModelData.receiptLength.kitchenBigTextRowWidth,
          },
          isScannerSupported: printerModelData.isScannerSupported || false,
          printerType: printerModelData.printerType,
        };
      }
    }
    return defaultPrinter;
  }

  public get isScannerSupport(): boolean {
    return this.printerConfiguration.isScannerSupported;
  }
}

export enum PrintingQueueItemStatus {
  waitForPrinting = 'waitForPrinting',
  printingInProgress = 'printingInProgress',
  printingDone = 'printingDone',
  printingFailed = 'printingFailed',
  printingCancel = 'printingCancel',
}

export class PrintingQueueItem {
  public uuid: string;
  public creationDate: string;
  public printer: ReceiptPrinter;
  public receipt: ReceiptBuilder | IMyPosReceiptItem[];
  public printingTriesCount: number;
  public resolve: Function;
  public reject: Function;
  public printingStatus: PrintingQueueItemStatus;
  public relatedEntityUuid: string;
  public relatedEntityName: string;

  constructor(
    uuid: string,
    creationDate: string,
    printer: ReceiptPrinter,
    receipt: ReceiptBuilder | IMyPosReceiptItem[],
    printingTriesCount: number,
    resolve: Function,
    reject: Function,
    printingStatus: PrintingQueueItemStatus,
    relatedEntityUuid: string,
    relatedEntityName: string
  ) {
    this.uuid = uuid;
    this.creationDate = creationDate || new Date().toISOString();
    this.printer = printer;
    this.receipt = receipt;
    this.printingTriesCount = printingTriesCount || 0;
    this.resolve = resolve;
    this.reject = reject;
    this.printingStatus = printingStatus;
    this.relatedEntityUuid = relatedEntityUuid;
    this.relatedEntityName = relatedEntityName;
  }
}
