/**
 * Created by maksymkunytsia on 11/8/16.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImageLoadWbService } from './image-load-wb.service';
import { Device } from '@ionic-native/device/ngx';

@Injectable()
export class ImageLoadService {
  public imageCache: any = {};

  constructor(public http: HttpClient, public device: Device, public ImageLoadWbService: ImageLoadWbService) {}

  public load(url: string): Promise<any> {
    if (this.imageCache[url]) {
      // image has been previously loaded, rely on browser cache
      return Promise.resolve(true);
    } else if (ImageLoadWbService.supported) {
      if (window.cordova && this.device.platform.toLowerCase() === 'ios' && this.device.version.toString().split('.')[0] === '9') {
        return this.loadViaHttp(url);
      } else {
        return this.loadViaWorker(url);
      }
    } else {
      return this.loadViaHttp(url);
    }
  }

  public loadViaWorker(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let id: any, completeHandler: any, msgFn: any, errorFn: any;

      completeHandler = (success: boolean, err?: any) => {
        this.ImageLoadWbService.terminate(id);

        if (success) {
          this.imageCache[url] = true;
          resolve(true);
        } else {
          reject(err);
        }
      };

      msgFn = (e: any) => {
        if (e && e.data !== 'ERROR') {
          completeHandler(true);
        } else {
          completeHandler(false, e);
        }
      };

      errorFn = (e: any) => {
        completeHandler(false, e);
      };

      let config: any = {
        method: 'GET',
        url: url,
      };

      id = this.ImageLoadWbService.load(config, msgFn, errorFn);
    });
  }

  public loadViaHttp(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then(() => {
          this.imageCache[url] = true;
          resolve(true);
        })
        .catch(() => reject(false));
    });
  }
}
