import { IDiscovery } from '../IDiscovery';
import { Subject, Subscription } from 'rxjs';
import { UniversalCustomerDisplayProvider } from '../UniversalCustomerDisplayProvider';
import { Device, SavedDeviceInfo, ConnectionInfo } from './Device';
import { Zeroconf, ZeroconfResult } from '@ionic-native/zeroconf';
import { CustomerDisplayStatus } from '../../ICustomerDisplayDevice';
import { Platform } from '@ionic/angular';
import { ILogger, ILoggerFactory } from '@spryrocks/logger';
import { ignorePromiseError } from '@pos-common/services/utils/promise.utils';

export class NetworkDiscovery extends IDiscovery<Device, ConnectionInfo> {
  private readonly logger: ILogger;
  private readonly discovery: { type: string; domain: string };

  public readonly discoveryId = 'NetworkDiscovery';

  private readonly devices_: Device[] = [];
  private readonly devicesSubject = new Subject<Device[]>();

  constructor(
    private readonly provider: UniversalCustomerDisplayProvider,
    private readonly platform: Platform,
    private readonly loggerFactory: ILoggerFactory
  ) {
    super();

    this.logger = loggerFactory.createLogger('NetworkDiscovery');

    this.discovery = {
      type: '_customerdisplay._tcp.',
      domain: 'local.',
    };

    this.platform.resume.subscribe(() => {
      this.logger.info('platform.resume event received');
      if (this.isDiscoveryStarted) {
        this.logger.info('restarting discovery...');
        setTimeout(() => {
          this.stopDiscoveryInternal()
            .then(() => this.startDiscoveryInternal())
            .catch(ignorePromiseError);
        }, 1000);
      }
    });
  }

  get devices() {
    return this.devicesSubject;
  }

  private isDiscoveryStarted: boolean;

  async startDiscovery() {
    this.logger.debug('Start network discovery');
    if (this.isDiscoveryStarted) {
      this.logger.warning('Network discovery already started');
      return;
    }
    this.isDiscoveryStarted = true;

    await this.startDiscoveryInternal();
    this.logger.info('Network discovery started');
  }

  private discoverySubscription?: Subscription;

  private async startDiscoveryInternal() {
    this.logger.info('Subscribe to Zeroconf', { discovery: this.discovery });
    this.discoverySubscription = Zeroconf.watch(this.discovery.type, this.discovery.domain).subscribe(
      (result) => {
        this.onZeroconfResult(result);
      },
      (error) => {
        this.onZeroconfError(error);
      }
    );
  }

  async stopDiscovery() {
    this.logger.debug('Stop network discovery');
    if (!this.isDiscoveryStarted) {
      this.logger.warning('Network discovery already stopped');
      return;
    }
    this.isDiscoveryStarted = false;

    await this.stopDiscoveryInternal();
    this.logger.info('Network discovery stopped');
  }

  private async stopDiscoveryInternal() {
    if (this.discoverySubscription) {
      this.discoverySubscription.unsubscribe();
      this.discoverySubscription = undefined;
    }

    this.logger.info('Unsubscribe from zeroconf');
    Zeroconf.unwatch(this.discovery.type, this.discovery.domain).then();
  }

  private updateDevices() {
    const devices = [...this.devices_];
    this.logger.debug('Device list updated', { devices });
    this.devicesSubject.next(devices);
  }

  private onZeroconfResult({ service, action }: ZeroconfResult) {
    this.logger.debug('Zeroconf result received', { service, action });
    if (action === 'resolved') {
      this.logger.info('Zeroconf service resolved', { service });

      const ipAddress: string | undefined = service.ipv4Addresses.length > 0 ? service.ipv4Addresses[0] : undefined;
      const host = ipAddress;
      const port = service.port;

      if (!host) {
        this.logger.error('Cannot receive host information');
        return;
      }

      const id = service.txtRecord['id'];
      const name = service.name;
      const connectionInfo: ConnectionInfo = { host, port };
      this.logger.info('Collected device information', { id, name, connectionInfo });

      const device = this.createDevice(id, name, 'disconnected', connectionInfo, true, undefined);
      this.logger.info('Device object created', { device });

      if (this.devices_.find((d) => d.equals(device))) {
        this.logger.info('Device already exists, skipping', { device });
        return;
      }

      this.logger.debug('Add device to list', { device });
      this.devices_.push(device);
      this.updateDevices();
    } else if (action === 'removed') {
      this.logger.info('Zeroconf service removed', { service });

      const index = this.devices_.findIndex((d) => d.id === service.name);
      if (index <= -1) {
        this.logger.info('Device now found, skipping', { device });
        return;
      }

      this.logger.debug('Remove device from list', { device });
      this.devices_.splice(index, 1);
      this.updateDevices();
    } else {
      this.logger.info(`Not supported zeroconf action: ${action}`, { service, action });
    }
  }

  private onZeroconfError(error: Error) {
    this.logger.error(error, 'Zeroconf error received, skipping');
  }

  getDeviceInfo(device: Device): SavedDeviceInfo {
    return {
      discoveryId: device.discoveryId,
      providerId: device.providerId,
      deviceId: device.id,
      deviceName: device.name,
      connectionInfo: device.connectionInfo,
      autoConnect: device.autoConnect,
      isOffline: device.status !== 'disconnected',
    };
  }

  private createDevice(
    id: string,
    name: string,
    status: CustomerDisplayStatus,
    connectionInfo: ConnectionInfo,
    discovered: boolean,
    autoConnect: boolean | undefined
  ) {
    return new Device(
      this.loggerFactory,
      this.provider.providerId,
      this.discoveryId,
      id,
      name,
      status,
      connectionInfo,
      discovered,
      autoConnect
    );
  }

  restoreDeviceFromInfo(deviceInfo: SavedDeviceInfo) {
    return this.createDevice(
      deviceInfo.deviceId,
      deviceInfo.deviceName,
      deviceInfo.isOffline ? 'offline' : 'disconnected',
      deviceInfo.connectionInfo,
      false,
      deviceInfo.autoConnect
    );
  }
}
