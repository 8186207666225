import { ProductItem } from './product-item.class';

export class ProductItemGroup {
  uuid: string;
  productItemList: ProductItem[] = [];
  order: number;

  constructor(productList: ProductItem[], order: number) {
    this.productItemList = productList;
    this.order = order;
    this.uuid = this.productItemList.length ? this.productItemList[0].uuid : null;
  }
}
