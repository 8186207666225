import { ChangeDetectionStrategy, Component, ViewEncapsulation, ChangeDetectorRef, OnInit } from '@angular/core';
import { NavController, SelectCustomEvent, ToggleCustomEvent } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_INFO } from '../../../common/constants/app-info.const';
import { SERVER_CONFIG } from '../../../common/constants/server.const';
import { APP_LANGUAGES } from '../../../common/constants/app-languages.const';
import { PosInfoService } from '../../../common/services/system/pos-info.service';
import { LocalStorage } from '../../../common/services/utils/localstorage.utils';
import { LogService } from '../../../common/services/system/logger/log.service';
import { PosSettingsService } from '../../../common/services/system/pos-settings.service';
import { UpdatesService } from '../../../common/services/system/updates.service';
import { UPDATES_TYPES } from '../../../common/constants/updates-types.const';
import { UPDATES_SOURCES } from '../../../common/constants/updates-sources.const';
import * as moment from 'moment';
import { AlertService } from '../../../common/services/system/alert.service';
import { PaymentMethodsDbEntityProvider } from '../../../common/services/resources/payment-methods-db-entity.provider';
import { PaymentMethod } from '../../../common/classes/payment-method.class';
import { ROUTE_URLS } from '../../../common/constants/route-urls.const';
import { ProductSettingsInterface } from '../../../common/interfaces';
import { DefaultPaymentMethods } from '@pos-common/constants/default-payment-methods.enum';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { ModalService } from '@pos-common/services/system/modal.service';
import { TippingSearchModalPage } from '@pos-modules/modals/tipping-search-modal';
import { by, notEquals, oneOf } from '@paymash/capacitor-database-plugin';
import { takeUntil } from 'rxjs/operators';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';
import { DbDaoService, GetAllDataOptions } from '@pos-common/services/db/db-dao.service';
import { TranslateSystemService } from '@pos-common/services/system';

@Component({
  selector: 'general',
  styleUrls: ['general.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'general.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class General implements OnInit {
  public appInfo = APP_INFO;
  public serverConfig = SERVER_CONFIG;
  public languagesList: Array<any> = APP_LANGUAGES;
  public activeLanguage = null;
  public currentDeviceScore: any;
  public debugAleternateApiInput: string;
  public invoicesKeepDuration: number;
  public invoicesKeepDurationOptions: number[] = [];
  public oneClickPaymentStatus: boolean = false;
  public defaultPaymentMethod: PaymentMethod;
  public paymentMethodsList: PaymentMethod[] = [];
  public tippingStatus: boolean = false;
  public defaultTipProduct: ProductSettingsInterface;
  public productSettingsList: ProductSettingsInterface[] = [];
  public multipleGuestsStatus = false;
  public onInvocePaymentStatus = true;

  constructor(
    public translateService: TranslateService,
    private localStorage: LocalStorage,
    private posInfoService: PosInfoService,
    private navController: NavController,
    private posSettingsService: PosSettingsService,
    private updatesService: UpdatesService,
    private alertService: AlertService,
    private translate: TranslateService,
    private logService: LogService,
    private paymentMethodsProvider: PaymentMethodsDbEntityProvider,
    private dbDaoService: DbDaoService,
    private modalService: ModalService,
    private subSinkService: SubSinkService,
    private translateSystemService: TranslateSystemService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setInitValues();
    this.currentDeviceScore = this.posInfoService.getDevicePerformanceStatistic();
    this.activeLanguage = this.languagesList.find((x) => x.code === this.translateService.currentLang);
    this.debugAleternateApiInput = this.serverConfig.SERVER_URL;
    this.setPaymentMethodList();
  }

  private setInitValues() {
    this.invoicesKeepDurationOptions = this.posSettingsService.getInvoicesKeepDurationOptions();
    this.invoicesKeepDuration = this.posSettingsService.getInvoicesKeepDuration();
    this.oneClickPaymentStatus = this.posSettingsService.getOneClickPaymentStatus();
    this.defaultPaymentMethod = this.posSettingsService.getDefaultPaymentMethod();
    this.tippingStatus = this.posSettingsService.getTippingStatus();
    this.defaultTipProduct = this.posSettingsService.getDefaultTipProduct();
    this.multipleGuestsStatus = this.posSettingsService.getMultipleGuestsStatus();
    this.onInvocePaymentStatus = this.posSettingsService.getOnInvocePaymentStatus();
  }

  private setPaymentMethodList() {
    const queryParams = { visiblePOS: true, method: notEquals(DefaultPaymentMethods.GIFTCARD) };
    this.paymentMethodsProvider
      .getListByParams(queryParams)
      .pipe(takeUntil(this.subSinkService.destroy$))
      .subscribe((paymentMethods) => {
        this.paymentMethodsList = paymentMethods;
        this.cdr.detectChanges();
      });
  }

  trackByFn(index: number, item: any) {
    return item && item.uuid ? item.uuid : index;
  }

  public showWeakDevicesPage(): void {
    this.navController.navigateForward(ROUTE_URLS.weakDevice).catch((err) => this.logService.error('General', '', err));
  }

  public updateSelectedValue(event: Event) {
    const newValue = (<SelectCustomEvent>event).target.value;
    const newLanguage = JSON.parse(newValue);
    if (this.activeLanguage.id === newLanguage.id) {
      return false;
    }
    this.translateSystemService.setLanguage(newLanguage.code);
    this.activeLanguage = newLanguage;
  }

  public debugAleternateApiInputChanged(value) {
    this.localStorage.set('debugAleternateApiInput', value);
  }

  public async updateInvoicesKeepDuration(event: Event) {
    try {
      const newValue = (<SelectCustomEvent>event).target.value;
      const confirm = await this.alertService.create({
        header: this.translate.instant('settings_change_keep_invoices_duration_title'),
        message: `${this.translate.instant('settings_change_keep_invoices_duration_text')} ${newValue}
        ${this.translate.instant('common_days')}`,
        buttons: [
          {
            text: this.translate.instant('common_cancel'),
            handler: () => {
              this.invoicesKeepDuration = this.posSettingsService.getInvoicesKeepDuration();
              this.logService.debug(
                'General',
                `updateInvoicesKeepDuration:common_cancel:this.invoicesKeepDuration - ${this.invoicesKeepDuration}`
              );
            },
          },
          {
            text: this.translate.instant('common_accept'),
            handler: () => {
              this.invoicesKeepDuration = parseInt(newValue);
              this.posSettingsService.setInvoicesKeepDuration(this.invoicesKeepDuration);
              const fromDate = moment.utc().subtract(newValue, 'day').startOf('day').toISOString();
              this.updatesService.getUpdates([UPDATES_TYPES.Invoice], UPDATES_SOURCES.UPDATE_ON_DEMAND, fromDate);
              this.logService.debug('General', `updateInvoicesKeepDuration - ${newValue}`);
              this.posSettingsService.setInitialPaidInvoicesStatus(false);
              this.cdr.markForCheck();
            },
          },
        ],
      });
      await confirm.present();
    } catch (error) {
      this.logService.error('General', 'updateInvoicesKeepDuration:confirm.present', error);
    }
  }

  public oneClickPaymentStatusChanged(event: Event) {
    const newValue = (<ToggleCustomEvent>event).detail.checked;
    this.oneClickPaymentStatus = newValue;
    this.posSettingsService.setOneClickPaymentStatus(newValue);
    if (newValue) {
      if (!this.defaultPaymentMethod.method && this.paymentMethodsList.length > 0) {
        this.defaultPaymentMethodChanged(JSON.stringify(this.paymentMethodsList[0]));
      }
    }
  }

  public defaultPaymentMethodChanged(event) {
    this.defaultPaymentMethod = new PaymentMethod(JSON.parse(event));
    this.posSettingsService.setDefaultPaymentMethod(this.defaultPaymentMethod);
  }

  public async tippingStatusChanged(event: Event) {
    const tippingStatus = (<ToggleCustomEvent>event).detail.checked;
    this.tippingStatus = tippingStatus;
    this.posSettingsService.setTippingStatus(tippingStatus);
    if (tippingStatus) {
      if (!this.defaultTipProduct) {
        await this.setDefaultTipProduct();
      }
      this.openTippingSearchModal();
    }
  }

  private setDefaultTipProduct() {
    const queryParams = { deleted: false, visible: true, isGiftCard: false, type: oneOf(PRODUCT_TYPES.PRODUCT) };
    const options: GetAllDataOptions = { offset: 0, limit: 1, order: [by('fieldToSort')] };
    return this.dbDaoService.getAllData(UPDATES_TYPES.Product.type, queryParams, options).then((response) => {
      if (response.data.length) {
        const { name, uuid } = response.data[0];
        this.defaultTipProductChanged({ name, uuid });
      }
    });
  }

  public defaultTipProductChanged(tipProduct: ProductSettingsInterface) {
    this.defaultTipProduct = tipProduct;
    this.posSettingsService.setDefaultTipProduct(this.defaultTipProduct);
    this.cdr.detectChanges();
  }

  public multipleGuestsStatusChanged(event: Event) {
    const newValue = (<ToggleCustomEvent>event).detail.checked;
    this.multipleGuestsStatus = newValue;
    this.posSettingsService.setMultipleGuestsStatus(newValue);
  }

  public onInvocePaymentStatusChange(event: Event) {
    const status = (<ToggleCustomEvent>event).detail.checked;
    this.onInvocePaymentStatus = status;
    this.posSettingsService.setOnInvocePaymentStatus(status);
  }

  public async openTippingSearchModal() {
    const modal = await this.modalService.presentModal(TippingSearchModalPage, { defaultTipProduct: this.defaultTipProduct });
    modal.onDidDismiss().then(({ data }) => data && this.defaultTipProductChanged(data));
    await modal.present();
  }
}
