import { Injectable } from '@angular/core';

@Injectable()
export class SearchUtils {
  replaceForSearch(value: string): string {
    if (!value) {
      return value;
    }
    return value
      .toLowerCase()
      .replace(/à|á|â|ã|ä|å|æ/g, 'a')
      .replace(/ç/g, 'c')
      .replace(/è|é|ê|ë/g, 'e')
      .replace(/ì|í|î|ï/g, 'i')
      .replace(/ð/g, 'd')
      .replace(/ñ/g, 'n')
      .replace(/ò|ó|ô|õ|ö|ø/g, 'o')
      .replace(/ù|ú|û|ü/g, 'u')
      .replace(/ý|þ|ÿ/g, 'y')
      .replace(/Œ|œ/g, 'o')
      .replace(/Š|š/g, 's')
      .replace(/ƒ/g, 'f')
      .replace(/ž/, 'z')
      .trim();
  }
}
