import { SYNC_ITEM_STATES } from '../constants/sync-statuses.const';
import { EntityInterface } from '../interfaces/entity.interface';
import { SyncRetryInterface } from '../interfaces/sync-retry.interface';
import { PAYMASH_PROFILE } from '@profile';

/**
 * Created by y.belinsky on 2/20/18.
 */

export class Entity implements EntityInterface, SyncRetryInterface {
  public uuid: string;
  public localCreationDate: string;
  public localModificationDate: string;
  private _syncState: string;
  public retryCount: number;
  public localLastSyncDate: string;

  constructor(data) {
    this.uuid = data.uuid || null;
    this.localCreationDate = data.localCreationDate || null;
    this.localModificationDate = data.localModificationDate || null;
    this._syncState = data._syncState === null || data._syncState === undefined ? SYNC_ITEM_STATES.SYNCHRONIZED : data._syncState;
    this.retryCount = data.retryCount || 0;
    this.localLastSyncDate = data.localLastSyncDate || null;
  }

  get syncState(): string {
    return this._syncState;
  }

  set syncState(newSyncState: string) {
    if (
      !(
        (this._syncState === SYNC_ITEM_STATES.NEED_SYNCHRONIZATION || this._syncState === SYNC_ITEM_STATES.REMOVED_NEED_SYNCHRONIZATION) &&
        newSyncState === SYNC_ITEM_STATES.SYNCHRONIZED
      )
    ) {
      this._syncState = newSyncState;
    }
  }

  increaseLocalModificationDate(): void {
    this.localModificationDate = new Date().toISOString();
  }

  setLocalCreationDate(): void {
    let dateForSet: string = new Date().toISOString();
    if (!this.localCreationDate) this.localCreationDate = dateForSet;
    this.localModificationDate = dateForSet;
  }

  increaseRetryCounter(): void {
    if (this.retryCount < PAYMASH_PROFILE.retrySyncItemIntervalMaxValue) {
      this.retryCount++;
    }
  }

  resetRetryCounter(): void {
    this.retryCount = 0;
  }

  setLocalLastSyncDate(): void {
    this.localLastSyncDate = new Date().toISOString();
  }
}
