import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { InvoceInlist } from '@pos-common/classes/invoice-in-list.class';
import { CANCELATION_STATUS_TYPES } from '@pos-common/constants';
import { INVOICE_TYPES } from '@pos-common/constants/invoice-types';

@Pipe({
  name: 'invoiceStatus',
})
export class InvoiceStatusPipe implements PipeTransform {
  transform(invoice: InvoceInlist): string {
    if (!invoice) {
      return;
    }

    if (invoice.cancellationStatus === CANCELATION_STATUS_TYPES.CANCELLED) {
      if (invoice.invoiceType === INVOICE_TYPES.CANCELLATION) {
        return 'common_cancellation';
      }
      return 'common_cancelled';
    }
    if (invoice.isPaid) {
      return 'common_paid';
    }
    if (invoice.isPartiallyPaid) {
      return 'common_partially_paid';
    }
    return 'common_unpaid';
  }
}

@NgModule({
  declarations: [InvoiceStatusPipe],
  exports: [InvoiceStatusPipe],
})
export class InvoiceStatusPipeModule {}
