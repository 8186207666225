<div class="invoice-entry-guest" (swipe)="removeGuest($event)">
  <guest-button
    [isSelected]="invoiceEntryGuest.isSelected"
    [marker]="invoiceEntryGuest.guestNumber"
    [name]="invoiceEntryGuest.name"
    [isChangeable]="isChangeableGuestButton"
    (onClick)="selectGuest()"
    (onEdit)="editGuest()"
  ></guest-button>
  <printer-link
    *ngIf="isGastroMode"
    posMode="kitchen"
    [invoice]="activeInvoice"
    [onlyIcon]="true"
    [isArrowRight]="true"
    [showUnprintedKitchenItems]="showUnprintedKitchenItems"
    [showGuestOnReceipt]="true"
    [guestNumber]="invoiceEntryGuest.guestNumber"
  ></printer-link>
</div>
