import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { mdTransitionAnimation, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentInCalendar } from '@pos-common/classes/appointment/appointment-in-calendar.class';
import { FilterGroup } from '@pos-common/classes/calendar/filter-group.class';
import { APPOINTMENT_EVENT_TYPE, CALENDAR_DATE_RANGE, CALENDAR_TIME_RANGE, CALENDAR_VIEWS, LANGUAGES } from '@pos-common/constants';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';
import { IBookableHours, IBookingSettings, ICalendarDateRange } from '@pos-common/interfaces';
import { AlertService } from '@pos-common/services/system/alert.service';
import { CalendarService } from '@pos-common/services/system/calendar/calendar.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { CalendarComponent } from '@paymash/cdk-paymash/dist/components';
import * as moment from 'moment';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { LOCALE } from '@pos-common/constants/locale.const';
import { LocalizationUtils } from '@pos-common/services/utils/localization.utils';

@Component({
  selector: 'pos-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class CalendarViewComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(CalendarComponent, { static: true }) fullCalendar: CalendarComponent;
  @Input() timeRange: CALENDAR_TIME_RANGE;
  @Output() posSelectDay = new EventEmitter<void>();
  public typeView: string;
  public inProcess = true;
  private currentDate: string;
  private view = CALENDAR_VIEWS.CALENDAR;
  private shouldUpdateEvents = false;
  private bookingSettings: IBookingSettings = null;

  constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
    private navController: NavController,
    private calendarService: CalendarService,
    private localizationUtils: LocalizationUtils,
    private cdr: ChangeDetectorRef,
    private subSinkService: SubSinkService,
    private setTimeoutUtil: SetTimeoutUtil,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.subSinkService.sink = this.calendarService.getEvents(this.view).subscribe(
      (resp) => {
        const { appointments, employees, bookableHours } = resp;
        const appointmentList = appointments
          .map((appointment) => new AppointmentInCalendar(appointment))
          .filter((appointment) => appointment.eventType === APPOINTMENT_EVENT_TYPE.APPOINTMENT);
        this.fullCalendar.updateAppointments(appointmentList);
        this.fullCalendar.updateEmployees(employees);
        this.inProcess = false;
        this.updateSlotTime(bookableHours);
        this.cdr.detectChanges();
      },
      () => {
        this.inProcess = false;
        this.cdr.detectChanges();
      }
    );

    this.subSinkService.sink = this.calendarService
      .getRenderCalendar()
      .pipe(switchMap(() => from(this.setTimeoutUtil.waitTimeAndDo(100))))
      .subscribe(() => {
        this.fullCalendar?.render();
        this.fullCalendar?.updateHeight('100%');
      });

    this.subSinkService.sink = this.calendarService.getDateRange().subscribe((dateRange) => {
      this.shouldUpdateEvents = true;
      if (dateRange === CALENDAR_DATE_RANGE.NEXT) {
        return this.fullCalendar.next();
      }
      if (dateRange === CALENDAR_DATE_RANGE.TODAY) {
        const isTodaySelected = moment(this.currentDate).isSame(moment(), 'day');
        if (!isTodaySelected) {
          this.fullCalendar.today();
        }
        return;
      }
      this.fullCalendar.prev();
    });

    this.subSinkService.sink = this.calendarService.getCurrentDate().subscribe((currentDate) => {
      this.currentDate = currentDate.start;
    });

    this.subSinkService.sink = this.calendarService.getUpdateStatus().subscribe(() => {
      this.loadEvents();
    });

    this.inProcess = true;
    this.calendarService.loadBokingSettings().subscribe((bookingSettings) => {
      this.bookingSettings = bookingSettings;
      this.updateCalendarAccuracy();
      this.initLoadEvents();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const timeRangeChanges = changes.timeRange;
    if (timeRangeChanges && !timeRangeChanges.isFirstChange()) {
      this.changeView();
      this.cdr.detectChanges();
      this.loadEvents();
    }
  }

  ngAfterViewInit(): void {
    this.changeView();
    this.setLocale();
    this.fixDeLocale();
  }

  ngOnDestroy(): void {
    this.calendarService.setCurrentDate({ start: moment().toISOString(), end: undefined });
  }

  getDate(value: Date) {
    const date = value.toString();
    const start = this.calendarService.getCalendarInterval(date, this.timeRange);
    const dateRange: ICalendarDateRange = { start };
    if (this.timeRange !== CALENDAR_TIME_RANGE.day) {
      dateRange.end = this.calendarService.getCalendarInterval(date, this.timeRange, true);
    }
    this.calendarService.setCurrentDate(dateRange);
    if (this.shouldUpdateEvents) {
      this.shouldUpdateEvents = false;
      this.loadEvents();
    }
  }

  openAppointment(appointmentInCalendar: AppointmentInCalendar) {
    this.navController
      .navigateForward(ROUTE_URLS.appointment, { state: { appointmentUuid: appointmentInCalendar.uuid }, animation: mdTransitionAnimation })
      .catch((error) => this.logService.error('CalendarViewComponent', 'openAppointment', error));
  }

  async createAppointmentPopup(startedAt: string, activeEmployeeUuid: string) {
    try {
      const date = this.localizationUtils.getFormattedDate(startedAt, 'dateFormat');
      const alert = await this.alertService.create({
        header: this.translateService.instant('calendar_create_appointment'),
        subHeader: this.translateService.instant('calendar_create_appointment_started_at', { date }),
        buttons: [
          { role: 'cancel', text: this.translateService.instant('common_cancel') },
          {
            text: this.translateService.instant('calendar_create_appointment'),
            handler: () => {
              this.createNewAppointment(startedAt, activeEmployeeUuid);
            },
          },
        ],
      });
      await alert.present();
    } catch (error) {
      this.logService.error('CalendarViewComponent', 'createAppointmentPopup', error);
    }
  }

  handleSelectDay() {
    this.posSelectDay.emit();
  }

  private initLoadEvents() {
    const filterForm = this.calendarService.getFilterFormValue(this.view);
    if (filterForm.interval) {
      this.loadEvents();
      return;
    }
    this.calendarService.loadEvents(this.view);
  }

  private loadEvents() {
    this.inProcess = true;
    this.cdr.detectChanges();
    const filterForm = this.calendarService.getFilterFormValue(this.view);
    filterForm.filterParams = { typeRange: this.timeRange };
    filterForm.interval = this.getInterval(filterForm.interval);
    this.calendarService.setFilterForm(this.view, filterForm);
    this.calendarService.loadEvents(this.view);
  }

  private getInterval(interval: FilterGroup) {
    const [fromFilter, toFilter] = interval.items;
    const date = this.calendarService.getDateFormat(this.currentDate);
    fromFilter.value = this.calendarService.getCalendarInterval(date, this.timeRange);
    fromFilter.isSelected = true;
    toFilter.value = this.calendarService.getCalendarInterval(date, this.timeRange, true);
    toFilter.isSelected = true;
    interval.items = [fromFilter, toFilter];
    return interval;
  }

  private changeView() {
    this.typeView = this.getTypeView();
    this.fullCalendar.changeView(this.typeView);
    this.fullCalendar.scrollToTime();
  }

  private getTypeView() {
    switch (this.timeRange) {
      case CALENDAR_TIME_RANGE.week:
        return 'timeGridWeek';
      case CALENDAR_TIME_RANGE.month:
        return 'dayGridMonth';
      default:
        return 'resourceTimeGridDay';
    }
  }

  private setLocale() {
    const locale = this.translateService.currentLang;
    this.fullCalendar.updateLocale(locale);
  }

  private fixDeLocale() {
    const de = this.fullCalendar.calendarOptions.locales.find((value) => value.code === LANGUAGES.de);
    this.fullCalendar.calendarOptions.locales = [
      ...this.fullCalendar.calendarOptions.locales,
      {
        ...de,
        moreLinkText: function (n) {
          return '+ noch ' + n;
        },
      },
    ];
  }

  private createNewAppointment(start: string, activeEmployeeUuid: string) {
    const startedAt = this.calendarService.getDateAt(start);
    this.navController
      .navigateForward(ROUTE_URLS.appointment, { state: { startedAt, activeEmployeeUuid }, animation: mdTransitionAnimation })
      .catch((error) => this.logService.error('CalendarViewComponent', 'createNewAppointment', error));
  }

  private updateSlotTime(bookableHours: IBookableHours) {
    if (!this.bookingSettings?.showOnlyBookableHours || !bookableHours) {
      return;
    }
    const slotTime = this.calendarService.getBookableHours(bookableHours);
    this.fullCalendar.updateSlotTime(slotTime);
  }

  private updateCalendarAccuracy() {
    if (!this.bookingSettings || !this.fullCalendar) {
      return;
    }
    const calendarAccuracy = moment(this.bookingSettings.calendarAccuracy.toString(), 'mm').format(LOCALE.TimeFormat.H24);
    this.fullCalendar.updateCalendarAccuracy(calendarAccuracy);
  }
}
