<ion-header>
  <ion-toolbar mode="md">
    <ion-buttons slot="start" class="header-start">
      <ng-content select="[header-start]"></ng-content>
    </ion-buttons>
    <div class="header-title">
      <ng-content select="[header-title]"></ng-content>
    </div>
    <ion-buttons slot="end" class="header-end">
      <ng-content select="[header-end]"></ng-content>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
