<ion-item-group>
  <div class="label-caption">{{ 'settings_app_printer_link' | translate }}</div>
  <div class="list-group">
    <ion-item class="list-item">
      <ion-label>{{ 'settings_app_print_receipt_right_after_pay' | translate }}</ion-label>
      <ion-toggle
        [checked]="printersSettings.printReceiptAutomatically"
        (ionChange)="printersSettingsChanged(printersSettingsType.AutoPrintReceipt, $event)"
      ></ion-toggle>
    </ion-item>
    <ion-item class="list-item">
      <ion-label>{{ 'settings_app_open_cash_register_right_after_pay' | translate }}</ion-label>
      <ion-toggle
        [checked]="printersSettings.openCashRegisterAutomatically"
        (ionChange)="printersSettingsChanged(printersSettingsType.CashRegisterAutoOpen, $event)"
      ></ion-toggle>
    </ion-item>
    <ion-item class="list-item">
      <ion-label>{{ 'settings_app_print_kitchen_receipt_right_after_pay' | translate }}</ion-label>
      <ion-toggle
        [checked]="printersSettings.printKitchenReceiptAutomatically"
        (ionChange)="printersSettingsChanged(printersSettingsType.AutoPrintKitchenReceipt, $event)"
      ></ion-toggle>
    </ion-item>
    <ion-item class="list-item" *ngIf="isRestaurantEnabled">
      <ion-label>{{ 'settings_app_show_unprinted_kitchen_items' | translate }}</ion-label>
      <ion-toggle
        [checked]="printersSettings.showUnprintedKitchenItems"
        (ionChange)="printersSettingsChanged(printersSettingsType.ShowUnprintedKitchenItems, $event)"
      ></ion-toggle>
    </ion-item>
  </div>
  <div class="label-caption">{{ 'printing_queue_page_title' | translate }}</div>
  <div class="list-group">
    <ion-item class="list-item" (click)="openPrinterQueuePage()">
      <ion-label>{{ 'settings_receipt_printers_queue_items_in_queue' | translate }}</ion-label>
    </ion-item>
  </div>
  <div class="label-caption" *ngIf="listOfSavedPrinter.length > 0">{{ 'receipt_printer_saved_printers' | translate }}</div>
  <div class="list-group" *ngIf="listOfSavedPrinter.length > 0">
    <ion-grid class="list-table">
      <ion-row class="list-table-header">
        <ion-col size-md="4" size-sm="10">{{ 'printer_name_and_model' | translate }}</ion-col>
        <ion-col size="2" offset="1" class="hide-mobile">{{ 'receipt_printer_receipt_toggle' | translate }}</ion-col>
        <ion-col size="3" class="hide-mobile"
          ><span *ngIf="isRestaurantEnabled">{{ 'receipt_printer_kitchen' | translate }}</span></ion-col
        >
        <ion-col size="2"></ion-col>
      </ion-row>
      <ion-row *ngFor="let printer of listOfSavedPrinter; trackByUuid">
        <ion-col size-md="4" size-sm="10" (click)="goDetails(printer)">
          <span class="status-circle" [class.offline]="!printer.isActive"></span>
          <div>{{ printer.printerViewName }}</div>
          <span class="subname">{{ printer.deviceName }}</span>
        </ion-col>
        <ion-col size="1" class="connection-type">
          <ion-icon
            [class.connection-type-bluetooth]="printer.connectionType === connectionType.BT"
            [name]="printer.connectionType === connectionType.BT ? 'bluetooth' : 'wifi'"
          ></ion-icon>
        </ion-col>
        <ion-col size="2" class="hide-mobile" (click)="goDetails(printer)">
          <span class="status" [class.offline]="!printer.isPosPrinter">{{
            (printer.isPosPrinter ? 'common_yes' : 'common_no') | translate
          }}</span>
        </ion-col>
        <ion-col size="3" class="hide-mobile" (click)="goDetails(printer)">
          <span class="status" [class.offline]="!printer.isKitchenPrinter"
            ><span *ngIf="isRestaurantEnabled">{{ (printer.isKitchenPrinter ? 'common_yes' : 'common_no') | translate }}</span></span
          >
        </ion-col>
        <ion-col size="2">
          <div class="is-btn" (click)="testPrint(printer)">
            <i class="custom-icon bill-icon"></i>
            {{ 'receipt_printer_test_printer' | translate }}
          </div>
        </ion-col>
        <!-- <ion-col col-1 (click)="showDeleteConfirmation(printer)">
            <i class="custom-icon custom-icon-paid-invoices-trash"></i>
        </ion-col> -->
      </ion-row>
    </ion-grid>
  </div>
  <ion-item class="founded-printers-title ion-text-uppercase" lines="none">
    <div>{{ 'receipt_printer_founded_printers' | translate }}</div>
    <div slot="end" class="bluetooth-button">
      <span (click)="openBluetoothSettings()" tappable>{{ 'common_bluetooth_settings' | translate }}</span>
      <pos-icon
        *ngIf="showInfoIcon"
        customName="information-circle-outline-icon"
        (click)="showBackgroundLocationAlert()"
        tappable
      ></pos-icon>
    </div>
  </ion-item>
  <div class="no-items" *ngIf="listPrinter.length === 0">{{ 'searching_of_printers' | translate }}...</div>
  <div class="list-group" *ngIf="listPrinter.length > 0">
    <ion-grid class="list-table">
      <ion-row *ngFor="let printer of listPrinter; trackByUuid">
        <ion-col size="7">
          <span class="status-circle" [class.offline]="!printer.isActive"></span>
          <div>{{ printer.printerViewName }}</div>
          <span class="subname">{{ printer.deviceName }}</span>
        </ion-col>
        <ion-col size="1" class="connection-type">
          <ion-icon
            [class.connection-type-bluetooth]="printer.connectionType === connectionType.BT"
            [name]="printer.connectionType === connectionType.BT ? 'bluetooth' : 'wifi'"
          ></ion-icon>
        </ion-col>
        <ion-col size="2">
          <div class="is-btn" (click)="testPrint(printer)">
            <i class="custom-icon bill-icon"></i>
            {{ 'receipt_printer_test_printer' | translate }}
          </div>
        </ion-col>
        <ion-col size="2">
          <div class="is-btn" (click)="addToSaved(printer)">
            <i class="custom-icon custom-icon-add-custom-product"></i>
            {{ 'common_add' | translate }}
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="add-manually">
    <button class="add-manually-button" (click)="addPrinterManually()">{{ 'printers_manual_connection' | translate }}</button>
  </div>
</ion-item-group>
