import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarFilterModalPage } from './page/calendar-filter-modal.page';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponentModule, CalendarFilterModule, HeaderComponentModule, IconComponentModule } from '@pos-modules/shared/components';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    IconComponentModule,
    HeaderComponentModule,
    ButtonComponentModule,
    CalendarFilterModule,
  ],
  declarations: [CalendarFilterModalPage],
})
export class CalendarFilterModalModule {}
