/**
 * Created by andrey.p on 1/12/17.
 */

import { Component, OnInit, OnDestroy, ViewEncapsulation, NgModule } from '@angular/core';

import { NotifyPopupsService } from '../../../common/services/system/notify-popups.service';
import { scaleElm } from '../../../app/app.animations';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'notify-popups',
  templateUrl: './notify-popups.component.html',
  styleUrls: ['./notify-popups.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [scaleElm()],
})
export class NotifyPopups implements OnInit, OnDestroy {
  public showNotify: boolean = false;
  public notifier: boolean = false;
  public notifyType: string = '';
  public payload: any = null;

  private animationEvents: Subscription;

  constructor(private NotifyPopupsService: NotifyPopupsService) {}

  ngOnDestroy() {
    this.NotifyPopupsService.launchNotification(null);
    this.animationEvents && this.animationEvents.unsubscribe();
  }

  ngOnInit() {
    this.animationEvents = this.NotifyPopupsService.notificationEvents().subscribe((data) => {
      if (data && data.event) {
        this.showNotify = true;
        this.notifier = true;
        this.notifyType = data.event;
        this.payload = data.payload;
        setTimeout(() => {
          this.notifier = false;
          this.NotifyPopupsService.launchNotification(null);
        }, 1800);
        setTimeout(() => (this.showNotify = false), 2000);
      }
    });
  }

  hideNotify() {
    this.notifier = false;
    setTimeout(() => {
      this.NotifyPopupsService.launchNotification(null);
      this.showNotify = false;
    }, 200);
  }
}
@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  declarations: [NotifyPopups],
  exports: [NotifyPopups],
})
export class NotifyPopupsModule {}
