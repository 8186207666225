<div
  class="cart-wrapper"
  [id]="activeInvoice?.uuid"
  [ngClass]="{ cartNotEmpty: activeInvoice?.totalProductsCount || isMultipleGuests }"
  [class.multiple-guest]="isMultipleGuests"
>
  <div class="cart-entries">
    <ul>
      <cart-list
        [activeInvoice]="activeInvoice"
        [invoiceEntries]="invoiceEntries"
        [showUnprintedKitchenItems]="showUnprintedKitchenItems"
        [isMultipleGuests]="isMultipleGuests"
        [isGastroMode]="isGastroMode"
        (onClickInvoiceEntry)="invoiceEntryClick($event)"
        (onSwipeInvoiceEntry)="invoiceEntrySwipe($event)"
        (onAddNewGuest)="addNewGuest()"
        (onEditGuest)="openGuestDetailsModal($event)"
      ></cart-list>
      <li class="discount-item" *ngIf="activeInvoice && (activeInvoice.customerDiscount || activeInvoice.customerDiscountPercentage)">
        <cart-discount-entry
          name="customer_discount"
          [discount]="activeInvoice.customerDiscount"
          [discountPercentage]="activeInvoice.customerDiscountPercentage"
          [totalInvoiceDiscountAmount]="activeInvoice.totalCustomerDiscountAmount"
          (onClick)="discountTap('customer')"
          (onSwipe)="discountSwipe($event, 'customer')"
        >
        </cart-discount-entry>
      </li>
      <li class="discount-item" *ngIf="activeInvoice && (activeInvoice.discount || activeInvoice.discountPercentage)">
        <cart-discount-entry
          name="invoice_discount"
          [discount]="activeInvoice.discount"
          [discountPercentage]="activeInvoice.discountPercentage"
          [totalInvoiceDiscountAmount]="activeInvoice.totalInvoiceDiscountAmount"
          (onClick)="discountTap('invoice')"
          (onSwipe)="discountSwipe($event, 'invoice')"
        >
        </cart-discount-entry>
      </li>
    </ul>
  </div>
  <cart-buttons [activeInvoice]="activeInvoice"></cart-buttons>
</div>
