import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdyenPaymentApi } from '@pos-common/services/system/adyen/services/api';
import { AdyenUtils } from '@pos-common/services/system/adyen/adyen-utils';

type ScanResult = { code: string };

@Injectable()
export class AdyenScannerService {
  constructor(private readonly paymentApi: AdyenPaymentApi, private readonly utils: AdyenUtils) {}

  startScanner(): Observable<ScanResult> {
    return new Observable<ScanResult>((subscriber) => {
      let cancelled = false;
      let sessionId: number | undefined;
      const timeoutMs = 60 * 1000;

      const startSession = async () => {
        while (!cancelled) {
          sessionId = new Date().getTime();
          try {
            const result = await this.paymentApi.startScanner({
              ...this.utils.createCommonRequestOptions(),
              sessionId,
              timeoutMs,
            });
            subscriber.next({ code: result.data });
          } catch (e) {
            await new Promise<void>((resolve) => setTimeout(() => resolve(), 5000));
          }
        }
      };

      const cancelSession = async () => {
        if (cancelled || !sessionId) return;
        cancelled = true;
        this.paymentApi
          .cancelScanSession({
            ...this.utils.createCommonRequestOptions(),
            sessionId,
          })
          .then();
        subscriber.complete();
      };

      startSession().then();

      return () => cancelSession();
    });
  }
}
