import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { LocalizationUtils } from '../../services/utils/localization.utils';

@Pipe({ name: 'CustomCurrency' })
export class CustomCurrency implements PipeTransform {
  constructor(private LocalizationUtils: LocalizationUtils) {}

  transform(value: any, showCurrency: any, currencyPosition?: any) {
    return this.LocalizationUtils.getFormattedCurrency(value, showCurrency, currencyPosition);
  }
}
@NgModule({
  declarations: [CustomCurrency],
  exports: [CustomCurrency],
})
export class CustomCurrencyPipeModule {}
