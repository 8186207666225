export enum RequestHeaderTypes {
  Authorization = 'Authorization',
  PosId = 'X-Paymash-PosId',
  RequestId = 'X-Paymash-RequestId',
  AppVersion = 'X-Paymash-AppVersion',
  AppPlatform = 'X-Paymash-AppPlatform',
  CompanyId = 'X-Paymash-CompanyId',
  Target = 'X-Paymash-Target',
  RetryAfter = 'Retry-After',
  DeviceFingerprint = 'X-Paymash-DeviceFingerprint',
}
