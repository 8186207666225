<ion-list class="product-search-list ion-no-padding">
  <ion-item
    class="product-search"
    *ngFor="let item of productItems; trackByUuid"
    (press)="productLongClick(item)"
    (click)="productClick(item, $event.target)"
    [class.active]="item.uuid === activeProductItem?.uuid"
  >
    <div class="product-search-image item-image">
      <span class="badge badge-new" *ngIf="item.data.isNew">{{ 'common_new' | translate }}</span>
      <span class="badge badge-sale" *ngIf="!item.data.isNew && item.data.isSale">{{ 'common_sale' | translate }}</span>
      <div class="product-search-image-holder" [ngStyle]="{ 'background-color': item.data.bgColor }">
        <pos-image-loader-cap *ngIf="item.data.images?.length" [url]="item.data.images[0].image | ImageUrl : IMAGE_SIZES.MEDIUM">
        </pos-image-loader-cap>
        <p class="product-search-image-placeholder text-ellipsis">{{ item.data.name }}</p>
      </div>
    </div>
    <div class="product-search-info">
      <span class="text-ellipsis">{{ item.name }}</span>
    </div>
  </ion-item>
  <div *ngIf="!productItems.length" class="no-items">{{ placeholderNoResult | translate }}</div>
</ion-list>
