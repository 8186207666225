/**
 * Created by maksymkunytsia on 6/29/16.
 */

import { CollectionViewPage } from '../../pages/collection-view/collection-view.page';
import { InvoicesPage } from '../../pages/invoices/page/invoices.page';
import { SettingsPage } from '../../pages/settings2/settings.page';
import { DayReportPage } from '../../pages/day-report/day-report.page';
import { ShiftsPage } from '../../pages/shifts/shifts.page';
import { ROUTE_URLS } from './route-urls.const';
import { CalendarPage } from '../../pages/calendar/page/calendar.page';

export const ROUTES_LIST: any = [
  {
    title: 'global_collection_view_title',
    icon: 'products',
    component: CollectionViewPage,
    hided: false,
    protectedRout: false,
    url: ROUTE_URLS.collection,
    isSelected: false,
  },
  {
    title: 'global_invoices_title',
    icon: 'invoices',
    component: InvoicesPage,
    hided: false,
    protectedRout: false,
    url: ROUTE_URLS.invoices,
    isSelected: false,
  },
  {
    title: 'global_day_report_title',
    icon: 'report',
    component: DayReportPage,
    hided: false,
    protectedRout: true,
    permission: 'hasReportPermission',
    url: ROUTE_URLS.report,
    isSelected: false,
  },
  {
    title: 'global_calendar_title',
    icon: 'report',
    component: CalendarPage,
    hided: false,
    protectedRout: false,
    checkCompanySetting: true,
    companySetting: 'isBookingEnabled',
    url: ROUTE_URLS.calendar,
    isSelected: false,
  },
  {
    title: 'global_admin_title',
    icon: 'admin',
    component: 'Admin',
    hided: false,
    protectedRout: true,
    permission: 'hasAdminPermission',
    url: ROUTE_URLS.report,
    isSelected: false,
  },
  {
    title: 'global_settings_title',
    icon: 'settings',
    component: SettingsPage,
    hided: false,
    protectedRout: true,
    permission: 'hasSettingsPermission',
    url: ROUTE_URLS.settings,
    isSelected: false,
  },
  {
    title: 'global_shifts_title',
    icon: 'shifts',
    component: ShiftsPage,
    hided: false,
    protectedRout: false,
    checkCompanySetting: true,
    companySetting: 'isCashRegisterEnabled',
    url: ROUTE_URLS.shifts,
    isSelected: false,
  },
  {
    title: 'global_help_title',
    icon: 'help',
    component: 'Help',
    hided: false,
    protectedRout: false,
    isSelected: false,
  },
  {
    title: 'global_refer_friend_title',
    icon: 'refer-friend',
    component: 'Refer',
    hided: false,
    protectedRout: true,
    permission: 'hasAdminPermission',
    isSelected: false,
  },
];
