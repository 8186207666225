/**
 * Created by y.belinsky on 1/16/17.
 */
export class License {
  public isActive: boolean;
  public isPosActivated: boolean;
  public numberOfConcurrentUsers: number;
  public isNumberOfConcurrentUsersExceeded: boolean;

  constructor(data) {
    this.isActive = data.isActive === undefined ? true : data.isActive;
    this.isPosActivated = data.isPosActivated === undefined ? true : data.isPosActivated;
    this.numberOfConcurrentUsers = data.numberOfConcurrentUsers || null;
    this.isNumberOfConcurrentUsersExceeded = data.isNumberOfConcurrentUsersExceeded || false;
  }
}
