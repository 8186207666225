import { InvoiceEntry as InvoiceEntryEntity } from '@pos-common/classes/invoice-entry.class';

export class InvoiceGuest {
  public static fromInvoiceEntryEntity(data: InvoiceEntryEntity) {
    return new InvoiceGuest(data.uuid, data.name, data.totalAmount, data.guestNumber);
  }

  constructor(
    public readonly uuid: string,
    public readonly name: string,
    public readonly totalPrice: number,
    public readonly guestNumber: number
  ) {}
}
