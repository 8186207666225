import { ICustomerDisplaySession } from '../ICustomerDisplaySession';
import { UniversalCustomerDisplayDevice } from './UniversalCustomerDisplayDevice';
import { Subject } from 'rxjs';
import { EmailModelChanged } from '../ICustomerDisplayService';
import { Product, InvoiceEntry, InvoiceGuest } from '../entities';
import { IDriver } from './IDriver';
import {
  createCartMessage,
  createCustomerMessage,
  createEmailModelUpdatedMessage,
  createInitializeMessage,
  createInvoiceSentMessage,
  createRequestEmailAddressMessage,
  createScreensaverProductsMessage,
  EmailModelUpdated,
  Message,
  SubmitEmailModel,
} from './Messages';
import { ILogger, ILoggerFactory } from '@spryrocks/logger';

export class UniversalCustomerDisplaySession extends ICustomerDisplaySession {
  private readonly logger: ILogger;
  public readonly closed = new Subject<UniversalCustomerDisplaySession>();

  private readonly emailModelChangedSubject = new Subject<EmailModelChanged>();

  private readonly emailModel: { emailAddress: string; rememberEmail: boolean } = {
    emailAddress: '',
    rememberEmail: false,
  };

  constructor(
    public readonly device: UniversalCustomerDisplayDevice<unknown>,
    public readonly driver: IDriver<UniversalCustomerDisplayDevice<unknown>, unknown>,
    loggerFactory: ILoggerFactory,
  ) {
    super();
    this.logger = loggerFactory.createLogger('UniversalCustomerDisplaySession');
    driver.setOnMessageReceived(this.onMessageReceived.bind(this));
    driver.setOnClosed(this.onClosed.bind(this));
  }

  async initialize(currency: string, lang: string, image: string | undefined) {
    this.logger.info('Initialize device', { currency, lang, image });
    await this.driver.sendMessage(createInitializeMessage(currency, lang, image));
  }

  async setProductsForScreensaver(products: Product[]) {
    this.logger.info('Set products for screensaver', { products });
    await this.driver.sendMessage(createScreensaverProductsMessage(products));
  }

  async addCustomer(name: string, image: string | undefined) {
    this.logger.info('Add customer', { name, image });
    await this.driver.sendMessage(createCustomerMessage({ name, image }));
  }

  async removeCustomer() {
    this.logger.info('Remove customer');
    await this.driver.sendMessage(createCustomerMessage(undefined));
  }

  async updateInvoiceEntries(entries: InvoiceEntry[], guests: InvoiceGuest[], amount: number) {
    this.logger.info('Update invoice entries', { entries, guests, amount });
    await this.driver.sendMessage(createCartMessage(entries, guests, amount));
  }

  async requestEmailAddress(amount: number, qrCode: string) {
    this.logger.info('Request email address', { amount, qrCode });
    await this.driver.sendMessage(createRequestEmailAddressMessage(amount, qrCode));
  }

  async setEmailAddress(value: string) {
    this.logger.info('Set email address', { value });
    this.emailModel.emailAddress = value;
    await this.driver.sendMessage(createEmailModelUpdatedMessage({ emailAddress: value }));
  }

  async lockUserInput() {
    this.logger.info('Lock user input');
    await this.driver.sendMessage(createEmailModelUpdatedMessage({ inputEnabled: false }));
  }

  async unlockUserInput() {
    this.logger.info('Unlock user input');
    await this.driver.sendMessage(createEmailModelUpdatedMessage({ inputEnabled: true }));
  }

  emailModelChanged() {
    return this.emailModelChangedSubject;
  }

  async invoiceSent() {
    this.logger.info('Invoice sent');
    await this.driver.sendMessage(createInvoiceSentMessage(this.emailModel.emailAddress));
  }

  async destroy() {}

  private onMessageReceived(message: Message) {
    this.logger.trace('On message received', { message });
    if (message.type === 'emailModelUpdated') {
      this.onEmailModelUpdatedReceived(message);
    } else if (message.type === 'submitEmailModel') {
      this.onSubmitEmailModelReceived(message);
    }
  }

  private onEmailModelUpdatedReceived(message: EmailModelUpdated) {
    this.logger.info('Email model updated', { message });
    if (message.emailAddress !== undefined) {
      this.emailModel.emailAddress = message.emailAddress;
    }
    if (message.rememberEmail !== undefined) {
      this.emailModel.rememberEmail = message.rememberEmail;
    }
    this.emailModelChangedSubject.next({
      emailAddress: this.emailModel.emailAddress,
      save: this.emailModel.rememberEmail,
      submit: false,
    });
  }

  private onSubmitEmailModelReceived(message: SubmitEmailModel) {
    this.logger.info('Submit email model received', { message });
    this.emailModel.emailAddress = message.emailAddress;
    this.emailModel.rememberEmail = message.rememberEmail;
    this.emailModelChangedSubject.next({
      emailAddress: message.emailAddress,
      save: message.rememberEmail,
      submit: true,
    });
  }

  private onClosed() {
    this.logger.info('Session closed');
    this.closed.next(this);
  }
}
