import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Image } from '../../classes/image.class';

@Pipe({ name: 'ImageUrl' })
export class ImageUrlPipe implements PipeTransform {
  transform(value: any, type: any) {
    return new Image(value).getImageUrlBySize(type);
  }
}
@NgModule({
  declarations: [ImageUrlPipe],
  exports: [ImageUrlPipe],
})
export class ImageUrlPipeModule {}
