import { NgForOf } from '@angular/common';
import { Directive, Host, NgModule } from '@angular/core';

interface Item {
  uuid: string;
}

@Directive({
  selector: '[ngForTrackByUuid]',
})
export class NgForTrackByUuidDirective<T extends Item> {
  constructor(@Host() readonly ngFor: NgForOf<T>) {
    ngFor.ngForTrackBy = (index: number, item: T) => item?.uuid || index;
  }
}
@NgModule({
  declarations: [NgForTrackByUuidDirective],
  exports: [NgForTrackByUuidDirective],
})
export class NgForTrackByUuidDirectiveModule {}
