import { Injectable } from '@angular/core';
import { DbEntityProvider } from './db-entity.provider';
import { DbDaoService } from '../db/db-dao.service';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { Observable } from 'rxjs';
import { VatRate } from '@pos-common/classes/vat-rate.class';
import { Query } from '@paymash/capacitor-database-plugin';

@Injectable()
export class VatRateProvider extends DbEntityProvider {
  constructor(dbDaoService: DbDaoService) {
    super(UPDATES_TYPES.VATRate.type, dbDaoService);
  }

  getByUuid(uuid: string): Observable<VatRate> {
    return <Observable<VatRate>>this.getEntityByUuid(uuid);
  }

  getList(): Observable<VatRate[]> {
    return <Observable<VatRate[]>>this.getEntitiesList();
  }

  getListByParams(queryParams: Query): Observable<VatRate[]> {
    return <Observable<VatRate[]>>this.getEntitiesListByParams(queryParams).pipe(this.mapPipeTransformEntities());
  }
}
