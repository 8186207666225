export class FilterItem {
  public title: string;
  public name: string;
  public value: string | object;
  public isSelected: boolean;

  constructor() {
    this.isSelected = false;
  }
}
