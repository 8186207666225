import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { Invoice } from '@pos-common/classes/invoice.class';
import { InvoicesService } from '@pos-common/services/system/invoices.service';
import { PrinterService } from '@pos-common/services/system/printer.service';

@Component({
  selector: 'pos-printer-icon',
  templateUrl: './printer-icon.component.html',
  styleUrls: ['./printer-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterIconComponent implements OnInit {
  @Input() invoice: Invoice;
  @Input() guestNumber = 0;
  public unprintedKitchenItems = 0;
  public showUnprintedKitchenItems: boolean;

  constructor(private invoicesService: InvoicesService, private printerService: PrinterService) {}

  ngOnInit() {
    this.showUnprintedKitchenItems = this.printerService.getShowUnprintedKitchenItems();
    this.setUnprintedKitchenItemsIndicator();
  }

  private setUnprintedKitchenItemsIndicator() {
    if (!this.showUnprintedKitchenItems) {
      return;
    }
    const invoiceEntries = this.invoice.getActiveInvoiceEntriesWithoutGuests();
    const invoiceEntriesForKitchen = this.invoicesService.getInvoiceEntriesForKitchen(invoiceEntries, this.guestNumber);
    this.unprintedKitchenItems = invoiceEntriesForKitchen.reduce((counter, invoiceEntry) => {
      const { quantityForKitchenReceipt } = invoiceEntry;
      return quantityForKitchenReceipt ? counter + quantityForKitchenReceipt : counter;
    }, 0);
  }
}
