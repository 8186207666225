import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  NgModule,
} from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import { SELECT_GUEST_MODAL_ACTION } from '@pos-common/constants';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { HeaderComponentModule, IconComponentModule } from '@pos-modules/shared/components';
import { AddGuestButtonComponentModule } from '../add-guest-button/add-guest-button.component';
import { GuestButtonComponentModule } from '../guest-button/guest-button.component';

@Component({
  selector: 'pos-select-guests-modal',
  templateUrl: './select-guests-modal.component.html',
  styleUrls: ['./select-guests-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectGuestsModalComponent implements OnInit {
  @Input() invoiceEntryGuests: InvoiceEntryGuest[];
  @Input() selectedGuestNumber: number;
  @Output() onEdit = new EventEmitter<any>();

  public activeGuestNumber: number;

  constructor(private modalController: ModalController, private cdr: ChangeDetectorRef, private logService: LogService) {}

  ngOnInit() {
    this.selectGuest(this.selectedGuestNumber);
  }

  dismiss(data?: any, action?: string) {
    action = action || SELECT_GUEST_MODAL_ACTION.CANCEL;

    this.modalController
      .dismiss(data, action)
      .catch((err) => this.logService.error('SelectGuestsModalPage', 'dismiss:modalController:dismiss', err));
  }

  selectGuest(guestNumber: number) {
    this.activeGuestNumber = guestNumber;
    this.cdr.detectChanges();
  }

  handleDone() {
    this.handleClick(SELECT_GUEST_MODAL_ACTION.SELECT);
  }

  editGuest(guestNumber: number) {
    this.handleClick(SELECT_GUEST_MODAL_ACTION.EDIT, guestNumber);
  }

  createNewGuest() {
    this.handleClick(SELECT_GUEST_MODAL_ACTION.ADD_NEW_GUEST);
  }

  deleteGuest(guestNumber: number) {
    this.handleClick(SELECT_GUEST_MODAL_ACTION.DELETE, guestNumber);
  }

  handleClick(action: SELECT_GUEST_MODAL_ACTION, activeGuestNumber?: number) {
    const { SELECT, DELETE, EDIT } = SELECT_GUEST_MODAL_ACTION;
    const actions = [SELECT, DELETE, EDIT];
    const data: any = {};

    if (actions.includes(action)) {
      data.activeGuestNumber = activeGuestNumber || this.activeGuestNumber;
    }
    if (action === EDIT) {
      return this.onEdit.emit({ data, role: action });
    }
    this.dismiss(data, action);
  }
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    HeaderComponentModule,
    IconComponentModule,
    AddGuestButtonComponentModule,
    GuestButtonComponentModule,
  ],
  declarations: [SelectGuestsModalComponent],
  exports: [SelectGuestsModalComponent],
})
export class SelectGuestsModalComponentModule {}
