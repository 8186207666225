import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pos-calendar-list-view',
  templateUrl: './calendar-list-view.component.html',
  styleUrls: ['./calendar-list-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarListViewComponent {
  public searchValue = '';
  public calendarEvents = {};

  handleSearchValue(value: string) {
    this.setSearchValue(value);
  }

  clearSearch() {
    this.setSearchValue('');
  }

  private setSearchValue(value: string) {
    this.searchValue = value;
  }
}
