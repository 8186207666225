/**
 * Created by maksymkunytsia on 9/22/16.
 */

export const APP_LANGUAGES = [
  {
    'id': 0,
    'name': 'Deutsch',
    'code': 'de'
  },
  {
    'id': 1,
    'name': 'Français',
    'code': 'fr'
  },
  {
    'id': 2,
    'name': 'Italiano',
    'code': 'it'
  },
  {
    'id': 3,
    'name': 'English',
    'code': 'en'
  },
  {
    'id': 4,
    'name': 'Español',
    'code': 'es'
  },
  {
    'id': 5,
    'name': 'Portuguese',
    'code': 'pt'
  }
];
