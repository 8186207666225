import { Entity } from "./entity.class";
export class GastronomyTable extends Entity {
  public uuid: string;
  public name: string;
  public deleted: boolean;
  public invoices: Array<{ uuid: string }>;

  constructor(data) {
    super(data);
    this.name = data.name || '';
    this.deleted = data.deleted || false;
    this.invoices = data.invoices || [];
  }

}
