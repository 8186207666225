<div class="device-set">
  <div class="terminal-logo" [ngClass]="activeTerminal.name">
    <div class="terminal-img">
      <div *ngIf="isOpiTerminal">OPI</div>
    </div>
  </div>
  <ion-grid>
    <ion-row class="terminal-row terminal-title">
      <ion-col>{{ activeTerminal.title }}</ion-col>
    </ion-row>

    <ion-row class="terminal-row terminal-status">
      <ion-col class="col-left">{{ 'receipt_printer_status' | translate }}</ion-col>
      <ion-col class="col-right active">{{ status | translate }}</ion-col>
    </ion-row>

    <ion-row *ngIf="activeTerminal.ip" class="terminal-row terminal-ip">
      <ion-col class="col-left">{{ 'receipt_printer_ip_address' | translate }}</ion-col>
      <ion-col class="col-right">{{ activeTerminal.ip }}</ion-col>
    </ion-row>

    <ion-row *ngIf="activeTerminal.id" class="terminal-row terminal-id">
      <ion-col class="col-left">{{ 'terminal_id' | translate }}</ion-col>
      <ion-col class="col-right">{{ activeTerminal.id }}</ion-col>
    </ion-row>

    <ion-row *ngIf="activeTerminal.deviceModel" class="terminal-row terminal-device-model">
      <ion-col class="col-left">{{ 'terminal_device_model' | translate }}</ion-col>
      <ion-col class="col-right">{{ activeTerminal.deviceModel }}</ion-col>
    </ion-row>

    <ion-row *ngIf="activeTerminal.serialNumber" class="terminal-row terminal-serial-number">
      <ion-col class="col-left">{{ 'terminal_serial_number' | translate }}</ion-col>
      <ion-col class="col-right">{{ activeTerminal.serialNumber }}</ion-col>
    </ion-row>

    <ion-row *ngIf="activeTerminal.printer && !isMiniTerminal" class="terminal-row terminal-receipt">
      <ion-col class="col-left">{{ 'print_receipt_on_terminal' | translate }}</ion-col>
      <ion-col class="col-right">
        <ion-toggle name="kitchen" id="kitchen" [checked]="useTerminalPrinter" (ionChange)="handleUseTerminalPrinter($event)"></ion-toggle>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="isSixTerminal" class="terminal-row terminal-tips">
      <ion-col class="col-left">{{ 'enable_tipping_on_terminal' | translate }}</ion-col>
      <ion-col class="col-right">
        <ion-toggle
          name="tipAllowed"
          id="tipAllowed"
          [checked]="activeTerminal.tipAllowed"
          (ionChange)="handleTipAllowed($event)"
        ></ion-toggle>
      </ion-col>
    </ion-row>
  </ion-grid>

  <span class="device-disconnect" (click)="handleDisconnect()" tappable>
    <i class="custom-icon custom-icon-paid-invoices-trash"></i> {{ 'common_disconnect' | translate }}
  </span>
</div>
