<div
  class="sync-icon-container"
  *ngIf="isOffline || isSyncDownInProgress || isSyncUpInProgress || itemsOnSync > 0"
  [class.offline]="isOffline"
  (click)="syncInfo()"
  tappable
>
  <i
    class="arrow-round-down-icon"
    [class.animate]="isSyncDownInProgress"
    [style.animation-duration]="speed + 'ms'"
    [style.animation-delay]="speed / 2 + 'ms'"
  ></i>
  <i class="arrow-round-up-icon" [class.animate]="isSyncUpInProgress" [style.animation-duration]="speed + 'ms'"></i>
</div>
<div class="sync-icon-badge" [class.hidden]="itemsOnSync < 1" [class.red]="itemsOnSync > 100">{{ itemsOnSync }}</div>
