import { Injectable } from '@angular/core';

import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class NotifyPopupsService {
  public notificationEvent: Subject<any> = new BehaviorSubject<any>(null);

  constructor() {}

  public notificationEvents() {
    return this.notificationEvent.asObservable();
  }

  public launchNotification(event, payload: any = null) {
    this.notificationEvent.next({ event: event, payload: payload });
  }
}
