import { Injectable } from '@angular/core';
import { PAYMENT_PROVIDERS, PAYMENT_TRANSACTION } from '../../constants/payment-providers.const';
import { AvailableCurrencies } from '../../../common/constants/mypos.const';
import { PaymentResult } from '../../classes/payment-result.class';
import { TimApiService } from './timApi.service';
import { IngenicoService } from './ingenico.service';
import { PaymentError } from '../../constants/payment-error.enum';
import { MyPosFacade } from '../../../modules/my-pos/my-pos.facade';
import { MyPosMiniFacade } from '../../../modules/my-pos-mini/my-pos-mini.facade';
import { TranslateService } from '@ngx-translate/core';
import { MyPosService } from '@pos-common/services/system/devices/my-pos/my-pos.service';
import { SumUpService } from './sumup/sumup.service';
import { AdyenPaymentService } from '@pos-common/services/system/adyen';
import { PaymentProcessingService } from '@pos-common/components/payment-processing/payment-processing.service';
import { PaymentProcessingActions } from '@pos-common/components/payment-processing/payment-processing-actions.enum';
import { LogService } from './logger/log.service';
import { stringifySafety } from '@pos-common/services/system/logger';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { LocalStorage } from '../utils/localstorage.utils';
import { PaymentMethodsApiService } from '../api/payment-methods-api.service';
import { PaymentMethodsDbEntityProvider } from '../resources/payment-methods-db-entity.provider';
import { switchMap } from 'rxjs/operators';
import { PaymentMethod } from '@pos-common/classes/payment-method.class';
import { REGEXPS } from '@pos-common/constants';
import { of } from 'rxjs';

@Injectable()
export class PaymentService {
  constructor(
    private readonly paymentProcessingService: PaymentProcessingService,
    private readonly timApiService: TimApiService,
    private readonly myPosFacade: MyPosFacade,
    private readonly myPosMiniFacade: MyPosMiniFacade,
    private readonly IngenicoService: IngenicoService,
    private readonly translateService: TranslateService,
    private readonly myPosService: MyPosService,
    private readonly sumUpService: SumUpService,
    private readonly adyenPaymentService: AdyenPaymentService,
    private paymentMethodsApiService: PaymentMethodsApiService,
    private paymentMethodsProvider: PaymentMethodsDbEntityProvider,
    private localStorageService: LocalStorage,
    private logService: LogService
  ) {}

  makeTransaction({
    paymentProvider,
    amount,
    currency,
    transactionType,
    invoiceUuid,
    paymentUuid,
  }: {
    paymentProvider: PAYMENT_PROVIDERS;
    amount: number;
    currency: string;
    transactionType: string;
    invoiceUuid: string;
    paymentUuid?: string;
  }): Promise<PaymentResult> {
    return new Promise(async (resolve, reject) => {
      const error = this.checkErrorBeforeTransaction(paymentProvider, amount, currency, transactionType);
      if (error) {
        reject(error);
        return;
      }
      const loggerMessage = stringifySafety(this.localStorageService.getObject(StorageKeys.activeTerminal));
      this.logService.debug('ActiveDevice', `paymentProvider = ${paymentProvider}, activeTerminal = ${loggerMessage}`);
      switch (paymentProvider) {
        case PAYMENT_PROVIDERS.SUMUP:
          this.sumUpService.makeTransaction(amount).then(resolve).catch(reject);
          break;
        case PAYMENT_PROVIDERS.SIX:
          this.timApiService.makeTransaction(amount, currency).then(resolve).catch(reject);
          break;
        case PAYMENT_PROVIDERS.OPI:
          if (transactionType === PAYMENT_TRANSACTION.PAYMENT_REFUND) {
            this.IngenicoService.makeRefundTransaction(amount.toFixed(2).toString()).then(resolve).catch(reject);
          } else if (transactionType === PAYMENT_TRANSACTION.PAYMENT_REVERSAL) {
            this.IngenicoService.makeReversalTransaction().then(resolve).catch(reject);
          } else {
            this.IngenicoService.initIngenicoPayment(amount.toFixed(2).toString()).then(resolve).catch(reject);
          }
          break;
        case PAYMENT_PROVIDERS.MYPOS:
          if (this.myPosService.isMyPosTerminal) {
            const language = this.translateService.currentLang;
            this.myPosMiniFacade.makeTransaction(currency, language, amount, transactionType).then(resolve).catch(reject);
            return;
          }

          if (!this.myPosService.isMyPosDevice) {
            reject(PaymentError.NO_MYPOS_FOUND);
            break;
          }

          this.myPosFacade.makeTransaction({ amount, currency, transactionType, invoiceUuid }).then(resolve).catch(reject);
          break;
        case PAYMENT_PROVIDERS.PAYMASH_PAY:
          if (transactionType === PAYMENT_TRANSACTION.PAYMENT_REFUND || amount < 0) {
            this.adyenPaymentService
              .makeRefund({ amount: Math.abs(amount), currency, paymentUuid })
              .then(resolve)
              .catch(reject);
          } else if (!transactionType || transactionType === PAYMENT_TRANSACTION.PAYMENT_PURCHASE) {
            this.adyenPaymentService.makePayment({ amount, currency, paymentUuid }).then(resolve).catch(reject);
          }
          break;
      }
    });
  }

  private checkErrorBeforeTransaction(
    paymentProvider: PAYMENT_PROVIDERS,
    amount: number,
    currency: string,
    transactionType: string
  ): string {
    const isPurchase = transactionType === PAYMENT_TRANSACTION.PAYMENT_PURCHASE;
    const isRefund = !isPurchase;
    const isReimbursement = isPurchase && amount < 0;
    const isZeroAmount = amount === 0;
    const isMyPosProvider = paymentProvider === PAYMENT_PROVIDERS.MYPOS;
    const isPaymashPayProvider = paymentProvider === PAYMENT_PROVIDERS.PAYMASH_PAY;
    const supportedRefundProviders = [PAYMENT_PROVIDERS.OPI, PAYMENT_PROVIDERS.MYPOS, PAYMENT_PROVIDERS.PAYMASH_PAY];

    if (!supportedRefundProviders.includes(paymentProvider) && (isRefund || isReimbursement)) {
      return PaymentError.REFUND_NOT_SUPPORTED;
    }
    if ((isPaymashPayProvider || isMyPosProvider) && isZeroAmount) {
      return PaymentError.AMOUNT_ZERO_NOT_SUPPORTED;
    }

    if (isMyPosProvider) {
      if (AvailableCurrencies.indexOf(currency.toLocaleUpperCase()) === -1) {
        return PaymentError.NO_MYPOS_CURRENCY;
      }
      const { isMyPosTerminal, isMyPosDevice } = this.myPosService;
      if (!isMyPosTerminal && !isMyPosDevice) {
        return PaymentError.NO_MYPOS_FOUND;
      }
    }
    return '';
  }

  public activeTerminals() {
    if (this.IngenicoService.checkIngenicoTerminal()) return PAYMENT_PROVIDERS.OPI;
    if (this.timApiService.getTerminalInstantly().viewName) return PAYMENT_PROVIDERS.SIX;
    return null;
  }

  showPaymentError(err: any) {
    const values = Object.values(PaymentError);
    if (values.includes(err)) {
      if (err === PaymentError.MYPOS_INVALID_CURRENCY) {
        return;
      }
      const message = this.translateService.instant(err);
      this.paymentProcessingService.init();
      this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, {
        message,
        retryButtonOff: true,
      });
    }
  }

  createNewPaymentMethod(name: string) {
    return this.paymentMethodsProvider
      .getListByParams({ deleted: false })
      .pipe(
        switchMap((paymentMethods: PaymentMethod[]) => {
          const currentName = name.toLowerCase().replace(REGEXPS.ANY_SPACES, '');
          const hasPaymentMethod = paymentMethods
            .map((paymentMethod) => paymentMethod?.name?.toLowerCase().replace(REGEXPS.ANY_SPACES, ''))
            .some((paymentMethodName) => currentName.indexOf(paymentMethodName) !== -1);
          return hasPaymentMethod ? of() : this.paymentMethodsApiService.create(name);
        })
      )
      .toPromise()
      .catch((error) => {
        this.logService.error('createNewPaymentMethod', error);
      });
  }
}
