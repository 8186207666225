/**
 * Created by y.belinsky on 10/25/16.
 */

import { of as observableOf, Observable, zip } from 'rxjs';

import { first, map, mergeMap } from 'rxjs/operators';
// Vendors
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Common
import { PRINTER_RECEIPT } from '../../constants/printer-receipt.const';
import { SERVER_CONFIG } from '../../constants/server.const';
import { LocalStorage } from '../utils/localstorage.utils';

import { Employee } from '../../classes/employee.class';
import { Invoice } from '../../classes/invoice.class';
import { Store } from '../../classes/store.class';
import { PrintingQueueItem, PrintingQueueItemStatus, ReceiptPrinter } from './receipt-printers/classes/receipt-printer.class';
import { DbDaoService } from '../db/db-dao.service';
import { SecurityService } from './security.service';
import * as lodash from 'lodash';
import { AlertService } from './alert.service';
import { InvoicesService } from './invoices.service';
import { INVOICE_TYPES } from '../../constants/invoice-types';
import { GoogleAnalyticsService } from './google-analitycs.service';
import { ReceiptBuilder } from './receipt-printers/classes/receipt-builder.class';
import { ReceiptPrinterModeTypes } from '../../constants/receipt-printer-mode-types';
import { ReceiptPrintersService } from './receipt-printers';
import { ReceiptBuilderService } from './receipt-builder.service';
import { PRINTER_MODELS } from './receipt-printers/printer-models.const';
import { Company } from '../../classes/company.class';
import { LogService } from './logger/log.service';
import { VirtualPrintersProvider } from '../resources/virtual-printers-db-entity.provider';
import { EmployeesProvider } from '../resources/employees-db-entity.provider';
import { StoresProvider } from '../resources/stores-db-entity.provider';
import { InvoicesProvider } from '../resources/invoices-db-entity.provider';
import { PaymentMethodsDbEntityProvider } from '../resources/payment-methods-db-entity.provider';
import { InvoiceEntry } from '../../classes/invoice-entry.class';
import { ProductVariantProvider } from '../resources/product-variant-db-entity.provider';
import { ProductProvider } from '../resources/product-db-entity.provider';
import { ProductCategoryProvider } from '../resources/product-category-db-entity.provider';
import { UuidService } from '../utils/uuid.utils';
import { PrintingQueueActionType, PrintingQueueStore } from '../../classes/printing-queue-store.class';
import { PRODUCT_TYPES } from '../../constants/product-types';
import { stringifySafety } from '@pos-common/services/system/logger';
import { PAYMASH_PROFILE } from '@profile';
import { ToastService } from './toast.service';
import { PrintingItemAdditionalInformationInterface } from './receipt-printers/interfaces/printing-item-additional-information.interface';
import { GiftCardsProviderAbstract } from '../resources/gift-cards.provider.abstract';
import { GiftCardsListInterface } from '@pos-modules/assing-gift-card/gift-cards-list.interface';
import { PAYMENT_PROVIDERS } from '../../constants/payment-providers.const';
import { FileReaderUtils } from '../utils/file-reader/file-reader.utils';
import { NotifyPopupsService } from './notify-popups.service';
import { MyPosFacade } from '@pos-modules/my-pos/my-pos.facade';
import { MyPosMiniFacade } from '@pos-modules/my-pos-mini/my-pos-mini.facade';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { PrintersSettingsType } from '@pos-common/constants/printers-settings-type.enum';
import { IPrintersSettings } from '@pos-common/interfaces/printers-settings.interface';
import { GastronomyHallsProvider } from '../resources';
import { MyPosService } from './devices/my-pos/my-pos.service';
import { PrintPdfPlugin } from '@paymash/capacitor-print-pdf-plugin';
import { PlatformService } from './platform/platform.service';

export const PRINTERS_SETTINGS_ACTIONS = {
  ENABLE_AUTO_PRINT_RECEIPT: 'ENABLE_AUTO_PRINT_RECEIPT',
  DISABLE_AUTO_PRINT_RECEIPT: 'DISABLE_AUTO_PRINT_RECEIPT',
  ENABLE_CASH_REGISTER_AUTO_OPEN: 'ENABLE_CASH_REGISTER_AUTO_OPEN',
  DISABLE_CASH_REGISTER_AUTO_OPEN: 'DISABLE_CASH_REGISTER_AUTO_OPEN',
  ENABLE_AUTO_PRINT_KITCHEN_RECEIPT: 'ENABLE_AUTO_PRINT_KITCHEN_RECEIPT',
  DISABLE_AUTO_PRINT_KITCHEN_RECEIPT: 'DISABLE_AUTO_PRINT_KITCHEN_RECEIPT',
};

@Injectable()
export class PrinterService {
  public receiptPrintingInProgress: boolean = false;
  private printersSettings: IPrintersSettings = {
    printReceiptAutomatically: false,
    openCashRegisterAutomatically: false,
    printKitchenReceiptAutomatically: false,
    showUnprintedKitchenItems: false,
  };
  private _printingQueue: PrintingQueueStore = new PrintingQueueStore([]);

  constructor(
    public LocalStorage: LocalStorage,
    public DbDaoService: DbDaoService,
    public securityService: SecurityService,
    private platformService: PlatformService,
    public translate: TranslateService,
    public toastService: ToastService,
    public AlertService: AlertService,
    public InvoicesService: InvoicesService,
    public GoogleAnalyticsService: GoogleAnalyticsService,
    public receiptPrintersService: ReceiptPrintersService,
    public receiptBuilderService: ReceiptBuilderService,
    public logService: LogService,
    public virtualPrintersProvider: VirtualPrintersProvider,
    public employeesProvider: EmployeesProvider,
    public storesProvider: StoresProvider,
    public invoicesProvider: InvoicesProvider,
    public gastronomyHallsProvider: GastronomyHallsProvider,
    public paymentMethodsProvider: PaymentMethodsDbEntityProvider,
    public productVariantProvider: ProductVariantProvider,
    public productProvider: ProductProvider,
    public productCategoryProvider: ProductCategoryProvider,
    public giftCardsProvider: GiftCardsProviderAbstract,
    public uuidService: UuidService,
    public myPosFacade: MyPosFacade,
    public myPosMiniFacade: MyPosMiniFacade,
    private myPosService: MyPosService,
    public notifyPopupsService: NotifyPopupsService
  ) {
    this.getPrintingQueueWithFilter(PrintingQueueItemStatus.waitForPrinting).subscribe((items) => {
      this.checkPrintingQueue(items);
    });
  }

  public getPrintingQueueWithFilter(status: PrintingQueueItemStatus): Observable<PrintingQueueItem[]> {
    return this.printingQueue.pipe(
      mergeMap((items) => {
        const filteredItems = items.filter((i) => i.printingStatus === status);
        const sortedItems = filteredItems.sort((a, b) => a.printingTriesCount - b.printingTriesCount);
        return observableOf(sortedItems);
      })
    );
  }

  get printingQueue(): Observable<PrintingQueueItem[]> {
    return this._printingQueue.asObservable();
  }

  public getPrinterViewName(printer: ReceiptPrinter) {
    let printerViewName: string = printer.printerName || printer.deviceName;
    if (printer.isPosPrinter || printer.isKitchenPrinter) printerViewName += ' (%s)';
    let printerViewNameActivities: string = '';
    if (printer.isPosPrinter) printerViewNameActivities = 'POS';
    if (printer.isPosPrinter && printer.isKitchenPrinter) printerViewNameActivities += ' & ';
    if (printer.isKitchenPrinter) printerViewNameActivities += this.translate.instant('receipt_printer_kitchen');
    if (printer.virtualPrinters.length > 0) printerViewNameActivities += ' ' + printer.virtualPrinters.map((p) => p.name).join(', ');
    return printerViewName.replace(/%s/g, printerViewNameActivities);
  }

  getPrintersList(filterType: ReceiptPrinterModeTypes = ReceiptPrinterModeTypes.ALL): Observable<ReceiptPrinter[]> {
    const printersList = this.receiptPrintersService.printersList.pipe(
      map((items: ReceiptPrinter[]) => {
        items.forEach((item) => {
          item.printerViewName = this.getPrinterViewName(item);
        });
        return items;
      })
    );
    switch (filterType) {
      case ReceiptPrinterModeTypes.POS:
      case ReceiptPrinterModeTypes.CASH_REGISTER:
      case ReceiptPrinterModeTypes.BILL:
        return printersList.pipe(map((printers) => printers.filter((p) => p.isPosPrinter)));
      case ReceiptPrinterModeTypes.KITCHEN:
        return printersList.pipe(map((printers) => printers.filter((p) => p.isKitchenPrinter)));
      case ReceiptPrinterModeTypes.STAR_SCANNER:
        return printersList.pipe(map((printers) => printers.filter((p) => p.isScannerActive)));
      case ReceiptPrinterModeTypes.STAR_SCANNER_SUPPORTED:
        return printersList.pipe(map((printers) => printers.filter((p) => p.isScannerSupport)));
      default:
        return printersList;
    }
  }

  getPrinterListOnce(filterType: ReceiptPrinterModeTypes = ReceiptPrinterModeTypes.ALL): Observable<ReceiptPrinter[]> {
    return this.getPrintersList(filterType).pipe(first());
  }

  public printReceipt(
    printer: ReceiptPrinter,
    receipt: ReceiptBuilder,
    entityInformation: PrintingItemAdditionalInformationInterface = {
      uuid: null,
      name: null,
    }
  ) {
    this.GoogleAnalyticsService.trackEvent('Printer', 'printReceiptCommon');
    this.logService.debug(
      'PrinterService',
      `printReceipt:printer - ${stringifySafety(printer)}, receipt - ${stringifySafety(receipt, ['image'])}`
    );
    return new Promise((resolve, reject) => {
      this._printingQueue.dispatch({
        type: PrintingQueueActionType.UPSERT_ITEM,
        payload: new PrintingQueueItem(
          this.uuidService.generate(),
          new Date().toISOString(),
          printer,
          receipt as ReceiptBuilder,
          0,
          resolve,
          reject,
          PrintingQueueItemStatus.waitForPrinting,
          entityInformation.uuid,
          entityInformation.name
        ),
      });
    });
  }

  public repeatPrintingOfItem(item: PrintingQueueItem) {
    this.logService.debug('PrinterService', `repeatPrintingOfItem:item - ${stringifySafety(item, ['image'])}`);
    item.printingStatus = PrintingQueueItemStatus.waitForPrinting;
    this._printingQueue.dispatch({
      type: PrintingQueueActionType.UPSERT_ITEM,
      payload: item,
    });
  }

  public removePrintingOfItemFromQueue(item: PrintingQueueItem) {
    this.logService.debug('PrinterService', `removePrintingOfItemFromQueue:item - ${stringifySafety(item, ['image'])}`);
    item.printingStatus = PrintingQueueItemStatus.printingCancel;
    this._printingQueue.dispatch({
      type: PrintingQueueActionType.REMOVE_ITEM,
      payload: item,
    });
  }

  public printInvoiceReceiptMultiple(
    invoice: Invoice,
    guestNumber: number,
    posMode: ReceiptPrinterModeTypes = ReceiptPrinterModeTypes.POS
  ) {
    return new Promise((resolve, reject) => {
      this.logService.debug('PrinterList', `Invoice ${invoice.uuid} will be printered as receipt multiple`);
      const printingResults: number[] = [];
      this.getPrinterListOnce(posMode).subscribe((printers) => {
        printers.map((printer) => {
          this.createReceiptFromInvoice(invoice, printer, posMode, false, guestNumber)
            .then((receipt) => {
              if (receipt) {
                this.printReceipt(printer, receipt, {
                  uuid: invoice.uuid,
                  name: invoice.invoiceDisplayId,
                })
                  .then(() => {
                    printingResults.push(1);
                    this.printingCompletion(printers, printingResults, resolve, reject);
                  })
                  .catch(() => {
                    printingResults.push(0);
                    this.printingCompletion(printers, printingResults, resolve, reject);
                  });
              } else {
                printingResults.push(0);
              }
            })
            .catch(() => {
              printingResults.push(0);
              this.printingCompletion(printers, printingResults, resolve, reject);
            });
        });
      }, reject);
    });
  }

  public printReceiptOnMultiplePrinters(
    receiptData: ReceiptBuilder,
    posMode: ReceiptPrinterModeTypes = ReceiptPrinterModeTypes.POS
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getPrinterListOnce(posMode).subscribe((printers) => {
        const printingResults: number[] = [];
        if (printers && printers.length > 0) {
          printers.map((printer) => {
            this.printReceipt(printer, receiptData)
              .then(() => {
                printingResults.push(1);
                this.printingCompletion(printers, printingResults, resolve, reject);
              })
              .catch(() => {
                printingResults.push(0);
                this.printingCompletion(printers, printingResults, resolve, reject);
              });
          });
        } else {
          reject('receipt_printer_default_printer_does_not_set');
        }
      }, reject);
    });
  }

  private printingCompletion(printers, printingResults, resolve, reject) {
    if (printers.length === printingResults.length) {
      const result = printingResults.reduce((p, c) => {
        return p + c;
      }, 0);
      if (result > 0) {
        resolve();
      } else {
        reject();
      }
    }
  }

  private checkPrintingQueue(printingQueue: PrintingQueueItem[]) {
    if (this.receiptPrintingInProgress) return;
    if (printingQueue.length > 0) {
      this.receiptPrintingInProgress = true;
      const printingStartTime = performance.now();
      let item: PrintingQueueItem = printingQueue.shift();
      const setStatusAndEmit = (status: PrintingQueueItemStatus) => {
        item.printingStatus = status;
        let actionType = PrintingQueueActionType.UPSERT_ITEM;
        if (status === PrintingQueueItemStatus.printingDone || status === PrintingQueueItemStatus.printingCancel) {
          actionType = PrintingQueueActionType.REMOVE_ITEM;
        }
        this._printingQueue.dispatch({
          type: actionType,
          payload: item,
        });
      };

      setStatusAndEmit(PrintingQueueItemStatus.printingInProgress);
      this.makePrintReceiptCall(item)
        .then(() => {
          item.resolve();
          this.receiptPrintingInProgress = false;
          setStatusAndEmit(PrintingQueueItemStatus.printingDone);
          const printingEndTime = performance.now();
          this.logService.info(
            'PrinterService',
            `checkPrintingQueue:makePrintReceiptCall:
                        printingDone,
                        printing took - ${printingEndTime - printingStartTime} milliseconds
                        item - ${stringifySafety(item, ['image'])}`
          );
        })
        .catch(() => {
          item.printingTriesCount++;
          this.receiptPrintingInProgress = false;
          const printingEndTime = performance.now();
          this.logService.info(
            'PrinterService',
            `checkPrintingQueue:makePrintReceiptCall:
                        printingFailed,
                        printing took - ${printingEndTime - printingStartTime} milliseconds
                        item - ${stringifySafety(item, ['image'])}`
          );
          if (item.printingTriesCount > 3) {
            item.reject();
          }
          if (item.printingTriesCount > PAYMASH_PROFILE.printingTriesCount) {
            setStatusAndEmit(PrintingQueueItemStatus.printingFailed);
          } else if (item.printingStatus === PrintingQueueItemStatus.printingCancel) {
            setStatusAndEmit(PrintingQueueItemStatus.printingCancel);
          } else {
            this.repeatPrintingOfItem(item);
          }
        });
    }
  }

  private makePrintReceiptCall(printingItem: PrintingQueueItem): Promise<void> {
    return new Promise((resolve, reject) => {
      this.logService.debug(
        'PrinterService',
        `makePrintReceiptCall: printerName - ${printingItem.printer.printerName},
                 printingTriesCount - ${printingItem.printingTriesCount},
                 relatedEntityName - ${printingItem.relatedEntityName},
                 relatedEntityUuid - ${printingItem.relatedEntityUuid}`
      );
      setTimeout(() => {
        if (window.cordova) {
          const { printerName, deviceName, connectionType, isScannerActive } = printingItem.printer;
          const loggerPrinter = { printerName, deviceName, connectionType, isScannerActive };
          this.logService.debug('ActiveDevice', `activePrinter = ${stringifySafety(loggerPrinter)}`);
          this.receiptPrintersService
            .print(printingItem.printer, printingItem.receipt as ReceiptBuilder)
            .then(() => {
              this.logService.info('PrinterService', `makePrintReceiptCall:printerName - ${printingItem.printer.printerName}`);
              resolve();
            })
            .catch((e) => {
              this.logService.error('PrinterService', `makePrintReceiptCall:printerName - ${printingItem.printer.printerName}`, e);
              reject(e);
            });
        } else {
          const random_boolean = Math.random() >= 0.5;
          if (random_boolean) {
            if ((printingItem.receipt as ReceiptBuilder).isOpenCashRegister) {
              this.logService.debug('PrinterService', 'Open cash register');
            }
            this.logService.debug('PrinterService', `receiptAsPlainText -\n${(printingItem.receipt as ReceiptBuilder).receiptAsPlainText}`);
            resolve();
          } else {
            reject('ERR');
          }
        }
      }, printingItem.printingTriesCount * 1000);
    });
  }

  public createReceiptFromInvoice(
    invoice: Invoice,
    printer: ReceiptPrinter,
    receiptType?: string,
    openCashRegister?: boolean,
    guestNumber?: number
  ): Promise<ReceiptBuilder> {
    if (receiptType && receiptType === ReceiptPrinterModeTypes.KITCHEN) {
      return this.kitchenReceiptCreationFlow(invoice, printer, guestNumber);
    } else {
      return this.regularReceiptCreationFLow(invoice, printer, openCashRegister);
    }
  }

  private kitchenReceiptCreationFlow(invoice: Invoice, printer: ReceiptPrinter, guestNumber: number): Promise<ReceiptBuilder> {
    return new Promise((resolve, reject) => {
      const listOfObservable = [
        this.employeesProvider.getByUuid(invoice.employee.uuid),
        this.assignVirtualPrintersInvoiceEntries(invoice.invoiceEntries),
        this.gastronomyHallsProvider.getByGastronomyTable(invoice.gastronomyTable),
      ];

      zip(...listOfObservable).subscribe((result) => {
        const [employeeData, invoiceEntriesData, gastronomyHallData] = <any[]>result;
        invoice.invoiceEntries = [...invoiceEntriesData];
        const receiptBuilder = this.receiptBuilderService.makeKitchenReceipt(
          invoice,
          employeeData,
          printer,
          guestNumber,
          gastronomyHallData
        );
        resolve(receiptBuilder);
      }, reject);
    });
  }

  public assignVirtualPrintersInvoiceEntries(invoiceEntries: InvoiceEntry[]): Observable<InvoiceEntry[]> {
    const listOfResults: Observable<InvoiceEntry>[] = [];
    invoiceEntries.forEach((invoiceEntry) => {
      listOfResults.push(this.getVirtualPrinterForInvoiceEntry(invoiceEntry));
    });
    return zip(...listOfResults);
  }

  private getVirtualPrinterForInvoiceEntry(invoiceEntry: InvoiceEntry): Observable<InvoiceEntry> {
    this.logService.debug('PrinterService', `getVirtualPrinterForInvoiceEntry`);
    const mutableInvoiceEntry = new InvoiceEntry(invoiceEntry);
    const { INDIVIDUAL, CATEGORY } = PRODUCT_TYPES;
    if (mutableInvoiceEntry.type === INDIVIDUAL || mutableInvoiceEntry.type === CATEGORY) {
      mutableInvoiceEntry.virtualPrintersUuid = '';
      return observableOf(mutableInvoiceEntry);
    }
    return this.productVariantProvider.getByUuid(invoiceEntry.productVariant.uuid).pipe(
      mergeMap((productVariant) => this.productProvider.getByUuid(productVariant.product.uuid)),
      mergeMap((product) => {
        const virtualPrinters = [];
        if (product.virtualPrinter) {
          virtualPrinters.push(observableOf(product.virtualPrinter.uuid));
        } else {
          const productCategories = product.productInCategories.filter((c) => c.category).map((c) => c.category.uuid);
          if (productCategories.length === 0) {
            virtualPrinters.push(observableOf(null));
          } else {
            const printers = productCategories.map((cat) => this.getPrinterUuidFromParentCategory(cat));
            virtualPrinters.push(...printers);
          }
        }
        return zip(...virtualPrinters);
      }),
      map((printers) => {
        mutableInvoiceEntry.virtualPrintersUuid = printers
          .filter((printer, index) => {
            return printer !== null && printers.indexOf(printer) === index;
          })
          .join(',');
        return mutableInvoiceEntry;
      })
    );
  }

  private getPrinterUuidFromParentCategory(categoryUuid: string, observer?): Observable<string> {
    const makeAction = (observerInner) => {
      this.productCategoryProvider.getByUuid(categoryUuid).subscribe(
        (cat) => {
          if (cat.virtualPrinter) {
            observerInner.next(cat.virtualPrinter.uuid);
            observerInner.complete();
            return;
          }
          if (cat.parentProductCategory) {
            this.getPrinterUuidFromParentCategory(cat.parentProductCategory.uuid, observerInner);
          } else {
            observerInner.next(null);
            observerInner.complete();
          }
        },
        (error) => {
          observerInner.next(null);
          observerInner.complete();
        }
      );
    };
    if (!observer) {
      return new Observable((observer) => {
        makeAction(observer);
      });
    } else {
      makeAction(observer);
    }
  }

  private getGiftCardsToListSources(invoice: Invoice): Observable<GiftCardsListInterface> {
    const giftCardsEntriesUuidList = invoice.getGiftCardUuidFromGiftCardEntries();
    const giftCardsPaymentsUuidList = invoice.getGiftCardUuidFromGiftCardPayments();
    const giftCardsUuidList = [...giftCardsEntriesUuidList, ...giftCardsPaymentsUuidList];
    if (giftCardsUuidList.length > 0) {
      return this.giftCardsProvider.getGiftCardsList(giftCardsUuidList);
    }
    return observableOf({});
  }

  private regularReceiptCreationFLow(
    invoice: Invoice,
    printer: ReceiptPrinter,
    openCashRegister?: boolean
  ): Promise<ReceiptBuilder | null> {
    return new Promise((resolve) => {
      const companyData: any = this.securityService.getLoggedCompanyData();
      const listSources: Observable<any>[] = [this.storesProvider.getByUuid(invoice.store.uuid), this.paymentMethodsProvider.getList()];

      if (invoice.employee?.uuid) {
        listSources.unshift(this.employeesProvider.getByUuid(invoice.employee.uuid));
      } else {
        listSources.unshift(observableOf({}));
      }

      if (invoice.invoiceType === INVOICE_TYPES.CANCELLATION && invoice.originalInvoiceReference) {
        listSources.push(this.invoicesProvider.getByUuid(invoice.originalInvoiceReference.uuid));
      } else {
        listSources.push(observableOf(null));
      }

      listSources.push(this.invoicesProvider.getCancelledInvoicesBylUuid(invoice.uuid));
      listSources.push(this.getGiftCardsToListSources(invoice));

      zip(...listSources).subscribe((result) => {
        const [employeeData, storeData, paymentMethods, originalInvoice, cancellationInvoicesList, giftCardsList] = result;

        //Set original invoice reference
        if (invoice.invoiceType === INVOICE_TYPES.CANCELLATION && invoice.originalInvoiceReference) {
          if (originalInvoice) {
            invoice.originalInvoiceReference.sequentId = originalInvoice.sequentId;
            invoice.originalInvoiceReference.invoiceId = originalInvoice.invoiceId;
          } else {
            invoice.originalInvoiceReference.invoiceId = invoice.originalInvoiceReference.uuid
              ? invoice.originalInvoiceReference.uuid.substring(0, 8)
              : '-';
          }
        }
        let cancelInvoice: Invoice = null;
        if (cancellationInvoicesList && cancellationInvoicesList.length) {
          cancelInvoice = new Invoice(invoice);
          cancellationInvoicesList.forEach((cancellationInvoice: Invoice) => {
            cancelInvoice = this.InvoicesService.subInvoices(cancelInvoice, cancellationInvoice);
          });
          this.InvoicesService.calculateInvoiceAmountAfterDiscount(cancelInvoice);
        }
        resolve(
          this.receiptBuilderService.makeInvoiceReceipt(
            invoice,
            employeeData,
            companyData,
            storeData,
            paymentMethods,
            giftCardsList,
            printer,
            openCashRegister,
            cancelInvoice
          )
        );
      });
    });
  }

  public async notifyUserOfPrinterAssignment() {
    const alert = await this.AlertService.create({
      header: this.translate.instant('common_error'),
      message: this.translate.instant('receipt_printer_wrong_virtual_printer_assignment_text'),
      buttons: [{ text: 'OK' }],
    });
    alert.present().catch((err) => this.logService.error('PrinterService', 'notifyUserOfPrinterAssignment:alert:present', err));
  }

  public showSelectModelPopUp(printer: ReceiptPrinter) {
    return new Promise(async (resolve, reject) => {
      const title = this.translate.instant('receipt_printer_select_model_title');
      let radio_options = [];
      lodash.each(PRINTER_MODELS, (printerModels) => {
        const printerModel = printerModels.model;
        if (printerModel !== undefined) {
          radio_options.push({
            type: 'radio',
            label: printerModel,
            value: printerModel,
            checked: printerModel === printer.printerModel,
          });
        }
      });
      const buttons = [
        {
          text: this.translate.instant('common_cancel'),
          handler: reject,
        },
        {
          text: 'OK',
          handler: (printerSelectedModel) => {
            printer.printerNameForModelPick = printerSelectedModel;
            printer.updateModel(printer);
            this.receiptPrintersService.updateSavedPrinterModel(printer);
            resolve(new ReceiptPrinter(printer));
          },
        },
      ];
      const alert = await this.AlertService.create({
        header: title,
        inputs: radio_options,
        buttons: buttons,
      });
      await alert.present();
    });
  }

  public testPrint(printer: ReceiptPrinter) {
    if (printer.printerConfiguration.model === undefined && printer.deviceName !== PAYMENT_PROVIDERS.MYPOS) {
      this.showSelectModelPopUp(printer)
        .then((printerData) => this.printTestReceipt(new ReceiptPrinter(printerData)))
        .catch((err) => this.logService.error('PrinterService', 'testPrint:showSelectModelPopUp', err));
    } else {
      this.printTestReceipt(new ReceiptPrinter(printer)).catch((err) =>
        this.logService.error('PrinterService', 'testPrint:printTestReceipt', err)
      );
    }
  }

  private printTestReceipt(printer: ReceiptPrinter) {
    this.notifyPopupsService.launchNotification('test-printer');
    const invoice = new Invoice(PRINTER_RECEIPT.receiptTestData.testInvoice);
    const testReceipt = this.receiptBuilderService.makeInvoiceReceipt(
      new Invoice(PRINTER_RECEIPT.receiptTestData.testInvoice),
      new Employee(PRINTER_RECEIPT.receiptTestData.testEmployee),
      new Company(PRINTER_RECEIPT.receiptTestData.testCompany),
      new Store(PRINTER_RECEIPT.receiptTestData.testStore),
      [],
      {},
      printer
    );
    return this.printReceipt(printer, testReceipt, {
      uuid: invoice.uuid,
      name: invoice.invoiceDisplayId,
    });
  }

  /**
   * Receipt printers END
   * */

  public printInvoiceA4(invoice: Invoice) {
    const pdfLink = `${SERVER_CONFIG.API_URL}/invoices/${invoice.uuid}/pdf`;
    this.printPdfFileByUrl(pdfLink);
  }

  public printPdfFileByUrl(pdfLink: string) {
    return this.securityService
      .doSecureRequest(pdfLink, 'get', null, { responseTypeData: 'Blob', showLoading: true })
      .then(
        (data) => this.blobToBase64(data.data),
        (err) => {
          throw { type: 'http', err };
        }
      )
      .then((base64String) => this.printPdf(base64String))
      .catch((error) => {
        this.showPrinterErrorMessageByType(error.type);
        throw error;
      });
  }

  private blobToBase64(data: any) {
    return new Promise<string>((resolve, reject) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const reader = FileReaderUtils.getFileReader();
      reader.onload = (event) => {
        const base64PdfString = event.target.result as string;
        const base64String = base64PdfString.split('data:application/pdf;base64,')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }

  public showPrinterErrorMessageByType(errorType: string): Promise<HTMLIonAlertElement> {
    let message: string = 'invoice_pdf_loading_error';
    if (errorType === 'printer' || errorType === 'web') {
      message = 'invoice_print_error';
    }
    return this.AlertService.showAlert({ header: 'common_error', message });
  }

  public printPdf(base64String: string) {
    if (this.platformService.isNativePlatform) {
      return PrintPdfPlugin.print(base64String, { title: 'Print Document' });
    }
    return Promise.reject({ type: 'web' });
  }

  public openCashRegister(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.myPosService.isMyPosHubDevice) {
        this.myPosFacade.openCashDrawer().then(resolve).catch(reject);
        return;
      }

      this.getPrinterListOnce(ReceiptPrinterModeTypes.POS).subscribe((printers) => {
        if (printers.length === 0) {
          reject('you_dont_have_any_active_printers');
          return;
        }
        let badRequestsCount = 0;
        const receiptBuilder = new ReceiptBuilder();
        receiptBuilder.addPulse();
        printers.forEach((printer: ReceiptPrinter) => {
          this.printReceipt(printer, receiptBuilder)
            .then(resolve)
            .catch(() => {
              badRequestsCount++;
              if (badRequestsCount === printers.length) reject();
            });
        });
      }, reject);
    });
  }

  private getUniqueAssignedVirtualPrinters(printers): string[] {
    const uniquePrinters = printers.reduce((previousValue, currentValue) => {
      currentValue.virtualPrinters.forEach((vp) => {
        previousValue[vp.uuid] = vp.name;
      });
      return previousValue;
    }, {});
    return Object.keys(uniquePrinters);
  }

  public checkVirtualPrinterAssignment(): Observable<boolean> {
    return zip(this.virtualPrintersProvider.getList(), this.receiptPrintersService.getSavedPrintersList()).pipe(
      map((result) => {
        this.logService.debug('PrinterService', 'Get result after checkVirtualPrinterAssignment');
        const [virtualPrinters, savedPrinters] = result;
        if (virtualPrinters.length === 0) return true;
        const uniqueAssignedVirtualPrinters = this.getUniqueAssignedVirtualPrinters(savedPrinters);
        return uniqueAssignedVirtualPrinters.length === virtualPrinters.length;
      })
    );
  }

  public getShowUnprintedKitchenItems() {
    const printersSettings = <IPrintersSettings>this.getPrintersSettingsFromLocalStorage();
    return printersSettings.showUnprintedKitchenItems || false;
  }

  public getPrintersSettings(): IPrintersSettings {
    const printersSettings = <IPrintersSettings>this.getPrintersSettingsFromLocalStorage();
    if (printersSettings.printReceiptAutomatically !== undefined) {
      this.printersSettings = printersSettings;
    } else {
      this.savePrintersSettingsToLocalStorage(this.printersSettings);
    }
    return this.printersSettings;
  }

  private getPrintersSettingsFromLocalStorage(): IPrintersSettings {
    return this.LocalStorage.getObject(StorageKeys.printersSettings);
  }

  private savePrintersSettingsToLocalStorage(printersSettings: IPrintersSettings): void {
    this.LocalStorage.setObject(StorageKeys.printersSettings, printersSettings);
  }

  public updatePrinterSettings(itemType: PrintersSettingsType, value: boolean): void {
    switch (itemType) {
      case PrintersSettingsType.AutoPrintReceipt:
        this.printersSettings.printReceiptAutomatically = value;
        break;
      case PrintersSettingsType.CashRegisterAutoOpen:
        this.printersSettings.openCashRegisterAutomatically = value;
        break;
      case PrintersSettingsType.AutoPrintKitchenReceipt:
        this.printersSettings.printKitchenReceiptAutomatically = value;
        break;
      case PrintersSettingsType.ShowUnprintedKitchenItems:
        this.printersSettings.showUnprintedKitchenItems = value;
        break;
    }
    this.savePrintersSettingsToLocalStorage(this.printersSettings);
  }
}
