<swiper-container posIonSlides [slideOptions]="slideOpts" class="swiper-no-swiping swiper-container">
  <swiper-slide
    *ngFor="let timeRange of timeRangeList; trackByUuid"
    [class.active]="timeRange === activeTimeRange"
    (click)="selectTime(timeRange)"
    class="swiper-slide"
    ripple-effect
  >
    <span>{{ 'calendar_time_range_' + timeRange | translate }}</span>
  </swiper-slide>
</swiper-container>
