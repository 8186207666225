import { EventEmitter, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import * as lodash from 'lodash';
import { LogService } from './logger/log.service';
import { MyPosService } from './devices/my-pos/my-pos.service';

@Injectable()
export class ModalService {
  private modalList: Array<HTMLIonModalElement> = [];

  constructor(private modalCtrl: ModalController, private myPosService: MyPosService, private logService: LogService) {}

  public async presentModal(component: any, data?: any, opts?: any): Promise<HTMLIonModalElement> {
    opts = opts || {};
    opts.cssClass = opts.cssClass || [];

    if (this.myPosService.isDisableModalAnimation) {
      if (typeof opts.cssClass === 'string') {
        opts.cssClass = opts.cssClass.split(' ');
      }
      opts.cssClass.push('my-pos-modal-animation');
      opts.animated = false;
    }

    const newModal = await this.modalCtrl.create({
      component,
      componentProps: data,
      ...opts,
    });
    this.subscribeForDismiss(newModal);
    this.modalList.push(newModal);
    return newModal;
  }

  public dismissAll() {
    for (let i = 0; i < this.modalList.length; i++) {
      this.modalList[i].dismiss().catch((err) => this.logService.error('ModalService', 'dismissAll', err));
    }
  }

  public getTop() {
    return Promise.resolve(this.modalList.slice(-1)?.[0]);
  }

  private subscribeForDismiss(modal: HTMLIonModalElement) {
    modal.onWillDismiss().then(() => {
      this.removeModalFromList(modal);
    });
  }

  //Modal id error
  private removeModalFromList(modal: any) {
    this.modalList = lodash.reject(this.modalList, { id: modal.id });
  }

  public getGuestsModal(component: any, invoiceEntryGuests: InvoiceEntryGuest[], selectedGuestNumber: number = 0) {
    return this.presentModal(
      component,
      {
        invoiceEntryGuests,
        selectedGuestNumber,
        onEdit: new EventEmitter(),
      },
      {
        cssClass: 'guests-modal',
        backdropDismiss: false,
      }
    );
  }
}
