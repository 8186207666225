<ion-backdrop tappable></ion-backdrop>
<div class="cart-modal-inner">
  <pos-header>
    <button tappable (click)="closeModal()" header-start>
      <pos-icon customName="custom-icon-cancel-search"></pos-icon>
    </button>
    <ion-title header-title>{{ 'guest_details_modal_title' | translate }}</ion-title>
    <button (click)="confirmModal()" header-end tappable>
      <pos-icon customName="custom-icon-select"></pos-icon>
    </button>
  </pos-header>
  <div class="cart-modal-body">
    <form [formGroup]="guestDetailsForm">
      <div class="row row-label-value">
        <span class="row-label">{{ 'common_name' | translate }}</span>
        <span class="row-value">
          <input
            class="cart-modal-input"
            type="text"
            formControlName="name"
            [placeholder]="'guest_details_modal_name_placeholder' | translate"
          />
        </span>
      </div>
    </form>
    <div class="row row-label-value">
      <button class="create-customer-button" (click)="createNewCustomer()" tappable>
        <pos-icon customName="custom-icon-add-custom-product"></pos-icon>
        <span>{{ 'customers_new_customer' | translate }}</span>
      </button>
    </div>
    <div class="row row-label-value">
      <pos-customer-search
        [showEditButton]="true"
        [selectedCustomerUuid]="invoiceEntryGuest.customerIdentifier"
        (onSelectCustomer)="selectCustomer($event)"
        (onEditCustomer)="editCustomer($event)"
      ></pos-customer-search>
    </div>
  </div>
  <div class="cart-modal-footer">
    <ng-container *ngIf="showClearButton">
      <pos-button class="cart-modal-button-remove" label="common_clear" color="--pos-red" (onClick)="removeGuestData()"></pos-button>
      <pos-button class="cart-modal-button-done" label="common_done" (onClick)="confirmModal()"></pos-button>
    </ng-container>
    <pos-button *ngIf="!showClearButton" label="common_done" (onClick)="confirmModal()"></pos-button>
  </div>
</div>
