<ion-item-group>
  <ng-container *ngIf="connectedDisplays.length">
    <div class="label-caption">{{ 'customer_display_connected_displays' | translate }}</div>
    <div class="list-group">
      <ion-item class="list-item" *ngFor="let connectedDisplay of connectedDisplays; trackBy: trackByFn">
        <span class="is-online" [class.offline]="connectedDisplay.status !== 'online'"></span>
        <span>{{ connectedDisplay.name }}</span>
        <span class="disconnect-btn" (click)="changeConnectionStatus(connectedDisplay)" slot="end">
          {{ 'common_disconnect' | translate }}
        </span>
      </ion-item>
    </div>
  </ng-container>

  <div class="label-caption">{{ 'customer_display_found_displays' | translate }}</div>
  <div class="list-group" *ngIf="displays.length > 0">
    <ion-item class="list-item" *ngFor="let display of displays; trackBy: trackByFn">
      <span>{{ display.name }}</span>
      <span class="connect-btn" (click)="changeConnectionStatus(display)" slot="end">{{ 'common_connect' | translate }}</span>
    </ion-item>
  </div>
  <div *ngIf="displays.length === 0" class="no-items">
    {{ 'customer_display_no_displays_found' | translate }}
  </div>
</ion-item-group>
