import { Injectable, Injector, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef, ComponentRef } from '@angular/core';
import { INullifyInitModal } from '../../interfaces';
import { SetTimeoutUtil } from '../utils/settimeout.utils';

@Injectable()
export class NullifyModalService {
  private nullifyComponentRef: ComponentRef<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private setTimeoutUtil: SetTimeoutUtil
  ) {}

  public async initModal(params: INullifyInitModal): Promise<void> {
    if (this.isNullifyModalExist()) {
      this.destroyModal();
    }
    await this.setTimeoutUtil.waitTimeAndDo(10);
    const cp = await import('../../../common/components/nullify-modal/nullify-modal.component').then((cp) => cp.NullifyModal);
    this.nullifyComponentRef = this.componentFactoryResolver.resolveComponentFactory(cp).create(this.injector);
    this.nullifyComponentRef.instance.proceedInit({ component: params.component, options: params.options, inputs: params.inputs });

    this.appRef.attachView(this.nullifyComponentRef.hostView);
    const domElem = (this.nullifyComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    const appElem = document.querySelector('app-paymash');
    const delayRender = params?.options?.delayRender ?? 0;
    this.setTimeoutUtil.addVisualEffect(delayRender).then(() => appElem.appendChild(domElem));
  }

  public destroyModal() {
    if (this.nullifyComponentRef) {
      this.appRef.detachView(this.nullifyComponentRef.hostView);
      this.nullifyComponentRef.instance.subComponentDestroy();
      this.nullifyComponentRef.destroy();
      this.nullifyComponentRef = null;
    }
  }

  public isNullifyModalExist(): boolean {
    return !!this.nullifyComponentRef;
  }
}
