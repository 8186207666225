import { Injectable, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject, pipe } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Nullable, SubscriptionLike } from 'subsink/dist/subsink';

@Injectable()
export class SubSinkService implements OnDestroy {
  destroy$ = new Subject<boolean>();
  subs = new SubSink();

  set sink(value: Nullable<SubscriptionLike>) {
    this.subs.sink = value;
  }

  takeUntilDestroy<T>(): MonoTypeOperatorFunction<T> {
    return pipe(takeUntil(this.destroy$));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
