import { ModuleWithProviders, NgModule } from '@angular/core';
import { GiftCardService } from './gift-card.service';
import { AssignGiftCardComponent } from './assign-gift-card-modal/assign-gift-card.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { GiftCardProvider } from './gift-card.provider';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CommonHeaderComponentModule } from '@pos-common/components/common-header/common-header.component';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule.forChild(), ReactiveFormsModule, CommonHeaderComponentModule],
  declarations: [AssignGiftCardComponent],
})
export class GiftCardModule {
  static forRoot(): ModuleWithProviders<GiftCardModule> {
    return {
      ngModule: GiftCardModule,
      providers: [{ provide: GiftCardProvider, useClass: GiftCardService }],
    };
  }
}
