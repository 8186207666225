import { CustomerDisplayStatus, ICustomerDisplayDevice } from '../ICustomerDisplayDevice';
import { IDriver } from './IDriver';
import { ILogFormatter, ILogger, ILoggerFactory, LogFormatterOptions } from '@spryrocks/logger';

export abstract class UniversalCustomerDisplayDevice<ConnectionInfo> extends ICustomerDisplayDevice implements ILogFormatter<UniversalCustomerDisplayDevice<ConnectionInfo>> {
  private readonly logger: ILogger;
  public failedConnectionAttempts: { count: number; last: Date } | undefined;

  protected _name: string;
  get name(): string {
    return this._name;
  }

  constructor(
    protected readonly loggerFactory: ILoggerFactory,
    public readonly providerId: string,
    public readonly discoveryId: string,
    public readonly id: string,
    name: string,
    public status: CustomerDisplayStatus,
    public readonly connectionInfo: ConnectionInfo,
    public discovered: boolean,
    public autoConnect: boolean | undefined,
    public temporary: boolean = false
  ) {
    super();
    this.logger = loggerFactory.createLogger("UniversalCustomerDisplayDevice");
    this.logger.updateParams({device: this});
    this._name = name;
  }

  setStatus(status: CustomerDisplayStatus) {
    this.logger.info("Set status", {oldStatus: this.status, newStatus: status});
    this.status = status;
  }

  abstract createDriver(): IDriver<UniversalCustomerDisplayDevice<ConnectionInfo>, ConnectionInfo>;

  loadFrom(device: ICustomerDisplayDevice) {
    this._name = device.name;
  }

  get logFormatter(): LogFormatterOptions<UniversalCustomerDisplayDevice<ConnectionInfo>> {
    return {
      excludeFields: [
        'loggerFactory',
        'logger',
      ] as any
    }
  }
}

export interface SavedDeviceInfo {
  providerId: string;
  discoveryId: string;
  deviceId: string;
  deviceName: string;
  autoConnect: boolean;
  isOffline: boolean;
}
