import { Injectable } from '@angular/core';
import { InvoceInlist } from '../../classes/invoice-in-list.class';
import { InvoicesService } from './invoices.service';

@Injectable()
export class InvoiceListService {
  constructor(private invoicesService: InvoicesService) {}

  public prepareListForUI(fullListInvoice: any[]): InvoceInlist[] {
    let listInvoice = [];
    const itemsCount = fullListInvoice.length;
    const cancellationInvoices = fullListInvoice.filter((cancellationInvoice) => cancellationInvoice.originalInvoiceReference);

    for (let i = 0; i < itemsCount; i++) {
      if (fullListInvoice[i]) {
        fullListInvoice[i].isPartiallyCancelled = this.invoicesService.isPartiallyCancelled(fullListInvoice[i], cancellationInvoices);
        const currentItem = new InvoceInlist(fullListInvoice[i]);
        listInvoice = [...listInvoice, currentItem];
      } else {
        break;
      }
    }

    return listInvoice;
  }
}
