import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, requestCameraPermissions, requestGalleryPermissions } from '@pos-common/classes';
import { PermissionState } from '@pos-common/constants';

@Injectable()
export class CameraService {
  async takePhoto(source: CameraSource): Promise<string> {
    return Camera.getPhoto({
      source,
      resultType: CameraResultType.Base64,
      saveToGallery: false,
      quality: 50,
      correctOrientation: true,
    }).then(({ base64String }) => base64String);
  }

  requestPermissions(source: CameraSource): Promise<boolean> {
    if (source === CameraSource.Photos) {
      return requestGalleryPermissions().then((status) => status === PermissionState.granted);
    }
    return requestCameraPermissions().then((status) => status === PermissionState.granted);
  }
}
