export const SERVER_RESPONSE_ERRORS = {
  ASSIGNED_CUSTOMER_DOES_NOT_EXIST_IN_DB: '00000100',
  INVOICE_IS_ALREADY_DELETED: '00000101',
  INVOICE_IS_ALREADY_CANCELED: '00000102',
  INVOICE_CAN_NOT_BE_DELETED: '00000104',
  ORIGIN_INVOICE_NOT_EXIST: '00000103',
  CUSTOMER_DELETED: '00030100',
  CUSTOMER_NOT_FOUND: '00030101',
  GIFT_CARD_STATUS_NOT_FOUND: 404,
  VERSION_IS_ABSENT_IN_REQUEST: '03080001',
  VERSION_WRONG_FORMAT: '03080002',
  VERSION_IS_EXPIRED: '03080003',
  POS_MAX_COUNT_IS_EXCEEDED: '03080004',
  MAX_APPOINTMENTS_IN_LICENSE: '00100101',
  NO_INTERNET: 0,
};
