/**
 * Created by ap on 6/04/18.
 */

import { Component, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { PaymentProcessingActions } from '../payment-processing-actions.enum';

@Component({
  selector: 'processing-icons',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'processing-icons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingIcons {
  @Input() icon: PaymentProcessingActions;
  paymentProcessingActions = PaymentProcessingActions;
}
