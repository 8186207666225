import { Injectable } from '@angular/core';
import { AppointmentNotification } from '@pos-common/classes/appointment/appointment-notification.class';
import { Appointment } from '@pos-common/classes/appointment/appointment.class';
import { SecuredResponse } from '@pos-common/classes/secured-response.class';
import { CALENDAR_TIME_RANGE } from '@pos-common/constants';
import { LOCALE } from '@pos-common/constants/locale.const';
import { SALES_CHANNEL_TYPES } from '@pos-common/constants/sales-channel-types';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import {
  IBookingSettings,
  ICancellationAppointmentParams,
  ICreateAppointmentParams,
  IEmailAppontmentParams,
  IFilterFormParams,
} from '@pos-common/interfaces';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityService } from '../system/security.service';

@Injectable()
export class AppointmentApiService {
  private baseAppontmentUrl = `${SERVER_CONFIG.API_URL}booking/appointments`;
  private baseCalendarUrl = `${SERVER_CONFIG.API_URL}booking/calendar`;
  private baseNotificationUrl = `${SERVER_CONFIG.API_URL}booking/notifications`;

  constructor(private securityService: SecurityService) {}

  createAppointment(startedAt: string, createdByEmployeeUuid: string) {
    const url = this.baseAppontmentUrl;
    const localCreationDate = moment().format(LOCALE.DateFormat.YYYY_MM_DD_HH_mm_DASH);
    return this.securityService
      .doSecureRequestObservable(url, 'post', { startedAt, localCreationDate, createdByEmployeeUuid })
      .pipe(map((response: SecuredResponse) => new Appointment(response.data)));
  }

  createInvoiceForAppointmet(params: ICreateAppointmentParams) {
    const { appoinmentUuid, ...restParams } = params;
    const url = `${this.baseAppontmentUrl}/${appoinmentUuid}/invoice`;
    return this.securityService
      .doSecureRequestObservable(url, 'post', { salesChannel: SALES_CHANNEL_TYPES.POS, ...restParams })
      .pipe(map((response: SecuredResponse) => response.data));
  }

  updateAppointment(appoinment: Appointment, updatedByEmployeeUuid: string) {
    const url = `${this.baseAppontmentUrl}/${appoinment.uuid}`;
    return this.securityService
      .doSecureRequestObservable(url, 'put', { ...appoinment, updatedByEmployeeUuid })
      .pipe(map((response: SecuredResponse) => new Appointment(response.data)));
  }

  deleteAppointment(appoinmentUuid: string, params: ICancellationAppointmentParams) {
    const url = `${this.baseAppontmentUrl}/${appoinmentUuid}`;
    return this.securityService.doSecureRequestObservable(url, 'delete', null, { body: params, showLoading: true });
  }

  sendAppointment(appoinmentUuid: string, savedByEmployeeUuid: string, emailParams: IEmailAppontmentParams = {}) {
    const url = `${this.baseAppontmentUrl}/${appoinmentUuid}/send`;
    return this.securityService
      .doSecureRequestObservable(url, 'put', { ...emailParams, savedByEmployeeUuid })
      .pipe(map((response: SecuredResponse) => new Appointment(response.data)));
  }

  getAppintment(appoinmentUuid: string) {
    const url = `${this.baseAppontmentUrl}/${appoinmentUuid}`;
    return this.securityService
      .doSecureRequestObservable(url, 'get', null, { showLoading: true })
      .pipe(map((response: SecuredResponse) => new Appointment(response.data)));
  }

  getCalendarAppintments(timeRange: CALENDAR_TIME_RANGE, data: any) {
    const type = timeRange === CALENDAR_TIME_RANGE.day ? timeRange : 'range';
    const isShowBookableHours = timeRange === CALENDAR_TIME_RANGE.week;
    const params = this.getFiltersParams(data);
    let url = `${this.baseCalendarUrl}/select?type=${type.toLocaleUpperCase()}&isShowBookableHours=${isShowBookableHours}`;
    url += params;
    return this.securityService.doSecureRequestObservable(url, 'get');
  }

  getListAppintments(filterFormParams: IFilterFormParams, data: any) {
    let url = `${this.baseCalendarUrl}/`;
    const { page, pageSize, search } = filterFormParams;
    if (search) {
      url += `search?search=${search}`;
      return this.securityService.doSecureRequestObservable(url, 'get');
    }
    const params = this.getFiltersParams(data);
    url += `list?page=${page}&pageSize=${pageSize}${params}`;
    return this.securityService.doSecureRequestObservable(url, 'get');
  }

  getNotificationList(employeeUuid: string): Observable<AppointmentNotification[]> {
    const url = `${this.baseNotificationUrl}?employeeUuid=${employeeUuid}`;
    return this.securityService
      .doSecureRequestObservable(url, 'get')
      .pipe(map((response: SecuredResponse) => response.data.map((item) => new AppointmentNotification(item))));
  }

  getNotificationCount(employeeUuid: string): Observable<number> {
    const url = `${this.baseNotificationUrl}/count?employeeUuid=${employeeUuid}`;
    return this.securityService.doSecureRequestObservable(url, 'get').pipe(map((response: SecuredResponse) => response.data));
  }

  removeNotifications(uuids: string[], employeeUuid: string) {
    const body = { appointmentNotificationsUuids: uuids };
    const url = `${this.baseNotificationUrl}?employeeUuid=${employeeUuid}`;
    return this.securityService.doSecureRequestObservable(url, 'delete', null, { body, showLoading: true });
  }

  getBookingSetting(): Observable<IBookingSettings> {
    const url = `${SERVER_CONFIG.API_URL}settings/booking`;
    return this.securityService.doSecureRequestObservable(url, 'get').pipe(map((response: SecuredResponse) => response.data));
  }

  private getFiltersParams(data: any) {
    const { filters, ...rest } = data;
    const queryData = { ...rest, ...filters };
    return Object.keys(queryData).reduce((params, key) => {
      let value = queryData[key];
      if (Array.isArray(value)) {
        value = value
          .map((v) => {
            if (v.hasOwnProperty('uuid')) {
              return v.uuid || 'null';
            }
            return v;
          })
          .join(',');
      }
      params += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      return params;
    }, '');
  }
}
