import { Component, ViewEncapsulation } from '@angular/core';
import { SocketScannerService } from '../../../common/services/system/socket-scanner/socket-scanner.service';
import { ScannerAppService } from '../../../common/services/system/scanner-app.service';
import { ModalService } from '../../../common/services/system/modal.service';
import { ScannerAppSelectModal } from '../../../common/components/scanner-app-select/scanner-app-select.component';
import { ReceiptPrinter } from '../../../common/services/system/receipt-printers/classes/receipt-printer.class';
import { PrinterService } from '../../../common/services/system/printer.service';
import { ReceiptPrintersService } from '../../../common/services/system/receipt-printers';
import { ReceiptPrinterModeTypes } from '../../../common/constants/receipt-printer-mode-types';
import { LoadingService } from '../../../common/services/system/loading.service';
import { LocalStorage } from '../../../common/services/utils/localstorage.utils';
import { StorageKeys } from '../../../common/constants/storage.const';
import { SubSink } from 'subsink';
import { finalize } from 'rxjs/operators';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';

@Component({
  selector: 'scanners',
  styleUrls: ['scanners.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'scanners.component.html',
})
export class Scanners {
  public isAndroid: boolean = false;
  public scannerNames: string[] = [];
  public socketScannerSearchInProgress: boolean = false;
  public scannerAppSelectModal: any = null;
  public activeScannerName: string;
  public listScanners: Array<ReceiptPrinter> = [];
  private subs = new SubSink();

  constructor(
    private socketScannerService: SocketScannerService,
    private scannerAppService: ScannerAppService,
    private platformService: PlatformService,
    private printerService: PrinterService,
    private receiptPrintersService: ReceiptPrintersService,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private localStorage: LocalStorage
  ) {}

  ngOnInit() {
    this.isAndroid = this.platformService.isAndroid;
    this.scannerNames = this.deviceNames();
    this.subs.sink = this.socketScannerService.onConnected().subscribe(() => {
      this.scannerNames = this.deviceNames();
      if (this.scannerNames.length) {
        this.localStorage.set(StorageKeys.socketScannerConnectedKey, 'true');
      }
      this.socketScannerSearchInProgress = false;
    });
    this.subs.sink = this.socketScannerService.onDisconnected().subscribe(() => {
      this.scannerNames = this.deviceNames();
    });

    this.activeScannerName = this.scannerAppService.activeScanner;

    this.subs.sink = this.scannerAppService.activeScannerChangedEvent.subscribe((data) => {
      this.activeScannerName = data;
    });
    this.subs.sink = this.printerService.getPrintersList(ReceiptPrinterModeTypes.STAR_SCANNER_SUPPORTED).subscribe((scannersList) => {
      this.listScanners = scannersList;
    });
  }

  ngOnDestroy() {
    if (this.scannerAppSelectModal) this.scannerAppSelectModal.dismiss();
    this.subs.unsubscribe();
  }

  public connectScanner() {
    this.socketScannerSearchInProgress = true;
    this.socketScannerService.startObserve();
    this.scannerNames = this.deviceNames();
  }

  public disconnectScanner() {
    this.socketScannerService.stopObserve().then(() => {
      this.socketScannerSearchInProgress = false;
      this.scannerNames = this.deviceNames();
      if (!this.scannerNames.length) {
        this.localStorage.set(StorageKeys.socketScannerConnectedKey, 'false');
      }
    });
  }

  public async openScannerAppSelect() {
    this.scannerAppSelectModal = await this.modalService.presentModal(ScannerAppSelectModal, {}, { showBackdrop: false });
    this.scannerAppSelectModal.onDidDismiss().then((data) => {
      this.activeScannerName = data.data;
      this.scannerAppSelectModal = null;
    });
    return this.scannerAppSelectModal.present();
  }

  public connectToScanner(scanner: ReceiptPrinter) {
    this.loadingService.showLoadingItem();
    this.subs.sink = this.receiptPrintersService
      .checkScannerConnectivityStatus(scanner)
      .pipe(finalize(() => this.loadingService.hideLoadingItem()))
      .subscribe((isConnected) => {
        if (!isConnected) {
          return;
        }
        const scannersForRemove = this.listScanners.filter((i) => i.macAddress !== scanner.macAddress);
        scannersForRemove.forEach((s) => this.receiptPrintersService.removeScannerPrinter(s));
        this.receiptPrintersService.addScannerPrinter(scanner);
      });
  }

  public disconnectFromScanner(scanner: ReceiptPrinter) {
    this.receiptPrintersService.removeScannerPrinter(scanner);
  }

  private deviceNames(): string[] {
    return this.socketScannerService.connectedDevices.map((device) => {
      const { name, batteryLevel } = device;
      if (batteryLevel) {
        return `${name} ${batteryLevel}%`;
      }
      return name;
    });
  }
}
