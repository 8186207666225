import { Component, ViewEncapsulation, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as lodash from 'lodash';
import { VatRate } from '../../../common/classes/vat-rate.class';
import { UPDATES_TYPES } from '../../../common/constants/updates-types.const';
import { DbDaoService } from '../../../common/services/db/db-dao.service';
import { UpdatesService } from '../../../common/services/system/updates.service';
import { CartService } from '../../../common/services/system/cart.service';
import { MathUtils } from '../../../common/services/utils/math.utils';
import { PRODUCT_TYPES } from '../../../common/constants/product-types';
import { UPDATES_SOURCES } from '../../../common/constants/updates-sources.const';
import { InvoiceEntry } from '../../../common/classes/invoice-entry.class';
import { SecurityService } from '../../../common/services/system/security.service';
import { LogService } from '../../../common/services/system/logger/log.service';
import { SubSink } from 'subsink';
import { ChangeDetectorRef } from '@angular/core';
import { QuantityModificationActions } from '@pos-common/constants/quantity-modification.actions';
import { FormControl, FormGroup } from '@angular/forms';
import { IIndividualProductForm } from '@pos-common/interfaces/forms/individual-product-form.interface';
import { TranslateService } from '@ngx-translate/core';
import { IndividualProductModalButtons } from '@pos-common/constants/individual-product-modal-buttons.enum';
import { GenerateRandomColor } from '@pos-common/classes';

@Component({
  selector: 'individual-product-modal',
  styleUrls: ['./individual-product-modal.component.scss'],
  templateUrl: './individual-product-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndividualProductModal implements OnInit, OnDestroy {
  public keyboardClickCallback: Function;
  public selectedIndividualProductData: InvoiceEntry = null;
  public vatRate: VatRate = null;
  public listVATRate: VatRate[] = [];
  public listUpdateInProgress = false;
  public individualProductForm: FormGroup;
  public IndividualProductModalButtons = IndividualProductModalButtons;

  private defaultVATRate: VatRate = null;
  private singleClickMade = false;
  private shouldCleanPrice = true;
  private isUpdatedVatRate = false;
  private initValues: IIndividualProductForm = null;
  private subs = new SubSink();

  constructor(
    private dbDaoService: DbDaoService,
    private updatesService: UpdatesService,
    private cartService: CartService,
    private viewController: ModalController,
    private navParams: NavParams,
    private securityService: SecurityService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private logService: LogService
  ) {
    this.individualProductForm = new FormGroup({
      name: new FormControl(),
      note: new FormControl(),
      quantity: new FormControl(),
      priceCents: new FormControl(),
      priceDecimal: new FormControl(),
      guestNumber: new FormControl(),
    });
  }

  ngOnInit() {
    const companyData = this.securityService.getLoggedCompanyData();
    if (companyData && companyData.defaultVATRate) {
      this.defaultVATRate = new VatRate(companyData.defaultVATRate);
    }
    this.keyboardClickCallback = this.keyboardClickHandler.bind(this);

    const invoiceEntryData = this.navParams.get('invoiceEntry');
    this.setFormValues(invoiceEntryData);
    this.subs.sink = this.individualProductForm.controls.quantity.valueChanges.subscribe((value) => this.setNewQuantity(value));

    this.subs.sink = this.updatesService.getUpdateEmmiterByType(UPDATES_TYPES.VATRate.type).subscribe((data) => {
      this.listUpdateInProgress = false;
      if (data) {
        this.handleUpdateData(data, UPDATES_TYPES.VATRate.type, UPDATES_SOURCES.UPDATE);
      }
    });
    this.getDataFromDb(UPDATES_TYPES.VATRate.type);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private setFormValues(invoiceEntryData: InvoiceEntry) {
    this.selectedIndividualProductData = invoiceEntryData || null;
    let name = '';
    let note = '';
    let quantity = 1;
    let priceCents = '0';
    let priceDecimal = 0;
    let guestNumber = null;

    if (invoiceEntryData) {
      this.selectedIndividualProductData = new InvoiceEntry(invoiceEntryData);
      const individualProductName = this.translateService.instant('individual_product_modal_title');
      name = invoiceEntryData.name !== 'Individual' && invoiceEntryData.name !== individualProductName ? invoiceEntryData.name : '';
      note = invoiceEntryData.note;
      quantity = invoiceEntryData.quantity;
      priceCents = '0' + MathUtils.roundHalfUp(invoiceEntryData.price * 100, 2).toString();
      priceDecimal = invoiceEntryData.price;
      guestNumber = invoiceEntryData.guestNumber;
    }

    this.individualProductForm.patchValue({
      name,
      note,
      quantity,
      priceCents,
      priceDecimal,
      guestNumber,
    });
    this.initValues = this.individualProductForm.value;
  }

  public getDataFromDb(type: string) {
    this.dbDaoService
      .getAllData(type)
      .then((data) => {
        this.handleUpdateData(data['data'], type, UPDATES_SOURCES.INITIAL);
      })
      .catch((err) => this.logService.error('IndividualProductModal', 'getDataFromDb:getAllDataFromCollection', err));
  }

  public handleUpdateData(updateData, type, source) {
    this.listVATRate = this.updatesService.handleUpdateData(this.listVATRate, updateData, type, source);

    if (this.selectedIndividualProductData) {
      this.vatRate = this.listVATRate[lodash.findIndex(this.listVATRate, { value: this.selectedIndividualProductData.taxRate })];
      if (!this.vatRate) {
        this.vatRate = new VatRate({ value: this.selectedIndividualProductData.taxRate });
        this.listVATRate.push(this.vatRate);
      }
    } else {
      this.setDefaultVatRate();
    }
    this.cdr.detectChanges();
  }

  private setDefaultVatRate(): void {
    if (this.vatRate) {
      return;
    }

    if (!this.defaultVATRate) {
      this.vatRate = this.listVATRate[0];
      return;
    }

    this.vatRate = this.listVATRate.find((vatRate) => vatRate.uuid === this.defaultVATRate.uuid);
  }

  public keyboardClickHandler(symbol: any, type: string) {
    if (this.shouldCleanPrice) {
      this.shouldCleanPrice = false;
      this.setNewValue('', 0);
    }

    const { priceCents } = this.individualProductForm.value;
    if (type === 'number') {
      // break if current amount is 0 and user tried to add 0
      if (symbol === '0' && priceCents.length === 0) {
        return false;
      }

      let tempCents = '' + priceCents + symbol;
      let tempDecimal = parseInt(tempCents) / 100;
      // check if temp amount don't break a limit
      // TODO REMOVE HARDCODED MAX AMOUNT
      if (tempDecimal < 10000000) {
        this.setNewValue(tempCents, tempDecimal);
      }
    } else if (type === 'special') {
      let tempCents = '' + priceCents;
      let tempDecimal = parseInt(tempCents) / 100;

      switch (symbol) {
        case 'delete':
          if (tempCents) {
            // remove last symbol from cent string
            tempCents = tempCents.substr(0, tempCents.length - 1);
            // converse cents string to int
            tempDecimal = parseInt(tempCents) / 100;
            // if all symbols removed
            if (!tempCents) {
              tempCents = '';
              tempDecimal = 0;
            }
            this.setNewValue(tempCents, tempDecimal);
          }
          break;
        case 'double_zero':
          this.keyboardClickHandler(0, 'number');
          this.keyboardClickHandler(0, 'number');
          break;
      }
    }
  }

  public setNewValue(tempCents, tempDecimal: number) {
    const priceDecimal = tempDecimal.toFixed(2);
    this.individualProductForm.patchValue({ priceCents: tempCents, priceDecimal }, { emitEvent: false });
  }

  public changeQuantity(type: string) {
    let { quantity } = this.individualProductForm.value;
    switch (type) {
      case QuantityModificationActions.subtract:
        quantity -= 1;
        if (quantity === 0) {
          quantity -= 1;
        }
        break;
      case QuantityModificationActions.add:
        quantity += 1;
        if (quantity === 0) {
          quantity += 1;
        }
        break;
    }
    this.individualProductForm.patchValue({ quantity }, { emitEvent: false });
  }

  private setNewQuantity(value: string) {
    let newQuantity = 1;
    const quantity = parseInt(value);
    if (quantity < 10000 && quantity > -10000) {
      newQuantity = quantity;
    }
    this.individualProductForm.controls.quantity.setValue(newQuantity, { emitEvent: false });
  }

  public changeVatRate(newVatRate) {
    this.vatRate = newVatRate;
    this.isUpdatedVatRate = true;
  }

  public closePopover(type?: IndividualProductModalButtons) {
    if (this.singleClickMade) {
      return;
    }
    this.singleClickMade = true;

    this.changeEntryQuantity(type);
    this.closeModal();
  }

  private changeEntryQuantity(type?: IndividualProductModalButtons) {
    if (!type) {
      return;
    }

    const quantity = this.individualProductForm.controls.quantity.value;
    if (!this.selectedIndividualProductData) {
      this.cartService.addEntry(this.updateIndividual(), PRODUCT_TYPES.INDIVIDUAL, quantity);
      return;
    }

    if (type === IndividualProductModalButtons.REMOVE) {
      this.cartService.changeEntryQuantity(this.selectedIndividualProductData, -this.selectedIndividualProductData.quantity);
      return;
    }

    if (!this.hasFormChanges() && !this.isUpdatedVatRate) {
      return;
    }

    this.selectedIndividualProductData.update(this.updateIndividual());
    const newQuantity = quantity - this.selectedIndividualProductData.quantity;
    this.cartService.changeEntryQuantity(this.selectedIndividualProductData, newQuantity);
  }

  private hasFormChanges(): boolean {
    const { value } = this.individualProductForm;
    return JSON.stringify(value) !== JSON.stringify(this.initValues);
  }

  public updateIndividual() {
    const values: IIndividualProductForm = this.individualProductForm.value;
    const individualProductName = this.translateService.instant('individual_product_modal_title');
    const productToAdd: any = {
      name: values.name ? values.name : individualProductName,
      note: values.note,
      price: parseInt(values.priceCents) / 100,
      taxRate: this.vatRate.value,
      guestNumber: values.guestNumber,
    };

    if (!this.selectedIndividualProductData) {
      productToAdd.bgColor = new GenerateRandomColor().generateColorByText(productToAdd.name);
    }
    return productToAdd;
  }

  public getListUpdate() {
    this.updatesService.getUpdates([UPDATES_TYPES.VATRate], UPDATES_SOURCES.INITIAL);
    this.listUpdateInProgress = true;
  }

  trackByFn(index: number, item: any) {
    return item ? item.uuid : index;
  }

  private closeModal() {
    this.viewController.dismiss().catch((err) => this.logService.error('IndividualProductModal', 'closeModal:viewController:dismiss', err));
  }
}
