import { ChangeDetectionStrategy, Component, Input, NgModule, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pos-badge',
  styleUrls: ['badge.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() value: string | number;
}

@NgModule({
  declarations: [BadgeComponent],
  exports: [BadgeComponent],
})
export class BadgeComponentModule {}
