import { AdyenUtils } from '@pos-common/services/system/adyen/adyen-utils';
import { Device } from '@ionic-native/device/ngx';
import { Injectable } from '@angular/core';
import { MANUFACTURER_TYPES } from '@pos-common/constants/manufacturer-types';
import { AdyenDeviceModel } from '@pos-common/constants';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { TerminalNames } from '@pos-common/constants/terminal-names.enum';

@Injectable()
export abstract class IAdyenService {
  abstract get isPrinterConnected(): boolean;
  abstract get isScannerConnected(): boolean;
  abstract get isAdyenDevice(): boolean;
  abstract get adyenDeviceModel(): string;
  abstract get isAdyenTerminalConnected(): boolean;
}

@Injectable()
export class AdyenService extends IAdyenService {
  private readonly modelsWithPrinter = ['S1F2'];
  private readonly modelsWithScanner = [];
  private _isAdyenDevice = false;
  private _deviceModel: AdyenDeviceModel;

  public constructor(private readonly adyenUtils: AdyenUtils, private device: Device, private localStorageService: LocalStorage) {
    super();
    this._isAdyenDevice = this.isAdyenManufacturer();
    this._deviceModel = this.detectDeviceModel();
  }

  get isAdyenDevice() {
    return this._isAdyenDevice && !!this._deviceModel;
  }

  get adyenDeviceModel() {
    return this._deviceModel;
  }

  get isPrinterConnected(): boolean {
    const terminal = this.adyenUtils.getTerminal();
    if (!terminal) return false;
    return this.modelsWithPrinter.includes(terminal.deviceModel);
  }

  get isScannerConnected(): boolean {
    const terminal = this.adyenUtils.getTerminal();
    if (!terminal) return false;
    return this.modelsWithScanner.includes(terminal.deviceModel);
  }

  get isAdyenTerminalConnected() {
    const activeTerminal = this.localStorageService.getObject(StorageKeys.activeTerminal);
    return activeTerminal.name === TerminalNames.PAYMASH_PAY;
  }

  private isAdyenManufacturer(): boolean {
    const { manufacturer } = this.device;
    const adyenManufacturers = [MANUFACTURER_TYPES.QUALCOMM, MANUFACTURER_TYPES.CASTLES];
    return adyenManufacturers.some((deviceManufacturer) => deviceManufacturer === manufacturer);
  }

  private detectDeviceModel(): AdyenDeviceModel {
    let model = this.device?.model || '';
    model = model.toLocaleLowerCase();
    return Object.values(AdyenDeviceModel).find((deviceModel) => model.includes(deviceModel.toLocaleLowerCase()));
  }
}
