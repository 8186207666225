import { Component, ViewEncapsulation } from '@angular/core';
import { AlertButton, NavController, ToggleCustomEvent } from '@ionic/angular';
import { PrinterService } from '../../../common/services/system/printer.service';
import { ReceiptPrinter } from '../../../common/services/system/receipt-printers/classes/receipt-printer.class';
import { AlertService } from '../../../common/services/system/alert.service';
import { ReceiptPrintersService } from '../../../common/services/system/receipt-printers/services/receipt-printers.service';
import { NotifyPopupsService } from '../../../common/services/system/notify-popups.service';
import { TranslateService } from '@ngx-translate/core';
import * as lodash from 'lodash';
import { LogService } from '../../../common/services/system/logger/log.service';
import { SecurityService } from '../../../common/services/system/security.service';
import { ROUTE_URLS } from '../../../common/constants/route-urls.const';
import { SubSink } from 'subsink';
import { IPrintersSettings } from '@pos-common/interfaces/printers-settings.interface';
import { PrintersSettingsType } from '@pos-common/constants/printers-settings-type.enum';
import { ReceiptPrinterConnectionTypeEnum } from '@pos-common/services/system/receipt-printers/enum/receipt-printer-connection-type.enum';
import { PermissionService } from '@pos-common/services/system/permission/permission.service';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { OPEN_SETTINGS } from '@pos-common/constants/open-settings.enum';
import { ReceiptPrinterModeTypes } from '@pos-common/constants/receipt-printer-mode-types';

@Component({
  selector: 'printers',
  templateUrl: './printers.component.html',
  styleUrls: ['./printers.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Printers {
  public printersSettings: IPrintersSettings = {
    printReceiptAutomatically: false,
    openCashRegisterAutomatically: false,
    printKitchenReceiptAutomatically: false,
    showUnprintedKitchenItems: true,
  };

  public listOfSavedPrinter: Array<ReceiptPrinter> = [];
  public listOfAvailablePrinters: Array<ReceiptPrinter> = [];
  public listPrinter: Array<ReceiptPrinter> = [];
  public selectedPrinter: ReceiptPrinter = null;
  public selectedPrinterTmpData: ReceiptPrinter = null;
  public printerEditModeActive: boolean = false;
  public deleteConfirm: any;
  public ionViewIsLeaved: boolean;
  public actionsList: any = {
    printerCanBeAdded: false,
    printerCanBeEditable: false,
  };
  public singleClickMade = false;
  public isRestaurantEnabled: boolean = false;
  public printersSettingsType = PrintersSettingsType;
  public readonly connectionType = ReceiptPrinterConnectionTypeEnum;
  public showInfoIcon = false;
  private subs = new SubSink();

  constructor(
    private printerService: PrinterService,
    public translateService: TranslateService,
    public receiptPrintersService: ReceiptPrintersService,
    public notifyPopupsService: NotifyPopupsService,
    public alertService: AlertService,
    public navController: NavController,
    private securityService: SecurityService,
    private permissionService: PermissionService,
    private platformService: PlatformService,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.isRestaurantEnabled = this.securityService.getLoggedCompanyData()
      ? this.securityService.getLoggedCompanyData().isRestaurantEnabled
      : null;
    this.getPrinters();
    this.printersSettings = this.printerService.getPrintersSettings();
    this.subs.sink = this.receiptPrintersService.discoverInProgressState.subscribe((searchInProgress) => {
      this.logService.debug('Printers', `ngOnInit:searchInprogress - ${searchInProgress}`);
      if (!searchInProgress) {
        this.receiptPrintersService.printersList;
      }
    });
    this.showInfoIcon = this.platformService.isAndroid10AndMore;
  }

  ngOnDestroy() {
    this.logService.debug('Printers', `ngOnDestroy`);
    this.subs.unsubscribe();
  }

  public printersSettingsChanged(itemType: PrintersSettingsType, event: Event): void {
    const value = (<ToggleCustomEvent>event).detail.checked;
    this.printerService.updatePrinterSettings(itemType, value);
  }

  public getPrinters() {
    this.subs.sink = this.printerService.getPrintersList().subscribe((printers: Array<ReceiptPrinter>) => {
      this.listOfAvailablePrinters = printers;
      this.setPrintersData();
    });
  }

  public selectPrinter(printer: ReceiptPrinter, listType?: string, source?: string) {
    // dont change selectedCustomer from UI action if editModeActive
    if (printer && source === 'ui' && this.printerEditModeActive) return false;
    this.actionsList.printerCanBeAdded = listType === 'founded';
    this.actionsList.printerCanBeEditable = listType === 'saved';
    // dont update selectedCustomer same customer chosen from UI action
    if (printer && source === 'ui' && this.selectedPrinter && printer && this.selectedPrinter.macAddress === printer.macAddress)
      return false;
    this.selectedPrinter = lodash.cloneDeep(printer);
  }

  private setPrintersData() {
    this.listOfSavedPrinter = this.listOfAvailablePrinters.filter((printer) => printer.saved);
    this.listPrinter = this.listOfAvailablePrinters.filter((printer) => !printer.saved);
  }

  public addToSaved(printer: ReceiptPrinter) {
    this.notifyPopupsService.launchNotification('add-printer');
    this.addPrinterToSaved(printer);
    this.selectPrinter(printer, 'saved');
  }

  public removeFromSaved(printer: ReceiptPrinter) {
    this.receiptPrintersService.removeFromSaved(printer);
  }

  public async showDeleteConfirmation(printer: ReceiptPrinter) {
    let deleteConfirm = await this.alertService.create({
      header: this.translateService.instant('do_you_want_to_delete'),
      buttons: [
        {
          text: this.translateService.instant('common_cancel'),
          handler: () => {},
        },
        {
          text: this.translateService.instant('common_delete'),
          handler: () => this.removeFromSaved(printer),
        },
      ],
    });
    deleteConfirm.present().catch((err) => this.logService.error('Printers', 'showDeleteConfirmation:deleteConfirm:present', err));
  }

  public testPrint(printer: ReceiptPrinter) {
    this.printerService.testPrint(printer);
  }

  goDetails(printer) {
    this.navController
      .navigateForward(ROUTE_URLS.deviceDetails, { state: { printer } })
      .catch((err) => this.logService.error('Printers', 'goDetails', err));
  }

  openPrinterQueuePage() {
    this.navController
      .navigateForward(ROUTE_URLS.printerQueue)
      .catch((err) => this.logService.error('Printers', 'openPrinterQueuePage', err));
  }

  addPrinterManually() {
    this.navController
      .navigateForward(ROUTE_URLS.manualConnection)
      .catch((err) => this.logService.error('Printers', 'addPrinterManually', err));
  }

  openBluetoothSettings() {
    const { isAndroid } = this.platformService;
    this.permissionService
      .openSettings(OPEN_SETTINGS.Bluetooth, isAndroid)
      .catch((err) => this.logService.error('Printers', 'openBluetoothSettings', err));
  }

  async showBackgroundLocationAlert() {
    try {
      const alert = await this.alertService.getBackgroundLocationAlert('alert_background_location_permisson_message_epson');
      const [cancelButton, acceptButton] = alert.buttons as AlertButton[];
      cancelButton.text = this.translateService.instant('common_close');
      alert.buttons = [cancelButton, acceptButton];
      await alert.present();
    } catch (error) {
      this.logService.error('Printers', 'showBackgroundLocationAlert', error);
    }
  }

  private addPrinterToSaved(printer: ReceiptPrinter) {
    if (!this.listOfSavedPrinter.length) {
      this.receiptPrintersService.setActivePrinter(printer, ReceiptPrinterModeTypes.BILL);
      return;
    }
    this.receiptPrintersService.addToSaved(printer);
  }
}
