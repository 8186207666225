<ion-content scrollY="false" fullscreen>
  <div class="calendar-content">
    <pos-header>
      <ng-container header-start>
        <ion-menu-toggle>
          <button>
            <pos-icon ionName="menu-outline"></pos-icon>
          </button>
        </ion-menu-toggle>
        <button (click)="openFilter()" tappable>
          <pos-icon customName="funnel-outline-icon"></pos-icon>
        </button>
        <button (click)="toggleListSearch()" tappable>
          <pos-icon customName="search-icon"></pos-icon>
        </button>
        <pos-notification-button></pos-notification-button>
        <sync-icon></sync-icon>
      </ng-container>

      <pos-date-range class="date-range-title" header-title [activeTimeRange]="activeTimeRange"></pos-date-range>

      <ng-container header-end>
        <button class="btn-create-new-appointment" (click)="createNewAppointment()" tappable>
          <span class="text mobile-hide">{{'common_new' | translate}}</span>
          <pos-icon customName="custom-icon-add-custom-product"></pos-icon>
        </button>
        <button class="btn-current-user">
          <current-user></current-user>
        </button>
      </ng-container>
    </pos-header>
    <pos-date-range class="date-range-content" [activeTimeRange]="activeTimeRange"></pos-date-range>
    <pos-time-range
      [activeTimeRange]="activeTimeRange"
      (posSelectTime)="selectTimeRange($event)"
      (posSelectToday)="selectToday()"
    ></pos-time-range>
    <ng-container [ngSwitch]="activeView">
      <pos-calendar-view
        *ngSwitchCase="CALENDAR_VIEWS.CALENDAR"
        [timeRange]="activeTimeRange"
        (posSelectDay)="selectDay()"
      ></pos-calendar-view>
      <pos-calendar-list-view *ngSwitchCase="CALENDAR_VIEWS.LIST"></pos-calendar-list-view>
    </ng-container>
  </div>
</ion-content>
