import { MyPosPrinterService } from './my-pos-printer.service';
import { MyPosPrinterMockService } from './my-pos-printer.service.mock';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { Injector } from '@angular/core';

export function myPosPrintersServiceFactory(injector: Injector) {
  const platformService = injector.get(PlatformService);
  if (platformService.isNativePlatform) {
    return new MyPosPrinterService(injector);
  }
  return new MyPosPrinterMockService(injector);
}
